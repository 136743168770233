/* eslint-disable camelcase */
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { removeRedundantSpaces } from "utils/strings";
import { JSONTextArea } from "components/common/JSONTextArea";
import { schemaToEmptyJson } from "utils/jsonSchema";
import { ErrorObject } from "ajv";
import { CommonConfigFields } from "../CommonConfigFields";
import { useStyles } from "./styles";
import type { TJsonSchema } from "types/utilTypes";
import type { IConfigForm, TOktaServiceJktConfiguration } from "types/directoryConfiguration";

const OKTA_JWK_SCHEMA = {
	type: "object",
	properties: {
		d: { type: "string" },
		p: { type: "string" },
		q: { type: "string" },
		dp: { type: "string" },
		dq: { type: "string" },
		qi: { type: "string" },
		kty: { type: "string" },
		e: { type: "string" },
		kid: { type: "string" },
		n: { type: "string" }
	},
	required: ["d", "p", "q", "dp", "dq", "qi", "kty", "e", "kid", "n"]
} as TJsonSchema;

const DEFAULT_MANAGER_EMAIL = "managerEmail";
const TRANSLATION_PREFIX = "pages.settings.directoryConfiguration.okta.";

export const OktaConfigurationForm: FC<IConfigForm> = ({ onSave, error, enableSave, shouldReset, inEditMode }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [orgUrl, setOrgUrl] = useState("");
	const [clientId, setClientId] = useState("");
	const [managerEmail, setManagerEmail] = useState("");
	const [jwkConfigJson, setJwkConfigJson] = useState<TOktaServiceJktConfiguration>(
		schemaToEmptyJson(OKTA_JWK_SCHEMA)! as TOktaServiceJktConfiguration
	);
	const [jwkConfigurationErrors, setJwkConfigurationErrors] = useState<string[] | undefined>(undefined);

	const onJwkError = useCallback((errors: Partial<ErrorObject>[] | null) => {
		setJwkConfigurationErrors(
			errors?.map(
				({ message, instancePath }) => `${instancePath ? instancePath.substring(1) + " " : ""}${message || ""}`
			)
		);
	}, []);

	const isValid = useMemo(() => {
		return (
			enableSave &&
			!jwkConfigurationErrors &&
			!!(
				removeRedundantSpaces(orgUrl) &&
				removeRedundantSpaces(clientId) &&
				Object.values(jwkConfigJson).every(value => removeRedundantSpaces(value))
			)
		);
	}, [clientId, enableSave, jwkConfigJson, jwkConfigurationErrors, orgUrl]);

	const submit = useCallback(
		async (agentTokenId?: string | null, isDirectManagerSource?: boolean) => {
			if (!isValid) return;

			const configuration = {
				orgUrl,
				client_id: clientId,
				jwk: jwkConfigJson,
				options: {
					manager_email_field_name: removeRedundantSpaces(managerEmail) || DEFAULT_MANAGER_EMAIL
				}
			};
			await onSave(configuration, isDirectManagerSource, agentTokenId, false);
		},
		[isValid, orgUrl, clientId, jwkConfigJson, managerEmail, onSave]
	);

	const onJsonChanged = useCallback(
		(value: Record<string, unknown>) => setJwkConfigJson(value as TOktaServiceJktConfiguration),
		[]
	);

	useEffect(() => {
		if (shouldReset) {
			setOrgUrl("");
			setClientId("");
			setManagerEmail("");
			setJwkConfigJson(schemaToEmptyJson(OKTA_JWK_SCHEMA)! as TOktaServiceJktConfiguration);
			setJwkConfigurationErrors(undefined);
		}
	}, [shouldReset]);

	return (
		<>
			<Form.Field>
				<JSONTextArea
					autoResize
					className={classes.textarea}
					errors={jwkConfigurationErrors}
					isRequired
					label={t(`${TRANSLATION_PREFIX}jwk`)}
					onChange={onJsonChanged}
					onError={onJwkError}
					validationSchema={OKTA_JWK_SCHEMA}
					value={jwkConfigJson}
				/>
			</Form.Field>
			<Form.Field>
				<Input label={t(`${TRANSLATION_PREFIX}clientId`)} value={clientId} onValueChange={setClientId} isRequired />
			</Form.Field>

			<Form.Field>
				<Input label={t(`${TRANSLATION_PREFIX}orgUrl`)} value={orgUrl} onValueChange={setOrgUrl} isRequired />
			</Form.Field>
			<Form.Field>
				<Input
					label={t(`${TRANSLATION_PREFIX}managerEmail`)}
					value={managerEmail}
					onValueChange={setManagerEmail}
					placeholder={t(`${TRANSLATION_PREFIX}managerEmailPlaceholder`)}
				/>
			</Form.Field>
			<CommonConfigFields inEditMode={inEditMode} onSave={submit} error={error} disabled={!isValid} />
		</>
	);
};
