import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	datePicker: {
		alignItems: "flex-start",
		background: "var(--color-white, #FFF)",
		borderRadius: "8px",
		border: "1px solid var(--color-gray-500, #B2B4B8)",
		display: "flex",
		padding: "var(--spacing-x5, 20px)",
		flexDirection: "row",
		gap: "var(--spacing-x6, 24px)",
		width: "100%"
	},
	calender: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)",
		"--rdp-accent-color": "var(--color-purple-300, #DFD2E4)",
		"--rdp-selected-color": "var(--color-purple-500, #FFFFFF)",
		"--rdp-caption-font-size": "14px",
		margin: "0px",
		width: "100%"
	},
	rangeEnd: {
		backgroundColor: "var(--color-purple-600, #DFD2E4)",
		borderRadius: "0px 8px 8px 0px",
		color: "var(--color-white, #FFFFFF)"
	},
	rangeStart: {
		backgroundColor: "var(--color-purple-600, #DFD2E4)",
		borderRadius: "8px 0px 0px 8px",
		color: "var(--color-white, #FFFFFF)"
	},
	todayDate: {
		color: "var(--color-blue-500, #4285F4)"
	},
	options: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		paddingTop: "var(--spacing-x1, 4px)",
		gap: "var(--spacing-x05, 2px)",
		width: "100%"
	},
	option: {
		display: "flex",
		alignItems: "flex-start",
		width: "100%",
		padding: "var(--spacing-x2, 8px) var(--spacing-x3, 12px)",
		cursor: "pointer",
		color: "var(--color-grey-600, #838589)",
		"&$selectedOption": {
			color: "var(--color-purple-600, #5E1D79)",
			background: "var(--color--purple-100, #F7F4F8)"
		}
	},
	oneDate: {
		display: "flex",
		height: "32px",
		padding: "var(--spacing-x2, 8px)",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "8px",
		background: "var(--color-purple-600, #5E1D79)"
	},
	selectedOption: {}
});
