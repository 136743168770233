import React, { useMemo } from "react";
import { Chip } from "components/ui/chips/Chip";
import { LoadingDots } from "components/ui/LoadingDots";
import { useBundles } from "hooks/useBundles";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { Unknown } from "components/ui/Unknown";
import type { BundleModel } from "models/BundleModel";

const BundleItem: FC<{ bundle?: BundleModel }> = ({ bundle }) => {
	return (
		<Chip size="medium" variant="regular" PrefixIcon={BundleIcon}>
			{bundle ? `${bundle.name} ${bundle.category ? ` - ${bundle.category}` : ""} ${bundle.description}` : <Unknown />}
		</Chip>
	);
};

export const BundlesList: FC<{ bundleIds: string[] }> = ({ bundleIds }) => {
	const bundles = useBundles(true);
	const policyBundles = useMemo(() => (bundles ? bundleIds.map(id => bundles.get(id)) : null), [bundles, bundleIds]);

	return policyBundles ? (
		<>
			{policyBundles.map((bundle, index) => (
				<BundleItem key={bundle?.id ?? index} bundle={bundle} />
			))}
		</>
	) : (
		<LoadingDots center />
	);
};
