import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LoadingDots } from "components/ui/LoadingDots";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { Table } from "components/ui/Table";
import { useLoadingState } from "hooks/useLoadingState";
import { Button } from "components/ui/Button";
import { useIntegrationResourceRoles } from "hooks/useIntegrationResourceRoles";
import { GroupChip } from "components/ui/chips/GroupChip";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { useCompany } from "hooks/useCompany";
import { useMultiDirectoryGroups } from "hooks/useMultiDirectoryGroups";
import { RolesList } from "./components/RolesList";
import { BundlesList } from "./components/BundlesList";
import { useStyles } from "./styles";
import type { PolicyModel } from "models/PolicyModel";
import type { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";

interface IProps {
	policy: PolicyModel;
	deletePolicy: (policyId: string) => void;
	movePolicyUp?: (policy: PolicyModel) => Promise<{ ok: boolean } | undefined>;
	movePolicyDown?: (policy: PolicyModel) => Promise<{ ok: boolean } | undefined>;
	openEditPolicyModal: (policy: PolicyModel) => void;
}

export const PolicyRow: FC<IProps> = ({ policy, openEditPolicyModal, movePolicyDown, movePolicyUp, deletePolicy }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const company = useCompany();
	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();
	const { isLoading, withLoader } = useLoadingState();
	const roles = useIntegrationResourceRoles(policy.integrationResourceRoleIds, true);
	const policyDirectoryGroups = useMultiDirectoryGroups(policy.directoryGroupIds);

	const schedulesLoadedOrNotNeeded = useMemo(() => {
		if (!company) return false;
		return company.integratedToOnCalls.size > 0 ? !!onCallIntegrationSchedules : true;
	}, [company, onCallIntegrationSchedules]);

	const policyOnCallIntegrationSchedules = useMemo(() => {
		const schedules = policy.onCallIntegrationScheduleIds
			.map(id => onCallIntegrationSchedules?.find(schedule => schedule.id === id))
			.filter(Boolean);
		return schedules as OnCallIntegrationScheduleModel[];
	}, [onCallIntegrationSchedules, policy.onCallIntegrationScheduleIds]);

	const openEditModalHandler = useCallback(() => {
		openEditPolicyModal(policy);
	}, [policy, openEditPolicyModal]);

	const deletePolicyHandler = useCallback(() => {
		deletePolicy(policy.id);
	}, [policy, deletePolicy]);

	const movePolicyUpHandler = useCallback(() => {
		if (movePolicyUp && !isLoading) {
			withLoader(movePolicyUp(policy));
		}
	}, [isLoading, movePolicyUp, policy, withLoader]);

	const movePolicyDownHandler = useCallback(() => {
		if (movePolicyDown && !isLoading) {
			withLoader(movePolicyDown(policy));
		}
	}, [isLoading, movePolicyDown, policy, withLoader]);

	return (
		<Table.Row key={policy.id} className={classes.policyRowContainer}>
			<Table.Cell className={classes.policyRowActions}>
				<ChevronDownIcon
					onClick={movePolicyDown ? movePolicyDownHandler : undefined}
					disabled={!movePolicyDown || isLoading}
				/>
				<ChevronUpIcon onClick={movePolicyUp ? movePolicyUpHandler : undefined} disabled={!movePolicyUp || isLoading} />
			</Table.Cell>
			<Table.Cell className={classes.policyRowActions}>{policy.number}</Table.Cell>
			<Table.Cell className={classes.policyRowList}>
				{policyDirectoryGroups && schedulesLoadedOrNotNeeded ? (
					<>
						{policyDirectoryGroups.map(group => (
							<GroupChip key={group.id} variant="regular" value={group} />
						))}
						{policyOnCallIntegrationSchedules.map(schedule => (
							<GroupChip key={schedule.id} variant="regular" value={schedule} />
						))}
					</>
				) : (
					<LoadingDots center />
				)}
			</Table.Cell>
			<Table.Cell className={classes.policyRowList}>
				<BundlesList bundleIds={policy.bundleIds} />
			</Table.Cell>
			<Table.Cell className={classes.policyRowList}>
				{roles !== null ? <RolesList roles={roles} /> : <LoadingDots center />}
			</Table.Cell>
			<Table.Cell className={classes.policyRowActions}>
				<Button size="small" variant="text" onClick={openEditModalHandler}>
					{t("buttons.edit")}
				</Button>
				<Button size="small" variant="text" onClick={deletePolicyHandler}>
					{t("buttons.delete")}
				</Button>
			</Table.Cell>
		</Table.Row>
	);
};
