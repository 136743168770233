import React, { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Section } from "components/ui/Section";
import { Button } from "components/ui/Button";
import { FilterSection, type TFilterOption } from "components/ui/filters/FilterSection";
import { MODEL_TO_ALLOWED_FILTERS } from "components/pages/BulkActionsPage/utils";
import { notEmpty } from "utils/comparison";
import { useOnMount } from "hooks/useOnMount";
import { IntegrationIdFilterExpression } from "../../filters/IntegrationIdFilterExpression";
import { MaintainerFilterExpression } from "../../filters/MaintainerFilterExpression";
import { NameFilterExpression } from "../../filters/NameFilterExpression";
import { OwnerFilterExpression } from "../../filters/OwnerFilterExpression";
import { RequestableFilterExpression } from "../../filters/RequestableFilterExpression";
import { ResourceIdFilterExpression } from "../../filters/ResourceIdFilterExpression";
import { ResourceTypeFilterExpression } from "../../filters/ResourceTypeFilterExpression";
import { RoleIdFilterExpression } from "../../filters/RoleIdFilterExpression";
import { TagsFilterExpression } from "../../filters/TagsFilterExpression";
import { WorkflowFilterExpression } from "../../filters/WorkflowFilterExpression";
import { useStyles } from "./styles";
import type { ALL_FILTERS, TFilterName } from "filters";
import type { IFilter } from "types/filters";
import type { TBulkActionTabOption, TFormFilters, TSelectedFilter } from "components/pages/BulkActionsPage/types";

type TProps = {
	type: TBulkActionTabOption;
	filters: TFormFilters;
	onClear: (type: TBulkActionTabOption) => void;
	onChangeFilter: (filter: IFilter | TFilterName, isValid?: boolean) => void;
};

type TFilterSectionOption = TFilterOption<TFilterName>;

const FilterElement: FC<{ selectedFilter: TSelectedFilter; onChangeFilter: TProps["onChangeFilter"] }> = React.memo(
	function FilterElement({ selectedFilter, onChangeFilter }) {
		const elementRef = useRef<HTMLElement | null>(null);
		const filter = selectedFilter.filter as InstanceType<(typeof ALL_FILTERS)[number]>;
		const defaultHasValue = selectedFilter.isValid;
		const onChange = useCallback(
			(value: IFilter | undefined, isValid?: boolean) => onChangeFilter(value || filter.name, isValid),
			[filter.name, onChangeFilter]
		);
		useOnMount(() => {
			elementRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
		});
		switch (filter.name) {
			case "integrationId":
			case "integrationResourceIntegrationId":
			case "integrationResourceRoleIntegrationId":
				return <IntegrationIdFilterExpression filter={filter} onChange={onChange} innerRef={elementRef} />;
			case "integrationMaintainer":
			case "integrationResourceMaintainer":
				return (
					<MaintainerFilterExpression
						defaultHasValue={defaultHasValue}
						filter={filter}
						onChange={onChange}
						innerRef={elementRef}
					/>
				);
			case "integrationName":
			case "integrationResourceIntegrationName":
			case "integrationResourceName":
			case "integrationResourceRoleName":
			case "integrationResourceRoleIntegrationResourceName":
			case "integrationResourceRoleIntegrationName":
				return <NameFilterExpression filter={filter} onChange={onChange} innerRef={elementRef} />;
			case "integrationOwner":
			case "integrationResourceOwner":
				return <OwnerFilterExpression filter={filter} onChange={onChange} innerRef={elementRef} />;
			case "integrationAllowRequests":
			case "integrationResourceAllowRequests":
			case "integrationResourceRoleAllowRequests":
				return (
					<RequestableFilterExpression
						defaultHasValue={defaultHasValue}
						filter={filter}
						onChange={onChange}
						innerRef={elementRef}
					/>
				);
			case "integrationResourceId":
			case "integrationResourceRoleIntegrationResourceId":
				return <ResourceIdFilterExpression filter={filter} onChange={onChange} innerRef={elementRef} />;
			case "integrationResourceType":
			case "integrationResourceRoleIntegrationResourceType":
				return <ResourceTypeFilterExpression filter={filter} onChange={onChange} innerRef={elementRef} />;
			case "integrationResourceRoleId":
				return <RoleIdFilterExpression filter={filter} onChange={onChange} innerRef={elementRef} />;
			case "integrationResourceTags":
			case "integrationResourceRoleIntegrationResourceTags":
				return <TagsFilterExpression filter={filter} onChange={onChange} innerRef={elementRef} />;
			case "integrationWorkflow":
			case "integrationResourceWorkflow":
			case "integrationResourceRoleWorkflow":
				return <WorkflowFilterExpression filter={filter} onChange={onChange} innerRef={elementRef} />;
			default:
				return null;
		}
	}
);

export const FilterExpressionsSection: FC<TProps> = ({
	className,
	filters,
	innerRef,
	onChangeFilter,
	onClear,
	type
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions" });
	const classes = useStyles();

	const filterOptions = useMemo<TFilterSectionOption[]>(() => {
		return (
			MODEL_TO_ALLOWED_FILTERS.get(type)?.map(filterName => ({
				value: filterName,
				label: t(`filters.title.${filterName}`)
			})) || []
		);
	}, [t, type]);

	const isFilterSelected = useCallback(
		(filterOption: TFilterSectionOption) => filters.has(filterOption.value),
		[filters]
	);

	const onFilterSelect = useCallback(
		(filterOption: TFilterSectionOption) => {
			if (filters.get(filterOption.value)?.isValid) return;
			onChangeFilter(filterOption.value);
		},
		[onChangeFilter, filters]
	);

	const onClearFilters = useCallback(() => {
		onClear(type);
	}, [onClear, type]);

	const filterElements = useMemo(() => {
		return filters
			.toList()
			.map(selectedFilter => (
				<FilterElement
					key={selectedFilter.filter.name}
					onChangeFilter={onChangeFilter}
					selectedFilter={selectedFilter}
				/>
			))
			.filter(notEmpty)
			.toArray();
	}, [filters, onChangeFilter]);

	return (
		<Section
			className={classNames(classes.container, className)}
			innerRef={innerRef}
			noDivider
			title={t("filterForm.filterExpressionsSection.title")}
			titleActions={
				<Button size="small" variant="secondary" onClick={onClearFilters} disabled={filters.size === 0}>
					{t("filterForm.filterExpressionsSection.clearAll")}
				</Button>
			}>
			<FilterSection
				filterOptions={filterOptions}
				isFilterOptionSelected={isFilterSelected}
				onFilterOptionSelected={onFilterSelect}
				filters={filterElements}
			/>
		</Section>
	);
};
