import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/legacy/Typography";
import { Select } from "components/ui/Select";
import { useStyles } from "./styles";
import { OAuthInput } from "./OAuthInput";
import { ConfigurationSchemaInput } from "./ConfigurationSchemaInput";
import type { IChangeConfig, TConfigState } from "hooks/useChangeConfig";

interface IProps {
	changeConfig: IChangeConfig;
	withHeader?: boolean;
}
const getOptionLabel = (option: TConfigState) => option.label;

const isOptionsEqual = (option1: TConfigState, option2: TConfigState) => {
	return option1.label === option2.label;
};

export const ConfigurationEdit: FC<IProps> = ({ changeConfig, withHeader = true }) => {
	const { configState, stateOptions, generalErrors, changeConfigState } = changeConfig;
	const { t } = useTranslation();
	const classes = useStyles();

	if (stateOptions.length > 1 && !configState) {
		changeConfigState(stateOptions[0]);
	}

	const content = useMemo(() => {
		if (stateOptions.length === 0)
			return <Typography variant="standard">{t("pages.integration.noConnections")}</Typography>;
		if (!configState) return null;
		if (configState.type === "oauth") {
			return <OAuthInput changeConfig={changeConfig} />;
		}
		return <ConfigurationSchemaInput changeConfig={changeConfig} />;
	}, [changeConfig, configState, t, stateOptions.length]);

	return (
		<>
			{withHeader && (
				<Typography variant="small" className={classes.label}>
					{t("pages.integration.connection")}
				</Typography>
			)}
			{stateOptions.length > 1 && (
				<Select
					className={classes.select}
					errors={generalErrors}
					getOptionKey={getOptionLabel}
					getOptionLabel={getOptionLabel}
					hideClear
					isOptionEqualToValue={isOptionsEqual}
					onChange={changeConfigState}
					options={stateOptions}
					sort={null}
					value={configState}
				/>
			)}
			{content}
		</>
	);
};
