import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { useControlled } from "hooks/useControlled";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { SAME_WIDTH_MODIFIER, useTooltip } from "hooks/useTooltip";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/Input";
import { Typography } from "components/ui/legacy/Typography";
import {
	cleanString,
	getGroups,
	getLabel,
	getOptionKey as utilsGetOptionKey,
	getSuffix,
	sortOptions,
	TRenderOption
} from "utils/ui/selectUtils";
import uniqueId from "lodash/uniqueId";
import { notEmpty } from "utils/comparison";
import objectHash from "object-hash";
import { SelectItem } from "./components/SelectItem";
import { DROPDOWN_DEFAULT_MAX_HEIGHT, useStyles } from "./styles";
import type { TInputSize } from "../fieldHelpers/types";

export type TSelectVariant = "regular" | "table" | "search";
export type TTargetValue = { target: { value: string }; [key: string]: unknown };
export type TInputChangeEvent = React.ChangeEvent<HTMLInputElement> | TTargetValue;
export type OptionsListActionPanelProps = {
	position: "top" | "bottom";
	content: JSX.Element;
};
export interface ISelectProps<T> {
	autoFocus?: boolean; // default: false. set to true to auto focus the input.
	debug?: boolean; // default: false. set to true to make select stay open (development only).
	defaultValue?: T; // default: null. default selected value.
	description?: string; // default: null. description to display under the label
	disableInput?: boolean; // default: false. set to true to disable the input.
	disabled?: boolean; // default: false. set to true to disable the select.
	errors?: string[]; // default: null. array of error messages.
	filter?: ((options: T[], inputValue: string) => T[]) | null; // default: by label. set the function you want to use to filter options by, use null for no filtering.
	getOptionKey?: (option: T) => string; // default: undefined, try get label. how to get key by option.
	getOptionLabel?: ((option: T) => string) | null; // default: option.label || String(option) || "". how to get label by option. Null will return empty function that returns ""
	getQuery?: (inputValue: string) => string;
	groupBy?: (option: T) => string; // default: null. set the function you want to use to group options by.
	hideClear?: boolean; // default: false. set to true to hide clear icon.
	inputValue?: string; // default: undefined. set input value from props (Controlled)
	isOptionEqualToValue?: (option: T, value: T) => boolean; // default: ===, equality comparator for options.
	label?: React.ReactNode; // default: "". label for select.
	labelIcon?: React.ReactNode | IconComponent; // default: undefined. icon to display in label.
	labelInfo?: React.ReactNode; // default: undefined. info to display in label.
	limit?: number; // default: 30. limit the number of options displayed.
	loading?: boolean; // default: false. show loading indicator.
	maxDropdownHeight?: number; // default 288(px). dropdown max height.
	noOptionsText?: string;
	onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void; // default: undefined. callback on input blur.
	onChange?: (value: T | null) => void; // default: undefined. callback on value change.
	onFocus?: (isFocused: boolean) => void; // default: undefined. callback on input focus.
	onInputChange?: (event: TInputChangeEvent) => void; // default: undefined. callback on input value change.
	optionSelectItemClassName?: string; // default: undefined. class name for option select item.
	options: T[]; // required. options to display.
	optionsListActionPanel?: OptionsListActionPanelProps; // default: undefined
	placeholder?: string; // default: "". placeholder for the select.
	prefix?: React.ReactNode; // default: null. icon to display before input.
	renderLabel?: ((option: T) => React.ReactNode) | null; // default: undefined. how to render label (instead of input value).
	renderOption?: TRenderOption<T>; // default: undefined. how to render option.
	required?: boolean; // default: false. set to true to make input required.
	resetValueOnSelect?: boolean; // default: false. set to true to reset input value on select.
	shouldDisableOption?: (value: T) => boolean;
	size?: TInputSize; // default large
	sort?: ((options: T[]) => T[]) | null; // default: undefined. set undefined for default sort. set to null if the options need no sort. set to function if options need to be sorted by the function.
	suffix?: React.ReactNode; // default: undefined. icon to show after input.
	validators?: ((value: string) => string | null)[];
	value?: T | null; // default: undefined. set value from props (Controlled).
	variant?: TSelectVariant; // default: "box". variant of select.
}

function Select<T>(props: TProps<ISelectProps<T>>) {
	const {
		autoFocus,
		className,
		debug,
		defaultValue = undefined,
		disableInput = false,
		disabled = false,
		errors: userErrors = null,
		filter,
		getOptionKey = undefined,
		getOptionLabel: propGetOptionLabel,
		getQuery = option => option,
		groupBy,
		hideClear = false,
		description,
		id: propId,
		inputValue: propInputValue,
		isOptionEqualToValue = (option, currentValue) => option === currentValue,
		label = "",
		labelIcon,
		labelInfo,
		limit = 30,
		loading = false,
		maxDropdownHeight = DROPDOWN_DEFAULT_MAX_HEIGHT,
		noOptionsText,
		onBlur = undefined,
		onChange: propOnChange,
		onFocus = undefined,
		onInputChange: propOnInputChange,
		options: propOptions,
		optionsListActionPanel,
		optionSelectItemClassName = "",
		placeholder,
		prefix: propPrefix = null,
		renderLabel: propRenderLabel,
		renderOption: propRenderOption,
		required = false,
		shouldDisableOption,
		size = "large",
		sort = sortOptions,
		suffix: propSuffix,
		validators = null,
		value: propValue,
		variant = "regular",
		resetValueOnSelect = false
	} = props;

	const [id] = useState(() => propId || uniqueId());
	const selectId = `select-${id}`;
	const inputId = `input-${id}`;
	const ref = useRef<HTMLDivElement>(null);
	const [value, setValue] = useControlled<T | null>({ controlled: propValue, default: defaultValue });
	const [inputValue, setInputValue] = useControlled<string>({ controlled: propInputValue, default: "" });
	const [open, setOpen] = useState(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(value ? -1 : 0);
	const inputRef = useRef<HTMLInputElement>(null);
	const [errorMessages, setErrorMessages] = useControlled<string[] | null>({
		controlled: userErrors,
		default: undefined
	});
	const classes = useStyles({
		optionPanelActionsPosition: optionsListActionPanel?.position,
		maxHeight: maxDropdownHeight
	});

	const { visible, setTooltipRef, getTooltipProps, setTriggerRef } = useTooltip({
		visible: open,
		offset: [0, 6],
		placement: "bottom",
		popperOptions: {
			modifiers: [SAME_WIDTH_MODIFIER]
		}
	});

	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => {
		if (!debug) {
			setOpen(false);
			setHighlightedIndex(value ? -1 : 0);
			setFilterQuery("");
			inputRef.current?.blur();
		}
	}, [debug, value]);

	useOnClickOutside(ref, handleClose);

	const [filterQuery, setFilterQuery] = useState("");

	const options = useMemo(() => {
		const newOptions = sort !== null ? (sort(propOptions) as T[]) || propOptions : propOptions;

		if (groupBy) {
			return newOptions.sort((a, b) => {
				if (!groupBy(a)) return -1;
				if (!groupBy(b)) return 1;
				return groupBy(a).localeCompare(groupBy(b));
			});
		}

		return newOptions;
	}, [groupBy, sort, propOptions]);

	const resolvedPropGetOptionLabel = useMemo(() => {
		if (propGetOptionLabel === null) return () => "";
		return propGetOptionLabel || getLabel;
	}, [propGetOptionLabel]);

	const getOptionLabel = useCallback(
		(option?: T | null) => {
			if (!option) return "";
			const optionLabel = resolvedPropGetOptionLabel(option);
			return typeof optionLabel === "string" ? optionLabel : "";
		},
		[resolvedPropGetOptionLabel]
	);

	const renderOption = useCallback(
		(option: T, index: number) => {
			if (propRenderOption) {
				return propRenderOption(option, index);
			}
			return <>{getOptionLabel(option)}</>;
		},
		[getOptionLabel, propRenderOption]
	);

	const handleErrors = useCallback(
		(toValidate: string) => {
			const errors = validators?.map(validator => validator(toValidate)).filter(notEmpty);
			setErrorMessages(errors?.length ? errors : undefined);
		},
		[setErrorMessages, validators]
	);

	const resetInputValue = useCallback(
		(event?: React.SyntheticEvent | null, newValue?: T | null) => {
			const newInputValue = getOptionLabel(newValue);

			if (newInputValue === inputValue) {
				return;
			}
			handleErrors(newInputValue);
			setInputValue(newInputValue);

			if (propOnInputChange) {
				propOnInputChange(
					event ? { ...event, target: { ...event.target, value: newInputValue } } : { target: { value: newInputValue } }
				);
			}
		},
		[getOptionLabel, handleErrors, inputValue, propOnInputChange, setInputValue]
	);

	const handleValue = useCallback(
		(newValue: T | null) => {
			if (newValue === value) return;
			if (propOnChange) {
				propOnChange(newValue);
			}
			if (resetValueOnSelect) {
				resetInputValue(null, null);
			} else {
				setValue(newValue);
			}
			setFilterQuery("");
		},
		[value, propOnChange, setValue, resetValueOnSelect, resetInputValue]
	);

	const selectNewValue = useCallback(
		(event: React.SyntheticEvent, newValue: T) => {
			if (!resetValueOnSelect) {
				resetInputValue(event, newValue);
			}
			handleValue(newValue);
			handleClose();
		},
		[handleClose, handleValue, resetInputValue, resetValueOnSelect]
	);

	const handleFocus = useCallback(() => {
		onFocus && onFocus(true);
		if (open || disabled) {
			return;
		}
		handleOpen();
	}, [onFocus, open, disabled, handleOpen]);

	const handleBlur = useCallback(
		(event: React.SyntheticEvent) => {
			onFocus && onFocus(false);
			if (inputValue !== getOptionLabel(value)) {
				resetInputValue(event, value);
			}
			if (!open) {
				return;
			}
			handleClose();
			onBlur && onBlur(event as React.FocusEvent<HTMLInputElement, Element>);
		},
		[getOptionLabel, handleClose, inputValue, onFocus, open, resetInputValue, value, onBlur]
	);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newInputValue = event.target.value;
			if (newInputValue !== inputValue) {
				setInputValue(newInputValue);
				setFilterQuery(newInputValue);
				if (propOnInputChange) {
					propOnInputChange(event);
				}
			}

			if (newInputValue) {
				handleOpen();
			}
		},
		[handleOpen, inputValue, propOnInputChange, setInputValue]
	);

	const handleMouseDown = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			const targetId = (event.target as HTMLDivElement).getAttribute("id");
			if (targetId !== selectId && targetId !== inputId) {
				event.preventDefault();
			}
		},
		[selectId, inputId]
	);

	const handleInputMouseDown = useCallback(
		(event: React.SyntheticEvent) => {
			if (inputValue === "" || !open) {
				if (open) {
					handleBlur(event);
				} else {
					handleFocus();
				}
			}
		},
		[handleBlur, handleFocus, inputValue, open]
	);

	const onClick = useCallback(() => {
		inputRef.current?.focus();
	}, []);

	const handleClear = useCallback(
		(event: React.SyntheticEvent) => {
			handleValue(null);
			resetInputValue(event, null);
			event.stopPropagation();
		},
		[handleValue, resetInputValue]
	);

	const filteredOptions = useMemo(() => {
		if (filter === null) {
			return options.slice(0, limit);
		}
		const cleanFilterQuery = cleanString(getQuery(filterQuery));

		if (groupBy && cleanFilterQuery) {
			return options
				.filter(
					option =>
						cleanString(groupBy(option)).includes(cleanFilterQuery) ||
						cleanString(getOptionLabel(option)).includes(cleanFilterQuery)
				)
				.slice(0, limit);
		}

		if (cleanFilterQuery.length > 0) {
			if (filter) {
				return filter(options, cleanFilterQuery).slice(0, limit);
			}
			return options.filter(option => cleanString(getOptionLabel(option)).includes(cleanFilterQuery)).slice(0, limit);
		}
		return options.slice(0, limit);
	}, [filter, getQuery, filterQuery, groupBy, options, limit, getOptionLabel]);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "ArrowUp") {
				setHighlightedIndex(Math.max(highlightedIndex - 1, 0));
				event.preventDefault();
			} else if (event.key === "ArrowDown") {
				setHighlightedIndex(Math.min(highlightedIndex + 1, limit - 1, filteredOptions.length - 1));
				event.preventDefault();
			} else if (event.key === "Enter") {
				if (highlightedIndex === -1) {
					return;
				}
				const newValue = filteredOptions.at(highlightedIndex);
				if (newValue) selectNewValue(event, newValue);

				handleBlur(event);
			}
		},
		[highlightedIndex, limit, filteredOptions, selectNewValue, handleBlur]
	);

	const suffix = useMemo(
		() =>
			getSuffix({
				disabled,
				handleClear,
				handleClose,
				handleOpen,
				loading,
				open,
				showClear: hideClear ? false : Boolean(inputValue && inputValue.length > 0) && !required,
				suffix: propSuffix,
				suffixClassName: classes.suffix,
				suffixClearClassName: classes.suffixClear
			}),
		[
			classes.suffix,
			classes.suffixClear,
			disabled,
			handleClear,
			handleClose,
			handleOpen,
			hideClear,
			inputValue,
			loading,
			open,
			propSuffix,
			required
		]
	);

	const resolvedPropRenderLabel = useMemo(() => {
		if (propRenderLabel === null) return () => "";
		return propRenderLabel;
	}, [propRenderLabel]);

	const prefix = useMemo(() => {
		if (!propPrefix && !resolvedPropRenderLabel) return null;
		if (!propPrefix && open) return null;
		const renderedLabel = resolvedPropRenderLabel && !open && value ? resolvedPropRenderLabel(value) : null;
		if (!propPrefix && !renderedLabel) return null;

		return (
			<>
				{propPrefix}
				{renderedLabel ? (
					<div className={classes.labelContainer} onClick={handleFocus}>
						{renderedLabel}
					</div>
				) : null}
			</>
		);
	}, [propPrefix, resolvedPropRenderLabel, open, value, classes.labelContainer, handleFocus]);

	const valueUniqueIdentifier = useMemo(() => {
		if (value === null || value === undefined) return "";
		if (typeof value === "string") return value;
		if (typeof value === "object" && "id" in value) return value.id;
		return objectHash(value);
	}, [value]);

	useEffect(() => {
		resetInputValue(null, value);
		setFilterQuery("");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueUniqueIdentifier]);

	const showInput = useMemo(() => {
		if (!resolvedPropRenderLabel) return true;
		if (open) return true;
		if (!value) return true;
		return false;
	}, [open, resolvedPropRenderLabel, value]);

	const SelectList = useMemo(() => {
		if (!visible || disabled) return null;
		return (
			<div ref={setTooltipRef} {...getTooltipProps()} className={classNames(classes.selectItemsContainer)}>
				<div className={classNames(classes.selectOptionsContainer, classes.maxHeight)}>
					<SelectItemList
						filteredOptions={filteredOptions}
						optionClassName={optionSelectItemClassName}
						getOptionKey={getOptionKey}
						getOptionLabel={resolvedPropGetOptionLabel}
						groupBy={groupBy}
						hasMore={options.length > limit && filteredOptions.length === limit}
						highlightedIndex={highlightedIndex}
						checkSelectedOnEmptyValue={resetValueOnSelect}
						isOptionEqualToValue={isOptionEqualToValue}
						maxHeight={maxDropdownHeight}
						noOptionsText={noOptionsText}
						onHighlight={setHighlightedIndex}
						onSelect={selectNewValue}
						renderOption={renderOption}
						shouldDisableOption={shouldDisableOption}
						value={value}
					/>
				</div>
				{optionsListActionPanel && (
					<div className={classes.optionsListActionsPanelContainer}>{optionsListActionPanel.content}</div>
				)}
			</div>
		);
	}, [
		classes.maxHeight,
		classes.optionsListActionsPanelContainer,
		classes.selectItemsContainer,
		classes.selectOptionsContainer,
		disabled,
		filteredOptions,
		getOptionKey,
		getTooltipProps,
		groupBy,
		highlightedIndex,
		isOptionEqualToValue,
		limit,
		maxDropdownHeight,
		noOptionsText,
		optionSelectItemClassName,
		options.length,
		optionsListActionPanel,
		resolvedPropGetOptionLabel,
		renderOption,
		selectNewValue,
		setTooltipRef,
		shouldDisableOption,
		value,
		visible,
		resetValueOnSelect
	]);

	return (
		<div
			className={classNames(classes.container, className)}
			id={selectId}
			onMouseDown={handleMouseDown}
			onClick={onClick}
			ref={ref}>
			<Input
				autoFocus={autoFocus}
				className={classNames(classes.input, className)}
				defaultValue={defaultValue ? getOptionLabel(defaultValue) : undefined}
				disabled={disabled}
				errors={errorMessages || undefined}
				description={description}
				id={inputId}
				innerRef={setTriggerRef}
				inputRef={inputRef}
				isRequired={required}
				label={label}
				labelIcon={labelIcon}
				labelInfo={labelInfo}
				onBlur={handleBlur}
				onChange={handleInputChange}
				onFocus={handleFocus}
				onKeyDown={onKeyDown}
				onMouseDown={handleInputMouseDown}
				placeholder={value && resolvedPropRenderLabel ? undefined : placeholder}
				prefix={prefix}
				showInput={showInput}
				size={size}
				readonly={disableInput}
				suffix={suffix}
				validators={validators || undefined}
				value={inputValue}
				variant={variant === "regular" ? "text" : variant}
			/>
			{SelectList}
		</div>
	);
}

interface ISelectItemListProps<T> {
	filteredOptions: T[];
	getOptionKey?: (option: T) => string;
	getOptionLabel?: (option: T) => string;
	groupBy?: (option: T) => string;
	hasMore: boolean;
	highlightedIndex?: number | null;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	maxHeight: number;
	noOptionsText?: string;
	onHighlight?: (index: number) => void;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	optionClassName?: string;
	renderOption: TRenderOption<T>;
	shouldDisableOption?: (value: T) => boolean;
	value?: T | null;
	checkSelectedOnEmptyValue?: boolean;
}

function SelectItemList<T>(props: TProps<ISelectItemListProps<T>>) {
	const {
		filteredOptions,
		getOptionKey: propGetOptionKey,
		getOptionLabel,
		groupBy,
		hasMore,
		highlightedIndex,
		isOptionEqualToValue,
		maxHeight,
		noOptionsText = null,
		onHighlight,
		onSelect,
		optionClassName,
		renderOption,
		shouldDisableOption,
		value,
		checkSelectedOnEmptyValue = false
	} = props;
	const classes = useStyles({ maxHeight });
	const getClassName = useCallback(
		(option: T) => {
			const isSelected =
				option && (value || checkSelectedOnEmptyValue) && isOptionEqualToValue && isOptionEqualToValue(option, value!);
			return classNames(optionClassName, { selected: isSelected });
		},
		[isOptionEqualToValue, optionClassName, value, checkSelectedOnEmptyValue]
	);
	const { t } = useTranslation();
	const noOptions = noOptionsText ? noOptionsText : t("common.select.noOptionsFound");

	const searchForMore = useMemo(
		() =>
			hasMore ? (
				<Typography className={classes.searchForMore} variant="small">
					{t("common.select.searchForMore")}
				</Typography>
			) : null,
		[classes.searchForMore, hasMore, t]
	);

	const groups = useMemo(
		() => (groupBy && filteredOptions ? getGroups(filteredOptions, groupBy) : null),
		[filteredOptions, groupBy]
	);

	const getOptionKey = useCallback(
		(option: T) => {
			const key = propGetOptionKey ? propGetOptionKey(option) : utilsGetOptionKey(option, getOptionLabel);
			return key;
		},
		[getOptionLabel, propGetOptionKey]
	);

	if (!filteredOptions?.length) {
		return <Typography className={classes.noOptions}>{noOptions}</Typography>;
	}

	if (groups) {
		const groupNames: string[] = [];
		for (const groupName of groups.keys()) {
			groupNames.push(groupName);
		}
		let itemIndex = -1;
		return (
			<div className={classes.maxHeight}>
				{groupNames.map(groupName => {
					const options = groups.get(groupName);
					const header = groupName && (groupName.endsWith(":") ? groupName : groupName + ":");
					return (
						<div key={header} className={classes.groupContainer}>
							{header && (
								<Typography variant="small" className={classes.groupLabel}>
									{header}
								</Typography>
							)}
							{options?.map(option => {
								itemIndex++;
								const optionKey = getOptionKey(option);
								const disabled = shouldDisableOption ? shouldDisableOption(option) : false;
								return (
									<SelectItem
										className={getClassName(option)}
										highlighted={highlightedIndex === itemIndex}
										index={itemIndex}
										isWithinGroup={!!groupName}
										key={optionKey}
										onHover={onHighlight}
										onSelect={onSelect}
										renderOption={renderOption}
										value={option}
										disabled={disabled}
									/>
								);
							})}
						</div>
					);
				})}
				{searchForMore}
			</div>
		);
	}

	return (
		<div className={classes.maxHeight}>
			{filteredOptions.map((option, index) => {
				const optionKey = getOptionKey(option);
				const disabled = shouldDisableOption ? shouldDisableOption(option) : false;
				return (
					<SelectItem
						className={getClassName(option)}
						highlighted={highlightedIndex === index}
						index={index}
						key={optionKey}
						onHover={onHighlight}
						onSelect={onSelect}
						renderOption={renderOption}
						value={option}
						disabled={disabled}
					/>
				);
			})}
			{searchForMore}
		</div>
	);
}

const MemoizedComponent = React.memo(Select) as typeof Select;

export { MemoizedComponent as Select };
