import { apiReq } from "utils/api/apiReq";
import { TicketCommentModel } from "models/TicketCommentModel";

export async function addTicketComment(ticketId: string, content: string, parentId?: string) {
	const { data } = await apiReq("POST", `/v1/ticket/comments`, { content, ticketId, parentId });
	return TicketCommentModel.fromServerData(data);
}

export async function deleteTicketComment(commentId: string) {
	const { data } = await apiReq("DELETE", `/v1/ticket/comments/${commentId}`);
	return TicketCommentModel.fromServerData(data);
}

export async function updateTicketComment(commentId: string, content: string) {
	const { data } = await apiReq("PUT", `/v1/ticket/comments/${commentId}`, { content });
	return TicketCommentModel.fromServerData(data);
}
