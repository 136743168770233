import React, { useCallback, useRef } from "react";
import { useOnMount } from "hooks/useOnMount";
import { AllowRequestsAttributeExpression } from "./components/AllowRequestsAttributeExpression";
import { WorkflowAttributeExpression } from "./components/WorkflowAttributeExpression";
import { ResourceOwnerAttributeExpression } from "./components/ResourceOwnerAttributeExpression/ResourceOwnerAttributeExpression";
import { MaintainersSelectAttributeExpression } from "./components/MaintainersSelectAttributeExpression";
import type {
	TResourceUpdates,
	TRoleOrResourceUpdate,
	TRoleUpdates,
	TRuleType,
	TUpdateMaintainer
} from "models/RuleModel";

type TUpdateAttribute = keyof TRoleUpdates | keyof TResourceUpdates;

type TAttributeExpressionProps<TAttribute extends TUpdateAttribute> = TProps<{
	selectedAttribute: TUpdateAttribute;
	removeSelectedAttribute: (attribute: TUpdateAttribute) => void;
	setUpdatesAttribute: <K extends "approvalAlgorithmId" | "allowsRequests" | "ownerUserId" | "maintainers">(
		attribute: K,
		value: TRoleOrResourceUpdate[K] | undefined
	) => void;
	ruleType: TRuleType;
	value: TRoleOrResourceUpdate[TAttribute];
}>;

export function AttributeExpression<TAttribute extends TUpdateAttribute>({
	selectedAttribute,
	removeSelectedAttribute,
	setUpdatesAttribute,
	ruleType,
	value
}: TAttributeExpressionProps<TAttribute>) {
	const ref = useRef<HTMLDivElement | null>(null);
	const onRemoveAttribute = useCallback(
		() => removeSelectedAttribute(selectedAttribute),
		[selectedAttribute, removeSelectedAttribute]
	);

	useOnMount(() => {
		ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	});

	switch (selectedAttribute) {
		case "allowsRequests":
			return (
				<AllowRequestsAttributeExpression
					innerRef={ref}
					ruleType={ruleType}
					onRemoveAttribute={onRemoveAttribute}
					key="allowsRequests"
					selected={value as boolean}
					setUpdatesAttribute={setUpdatesAttribute}
					attribute={selectedAttribute}
				/>
			);
		case "approvalAlgorithmId":
			return (
				<WorkflowAttributeExpression
					innerRef={ref}
					ruleType={ruleType}
					onRemoveAttribute={onRemoveAttribute}
					key="approvalAlgorithmId"
					selected={value as string}
					setUpdatesAttribute={setUpdatesAttribute}
					attribute={selectedAttribute}
				/>
			);
		case "ownerUserId":
			return (
				<ResourceOwnerAttributeExpression
					innerRef={ref}
					onRemoveAttribute={onRemoveAttribute}
					key="approvalAlgorithmId"
					selected={value as string | null}
					setUpdatesAttribute={setUpdatesAttribute}
					attribute={selectedAttribute}
				/>
			);
		case "maintainers":
			return (
				<MaintainersSelectAttributeExpression
					setUpdatesAttribute={setUpdatesAttribute}
					selected={value as TUpdateMaintainer[]}
					innerRef={ref}
					onRemoveAttribute={onRemoveAttribute}
					key="maintainers"
				/>
			);
		default:
			return null;
	}
}
