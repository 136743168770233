import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modal: {
		maxWidth: "484px"
	},
	content: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		padding: "0 var(--spacing-x3, 12px) var(--spacing-x12, 48px) var(--spacing-x3, 12px)"
	},
	inputAndList: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)"
	},
	actorList: {
		alignItems: "flex-start",
		display: "flex",
		flexGrow: "1",
		flexDirection: "column",
		gap: "var(--spacing-x3, 12px)",
		maxHeight: "325px",
		overflowY: "auto",
		width: "100%"
	}
});
