import React from "react";
import classNames from "classnames";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Tooltip } from "components/ui/Tooltip";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { useTranslation } from "react-i18next";
import { DateWithDiff } from "../DateWithDiff";
import { useStyles } from "./styles";

type TProps = {
	prefix?: React.ReactNode;
	number?: number;
	date: Date;
};

export const RequestDateAndNumber: FC<TProps> = ({ className, innerRef, prefix = null, number, date }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div>{prefix}</div>
			<DateWithDiff date={date} />
			<Tooltip content={t("common.requestDateAndNumber.requestNumber")}>
				<StaticChip size="medium" variant="regular" PrefixIcon={RequestsIcon}>
					{number}
				</StaticChip>
			</Tooltip>
		</div>
	);
};
