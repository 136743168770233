import { Record as ImmutableRecord } from "immutable";
import { ApprovalFlowsWebhookModel } from "models/ApprovalFlowsWebhookModel";

interface IApprovalFlowsWebhookCreatedAuditLogDiffData {
	url: string;
	additionalParams: Record<string, unknown>;
	name: string;
}

export interface IApprovalFlowsWebhookDeletedAuditLogDiffData {
	url: string;
	name: string;
}

export interface IApprovalFlowsWebhookUpdatedAuditLogDiffData {
	fromUrl?: string;
	toUrl?: string;
	fromName?: string;
	toName?: string;
	fromAdditionalParams?: Record<string, unknown>;
	toAdditionalParams?: Record<string, unknown>;
	headersUpdated?: true;
}

type TApprovalFlowsWebhookAuditLogDiffData =
	| IApprovalFlowsWebhookCreatedAuditLogDiffData
	| IApprovalFlowsWebhookDeletedAuditLogDiffData
	| IApprovalFlowsWebhookUpdatedAuditLogDiffData;

type TApprovalFlowsWebhookAuditLogAction =
	| "WorkflowStepWebhookCreated"
	| "WorkflowStepWebhookDeleted"
	| "WorkflowStepWebhookUpdated";

const defaults = {
	action: "" as TApprovalFlowsWebhookAuditLogAction,
	approvalFlowsWebhook: null as ApprovalFlowsWebhookModel | null,
	approvalFlowsWebhookId: "",
	id: "",
	createdAt: new Date(0),
	data: null as TApprovalFlowsWebhookAuditLogDiffData | null,
	userId: ""
};

export class ApprovalFlowsWebhookAuditLogModel extends ImmutableRecord<typeof defaults>(defaults) {
	static readonly type = "approvalFlowsWebhook";

	static fromServerData(data: unknown) {
		const {
			id,
			createdAt,
			data: _data,
			action,
			userId,
			approvalFlowsWebhook,
			approvalFlowsWebhookId
		} = data as typeof defaults;

		return new ApprovalFlowsWebhookAuditLogModel({
			id,
			createdAt: new Date(createdAt),
			approvalFlowsWebhook: approvalFlowsWebhook
				? ApprovalFlowsWebhookModel.fromServerData(approvalFlowsWebhook)
				: null,
			approvalFlowsWebhookId,
			data: _data ? (_data as TApprovalFlowsWebhookAuditLogDiffData) : null,
			action,
			userId
		});
	}
}
