import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { IntegrationOwnerFilter } from "filters/integration";
import { IntegrationResourceOwnerFilter } from "filters/integrationResource";
import { notEmpty } from "utils/comparison";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Chip } from "components/ui/chips/Chip";
import { UserCard } from "components/common/UserCard";
import { UsersIcon } from "components/ui/Icons/UsersIcon";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { useUsersSelect } from "hooks/useUsersSelect";
import { useFilterFormExpression } from "./filter.hooks";
import type { Constructor } from "types/utilTypes";
import type { TFilterOperator } from "types/filters";
import type { UserModel } from "models/UserModel";

type TOwnerFilters = IntegrationOwnerFilter | IntegrationResourceOwnerFilter;

type TOwnerFilterProps = {
	filter: TOwnerFilters;
	onChange: (filter: TOwnerFilters | undefined, isValid: boolean) => void;
};

function getFilter(filterName: TOwnerFilters["name"]): Constructor<TOwnerFilters> {
	switch (filterName) {
		case IntegrationOwnerFilter.filterName:
			return IntegrationOwnerFilter;
		default:
			return IntegrationResourceOwnerFilter;
	}
}

const isValidOperator = (operator: TFilterOperator): operator is TOwnerFilters["operator"] =>
	operator === "is" || operator === "isNot";

const INHERIT = "inherit" as const;
type TInherit = typeof INHERIT;

type TOwnerOption = UserModel | TInherit;

const OPERATORS = ["is", "isNot"] as TFilterOperator[];

export const OwnerFilterExpression: FC<TOwnerFilterProps> = ({ className, innerRef, filter, onChange }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });
	const withInherit = useMemo(() => filter.name !== IntegrationOwnerFilter.filterName, [filter.name]);
	const [query, setQuery] = useState<string>();
	const ids = useMemo(() => filter.value.filter(notEmpty) as string[], [filter.value]);
	const { items: users, isLoading, selectedItems } = useUsersSelect(query || "", { selectedIds: ids });

	const OwnerOptions = useMemo(() => {
		const inheritOption = withInherit ? [INHERIT] : [];
		return (inheritOption as TOwnerOption[]).concat(users?.toArray() || []);
	}, [users, withInherit]);

	const selectedOwners = useMemo(() => {
		const result = selectedItems.toArray() as TOwnerOption[];
		if (filter.value.filter(id => !id).length) {
			result.push(INHERIT);
		}
		return result;
	}, [filter.value, selectedItems]);

	const { clearFilter, removeFilter } = useFilterFormExpression<TOwnerFilters>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const onOperatorSelect = useCallback(
		(operator: TFilterOperator) => {
			if (!filter) return;
			if (!isValidOperator(operator)) return;
			onChange(filter.set("operator", operator), filter.value.length > 0);
		},
		[filter, onChange]
	);

	const onOptionSelect = useCallback(
		(option: TOwnerOption) => {
			if (!filter || !option) return;
			const currentValue = filter.value;
			let newValue = currentValue;
			if (option === INHERIT) {
				newValue = currentValue.find(value => value === null)
					? currentValue.filter(value => value !== null)
					: [...currentValue, null];
			} else {
				newValue = currentValue.includes(option.id)
					? currentValue.filter(id => id !== option.id)
					: [...currentValue, option.id];
			}
			if (filter.name === IntegrationOwnerFilter.filterName) {
				newValue = newValue.filter(notEmpty);
				onChange(filter.set("value", newValue as string[]), newValue.length > 0);
			} else {
				onChange(filter.set("value", newValue as (string | null)[]), newValue.length > 0);
			}
		},
		[filter, onChange]
	);

	const renderOption = useCallback(
		(option: TOwnerOption) => {
			return option === INHERIT ? (
				<TooltipOnOverflow content={t("values.inheritIntegration")} textVariant="body_reg" />
			) : (
				<UserCard user={option} />
			);
		},
		[t]
	);

	const renderSelected = useCallback(
		(option: TOwnerOption) => {
			return option === INHERIT ? (
				<Chip size="large" selected onDelete={() => onOptionSelect(option)}>
					{t("values.inheritIntegration")}
				</Chip>
			) : (
				<UserCard user={option} selected onDelete={() => onOptionSelect(option)} />
			);
		},
		[onOptionSelect, t]
	);

	return (
		<FilterExpression
			className={className}
			emptyState={<FilterExpressionEmptyState text={t("values.emptyUsers")} Icon={UsersIcon} />}
			filter={null}
			innerRef={innerRef}
			inputPlaceholder={t("placeholders.user")}
			isLoading={isLoading}
			getMoreOptions={setQuery}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={removeFilter}
			onReset={clearFilter}
			operators={OPERATORS}
			options={OwnerOptions}
			renderOption={renderOption}
			renderSelected={renderSelected}
			selected={selectedOwners}
			selectedOperator={filter.operator}
			title={t(`title.${filter.name}`)}
			type="multiSelect"
		/>
	);
};
