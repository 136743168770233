import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "components/common/Link";
import { Typography } from "components/ui/legacy/Typography";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { usePermission } from "hooks/usePermission";
import { RoleLabel } from "components/common/RoleLabel";
import { Button } from "components/ui/Button";
import { useLoadingState } from "hooks/useLoadingState";
import { useIsOpenState } from "hooks/useIsOpenState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useStyles } from "./styles";

export const ActorCell = (permission: UserEntitlementModel) => <Typography>{permission.actor!.displayName}</Typography>;

const RoleCellComponent = ({ permission }: { permission: UserEntitlementModel }) => {
	const classes = useStyles();
	return <RoleLabel option={permission.role!} withImage imageClassName={classes.smallIntegrationImage} />;
};
export const RoleCell = (permission: UserEntitlementModel) => <RoleCellComponent permission={permission} />;

const TicketsCellComponent = ({ permission }: { permission: UserEntitlementModel }) => {
	const classes = useStyles();
	return (
		<div className={classes.ticketsCell}>
			{permission.ticketPermissions?.map(
				({ ticket }) =>
					ticket && (
						<Link noDecoration key={ticket.id} to={`?ticketId=${ticket.id}`}>
							#{ticket.number}
						</Link>
					)
			)}
		</div>
	);
};
export const TicketsCell = (permission: UserEntitlementModel) => <TicketsCellComponent permission={permission} />;

const ExpiresCellComponent = ({ permission }: { permission: UserEntitlementModel }) => {
	const resource = permission.role!.integrationResource!;
	const { expiresAt } = usePermission(resource, permission.ticketPermissions);
	const { t } = useTranslation();

	return (
		<Typography>{expiresAt ? t("dateTime.date", { date: expiresAt }) : t("shared.ticketExpiration.never")}</Typography>
	);
};
export const ExpiresCell = (permission: UserEntitlementModel) => <ExpiresCellComponent permission={permission} />;

export const ActionsCellComponent = ({
	permission,
	onRevokePermission
}: {
	permission: UserEntitlementModel;
	onRevokePermission: (permission: UserEntitlementModel) => Promise<void>;
}) => {
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const areYouSureModal = useIsOpenState();
	const { isLoading, withLoader } = useLoadingState();
	const { t } = useTranslation();

	const onRevokePermissionConfirmed = useCallback(async () => {
		try {
			await withLoader(onRevokePermission(permission));
		} catch (e) {
			openGlobalErrorModal(e as Error);
		} finally {
			areYouSureModal.close();
		}
	}, [permission, withLoader, onRevokePermission, openGlobalErrorModal, areYouSureModal]);

	return permission.revokeStatus !== "notRevoking" ? (
		<Typography>{t("pages.user.entitlements.permissionsTable.pendingRevoke")}</Typography>
	) : (
		<>
			<AreYouSureModal
				closeLabel={t("pages.user.entitlements.permissionsTable.revokeAreYouSureModal.close")}
				actionLabel={t("pages.user.entitlements.permissionsTable.revokeAreYouSureModal.confirm")}
				content={t("pages.user.entitlements.permissionsTable.revokeAreYouSureModal.content")}
				title={t("pages.user.entitlements.permissionsTable.revokeAreYouSureModal.title")}
				isOpen={areYouSureModal.isOpen}
				onClose={areYouSureModal.close}
				onAction={onRevokePermissionConfirmed}
			/>
			<Button variant="text" size="medium" loading={isLoading} onClick={areYouSureModal.open}>
				<Typography>{t("pages.user.entitlements.permissionsTable.revoke")}</Typography>
			</Button>
		</>
	);
};
