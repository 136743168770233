import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typography } from "components/ui/legacy/Typography";
import { useTranslation } from "react-i18next";
import { Tooltip } from "components/ui/Tooltip";
import { IBaseInputProps, Input } from "components/ui/Input";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useLoadingState } from "hooks/useLoadingState";
import classNames from "classnames";
import { useStyles } from "./styles";

interface IProps {
	value: string;
	onEdit?: (name: string) => Promise<void> | void;
	resourceType: "integration" | "resource" | "template" | "role";
	inputType: "name" | "type";
	fontVariation?: "big" | "regular";
	noPadding?: boolean;
	validators?: ((val: string) => string | null)[];
	renderInput?: FC<IBaseInputProps & { validators?: ((val: string) => string | null)[] }>;
	renderText?: FC;
}

export const EditableInput: FC<IProps> = ({
	value: propsValue,
	onEdit,
	resourceType,
	fontVariation = "big",
	inputType,
	noPadding = false,
	validators,
	renderInput,
	renderText
}) => {
	const [value, setValue] = useState(propsValue);
	const [showInput, setShowInput] = useState(false);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const { isLoading, withLoader } = useLoadingState();
	const classes = useStyles();
	const { t } = useTranslation();

	const onClick = useCallback(() => {
		if (!showInput) {
			onEdit && setShowInput(true);
		}
	}, [onEdit, showInput]);
	const onChange = useCallback((newValue: string) => setValue(newValue), []);

	const onBlur = useCallback(async () => {
		if (!onEdit || (!renderInput && inputRef.current && inputRef.current === document.activeElement)) return;

		if (value === "" || !value || value === propsValue) {
			setValue(propsValue);
			setShowInput(false);
			return;
		}

		const isValid = validators ? !validators.some(validator => validator(value)) : true;
		if (!isValid) setValue(propsValue);
		else await withLoader(onEdit(value));

		setShowInput(false);
	}, [value, onEdit, propsValue, validators, withLoader, setValue, renderInput]);

	useEffect(() => {
		setValue(propsValue);
	}, [propsValue]);

	const InputComponent = renderInput ?? Input;
	const Text = renderText;
	return (
		<div className={classes.container}>
			<Tooltip
				content={onEdit ? t("common.resourceHeader.edit", { resourceType, inputType }) : null}
				delayShow={300}
				delayHide={300}>
				<div>
					<Typography
						variant="h3"
						className={classNames(classes.value, {
							[classes.hoverActions]: !showInput && !!onEdit,
							[classes.regular]: fontVariation === "regular",
							[classes.big]: fontVariation === "big"
						})}
						onClick={onClick}>
						{showInput ? (
							<div className={classes.inputContainer}>
								<InputComponent
									className={classes.hiddenInput}
									inputRef={inputRef}
									autoFocus
									onValueChange={onChange}
									onBlur={onBlur}
									type="text"
									value={value}
									validators={validators}
								/>
								{isLoading && <LoadingSpinner inline />}
							</div>
						) : Text ? (
							<Text />
						) : (
							value
						)}
					</Typography>
				</div>
			</Tooltip>
		</div>
	);
};
