import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgentTokenModel } from "models/AgentTokenModel";
import { ApiTokenModel } from "models/ApiTokenModel";
import { Input } from "components/ui/Input";
import { Typography } from "components/ui/legacy/Typography";
import { useTranslation } from "react-i18next";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { removeRedundantSpaces } from "utils/strings";
import { Table } from "components/ui/Table";
import { Button } from "components/ui/Button";
import classNames from "classnames";
import { API_TOKEN_DURATION_OPTIONS, TApiTokenDuration } from "utils/tokenDurations";
import { formatDate } from "i18n";
import { TokenDisplay } from "components/common/Token/TokenDisplay";
import { TokenDurationSelectInput } from "components/common/Token/TokenDurationSelectInput";
import { useStyles } from "components/common/Token/styles";
import { PersonalAccessTokenModel } from "models/PersonalAccessTokenModel";

type TToken = ApiTokenModel | AgentTokenModel | PersonalAccessTokenModel;

interface IProps {
	onDelete?: (id: string) => Promise<void> | void;
	isNameTaken: (name: string) => boolean;
	enterEdit?: (id: string) => void;
	onCancel: () => void;
	afterSave: () => void;
}

type TCreate =
	| ((name: string, duration: number | null) => Promise<TToken | undefined | void>)
	| ((name: string) => Promise<TToken | undefined | void>);

interface IEdit extends IProps {
	edit: boolean;
	onEdit: (id: string, name: string) => Promise<TToken | undefined | void>;
	create?: never;
	onCreate?: never;
}

interface ICreate extends IProps {
	create: boolean;
	onCreate: TCreate;
	edit?: never;
	onEdit?: never;
}

type TExpiration =
	| {
			token: ApiTokenModel | PersonalAccessTokenModel;
			withExpiration?: true;
			onCreate?: (name: string, duration: number | null) => Promise<TToken | undefined | void>;
	  }
	| {
			token: AgentTokenModel;
			withExpiration?: false;
			onCreate?: (name: string) => Promise<TToken | undefined | void>;
	  };

type Props = (IEdit | ICreate) & TExpiration;

export const Token: FC<Props> = ({
	token,
	create,
	edit,
	enterEdit,
	isNameTaken,
	withExpiration,
	onDelete,
	onEdit,
	onCreate,
	onCancel,
	afterSave
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [name, setName] = useState(token.name || "");
	const [duration, setDuration] = useState<number | null>((withExpiration && token.expiresAt?.valueOf()) || null);
	const [nameError, setNameError] = useState<string>("");
	const [durationError, setDurationError] = useState<string>("");

	const inputRef = useRef<React.Ref<HTMLInputElement>>();
	const handleError = useOpenGlobalErrorModal();

	const handleCancel = useCallback(() => {
		setName(token.name);
		setNameError("");
		onCancel();
	}, [token.name, onCancel]);

	const save = useCallback(async () => {
		const trimmedName = removeRedundantSpaces(name);
		setName(trimmedName);

		if (trimmedName === token.name && token.id) {
			handleCancel();
		} else if (trimmedName.length < 2 || trimmedName.length > 50) {
			setNameError(t("validationErrors.global.nameLength"));
		} else if (isNameTaken(trimmedName)) {
			setNameError(t("validationErrors.global.alreadyTaken", { field: t("modelsFields.token.name") }));
		} else if (withExpiration && create && !duration) {
			setDurationError(t("pages.settings.tokens.errors.durationRequired"));
		} else if (withExpiration && create && !API_TOKEN_DURATION_OPTIONS.includes(duration as TApiTokenDuration)) {
			setDurationError(t("pages.settings.tokens.errors.durationNotAnOption"));
		} else {
			setNameError("");
			try {
				if (edit) {
					await onEdit(token.id, trimmedName);
				} else if (create) {
					await onCreate(trimmedName, duration !== -1 || !withExpiration ? duration : null);
				}
				afterSave();
			} catch (error) {
				handleError(error as Error);
			}
		}
	}, [
		name,
		token,
		isNameTaken,
		withExpiration,
		create,
		duration,
		handleCancel,
		t,
		edit,
		afterSave,
		onEdit,
		onCreate,
		handleError
	]);

	const handleChange = useCallback(
		(value: string) => {
			setName(value);
		},
		[setName]
	);

	const handleCancelClick = useCallback(
		(event: React.MouseEvent) => {
			event.preventDefault();
			handleCancel();
		},
		[handleCancel]
	);

	const handleKeyDown = useCallback(
		async (event: React.KeyboardEvent) => {
			if (event.key === "Enter") {
				event.preventDefault();
				await save();
			}
		},
		[save]
	);

	const handleDelete = useCallback(async () => {
		onDelete && onDelete(token.id);
	}, [token, onDelete]);

	const handleDurationChange = useCallback(
		(value: number | null) => {
			withExpiration && setDuration(value);
			setDurationError("");
		},
		[withExpiration]
	);

	const onEditClick = useCallback(() => enterEdit && enterEdit(token.id), [enterEdit, token]);

	const expirationDate = useMemo(
		() => (withExpiration && token && token.expiresAt ? new Date(token.expiresAt) : null),
		[token, withExpiration]
	);

	useEffect(() => {
		if (!(edit || create)) {
			return;
		}

		const eventListener = (event: KeyboardEvent) => {
			if (event.key === "Escape" && document.activeElement === inputRef.current) {
				handleCancel();
			}
		};

		document.addEventListener("keydown", eventListener);
		return () => document.removeEventListener("keydown", eventListener);
	}, [handleCancel, edit, create]);

	return (
		<Table.Row className={classes.container}>
			<Table.Cell>
				{create || edit ? (
					<div className={classes.inputContainer}>
						<Input
							autoFocus
							value={name}
							onKeyDown={handleKeyDown}
							onValueChange={handleChange}
							inputRef={inputRef as React.Ref<HTMLInputElement> | undefined}
							variant="table"
						/>
						{nameError && <Typography className={classes.error}>{nameError}</Typography>}
					</div>
				) : (
					<Typography className={classes.name}>
						{token.name}
						<Button onClick={onEditClick} variant="text" size="small" className={classes.edit}>
							{t("buttons.edit")}
						</Button>
					</Typography>
				)}
				{!create && !edit && nameError && <Typography className={classes.error}>{nameError}</Typography>}
			</Table.Cell>

			{withExpiration && (
				<Table.Cell className={classNames(classes.expiryContainer)}>
					<div className={classes.inputContainer}>
						{create ? (
							<TokenDurationSelectInput onChange={handleDurationChange} value={duration || ""} />
						) : expirationDate ? (
							<Typography className={classes.expiration}>
								{Date.now() <= expirationDate.getTime() ? (
									<>
										{t("pages.settings.tokens.expiresOn")}
										{formatDate(expirationDate, "en-US", { format: "with_time" })}
									</>
								) : (
									<div className={classes.expired}>{t("pages.settings.tokens.expired")}</div>
								)}
							</Typography>
						) : (
							<Typography className={classes.expiration}>{t("pages.settings.tokens.noExpiry")}</Typography>
						)}
						{durationError && <Typography className={classes.error}>{durationError}</Typography>}
					</div>
				</Table.Cell>
			)}

			<Table.Cell className={classes.keyContainer}>
				{token.value ? (
					<div className={classes.token}>
						<TokenDisplay token={token.value} />
					</div>
				) : (
					!create && <Typography>{t("pages.settings.tokens.hiddenValue")}</Typography>
				)}
			</Table.Cell>

			<Table.Cell>
				<div className={classes.actionsContainer}>
					{create || edit ? (
						<>
							<Button onClick={save} variant="text" size="small" disabled={!name || (withExpiration && !duration)}>
								{t("buttons.save")}
							</Button>
							<Button onClick={handleCancelClick} variant="text" size="small">
								{t("buttons.cancel")}
							</Button>
						</>
					) : (
						<Button onClick={handleDelete} variant="text" size="small">
							{t("buttons.revoke")}
						</Button>
					)}
				</div>
			</Table.Cell>
		</Table.Row>
	);
};
