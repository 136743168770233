import React, { useCallback, useRef, useState } from "react";
import "react-day-picker/dist/style.css";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { DayPicker, DateRange, DateAfter, DateInterval } from "react-day-picker";
import { useStyles } from "./styles";

type TDateType = "Today" | "Yesterday" | "Last 7 Days" | "Last 30 Days" | "This Month" | "Last Month" | "Custom Range";

type TDateTimeProps = {
	onRangeChange?: (range: DateRange | undefined) => void;
	dateRange?: DateRange;
};
const TODAY = "Today";
const YESTERDAY = "Yesterday";
const LAST_7_DAYS = "Last 7 Days";
const LAST_30_DAYS = "Last 30 Days";
const THIS_MONTH = "This Month";
const LAST_MONTH = "Last Month";
const CUSTOM_RANGE = "Custom Range";

export const DatePicker: FC<TDateTimeProps> = ({ onRangeChange, dateRange }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const currDate = useRef(new Date());
	const [dateType, setDateType] = useState<TDateType>(CUSTOM_RANGE);
	const [disbaledDates, setDisabledDates] = useState<DateInterval | DateAfter>({ after: currDate.current });
	const [month, setMonth] = useState<Date>(currDate.current);

	const setDateToToday = useCallback(() => {
		onRangeChange && onRangeChange({ from: currDate.current, to: currDate.current });
		setDateType(TODAY);
		setDisabledDates({ before: currDate.current, after: currDate.current });
		setMonth(currDate.current);
	}, [onRangeChange]);

	const setDateToYesterday = useCallback(() => {
		const yesterday = new Date(
			currDate.current.getFullYear(),
			currDate.current.getMonth(),
			currDate.current.getDate() - 1
		);
		onRangeChange && onRangeChange({ from: yesterday, to: yesterday });
		setDateType(YESTERDAY);
		setDisabledDates({ before: yesterday, after: yesterday });
		setMonth(yesterday);
	}, [onRangeChange]);

	const setDateToLastSevenDays = useCallback(() => {
		const lastSevenDays = new Date(
			currDate.current.getFullYear(),
			currDate.current.getMonth(),
			currDate.current.getDate() - 7
		);
		onRangeChange && onRangeChange({ from: lastSevenDays, to: currDate.current });
		setDateType(LAST_7_DAYS);
		setDisabledDates({ before: lastSevenDays, after: currDate.current });
		setMonth(lastSevenDays);
	}, [onRangeChange]);

	const setDateToLastThirtyDays = useCallback(() => {
		const lastThirtyDays = new Date(
			currDate.current.getFullYear(),
			currDate.current.getMonth(),
			currDate.current.getDate() - 30
		);
		onRangeChange && onRangeChange({ from: lastThirtyDays, to: currDate.current });
		setDateType(LAST_30_DAYS);
		setDisabledDates({ before: lastThirtyDays, after: currDate.current });
		setMonth(lastThirtyDays);
	}, [onRangeChange]);

	const setDateToThisMonth = useCallback(() => {
		const firstDayOfMonth = new Date(currDate.current.getFullYear(), currDate.current.getMonth(), 1);

		onRangeChange && onRangeChange({ from: firstDayOfMonth, to: currDate.current });
		setDateType(THIS_MONTH);
		setDisabledDates({ before: firstDayOfMonth, after: currDate.current });
		setMonth(firstDayOfMonth);
	}, [onRangeChange]);

	const setDateToLastMonth = useCallback(() => {
		const firstDayOfPreviousMonth = new Date(currDate.current.getFullYear(), currDate.current.getMonth() - 1, 1);
		const lastDayOfPreviousMonth = new Date(currDate.current.getFullYear(), currDate.current.getMonth(), 0);

		onRangeChange && onRangeChange({ from: firstDayOfPreviousMonth, to: lastDayOfPreviousMonth });
		setDateType(LAST_MONTH);
		setDisabledDates({ before: firstDayOfPreviousMonth, after: lastDayOfPreviousMonth });
		setMonth(firstDayOfPreviousMonth);
	}, [onRangeChange]);

	const setDateToCustomRange = useCallback(() => {
		setDateType(CUSTOM_RANGE);
		setMonth(dateRange?.from || currDate.current);
		setDisabledDates({ after: currDate.current });
	}, [dateRange?.from]);

	const handleDateChange = useCallback(
		(range: DateRange | undefined) => {
			if (!range?.to && range?.from) range?.to?.setDate(range?.from?.getDate());
			onRangeChange && onRangeChange(range);
		},
		[onRangeChange]
	);

	const checkIfDateRangeInInputValues = useCallback(() => {
		if (dateRange?.from) {
			if (dateRange.from > disbaledDates.after) {
				dateRange.from = disbaledDates.after;
			}
			if ("before" in disbaledDates && dateRange.from < disbaledDates.before) {
				dateRange.from = disbaledDates.before;
			}
		}

		if (dateRange?.to) {
			if (dateRange.to > disbaledDates.after) {
				dateRange.to = disbaledDates.after;
			}
			if ("before" in disbaledDates && dateRange.to < disbaledDates.before) {
				dateRange.to = disbaledDates.before;
			}
		}
		return dateRange;
	}, [dateRange, disbaledDates]);

	return (
		<div className={classes.datePicker}>
			<div className={classes.options}>
				<div
					className={classNames(classes.option, {
						[classes.selectedOption]: dateType === TODAY
					})}
					onClick={setDateToToday}>
					{t("dateTime.today")}
				</div>
				<div
					className={classNames(classes.option, {
						[classes.selectedOption]: dateType === YESTERDAY
					})}
					onClick={setDateToYesterday}>
					{t("dateTime.yesterday")}
				</div>
				<div
					className={classNames(classes.option, {
						[classes.selectedOption]: dateType === LAST_7_DAYS
					})}
					onClick={setDateToLastSevenDays}>
					{t("dateTime.last7Days")}
				</div>
				<div
					className={classNames(classes.option, {
						[classes.selectedOption]: dateType === LAST_30_DAYS
					})}
					onClick={setDateToLastThirtyDays}>
					{t("dateTime.last30Days")}
				</div>
				<div
					className={classNames(classes.option, {
						[classes.selectedOption]: dateType === THIS_MONTH
					})}
					onClick={setDateToThisMonth}>
					{t("dateTime.thisMonth")}
				</div>
				<div
					className={classNames(classes.option, {
						[classes.selectedOption]: dateType === LAST_MONTH
					})}
					onClick={setDateToLastMonth}>
					{t("dateTime.lastMonth")}
				</div>
				<div
					className={classNames(classes.option, {
						[classes.selectedOption]: dateType === CUSTOM_RANGE
					})}
					onClick={setDateToCustomRange}>
					{t("dateTime.customRange")}
				</div>
			</div>
			<DayPicker
				className={classes.calender}
				mode="range"
				modifiersClassNames={{
					// eslint-disable-next-line camelcase
					range_start: dateRange?.from?.getTime() === dateRange?.to?.getTime() ? classes.oneDate : classes.rangeStart,
					// eslint-disable-next-line camelcase
					range_end: classes.rangeEnd,
					today: classes.todayDate
				}}
				defaultMonth={currDate.current || dateType !== CUSTOM_RANGE}
				ISOWeek
				disabled={disbaledDates}
				month={month}
				onMonthChange={setMonth}
				selected={checkIfDateRangeInInputValues()}
				fromYear={2022}
				onSelect={handleDateChange}
			/>
		</div>
	);
};
