import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Block } from "components/ui/Block";
import { EditorSuccessModal } from "components/common/EditorSuccessModal";
import { useIsOpenState } from "hooks/useIsOpenState";
import { UserModel } from "models/UserModel";
import { ErrorModal } from "components/ui/ErrorModal";
import useErrorModalState from "hooks/useErrorModalState";
import { useUpdateUser } from "hooks/useUpdateUser";
import { PersonalAccessTokens } from "components/pages/ProfilePage/components/ChangeProfileForm/PersonalAccessTokens";
import { IntegrationActorsTable } from "./IntegrationActorsTable";
import { UserNameEdit } from "./UserNameEdit";
import { useStyles } from "./styles";

export const ProfileForm: FC<{ user: UserModel }> = ({ className, user }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const updateUser = useUpdateUser();
	const { isOpen: successModalIsOpen, open: openSuccessModal, close: closeSuccessModal } = useIsOpenState();

	const {
		errorModalSetError,
		errorModalIsOpen,
		errorModalError,
		errorModalClose: closeErrorModal
	} = useErrorModalState();

	return (
		<div className={classNames(className, classes.changeProfileForm)}>
			<Block className={classes.nameBlock}>
				<UserNameEdit user={user} onSuccess={openSuccessModal} onUpdate={updateUser} setError={errorModalSetError} />
			</Block>
			<Block className={classes.integrationActorsBlock}>
				<IntegrationActorsTable
					user={user}
					onSuccess={openSuccessModal}
					onUpdate={updateUser}
					setError={errorModalSetError}
				/>
			</Block>
			<Block>
				<PersonalAccessTokens />
			</Block>
			<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />
			<EditorSuccessModal
				state={"edit"}
				isOpen={successModalIsOpen}
				editTitle={t("pages.profile.editSuccessModalTitle")}
				createTitle={t("pages.profile.editSuccessModalTitle")}
				content={t("pages.profile.successModalInformation")}
				onClose={closeSuccessModal}
			/>
		</div>
	);
};
