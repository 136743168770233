import React, { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { DraggableSidebar, RESIZER_WIDTH_PX } from "components/common/DraggableSidebar";
import { SessionAuditLogModel } from "models/auditLogs/SessionAuditLogModel";
import { useIntegrations } from "hooks/useIntegrations";
import { Divider } from "components/ui/Divider";
import { Typography } from "components/ui/Typography";
import { useTranslation } from "react-i18next";
import { useObserverContext } from "context/observerContext";
import { TKeyDownOptions, useOnKeyDownEvent } from "hooks/useOnKeyDownEvent";
import { IntegrationLogJsonView } from "./components/IntegrationLogJsonView";
import { IntegrationLogGeneralInfo } from "./components/IntegrationLogGeneralInfo";
import { IntegrationLogActionFields } from "./components/IntegrationLogActionFields";
import { useStyles } from "./styles";
import type { TTimezone } from "utils/auditLogs/systemAuditLogTimezone";

const MIN_WIDTH_PX = 260;
const INITIAL_WIDTH_PX = 1128 + RESIZER_WIDTH_PX;

type TIntegrationLogsDetails = {
	integrationAuditLog?: SessionAuditLogModel;
	timezone: TTimezone;
	moveToNextLog: () => void;
	moveToPrevLog: () => void;
	setSelectedRow: React.Dispatch<React.SetStateAction<SessionAuditLogModel | undefined>>;
};

export const IntegrationLogDetails: FC<TIntegrationLogsDetails> = ({
	integrationAuditLog,
	timezone,
	setSelectedRow,
	moveToNextLog,
	moveToPrevLog
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams();
	const {
		state: { maxWidth },
		actions: { calculateObserver }
	} = useObserverContext();

	const integrationLogId = searchParams.get("integrationLogId") || undefined;

	const integrations = useIntegrations();
	const integration = integrations?.get(integrationAuditLog?.integrationId || "");
	const keyDownEventMap: TKeyDownOptions = useMemo(
		() =>
			integrationLogId
				? new Map([
						[
							"ArrowDown",
							(e: KeyboardEvent) => {
								moveToNextLog();
								e.preventDefault();
							}
						],
						[
							"ArrowUp",
							(e: KeyboardEvent) => {
								moveToPrevLog();
								e.preventDefault();
							}
						]
					])
				: new Map([]),
		[integrationLogId, moveToNextLog, moveToPrevLog]
	);

	useOnKeyDownEvent(keyDownEventMap);

	const closeSidebar = useCallback(() => {
		searchParams.delete("integrationLogId");
		setSearchParams(searchParams);
		setSelectedRow(undefined);
	}, [searchParams, setSearchParams, setSelectedRow]);

	useEffect(() => {
		calculateObserver();
	}, [calculateObserver]);

	return (
		<DraggableSidebar
			maxWidth={maxWidth}
			initialWidth={INITIAL_WIDTH_PX}
			minWidth={MIN_WIDTH_PX}
			open={Boolean(integrationLogId)}
			closeSidebar={closeSidebar}>
			<div className={classes.sidebarContent}>
				<div className={classes.title}>
					<Typography variant="title_sb">{t("common.integrationLogDetails.title")}</Typography>
				</div>
				<div className={classes.detailsContainer}>
					<IntegrationLogGeneralInfo
						integrationAuditLog={integrationAuditLog || undefined}
						integration={integration!}
						timezone={timezone}
					/>
					<IntegrationLogActionFields integrationAuditLog={integrationAuditLog} />
				</div>
				<Divider horizontal />
				<IntegrationLogJsonView integrationAuditLog={integrationAuditLog} />
			</div>
		</DraggableSidebar>
	);
};
