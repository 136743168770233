import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Tabs } from "components/ui/Tabs";
import { LoadingDots } from "components/ui/LoadingDots";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { EntitleLogo } from "components/ui/systemLogos/EntitleLogo";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { Drawer } from "components/templates/Drawer";
import { useIsOpenState } from "hooks/useIsOpenState";
import { SessionAuditLogsProvider } from "components/pages/AuditLogsPage/sessionAuditLogContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RequestDetails } from "components/common/RequestDetails";
import { useStyles } from "./styles";
import { AuditLogsProvider, useAuditLogsContext } from "./auditLogsContext";
import { EntitleLogsSection } from "./components/EntitleLogs";
import { IntegrationLogsSection } from "./components/IntegrationLogs";
import { IntegrationLogsFilters } from "./components/IntegrationLogs/components/IntegrationLogsFilters";

type TPageContentProps = {
	toggleDrawer?: (isOpen: boolean) => void;
	drawerIsOpen?: boolean;
};

const INTEGRATION_LOGS = "integrationLogs";
const ENTITLE_LOGS = "entitleLogs";

const PageContent: FC<TPageContentProps> = ({ toggleDrawer, drawerIsOpen }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState<string>(ENTITLE_LOGS);
	const navigate = useNavigate();
	const [urlSearchParams] = useSearchParams();

	const {
		state: { auditLogs, isLoadingAuditLogs, isLoadingIntegrations }
	} = useAuditLogsContext();

	const showAuditLogs = (auditLogs.length > 0 || !isLoadingAuditLogs) && !isLoadingIntegrations;
	const handleTabChange = useCallback(
		(tabId: string) => {
			if (tabId === ENTITLE_LOGS) {
				toggleDrawer?.(false);
			}
			navigate(`?tab=${tabId}`);
			setActiveTab(tabId);
		},
		[navigate, toggleDrawer]
	);

	const tabs = useMemo(() => {
		return [
			{
				id: ENTITLE_LOGS,
				Icon: getDynamicSizeIcon(<EntitleLogo />),
				header: t("pages.auditLog.tabs.entitleLogs"),
				content: showAuditLogs ? <EntitleLogsSection /> : null
			},
			{
				id: INTEGRATION_LOGS,
				Icon: IntegrationIcon,
				header: t("pages.auditLog.tabs.integrationLogs"),
				content: <IntegrationLogsSection toggleDrawer={toggleDrawer} drawerIsOpen={drawerIsOpen} />
			}
		];
	}, [t, showAuditLogs, toggleDrawer, drawerIsOpen]);

	useEffect(() => {
		const tabId = urlSearchParams.get("tab");
		if (!tabId) {
			navigate(`?tab=${ENTITLE_LOGS}`);
			setActiveTab(ENTITLE_LOGS);
		} else {
			setActiveTab(tabId);
		}
	}, [navigate, urlSearchParams]);

	return (
		<>
			<Tabs
				tabs={tabs}
				onChangeTab={handleTabChange}
				activeTab={activeTab}
				contentClassName={classes.tabsContentContainer}
			/>
			{(isLoadingAuditLogs || isLoadingIntegrations) && <LoadingDots className={classes.spinner} />}
		</>
	);
};

export const AuditLogsPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [pageRef, setPageRef] = useState<HTMLDivElement | null>(null);
	const { isOpen: openDrawer, setIsOpen: handleDrawer, close: closeDrawer } = useIsOpenState(false);

	return (
		<PageTitleContent innerRef={setPageRef} className={classNames(className)} noTransition>
			<SessionAuditLogsProvider>
				<Drawer open={openDrawer} size={600} parent={pageRef} className={classes.drawer}>
					<IntegrationLogsFilters closeDrawer={closeDrawer} />
				</Drawer>
				<PageTitleContent.Header>
					<PageTitleContent.Header.Bottom>
						<PageTitleContent.Header.Title title={t("pages.auditLog.title")} />
					</PageTitleContent.Header.Bottom>
				</PageTitleContent.Header>
				<PageTitleContent.Body className={classes.auditLogsPageContent}>
					<RequestDetails />
					<AuditLogsProvider>
						<PageContent toggleDrawer={handleDrawer} drawerIsOpen={openDrawer} />
					</AuditLogsProvider>
				</PageTitleContent.Body>
			</SessionAuditLogsProvider>
		</PageTitleContent>
	);
};
