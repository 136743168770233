import React, { useMemo } from "react";
import classNames from "classnames";
import { Typography } from "components/ui/legacy/Typography";
import { Unknown } from "components/ui/Unknown";
import { useIntegrations } from "hooks/useIntegrations";
import { useApplications } from "hooks/useApplications";
import { useStyles } from "./styles";

interface IProps {
	integrationId: string;
	name: string;
	resourceName: string;
}

export const RoleName: FC<IProps> = ({ className, id, innerRef, integrationId, name, resourceName }) => {
	const classes = useStyles();
	const integrations = useIntegrations(true);
	const applications = useApplications();
	const integration = integrations?.get(integrationId);
	const imageUrl = useMemo(
		() =>
			integration?.imageUrl ||
			applications?.get(integration?.applicationId || "")?.imageUrl ||
			applications?.find(application => application.adapterless)?.imageUrl,
		[applications, integration]
	);

	const backgroundInfo = resourceName + (integration ? ` - ${integration.name}` : "");

	return (
		<Typography innerRef={innerRef} id={id} className={classNames(classes.roleContainer, className)} component="div">
			<Unknown unknown={!integration}>
				<>
					{imageUrl && <img className={classNames(classes.integrationImage)} src={imageUrl} alt={integration?.name} />}
					<div className={classes.roleName}>
						<Typography component="div">{name}</Typography>
						<Typography component="div" variant="small">
							{backgroundInfo}
						</Typography>
					</div>
				</>
			</Unknown>
		</Typography>
	);
};
