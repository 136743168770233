import { LoadingDots } from "components/ui/LoadingDots";
import { useApprovalAlgorithm } from "hooks/useApprovalAlgorithm";
import { boldComponent } from "i18n";
import { ApprovalAlgorithmAuditLogModel } from "models/auditLogs";
import { IApprovalAlgorithmAuditLogDiffData } from "models/auditLogs/ApprovalAlgorithmAuditLogModel";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getAuditLogDataValues } from "utils/auditLogs/auditLogUtils";
import { AuditLogUser } from "../AuditLogUser";
import { useStyles } from "./styles";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const TRANSLATION_PREFIX = "pages.auditLog.auditLogList.approvalAlgorithm.";

export const ApprovalAlgorithmAuditLogContent: TAuditLogContentComponent<ApprovalAlgorithmAuditLogModel> = ({
	auditLog,
	logUser
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { approvalAlgorithm, loading } = useApprovalAlgorithm(auditLog.approvalAlgorithmId, true);

	const values = useMemo(
		() =>
			getAuditLogDataValues(auditLog.data || ({} as IApprovalAlgorithmAuditLogDiffData), [
				{
					currentValue: approvalAlgorithm?.name,
					valueField: "approvalAlgorithmName"
				}
			]),
		[approvalAlgorithm?.name, auditLog.data]
	);

	const props = useMemo(
		() => ({
			values,
			components: { bold: boldComponent, user: <AuditLogUser user={logUser} className={classes.userText} /> },
			i18nKey: auditLog.action ? (`${TRANSLATION_PREFIX}${auditLog.action}` as const) : ("shared.emptyString" as const)
		}),
		[auditLog.action, classes.userText, logUser, values]
	);

	return loading ? <LoadingDots /> : <Trans t={t} {...props} />;
};
