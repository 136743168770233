import { apiReq } from "utils/api/apiReq";
import { ApprovalAlgorithmModel, IApprovalAlgorithmSchema } from "models/ApprovalAlgorithmModel";
import { Map } from "immutable";

export async function getApprovalAlgorithms(): Promise<Map<string, ApprovalAlgorithmModel>> {
	const { data } = await apiReq("GET", "/v1/approvalAlgorithms");

	return Map(
		data.map((algo: unknown) => {
			const model = ApprovalAlgorithmModel.fromServerData(algo);

			return [model.id, model];
		})
	);
}

const toSchema = (approvalAlgorithm: ApprovalAlgorithmModel | IApprovalAlgorithmSchema): IApprovalAlgorithmSchema => {
	if (approvalAlgorithm instanceof ApprovalAlgorithmModel) {
		return approvalAlgorithm.toServerData();
	}
	return approvalAlgorithm;
};

export const createApprovalAlgorithm = async (approvalAlgorithm: ApprovalAlgorithmModel | IApprovalAlgorithmSchema) => {
	const schema = toSchema(approvalAlgorithm);
	const { id: _, ...body } = schema;
	const { data: newApprovalAlgorithm } = await apiReq("POST", "/v1/approvalAlgorithms", body);

	return ApprovalAlgorithmModel.fromServerData(newApprovalAlgorithm);
};

export const updateApprovalAlgorithm = async (approvalAlgorithm: ApprovalAlgorithmModel | IApprovalAlgorithmSchema) => {
	const schema = toSchema(approvalAlgorithm);
	const { id, ...body } = schema;
	const { data: updatedApprovalAlgorithm } = await apiReq("PUT", `/v1/approvalAlgorithms/${id}`, body);

	return ApprovalAlgorithmModel.fromServerData(updatedApprovalAlgorithm);
};

export const deleteApprovalAlgorithm = async (approvalAlgorithm: ApprovalAlgorithmModel) => {
	const { id } = approvalAlgorithm;
	return apiReq("DELETE", `/v1/approvalAlgorithms/${id}`);
};
