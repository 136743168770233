import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { TicketCommentModel } from "models/TicketCommentModel";
import { AddTicketComment } from "./components/AddTicketComment";
import { TicketActivityItem } from "./components/TicketActivityItem";
import { useStyles } from "./styles";
import { getActivities } from "./utils";
import type { TFullTicket } from "components/common/RequestDetails";

interface IProps {
	addComment: (comment: string, reload: boolean) => Promise<void>;
	updateComment: (comment: TicketCommentModel) => void;
	ticket: TFullTicket | null;
}

export const TicketActivity: FC<IProps> = ({ addComment, className, id, innerRef, ticket, updateComment }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const activities = useMemo(() => (ticket ? getActivities(ticket) : null), [ticket]);

	return (
		<div className={classNames(classes.activity, className)} id={id} ref={innerRef}>
			{ticket ? (
				<>
					<div className={classes.activityTimeline}>
						<Typography variant="title_sb" className={classes.activityTitle}>
							{t("common.ticketActivity.title")}
						</Typography>
						<div className={classes.activityTimelineList}>
							{activities?.map((activity, index) => (
								<TicketActivityItem
									updateComment={updateComment}
									key={index}
									activity={activity}
									ticket={ticket}
									showLine={index + 1 < activities.length}
								/>
							))}
						</div>
					</div>
					<AddTicketComment className={classes.activityTimelineAddComment} addComment={addComment} ticket={ticket} />
				</>
			) : null}
		</div>
	);
};
