import { Record } from "immutable";
import uniq from "lodash/uniq";
import {
	getMaintainerPossibleValue,
	validateFilterEqualityOperator,
	validateFilterInclusionOperator
} from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationMaintainer" as const;

const defaults = {
	name: filterName,
	value: [] as string[] | null,
	operator: "contains" as "contains" | "is" | "isNot" | "notContains"
};

type TDefaults = typeof defaults;

export class IntegrationMaintainerFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationMaintainerFilter | null {
		const integrationMaintainer = uniq(urlSearchParams.getAll("integrationMaintainer"));
		const integrationMaintainerOperator = urlSearchParams.get("integrationMaintainerOperator") || undefined;
		if (
			integrationMaintainer.length === 0 ||
			!(
				validateFilterEqualityOperator(integrationMaintainerOperator) ||
				validateFilterInclusionOperator(integrationMaintainerOperator)
			)
		) {
			return null;
		}
		return new IntegrationMaintainerFilter({
			value: getMaintainerPossibleValue(integrationMaintainer),
			operator: integrationMaintainerOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (this.value === null) {
			params.append("integrationMaintainer", "null");
		} else if (!this.value.length) {
			return params;
		} else {
			for (const value of uniq(this.value)) {
				params.append("integrationMaintainer", value);
			}
		}
		params.append("integrationMaintainerOperator", this.operator);
		return params;
	}
}
