import React, { useCallback, useMemo } from "react";
import { PieChart, Pie, ResponsiveContainer, Legend } from "recharts";
import sumBy from "lodash/sumBy";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/legacy/Typography";
import { useRemToPx } from "hooks/useRemToPx";
import { AccessReviewModel } from "models/AccessReviewModel";
import { IEntitlementsStatistics } from "utils/accessReview";
import { useStyles } from "./styles";

const statusesPrefix = "common.accessReview.statuses";
const statisticsPrefix = "pages.accessReview.statistics";

interface IProps {
	accessReview: AccessReviewModel;
}

interface IReportStatistics {
	done: number;
	pending: number;
	inProgress: number;
}

interface IChartData {
	name: string;
	value: number;
	className?: string;
}

export const AccessReviewStatistics: FC<IProps> = ({ accessReview, className, id, innerRef }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const remToPx = useRemToPx();

	const mergedEntitlementsStatistics: IEntitlementsStatistics = useMemo(() => {
		const defaultStatistics = { pending: 0, approved: 0, denied: 0, flagged: 0 };
		if (!accessReview || !accessReview.statistics) {
			return defaultStatistics;
		}
		return Object.assign({}, defaultStatistics, accessReview.statistics.permissionsStatus);
	}, [accessReview]);

	const mergedReviewStatistics: IReportStatistics = useMemo(() => {
		const defaultStatistics = { done: 0, pending: 0, inProgress: 0 };
		if (!accessReview) {
			return defaultStatistics;
		}
		return { ...defaultStatistics, ...accessReview.statistics?.reportsStatus };
	}, [accessReview]);

	const entitlementsChartData: IChartData[] = useMemo(
		() => [
			{
				name: t(`${statusesPrefix}.permissions.approved`),
				value: mergedEntitlementsStatistics?.approved || 0,
				fill: "var(--color-green)"
			},
			{
				name: t(`${statusesPrefix}.permissions.denied`),
				value: mergedEntitlementsStatistics?.denied || 0,
				fill: "var(--color-red)"
			},
			{
				name: t(`${statusesPrefix}.permissions.flagged`),
				value: mergedEntitlementsStatistics?.flagged || 0,
				fill: "var(--color-grey-dark)"
			},
			{
				name: t(`${statusesPrefix}.permissions.pending`),
				value: mergedEntitlementsStatistics?.pending || 0,
				fill: "var(--color-yellow)"
			}
		],
		[mergedEntitlementsStatistics, t]
	);

	const reportChartData: IChartData[] = useMemo(
		() => [
			{
				name: t(`${statusesPrefix}.subordinates.done`),
				value: mergedReviewStatistics?.done || 0,
				fill: "var(--color-green)"
			},
			{
				name: t(`${statusesPrefix}.subordinates.inProgress`),
				value: mergedReviewStatistics?.inProgress || 0,
				fill: "var(--color-purple-pastel)"
			},
			{
				name: t(`${statusesPrefix}.subordinates.pending`),
				value: mergedReviewStatistics?.pending || 0,
				fill: "var(--color-yellow)"
			}
		],
		[mergedReviewStatistics, t]
	);

	const totalEntitlementCount = sumBy(entitlementsChartData, "value");

	const totalReportCount = sumBy(reportChartData, "value");

	const legendFormatter = useCallback(
		(value: string, entry: unknown & { payload?: Record<string, unknown> & { value?: number } }) => (
			<Typography relative className={classes.legendText}>{`${entry.payload?.value} ${value}`}</Typography>
		),
		[classes.legendText]
	);

	const sizes = useMemo(
		() => ({
			container: remToPx(20),
			outer: remToPx(3.5),
			inner: remToPx(2.5),
			icon: remToPx(1)
		}),
		[remToPx]
	);

	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			<div className={classes.labelContainer}>
				<Typography variant="h1">{totalEntitlementCount}</Typography>
				<Typography className={classes.labelText}>
					{t(`${statisticsPrefix}.entitlementsReviewed`, {
						count: totalEntitlementCount
					})}
				</Typography>
			</div>
			<ResponsiveContainer width={sizes.container} height="100%">
				<PieChart>
					<Pie
						data={entitlementsChartData}
						outerRadius={sizes.outer}
						innerRadius={sizes.inner}
						nameKey="name"
						dataKey="value"
					/>
					<Legend
						iconType="circle"
						layout="vertical"
						align="right"
						verticalAlign="middle"
						iconSize={sizes.icon}
						formatter={legendFormatter}
					/>
				</PieChart>
			</ResponsiveContainer>
			<div className={classes.spacer} />
			<div className={classes.labelContainer}>
				<Typography variant="h1">{totalReportCount}</Typography>
				<Typography className={classes.labelText}>
					{t(`${statisticsPrefix}.reportersReviewed`, {
						count: totalReportCount
					})}
				</Typography>
			</div>
			<ResponsiveContainer width={sizes.container} height="100%">
				<PieChart>
					<Pie
						data={reportChartData}
						outerRadius={sizes.outer}
						innerRadius={sizes.inner}
						nameKey="name"
						dataKey="value"
					/>
					<Legend
						iconType="circle"
						layout="vertical"
						align="right"
						verticalAlign="middle"
						iconSize={sizes.icon}
						formatter={legendFormatter}
					/>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};
