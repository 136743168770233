import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceWorkflow" as const;

const defaults = {
	name: filterName,
	value: [] as (string | null)[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationResourceWorkflowFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceWorkflowFilter | null {
		const integrationResourceWorkflow = uniq(
			urlSearchParams.getAll("integrationResourceWorkflow").map(value => value || null)
		);
		const integrationResourceWorkflowOperator = urlSearchParams.get("integrationResourceWorkflowOperator") || undefined;
		if (
			integrationResourceWorkflow.length === 0 ||
			!validateFilterEqualityOperator(integrationResourceWorkflowOperator)
		) {
			return null;
		}
		return new IntegrationResourceWorkflowFilter({
			value: integrationResourceWorkflow,
			operator: integrationResourceWorkflowOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceWorkflow", value || "null");
		}
		params.append("integrationResourceWorkflowOperator", this.operator);
		return params;
	}
}
