import { OptionWithIcon } from "components/common/OptionWithIcon";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { IntegrationChip } from "components/ui/chips/IntegrationChip";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Set } from "immutable";
import { TIntegrationIdFilter, OPERATORS } from "filters/integration/integrationIdFilter";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import type { TFilterOperator } from "types/filters";

type TOperator = (typeof OPERATORS)[number];
type TIntegrationIdFilterExpressionProps = {
	updateFilter: (condition: TIntegrationIdFilter) => unknown;
	filter: TIntegrationIdFilter;
};
export const IntegrationIdFilterExpression: FC<TIntegrationIdFilterExpressionProps> = ({
	className,
	innerRef,
	updateFilter,
	filter
}) => {
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const [selectedOperator, setSelectedOperator] = useState<TOperator>(filter.operator);
	const options = useMemo(() => integrations?.toIndexedSeq().toArray() ?? [], [integrations]);
	const normalizedCondition = useMemo(
		() => ({ operator: selectedOperator, value: Set(filter.value) }),
		[filter, selectedOperator]
	);
	const onReset = useCallback(() => updateFilter({ operator: "is", value: [] }), [updateFilter]);

	useEffect(() => {
		setSelectedOperator(filter.operator);
	}, [filter.operator]);

	const removeSelectedIntegration = useCallback(
		(integration: IntegrationModel) => {
			normalizedCondition.value.size === 1
				? onReset()
				: updateFilter({
						...normalizedCondition,
						value: normalizedCondition.value.delete(integration.id).toArray()
					});
		},
		[normalizedCondition, updateFilter, onReset]
	);

	const toggleIntegrationSelection = useCallback(
		(integration: IntegrationModel) => {
			if (normalizedCondition.value.has(integration.id)) return removeSelectedIntegration(integration);
			updateFilter({
				...normalizedCondition,
				value: normalizedCondition.value.add(integration.id).toArray()
			});
		},
		[normalizedCondition, updateFilter, removeSelectedIntegration]
	);

	const renderOption = useCallback(
		(option: IntegrationModel) => <OptionWithIcon imageUrl={option.imageUrl}>{option.name}</OptionWithIcon>,
		[]
	);

	const renderSelected = useCallback(
		(integration: IntegrationModel) => (
			<IntegrationChip
				selected
				size="large"
				onDelete={() => removeSelectedIntegration(integration)}
				integration={integration}
			/>
		),
		[removeSelectedIntegration]
	);

	const onUpdateSelectedOperator = useCallback(
		(operator: TFilterOperator) =>
			filter.value.length
				? updateFilter({ value: normalizedCondition.value.toArray(), operator: operator as TOperator })
				: setSelectedOperator(operator as TOperator),
		[normalizedCondition, updateFilter, filter]
	);

	const selectedIntegrations = useMemo(
		() =>
			normalizedCondition.value.map(integrationId => options.find(option => option.id === integrationId)!).toArray(),
		[normalizedCondition.value, options]
	);

	const getOptionLabel = useCallback((option: IntegrationModel) => option.name, []);

	return (
		<FilterExpression
			className={className}
			innerRef={innerRef}
			title={t("pages.createRule.ruleDefinition.applyTo.filterExpressionTitle")}
			type="multiSelect"
			relation="or"
			onReset={onReset}
			emptyState={
				<FilterExpressionEmptyState
					text={t("pages.createRule.ruleDefinition.applyTo.allIntegrations")}
					Icon={IntegrationIcon}
				/>
			}
			inputPlaceholder={t("filters.placeholders.integration")}
			selectedOperator={normalizedCondition.operator ?? "is"}
			operators={OPERATORS}
			renderOption={renderOption}
			renderSelected={renderSelected}
			options={options}
			getOptionLabel={getOptionLabel}
			selected={selectedIntegrations}
			onOperatorSelect={onUpdateSelectedOperator}
			onOptionSelect={toggleIntegrationSelection}
		/>
	);
};
