import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { VirtualRequestList } from "components/common/RequestList";
import { getUserPastTickets } from "api/user";
import { useSortState } from "hooks/useSortState";
import { usePagination } from "hooks/usePagination";
import { TicketFiltersModal } from "components/common/TicketFilters";
import { useIsOpenState } from "hooks/useIsOpenState";
import { Button } from "components/ui/Button";
import { FilterIcon } from "components/ui/Icons/FilterIcon";
import { RequestBar } from "components/common/RequestBar";
import { DropdownSortButton } from "components/ui/DropdownSortButton";
import { RequestDetails } from "components/common/RequestDetails";
import { Skeleton } from "components/ui/Skeleton";
import { REQUEST_BAR_HEIGHT } from "components/common/RequestBar/styles";
import { TicketRenewalProvider } from "context/renewalTicketContext";
import type { TSearchTicketsParams } from "api/tickets";
import type { TicketModel } from "models/TicketModel";

const REQUESTS_PAGE_SIZE = 20;

const notNothing = (value: unknown) => {
	if (typeof value === "string") return value !== "";
	if (Array.isArray(value)) return value.length > 0;
	if (typeof value === "undefined") return false;
	return value && Object.values(value).some(notNothing);
};

export const PastRequestsPage: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const { close, isOpen, open } = useIsOpenState();
	const sortState = useSortState({ defaultSortField: "ticketNumber", defaultSortOrder: "DESC" });
	const [requestFilters, setRequestFilters] = useState<TSearchTicketsParams>({});
	const { itemsForVirtualTable, getPage, totalResults } = usePagination({
		fetchItems: getUserPastTickets,
		perPage: REQUESTS_PAGE_SIZE,
		sortOrder: sortState.sortOrder,
		sortFields: sortState.sortFields,
		body: requestFilters
	});
	const requests = useMemo(() => itemsForVirtualTable ?? [], [itemsForVirtualTable]);

	const activeFilters = useMemo(() => {
		const { target, ...restFilters } = requestFilters;
		const baseFiltersAmount = Object.values(restFilters).filter(notNothing).length;
		const targetFiltersAmount = Object.values(target ?? {}).filter(notNothing).length;
		const totalAmount = baseFiltersAmount + targetFiltersAmount;
		return totalAmount > 0 ? ` (${totalAmount})` : "";
	}, [requestFilters]);

	const renderRequest = useCallback((request?: TicketModel) => {
		if (!request) return <Skeleton height={REQUEST_BAR_HEIGHT} />;
		return <RequestBar key={request.id} request={request} type="past" openSidebarOnClick />;
	}, []);

	const sortOptions = useMemo(() => {
		return [
			{
				label: t("pages.pastRequests.sortOptions.ticketNumber"),
				value: "ticketNumber"
			},
			{
				label: t("pages.pastRequests.sortOptions.duration"),
				value: "duration"
			},
			{
				label: t("pages.pastRequests.sortOptions.status"),
				value: "status"
			},
			{
				label: t("pages.pastRequests.sortOptions.receiver"),
				value: "receiver"
			}
		];
	}, [t]);

	const breadcrumbs = useMemo(() => {
		return [
			{
				title: t("navigation.requests"),
				url: "/requests"
			},
			{
				title: t("pages.pastRequests.title")
			}
		];
	}, [t]);

	return (
		<TicketRenewalProvider>
			<PageTitleContent innerRef={innerRef} className={classNames(className)}>
				<RequestDetails />
				<PageTitleContent.Header>
					<PageTitleContent.Header.Top breadcrumbs={breadcrumbs} />
					<PageTitleContent.Header.Bottom>
						<PageTitleContent.Header.Title title={t("pages.pastRequests.title")} />
						<PageTitleContent.Header.Actions>
							<DropdownSortButton sortState={sortState} options={sortOptions} />
							<Button
								size="medium"
								variant={activeFilters ? "secondary" : "primary"}
								prefix={<FilterIcon />}
								onClick={open}>
								{t("buttons.filters") + activeFilters}
							</Button>
						</PageTitleContent.Header.Actions>
					</PageTitleContent.Header.Bottom>
				</PageTitleContent.Header>
				<PageTitleContent.Body>
					<TicketFiltersModal
						isOpen={isOpen}
						onClose={close}
						onFiltersChange={setRequestFilters}
						ticketFilters={requestFilters}
					/>
					<VirtualRequestList
						fetchPage={getPage}
						fullHeight
						perPage={REQUESTS_PAGE_SIZE}
						requestRenderer={renderRequest}
						requests={requests}
						totalRequests={totalResults}
					/>
				</PageTitleContent.Body>
			</PageTitleContent>
		</TicketRenewalProvider>
	);
};
