import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actionsContainer: {
		display: "flex"
	},
	container: {
		alignSelf: "stretch",
		display: "grid",
		gridTemplateColumns: "auto",
		gridTemplateRows: "auto 1fr auto"
	},
	statusContainer: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-end"
	}
});
