import React from "react";
import classNames from "classnames";
import { Entity } from "components/common/Entity";
import { TOnCallType } from "models/OnCallIntegrationScheduleModel";
import { ON_CALL_INTEGRATIONS_LOGOS } from "utils/ui/onCall";
import { useStyles } from "./styles";

interface IProps {
	isDeleted?: boolean;
	onCallType?: TOnCallType;
	iconClassName?: string;
	value: string;
}

export const OnCallIntegrationScheduleLabel: FC<IProps> = ({
	onCallType,
	iconClassName,
	value,
	className,
	isDeleted
}) => {
	const classes = useStyles();
	const Icon = onCallType && ON_CALL_INTEGRATIONS_LOGOS.get(onCallType);

	return (
		<div className={classNames(classes.sourceIconContainer, className)}>
			<Entity
				isDeleted={isDeleted}
				content={value}
				prefixIcon={Icon ? <Icon className={classNames(classes.sourceIcon, iconClassName)} /> : undefined}
			/>
		</div>
	);
};
