import React from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationLogsFields } from "api/sessionAuditLogs";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TSubjectFilterExpressionProps = {
	onRemove: () => void;
};

export const SubjectFilterExpression: FC<TSubjectFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();

	return (
		<IntegrationLogsMultiSelectFilterExpression
			title={t("pages.auditLog.integrationLogs.filter.subject")}
			fetchOptions={getIntegrationLogsFields}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.subjectPlaceholder")}
			filterEmptyState={
				<FilterExpressionEmptyState text={t("pages.auditLog.integrationLogs.filter.subjectEmptyState")} />
			}
			onFilterRemove={onRemove}
			filterField="subject_display_name"
			filterName="subject"
		/>
	);
};
