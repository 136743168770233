import { Typography } from "components/ui/legacy/Typography";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/ui/Table";
import { CommonAccordion } from "components/common/CommonAccordion";
import { useLocalPagination } from "hooks/useLocalPagination";
import { PageSelect } from "components/ui/PageSelect";
import { List } from "immutable";
import { IsNullError } from "utils/errors/isNullError";
import { UsersIcon } from "components/ui/Icons/UsersIcon";
import { useMultiUsers } from "hooks/useMultiUsers";
import { EntitlementRow } from "./EntitlementRow";
import type { IEntitlementsByUser } from "../../utils";

interface IProps {
	entitlementsByUsers: List<IEntitlementsByUser>;
}

const TRANSLATION_PREFIX = "pages.integrationResource" as const;

export const EntitlementsTable: FC<IProps> = ({ entitlementsByUsers }) => {
	const { t } = useTranslation();

	const sortedEntitlementsByUsers = useMemo(() => {
		return entitlementsByUsers.sort((entitlementsByUser, _) => (entitlementsByUser.userId === null ? 1 : -1));
	}, [entitlementsByUsers]);

	const { page, currentPageNumber, setCurrentPage, totalPages } = useLocalPagination(sortedEntitlementsByUsers);
	const entitlementUserIds = useMemo(
		() => page?.map(entitlementByUser => entitlementByUser.userId).toArray() || [],
		[page]
	);
	const entitlementUsers = useMultiUsers(entitlementUserIds);

	const entitlementsCount = useMemo(() => {
		return entitlementsByUsers.reduce((prevCount, entitlement) => {
			return prevCount + entitlement.entitlements.size;
		}, 0);
	}, [entitlementsByUsers]);

	const headers: string[] = [
		t(`${TRANSLATION_PREFIX}.user`),
		t(`${TRANSLATION_PREFIX}.account`),
		t(`${TRANSLATION_PREFIX}.role`),
		t(`${TRANSLATION_PREFIX}.source`),
		t(`${TRANSLATION_PREFIX}.requests`),
		t(`${TRANSLATION_PREFIX}.expires`),
		t(`${TRANSLATION_PREFIX}.lastUsed`)
	];

	return (
		<CommonAccordion
			rounded
			noMargin
			title={
				<Typography variant="h3" prefixIcon={<UsersIcon />}>
					{entitlementsCount} {t("pages.integrationResource.entitlements")}
				</Typography>
			}>
			<Table gridColumns="3fr 2fr 2fr 4fr 2fr 2fr 2fr">
				<Table.Row>
					{headers.map(title => (
						<Table.Header key={title}>
							<Typography>{title}</Typography>
						</Table.Header>
					))}
				</Table.Row>
				{page.map(entitlementByUser => {
					const { userId, entitlements } = entitlementByUser;
					const user = userId ? entitlementUsers?.get(userId) : null;
					if (userId && !user && entitlementUsers) {
						throw IsNullError.from({
							location: "EntitlementsTable",
							parentObject: {
								name: "users",
								value: entitlementUsers
							},
							requestedProperty: `user - ${userId}`
						});
					}
					return entitlements.map((entitlement, i) => {
						return (
							<EntitlementRow index={i} entitlement={entitlement} user={user} key={(user?.id || "userless") + i} />
						);
					});
				})}
			</Table>
			{totalPages > 1 && (
				<PageSelect
					changePage={setCurrentPage}
					currentPageNumber={currentPageNumber}
					pagesCount={totalPages}
					pagesShownAmount={3}
				/>
			)}
		</CommonAccordion>
	);
};
