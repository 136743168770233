import { apiReq } from "utils/api/apiReq";
import { List } from "immutable";
import { AccessReviewModel } from "models/AccessReviewModel";
import { withPagination } from "utils/pagination";
import { TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";
import { notEmpty } from "utils/comparison";
import { AccessReviewSubordinatePermissionModel } from "models/AccessReviewSubordinatePermissionModel";
import { AccessReviewResourcePermissionModel } from "models/AccessReviewResourcePermissionModel";

export const getAccessReviews = async (): Promise<List<AccessReviewModel>> => {
	const { data } = await apiReq("GET", "/v1/accessReviews");

	return List<AccessReviewModel>(data.map(AccessReviewModel.fromServerData));
};

export const createAccessReview = async (
	name: string,
	description: string,
	immediateRevoke: boolean,
	templateId: string | null = null
): Promise<AccessReviewModel> => {
	const { data: accessReview } = await apiReq("POST", "/v1/accessReviews", {
		templateId,
		name,
		description,
		immediateRevoke
	});

	return AccessReviewModel.fromServerData(accessReview);
};

export const editAccessReview = async (id: string, accessReviewChanges: Partial<AccessReviewModel>) => {
	const { data: accessReview } = await apiReq("PUT", `/v1/accessReviews/${id}`, accessReviewChanges);

	return AccessReviewModel.fromServerData(accessReview);
};

export const getAccessReview = async (id: string): Promise<AccessReviewModel> => {
	const { data: accessReview } = await apiReq("GET", `/v1/accessReviews/${id}`);

	return AccessReviewModel.fromServerData(accessReview);
};

export const deleteAccessReview = async (id: string) => {
	return apiReq("DELETE", `/v1/accessReviews/${id}`);
};

export const finishAccessReview = async (id: string) => {
	return apiReq("PUT", `/v1/accessReviews/${id}/finish`);
};

export const getAccessReviewPermissions = async (
	id: string,
	paginationOptions?: IPaginatedSearchOptions,
	status?: TAccessReviewPermissionStatus
) => {
	const paginationQs = getPaginatedSearchQueryString(paginationOptions);
	let url = `/v1/accessReviews/${id}/permissions`;
	const queryStrings = [paginationQs, status ? `status=${status}` : undefined].filter(notEmpty);
	url += queryStrings.length > 0 ? `?${queryStrings.join("&")}` : "";
	return withPagination(
		(subjectPermission: Record<string, unknown>) =>
			subjectPermission?.accessReviewSubordinateId
				? AccessReviewSubordinatePermissionModel.fromServerData(subjectPermission)
				: AccessReviewResourcePermissionModel.fromServerData(subjectPermission),
		() => apiReq("GET", url)
	);
};

export const activateAccessReview = async (id: string) => {
	const { data: accessReview } = await apiReq("PUT", `/v1/accessReviews/${id}/activate`);

	return AccessReviewModel.fromServerData(accessReview);
};
