import React, { StrictMode, useCallback, useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoginWall } from "components/App/components/LoginWall";
import { PageProvider } from "context/pageContext";
import { PageProvider as NewPageProvider } from "context/new/pageContext";
import { compiledClientConfig } from "config";
import { logrocketLogError } from "utils/logrocket";
import { useGlobalStyles } from "globalStyles";
import { CLITokenGenerationPage } from "components/pages/CLITokenGenerationPage";
import { BetaContext, useIsBeta } from "context/betaContext";
import { useCompanyContext } from "context/companyContext";
import { devLog } from "utils/devtools/devLogging";
import { Layout } from "./components/Layout";
import { useStyles } from "./styles";
import { MicrosoftTeamsRouter } from "./components/MicrosoftTeamsRouter";
import { GlobalErrorModal } from "./components/GlobalErrorModal";
import { Providers } from "./Providers";

const getLastCommitHash = async () => {
	const data = await (await fetch("/deploymentInfo")).json();
	return data["COMMIT_HASH"] as string;
};

const useKeepAppUpToDate = (production: boolean) => {
	const lastCommitHashRef = useRef<string>();
	const intervalRef = useRef<number>();

	const update = useCallback(async () => {
		try {
			lastCommitHashRef.current = await getLastCommitHash();

			if (intervalRef.current) window.clearInterval(intervalRef.current);
			intervalRef.current = window.setInterval(async () => {
				try {
					if (lastCommitHashRef.current !== (await getLastCommitHash())) {
						window.location.reload();
					}
				} catch (e) {
					devLog({ message: "failed to fetch last commit hash, will try again soon", level: "error" });
					logrocketLogError(
						e instanceof Error ? e : new Error("failed to fetch last commit hash, will try again soon")
					);
				}
			}, compiledClientConfig.upToDateCheckInterval);
		} catch (e) {
			devLog({ message: "failed to fetch last commit hash, client won't refresh automatically", level: "error" });
			logrocketLogError(
				e instanceof Error ? e : new Error("failed to fetch last commit hash, client won't refresh automatically")
			);
		}
	}, []);

	useEffect(() => {
		if (production) update();
		return () => {
			if (intervalRef.current) window.clearInterval(intervalRef.current);
		};
	}, [production, update]);
};

const Routers: FC = () => {
	const {
		actions: { setBeta }
	} = BetaContext();
	const isBeta = useIsBeta();
	const {
		state: { company }
	} = useCompanyContext(); // we need to use the context here instead of the hook to prevent refetching the company on signout

	useEffect(() => {
		if (company?.forceBeta) {
			setBeta(true);
		}
	}, [company?.forceBeta, setBeta]);

	const shouldAllowBeta = isBeta && !company?.forceBeta;
	// we need the key to rerender after moving to beta mode
	return (
		<BrowserRouter basename={shouldAllowBeta ? "/beta" : undefined} key={shouldAllowBeta ? "beta" : "notBeta"}>
			<PageProvider>
				<NewPageProvider>
					<MicrosoftTeamsRouter>
						<LoginWall>
							<Routes>
								<Route path="/cliLogin" key={"CLILoginGeneration"} element={<CLITokenGenerationPage />} />
								<Route path="*" element={<Layout />} />
							</Routes>
						</LoginWall>
					</MicrosoftTeamsRouter>
				</NewPageProvider>
			</PageProvider>
		</BrowserRouter>
	);
};

export default function App() {
	const { t } = useTranslation();
	const classes = useStyles();
	useGlobalStyles();
	useKeepAppUpToDate(compiledClientConfig.isProd);

	useEffect(() => {
		const direction = t("direction");

		document.body.classList.toggle("ltr", direction === "ltr");
		document.body.classList.toggle("rtl", direction === "rtl");
	}, [t]);

	return (
		<div className={classes.app}>
			<StrictMode>
				<Providers>
					<GlobalErrorModal />
					<Routers />
				</Providers>
			</StrictMode>
		</div>
	);
}
