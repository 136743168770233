import React from "react";
import { List } from "immutable";
import { UserVertexModel } from "models/IdentityGraph/UserVertexModel";
import { useIdentityGraphContext } from "components/pages/IdentityGraphPage/identityGraphContext";
import { UserVertex } from "../Vertices/UserVertex";
import { useSingularColumn } from "./columnsHooks";

type TProps = {
	refSetter: (ids: string[]) => (element: unknown) => void;
	vertices: List<UserVertexModel>;
};

export const UsersColumn: FC<TProps> = ({ refSetter, vertices }) => {
	const { decrease, extraVerticesIds, increase, showCollapse, showedVertices, maxVertices, finalMaxEntities } =
		useSingularColumn(vertices);
	const {
		actions: { isUserSelected }
	} = useIdentityGraphContext();

	if (!vertices || !showedVertices) return null;
	const showedUserVertices = showedVertices as UserVertexModel[];
	return (
		<>
			{showedUserVertices.map(vertex => (
				<UserVertex
					selected={isUserSelected(vertex.id)}
					key={vertex.id}
					userId={vertex.entity.userId}
					refSetter={refSetter}
				/>
			))}
			{extraVerticesIds?.length ? (
				<UserVertex
					key={extraVerticesIds.join(",")}
					userIds={extraVerticesIds}
					onExpand={increase}
					onCollapse={maxVertices > finalMaxEntities ? decrease : undefined}
					refSetter={refSetter}
				/>
			) : null}
			{showCollapse ? <UserVertex collapse onCollapse={decrease} /> : null}
		</>
	);
};
