import React from "react";
import { IntegrationFilterExpression } from "../IntegrationFilterExpression";
import { ActionFilterExpression } from "../ActionFilterExpression";
import { AccountFilterExpression } from "../AccountFilterExpression";
import { SubjectFilterExpression } from "../SubjectFilterExpression";
import { TargetsFilterExpression } from "../TargetFilterExpression";
import { StatusFilterExpression } from "../StatusFilterExpression";
import { UserFilterExpression } from "../UserFilterExpression";
import { EntitleLogsFilterExpression } from "../EntitleLogsFilterExpression";
import { DateTimeFilterExpression } from "../DateTimeFilterExpression";

type TFilterExpressionFactoryProps = {
	expressionType: string;
	onRemove: () => void;
};

export const FilterExpressionFactory: FC<TFilterExpressionFactoryProps> = ({ expressionType, onRemove }) => {
	switch (expressionType) {
		case "Date & Time":
			return <DateTimeFilterExpression onRemove={onRemove} />;
		case "Action":
			return <ActionFilterExpression onRemove={onRemove} />;
		case "Account":
			return <AccountFilterExpression onRemove={onRemove} />;
		case "Integration":
			return <IntegrationFilterExpression onRemove={onRemove} />;
		case "Subject":
			return <SubjectFilterExpression onRemove={onRemove} />;
		case "Target":
			return <TargetsFilterExpression onRemove={onRemove} />;
		case "Status":
			return <StatusFilterExpression onRemove={onRemove} />;
		case "User":
			return <UserFilterExpression onRemove={onRemove} />;
		case "Entitle logs":
			return <EntitleLogsFilterExpression onRemove={onRemove} />;
		default:
			return null;
	}
};
