import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "components/ui/Section";
import { BasicCard } from "components/ui/BasicCard";
import { Typography } from "components/ui/Typography";
import { MODEL_TO_ALLOWED_FILTERS } from "components/pages/BulkActionsPage/utils";
import { useStyles } from "./styles";
import type { TFilterName } from "filters";
import type { TBulkActionTabOption } from "components/pages/BulkActionsPage/types";

type TProps = {
	type: TBulkActionTabOption;
	filters: TFilterName[];
	selectFilter: (filterName: TFilterName) => void;
};

export const FilterSelectionSection: FC<TProps> = ({ className, innerRef, filters, selectFilter, type }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions" });
	const classes = useStyles();

	const availableFilters = useMemo(() => MODEL_TO_ALLOWED_FILTERS.get(type) ?? [], [type]);

	return (
		<Section className={className} innerRef={innerRef} expanded title={t("filterForm.filterSelectionSection.title")}>
			<div className={classes.filters}>
				{availableFilters.map(filterName => (
					<BasicCard
						size="medium"
						selected={filters.includes(filterName)}
						key={filterName}
						onClick={() => selectFilter(filterName)}>
						<Typography variant="body_sb">{t(`filters.title.${filterName}`)}</Typography>
					</BasicCard>
				))}
			</div>
		</Section>
	);
};
