import React, { useMemo } from "react";
import classNames from "classnames";
import { Chips } from "components/ui/MultipleSelect/components/Chips";
import { Typography } from "components/ui/legacy/Typography";
import { useStyles } from "./styles";

interface IEntityOptionProps {
	entity: {
		name: string;
		description: string | null;
		tags: string[] | null;
		userDefinedDescription?: string | null;
		userDefinedTags?: string[] | null;
	};
	tagsLimit?: number;
	disabled?: boolean;
}

const getOptionLabel = (option: string) => option;

export function EntityOption({ entity, tagsLimit = 6, disabled = false }: IEntityOptionProps) {
	const classes = useStyles();
	const tags = useMemo(
		() => [...(entity.tags || []), ...(entity.userDefinedTags || [])],
		[entity.tags, entity.userDefinedTags]
	);
	const description = entity.userDefinedDescription || entity.description;
	return (
		<Typography>
			<div>{entity.name}</div>
			{description && (
				<div className={classNames(classes.entityDescription, { [classes.disabled]: disabled })}>{description}</div>
			)}
			{tags && (
				<div className={classes.entityTagsContainer}>
					<Chips
						values={tags}
						type="tag"
						limitChipType="tag"
						size="small"
						limit={tagsLimit}
						getOptionLabel={getOptionLabel}
					/>
				</div>
			)}
		</Typography>
	);
}
