import { Record } from "immutable";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceRoleAllowRequests" as const;

const defaults = {
	name: filterName,
	value: false as boolean,
	operator: "is" as const
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleAllowRequestsFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceRoleAllowRequestsFilter | null {
		const integrationResourceRoleAllowRequests = urlSearchParams.get("integrationResourceRoleAllowRequests");
		const integrationResourceRoleAllowRequestsOperator =
			urlSearchParams.get("integrationResourceRoleAllowRequestsOperator") || undefined;
		if (
			integrationResourceRoleAllowRequests === null ||
			(typeof integrationResourceRoleAllowRequestsOperator === "string" &&
				integrationResourceRoleAllowRequestsOperator !== "is")
		) {
			return null;
		}
		return new IntegrationResourceRoleAllowRequestsFilter({
			value: integrationResourceRoleAllowRequests === "true",
			operator: integrationResourceRoleAllowRequestsOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		params.append("integrationResourceRoleAllowRequests", this.value.toString());
		params.append("integrationResourceRoleAllowRequestsOperator", this.operator);
		return params;
	}
}
