import React, { useEffect, useState, useCallback } from "react";
import { JSONTextArea } from "components/common/JSONTextArea";
import { schemaToEmptyJson } from "utils/jsonSchema";
import type { ErrorObject } from "ajv";
import type EventEmitter from "events";
import type { TJsonSchema } from "types/utilTypes";

const DEFAULT_CONFIGURATION: TJsonSchema = { type: "object" };

const getAllowedValues = (params?: ErrorObject["params"]): string | null => {
	const enumParams = params as { allowedValues?: string[] };
	if (!enumParams || !enumParams.allowedValues) return null;
	return enumParams.allowedValues.join(", ");
};

interface IProps {
	actions?: JSX.Element;
	configuration?: TJsonSchema;
	disabled?: boolean;
	errors?: string[];
	onChange?: (data: Record<string, unknown>) => void;
	onError?: (errors: Partial<ErrorObject>[]) => void;
	readonly?: boolean;
	resetEventEmitter?: EventEmitter;
	value?: Record<string, unknown> | null;
}

export const IntegrationConfigurationInput: FC<IProps> = ({
	actions = undefined,
	className,
	configuration = DEFAULT_CONFIGURATION,
	disabled = false,
	errors: propErrors,
	onChange: userOnChange,
	onError: userOnError,
	readonly,
	resetEventEmitter,
	value: propValue
}) => {
	const [value, setValue] = useState<Record<string, unknown>>(propValue || {});
	const [configurationErrors, setConfigurationErrors] = useState<string[] | undefined>(propErrors);
	const [currentConfigurationSchema, setCurrentConfigurationSchema] = useState<TJsonSchema>(configuration);

	useEffect(() => {
		const handleReset = () => {
			const configurationSchemaToEmptyJson = schemaToEmptyJson(configuration);
			configurationSchemaToEmptyJson && configuration && setValue(configurationSchemaToEmptyJson);
			setConfigurationErrors(undefined);
		};

		resetEventEmitter?.on("reset", handleReset);

		return () => {
			resetEventEmitter?.off("reset", handleReset);
		};
	}, [configuration, resetEventEmitter]);

	useEffect(() => {
		if (configuration) {
			setCurrentConfigurationSchema(configuration);
			const configurationSchemaToEmptyJson = schemaToEmptyJson(configuration);
			configurationSchemaToEmptyJson && setValue(configurationSchemaToEmptyJson);
		}
	}, [configuration]);

	useEffect(() => {
		if (propErrors) {
			setConfigurationErrors(propErrors);
		}
	}, [propErrors]);

	const onError = useCallback(
		(errors: Partial<ErrorObject>[] | null) => {
			if (errors) {
				setConfigurationErrors(
					errors?.map(({ message, instancePath, params }) => {
						const path = instancePath ? instancePath.substring(1) + " " : "";
						const allowedValues = getAllowedValues(params);
						const allowedValuesMessage = allowedValues ? `: (${allowedValues})` : "";
						return `${path}${message || ""}${allowedValuesMessage}`;
					})
				);
				if (userOnError) userOnError(errors);
			} else {
				setConfigurationErrors(undefined);
			}
		},
		[userOnError]
	);

	if (!configuration) return null;

	return (
		<JSONTextArea
			actions={disabled ? undefined : actions}
			autoResize
			className={className}
			disabled={disabled}
			errors={configurationErrors}
			isRequired
			onChange={userOnChange}
			onError={onError}
			readonly={readonly}
			validationSchema={currentConfigurationSchema}
			value={value}
		/>
	);
};
