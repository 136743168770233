import React, { useCallback, useMemo } from "react";
import { Typography } from "components/ui/Typography";
import { IconPrefix } from "components/ui/IconPrefix";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { useTranslation } from "react-i18next";
import { IntegrationLogsSingleSelectFilterExpression } from "../IntegrationLogsSingleSelectFilterExpression";

type TStatusFilterExpressionProps = {
	onRemove: () => void;
};

export const StatusFilterExpression: FC<TStatusFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();
	const statuses = useMemo(
		() => [
			{
				value: "success",
				label: t("pages.auditLog.integrationLogs.filter.labels.success")
			},
			{
				value: "failure",
				label: t("pages.auditLog.integrationLogs.filter.labels.failure")
			}
		],
		[t]
	);

	const renderOption = useCallback((status: string) => {
		return (
			<Typography variant="body_sb">
				<IconPrefix content={status} Icon={status === "Failed" ? CloseCircleIcon : GrantedIcon} />
			</Typography>
		);
	}, []);

	return (
		<IntegrationLogsSingleSelectFilterExpression
			singleChoiceOptions={statuses || []}
			title={t("pages.auditLog.integrationLogs.filter.status")}
			onFilterRemove={onRemove}
			filterName="status"
			renderOption={renderOption}
			initialOperator="is"
		/>
	);
};
