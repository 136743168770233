import React, { useCallback, useEffect, useMemo, useState } from "react";
import get from "lodash/get";
import uniq from "lodash/uniq";
import { SyncButton } from "components/common/SyncButton";
import { sourceIcons } from "utils/ui/sourceIcons";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import capitalize from "lodash/capitalize";
import { useIsOpenState } from "hooks/useIsOpenState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { ExtraOptionsButton, TRequestAction, TSelectClickableOption } from "components/common/ExtraOptionsButton";
import { useDirectoryConfigurationsContext } from "context/directoryConfigurationsContext";
import { DirectoryConfigurationModel } from "models/DirectoryConfigurationModel";
import { sortByDisplayName } from "utils/sortUtils";
import { editDirectoryConfiguration } from "api/directoryConfigurations";
import { IntegrationRow } from "../IntegrationRow";
import { DirectoryConfigurationModal } from "./DirectoryConfigurationModal";
import { useStyles } from "./styles";
import type { TDirectory, TDirectorySchema } from "types/directoryConfiguration";

const DIRECTORIES: TDirectory[] = ["azureAD", "google", "jumpcloud", "okta", "onelogin"];

const OpenConfigModalButton: FC<{ type: TDirectory; openConfigModal: (type: TDirectory) => void }> = ({
	type,
	openConfigModal
}) => {
	const { t } = useTranslation();
	const openConfigModalHandler = useCallback(() => openConfigModal(type), [openConfigModal, type]);

	return (
		<Button variant="text" size="small" onClick={openConfigModalHandler}>
			{t("pages.settings.integrations.connect")}
		</Button>
	);
};

export const DirectoryIntegrations: FC = () => {
	const {
		actions: { createConfiguration, deleteConfiguration, updateConfiguration, getConfigurations, syncDirectory },
		state: { directoryConfigurations }
	} = useDirectoryConfigurationsContext();

	const classes = useStyles();
	const openErrorModal = useOpenGlobalErrorModal();
	const [configurationIdToDelete, setConfigurationIdToDelete] = useState<string>();
	const [chosenDirectory, setChosenDirectory] = useState<TDirectory>();
	const [configurationModalExistingConfigurationId, setConfigurationModalExistingConfigurationId] = useState<
		string | undefined
	>(undefined);
	const { t } = useTranslation();
	const areYouSureModal = useIsOpenState();
	const configurationModal = useIsOpenState();

	const sync = useCallback(
		async (id: string) => {
			try {
				await syncDirectory(id);
			} catch (err) {
				openErrorModal(err as Error);
			}
		},
		[openErrorModal, syncDirectory]
	);

	const toggleHRManagementSource = useCallback(
		async (id: string, isDirectManagerSource: boolean) => {
			try {
				await updateConfiguration(id, { isDirectManagerSource });
			} catch (err) {
				openErrorModal(err as Error);
			}
		},
		[openErrorModal, updateConfiguration]
	);

	const openConfigModal = useCallback(
		(type: TDirectory, existingConfiguration?: string) => {
			setChosenDirectory(type);
			setConfigurationModalExistingConfigurationId(existingConfiguration);
			configurationModal.open();
		},
		[configurationModal]
	);

	const closeConfigModal = useCallback(() => {
		setChosenDirectory(undefined);
		configurationModal.close();
	}, [configurationModal]);

	const openAreYouSureModal = useCallback(
		(id: string) => {
			setConfigurationIdToDelete(id);
			areYouSureModal.open();
		},
		[areYouSureModal]
	);

	const closeAreYouSureModal = useCallback(() => {
		setConfigurationIdToDelete(undefined);
		areYouSureModal.close();
	}, [areYouSureModal]);

	const removeDirectoryConfiguration = useCallback(async () => {
		try {
			configurationIdToDelete && (await deleteConfiguration(configurationIdToDelete));
		} catch (err) {
			openErrorModal(err as Error);
		}
		areYouSureModal.close();
	}, [areYouSureModal, deleteConfiguration, openErrorModal, configurationIdToDelete]);

	const getExtraOptions = useCallback(
		(directory: DirectoryConfigurationModel) => {
			const options = new Map<TRequestAction, TSelectClickableOption<TDirectorySchema>>();
			options.set("addConnection", {
				value: "addConnection",
				label: t("pages.settings.integrations.addConnection"),
				onClick: () => openConfigModal(directory.directory)
			});

			options.set("hr", {
				value: "hr",
				label: `${directory.isDirectManagerSource ? t("pages.settings.disable") : t("pages.settings.enable")} ${t(
					"pages.settings.HR"
				)}`,
				onClick: () => toggleHRManagementSource(directory.id, !directory.isDirectManagerSource)
			});

			options.set("editConnection", {
				value: "editConnection",
				label: t("pages.settings.editConnection"),
				onClick: () => openConfigModal(directory.directory, directory.id)
			});

			options.set("disconnect", {
				value: "disconnect",
				label: t("pages.settings.disconnect"),
				onClick: () => openAreYouSureModal(directory.id)
			});

			return options;
		},
		[openAreYouSureModal, openConfigModal, t, toggleHRManagementSource]
	);

	const configuredDirectories = useMemo(
		() =>
			uniq(
				directoryConfigurations
					?.map(config => config.directory)
					.sort((a, b) => a.localeCompare(b))
					.toArray()
			),
		[directoryConfigurations]
	);

	const nonConfiguredDirectories = useMemo(
		() => DIRECTORIES.filter(dir => !configuredDirectories.includes(dir)),
		[configuredDirectories]
	);

	const existingConfigurations = useMemo(() => {
		if (!directoryConfigurations) return null;
		return configuredDirectories.flatMap(type => {
			const sortedConfigs = sortByDisplayName(
				directoryConfigurations.filter(config => config.directory === type).toArray()
			);
			return sortedConfigs.map(config => ({
				directoryConfig: config,
				sync: () => sync(config.id),
				extraOptions: getExtraOptions(config)
			}));
		});
	}, [configuredDirectories, directoryConfigurations, getExtraOptions, sync]);

	useEffect(() => {
		getConfigurations();
	}, [getConfigurations]);

	if (!directoryConfigurations) return null;

	return (
		<>
			<AreYouSureModal
				isOpen={areYouSureModal.isOpen}
				onClose={closeAreYouSureModal}
				onAction={removeDirectoryConfiguration}
				actionLabel={t("pages.settings.integrations.disconnect")}
				content={t("pages.settings.integrations.disconnectDirectory")}
			/>
			<DirectoryConfigurationModal
				existingConfigurationId={configurationModalExistingConfigurationId}
				onCreate={createConfiguration}
				onEdit={editDirectoryConfiguration}
				directory={chosenDirectory!}
				isOpen={configurationModal.isOpen}
				onClose={closeConfigModal}
			/>
			{nonConfiguredDirectories!.map(type => (
				<IntegrationRow
					key={type}
					SystemLogo={get(sourceIcons, type)}
					integration={t(`pages.settings.directoryConfiguration.${type}.name`) || capitalize(type)}
					actions={<OpenConfigModalButton type={type} openConfigModal={openConfigModal} />}
				/>
			))}
			{existingConfigurations?.map(config => {
				const { directoryConfig, extraOptions, sync } = config;
				const { id, directory: type, displayName } = directoryConfig;
				return (
					<IntegrationRow
						key={id}
						isIntegrated
						SystemLogo={get(sourceIcons, type)}
						integration={`${
							t(`pages.settings.directoryConfiguration.${type}.name`) || capitalize(type)
						} (${displayName})`}
						actions={
							<>
								<SyncButton nextSyncTime={directoryConfig.nextSyncTime} propsOnClick={sync} />
								<ExtraOptionsButton
									noBorder
									item={directoryConfig}
									extraOptions={extraOptions}
									size="medium"
									sort={null}
									className={classes.extraOptionsButton}
								/>
							</>
						}
					/>
				);
			})}
		</>
	);
};
