import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { Table } from "components/ui/Table";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { Link } from "components/common/Link";
import { TIntegrationResourceRole, editIntegrationResourceRole } from "api/integrationResourceRoles";
import { WorkflowInlineSelect } from "components/common/WorkflowInlineSelect";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useStyles } from "./styles";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

interface IProps {
	role: IntegrationResourceRoleModel;
	integrationResource: IntegrationResourceModel;
	onRoleChanged: (role: Partial<TIntegrationResourceRole> & { id: string }) => void;
}

export const RoleRow: FC<IProps> = ({ role, integrationResource, onRoleChanged }) => {
	const classes = useStyles();

	const { t } = useTranslation();
	const [approvalAlgorithmId, setApprovalAlgorithmId] = useState<string | null>(role.approvalAlgorithmId);
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const changeApprovalAlgorithm = useCallback(
		async (value: ApprovalAlgorithmModel | undefined) => {
			setApprovalAlgorithmId(value?.id || null);
			try {
				await editIntegrationResourceRole({ id: role.id, approvalAlgorithmId: value?.id ?? null });
			} catch (e) {
				setApprovalAlgorithmId(role.approvalAlgorithmId);
				openGlobalErrorModal(
					new Error(t("errors.integration.integrationResource.workflowChange", { roleName: role.name }))
				);
			}
			onRoleChanged({ id: role.id, approvalAlgorithmId: value?.id ?? null });
		},
		[onRoleChanged, role.id, openGlobalErrorModal, t, role.approvalAlgorithmId, role.name]
	);

	return (
		<Table.Row className={classes.roleRow}>
			<Table.Cell className={classes.roleCell}>
				<Link className={classes.link} noDecoration to={`roles/${role.id}`}>
					{role.name}
				</Link>
			</Table.Cell>
			<Table.Cell className={classes.roleCell}>
				<WorkflowInlineSelect
					approvalAlgorithmId={approvalAlgorithmId}
					inheritTranslationPath="pages.integration.resource.role.inherit"
					setApprovalAlgorithm={changeApprovalAlgorithm}
				/>
			</Table.Cell>

			<Table.Cell className={classes.roleCell}>
				{role?.allowedDurations
					? t("pages.integration.resource.role.overridden")
					: t("pages.integration.resource.role.inherit")}
			</Table.Cell>
			<Table.Cell className={classNames(classes.roleCell, classes.iconCell)}>
				{integrationResource.allowsRequests && role?.allowsRequests ? (
					<GrantedIcon color="var(--color-green-dark)" />
				) : (
					<CloseIcon color="var(--color-red-dark)" />
				)}
			</Table.Cell>
		</Table.Row>
	);
};
