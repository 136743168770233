import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationOwner" as const;

const defaults = {
	name: filterName,
	value: [] as string[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationOwnerFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationOwnerFilter | null {
		const integrationOwner = uniq(urlSearchParams.getAll("integrationOwner"));
		const integrationOwnerOperator = urlSearchParams.get("integrationOwnerOperator") || undefined;
		if (integrationOwner.length === 0 || !validateFilterEqualityOperator(integrationOwnerOperator)) {
			return null;
		}
		return new IntegrationOwnerFilter({
			value: integrationOwner,
			operator: integrationOwnerOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationOwner", value);
		}
		params.append("integrationOwnerOperator", this.operator);
		return params;
	}
}
