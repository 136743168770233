export const TABLES_SORT_FIELDS_TRANSLATION_MAP = {
	integration: {
		allowsRequests: "requestable",
		approvalAlgorithm: "approvalAlgorithm",
		name: "integration",
		owner: "owner"
	} as const,
	resource: {
		allowsRequests: "requestable",
		approvalAlgorithm: "approvalAlgorithm",
		integrationName: "integration",
		name: "integrationResource",
		owner: "owner",
		type: "resourceType"
	} as const,
	role: {
		allowsRequests: "requestable",
		approvalAlgorithm: "approvalAlgorithm",
		integrationName: "integration",
		name: "integrationResourceRole",
		resourceName: "integrationResource",
		resourceOwner: "owner",
		resourceType: "resourceType"
	} as const
};
