import classNames from "classnames";
import React from "react";
import { RuleModel } from "models/RuleModel";
import { Divider } from "components/ui/Divider";
import { useRulesContext } from "components/pages/RulesPage/RulesContext";
import { useStyles } from "./styles";
import { ApplyToCell, ConditionsCell, PriorityCell } from "./RuleCells";
import { RuleActionsCell } from "./components/RuleActionsCell";
import { UpdatesCell } from "./components/UpdatesCell";

type TRuleBarProps = {
	rule: RuleModel;
};
export const RuleBar: FC<TRuleBarProps> = React.memo(function RuleBar({ rule }) {
	const classes = useStyles();

	const {
		state: { selectedRule }
	} = useRulesContext();

	const isSelected = selectedRule?.id === rule.id;

	return (
		<div
			className={classNames(classes.barContainer, classes.ruleBarContainer, {
				[classes.selected]: isSelected,
				[classes.disabled]: !!selectedRule && !isSelected
			})}>
			<PriorityCell priority={rule.priority} />
			<Divider vertical />
			<ApplyToCell condition={rule.conditions.integration} />
			<Divider vertical />
			<ConditionsCell conditions={rule.conditions} />
			<Divider vertical />
			<UpdatesCell updates={rule.updates} ruleType={rule.type} />
			<RuleActionsCell rule={rule} />
		</div>
	);
});
