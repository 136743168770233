import React, { useCallback, useEffect, useMemo } from "react";
import { RoleLabel } from "components/common/RoleLabel";
import { Select } from "components/ui/Select";
import { getIntegrationResourceRoles } from "api/integrationResourceRoles";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { sortByName } from "utils/sortUtils";
import { getIntegrationResourceRoleFilters } from "utils/api/filters";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

interface IProps {
	disabled?: boolean;
	error?: string;
	inputLabel: string;
	integrationResourceId?: string;
	limit?: number;
	noOptionsText?: string;
	onChange: (value: IntegrationResourceRoleModel | null) => void;
	placeholder: string;
	required?: boolean;
	selectedRole: IntegrationResourceRoleModel | null;
	shouldDisable?: (integrationResourceRole: IntegrationResourceRoleModel) => boolean;
	shouldFilter?: (integrationResourceRole: IntegrationResourceRoleModel) => boolean;
}
const OPTIONS_LIMIT = 100;

const getOptionLabel = (option: IntegrationResourceRoleModel) => option?.name || "";

const equality = (option: IntegrationResourceRoleModel, selected: IntegrationResourceRoleModel) =>
	option.id === selected.id;

export const RoleSelectInput: FC<IProps> = ({
	className,
	disabled = false,
	error = "",
	inputLabel,
	integrationResourceId,
	limit = OPTIONS_LIMIT,
	noOptionsText,
	onChange,
	placeholder,
	required = false,
	selectedRole,
	shouldDisable,
	shouldFilter
}) => {
	const fetchRoles = useCallback(
		async (qs: string) => {
			if (integrationResourceId && !disabled) {
				const { result: newRoles } = await getIntegrationResourceRoles({
					perPage: limit,
					sortFields: ["nameLength", "name"],
					order: "ASC",
					filters: getIntegrationResourceRoleFilters({
						integrationResourceId: [integrationResourceId],
						name: [qs]
					})
				});

				return newRoles?.toArray();
			}
			return [];
		},
		[integrationResourceId, disabled, limit]
	);
	const { selectProps, query } = useSelectSearchProps(fetchRoles, integrationResourceId ? selectedRole : null, true);

	const roles = selectProps.options;

	const filteredRoles = useMemo(
		() => (shouldFilter ? roles.filter(role => shouldFilter(role)) : roles),
		[shouldFilter, roles]
	);

	// integrationResourceId is updated before roles, this assures they are synced
	const isRelevantRoles = useMemo(
		() => filteredRoles && filteredRoles[0]?.integrationResourceId === integrationResourceId,
		[filteredRoles, integrationResourceId]
	);

	const isSingleOption = useMemo(
		() => filteredRoles?.length === 1 && query === "" && !(shouldDisable && shouldDisable(filteredRoles[0])),
		[filteredRoles, query, shouldDisable]
	);

	useEffect(() => {
		if (!isRelevantRoles || selectProps.loading) return;

		if (!selectedRole) {
			if (isSingleOption && filteredRoles) onChange(filteredRoles[0]);
			return;
		}

		if (selectedRole.integrationResourceId !== integrationResourceId)
			onChange(isSingleOption && filteredRoles ? filteredRoles[0] : null);
	}, [
		integrationResourceId,
		isRelevantRoles,
		isSingleOption,
		selectProps.loading,
		onChange,
		filteredRoles,
		selectedRole
	]);

	const renderOption = useCallback(
		(option: IntegrationResourceRoleModel) => (
			<RoleLabel option={option} query={query || ""} showPermissions fullData={false} />
		),
		[query]
	);
	const renderLabel = useCallback(
		(option: IntegrationResourceRoleModel) => <RoleLabel option={option} query={query || ""} simple fullData={false} />,
		[query]
	);

	return (
		<Select
			{...selectProps}
			className={className}
			disabled={isSingleOption || disabled}
			errors={error ? [error] : []}
			filter={null}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={equality}
			label={inputLabel}
			limit={limit}
			noOptionsText={noOptionsText}
			onChange={onChange}
			options={filteredRoles}
			placeholder={placeholder}
			renderLabel={renderLabel}
			renderOption={renderOption}
			required={required}
			shouldDisableOption={shouldDisable}
			sort={sortByName}
			value={selectedRole || null}
		/>
	);
};
