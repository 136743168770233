import React, { useMemo, useState, useCallback } from "react";
import partition from "lodash/partition";
import { useTranslation } from "react-i18next";

import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { Typography } from "components/ui/Typography";
import { IconButton } from "components/ui/IconButton";
import { StaticChip } from "components/ui/chips/StaticChip";
import { toMapBy } from "utils/toMapBy";
import { RoleRow } from "./RoleRow";
import { useStyles } from "./styles";

import type { List } from "immutable";
import type { TFullTicketPermission } from "components/common/RequestDetails";
import type { TicketTargetModel } from "models/TicketTargetModel";

export const RequestDetailsRequests: FC<{
	ticketApproved?: boolean;
	ticketPermissions: List<TFullTicketPermission>;
	ticketTargets: List<TicketTargetModel>;
}> = ({ ticketPermissions, ticketApproved, ticketTargets }) => {
	const classes = useStyles();

	const [additionalPermissionsOpened, setAdditionalPermissionsOpened] = useState(false);
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails.requests" });
	const [regularPermissions, prerequisitePermissions] = useMemo(
		() => partition(ticketPermissions.toArray(), ticketPermission => ticketPermission.type === "regular"),
		[ticketPermissions]
	);

	const targetsById = useMemo(() => toMapBy(ticketTargets, target => target.id), [ticketTargets]);
	const hasBundlesColumn = useMemo(() => ticketTargets.some(target => target.type === "bundle"), [ticketTargets]);
	const sortedRegularPermissions = useMemo(
		() =>
			regularPermissions.sort((a, b) => {
				const aHasBundle = a.ticketTargetIds.some(id => targetsById.get(id)?.type === "bundle");
				const bHasBundle = b.ticketTargetIds.some(id => targetsById.get(id)?.type === "bundle");

				return aHasBundle === bHasBundle ? 0 : aHasBundle ? -1 : 1;
			}),
		[regularPermissions, targetsById]
	);

	const toggleAdditionalPermissions = useCallback(() => setAdditionalPermissionsOpened(curr => !curr), []);

	const ChevronIcon = additionalPermissionsOpened ? ChevronUpIcon : ChevronDownIcon;

	return (
		<div className={classes.requestsGrid}>
			<div>
				<div className={classes.title}>
					<Typography variant="text_reg">{t("title")}</Typography>
					<StaticChip size="small" variant="regular">
						{regularPermissions.length}
					</StaticChip>
				</div>
				<div className={classes.gridContainer}>
					{sortedRegularPermissions.map(({ id, role, status, ticketTargetIds }) => (
						<RoleRow
							key={id}
							role={role}
							status={status}
							ticketApproved={ticketApproved}
							hasBundleColumn={hasBundlesColumn}
							targetsById={targetsById}
							ticketTargetIds={ticketTargetIds}
						/>
					))}
				</div>
			</div>
			{prerequisitePermissions.length > 0 && (
				<div>
					<div className={classes.title}>
						<Typography variant="text_reg">{t("additionalPermissions.title")}</Typography>
						<IconButton
							tooltipClassName={classes.additionalPermissionsTooltip}
							size="small"
							tooltip={t("additionalPermissions.tooltip")}>
							<InfoIcon />
						</IconButton>
						<StaticChip size="small" variant="regular">
							{prerequisitePermissions.length}
						</StaticChip>
						<IconButton size="medium" className={classes.chevronIcon}>
							<ChevronIcon onClick={toggleAdditionalPermissions} />
						</IconButton>
					</div>
					{additionalPermissionsOpened && (
						<div className={classes.gridContainer}>
							{prerequisitePermissions.map(({ id, role, status, ticketTargetIds }) => (
								<RoleRow
									key={id}
									role={role}
									status={status}
									ticketApproved={ticketApproved}
									targetsById={targetsById}
									ticketTargetIds={ticketTargetIds}
								/>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
