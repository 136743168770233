import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useIsOpenState } from "hooks/useIsOpenState";
import useErrorModalState from "hooks/useErrorModalState";
import { useBundlesContext } from "context/bundlesContext";
import { Button } from "components/ui/Button";
import { PageTemplate } from "components/templates/PageTemplate";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { ErrorModal } from "components/ui/ErrorModal";
import { EditorSuccessModal } from "components/common/EditorSuccessModal";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { BundleModel } from "models/BundleModel";
import { uniq } from "lodash";
import { useBundles } from "hooks/useBundles";
import { useStyles } from "./styles";
import { EditBundle } from "./components/EditBundle";
import { BundleList } from "./components/BundleList";

export const BundlesPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const bundles = useBundles(false, true);
	const {
		actions: { deleteBundle }
	} = useBundlesContext();
	const [bundleToEdit, setBundleToEdit] = useState<BundleModel | null>(null);
	const { isOpen: editorIsOpen, open: openEditor, close: closeEditor } = useIsOpenState();
	const { isOpen: successModalIsOpen, open: openSuccessModal, close: closeSuccessModal } = useIsOpenState();
	const { isOpen: areYouSureModalIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();
	const {
		errorModalSetError,
		errorModalIsOpen,
		errorModalError,
		errorModalClose: closeErrorModal
	} = useErrorModalState();

	const categories = useMemo(
		() =>
			bundles
				? uniq(
						bundles
							.toList()
							.toArray()
							.map(bundle => bundle.category)
							.filter(option => !!option)
					)
				: [],
		[bundles]
	);

	const onAddBundleClick = useCallback(() => {
		setBundleToEdit(null);
		openEditor();
	}, [openEditor]);

	const onEditBundleClick = useCallback(
		(bundle: BundleModel) => {
			setBundleToEdit(bundle);
			openEditor();
		},
		[openEditor]
	);
	const [removeAction, setRemoveAction] = useState<() => Promise<void>>();

	const onDeleteBundleClick = useCallback(
		(bundle: BundleModel) => {
			const remove = () => deleteBundle(bundle.id);
			setRemoveAction(() => remove);
			openAreYouSureModal();
		},
		[openAreYouSureModal, deleteBundle]
	);

	const removeBundle = useCallback(async () => {
		if (!removeAction) return;
		try {
			await removeAction();
		} catch (err) {
			errorModalSetError(err as Error);
		} finally {
			closeAreYouSureModal();
		}
	}, [closeAreYouSureModal, removeAction, errorModalSetError]);

	const onSubmit = useCallback(() => {
		closeEditor();
		openSuccessModal();
	}, [openSuccessModal, closeEditor]);

	return (
		<PageTemplate className={classNames("bundlesPage", className)}>
			{editorIsOpen && (
				<EditBundle
					isOpen={editorIsOpen}
					onClose={closeEditor}
					bundle={bundleToEdit || undefined}
					onError={errorModalSetError}
					onSubmit={onSubmit}
					categories={categories as string[]}
				/>
			)}
			<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />
			<EditorSuccessModal
				state={bundleToEdit ? "edit" : "create"}
				isOpen={successModalIsOpen}
				editTitle={t("pages.bundles.bundleEditor.editSuccessModalTitle")}
				createTitle={t("pages.bundles.bundleEditor.createSuccessModalTitle")}
				content={t("pages.bundles.bundleEditor.successModalInformation")}
				onClose={closeSuccessModal}
			/>
			<AreYouSureModal
				onClose={closeAreYouSureModal}
				isOpen={areYouSureModalIsOpen}
				onAction={removeBundle}
				actionLabel={t("pages.bundles.deleteBundle.deleteBundle")}
				title={t("pages.bundles.deleteBundle.title")}
				content={t("pages.bundles.deleteBundle.content")}
			/>

			<PageTemplate.Title className={classes.titleContainer}>
				{t("pages.bundles.title")}
				<Button size="medium" onClick={onAddBundleClick}>
					{t("pages.bundles.addBundle")}
				</Button>
			</PageTemplate.Title>
			<PageTemplate.Content>
				{bundles === null ? (
					<LoadingSpinner className={classes.spinner} />
				) : (
					<BundleList onRemove={onDeleteBundleClick} onEdit={onEditBundleClick} />
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
