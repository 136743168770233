import { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import constate from "constate";
import { List, Map } from "immutable";
import {
	deleteResourcesRule,
	deleteRolesRule,
	getResourcesRules,
	getRolesRules,
	updateResourcesRule,
	updateRolesRule
} from "api/rules";
import { useFetchedState } from "hooks/useFetchedState";
import { useLoadingState } from "hooks/useLoadingState";
import { RuleModel, TRuleType } from "models/RuleModel";

const useRules = () => {
	const [newRuleType, setNewRuleType] = useState<TRuleType>();
	const [typelessRule, setTypelessRule] = useState<RuleModel | null>(null);
	const [rulesReadonly, setRulesReadonly] = useState(false);
	const [selectedRule, setSelectedRule] = useState<RuleModel | null>(null);
	const {
		data: resourcesRules,
		forceLoadData: loadResourcesRulesData,
		fetchingState: resourcesRulesFetchingState,
		isLoading: resourcesRulesIsLoading,
		setData: setResourceRules
	} = useFetchedState(getResourcesRules);
	const {
		data: rolesRules,
		forceLoadData: loadRolesRulesData,
		fetchingState: rolesRulesFetchingState,
		isLoading: rolesRulesIsLoading,
		setData: setRoleRules
	} = useFetchedState(getRolesRules);

	const { isLoading, withLoader } = useLoadingState();

	useEffect(() => {
		if (resourcesRulesFetchingState === "Initial") {
			loadResourcesRulesData();
		}
	}, [resourcesRulesFetchingState, loadResourcesRulesData]);

	useEffect(() => {
		if (rolesRulesFetchingState === "Initial") {
			loadRolesRulesData();
		}
	}, [rolesRulesFetchingState, loadRolesRulesData]);

	const reloadData = useCallback(() => {
		loadRolesRulesData();
		loadResourcesRulesData();
	}, [loadResourcesRulesData, loadRolesRulesData]);

	const upsertTypedRule = useCallback(
		async (
			rule: RuleModel,
			setRules: (value: SetStateAction<List<RuleModel> | null>) => void,
			updateInServer?: boolean
		) => {
			setRules(rules => {
				if (!rules) return rules;

				const existingRuleIndex = rules.findIndex(_rule => _rule.id === rule.id);
				const existingRule = existingRuleIndex > -1 ? rules.get(existingRuleIndex) : undefined;

				if (existingRule && (existingRule.priority === rule.priority || !rule.priority)) {
					return rules.setIn([existingRuleIndex], rule.set("priority", existingRule.priority));
				}
				const newRules = rules.filter(_rule => _rule.id !== rule.id);
				const ruleNewPriority = rule.priority || existingRule?.priority || 1;
				const ruleNewIndex = newRules.findIndex(r => r.priority === ruleNewPriority);

				const res = newRules
					.insert(!existingRule || existingRule.priority > ruleNewPriority ? ruleNewIndex : ruleNewIndex + 1, rule)
					.map((rule, index) => rule.set("priority", index + 1));

				return res;
			});
			if (updateInServer) {
				await withLoader(
					(async () => {
						if (rule.type === "roles") {
							await updateRolesRule(rule, false);
							await loadRolesRulesData();
						} else if (rule.type === "resources") {
							await updateResourcesRule(rule, false);
							await loadResourcesRulesData();
						}
					})()
				);
			}
		},
		[loadRolesRulesData, loadResourcesRulesData, withLoader]
	);

	const removeRule = useCallback(
		async (rule: RuleModel, setRules: (value: SetStateAction<List<RuleModel> | null>) => void) => {
			setRules(rules => {
				if (!rules || rules.size === 0) return rules;
				const index = rules.findIndex(_rule => _rule.id === rule.id);
				if (index !== -1) {
					return rules.delete(index).map((rule, index) => rule.set("priority", index + 1));
				}
				return rules;
			});
		},
		[]
	);

	const onDeleteRule = useCallback(
		async (rule: RuleModel) => {
			if (!rule.id) {
				return;
			}
			removeRule(rule, rule.type === "roles" ? setRoleRules : setResourceRules);
			await withLoader(
				(async () => {
					if (rule.type === "roles") {
						await deleteRolesRule(rule.id);
						await loadRolesRulesData();
					} else if (rule.type === "resources") {
						await deleteResourcesRule(rule.id);
						await loadResourcesRulesData();
					}
				})()
			);
		},
		[removeRule, setRoleRules, setResourceRules, withLoader, loadRolesRulesData, loadResourcesRulesData]
	);

	const upsertRule = useCallback(
		(rule: RuleModel, updateInServer?: boolean) => {
			if (rule.type === "roles") {
				upsertTypedRule(rule, setRoleRules, updateInServer);
			} else if (rule.type === "resources") {
				upsertTypedRule(rule, setResourceRules, updateInServer);
			}
		},
		[upsertTypedRule, setRoleRules, setResourceRules]
	);

	const changePriority = useCallback(
		(rule: RuleModel, newPriority: number, updateInServer?: boolean) =>
			upsertRule(rule.set("priority", newPriority), updateInServer),
		[upsertRule]
	);

	const normalizedRolesRules = useMemo(() => rolesRules ?? List<RuleModel>(), [rolesRules]);
	const normalizedResourcesRules = useMemo(() => resourcesRules ?? List<RuleModel>(), [resourcesRules]);
	const normalizedTypelessRules = useMemo(() => List<RuleModel>(typelessRule ? [typelessRule] : []), [typelessRule]);

	const upsertNewRule = useCallback(
		(rule: RuleModel, ruleType: TRuleType | undefined) => {
			if (resourcesRulesIsLoading || rolesRulesIsLoading) return;
			const isOnlyOneInsideRoleRules = newRuleType === "roles" && normalizedRolesRules.size < 2;
			const isOnlyOneInsideResourceRules = newRuleType === "resources" && normalizedResourcesRules.size < 2;
			const haveNoPreviousRoles = normalizedRolesRules.size === 0 && normalizedResourcesRules.size === 0;
			if (!ruleType && (isOnlyOneInsideRoleRules || isOnlyOneInsideResourceRules || haveNoPreviousRoles)) {
				setTypelessRule(rule.set("priority", 1));
				setNewRuleType(undefined);
				if (newRuleType === "roles") {
					removeRule(rule, setRoleRules);
				} else if (newRuleType === "resources") {
					removeRule(rule, setResourceRules);
				}
			} else if (
				ruleType === "roles" ||
				(!ruleType && (normalizedRolesRules.size !== 0 || normalizedResourcesRules.size === 0))
			) {
				setTypelessRule(null);
				setNewRuleType("roles");
				removeRule(rule, setResourceRules);
				upsertTypedRule(rule.set("type", "roles"), setRoleRules);
			} else {
				setTypelessRule(null);
				setNewRuleType("resources");
				removeRule(rule, setRoleRules);
				upsertTypedRule(rule.set("type", "resources"), setResourceRules);
			}
		},
		[
			resourcesRulesIsLoading,
			rolesRulesIsLoading,
			normalizedRolesRules.size,
			normalizedResourcesRules.size,
			newRuleType,
			upsertTypedRule,
			removeRule,
			setTypelessRule,
			setRoleRules,
			setResourceRules
		]
	);

	const rules = useMemo(
		() => normalizedRolesRules.concat(normalizedResourcesRules).concat(normalizedTypelessRules),
		[normalizedRolesRules, normalizedResourcesRules, normalizedTypelessRules]
	);

	const rulesMap = useMemo(() => Map<string, RuleModel>(rules.map(rule => [rule.id ?? "new", rule])), [rules]);

	return {
		state: {
			isLoading: isLoading || resourcesRulesIsLoading || rolesRulesIsLoading || !rolesRules || !resourcesRules,
			rulesMap,
			roleRules: normalizedRolesRules,
			resourceRules: normalizedResourcesRules,
			typelessRules: normalizedTypelessRules,
			rulesReadonly,
			selectedRule
		},
		actions: {
			changePriority,
			setRulesReadonly,
			setSelectedRule,
			upsertRule,
			upsertNewRule,
			deleteRule: onDeleteRule,
			reloadData
		}
	};
};

export const [RulesProvider, useRulesContext] = constate(useRules);
