import { EntityOption } from "components/common/EntityOption";
import { Select } from "components/ui/Select";
import { BundleModel } from "models/BundleModel";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { sortByName } from "utils/sortUtils";

interface IProps {
	bundles: BundleModel[] | null;
	disabled?: boolean;
	label?: string;
	limit?: number;
	onChange: (bundle: BundleModel | null) => void;
	value: BundleModel | null;
	loading?: boolean;
	shouldDisableOption?: (bundle: BundleModel) => boolean;
	filter?: (bundle: BundleModel) => boolean;
}

const BundleOption = (bundleOption: BundleModel, index: number, disabled?: boolean) => {
	return <EntityOption entity={bundleOption} key={bundleOption.id} disabled />;
};

const optionsFilter = (options: BundleModel[], inputValue: string) => {
	return options.filter(
		op =>
			op.name.toLocaleLowerCase().includes(inputValue) ||
			op.tags?.map(tag => tag.toLocaleLowerCase()).includes(inputValue)
	);
};

const getLabel = (bundle: BundleModel) => bundle.name;
const equality = (bundle: BundleModel, value: BundleModel) => bundle.id === value.id;

export const BundleSelect: FC<IProps> = ({
	bundles,
	label,
	onChange,
	value,
	disabled,
	limit,
	loading = false,
	shouldDisableOption,
	filter
}) => {
	const { t } = useTranslation();

	const options = useMemo(() => (bundles ? bundles.filter(bundle => bundle.bundleItems?.size) : []), [bundles]);
	const filteredOptions = useMemo(
		() => (filter ? options.filter(option => filter(option)) : options),
		[options, filter]
	);

	return (
		<Select
			disabled={disabled}
			filter={optionsFilter}
			getOptionLabel={getLabel}
			isOptionEqualToValue={equality}
			label={label}
			limit={limit}
			loading={loading}
			onChange={onChange}
			options={filteredOptions}
			placeholder={t("common.bundleSelect.placeholder")}
			renderOption={BundleOption}
			required
			shouldDisableOption={shouldDisableOption}
			sort={sortByName}
			value={value}
		/>
	);
};
