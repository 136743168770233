import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { UsersIcon } from "components/ui/Icons/UsersIcon";
import { UserWithEmail } from "components/common/UserWithEmail";
import { UserModel } from "models/UserModel";
import { useUsersSelect } from "hooks/useUsersSelect";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TIntegrationFilterExpressionProps = {
	onRemove: () => void;
};

export const UserFilterExpression: FC<TIntegrationFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();
	const [searchQuery, setSearchQuery] = useState<string>("");
	const { items: users } = useUsersSelect(searchQuery);
	const renderOption = useCallback((value: UserModel) => {
		return <UserWithEmail user={value}>{value.fullName}</UserWithEmail>;
	}, []);

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchValues={users}
			title={t("pages.auditLog.integrationLogs.filter.user")}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.userPlaceholder")}
			filterEmptyState={
				<FilterExpressionEmptyState text={t("pages.auditLog.integrationLogs.filter.userEmptyState")} Icon={UsersIcon} />
			}
			renderOption={renderOption}
			onFilterRemove={onRemove}
			onInputChange={setSearchQuery}
			renderType="UserCard"
			filterName="userId"
		/>
	);
};
