import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { devLog } from "utils/devtools/devLogging";

interface DebounceOptions {
	leading?: boolean;
	trailing?: boolean;
	maxWait?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TAnyFunc = (...args: any[]) => any;

function useLatest<T>(value: T): MutableRefObject<T> {
	const ref = useRef(value);
	ref.current = value;

	return ref;
}

const isNotFunction = (fn: TAnyFunc) => {
	if (typeof fn !== "function") {
		devLog({ message: `useDebounceFn expected parameter is a function, got ${typeof fn}`, level: "error" });
	}
};

const useUnmount = (fn: () => void) => {
	isNotFunction(fn);

	const fnRef = useLatest(fn);

	useEffect(
		() => () => {
			fnRef.current();
		},
		[fnRef]
	);
};

export function useDebounceFn<T extends TAnyFunc>(fn: T, wait = 1000, options?: DebounceOptions) {
	isNotFunction(fn);

	const fnRef = useLatest(fn);

	const debounced = useMemo(
		() =>
			debounce(
				((...args: Parameters<T>): ReturnType<T> => {
					return fnRef.current(...args);
				}) as T,
				wait,
				options
			),
		[fnRef, options, wait]
	);

	useUnmount(() => {
		debounced.cancel();
	});

	return [debounced, debounced.cancel, debounced.flush];
}

export function useDebouncedValue<T>(value: T, wait = 1000, options?: DebounceOptions) {
	const [state, setState] = useState(value);
	const [debounced] = useDebounceFn(setState, wait, options);

	useEffect(() => {
		debounced(value);
	}, [debounced, value]);

	return state;
}
