import { Record } from "immutable";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TDiff } from "utils/auditLogs/auditLogUtils";

export interface IIntegrationResourceRoleAuditLogDiffData {
	approvalAlgorithmId?: TDiff<string>;
	name?: TDiff<string>;
	allowsRequests?: TDiff<boolean>;
	allowedDurations: TDiff<number[]>;
}

type TIntegrationResourceRoleAuditLogAction =
	| "RoleCreatedManually"
	| "RoleDeletedManually"
	| "RoleUpdatedOverrideAllowedDurations"
	| "RoleUpdatedAllowRequests"
	| "RoleUpdatedWorkflow"
	| "RoleUpdatedName";

const defaults = {
	action: "RoleCreatedManually" as TIntegrationResourceRoleAuditLogAction,
	integrationResourceRole: null as IntegrationResourceRoleModel | null,
	integrationResourceRoleId: "",
	id: "",
	integrationId: "",
	createdAt: new Date(0),
	data: null as IIntegrationResourceRoleAuditLogDiffData | null,
	userId: ""
};

export class IntegrationResourceRoleAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "integrationResourceRole";

	static fromServerData(data: unknown) {
		const {
			id,
			createdAt,
			data: _data,
			action,
			integrationResourceRole,
			integrationId,
			integrationResourceRoleId,
			userId
		} = data as typeof defaults;

		return new IntegrationResourceRoleAuditLogModel({
			id,
			createdAt: new Date(createdAt),
			data: _data ? (_data as IIntegrationResourceRoleAuditLogDiffData) : null,
			action,
			integrationId,
			integrationResourceRole: integrationResourceRole
				? IntegrationResourceRoleModel.fromServerData(integrationResourceRole)
				: null,
			integrationResourceRoleId,
			userId
		});
	}
}
