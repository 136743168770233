import { Record, List } from "immutable";
import { TTicketDuration } from "utils/durationsOptions";
import { IntegrationActorPermissionModel } from "./IntegrationActorPermissionModel";
import { IntegrationResourceModel } from "./IntegrationResourceModel";

const defaults = {
	allowedDurations: null as List<TTicketDuration> | null,
	allowsRequests: false,
	approvalAlgorithmId: null as string | null,
	euid: "",
	givesAccessToRoles: null as List<IntegrationResourceRoleModel> | null,
	hasAccessFromResources: null as List<IntegrationResourceModel> | null,
	hasAccessFromRoles: null as List<IntegrationResourceRoleModel> | null,
	id: "",
	integrationActorPermissions: null as List<IntegrationActorPermissionModel> | null,
	integrationResource: null as IntegrationResourceModel | null,
	integrationResourceId: "",
	isDeleted: false,
	name: "",
	permissions: null as string[] | null,
	prerequisitePermissionId: null as string | null,
	virtualOfRoleIds: null as string[] | null,
	virtualizedRole: null as IntegrationResourceRoleModel | null,
	virtualizedRoleId: null as string | null
};

export type TIntegrationResourceRoleModel = typeof defaults;

export class IntegrationResourceRoleModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): IntegrationResourceRoleModel {
		const {
			allowedDurations,
			allowsRequests,
			approvalAlgorithmId,
			euid,
			givesAccessToRoles: giveAccessToRoles,
			hasAccessFromResources,
			hasAccessFromRoles,
			id,
			integrationActorPermissions,
			integrationResource,
			integrationResourceId,
			isDeleted,
			name,
			permissions,
			prerequisitePermissionId,
			virtualOfRoleIds,
			virtualizedRole,
			virtualizedRoleId
		} = data as typeof defaults;

		return new IntegrationResourceRoleModel({
			allowedDurations: allowedDurations ? List(allowedDurations) : null,
			allowsRequests,
			approvalAlgorithmId,
			euid,
			givesAccessToRoles: giveAccessToRoles
				? List(giveAccessToRoles.map(IntegrationResourceRoleModel.fromServerData))
				: null,
			hasAccessFromResources: hasAccessFromResources
				? List(hasAccessFromResources.map(IntegrationResourceModel.fromServerData))
				: null,
			hasAccessFromRoles: hasAccessFromRoles
				? List(hasAccessFromRoles.map(IntegrationResourceRoleModel.fromServerData))
				: null,
			id,
			integrationActorPermissions: integrationActorPermissions
				? List(integrationActorPermissions.map(IntegrationActorPermissionModel.fromServerData))
				: null,
			integrationResource: integrationResource ? IntegrationResourceModel.fromServerData(integrationResource) : null,
			integrationResourceId,
			isDeleted,
			name,
			permissions,
			prerequisitePermissionId,
			virtualOfRoleIds,
			virtualizedRole: virtualizedRole ? IntegrationResourceRoleModel.fromServerData(virtualizedRole) : null,
			virtualizedRoleId
		});
	}
}
