import React, { useMemo } from "react";
import classNames from "classnames";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Typography } from "components/ui/Typography";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { GroupCard } from "components/common/GroupCard";
import { UserCard } from "components/common/UserCard";
import { UserMaintainerModel } from "models/UserMaintainerModel";
import { useStyles } from "./styles";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationModel } from "models/IntegrationModel";

type TProps = {
	model: IntegrationModel | IntegrationResourceModel;
	getIsDisabled: (id: string) => boolean;
	isSelected?: boolean;
	tooltipHeader: string;
};

export const MaintainersCell: FC<TProps> = ({
	model,
	getIsDisabled,
	isSelected,
	tooltipHeader,
	className,
	innerRef
}) => {
	const classes = useStyles();
	const maintainersCards = useMemo(() => {
		return (model.maintainers?.toArray() || []).map(maintainer =>
			maintainer instanceof UserMaintainerModel ? (
				<UserCard key={maintainer.entityId} user={maintainer.entityId} />
			) : (
				<GroupCard key={maintainer.entityId} group={maintainer.entityId} />
			)
		);
	}, [model]);
	const isDisabled = getIsDisabled(model.id);
	if (maintainersCards.length === 0) return null;
	return (
		<div className={classNames(classes.maintainersContainer, className)} ref={innerRef}>
			<TitleTooltip
				key="maintainersTooltip"
				header={
					<div className={classes.tooltipHeader}>
						<MaintainersIcon size={24} />
						<Typography variant="body_sb">{tooltipHeader}</Typography>
						<StaticChip size="small" variant="regular">
							{maintainersCards.length}
						</StaticChip>
					</div>
				}
				body={<div className={classes.tooltipBody}>{maintainersCards}</div>}>
				<StaticChip
					PrefixIcon={MaintainersIcon}
					size="medium"
					variant={isSelected ? "light" : "regular"}
					disabled={isDisabled}>
					{maintainersCards.length}
				</StaticChip>
			</TitleTooltip>
		</div>
	);
};
