import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorObject } from "ajv";
import { checkIntegration, ICheckIntegration } from "api/integrations";
import { ApplicationModel } from "models/ApplicationModel";
import { schemaToEmptyJson } from "utils/jsonSchema";
import { notEmpty } from "utils/comparison";
import { generateAjv } from "utils/ajvUtils";
import { useLoadingState } from "./useLoadingState";
import type { TJsonSchema } from "types/utilTypes";

type TValidState = "unchecked" | "invalid" | "valid";
export type TOAuthError = { code: number; message: string };

type TConfigStateType = "oauth" | "schema";

type TConfigStateOption<TValue> = {
	label: string;
	value: TValue;
	type: TConfigStateType;
	shouldValidate: boolean;
};

type TOAuthConfigState = TConfigStateOption<string> & {
	value: "oauth";
	type: "oauth";
	shouldValidate: false;
};

type TSchemaConfigState = TConfigStateOption<TJsonSchema> & {
	type: "schema";
	shouldValidate: true;
};

export type TConfigState = TOAuthConfigState | TSchemaConfigState;

const mapAppConfigSchema =
	(type: TConfigStateType, defaultTranslation: string, shouldValidate: boolean, multipleOptions: boolean) =>
	(item: TJsonSchema, index: number) =>
		({
			label: item.name || `${defaultTranslation} ${multipleOptions ? index + 1 : ""}`,
			value: item,
			type,
			shouldValidate
		}) as TConfigState;

export interface IChangeConfig {
	adapterName: string;
	adapterless: boolean;
	changeConfigState: (value: TConfigState | null) => void;
	changeOauthConfig: (config: Record<string, unknown>) => void;
	checkConfig: () => Promise<boolean>;
	config: Record<string, unknown> | null;
	configErrors: string[];
	configSchema: TJsonSchema[] | null;
	configState: TConfigState | null;
	generalErrors: string[];
	isConfigLoading: boolean;
	oauthError: TOAuthError | undefined;
	onConfigChange: (data: Record<string, unknown>) => void;
	onError: (errors: Partial<ErrorObject>[]) => void;
	onOauthError: (error: TOAuthError | undefined) => void;
	reset: () => void;
	stateOptions: TConfigState[];
	validConfig: TValidState;
	validValue: boolean;
}

export const useChangeConfig = (
	application: ApplicationModel | null,
	agentTokenId?: string,
	integrationId?: string
): IChangeConfig => {
	const { t } = useTranslation();
	const ajv = useMemo(() => generateAjv({ strictSchema: false }), []);
	const [validValue, setValidValue] = useState(true);
	const [validConfig, setValidConfig] = useState<TValidState>("unchecked");
	const [configErrors, setConfigErrors] = useState<string[]>([]);
	const [generalErrors, setGeneralErrors] = useState<string[]>([]);
	const [config, setConfig] = useState<Record<string, unknown> | null>(null);
	const [configState, setConfigState] = useState<TConfigState | null>(null);
	const [oauthError, setOauthError] = useState<{ code: number; message: string }>();
	const { withLoader: withConfigLoader, isLoading: isConfigLoading } = useLoadingState();

	const stateOptions = useMemo<TConfigState[]>(() => {
		return [
			...(application?.oauthConfigurationSchema?.map(
				mapAppConfigSchema(
					"oauth",
					t("pages.integration.connectWithOauth"),
					false,
					application?.oauthConfigurationSchema.length > 1
				)
			) || []),
			...(application?.configurationSchema?.map(
				mapAppConfigSchema(
					"schema",
					t("pages.integration.configuration"),
					true,
					application?.configurationSchema.length > 1
				)
			) || [])
		];
	}, [application, t]);

	const changeConfigState = useCallback((value: TConfigState | null) => {
		setConfigState(value);
		if (value?.type === "schema") {
			setConfig(schemaToEmptyJson(value?.value));
		}
		setValidConfig("unchecked");
		setGeneralErrors([]);
		setConfigErrors([]);
	}, []);

	const reset = useCallback(() => {
		setValidValue(true);
		setValidConfig("unchecked");
		setConfigErrors([]);
		setConfig(null);
		setOauthError(undefined);
		setConfigState(null);
		setGeneralErrors([]);
	}, []);

	const onConfigChange = useCallback(
		(data: Record<string, unknown>) => {
			if (!application) return;
			if (data) {
				setConfig(data);
				setValidValue(true);
				setValidConfig("unchecked");
			}
		},
		[application]
	);

	const onError = useCallback((errors: Partial<ErrorObject>[]) => {
		setValidValue(false);
		setConfigErrors(errors.map(error => error.message).filter(notEmpty));
	}, []);

	const checkConfig = useCallback(async () => {
		if (!validValue || !application) return false;
		if (application.adapterless) return true;
		if (config === null && configState?.type === "oauth") {
			setValidConfig("invalid");
			setOauthError({ message: "", code: 2 });
			return false;
		}
		if (config === null) {
			setValidConfig("invalid");
			setGeneralErrors([t("pages.integration.errors.noConnection")]);
			return false;
		}
		if (configState?.value) {
			const ajvValid = ajv.validate(configState.value, config);
			if (!ajvValid) {
				onError(ajv.errors || []);
				return false;
			}
		}
		const isValid = await withConfigLoader(
			(async () => {
				try {
					const checkIntegrationResult: ICheckIntegration = await checkIntegration({
						agentTokenId: agentTokenId || undefined,
						integrationId: integrationId || undefined,
						applicationId: application.id,
						configuration: { ...config, configurationSchemaName: configState?.label }
					});
					if (!checkIntegrationResult.isValidConfig) {
						setConfigErrors([checkIntegrationResult.message]);
						setValidConfig("invalid");
						return false;
					} else {
						setConfigErrors([]);
						setValidConfig("valid");
						return true;
					}
				} catch (error) {
					setValidConfig("invalid");
					setConfigErrors([error instanceof Error ? error.message : t("errors.unknown.title")]);
					return false;
				}
			})()
		);
		return isValid;
	}, [validValue, application, config, configState, withConfigLoader, t, ajv, onError, agentTokenId, integrationId]);

	const changeOauthConfig = useCallback(
		(value: Record<string, unknown>) => {
			onConfigChange(value);
			setValidConfig("valid");
		},
		[onConfigChange]
	);

	useEffect(() => {
		if (application?.id) {
			reset();
		}
	}, [application?.id, reset]);

	useEffect(() => {
		if (stateOptions.length === 1 && configState === null) {
			changeConfigState(stateOptions[0]);
		}
	}, [stateOptions.length, stateOptions, configState, changeConfigState]);

	return useMemo(
		() => ({
			adapterless: application?.adapterless === undefined ? true : application.adapterless,
			adapterName: application?.adapterName || "",
			changeOauthConfig,
			checkConfig,
			config,
			configErrors,
			generalErrors,
			configSchema: application?.configurationSchema || null,
			configState,
			isConfigLoading,
			oauthError,
			onConfigChange,
			onError,
			onOauthError: setOauthError,
			reset,
			stateOptions,
			changeConfigState,
			validConfig,
			validValue
		}),
		[
			application,
			changeConfigState,
			changeOauthConfig,
			checkConfig,
			config,
			configErrors,
			configState,
			generalErrors,
			isConfigLoading,
			oauthError,
			onConfigChange,
			onError,
			reset,
			stateOptions,
			validConfig,
			validValue
		]
	);
};
