import React from "react";
import { Typography } from "components/ui/Typography";
import { useTranslation } from "react-i18next";
import { IconPrefix } from "components/ui/IconPrefix";
import { CalendarIcon } from "components/ui/Icons/CalendarIcon";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { IntegrationImage } from "components/common/IntegrationImage";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { DurationIcon } from "components/ui/Icons/DurationIcon";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { PermissionsIcon } from "components/ui/Icons/PermissionsIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { UsersCell } from "../../../utils/UsersCell";
import { PermissionsCell } from "../../../utils/PermissionsCell";
import { formatDateToText, formatTimeToText } from "../../../utils/utils";
import { useStyles } from "./styles";
import type { SessionAuditLogModel } from "models/auditLogs/SessionAuditLogModel";
import type { IntegrationModel } from "models/IntegrationModel";
import type { TTimezone } from "utils/auditLogs/systemAuditLogTimezone";

const INTEGRATION_INFO_PREFIX = "common.integrationLogDetails.generalInfo";

type TIntegrationLogActionFields = {
	integrationAuditLog?: SessionAuditLogModel;
	integration: IntegrationModel;
	timezone: TTimezone;
};

export const IntegrationLogGeneralInfo: FC<TIntegrationLogActionFields> = ({
	integrationAuditLog,
	innerRef,
	integration,
	timezone
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.generalInfo}>
			<Typography variant="text_reg">{t(`${INTEGRATION_INFO_PREFIX}.title`)}</Typography>
			<div className={classes.fieldsContainer}>
				<div className={classes.field} ref={innerRef}>
					<IconPrefix Icon={CalendarIcon} content={t(`${INTEGRATION_INFO_PREFIX}.fields.date`)} semibold />
					<Typography variant="body_reg">
						{formatDateToText(new Date(integrationAuditLog?.date || ""), timezone)}
					</Typography>
				</div>

				<div className={classes.field} ref={innerRef}>
					<IconPrefix Icon={DurationIcon} content={t(`${INTEGRATION_INFO_PREFIX}.fields.time`)} semibold />
					<Typography variant="body_reg">
						{formatTimeToText(new Date(integrationAuditLog?.date || ""), timezone)}
					</Typography>
				</div>

				<div className={classes.field} ref={innerRef}>
					<IconPrefix Icon={IntegrationIcon} content={t(`${INTEGRATION_INFO_PREFIX}.fields.integration`)} semibold />
					{integration ? (
						<IconPrefix
							Icon={getDynamicSizeIcon(<IntegrationImage noWrap size="xs" integration={integration} />)}
							content={integration.name}
							semibold
						/>
					) : null}
				</div>

				<div className={classes.field} ref={innerRef}>
					<IconPrefix Icon={UserCircleIcon} content={t(`${INTEGRATION_INFO_PREFIX}.fields.user`)} semibold />
					<UsersCell rowUsers={integrationAuditLog?.users || []} isCell={false} />
				</div>

				<div className={classes.field} ref={innerRef}>
					<IconPrefix Icon={AccountIcon} content={t(`${INTEGRATION_INFO_PREFIX}.fields.account`)} semibold />
					{integrationAuditLog?.account ? <TooltipOnOverflow content={integrationAuditLog.account} /> : null}
				</div>

				<div className={classes.field} ref={innerRef}>
					<IconPrefix Icon={PermissionsIcon} content={t(`${INTEGRATION_INFO_PREFIX}.fields.permission`)} semibold />
					<PermissionsCell permissions={integrationAuditLog?.permissions || []} />
				</div>
			</div>
		</div>
	);
};
