import React, { useCallback, useMemo } from "react";
import uniq from "lodash/uniq";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { List } from "immutable";
import { TColumn, VirtualTable } from "components/ui/VirtualTable";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationImage } from "components/common/IntegrationImage";
import { notEmpty } from "utils/comparison";
import { Chip } from "components/ui/chips/Chip";
import { getTicketExpirationDate } from "utils/tickets/ticketExpirationDate";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { ExpiredIcon } from "components/ui/Icons/ExpiredIcon";
import { ChipContainerCellContent, HeaderCellContent, TextCellContent } from "components/ui/VirtualTable/components";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";

type TProps = {
	permissions?: List<UserEntitlementModel>;
};

const MY_PERMISSIONS_TABLE_PREFIX = "pages.myPermissions.myPermissionsTable";
const COLUMNS_WIDTHS = [
	"minmax(192px, 340px)",
	"minmax(160px, 240px)",
	"minmax(180px, 280px)",
	// The ideal is max- 1fr. The problem is that when the table grows these columns grow last.
	"minmax(176px, 2000px)",
	"minmax(176px, 2000px)",
	"160px",
	"188px"
] as const;

export const MyPermissionsTable: FC<TProps> = ({ className, innerRef, permissions }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();
	const permissionsArray = useMemo(() => permissions?.toArray() || [], [permissions]);
	const integrations = useIntegrations();

	const renderRequestNumber = useCallback(
		(entitlement: UserEntitlementModel, disabled = false) => {
			const tickets = uniq(
				(entitlement.ticketPermissions?.toArray() || [])
					.map(ticketPermission => ticketPermission.ticket)
					.filter(notEmpty)
			);

			const onChipClick = (ticketId: string) => {
				searchParams.set("ticketId", ticketId);
				setSearchParams(searchParams);
			};

			return tickets.map(ticket => (
				<Chip
					key={ticket.id}
					PrefixIcon={RequestsIcon}
					size="small"
					variant="regular"
					onClick={!disabled ? () => onChipClick(ticket.id) : undefined}>
					{ticket.number}
				</Chip>
			));
		},
		[searchParams, setSearchParams]
	);

	const columns = useMemo<TColumn<UserEntitlementModel>[]>(
		() => [
			{
				renderCell: TextCellContent,
				getProps: row => {
					const integration = integrations?.get(row.role?.integrationResource?.integrationId || "");
					return {
						icon: <IntegrationImage noWrap size="xs" integration={integration} />,
						text: integration?.name ?? ""
					};
				},
				header: (
					<HeaderCellContent
						text={t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.integration`)}
						icon={<IntegrationIcon />}
					/>
				),
				key: "integration",
				width: COLUMNS_WIDTHS[0]
			},
			{
				renderCell: TextCellContent,
				getProps: row => ({ text: row.actor?.displayName ?? "" }),
				header: <HeaderCellContent text={t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.account`)} icon={<AccountIcon />} />,
				key: "account",
				width: COLUMNS_WIDTHS[1]
			},
			{
				renderCell: TextCellContent,
				getProps: row => ({ text: row.role?.integrationResource?.type ?? "" }),
				header: <HeaderCellContent text={t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.resourceType`)} />,
				key: "resourceType",
				width: COLUMNS_WIDTHS[2]
			},
			{
				renderCell: TextCellContent,
				getProps: row => ({ text: row.role?.integrationResource?.name ?? "" }),
				header: (
					<HeaderCellContent text={t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.resource`)} icon={<ResourcesIcon />} />
				),
				key: "resource",
				width: COLUMNS_WIDTHS[3]
			},
			{
				renderCell: TextCellContent,
				getProps: row => ({ text: row.role?.name ?? "" }),
				header: <HeaderCellContent text={t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.role`)} icon={<RoleIcon />} />,
				key: "role",
				width: COLUMNS_WIDTHS[4]
			},
			{
				renderCell: TextCellContent,
				getProps: row => {
					const expiresAt = getTicketExpirationDate(row.ticketPermissions);
					return { text: expiresAt ? t("dateTime.date", { date: expiresAt }) : t("shared.ticketExpiration.never") };
				},
				header: (
					<HeaderCellContent text={t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.expiration`)} icon={<ExpiredIcon />} />
				),
				key: "expiration",
				width: COLUMNS_WIDTHS[5]
			},
			{
				renderCell: ChipContainerCellContent,
				getProps: (row, options) => ({
					chips: renderRequestNumber(row, options.disabled || false),
					size: "small"
				}),
				header: <HeaderCellContent text={t("common.requestDateAndNumber.requestNumber")} />,
				key: "requestNumber",
				width: COLUMNS_WIDTHS[6]
			}
		],
		[integrations, t, renderRequestNumber]
	);

	return (
		<VirtualTable
			rows={permissionsArray}
			totalRows={permissionsArray.length}
			columns={columns}
			className={className}
			innerRef={innerRef}
		/>
	);
};
