import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/legacy/Typography";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { TooltipOnOverflow } from "components/ui/legacy/TooltipOnOverflow";
import { AccountNode } from "components/common/Nodes/AccountNode";

type TAccountProps = {
	account: { id: string; displayName: string };
	refSetter?: (ids: string[]) => (element: unknown) => void;
	selected?: boolean;
};

type TAccountsProps = {
	accountIds: string[];
	refSetter?: (ids: string[]) => (element: unknown) => void;
	onCollapse?: () => void;
	onExpand: () => void;
};

type TCollapseProps = {
	collapse: true;
	onCollapse: () => void;
};

type TVertexProps = TAccountProps | TAccountsProps | TCollapseProps;

const isVertexProps = (props: TProps<TVertexProps>): props is TAccountProps | TAccountsProps => {
	return isAccountProps(props) || "accountIds" in props;
};
const isAccountProps = (props: TProps<TVertexProps>): props is TAccountProps => {
	return "account" in props;
};

const getRef = (props: TProps<TVertexProps>) => {
	if (!isVertexProps(props)) {
		return props.innerRef;
	} else if (isAccountProps(props)) {
		const ids = [props.account.id];
		return props.refSetter ? props.refSetter(ids) : props.innerRef;
	} else {
		return props.refSetter ? props.refSetter(props.accountIds) : props.innerRef;
	}
};

export const AccountVertex: FC<TVertexProps> = props => {
	const { t } = useTranslation();
	const ref = getRef(props);
	const isAccount = isAccountProps(props);
	const isAccounts = isVertexProps(props) && !isAccount;
	const selected = isAccount && props.selected;

	const { content, actions } = useMemo(() => {
		if (isAccount) {
			return {
				content: <TooltipOnOverflow textVariant="small" content={props.account.displayName} />,
				actions: null
			};
		}
		if (isAccounts) {
			return {
				content: <Typography variant="small">+{props.accountIds.length}</Typography>,
				actions: (
					<>
						{props.onCollapse ? <ChevronUpIcon onClick={props.onCollapse} /> : null}
						<ChevronDownIcon onClick={props.onExpand} />
					</>
				)
			};
		}
		return {
			content: <Typography variant="small">{t("common.entities.collapse")}</Typography>,
			actions: <ChevronUpIcon onClick={props.onCollapse} />
		};
	}, [isAccount, isAccounts, props, t]);
	return (
		<AccountNode className={props.className} content={content} actions={actions} selected={selected} innerRef={ref} />
	);
};
