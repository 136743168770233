import { useTicketRenewalContext } from "context/renewalTicketContext";

export const useTicketRenewal = (ticketId: string) => {
	const {
		state: { renewedIds },
		actions: { renewTicket }
	} = useTicketRenewalContext();
	const renewed = renewedIds.has(ticketId);

	return { renewed, renewTicket };
};
