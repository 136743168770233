import { useEffect, useState } from "react";
import constate from "constate";
import { useSubscriber } from "hooks/useSubscriber";
import { TBulkActionProgressData, useAdminLiveUpdatesContext } from "./adminLiveUpdatesContext";

const useBulkActionsUpdates = () => {
	const [isBulkActionInProgress, setIsBulkActionInProgress] = useState(false);
	const [isBulkActionFailed, setIsBulkActionFailed] = useState(false);
	const { subscribeToBulkActionsUpdate, unsubscribeFromBulkActionsUpdate } = useAdminLiveUpdatesContext();
	const { addSubscription, notify, removeSubscription } = useSubscriber();

	useEffect(() => {
		const onBulkActionsUpdate = (data: TBulkActionProgressData) => {
			if ("error" in data) {
				if (data.error) {
					setIsBulkActionInProgress(false);
					notify({ type: "error" });
				}
			} else if (data.progressPercentage === 100) {
				setIsBulkActionInProgress(false);
				notify({ type: "done" });
			} else {
				setIsBulkActionInProgress(true);
			}
		};

		subscribeToBulkActionsUpdate(onBulkActionsUpdate);

		return () => {
			unsubscribeFromBulkActionsUpdate(onBulkActionsUpdate);
		};
	}, [notify, subscribeToBulkActionsUpdate, unsubscribeFromBulkActionsUpdate]);

	return {
		subscribeOnDone: addSubscription,
		unsubscribeOnDone: removeSubscription,
		isBulkActionInProgress,
		isBulkActionFailed,
		setIsBulkActionInProgress,
		setIsBulkActionFailed
	};
};

export const [BulkActionsUpdatesProvider, useBulkActionsUpdatesContext] = constate(useBulkActionsUpdates);
