import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	spinner: {
		margin: "3rem auto"
	},
	auditLogsPageContent: {
		justifyContent: "flex-start",
		backgroundColor: "white",
		height: "100%"
	},
	drawer: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start"
	},
	tabsContentContainer: {
		display: "flex",
		gap: "var(--spacing-x5, 20px)",
		width: "100%",
		height: "100%"
	}
});
