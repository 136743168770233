import React, { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Modal } from "components/ui/Modal";
import { Button } from "components/ui/Button";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Typography } from "components/ui/Typography";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { TitleBody } from "components/ui/TitleBody";
import { WarningIcon } from "components/ui/Icons/WarningIcon";
import { useStyles } from "./styles";

type TProps = {
	close: () => void;
	isOpen: boolean;
	success?: boolean;
	error?: boolean;
	retry: () => void;
	successMessage: string;
	errorTitle: string;
	errorContent: string;
	loadingMessage: string;
	goBackMessage: string;
	retryMessage?: string;
	navigateTo?: string | number;
};

const SUCCESS_TIMEOUT_IN_MS = 1000;

export const SubmitModal: FC<TProps> = ({
	className,
	innerRef,
	success,
	close,
	error,
	retry,
	isOpen,
	successMessage,
	errorTitle,
	errorContent,
	loadingMessage,
	goBackMessage,
	retryMessage,
	navigateTo = -1
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();

	const goBack = useCallback(() => {
		close();
	}, [close]);

	const modalContent = useMemo(() => {
		if (success) {
			return (
				<div className={classes.successContent}>
					<GrantedIcon size={80} />
					<Typography variant="title_sb">{successMessage}</Typography>
				</div>
			);
		}
		if (error) {
			return (
				<div className={classes.errorContent}>
					<WarningIcon size={40} />
					<TitleBody title={errorTitle} body={errorContent} className={classes.errorContentText} />
				</div>
			);
		}

		return (
			<div className={classes.loadingContent}>
				<LoadingSpinner size={80} />
				<Typography variant="title_sb">{loadingMessage}</Typography>
			</div>
		);
	}, [success, error, successMessage, errorTitle, errorContent, loadingMessage, classes]);

	const modalActions = useMemo(() => {
		if (!error) return null;
		return (
			<>
				<Button variant="secondary" size="medium" onClick={goBack}>
					{goBackMessage}
				</Button>
				<Button size="medium" onClick={retry} suffix={<RefreshIcon size={20} />}>
					{retryMessage ?? t("buttons.retry")}
				</Button>
			</>
		);
	}, [error, goBack, retry, t, retryMessage, goBackMessage]);

	useEffect(() => {
		if (!success) return;

		const timeout = setTimeout(() => {
			close();
			goBack();
			typeof navigateTo === "number" ? navigate(navigateTo) : navigate(navigateTo); // method overloading is shit
		}, SUCCESS_TIMEOUT_IN_MS);
		return () => clearTimeout(timeout);
	}, [goBack, success, close, navigate, navigateTo]);

	return (
		<Modal
			actions={modalActions}
			className={classNames(classes.modal, className)}
			content={modalContent}
			innerRef={innerRef}
			isOpen={isOpen}
			noTopCloseButton
			onClose={close}
		/>
	);
};
