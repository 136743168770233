import constate from "constate";
import { Map } from "immutable";
import { useCallback, useMemo } from "react";
import { ApprovalAlgorithmModel, IApprovalAlgorithmSchema } from "models/ApprovalAlgorithmModel";
import {
	createApprovalAlgorithm,
	deleteApprovalAlgorithm as apiDeleteApprovalAlgorithm,
	getApprovalAlgorithms,
	updateApprovalAlgorithm as apiUpdateApprovalAlgorithm
} from "api/approvalAlgorithms";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useFetchedState } from "hooks/useFetchedState";

const useApprovalAlgorithms = () => {
	const {
		data: approvalAlgorithms,
		setData: setApprovalAlgorithms,
		loadData: loadApprovalAlgorithms
	} = useFetchedState(getApprovalAlgorithms);
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const getMap = useCallback(async (): Promise<Map<string, ApprovalAlgorithmModel>> => {
		if (approvalAlgorithms) return approvalAlgorithms;
		try {
			return getApprovalAlgorithms();
		} catch (err) {
			openGlobalErrorModal(err as Error);
			return Map();
		}
	}, [approvalAlgorithms, openGlobalErrorModal]);

	const saveApprovalAlgorithm = useCallback(
		async (approvalAlgorithm: ApprovalAlgorithmModel | IApprovalAlgorithmSchema) => {
			const savedAlgorithm = await createApprovalAlgorithm(approvalAlgorithm);
			const newApprovalAlgorithms = (await getMap()).set(savedAlgorithm.id, savedAlgorithm);
			setApprovalAlgorithms(newApprovalAlgorithms);
			return savedAlgorithm;
		},
		[getMap, setApprovalAlgorithms]
	);

	const updateApprovalAlgorithm = useCallback(
		async (approvalAlgorithm: ApprovalAlgorithmModel | IApprovalAlgorithmSchema) => {
			try {
				const updatedAlgorithm = await apiUpdateApprovalAlgorithm(approvalAlgorithm);
				const newApprovalAlgorithms = (await getMap()).set(updatedAlgorithm.id, updatedAlgorithm);
				setApprovalAlgorithms(newApprovalAlgorithms);
				return updatedAlgorithm;
			} catch (err) {
				openGlobalErrorModal(err as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal, setApprovalAlgorithms]
	);

	const deleteApprovalAlgorithm = useCallback(
		async (approvalAlgorithmId: string) => {
			const approvalAlgorithm = (await getMap()).get(approvalAlgorithmId);
			if (!approvalAlgorithm) return;
			try {
				await apiDeleteApprovalAlgorithm(approvalAlgorithm);
			} catch (err) {
				openGlobalErrorModal(err as Error);
				return;
			}
			const newApprovalAlgorithms = (await getMap()).setIn([approvalAlgorithmId, "isDeleted"], true);

			setApprovalAlgorithms(newApprovalAlgorithms);
		},
		[getMap, openGlobalErrorModal, setApprovalAlgorithms]
	);

	const sortedApprovalAlgorithms = useMemo(
		() => approvalAlgorithms?.toList()?.sortBy(approvalAlgorithm => approvalAlgorithm.name),
		[approvalAlgorithms]
	);

	return useMemo(
		() => ({
			state: { approvalAlgorithms, sortedApprovalAlgorithms },
			actions: { loadApprovalAlgorithms, saveApprovalAlgorithm, updateApprovalAlgorithm, deleteApprovalAlgorithm }
		}),
		[
			approvalAlgorithms,
			sortedApprovalAlgorithms,
			loadApprovalAlgorithms,
			saveApprovalAlgorithm,
			updateApprovalAlgorithm,
			deleteApprovalAlgorithm
		]
	);
};

export const [ApprovalAlgorithmsProvider, useApprovalAlgorithmsContext] = constate(useApprovalAlgorithms);
