import React, { useCallback, useEffect, useState } from "react";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Title } from "components/ui/Title";
import { Button } from "components/ui/Button";
import { Typography } from "components/ui/legacy/Typography";
import { useTranslation } from "react-i18next";
import { Table } from "components/ui/Table";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useIdentityProvidersContext } from "context/identityProvidersContext";
import { IdentityProvider } from "./IdentityProvider";
import { useStyles } from "./styles";
import type { IAreYouSureModalProps } from "components/pages/SettingsPage";

interface IProps {
	openAreYouSureModal: (props: IAreYouSureModalProps) => void;
}

export const IdentityProviders: FC<IProps> = ({ className, openAreYouSureModal }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		state: { identityProviders, isLoading },
		actions: { deleteIdentityProvider, loadIdentityProviders }
	} = useIdentityProvidersContext();

	const [newIdentityProvider, setNewIdentityProvider] = useState<boolean>(false);

	const handleError = useOpenGlobalErrorModal();

	const clearNewIdentityProvider = useCallback(() => {
		setNewIdentityProvider(false);
	}, []);

	const openInput = useCallback(() => {
		setNewIdentityProvider(true);
	}, []);

	const onDelete = useCallback(
		(id: string) => {
			const onAction = async () => {
				try {
					await deleteIdentityProvider(id);
				} catch (error) {
					handleError(error as Error);
				}
			};

			openAreYouSureModal({
				onAction,
				title: t("pages.settings.identityProviders.areYouSure.title"),
				content: t("pages.settings.identityProviders.areYouSure.content")
			});
		},
		[deleteIdentityProvider, handleError, openAreYouSureModal, t]
	);

	useEffect(() => {
		clearNewIdentityProvider();
	}, [clearNewIdentityProvider]);

	useEffect(() => {
		if (!identityProviders) {
			loadIdentityProviders();
		}
	}, [identityProviders, loadIdentityProviders]);

	return (
		<>
			<Title variant="h3" noBorder className={classes.title} extraMargin>
				<span>{t("pages.settings.identityProviders.title")}</span>
				<Button onClick={openInput} variant="secondary" size="medium" prefix={<AddIcon />}>
					{t("buttons.add")}
				</Button>
			</Title>
			<div className={className}>
				{!isLoading ? (
					identityProviders?.isEmpty() && !newIdentityProvider ? (
						<Typography className={classes.noProviders} variant="h3">
							{t("pages.settings.identityProviders.empty")}
						</Typography>
					) : (
						<Table gridColumns="1fr 1fr 10rem">
							<Table.Row>
								<Table.Header>
									<Typography variant="small">{t("pages.settings.identityProviders.application")}</Typography>
								</Table.Header>
								<Table.Header>
									<Typography variant="small">{t("pages.settings.identityProviders.metadataUrl")}</Typography>
								</Table.Header>
							</Table.Row>
							{newIdentityProvider && <IdentityProvider close={clearNewIdentityProvider} />}
							{identityProviders?.map(idp => (
								<IdentityProvider key={idp.id} identityProvider={idp} onDelete={onDelete} />
							))}
						</Table>
					)
				) : (
					<LoadingSpinner />
				)}
			</div>
		</>
	);
};
