import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/ui/Table";
import { UserModel } from "models/UserModel";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { Button } from "components/ui/Button";
import { Select, type TTargetValue } from "components/ui/Select";
import { UserWithEmail } from "components/common/UserWithEmail";
import { DirectoryGroupWithEmail } from "components/common/DirectoryGroupWithEmail";
import { directoryIconRegex } from "utils/ui/directoryGroups";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { useUser } from "hooks/useUser";
import {
	isCustomerEntityDirectoryGroup,
	isCustomerEntityUser,
	useCustomerEntitiesSelect
} from "hooks/useCustomerEntitiesSelect";
import { DirectoryGroupMaintainerModel } from "models/DirectoryGroupMaintainerModel";
import { useStyles } from "./styles";
import type { List } from "immutable";

interface IDisplayProps {
	maintainer: TMaintainerModel;
	onRemove: (maintainer: TMaintainerModel) => Promise<void>;
}

interface ICreateProps {
	disabledIds: string[];
	cancel: () => void;
	onSubmit: (maintainer: UserModel | DirectoryGroupModel) => Promise<void>;
}

type TMaintainerProps = IDisplayProps | ICreateProps;

const UserMaintainer: React.FC<{ user: UserModel }> = ({ user }) => {
	return <UserWithEmail user={user} />;
};

const DirectoryGroupMaintainer: React.FC<{ group: DirectoryGroupModel }> = ({ group }) => {
	return <DirectoryGroupWithEmail directoryGroup={group} />;
};

const DisplayMaintainerRow: FC<IDisplayProps> = ({ maintainer, onRemove }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const classes = useStyles();

	const isDirectoryGroupMaintainer = maintainer instanceof DirectoryGroupMaintainerModel;
	const directoryGroup = useDirectoryGroup(isDirectoryGroupMaintainer ? maintainer.directoryGroupId : "");
	const user = useUser(!isDirectoryGroupMaintainer ? maintainer.userId : "");

	const entity: DirectoryGroupModel | UserModel | undefined | null = useMemo(() => {
		if (maintainer.entity) return maintainer.entity;
		return isDirectoryGroupMaintainer ? directoryGroup : user;
	}, [maintainer.entity, isDirectoryGroupMaintainer, user, directoryGroup]);

	const onClick = useCallback(async () => {
		setIsLoading(true);
		try {
			await onRemove(maintainer);
		} finally {
			setIsLoading(false);
		}
	}, [maintainer, onRemove]);

	if (!entity) return null;

	return (
		<Table.Row>
			<Table.Cell>
				<div>
					{entity instanceof DirectoryGroupModel ? (
						<DirectoryGroupMaintainer group={entity} />
					) : (
						<UserMaintainer user={entity} />
					)}
				</div>
			</Table.Cell>
			<Table.Cell className={classes.actionsCell}>
				<Button size="small" variant="text" disabled={isLoading} loading={isLoading} onClick={onClick}>
					{t("buttons.remove")}
				</Button>
			</Table.Cell>
		</Table.Row>
	);
};

interface IMaintainerOption {
	value: DirectoryGroupModel | UserModel;
	label: string;
}

const isOptionsEqual = (option1: IMaintainerOption, option2: IMaintainerOption) => {
	return option1.value.id === option2.value.id;
};

const renderOption = (option: IMaintainerOption) => {
	const entity = option.value;

	return entity instanceof DirectoryGroupModel ? (
		<DirectoryGroupMaintainer group={entity} />
	) : (
		<UserMaintainer user={entity} />
	);
};

const getOptionLabel = (option: IMaintainerOption) => {
	const matches = directoryIconRegex.exec(option.label);
	if (!matches) return option.label;
	return matches[2];
};

const CreateMaintainerRow: React.FC<ICreateProps> = ({ cancel, disabledIds, onSubmit }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [selected, setSelected] = useState<IMaintainerOption | null>(null);
	const [query, setQuery] = useState("");

	const { items, isLoading: isLoadingEntities } = useCustomerEntitiesSelect(query, {
		entities: ["directoryGroup", "user"]
	});

	const onInputChange = useCallback((event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	}, []);

	const onChange = useCallback((value: IMaintainerOption | null) => {
		if (!value) return;
		setSelected(value);
	}, []);

	const save = useCallback(async () => {
		if (!selected) return;
		setIsLoading(true);
		try {
			await onSubmit(selected?.value);
		} finally {
			setIsLoading(false);
		}
	}, [onSubmit, selected]);

	const options: IMaintainerOption[] = useMemo(() => {
		if (isLoadingEntities) return [];
		const filteredItems = items.filter(
			item =>
				(isCustomerEntityUser(item) || isCustomerEntityDirectoryGroup(item)) &&
				!item.isDeleted &&
				!disabledIds.includes(item.id)
		) as List<UserModel | DirectoryGroupModel>;
		return filteredItems
			.map(item => ({
				value: item,
				label: isCustomerEntityUser(item) ? item.fullName : item.name
			}))
			.toArray();
	}, [disabledIds, isLoadingEntities, items]);

	const getKey = useCallback((option: IMaintainerOption) => option.value.id, []);

	return (
		<Table.Row>
			<Table.Cell>
				<Select
					renderLabel={renderOption}
					getOptionKey={getKey}
					getOptionLabel={getOptionLabel}
					isOptionEqualToValue={isOptionsEqual}
					onChange={onChange}
					onInputChange={onInputChange}
					loading={isLoadingEntities}
					inputValue={query}
					options={options}
					renderOption={renderOption}
					variant="table"
				/>
			</Table.Cell>
			<Table.Cell className={classes.actionsCell}>
				<Button size="small" variant="text" disabled={!selected || isLoading} loading={isLoading} onClick={save}>
					{t("buttons.save")}
				</Button>
				<Button size="small" variant="text" disabled={isLoading} onClick={cancel}>
					{t("buttons.cancel")}
				</Button>
			</Table.Cell>
		</Table.Row>
	);
};

const isDisplayProps = (props: TMaintainerProps): props is IDisplayProps => {
	return "maintainer" in props;
};

export function Maintainer(props: TMaintainerProps) {
	if (isDisplayProps(props)) {
		return <DisplayMaintainerRow {...props} />;
	}
	return <CreateMaintainerRow {...props} />;
}
