import classNames from "classnames";
import { Checkbox } from "components/ui/Checkbox";
import { Typography } from "components/ui/legacy/Typography";
import React, { useCallback } from "react";
import { useStyles } from "./styles";

interface IProps {
	label: string;
	description?: string;
	selected?: boolean;
	onClick?: (event: React.MouseEvent) => void;
	disabled?: boolean;
	descriptionClassName?: string;
}

export const DescribedCheckbox: FC<IProps> = ({
	label,
	description,
	selected,
	onClick: propsOnClick,
	disabled = false,
	descriptionClassName
}) => {
	const classes = useStyles();
	const onClick = useCallback(
		(event: React.MouseEvent) => !disabled && propsOnClick && propsOnClick(event),
		[disabled, propsOnClick]
	);

	return (
		<div className={classes.checkboxContainer}>
			<Checkbox selected={selected} onClick={onClick} disabled={disabled} />
			<div onClick={onClick} className={classNames(classes.descriptionContainer, { [classes.disabled]: disabled })}>
				<Typography className={classes.label} variant="small">
					{label}
				</Typography>
				{description && (
					<Typography className={classNames(classes.description, descriptionClassName)} variant="tiny">
						{description}
					</Typography>
				)}
			</div>
		</div>
	);
};
