import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { List } from "immutable";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { Modal } from "components/ui/Modal";
import { TitleBody } from "components/ui/TitleBody";
import { useIntegrations } from "hooks/useIntegrations";
import { RoleBar, TRoleBarColumn } from "components/common/RoleBar";
import {
	ROLE_BAR_INTEGRATION_WIDTH,
	RoleBarIntegration
} from "components/common/RoleBar/components/RoleBarIntegration";
import { ROLE_BAR_RESOURCE_WIDTH, RoleBarResource } from "components/common/RoleBar/components/RoleBarResource";
import { ROLE_BAR_ROLE_WIDTH, RoleBarRole } from "components/common/RoleBar/components/RoleBarRole";
import { useStyles } from "./styles";

type TProps = {
	isOpen: boolean;
	onClose: () => void;
	prerequisitePermissions: List<IntegrationResourceRoleModel>;
};

const PrerequisitePermission: FC<{ role: IntegrationResourceRoleModel }> = ({ role }) => {
	const integrations = useIntegrations();

	const columns = useMemo(() => {
		if (!integrations) return null;
		const resource = role.integrationResource;
		const integration = integrations.get(resource?.integrationId || "");
		if (!integration || !resource) return null;
		const roleColumns: TRoleBarColumn[] = [
			{
				content: <RoleBarIntegration name={integration.name} imageUrl={integration.imageUrl} />,
				width: ROLE_BAR_INTEGRATION_WIDTH
			},
			{
				content: (
					<RoleBarResource
						name={resource.name}
						euid={resource.euid}
						description={resource.description || undefined}
						tags={resource.calculatedTags}
						type={resource.type}
					/>
				),
				width: ROLE_BAR_RESOURCE_WIDTH
			},
			{
				content: <RoleBarRole name={role.name} />,
				width: ROLE_BAR_ROLE_WIDTH
			}
		];
		return roleColumns;
	}, [integrations, role.integrationResource, role.name]);

	if (!columns) return null;
	return <RoleBar columns={columns} />;
};

export const PrerequisitePermissionsModal: FC<TProps> = ({
	className,
	innerRef,
	isOpen,
	onClose,
	prerequisitePermissions
}) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "pages.newRequest.summaryStep.requestTargetsSection.prerequisitesModal"
	});
	const classes = useStyles();

	return (
		<Modal
			isOpen={isOpen}
			className={classNames(classes.modal, className)}
			onClose={onClose}
			innerRef={innerRef}
			content={
				<div className={classes.content}>
					<TitleBody size="large" title={t("title")} body={t("content")} />
					<div className={classes.roleBars}>
						{prerequisitePermissions.toArray().map(role => (
							<PrerequisitePermission key={role.id} role={role} />
						))}
					</div>
				</div>
			}
		/>
	);
};
