import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useIdentityGraphContext } from "components/pages/IdentityGraphPage/identityGraphContext";
import { Form } from "components/ui/Form";
import { RedoIcon } from "components/ui/Icons/RedoIcon";
import { UndoIcon } from "components/ui/Icons/UndoIcon";
import { Typography } from "components/ui/legacy/Typography";
import { IconButton } from "components/ui/IconButton";
import { UsersSelectInput } from "components/common/UsersSelectInput";
import { IntegrationsSelect } from "./components/IntegrationsSelect";
import { ResourceTypesSelect } from "./components/ResourceTypesSelect";
import { ResourcesSelect } from "./components/ResourcesSelect";
import { RolesSelect } from "./components/RolesSelect";
import { useStyles } from "./styles";

export const IdentityGraphHeader: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		state: { form, hasBack, hasForward },
		actions: { setFormValue, toState }
	} = useIdentityGraphContext();

	const setUsers = useCallback(
		(values: string[]) => setFormValue("userIds", values.length ? values : []),
		[setFormValue]
	);

	const setRole = useCallback((roleId: string | null) => setFormValue("roleId", roleId || undefined), [setFormValue]);

	const setResource = useCallback(
		(resourceId: string | null) => {
			setFormValue("resourceId", resourceId || undefined);
			setRole(null);
		},
		[setFormValue, setRole]
	);

	const setResourceType = useCallback(
		(resourceType: string | null) => {
			setFormValue("resourceType", resourceType || undefined);
			setResource(null);
		},
		[setFormValue, setResource]
	);

	const setIntegration = useCallback(
		(integrationId: string | null) => {
			setFormValue("integrationId", integrationId || undefined);
			setResourceType(null);
		},
		[setFormValue, setResourceType]
	);

	const forward = useCallback(() => toState("forward"), [toState]);
	const back = useCallback(() => toState("back"), [toState]);

	return (
		<Typography component="div" variant="standard" className={classNames(classes.header, className)}>
			<Form className={classes.headerForm}>
				<Form.Field className={classes.field}>
					<UsersSelectInput
						className={classes.select}
						label={t("pages.identityGraph.header.usersSelect.label")}
						onChange={setUsers}
						placeholder={t("pages.identityGraph.header.usersSelect.placeholder")}
						selectedIds={form.userIds || []}
					/>
				</Form.Field>
				<Form.Field className={classes.field}>
					<IntegrationsSelect
						className={classes.select}
						onChange={setIntegration}
						value={form.integrationId || null}
						byId
					/>
				</Form.Field>
				<Form.Field className={classes.field}>
					<ResourceTypesSelect
						className={classes.select}
						disabled={!form.integrationId}
						integrationId={form.integrationId || null}
						onChange={setResourceType}
						value={form.resourceType || null}
					/>
				</Form.Field>
				<Form.Field className={classes.field}>
					<ResourcesSelect
						byId
						className={classes.select}
						disabled={!form.integrationId}
						integrationId={form.integrationId || null}
						onChange={setResource}
						resourceType={form.resourceType || null}
						value={form.resourceId}
					/>
				</Form.Field>
				<Form.Field className={classes.field}>
					<RolesSelect
						byId
						className={classes.select}
						disabled={!form.resourceId}
						resourceId={form.resourceId || null}
						onChange={setRole}
						value={form.roleId || null}
					/>
				</Form.Field>
			</Form>
			<div className={classes.historyActions}>
				<IconButton size="large" disabled={!hasBack} onClick={back}>
					<UndoIcon />
				</IconButton>
				<IconButton size="large" disabled={!hasForward} onClick={forward}>
					<RedoIcon />
				</IconButton>
			</div>
		</Typography>
	);
};
