import type { IFilter } from "types/filters";
import type { TPaginationSortOptions } from "types/pagination";

export function mergeURLSearchParams(searchParams: URLSearchParams[]): URLSearchParams {
	const mergedSearchParams = new URLSearchParams();
	searchParams.forEach(params => {
		for (const [key, value] of params) {
			mergedSearchParams.append(key, value);
		}
	});
	return mergedSearchParams;
}

export function fromPaginationSortOptions(options: TPaginationSortOptions): URLSearchParams {
	const searchParams = new URLSearchParams();
	if (options.page) {
		searchParams.append("page", options.page.toString());
	}
	if (options.perPage) {
		searchParams.append("perPage", options.perPage.toString());
	}
	if (options.order) {
		searchParams.append("sortOrder", options.order);
	}
	if (options.sortFields) {
		const fields = Array.isArray(options.sortFields) ? options.sortFields : [options.sortFields];
		fields.forEach(field => searchParams.append("sortFields", field));
	}
	return searchParams;
}

export function fromFilters(filters: IFilter[]): URLSearchParams {
	return mergeURLSearchParams(filters.map(filter => filter.toURLSearchParams()));
}

export function fromPaginationSortFilterOptions(
	options?: TPaginationSortOptions & { filters?: IFilter[] }
): URLSearchParams {
	if (!options) {
		return new URLSearchParams();
	}
	const searchParams = fromPaginationSortOptions(options);
	const filterParams = fromFilters(options.filters || []);
	return mergeURLSearchParams([searchParams, filterParams]);
}
