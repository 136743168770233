import { useEffect, useMemo } from "react";
import { List } from "immutable";
import { useDirectoryGroupsContext } from "context/directoryGroupsContext";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import {
	type ICustomerEntity,
	type TCustomerEntityType,
	getPaginatedCustomerEntities,
	type TCustomerEntityModels
} from "api/customerEntities";
import { useUsersContext } from "context/usersContext";
import { notEmpty } from "utils/comparison";
import { UserModel } from "models/UserModel";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { ApprovalFlowsWebhookModel } from "models/ApprovalFlowsWebhookModel";
import { useMultiDirectoryGroups } from "./useMultiDirectoryGroups";
import { useDebouncedPaginatedSearch } from "./useDebouncedPaginationSearch";
import { useMultiUsers } from "./useMultiUsers";
import { useApprovalFlowsWebhooksMap } from "./useApprovalFlowsWebhooksMap";
import { useOnCallIntegrationSchedules } from "./useOnCallIntegrationSchedules";
import type { TUsePaginationOptions } from "./usePagination";

type TDirectoryGroupsDebouncedPaginationOptions = Omit<TUsePaginationOptions<List<ICustomerEntity>>, "fetchItems"> & {
	wait?: number;
	entities?: TCustomerEntityType[];
	selectedIdsByType?: TCustomerEntitiesSelectedIds;
};

export type TCustomerEntitiesSelectedIds = Partial<Record<TCustomerEntityType, string[] | undefined>>;

export const isCustomerEntityUser = (item: TCustomerEntityModels): item is UserModel => item instanceof UserModel;
export const isCustomerEntityDirectoryGroup = (item: TCustomerEntityModels): item is DirectoryGroupModel =>
	item instanceof DirectoryGroupModel;
export const isCustomerEntityOnCallIntegrationSchedule = (
	item: TCustomerEntityModels
): item is OnCallIntegrationScheduleModel => item instanceof OnCallIntegrationScheduleModel;
export const isCustomerEntityApprovalFlowsWebhook = (item: TCustomerEntityModels): item is ApprovalFlowsWebhookModel =>
	item instanceof ApprovalFlowsWebhookModel;

const useSelectedEntities = (selectedIdsByType: TCustomerEntitiesSelectedIds) => {
	const onCallIntegrationSchedulesMap = useOnCallIntegrationSchedules();
	const approvalFlowsWebhooksMap = useApprovalFlowsWebhooksMap();
	const selectedDirectoryGroupIds = useMemo(
		() => selectedIdsByType.directoryGroup || [],
		[selectedIdsByType.directoryGroup]
	);
	const selectedUserIds = useMemo(() => selectedIdsByType.user || [], [selectedIdsByType.user]);
	// Type safety in separate line for readability
	let selectedDirectoryGroups = useMultiDirectoryGroups(selectedDirectoryGroupIds, true);
	selectedDirectoryGroups = selectedDirectoryGroups || List<DirectoryGroupModel>();
	const selectedUsersMap = useMultiUsers(selectedUserIds, true, true);
	const selectedUsers = useMemo(() => selectedUsersMap?.toList() || List<UserModel>(), [selectedUsersMap]);
	const selectedOnCallIntegrationSchedules = useMemo(
		() =>
			selectedIdsByType.onCallIntegrationSchedule?.map(id => onCallIntegrationSchedulesMap?.get(id)).filter(notEmpty) ||
			[],
		[onCallIntegrationSchedulesMap, selectedIdsByType]
	);
	const selectedApprovalFlowsWebhooks = useMemo(
		() => selectedIdsByType.approvalFlowWebhook?.map(id => approvalFlowsWebhooksMap?.get(id)).filter(notEmpty) || [],
		[approvalFlowsWebhooksMap, selectedIdsByType]
	);

	return {
		selectedDirectoryGroups,
		selectedUsers,
		selectedOnCallIntegrationSchedules,
		selectedApprovalFlowsWebhooks
	};
};

/*
 * This hook performs three main tasks:
 * 1. Fetches the selected IDs and adds them to the contexts of users and directory groups.
 * 2. Fetches a paginated list of a the selected entities list and adds them to the directory groups and users context.
 * 3. Manages the debounced pagination of the selected entities.
 */
export function useCustomerEntitiesSelect<T extends TCustomerEntityModels = TCustomerEntityModels>(
	query: string,
	options: TDirectoryGroupsDebouncedPaginationOptions
) {
	const {
		selectedIdsByType = {},
		entities = ["directoryGroup", "user", "onCallIntegrationSchedule", "approvalFlowWebhook"],
		...paginationAndDebounceOptions
	} = options;
	const onCallIntegrationSchedulesMap = useOnCallIntegrationSchedules();
	const approvalFlowsWebhooksMap = useApprovalFlowsWebhooksMap();

	const { selectedDirectoryGroups, selectedUsers, selectedOnCallIntegrationSchedules, selectedApprovalFlowsWebhooks } =
		useSelectedEntities(selectedIdsByType);

	const {
		actions: { addGroups },
		state: { directoryGroupsById: directoryGroups }
	} = useDirectoryGroupsContext();

	const {
		actions: { addUsers },
		state: { users }
	} = useUsersContext();

	const pagination = useDebouncedPaginatedSearch(query, {
		fetchItems: getPaginatedCustomerEntities,
		payload: {
			entities
		},
		...paginationAndDebounceOptions
	});
	const { getPageDebounced } = pagination;

	const allItems = useMemo(() => {
		let itemsList = List<TCustomerEntityModels>();
		if (entities.includes("directoryGroup")) {
			itemsList = itemsList.concat(directoryGroups.toList().filter(notEmpty));
		}
		if (entities.includes("user")) {
			itemsList = itemsList.concat(users.toList().filter(notEmpty));
		}
		if (entities.includes("onCallIntegrationSchedule") && onCallIntegrationSchedulesMap) {
			itemsList = itemsList.concat(onCallIntegrationSchedulesMap.toList());
		}
		if (entities.includes("approvalFlowWebhook") && approvalFlowsWebhooksMap) {
			itemsList = itemsList.concat(approvalFlowsWebhooksMap.toList());
		}

		return itemsList as List<T>;
	}, [approvalFlowsWebhooksMap, directoryGroups, entities, onCallIntegrationSchedulesMap, users]);

	useEffect(() => {
		if (pagination.items?.size) {
			const groupingByType = pagination.items.groupBy(item => item.type);
			const directoryGroups =
				groupingByType
					.get("directoryGroup")
					?.map(({ entity }) => entity as DirectoryGroupModel)
					.toArray() || [];
			const users =
				groupingByType
					.get("user")
					?.map(({ entity }) => entity as UserModel)
					.toArray() || [];
			addGroups(directoryGroups);
			addUsers(users);
		}
	}, [addGroups, addUsers, pagination.items]);

	useEffect(() => getPageDebounced(), [getPageDebounced, query]);

	const selectedItems = useMemo(
		() =>
			selectedDirectoryGroups
				.concat(selectedUsers)
				.concat(selectedOnCallIntegrationSchedules)
				.concat(selectedApprovalFlowsWebhooks) as List<T>,
		[selectedApprovalFlowsWebhooks, selectedDirectoryGroups, selectedOnCallIntegrationSchedules, selectedUsers]
	);

	const filteredItems = useMemo(() => {
		if (query) return (pagination.items?.map(customerEntity => customerEntity.entity) as List<T>) ?? List<T>();
		return allItems;
	}, [allItems, pagination.items, query]);

	return {
		...pagination,
		items: filteredItems,
		selectedItems
	};
}
