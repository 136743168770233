import { devLog, isDevelopment } from "./devLogging";

if (isDevelopment) {
	try {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const useWhatChanged = require("@simbathesailor/use-what-changed");
		useWhatChanged.setUseWhatChange(true);
	} catch (e) {
		devLog({ message: "Could not load use-what-changed", level: "warn" });
		devLog({ message: "For extra information: ", extra: { error: e }, level: "warn" });
	}
}
