import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	entitleLogsSection: {
		paddingTop: "var(--spacing-x7, 28px)"
	},
	rightTitle: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-end",
		flexDirection: "row",
		flexWrap: "nowrap",
		gap: "var(--spacing-x2, 8px)"
	}
});
