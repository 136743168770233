import { Map, OrderedMap } from "immutable";

type TGetKey<T, K> = (value: T) => K;

export const toMapBy: {
	<T, K>(collection: Iterable<T>, byKey: TGetKey<T, K>): Map<K, T>;
	<T, K>(collection: Iterable<T>, byKey: TGetKey<T, K>, orderedMap: true): OrderedMap<K, T>;
} = <T, K>(collection: Iterable<T>, byKey: TGetKey<T, K>, orderedMap?: boolean) => {
	let map: Map<K, T> | OrderedMap<K, T> = orderedMap ? OrderedMap<K, T>() : Map<K, T>();
	for (const value of collection) {
		const key = byKey(value);
		map = map.set(key, value);
	}
	return map;
};

export const toGroupMapBy = <T, K>(collection: Iterable<T>, toKey: (value: T) => K) => {
	let map = Map<K, T[]>();
	for (const value of collection) {
		const key = toKey(value);
		if (!map.has(key)) {
			map = map.set(key, []);
		}
		map.get(key)!.push(value);
	}
	return map;
};
