import React from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationLogsFields } from "api/sessionAuditLogs";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TTargetsFilterExpressionProps = {
	onRemove: () => void;
};

export const TargetsFilterExpression: FC<TTargetsFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();

	return (
		<IntegrationLogsMultiSelectFilterExpression
			title={t("pages.auditLog.integrationLogs.filter.target")}
			fetchOptions={getIntegrationLogsFields}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.targetPlaceholder")}
			filterEmptyState={
				<FilterExpressionEmptyState text={t("pages.auditLog.integrationLogs.filter.targetEmptyState")} />
			}
			onFilterRemove={onRemove}
			filterField="target_display_name"
			filterName="target"
		/>
	);
};
