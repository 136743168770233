import React, { useCallback, useMemo, useState } from "react";
import { getTagValidators } from "utils/validation/validationUtils";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/Input";
import { TagChip } from "components/ui/chips/TagChip";
import { useStyles } from "./styles";

interface IProps {
	label: string;
	values: string[];
	onChange: (value: string[]) => void;
	readonlyValues?: string[];
}

export const TagsInput: FC<IProps> = ({ label, values, onChange, readonlyValues }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [value, setValue] = useState("");
	const validators = useMemo(() => getTagValidators(t("shared.tag")), [t]);
	const onDelete = useCallback(
		(tag: string) => {
			onChange(values.filter(value => value !== tag));
		},
		[onChange, values]
	);
	const onEnterClick = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "Enter") {
				const value = event.currentTarget.value;
				if (value && !values.includes(value)) {
					onChange([...values, value]);
					setValue("");
				}
			}
		},
		[onChange, values]
	);

	return (
		<div className={classes.container}>
			<Input label={label} onKeyDown={onEnterClick} onValueChange={setValue} validators={validators} value={value} />
			<div className={classes.tagsContainer}>
				{values.map(tag => (
					<TagChip key={tag} selected onDelete={() => onDelete(tag)}>
						{tag}
					</TagChip>
				))}
				{readonlyValues
					? readonlyValues.map(tag => (
							<TagChip key={tag} selected>
								{tag}
							</TagChip>
						))
					: null}
			</div>
		</div>
	);
};
