import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { useTicket } from "hooks/useTicket";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useTranslation } from "react-i18next";
import { addTicketComment } from "api/ticketComments";
import { TicketCommentModel } from "models/TicketCommentModel";
import { TicketRenewalProvider } from "context/renewalTicketContext";
import { LoadingDots } from "components/ui/LoadingDots";
import { Typography } from "components/ui/Typography";
import { BP } from "utils/ui/breakpoints";
import { Divider } from "components/ui/Divider";
import { DraggableSidebar, RESIZER_WIDTH_PX } from "components/common/DraggableSidebar";
import { RequestDateAndNumber } from "components/common/RequestDateAndNumber";
import { useObserverContext } from "context/observerContext";
import { TicketActivity } from "./components/TicketActivity";
import { GeneralInfo } from "./components/GeneralInfo";
import { RequestDetailsRequests } from "./components/Requests";
import { ApprovalProcess } from "./components/ApprovalProcess";
import { RequestDetailsFooter } from "./RequestDetailsFooter";
import { ACTIVITY_WIDTH_PX, MIN_DETAILS_WIDTH_PX, useStyles } from "./styles";
import type { TFullTicket } from "./types";
import type { TicketModel } from "models/TicketModel";

const APPROVED_TICKET_STATUSES = new Set<TFullTicket["status"]>(["approved", "permissionInProgress", "waitingForIT"]);
const REQUEST_DETAILS_SUBSCRIPTION_ID = "requestDetails";

const MIN_FULL_DETAILS_WIDTH_PX = 760 + RESIZER_WIDTH_PX;
const MIN_WIDTH_PX = ACTIVITY_WIDTH_PX + MIN_DETAILS_WIDTH_PX + RESIZER_WIDTH_PX;

export const RequestDetails: FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [withActivity, setWithActivity] = useState(document.body.clientWidth > BP.FORTY);
	const ticketId = searchParams.get("ticketId") || undefined;
	const { isOpen, open, close } = useIsOpenState(Boolean(ticketId));
	const { ticket: remoteTicket, loading, setTicket, reloadTicket } = useTicket(ticketId || "");
	const ticket = remoteTicket as TFullTicket | null;
	const { notifyTicketUpdate, subscribeTicketUpdates, unsubscribeTicketUpdates } = useTicketUpdatesContext();
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		state: { maxWidth },
		actions: { calculateObserver }
	} = useObserverContext();

	const closeSidebar = useCallback(() => {
		close();
		searchParams.delete("ticketId");
		setSearchParams(searchParams);
	}, [close, searchParams, setSearchParams]);

	const reloadTicketHandler = useCallback(
		async (ticket?: TicketModel) => {
			const updatedTicket = ticket ?? (await reloadTicket());
			notifyTicketUpdate(updatedTicket);
		},
		[notifyTicketUpdate, reloadTicket]
	);

	useEffect(() => {
		if (ticketId && !isOpen) {
			open();
		}
	}, [isOpen, open, ticketId]);

	useEffect(() => {
		subscribeTicketUpdates(REQUEST_DETAILS_SUBSCRIPTION_ID, updatedTicket => {
			if (ticketId === updatedTicket.id) {
				reloadTicket();
			}
		});
		return () => {
			unsubscribeTicketUpdates(REQUEST_DETAILS_SUBSCRIPTION_ID);
		};
	}, [reloadTicket, subscribeTicketUpdates, ticketId, unsubscribeTicketUpdates]);

	const addComment = useCallback(
		async (comment: string, reload: boolean) => {
			if (!ticket || !comment) return;
			const newComment = await addTicketComment(ticket.id, comment);
			if (ticket.comments) {
				setTicket(ticket.set("comments", ticket.comments.unshift(newComment)));
			}
			if (reload) await reloadTicketHandler();
		},
		[reloadTicketHandler, setTicket, ticket]
	);

	const updateComment = useCallback(
		(comment: TicketCommentModel) => {
			if (!ticket) return;
			if (ticket.comments) {
				const ticketIndex = ticket.comments.findIndex(({ id }) => id === comment.id);
				let updatedTicket = ticket.set("comments", ticket.comments.set(ticketIndex, comment));
				if (ticketIndex === 0) {
					updatedTicket = updatedTicket.set("justification", comment.content);
					notifyTicketUpdate(updatedTicket);
				}
				setTicket(updatedTicket);
			}
		},
		[setTicket, ticket, notifyTicketUpdate]
	);

	useEffect(() => {
		calculateObserver();
	}, [calculateObserver]);

	return (
		<TicketRenewalProvider>
			<DraggableSidebar
				className={classes.sidebarContent}
				maxWidth={maxWidth}
				initialWidth={withActivity ? MIN_FULL_DETAILS_WIDTH_PX + ACTIVITY_WIDTH_PX : MIN_FULL_DETAILS_WIDTH_PX}
				minWidth={withActivity ? MIN_WIDTH_PX : MIN_WIDTH_PX - ACTIVITY_WIDTH_PX}
				open={isOpen}
				closeSidebar={closeSidebar}>
				<div className={classes.requestDetailsSidebar}>
					{loading ? (
						<LoadingDots center />
					) : ticket ? (
						<div className={classes.detailsContainer}>
							<div className={classes.requestData}>
								<div className={classes.requestTitleContainer}>
									<Typography variant="title_sb">{t("common.requestDetails.title")}</Typography>
									<RequestDateAndNumber date={ticket.createdAt} number={ticket.number} />
								</div>
								<GeneralInfo ticket={ticket} />
								<Divider horizontal className={classes.divider} />
								{(ticket.ticketPermissions.size || 0) > 0 ? (
									<>
										<RequestDetailsRequests
											ticketTargets={ticket.targets}
											ticketPermissions={ticket.ticketPermissions}
											ticketApproved={APPROVED_TICKET_STATUSES.has(ticket.status)}
										/>
										<Divider horizontal className={classes.divider} />
										<ApprovalProcess
											approvalRequests={ticket.approvalRequests}
											approvalResponses={ticket.ticketApprovalResponses}
										/>
									</>
								) : null}
							</div>
							<RequestDetailsFooter ticket={ticket} reloadTicket={reloadTicketHandler} closeSidebar={closeSidebar} />
						</div>
					) : null}
					{withActivity && (
						<TicketActivity
							className={classes.ticketActivityContainer}
							addComment={addComment}
							ticket={ticket}
							updateComment={updateComment}
						/>
					)}
				</div>
			</DraggableSidebar>
		</TicketRenewalProvider>
	);
};
