import React from "react";
import { RuleIcon } from "components/ui/Icons/RuleIcon";
import { Typography } from "components/ui/Typography";
import { useTranslation } from "react-i18next";
import { Divider } from "components/ui/Divider";
import { useStyles } from "./styles";
import { ApplyToSection } from "./components/ApplyToSection";
import { SetConditionsSection } from "./components/SetConditionsSection/SetConditionsSection";
import { UpdatesSection } from "./components/UpdatesSection";

export const RuleDefinition: FC = React.memo(function RuleDefinition() {
	const { t } = useTranslation("translation", { keyPrefix: "pages.createRule.ruleDefinition" });
	const classes = useStyles();
	return (
		<div className={classes.drawerContent}>
			<div className={classes.ruleDefinitionTitleContainer}>
				<div className={classes.ruleDefinitionTitle}>
					<RuleIcon size={24} />
					<Typography variant="title_sb">{t("title")}</Typography>
				</div>
				<Divider horizontal />
			</div>
			<ApplyToSection />
			<Divider horizontal />
			<SetConditionsSection />
			<Divider horizontal />
			<UpdatesSection />
		</div>
	);
});
