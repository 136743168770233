import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLoadingState } from "hooks/useLoadingState";
import { Button } from "components/ui/Button";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import { useTicketRenewal } from "./ticketRenewalButton.hooks";

export const TicketRenewalButton: FC<{ ticketId: string }> = ({ ticketId }) => {
	const { isLoading, withLoader } = useLoadingState();
	const { t } = useTranslation();
	const { renewed, renewTicket } = useTicketRenewal(ticketId);
	const handleRenew = useCallback(() => withLoader(renewTicket(ticketId)), [renewTicket, ticketId, withLoader]);

	return (
		<Button
			size="medium"
			variant="secondary"
			loading={isLoading}
			disabled={isLoading || renewed}
			onClick={handleRenew}
			suffix={renewed ? <GrantedIcon /> : <RefreshIcon />}>
			{renewed ? t("common.ticketSidebar.renewed") : t("common.ticketSidebar.renew")}
		</Button>
	);
};
