import { apiReq } from "utils/api/apiReq";
import { CompanyModel } from "models/CompanyModel";
import { TTicketDuration } from "utils/durationsOptions";

export interface ICheckConfig {
	isValidConfig: boolean;
	message: string;
}

export async function getCompany() {
	const { data } = await apiReq("GET", "/v1/company");

	return CompanyModel.fromServerData(data);
}

export async function editCompany(company: Partial<CompanyModel>) {
	const { data } = await apiReq("PUT", "/v1/company", company);
	return CompanyModel.fromServerData(data);
}

export async function addAllowedDuration(duration: TTicketDuration) {
	await apiReq("POST", `/v1/company/allowedDurations/${duration}`);
}

export async function removeAllowedDuration(duration: TTicketDuration) {
	await apiReq("DELETE", `/v1/company/allowedDurations/${duration}`);
}
