import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import uniq from "lodash/uniq";
import { Modal } from "components/ui/Modal";
import { HelpBlock } from "components/ui/Block";
import { Typography } from "components/ui/legacy/Typography";
import { TitleBody } from "components/ui/TitleBody";
import { useUser } from "hooks/useUser";
import { Entity } from "../Entity";
import { Avatar } from "../Avatar";
import { UserTooltip } from "../UserTooltip";
import { useStyles } from "./styles";

interface IProps {
	approvers: string[][];
	close: () => void;
	isOpen: boolean;
}

const SingleApprover: FC<{ approverId: string }> = ({ approverId }) => {
	const approver = useUser(approverId);
	if (!approver) return null;
	return (
		<UserTooltip user={approver}>
			<Entity content={approver.fullName} isDeleted={approver.isDeleted} prefixIcon={<Avatar user={approver} />} />
		</UserTooltip>
	);
};

const Separator: FC = () => {
	const { t } = useTranslation();
	return <Typography>{t("common.approversModal.andOneOf")}</Typography>;
};

const ApproversModal: FC<IProps> = ({ approvers, isOpen, close }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const approversList = useMemo(() => {
		if (!approvers.length) return null;
		const approverElements = approvers.flatMap((approverGroup, index) => {
			const mappedApprovers = uniq(approverGroup).map(approverId => (
				<SingleApprover approverId={approverId} key={approverId} />
			));
			if (index + 1 < approvers.length) {
				mappedApprovers.push(<Separator key={`_${index}`} />);
			}
			return mappedApprovers;
		});
		return <div className={classes.approverList}>{approverElements}</div>;
	}, [approvers, classes]);

	return (
		<Modal
			className={classes.modal}
			isOpen={isOpen}
			onClose={close}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={t("common.approversModal.title")} />
					{approversList}
					<HelpBlock className={classes.notice}>
						<Typography variant="small">{t(`common.approversModal.notice`)}</Typography>
					</HelpBlock>
				</div>
			}
		/>
	);
};

const memoized = React.memo(ApproversModal);
export { memoized as ApproversModal };
