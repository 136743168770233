import React, { useCallback, useMemo } from "react";
import { ChooseUserModal } from "components/common/ChooseUserModal";
import { redirectTicket } from "api/tickets";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useTranslation } from "react-i18next";
import { useRedirectModalContext } from "context/redirectModalContext";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";

export const RedirectTicketModal: FC = () => {
	const { user } = useAuthenticatedUser();

	const {
		state: { ticket, isOpen },
		actions: { close, setTicket }
	} = useRedirectModalContext();

	const { notifyTicketUpdate } = useTicketUpdatesContext();
	const { t } = useTranslation();
	const handleError = useOpenGlobalErrorModal();
	const disabledUsers = useMemo(() => (user?.id ? [user.id] : []), [user]);

	const redirectTicketHandler = useCallback(
		async (userId: string) => {
			try {
				if (ticket?.id && user?.id) {
					const updatedTicket = await redirectTicket(ticket.id, user.id, userId);
					setTicket(null);
					notifyTicketUpdate(updatedTicket);
				}
			} catch (e) {
				handleError(e as Error);
			}
		},
		[ticket?.id, user?.id, setTicket, notifyTicketUpdate, handleError]
	);

	return (
		<>
			{isOpen && (
				<ChooseUserModal
					title={t("pages.pendingRequests.chooseRedirectUser")}
					isOpen
					onClose={close}
					onSave={redirectTicketHandler}
					disabledUsers={disabledUsers}
				/>
			)}
		</>
	);
};
