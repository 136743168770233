import React, { ReactNode, useCallback, useMemo } from "react";
import { Accordion } from "components/ui/Accordion";
import { AccordionSummary } from "components/ui/Accordion/AccordionSummary";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { Typography } from "components/ui/legacy/Typography";
import { AccordionDetails } from "components/ui/Accordion/AccordionDetails";
import { useStyles } from "./styles";

interface IProps {
	defaultExpanded?: boolean;
	detailsClassname?: string;
	disabled?: boolean;
	expanded?: boolean;
	noMargin?: boolean;
	onChange?: (expanded: boolean) => void;
	rounded?: boolean;
	title: ReactNode;
}

export const CommonAccordion: FC<IProps> = ({
	children,
	className,
	defaultExpanded = false,
	detailsClassname,
	disabled = false,
	expanded: expandedProp,
	noMargin,
	onChange: propOnChange,
	rounded,
	title: titleProp
}) => {
	const classes = useStyles();
	const onChange = useCallback(
		(expanded: boolean) => {
			if (propOnChange) {
				propOnChange(expanded);
			}
		},
		[propOnChange]
	);

	const title = useMemo(() => {
		return (
			<Typography variant="h3" component="div" className={classes.title}>
				{titleProp}
			</Typography>
		);
	}, [classes.title, titleProp]);

	return (
		<Accordion
			className={className}
			noMargin={noMargin}
			rounded={rounded}
			disabled={disabled}
			onChange={onChange}
			defaultExpanded={defaultExpanded}
			expanded={expandedProp}>
			<AccordionSummary expandIcon={<ChevronUpIcon />} aria-controls="panel1d-content">
				{title}
			</AccordionSummary>
			<AccordionDetails className={detailsClassname}>{children}</AccordionDetails>
		</Accordion>
	);
};
