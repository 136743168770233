import React, { useCallback, useState } from "react";
import { useNotificationIntegrations } from "hooks/useNotificationIntegrations";
import { useTranslation } from "react-i18next";
import { useIsOpenState } from "hooks/useIsOpenState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { TeamsIntegration } from "./TeamsIntegration";
import { SlackIntegration } from "./SlackIntegration";

export const NotificationIntegrations: FC = () => {
	const {
		state: { slackIntegrations, teamsIntegrations },
		actions: { removeNotificationIntegration }
	} = useNotificationIntegrations();
	const { open, close, isOpen } = useIsOpenState();
	const { t } = useTranslation();

	const [integrationIdToRemove, setIntegrationIdToRemove] = useState<string | null>(null);

	const onClose = useCallback(() => {
		setIntegrationIdToRemove(null);
		close();
	}, [close]);

	const onRemove = useCallback(async () => {
		integrationIdToRemove && (await removeNotificationIntegration(integrationIdToRemove));
		onClose();
	}, [integrationIdToRemove, onClose, removeNotificationIntegration]);

	const onDisconnect = useCallback(
		(id: string) => {
			setIntegrationIdToRemove(id);
			open();
		},
		[open]
	);

	return (
		<>
			<AreYouSureModal
				isOpen={isOpen}
				onClose={onClose}
				onAction={onRemove}
				content={t("pages.settings.integrations.disconnectNotificationIntegration")}
				actionLabel={t("pages.settings.integrations.disconnect")}
			/>
			{slackIntegrations.length > 0 ? (
				slackIntegrations.map(integration => (
					<SlackIntegration key={integration.id} integration={integration} onDisconnect={onDisconnect} />
				))
			) : (
				<SlackIntegration />
			)}
			{teamsIntegrations.length > 0 ? (
				teamsIntegrations.map(integration => (
					<TeamsIntegration key={integration.id} integration={integration} onDisconnect={onDisconnect} />
				))
			) : (
				<TeamsIntegration />
			)}
		</>
	);
};
