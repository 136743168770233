import React, { useEffect, useMemo, useRef } from "react";
import pickBy from "lodash/pickBy";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";
import { useRulesContext } from "components/pages/RulesPage/RulesContext";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { RulesSection } from "components/common/Rules/RulesSection";
import { Drawer } from "components/templates/Drawer";
import { Bp } from "utils/ui/breakpoints";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useRuleStepperContext } from "../../ruleStepperContext";
import { RuleDefinition } from "./components/RuleDefinition";
import { useStyles } from "./styles";

const ABSOLUTE_DRAWER_OFFSET = { top: 180, bottom: 68 };

type TBuildRuleStepProps = {
	onExit: () => void;
	editMode?: boolean;
};

export const BuildRuleStep: FC<TBuildRuleStepProps> = ({ onExit, editMode, className }) => {
	const { isBiggerThan: actionsTopRight } = useBreakpoint(Bp.FIFTY);
	const classes = useStyles();
	const ref = useRef<HTMLDivElement>(null);
	const { t } = useTranslation("translation", { keyPrefix: "pages.createRule.buildRuleStep" });

	const {
		actions: { setRulesReadonly, setSelectedRule }
	} = useRulesContext();

	const {
		state: { updates, rule }
	} = useRuleStepperContext();

	const {
		actions: { setCanContinue }
	} = useStepperContext();

	useEffect(() => {
		const canContinue = Object.keys(pickBy(updates, value => !isNil(value))).length > 0;
		setCanContinue(canContinue);
	}, [updates, setCanContinue]);

	useEffect(() => {
		setRulesReadonly(true);
		setSelectedRule(rule);
	}, [setRulesReadonly, rule, setSelectedRule]);

	const exitAction = useMemo(
		() => (
			<IconButton size="large">
				<CloseIcon onClick={onExit} />
			</IconButton>
		),
		[onExit]
	);
	return (
		<PageTitleContent innerRef={ref} className={className} wrapperClassName={classes.pageContentContainer} noTransition>
			<Drawer open size={600} absoluteOffset={ABSOLUTE_DRAWER_OFFSET} parent={ref}>
				<RuleDefinition />
			</Drawer>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top breadcrumbs={actionsTopRight ? null : exitAction}>
					{actionsTopRight ? <PageTitleContent.Header.Actions>{exitAction}</PageTitleContent.Header.Actions> : null}
				</PageTitleContent.Header.Top>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t(editMode ? "editTitle" : "createTitle")} subtitle={t("subtitle")} />
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<RulesSection showTypelessRules />
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
