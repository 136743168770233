import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { Select } from "components/ui/Select";
import { sortByName } from "utils/sortUtils";
import { useUserEntitlementsResources } from "../../myPermissionsHooks";
import { useStyles } from "./styles";

type TProps = {
	accountId?: string | null;
	disabled?: boolean;
	integrationId: string | null;
	onChange: (resourceId: string | null) => void;
	resourceType?: string | null;
	value: string | null;
};

const isEqual = (option: IntegrationResourceModel, value: IntegrationResourceModel) => option.id === value.id;
const getLabel = (option: IntegrationResourceModel) => option.name;

export const ResourcesSelect: FC<TProps> = ({
	accountId,
	className,
	disabled,
	innerRef,
	integrationId,
	onChange: propOnChange,
	resourceType,
	value: propValue
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { resources, isLoading } = useUserEntitlementsResources(integrationId, resourceType, accountId);
	const options = useMemo(() => (resources ? resources.valueSeq().toArray() : []), [resources]);

	const renderOption = useCallback(
		(option: IntegrationResourceModel) => <div className={classes.text}>{option.name}</div>,
		[classes]
	);

	const onChange = useCallback(
		(value: IntegrationResourceModel | null) => {
			propOnChange(value?.id ?? null);
		},
		[propOnChange]
	);

	const value = useMemo(() => {
		return resources?.get(propValue || "") ?? null;
	}, [resources, propValue]);

	return (
		<Select
			className={classNames(classes.container, className)}
			disabled={disabled}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			isOptionEqualToValue={isEqual}
			label={t("common.resourceSelectInput.label")}
			limit={20}
			loading={isLoading}
			onChange={onChange}
			options={options}
			placeholder={t("common.resourceSelectInput.placeholder")}
			renderLabel={renderOption}
			renderOption={renderOption}
			size="medium"
			sort={sortByName}
			value={value}
		/>
	);
};
