import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationWorkflow" as const;

const defaults = {
	name: filterName,
	value: [] as string[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationWorkflowFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationWorkflowFilter | null {
		const integrationWorkflow = uniq(urlSearchParams.getAll("integrationWorkflow"));
		const integrationWorkflowOperator = urlSearchParams.get("integrationWorkflowOperator") || undefined;
		if (integrationWorkflow.length === 0 || !validateFilterEqualityOperator(integrationWorkflowOperator)) {
			return null;
		}
		return new IntegrationWorkflowFilter({
			value: integrationWorkflow,
			operator: integrationWorkflowOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationWorkflow", value);
		}
		params.append("integrationWorkflowOperator", this.operator);
		return params;
	}
}
