import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	userNodeContent: {
		display: "flex",
		flexDirection: "column",
		minWidth: 0,
		justifyContent: "center",
		alignItems: "flex-start"
	},
	image: {
		height: "100%",
		width: "100%",
		borderRadius: "50%",
		fontSize: "32px"
	}
});
