import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterInclusionOperator, validateFilterRelation } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceRoleIntegrationResourceName" as const;

const defaults = {
	name: filterName,
	value: [] as string[],
	operator: "contains" as "contains" | "notContains",
	relation: "or" as "and" | "or"
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleIntegrationResourceNameFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(
		urlSearchParams: URLSearchParams
	): IntegrationResourceRoleIntegrationResourceNameFilter | null {
		const integrationResourceRoleIntegrationResourceName = uniq(
			urlSearchParams.getAll("integrationResourceRoleIntegrationResourceName")
		);
		const integrationResourceRoleIntegrationResourceNameOperator =
			urlSearchParams.get("integrationResourceRoleIntegrationResourceNameOperator") || undefined;
		const integrationResourceRoleIntegrationResourceNameRelation =
			urlSearchParams.get("integrationResourceRoleIntegrationResourceNameRelation") || undefined;
		if (
			integrationResourceRoleIntegrationResourceName.length === 0 ||
			!validateFilterInclusionOperator(integrationResourceRoleIntegrationResourceNameOperator) ||
			!validateFilterRelation(integrationResourceRoleIntegrationResourceNameRelation)
		) {
			return null;
		}
		return new IntegrationResourceRoleIntegrationResourceNameFilter({
			value: integrationResourceRoleIntegrationResourceName,
			operator: integrationResourceRoleIntegrationResourceNameOperator,
			relation: integrationResourceRoleIntegrationResourceNameRelation
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceRoleIntegrationResourceName", value);
		}
		params.append("integrationResourceRoleIntegrationResourceNameOperator", this.operator);
		params.append("integrationResourceRoleIntegrationResourceNameRelation", this.relation);
		return params;
	}
}

export type TIntegrationResourceRoleIntegrationResourceNameFilter = Omit<TDefaults, "name">;
