import Immutable from "immutable";
import { isNil, isBoolean, isNumber, isString, isPlainObject, isArray, get, has, uniqueId } from "lodash";

const isSerializable = (item: unknown): boolean => {
	try {
		if (isNil(item) || isBoolean(item) || isNumber(item) || isString(item)) {
			return true;
		}

		if (!isPlainObject(item) && !isArray(item)) {
			return false;
		}

		for (const key in item as object) {
			if (!isSerializable(get(item, key))) {
				return false;
			}
		}

		return true;
	} catch (error) {
		return false;
	}
};

export const getUniqueKey = (item: unknown, defaultValue?: string): string => {
	if (has(item, "id")) {
		return getUniqueKey(get(item, "id"), defaultValue);
	} else if (Immutable.isImmutable(item)) {
		return JSON.stringify(item.toJS());
	} else if (isSerializable(item)) {
		return JSON.stringify(item);
	} else {
		return defaultValue || uniqueId("uniqueKey-");
	}
};
