import React from "react";
import { isDevelopment, devLog } from "./devLogging";

// Make sure to only include the library in development
if (isDevelopment) {
	try {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const whyDidYouRender = require("@welldone-software/why-did-you-render");
		whyDidYouRender(React, {
			trackAllPureComponents: false
		});
	} catch (e) {
		devLog({ message: "Could not load why-did-your-render", level: "warn" });
		devLog({ message: "For extra information: ", extra: { error: e }, level: "warn" });
	}
}
