import partition from "lodash/partition";
import pickBy from "lodash/pickBy";
import { PartialOrNullWithOneRequired } from "types/utilTypes";

import type { TFormData } from "../components/NewTicketForm/types";

const urlFieldsCreator = (formData: PartialOrNullWithOneRequired<TFormData, "targets">) => {
	const { targets } = formData;
	if (!targets) return null;

	const [roleTargets, bundleTargets] = partition(targets, target => target.type === "role");

	return {
		ticketingIntegrationTicketId: formData.ticketingIntegrationTicketId,
		duration: formData.duration?.toString(),
		justification: formData.justification,
		roleIds: roleTargets.length
			? roleTargets.map(target => ({
					id: target.value.id,
					type: "role"
				}))
			: undefined,
		bundleIds: bundleTargets.length ? bundleTargets.map(target => target.value.id) : undefined
	};
};

export const getUrlParams = (
	formData: Pick<TFormData, "duration" | "justification" | "targets" | "ticketingIntegrationTicketId">
) => {
	const fields = urlFieldsCreator(formData);
	if (!fields) return;

	const dataStr = JSON.stringify(pickBy(fields, Boolean));
	return new URLSearchParams({ data: Buffer.from(dataStr).toString("base64") }).toString();
};
