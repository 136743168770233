import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { Typography } from "components/ui/legacy/Typography";
import { CommonAccordion } from "components/common/CommonAccordion";
import { LoadingDots } from "components/ui/LoadingDots";
import { useIntegrationResourceRole } from "hooks/useIntegrationResourceRole";
import { useIntegrations } from "hooks/useIntegrations";
import { VirtualPermissionIcon } from "components/ui/Icons/VirtualPermissionIcon";
import { useStyles } from "./styles";

interface IProps {
	virtualizedRoleId: string;
}

export const VirtualizedRoleBlock: FC<IProps> = ({ virtualizedRoleId }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { role, isLoading } = useIntegrationResourceRole(virtualizedRoleId);
	const integrations = useIntegrations();
	const integration = integrations?.get(role?.integrationResource?.integrationId || "");

	const Name = useMemo(() => {
		if (!role || !integration) return null;
		const TargetName = role.integrationResource ? (
			<Typography variant="standard" className={classes.subText}>
				{role.integrationResource.name}
			</Typography>
		) : null;

		return (
			<>
				<Typography variant="h3">{integration.name}</Typography> - {TargetName} -
				<Typography className={classes.subText}>{role.name}</Typography>
			</>
		);
	}, [classes.subText, integration, role]);

	return (
		<CommonAccordion
			rounded
			title={
				<Typography variant="h3" prefixIcon={<VirtualPermissionIcon />}>
					{t("pages.integrationResourceRole.virtualizedRole.title")}
				</Typography>
			}
			detailsClassname={classes.detailsContainer}>
			{!integrations && isLoading ? (
				<LoadingDots className={classes.loading} />
			) : (
				<div className={classes.resource}>
					<div className={classes.resourceLogoContainer}>
						<img src={integration?.application?.imageUrl} className={classes.resourceLogo} />
					</div>
					<div className={classes.targetContainer}>{Name}</div>
				</div>
			)}
		</CommonAccordion>
	);
};
