import { apiReq } from "utils/api/apiReq";
import { Map } from "immutable";
import { ApiTokenModel } from "models/ApiTokenModel";

type TApiCreateToken = {
	name: string;
	duration: number | null;
};

export async function getApiTokens(): Promise<Map<string, ApiTokenModel>> {
	const { data } = await apiReq("GET", "/v1/apiTokens");

	return Map<string, ApiTokenModel>(
		data.map((token: unknown) => {
			const model = ApiTokenModel.fromServerData(token);

			return [model.id, model];
		})
	);
}

export const createApiToken = async (token: TApiCreateToken) => {
	const { data: newToken } = await apiReq("POST", "/v1/apiTokens", token);

	return ApiTokenModel.fromServerData(newToken);
};

export const updateApiToken = async (id: string, name: string) => {
	const { data: updatedToken } = await apiReq("PUT", `/v1/apiTokens/${id}`, { name });

	return ApiTokenModel.fromServerData(updatedToken);
};

export const deleteApiToken = async (id: string) => {
	return apiReq("DELETE", `/v1/apiTokens/${id}`);
};
