import React, { useCallback, useMemo } from "react";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/legacy/Typography";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { TooltipOnOverflow } from "components/ui/legacy/TooltipOnOverflow";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { StaticChip } from "components/ui/chips/StaticChip";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { useIntegrations } from "hooks/useIntegrations";
import { useMaxEntities } from "hooks/useMaxEntities";
import { RoleNode } from "components/common/Nodes/RoleNode";
import { ResourceNode } from "components/common/Nodes/ResourceNode";
import { IntegrationNode } from "components/common/Nodes/IntegrationNode";
import { IntegrationImage } from "components/common/IntegrationImage";

const ITEMS_TO_SHOW = 3;
const ITEM_INCREASE = 4;
const MAX_ITEMS_TO_SHOW = 100;
const SHOW_CHIP_MIN = 3;

type TMinimalRole = {
	roleId?: string;
	name: string;
	id: string;
};

type TMinimalResource = {
	resourceId: string;
	name: string;
	integrationId: string;
};

export type TMinimalRoleWithResource = TMinimalRole & {
	integrationResource: TMinimalResource;
};

type TRoleProps = {
	role: TMinimalRole;
	refSetter?: (ids: string[]) => (element: unknown) => void;
};

type TRolesProps = {
	onCollapse?: () => void;
	onExpand: () => void;
	refSetter?: (ids: string[]) => (element: unknown) => void;
	roleIds: string[];
};

type TCollapseProps = {
	onCollapse: () => void;
};

type TRoleVertexProps = TRoleProps | TRolesProps;

const isRoleProps = (props: TProps<TRoleVertexProps>): props is TRoleProps => {
	return "role" in props;
};

const getRoleRef = (props: TProps<TRoleVertexProps>) => {
	if (isRoleProps(props)) {
		const ids = [props.role.id || props.role.roleId || ""];
		return props.refSetter ? props.refSetter(ids) : props.innerRef;
	} else {
		return props.refSetter ? props.refSetter(props.roleIds) : props.innerRef;
	}
};

const RoleCollapseVertex: FC<TCollapseProps> = ({ onCollapse, className, innerRef }) => {
	const { t } = useTranslation();
	const content = <Typography variant="standard">{t("common.entities.collapse")}</Typography>;
	const actions = <ChevronUpIcon onClick={onCollapse} />;
	return <RoleNode className={className} content={content} actions={actions} innerRef={innerRef} />;
};

const RoleVertex: FC<TRoleVertexProps> = props => {
	const ref = useMemo(() => getRoleRef(props), [props]);
	const isRole = isRoleProps(props);
	const [content, actions] = useMemo(() => {
		const content = isRole ? props.role.name : `+${props.roleIds.length}`;
		const actions = isRole ? null : (
			<>
				{props.onCollapse ? <ChevronUpIcon onClick={props.onCollapse} /> : null}
				<ChevronDownIcon onClick={props.onExpand} />
			</>
		);
		return [content, actions];
	}, [isRole, props]);
	return <RoleNode className={props.className} content={content} actions={actions} innerRef={ref} />;
};

type TResourceProps = {
	resource: TMinimalResource;
	roleAmount?: number;
};

type TMultipleResourcesProps = {
	onCollapse?: () => void;
	onExpand: () => void;
	refSetter: (ids: string[]) => (element: unknown) => void;
	resourcesAmount: number;
	roleIds: string[];
};

const ResourceCollapseVertex: FC<TCollapseProps> = ({ onCollapse, className, innerRef }) => {
	const { t } = useTranslation();
	const content = (
		<>
			<ResourcesIcon />
			<Typography variant="standard">{t("common.entities.collapse")}</Typography>
		</>
	);
	const actions = <ChevronUpIcon onClick={onCollapse} />;
	return <ResourceNode className={className} content={content} actions={actions} innerRef={innerRef} />;
};

const ResourceVertex: FC<TResourceProps> = ({ resource, roleAmount = 0, className, innerRef }) => {
	const content = (
		<>
			<ResourcesIcon />
			<TooltipOnOverflow textVariant="small" content={resource.name} />
		</>
	);
	const actions =
		roleAmount > SHOW_CHIP_MIN ? (
			<StaticChip size="small" variant="regular">
				{roleAmount}
			</StaticChip>
		) : null;
	return <ResourceNode className={className} content={content} actions={actions} innerRef={innerRef} />;
};

const MultipleResourcesVertex: FC<TMultipleResourcesProps> = ({
	className,
	onCollapse,
	onExpand,
	refSetter,
	resourcesAmount,
	roleIds
}) => {
	const ref = useMemo(() => refSetter(roleIds), [refSetter, roleIds]);
	const content = (
		<>
			<ResourcesIcon />
			<TooltipOnOverflow textVariant="small" content={`${resourcesAmount}+`} />
		</>
	);
	const actions = (
		<>
			{onCollapse ? <ChevronUpIcon onClick={onCollapse} /> : null}
			<ChevronDownIcon onClick={onExpand} />
		</>
	);
	return <ResourceNode className={className} content={content} actions={actions} innerRef={ref} />;
};

export const IntegrationCollapseVertex: FC<TCollapseProps> = ({ onCollapse, className, innerRef }) => {
	const { t } = useTranslation();
	const content = (
		<>
			<IntegrationIcon />
			<Typography variant="small">{t("common.entities.collapse")}</Typography>
		</>
	);
	const actions = <ChevronUpIcon onClick={onCollapse} />;
	return <IntegrationNode className={className} content={content} actions={actions} innerRef={innerRef} />;
};

type TExpandableIntegrationVertexProps = {
	integrationAmount: number;
	onCollapse?: () => void;
	onExpand: () => void;
	refSetter: (ids: string[]) => (element: unknown) => void;
	roleIds: string[];
};

export const IntegrationExpandableVertex: FC<TExpandableIntegrationVertexProps> = ({
	className,
	integrationAmount,
	onCollapse,
	onExpand,
	refSetter,
	roleIds
}) => {
	const ref = useMemo(() => refSetter(roleIds), [refSetter, roleIds]);
	const content = (
		<>
			<IntegrationIcon />
			<Typography variant="small">{integrationAmount}+</Typography>
		</>
	);
	const actions = (
		<>
			{onCollapse ? <ChevronUpIcon onClick={onCollapse} /> : null}
			<ChevronDownIcon onClick={onExpand} />
		</>
	);
	return <IntegrationNode className={className} content={content} actions={actions} innerRef={ref} />;
};

type TResourceAndRolesVertexProps = {
	roles: TMinimalRoleWithResource[];
	onResize: (ids: string[]) => void;
	refSetter: (ids: string[]) => (element: unknown) => void;
};

const ResourceAndRolesVertex: FC<TResourceAndRolesVertexProps> = ({ roles, onResize, refSetter }) => {
	const { maxEntities, increaseMax, decreaseMax } = useMaxEntities(ITEMS_TO_SHOW, MAX_ITEMS_TO_SHOW, ITEM_INCREASE);

	const resource = useMemo(() => roles[0].integrationResource, [roles]);
	const [showedRoles, extraRoleIds] = useMemo(() => {
		if (!roles?.length) return [null, null];
		const sortedRoles = sortBy(roles, role => role.name);
		const showedRoles = sortedRoles.slice(0, maxEntities);
		const extraRoles = sortedRoles.slice(maxEntities).map(role => role.id);
		return [showedRoles, extraRoles];
	}, [maxEntities, roles]);

	const increase = useCallback(() => {
		increaseMax();
		onResize(extraRoleIds?.slice(0, ITEM_INCREASE) || []);
	}, [extraRoleIds, increaseMax, onResize]);

	const decrease = useCallback(() => {
		decreaseMax();
		onResize(showedRoles?.slice(-ITEM_INCREASE).map(role => role.id) || []);
	}, [decreaseMax, onResize, showedRoles]);

	const showCollapse = useMemo(() => {
		return roles.length <= maxEntities && maxEntities > ITEMS_TO_SHOW;
	}, [maxEntities, roles]);

	if (!resource) return null;
	return (
		<>
			<ResourceVertex resource={resource} roleAmount={roles.length} />
			{showedRoles?.map(role => <RoleVertex key={`role-${role.id}`} role={role} refSetter={refSetter} />)}
			{extraRoleIds?.length ? (
				<RoleVertex
					roleIds={extraRoleIds}
					onExpand={increase}
					onCollapse={maxEntities > ITEMS_TO_SHOW ? decrease : undefined}
					refSetter={refSetter}
				/>
			) : null}
			{showCollapse ? <RoleCollapseVertex onCollapse={decrease} /> : null}
		</>
	);
};

type TIntegrationVertexProps = {
	roles: TMinimalRoleWithResource[];
	onResize: (ids?: string[]) => void;
	refSetter: (ids: string[]) => (element: unknown) => void;
	selected?: boolean;
};

export const IntegrationVertex: FC<TIntegrationVertexProps> = ({
	roles,
	onResize,
	refSetter,
	className,
	selected,
	innerRef
}) => {
	const integrations = useIntegrations();
	const integration = useMemo(() => {
		const integrationId = roles[0].integrationResource.integrationId || "";
		return integrations?.get(integrationId);
	}, [integrations, roles]);

	const { maxEntities, increaseMax, decreaseMax } = useMaxEntities(ITEMS_TO_SHOW, MAX_ITEMS_TO_SHOW, ITEM_INCREASE);

	const rolesGroupByResource = useMemo(() => {
		const rolesGroupByResource = new Map<string, TMinimalRoleWithResource[]>();
		roles.forEach(role => {
			const integrationResourceId = role.integrationResource.resourceId || "";
			const resourceRoles = rolesGroupByResource.get(integrationResourceId) || [];
			resourceRoles.push(role);
			rolesGroupByResource.set(integrationResourceId, resourceRoles);
		});
		return rolesGroupByResource;
	}, [roles]);

	const { showedResourcesRoles, extraRoleIds, extraResourcesAmount, extraResources } = useMemo(() => {
		if (!rolesGroupByResource.size) {
			return { showedResourcesRoles: null, extraRoleIds: null, extraResourcesAmount: null, extraResource: null };
		}
		const sortedKeysByResourceName = sortBy(
			Array.from(rolesGroupByResource.keys()),
			key => rolesGroupByResource.get(key)?.at(0)?.integrationResource.name
		);
		const showedResources = sortedKeysByResourceName.slice(0, maxEntities);
		const extraResources = sortedKeysByResourceName.slice(maxEntities);
		const showedResourcesRoles = new Map<string, TMinimalRoleWithResource[]>();
		showedResources.forEach(resourceId =>
			showedResourcesRoles.set(resourceId, rolesGroupByResource.get(resourceId) || [])
		);
		const extraRoleIds = extraResources.flatMap(
			resourceId => rolesGroupByResource.get(resourceId)?.map(role => role.id) || []
		);
		return {
			showedResourcesRoles,
			extraRoleIds,
			extraResourcesAmount: extraResources.length,
			extraResources
		};
	}, [maxEntities, rolesGroupByResource]);

	const increase = useCallback(() => {
		increaseMax();
		const resizedRoleIds =
			extraResources
				?.slice(0, ITEM_INCREASE)
				.flatMap(resourceId => rolesGroupByResource.get(resourceId)?.map(role => role.id) || []) || [];
		onResize(resizedRoleIds);
	}, [extraResources, increaseMax, onResize, rolesGroupByResource]);

	const decrease = useCallback(() => {
		decreaseMax();
		const lastKeys = Array.from(showedResourcesRoles?.keys() || []).slice(-ITEM_INCREASE);
		const resizedRoleIds = lastKeys.flatMap(
			resourceId => showedResourcesRoles?.get(resourceId)?.map(role => role.id) || []
		);
		onResize(resizedRoleIds);
	}, [decreaseMax, onResize, showedResourcesRoles]);

	const showedResourcesRolesEntriesArray = useMemo(() => {
		return Array.from(showedResourcesRoles?.entries() || []);
	}, [showedResourcesRoles]);

	const showCollapse = useMemo(() => {
		return roles.length <= maxEntities && maxEntities > ITEMS_TO_SHOW;
	}, [maxEntities, roles]);

	if (!integration) return null;

	const header = (
		<>
			<IntegrationImage integration={integration} />
			<TooltipOnOverflow textVariant="small" content={integration.name} />
		</>
	);
	const content = (
		<>
			{showedResourcesRolesEntriesArray?.map(([resourceId, roles]) => (
				<ResourceAndRolesVertex roles={roles} onResize={onResize} refSetter={refSetter} key={resourceId} />
			))}
			{extraResourcesAmount ? (
				<MultipleResourcesVertex
					onExpand={increase}
					roleIds={extraRoleIds}
					resourcesAmount={extraResourcesAmount}
					refSetter={refSetter}
					onCollapse={maxEntities > ITEMS_TO_SHOW ? decrease : undefined}
				/>
			) : null}
			{showCollapse ? <ResourceCollapseVertex onCollapse={decrease} /> : null}
		</>
	);
	return (
		<IntegrationNode header={header} content={content} innerRef={innerRef} className={className} selected={selected} />
	);
};

// IntegrationAndResourcesCountVertex

const ResourcesCountVertex: FC<{ resourcesCount: number }> = ({ resourcesCount, className, innerRef }) => {
	const { t } = useTranslation();
	const content = (
		<>
			<ResourcesIcon />
			<TooltipOnOverflow textVariant="small" content={t("common.entities.resources")} />
		</>
	);
	const actions = (
		<StaticChip size="small" variant="light">
			{resourcesCount}
		</StaticChip>
	);
	return <ResourceNode className={className} innerRef={innerRef} content={content} actions={actions} />;
};

type TMinimalIntegration = {
	id: string;
	name: string;
	resourcesCount: number;
};

type TIntegrationAndResourcesCountVertexProps = {
	integration?: TMinimalIntegration;
	selected?: boolean;
};

export const IntegrationAndResourcesCountVertex: FC<TIntegrationAndResourcesCountVertexProps> = ({
	integration,
	selected,
	className,
	innerRef
}) => {
	const integrations = useIntegrations();
	const integrationForImage = useMemo(() => {
		const integrationId = integration?.id || "";
		return integrations?.get(integrationId);
	}, [integration, integrations]);

	if (!integration) return null;
	const header = (
		<>
			<IntegrationImage integration={integrationForImage} />
			<TooltipOnOverflow textVariant="small" content={integration.name} />
		</>
	);
	const content = <ResourcesCountVertex resourcesCount={integration.resourcesCount} />;
	return (
		<IntegrationNode header={header} content={content} innerRef={innerRef} className={className} selected={selected} />
	);
};
