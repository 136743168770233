import React, { useMemo } from "react";
import classNames from "classnames";
import { useStyles } from "./styles";
import { MultiTextFilter } from "./components/MultiTextFilter";
import { MultiChoiceFilter } from "./components/MultiChoiceFilter";
import { MultiSelectFilter } from "./components/MultiSelectFilter";
import { SingleChoiceFilter } from "./components/SingleChoiceFilter";
import { SingleSelectFilter } from "./components/SingleSelectFilter";
import { MultiSelectAndTextFilter } from "./components/MultiSelectAndTextFilter";
import { DateTimeFilter } from "./components/DateTimeFilter";
import type { TFilterExpressionProps } from "./types";

export const FilterExpression = <T,>({
	id,
	className,
	innerRef,
	...filterProps
}: TProps<TFilterExpressionProps<T>>) => {
	const classes = useStyles();

	const filterElement = useMemo(() => {
		switch (filterProps.type) {
			case "datetime":
				return <DateTimeFilter {...filterProps} />;
			case "multiText":
				return <MultiTextFilter {...filterProps} />;
			case "multiChoice":
				return <MultiChoiceFilter {...filterProps} />;
			case "multiSelect":
				return <MultiSelectFilter {...filterProps} />;
			case "multiSelectAndText":
				return <MultiSelectAndTextFilter {...filterProps} />;
			case "singleChoice":
				return <SingleChoiceFilter {...filterProps} />;
			case "singleSelect":
				return <SingleSelectFilter {...filterProps} />;
			default:
				return null;
		}
	}, [filterProps]);

	return (
		<div id={id} className={classNames(classes.container, className)} ref={innerRef}>
			{filterElement}
		</div>
	);
};
