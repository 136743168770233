import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { List, Map } from "immutable";
import { dayjs } from "utils/dates/dayjs";
import { UserWithEmail } from "components/common/UserWithEmail";
import { Chip } from "components/ui/chips/Chip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { TooltipOnOverflow } from "components/ui/legacy/TooltipOnOverflow";
import { Typography } from "components/ui/legacy/Typography";
import { useCompany } from "hooks/useCompany";
import { UserAttributeModel } from "models/UserAttributeModel";
import { UserModel } from "models/UserModel";
import { Tooltip } from "components/ui/Tooltip";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { useMultiUsers } from "hooks/useMultiUsers";
import { useStyles } from "./styles";

type UserPageHeaderProps = { user: UserModel };

type TAttribute = { attribute: string; value: string };

const MANAGERS_TAKE_LENGTH = 3;
const ATTRIBUTES_TAKE_LENGTH = 5;

const mergeAttributes = (userAttributes: List<UserAttributeModel>): List<TAttribute> => {
	const groupedAttributes = userAttributes.reduce((acc, userAttribute) => {
		const existingAttribute = acc.get(userAttribute.attribute);
		const value = dayjs(userAttribute.value).isValid() ? dayjs(userAttribute.value).toString() : userAttribute.value;
		if (existingAttribute) {
			existingAttribute.value += `, ${value}`;
		} else {
			acc = acc.set(userAttribute.attribute, { attribute: userAttribute.attribute, value: value });
		}
		return acc;
	}, Map<string, TAttribute>());
	return groupedAttributes.toList();
};

const UserAttributesList: FC<{ userAttributes: List<TAttribute> }> = ({ userAttributes }) => {
	const classes = useStyles();

	return (
		<div className={classes.userAttributesList}>
			{userAttributes.map(userAttribute => (
				<StaticChip key={userAttribute.value} variant="regular" size="small">
					{userAttribute.attribute}: {userAttribute.value}
				</StaticChip>
			))}
		</div>
	);
};

const UserInfoHeader: FC<UserPageHeaderProps> = ({ user }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const company = useCompany();
	const managerUsers = useMultiUsers(user.managerIds?.toArray() || []);
	const hasManagers = user.managerIds && user.managerIds.size > 0;
	const hasAttributes = company?.enableUserAttributes && user.userAttributes && user.userAttributes.size > 0;
	const userAttributes = useMemo(() => {
		const attributes = hasAttributes ? mergeAttributes(user.userAttributes) : List<TAttribute>();
		return attributes.sort((a, b) => a.attribute.localeCompare(b.attribute));
	}, [hasAttributes, user.userAttributes]);
	return (
		<div>
			<div className={classes.userInfoContainer}>
				<UserWithEmail user={user} className={classes.userWithEmail} />
				<Typography variant="small" component="div" className={classes.roleContainer}>
					<Typography relative className={classes.label}>
						{t("shared.roles.entitleRole")}:
					</Typography>
					{t(`shared.roles.${user.role}`)}
				</Typography>
				{!!hasManagers && (
					<Typography variant="small" component="div" className={classes.managerContainer}>
						<Typography relative className={classes.label}>
							{t("pages.user.manager", { count: user.managerIds.size })}:
						</Typography>
						{user.managerIds
							.map(managerId => managerUsers?.get(managerId))
							.filter(Boolean)
							.take(MANAGERS_TAKE_LENGTH)
							.map(manager => (
								<UserWithEmail className={classes.userWithEmail} key={manager!.id} user={manager!} />
							))}
					</Typography>
				)}
			</div>
			{userAttributes.size > 0 && (
				<Typography variant="small" component="div" className={classes.attributesContainer}>
					{userAttributes.take(ATTRIBUTES_TAKE_LENGTH).map(userAttribute => (
						<Chip size="medium" key={userAttribute.attribute} variant="regular">
							<div className={classes.attributeChip}>
								<div>
									<TooltipOnOverflow maxInlineWidth="20ch" content={userAttribute.attribute} />:
								</div>
								<TooltipOnOverflow maxInlineWidth="20ch" content={userAttribute.value} />
							</div>
						</Chip>
					))}
					{userAttributes.size > ATTRIBUTES_TAKE_LENGTH && (
						<Tooltip content={<UserAttributesList userAttributes={userAttributes} />} trigger="click">
							<Chip size="small" className={classes.attributeChip} variant="regular">
								<div>+{userAttributes.size - ATTRIBUTES_TAKE_LENGTH}</div>
							</Chip>
						</Tooltip>
					)}
				</Typography>
			)}
		</div>
	);
};

export const UserPageHeader: FC<UserPageHeaderProps> = ({ user }) => {
	const { t } = useTranslation();

	const breadcrumbs = useMemo(() => {
		return [
			{
				title: t("navigation.users"),
				url: "/users"
			},
			{
				title: user.fullName
			}
		];
	}, [t, user.fullName]);

	return (
		<>
			<Breadcrumbs parts={breadcrumbs} />
			<UserInfoHeader user={user} />
		</>
	);
};
