import { useMemo, useCallback, useState, useEffect } from "react";
import { Set } from "immutable";
import constate from "constate";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import { Conditions, RuleModel, TCondition, TRoleOrResourceUpdate, TRuleType, TRuleUpdates } from "models/RuleModel";
import { Require } from "types/utilTypes";
import { useRulesContext } from "../RulesPage/RulesContext";
import type { TConditionName } from "filters/condition.interface";
import type { TIntegrationIdFilter } from "filters/integration/integrationIdFilter";

const getInitialConditions = (initialRule?: RuleModel) => {
	return (
		(Object.keys(initialRule?.conditions.toObject() || {}).filter(key => key !== "integration") as TConditionName[]) ??
		[]
	);
};

const useRuleStepper = ({ rule: initialRule }: { rule?: RuleModel }) => {
	const {
		state: { isLoading },
		actions: { upsertNewRule, upsertRule }
	} = useRulesContext();

	const [applyToIntegrations, setApplyToIntegrations] = useState<TIntegrationIdFilter>(
		initialRule?.conditions["integration"] ?? { operator: "is", value: [] }
	);
	const [filtersOrdered, setFiltersOrdered] = useState(Set<TConditionName>(getInitialConditions(initialRule)));
	const [conditions, setConditions] = useState<Omit<Conditions, "integration">>(
		initialRule?.conditions.delete("integration") ?? new Conditions()
	);

	const [updates, setUpdates] = useState<TRuleUpdates>(initialRule?.updates ?? {});
	const [applyToExisting, setApplyToExisting] = useState<boolean>();
	const [ruleTypeByUpdates, setRuleTypeByUpdates] = useState<TRuleType>();

	const upsertCondition = useCallback((conditionName: TConditionName, condition: TCondition) => {
		setFiltersOrdered(filters => (filters.includes(conditionName) ? filters : filters.add(conditionName)));
		setConditions(conditions => {
			return conditions.set(conditionName, condition);
		});
	}, []);

	const removeCondition = useCallback((conditionName: TConditionName) => {
		setFiltersOrdered(filters =>
			filters.includes(conditionName) ? filters.filter(name => name !== conditionName) : filters
		);
		setConditions(conditions => conditions.remove(conditionName));
	}, []);

	const setUpdatesAttribute = useCallback(
		<K extends keyof TRoleOrResourceUpdate>(attribute: K, value: TRoleOrResourceUpdate[K] | undefined) => {
			setUpdates(updates => {
				return { ...updates, [attribute]: value };
			});
		},
		[]
	);

	const removeAttribute = useCallback(<K extends keyof TRoleOrResourceUpdate>(attribute: K) => {
		setUpdates(updates => {
			return omit(updates, attribute);
		});
	}, []);

	const ruleType = useMemo(() => {
		if (initialRule?.type) {
			// we don't let the user change the rule type if it was already set
			return initialRule.type;
		}

		if (conditions.get("roleName") !== undefined) {
			return "roles";
		}

		if (ruleTypeByUpdates) {
			return ruleTypeByUpdates;
		}

		return undefined;
	}, [initialRule?.type, ruleTypeByUpdates, conditions]);

	const rule = useMemo(
		() =>
			new RuleModel({
				...(initialRule?.toObject() ?? {}),
				type: ruleType ?? "roles",
				conditions: applyToIntegrations.value.length
					? conditions.setIn(["integration"], applyToIntegrations)
					: conditions,
				updates
			}) as Require<RuleModel, "type">,
		[conditions, ruleType, updates, applyToIntegrations, initialRule]
	);

	const dirty = useMemo(() => {
		if (!initialRule) {
			return (
				applyToIntegrations.value.length ||
				conditions.toSeq().some(condition => condition !== undefined) ||
				Object.keys(updates).length > 0 ||
				applyToExisting !== undefined
			);
		}
		const currentRuleParams = { conditions: rule.conditions.toObject(), updates: rule.updates };
		const initialRuleParams = { conditions: initialRule.conditions.toObject(), updates: initialRule.updates };
		return !isEqual(currentRuleParams, initialRuleParams);
	}, [applyToIntegrations, conditions, updates, applyToExisting, rule, initialRule]);

	useEffect(() => {
		if (isLoading) return;

		if (!initialRule?.id) {
			upsertNewRule(rule, ruleType);
		} else {
			upsertRule(rule);
		}
	}, [isLoading, rule, initialRule, upsertNewRule, upsertRule, ruleType]);

	return {
		state: { rule, dirty, ruleType, conditions, updates, applyToIntegrations, applyToExisting, filtersOrdered },
		actions: {
			upsertCondition,
			removeCondition,
			setUpdatesAttribute,
			removeAttribute,
			setApplyToIntegrations,
			setApplyToExisting,
			setRuleTypeByUpdates
		}
	};
};

export const [RuleStepperProvider, useRuleStepperContext] = constate(useRuleStepper);
