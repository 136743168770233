import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IntegrationNode } from "components/common/Nodes/IntegrationNode";
import { ResourceNode } from "components/common/Nodes/ResourceNode";
import { RoleNode } from "components/common/Nodes/RoleNode";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationImage } from "components/common/IntegrationImage";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { TTypographyVariant, Typography } from "components/ui/Typography";
import { useBreakpoints } from "hooks/useBreakpoints";
import { Bp } from "utils/ui/breakpoints";
import { useIntegrationResourceRole } from "hooks/useIntegrationResourceRole";
import { useIntegrationResource } from "hooks/useIntegrationResource";
import { ResourceDetailsTooltip } from "components/common/ResourceDetailsTooltip";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { StaticChip } from "components/ui/chips/StaticChip";
import { useBundles } from "hooks/useBundles";
import { useStyles } from "./styles";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TFullRole, TIdCardProps, TModelCardProps, TMultipleCardProps, TRequestPermissionProps } from "./types";
import type { TChipVariant } from "components/ui/chips/Chip";

const SkeletonText: FC = () => {
	const classes = useStyles({});
	return <div className={classes.skeletonText} />;
};

const SkeletonIcon: FC = () => {
	const classes = useStyles({});
	return <div className={classes.skeletonIcon} />;
};

const TextAndChip: FC<{
	text: string;
	chipContent: React.ReactNode;
	tootipTextVariant?: TTypographyVariant;
	color: TChipVariant;
}> = ({ text, color, chipContent, tootipTextVariant = "text_sm_reg" }) => {
	const classes = useStyles({});
	return (
		<div className={classes.textAndChip}>
			<TooltipOnOverflow textVariant={tootipTextVariant} content={text} />
			{chipContent && (
				<StaticChip size="small" variant={color}>
					{chipContent}
				</StaticChip>
			)}
		</div>
	);
};

const isFullRole = (role: IntegrationResourceRoleModel): role is TFullRole => {
	return !!role.integrationResource;
};

const useRoleResourceIntegration = (id: string) => {
	const { role } = useIntegrationResourceRole(id, true);
	const { resource } = useIntegrationResource(role?.integrationResourceId || "", false, true);
	const integrations = useIntegrations(true);
	const integration = integrations?.get(resource?.integrationId || "");
	if (!role || !resource || !integration) {
		return null;
	}
	return { role, resource, integration };
};

const useRoleCardContent = (
	role?: IntegrationResourceRoleModel | null,
	resource?: IntegrationResourceModel | null,
	integration?: IntegrationModel | null
) => {
	const classes = useStyles({});
	const header = useMemo(() => {
		return (
			<>
				{integration ? <IntegrationImage integration={integration} /> : <SkeletonIcon />}
				{integration ? <TooltipOnOverflow textVariant="text_sm_sb" content={integration.name} /> : <SkeletonText />}
			</>
		);
	}, [integration]);
	const content = useMemo(() => {
		const resourceContent = resource ? (
			<ResourceDetailsTooltip
				description={resource.description}
				euid={resource.euid}
				name={resource.name}
				type={resource.type}>
				<div className={classes.resourceInformation}>
					<ResourcesIcon />
					<Typography variant="text_sm_reg" noWrap>
						{resource.name}
					</Typography>
				</div>
			</ResourceDetailsTooltip>
		) : (
			<>
				<SkeletonIcon />
				<SkeletonText />
			</>
		);

		const roleContent = role ? <TooltipOnOverflow textVariant="text_title_sb" content={role.name} /> : <SkeletonText />;
		return (
			<>
				<ResourceNode content={resourceContent} />
				<RoleNode content={roleContent} />
			</>
		);
	}, [classes.resourceInformation, resource, role]);

	return { header, content };
};

const RolePermissionCardById: FC<TIdCardProps> = ({ className, innerRef, role }) => {
	const classes = useStyles({});
	const modelsData = useRoleResourceIntegration(role);
	const { header, content } = useRoleCardContent(modelsData?.role, modelsData?.resource, modelsData?.integration);

	const isLoading = useMemo(
		() => !modelsData || !modelsData.role || !modelsData.resource || !modelsData.integration,
		[modelsData]
	);

	return (
		<IntegrationNode
			className={classNames(className, { [classes.nodeSkeleton]: isLoading })}
			innerRef={innerRef}
			header={header}
			content={content}
		/>
	);
};

const RolePermissionCardByModel: FC<TModelCardProps> = ({ className, innerRef, role }) => {
	const classes = useStyles({});
	const integrations = useIntegrations(true);
	const integration = integrations?.get(role.integrationResource.integrationId);
	const { header, content } = useRoleCardContent(role, role.integrationResource, integration);

	const isLoading = useMemo(() => !role || !role.integrationResource || !integration, [role, integration]);
	return (
		<IntegrationNode
			className={classNames(className, { [classes.nodeSkeleton]: isLoading })}
			innerRef={innerRef}
			header={header}
			content={content}
		/>
	);
};

const MultiplePermissionCard: FC<TMultipleCardProps> = props => {
	const { className, innerRef, name, resourcesAmount, resource, rolesAmount, headerIcon } = props;
	const classes = useStyles({});
	const { t } = useTranslation();
	const header = useMemo(() => {
		return (
			<>
				{headerIcon}
				<TooltipOnOverflow textVariant="text_sm_sb" content={name} />
			</>
		);
	}, [name, headerIcon]);

	const content = useMemo(() => {
		let resourceContent = (
			<>
				<ResourcesIcon />
				<TextAndChip
					color="white"
					text={resource?.name || t("pages.requests.pendingRequestsSection.resources")}
					chipContent={!resource ? resourcesAmount : null}
				/>
			</>
		);
		resourceContent = resource ? (
			<ResourceDetailsTooltip
				description={resource.description}
				euid={resource.euid || ""}
				name={resource.name || ""}
				type={resource.type}>
				<div className={classes.resourceInformation}>{resourceContent}</div>
			</ResourceDetailsTooltip>
		) : (
			resourceContent
		);
		const roleContent = (
			<TextAndChip
				color="regular"
				tootipTextVariant="text_title_sb"
				text={t("pages.requests.pendingRequestsSection.roles")}
				chipContent={rolesAmount}
			/>
		);

		return (
			<>
				<ResourceNode content={resourceContent} />
				<RoleNode content={roleContent} />
			</>
		);
	}, [classes.resourceInformation, resource, resourcesAmount, rolesAmount, t]);

	return <IntegrationNode className={className} innerRef={innerRef} header={header} content={content} />;
};

const RoleComponentMapper: FC<{ role: IntegrationResourceRoleModel | string | undefined }> = ({ role }) => {
	if (!role) return null;
	const id = typeof role === "string" ? role : role.id;
	if (typeof role === "string" || !isFullRole(role)) {
		return <RolePermissionCardById key={id} role={id} />;
	}
	return <RolePermissionCardByModel key={id} role={role} />;
};

const DEFAULT_SHOWN = 1;
const SHOWN_MAX = 2;

const RequestPermissions: FC<TRequestPermissionProps> = ({ className, innerRef, permissionsGrouping }) => {
	const integrations = useIntegrations(true);
	const bundles = useBundles();
	const { isBiggerThan } = useBreakpoints(Bp.SEVENTY);
	const shown = isBiggerThan ? SHOWN_MAX : DEFAULT_SHOWN;
	const classes = useStyles({ nodesAmount: shown });

	const cards = useMemo(() => {
		return permissionsGrouping
			.sort((a, b) => (a.resourcesIds.size > b.resourcesIds.size ? -1 : 1))
			.map(permissionCard => {
				const { id, resourcesIds: resources, rolesIds: roles, resource, role } = permissionCard;
				const isIntergration = permissionCard.type === "integration";
				const model = isIntergration ? integrations?.get(id) : bundles?.get(id);
				const resourcesAmount = resources.size;
				const rolesAmount = roles.size;
				const name = permissionCard.name || model?.name || "";
				const headerIcon =
					isIntergration && model ? <IntegrationImage integration={model as IntegrationModel} /> : <BundleIcon />;

				return !role ? (
					<MultiplePermissionCard
						key={"card_" + permissionCard.id}
						name={name}
						resourcesAmount={resourcesAmount}
						rolesAmount={rolesAmount}
						resource={resource}
						headerIcon={headerIcon}
					/>
				) : (
					<RoleComponentMapper key={"card_" + permissionCard.id} role={role} />
				);
			})
			.toArray();
	}, [permissionsGrouping, integrations, bundles]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			{cards.slice(0, shown)}
			{shown < cards.length ? <Typography variant="text_title_sb">{cards.length - shown}+</Typography> : null}
		</div>
	);
};

const memoizedRequestPermissions = React.memo(RequestPermissions);
export { memoizedRequestPermissions as RequestPermissions };
