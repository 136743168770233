import React from "react";
import { createUseStyles } from "react-jss";
import { ENTITY_NODE_HEIGHT } from "components/common/Nodes/EntityNode/styles";

const useEmptyStateStyles = createUseStyles({
	emptyState: {
		height: `${ENTITY_NODE_HEIGHT}px`
	}
});

export const SelectRowEmptyState: FC<{ content?: React.ReactNode }> = ({ content }) => {
	const classes = useEmptyStateStyles();
	if (content !== undefined) {
		return content;
	}
	return <div className={classes.emptyState} />;
};
