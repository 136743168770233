import React from "react";
import ReactJson from "react-json-view";
import { Typography } from "components/ui/Typography";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import type { SessionAuditLogModel } from "models/auditLogs/SessionAuditLogModel";

type TIntegrationLogJsonViewProps = {
	integrationAuditLog?: SessionAuditLogModel;
};

export const IntegrationLogJsonView: FC<TIntegrationLogJsonViewProps> = ({ integrationAuditLog }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.jsonDetailsContainer}>
			<Typography variant="text_reg">{t("common.integrationLogDetails.jsonView.title")}</Typography>
			<div className={classes.jsonContainer}>
				<ReactJson
					name={t("common.integrationLogDetails.jsonView.title")}
					src={integrationAuditLog?.json || {}}
					enableClipboard
					displayObjectSize={false}
					displayDataTypes={false}
				/>
			</div>
		</div>
	);
};
