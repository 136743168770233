import React, { useMemo } from "react";
import classNames from "classnames";
import { Divider } from "components/ui/Divider";
import { useStyles } from "./styles";

export type TRoleBarColumn = {
	width?: number | `${number}px` | `${number}fr` | `${number}%` | `minmax(${string})` | "auto" | "max-content"; // default is auto
	content: React.ReactNode;
	className?: string;
	suffixDivider?: boolean | "force"; // default true for everyone but the last one, you can force last one with "force"
};

type TProps = {
	columns: TRoleBarColumn[];
	selected?: boolean;
	onClick?: () => void;
	noInteraction?: boolean;
	actions?: React.ReactNode;
};

const RoleBarColumn: FC<{
	column: TRoleBarColumn;
	index: number;
	last: boolean;
}> = ({ column, index, last }) => {
	const classes = useStyles();

	const suffixDivider = column.suffixDivider === undefined ? true : column.suffixDivider;
	const hasDivider = suffixDivider === "force" || (suffixDivider !== false && !last);
	return (
		<>
			<div className={classNames(classes.column, column.className)} key={index}>
				{column.content}
			</div>
			<Divider vertical width={hasDivider ? 1 : 0} />
		</>
	);
};

export const RoleBar: FC<TProps> = ({
	className,
	innerRef,
	columns,
	selected = false,
	onClick,
	noInteraction = false,
	actions
}) => {
	const calculateColumnsGrid = useMemo(() => {
		return columns.map(column => column.width || "auto").join(" 1px ") + "	1px" + (actions ? " auto" : "");
	}, [actions, columns]);

	const classes = useStyles();

	const columnsContent = useMemo(
		() =>
			columns.map((column, index) => (
				<RoleBarColumn key={index} column={column} index={index} last={index === columns.length - 1} />
			)),
		[columns]
	);

	if (columns.length === 0) return null;

	return (
		<div className={classes.outerContainer} ref={innerRef}>
			<div
				className={classNames(
					classes.container,
					{ [classes.selected]: selected, [classes.clickable]: !!onClick, [classes.noInteraction]: noInteraction },
					className
				)}
				style={{ gridTemplateColumns: calculateColumnsGrid || "1fr" }}
				onClick={onClick}>
				{columnsContent}
				{actions}
			</div>
		</div>
	);
};
