import { FilterExpression } from "components/ui/filters/FilterExpression";
import { IconPrefix } from "components/ui/IconPrefix";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TUpdateExpressionProps } from "../../updates.types";

const BOOLEAN_OPTIONS = [true, false];
export const AllowRequestsAttributeExpression: FC<TUpdateExpressionProps<"allowsRequests">> = ({
	ruleType,
	innerRef,
	attribute,
	selected,
	setUpdatesAttribute,
	onRemoveAttribute
}) => {
	const { t } = useTranslation();

	const onReset = useCallback(() => setUpdatesAttribute(attribute, undefined), [setUpdatesAttribute, attribute]);
	const onOptionSelect = useCallback(
		(value: boolean) => setUpdatesAttribute(attribute, value),
		[setUpdatesAttribute, attribute]
	);
	const renderOption = useCallback(
		(value: boolean) => <IconPrefix Icon={value ? GrantedIcon : CloseIcon} content={t(`shared.boolean.${value}`)} />,
		[t]
	);

	return (
		<FilterExpression
			id={attribute}
			innerRef={innerRef}
			onRemoveFilter={onRemoveAttribute}
			title={t(`pages.rules.updates.${ruleType}.${attribute}`)}
			type="singleChoice"
			options={BOOLEAN_OPTIONS}
			renderOption={renderOption}
			onReset={onReset}
			onOptionSelect={onOptionSelect}
			selected={selected ?? null}
		/>
	);
};
