import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { SettingsPopup } from "components/common/SettingsPopup";
import { DescribedCheckbox } from "components/common/DescribedCheckbox";
import { DurationsOverrideSelect } from "components/common/DurationsOverrideSelect";
import { IntegrationModel } from "models/IntegrationModel";
import { getNameValidators } from "utils/validation/validationUtils";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { useStyles } from "./styles";
import { TRoleFormData, useSettingsPopupState } from "./useSettingsPopupState";

interface IRoleSettingsModalProps {
	manual: boolean;
	virtual: boolean;
	initialFormData: TRoleFormData;
	integration: IntegrationModel | undefined;
	integrationResource: IntegrationResourceModel | undefined;
	onClose?: () => void;
	onSave: (data: TRoleFormData) => Promise<void>;
}

export const RoleSettingsPopup: FC<IRoleSettingsModalProps> = ({
	manual,
	virtual,
	integration,
	integrationResource,
	initialFormData: {
		allowsRequests: propAllowsRequests,
		allowedDurations: propAllowedDurations,
		overrideAllowedDurations: propOverrideAllowedDurations,
		name: propName
	},
	onClose: propOnClose,
	onSave: propOnSave
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		state: {
			allowsRequests,
			allowedDurations,
			overrideAllowedDurations,
			name,
			toggleAllowsRequests,
			setName,
			setAllowedDurations,
			setOverrideAllowedDurations,
			onSave,
			onClose
		}
	} = useSettingsPopupState({
		propAllowsRequests,
		propAllowedDurations,
		propOnSave,
		propName,
		propOverrideAllowedDurations
	});

	const nameValidators = useMemo(() => getNameValidators(t("common.resourceHeader.name")), [t]);

	const isValid = !nameValidators.some(validate => validate(name));

	const handleSave = useCallback(async () => {
		if (!isValid) return;
		return onSave();
	}, [isValid, onSave]);

	const handleClose = useCallback(() => {
		propOnClose && propOnClose();
		onClose();
	}, [propOnClose, onClose]);

	useEffect(() => {
		setName(propName);
	}, [propName, setName]);

	return (
		<SettingsPopup onClose={handleClose} onSave={handleSave} className={classes.settingsModal} disable={!isValid}>
			<Form.Field>
				<Input
					label={t("common.resourceHeader.name")}
					value={name}
					onValueChange={setName}
					validators={nameValidators}
					disabled={!(manual || virtual)}
				/>
			</Form.Field>
			<Form.Field>
				<DescribedCheckbox
					label={t("pages.integration.resource.role.requestable")}
					selected={integration?.allowsRequests && integrationResource?.allowsRequests && allowsRequests}
					description={t("pages.integration.resource.role.requestableDescription")}
					onClick={toggleAllowsRequests}
					disabled={!integration?.allowsRequests || !integrationResource?.allowsRequests}
				/>
			</Form.Field>
			<Form.Field>
				<DurationsOverrideSelect
					overrideAllowedDurations={overrideAllowedDurations}
					setOverrideAllowedDurations={setOverrideAllowedDurations}
					allowedDurations={allowedDurations}
					setAllowedDurations={setAllowedDurations}
					disabled={!integration}
					type="role"
				/>
			</Form.Field>
		</SettingsPopup>
	);
};
