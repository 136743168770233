import React from "react";
import { TIconProps } from "components/ui/Icon";
import { Chip, TChipVariant, TChipSize } from "../Chip";

interface IStaticChipProps {
	PrefixIcon?: FC<TIconProps>;
	SuffixIcon?: FC<TIconProps>;
	disabled?: boolean;
	selected?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
}

export const StaticChip: FC<IStaticChipProps> = ({
	PrefixIcon,
	SuffixIcon,
	children,
	className,
	disabled = false,
	innerRef,
	selected = false,
	size,
	variant = "regular"
}) => {
	return (
		<Chip
			PrefixIcon={PrefixIcon}
			SuffixIcon={SuffixIcon}
			className={className}
			disabled={disabled}
			innerRef={innerRef}
			readonly
			selected={selected}
			size={size}
			variant={variant}>
			{children}
		</Chip>
	);
};
