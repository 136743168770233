import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GroupIcon } from "components/ui/Icons/GroupIcon";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { useDirectoryGroupIcon } from "hooks/useDirectoryGroupIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { GroupNode } from "../Nodes/GroupNode";
import { useStyles } from "./styles";
import type { TEntityNodeColor } from "../Nodes/EntityNode";

type TGroupProps = {
	content?: never;
	group: DirectoryGroupModel | string;
};

type TContentProps = {
	content: React.ReactNode;
	group?: never;
};

type TProps = (TGroupProps | TContentProps) & {
	actions?: React.ReactNode;
	color?: TEntityNodeColor;
	fixedWidth?: boolean;
	icon?: React.ReactNode;
	isDeleted?: boolean;
	onDelete?: () => void;
	selected?: boolean;
};

export const GroupCard: FC<TProps> = ({ className, content, group, icon, isDeleted, ...nodeProps }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const directoryGroup = useDirectoryGroup(typeof group === "string" ? group : "");
	const directoryGroupModel = group instanceof DirectoryGroupModel ? group : directoryGroup;
	const { name, Icon } = useDirectoryGroupIcon(directoryGroupModel?.name || "");
	const deleted = isDeleted || directoryGroupModel?.isDeleted;

	const groupIcon = useMemo(() => {
		if (icon) return icon;
		if (Icon) {
			return <Icon className={classes.icon} />;
		}
		return <GroupIcon className={classes.icon} />;
	}, [Icon, classes.icon, icon]);

	const nodeContent = useMemo(() => {
		if (content) return content;
		if (!directoryGroupModel) return t("common.unknown.group");
		return <TooltipOnOverflow textVariant="text_sm_sb" content={name} />;
	}, [content, directoryGroupModel, t, name]);

	return <GroupNode content={nodeContent} icon={groupIcon} className={className} isDeleted={deleted} {...nodeProps} />;
};
