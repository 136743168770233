import React from "react";
import { useIsOpenState } from "hooks/useIsOpenState";
import { ErrorModal } from "components/ui/ErrorModal";
import { logrocketLogError } from "utils/logrocket";
import type { FallbackProps } from "react-error-boundary";

export const DefaultErrorBoundary: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
	const { open, isOpen, close } = useIsOpenState();

	const closeErrorBoundary = React.useCallback(() => {
		resetErrorBoundary();
		close();
	}, [close, resetErrorBoundary]);

	React.useEffect(() => {
		if (error && !isOpen) open();
	}, [error, isOpen, open]);

	React.useEffect(() => {
		if (!error) return;
		logrocketLogError(error, { location: "ErrorBoundary" });
	}, [error]);

	return <ErrorModal isOpen={isOpen} error={error} onClose={closeErrorBoundary} useUnwrapped />;
};
