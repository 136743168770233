import { Record } from "immutable";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceAllowRequests" as const;

const defaults = {
	name: filterName,
	value: false as boolean,
	operator: "is" as const
};

type TDefaults = typeof defaults;

export class IntegrationResourceAllowRequestsFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceAllowRequestsFilter | null {
		const integrationResourceAllowRequests = urlSearchParams.get("integrationResourceAllowRequests");
		const integrationResourceAllowRequestsOperator =
			urlSearchParams.get("integrationResourceAllowRequestsOperator") || undefined;
		if (
			integrationResourceAllowRequests === null ||
			(typeof integrationResourceAllowRequestsOperator === "string" &&
				integrationResourceAllowRequestsOperator !== "is")
		) {
			return null;
		}
		return new IntegrationResourceAllowRequestsFilter({
			value: integrationResourceAllowRequests === "true",
			operator: integrationResourceAllowRequestsOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		params.append("integrationResourceAllowRequests", this.value.toString());
		params.append("integrationResourceAllowRequestsOperator", this.operator);
		return params;
	}
}
