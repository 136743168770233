import React, { useCallback } from "react";
import { Select } from "components/ui/Select";
import { FilterHeader } from "components/ui/filters/FilterExpression/components/FilterHeader";
import classNames from "classnames";
import { useStyles } from "./styles";

type TSingleSelectAttributeExpressionProps<T> = {
	selected: T | undefined;
	renderOption?: (option: T) => React.ReactNode;
	getMoreOptions?: (search?: string) => void;
	isLoading?: boolean;
	inputPlaceholder?: string;
	relation?: "and" | "or";
	renderSelected: (option: T) => React.ReactNode;
	onOptionSelect: (option: T) => void;
	inputValue?: string;
	onInputChange?: (event: { target: { value: string } }) => void;
	title: string | React.ReactNode;
	onRemoveAttribute?: () => void;
	onReset?: () => void;
	options: T[];
	filter?: ((options: T[], inputValue: string) => T[]) | null;
	sort?: ((options: T[]) => T[]) | null;
	emptyState?: React.ReactNode;
};
export function SingleSelectAttributeExpression<T>({
	id,
	className,
	innerRef,
	title,
	onReset,
	onRemoveAttribute,
	selected,
	renderSelected,
	options,
	filter,
	sort,
	onOptionSelect,
	renderOption,
	getMoreOptions,
	isLoading,
	inputPlaceholder,
	inputValue,
	onInputChange: onInputChangeProp,
	emptyState = null
}: TProps<TSingleSelectAttributeExpressionProps<T>>) {
	const classes = useStyles();

	const handleValueChange = useCallback(
		(option: T | null) => {
			if (option) {
				onOptionSelect(option);
			}
		},
		[onOptionSelect]
	);

	const onInputChange = useCallback(
		(event: { target: { value: string } }) => {
			getMoreOptions?.(event.target.value);
			onInputChangeProp?.(event);
		},
		[getMoreOptions, onInputChangeProp]
	);

	const isOptionEqualToValue = useCallback((option: T) => selected === option, [selected]);

	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			<FilterHeader
				className={className}
				title={title}
				onRemoveFilter={onRemoveAttribute}
				onReset={onReset}
				innerRef={innerRef}
				hasSelection={selected !== undefined}
				inputs={
					<Select
						className={classes.select}
						hideClear
						filter={filter}
						sort={sort}
						loading={isLoading}
						onChange={handleValueChange}
						isOptionEqualToValue={isOptionEqualToValue}
						onInputChange={onInputChange}
						options={options}
						placeholder={inputPlaceholder}
						renderOption={renderOption}
						getOptionLabel={null}
						value={null}
						inputValue={inputValue}
						resetValueOnSelect
					/>
				}
			/>
			<div className={classes.content}>{selected !== undefined ? renderSelected(selected) : emptyState}</div>
		</div>
	);
}
