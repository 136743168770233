import { createUseStyles } from "react-jss";

export const ROLE_BAR_RESOURCE_MIN_WIDTH_PX = 164;

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "grid",
		flexGrow: 1,
		gap: "var(--spacing-x2, 8px)",
		gridTemplateColumns: "auto",
		minWidth: `${ROLE_BAR_RESOURCE_MIN_WIDTH_PX}px`,
		"&$withChips": {
			gridTemplateColumns: "auto minmax(50px, 1fr)"
		}
	},
	iconPrefixContent: {
		marginRight: "var(--spacing-x2, 8px)"
	},
	chips: {
		justifySelf: "flex-end",
		maxWidth: "100%",
		minWidth: "0",
		"& > div": {
			maxWidth: "100%"
		}
	},
	withChips: {}
});
