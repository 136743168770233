import { useEffect } from "react";
import { useLiveUpdatesContext } from "context/liveUpdatesContext";

export const useLiveUpdate = <EventData = unknown>({
	eventName,
	handleEvent,
	roomName,
	roomValue,
	shouldJoinRoom = true
}: {
	eventName: string;
	handleEvent: (data: EventData) => void;
	roomName?: string;
	roomValue?: unknown;
	shouldJoinRoom?: boolean;
}) => {
	const { socket, joinRoom, isConnected } = useLiveUpdatesContext();

	useEffect(() => {
		if (isConnected && shouldJoinRoom) {
			if (roomName) joinRoom(roomName, roomValue);
			socket.on(eventName, handleEvent);
		}
		return () => {
			socket.off(eventName, handleEvent);
		};
	}, [eventName, handleEvent, isConnected, joinRoom, roomName, roomValue, shouldJoinRoom, socket]);
};
