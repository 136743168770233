import { apiReq } from "utils/api/apiReq";
import { CompanyModel } from "models/CompanyModel";
import type { TOnCallType } from "models/OnCallIntegrationScheduleModel";
import type { TTicketingIntegrationType } from "models/TicketingIntegrationTicketModel";

export type THRType = "BambooHR" | "Hibob" | "Freshteam" | "Workday";

export async function getMergeLinkToken(
	hrType: THRType | TTicketingIntegrationType
): Promise<{ uuid: string; linkToken: string }> {
	const { data } = await apiReq("GET", `/v1/company/mergeLinkToken/${hrType}`);

	return data;
}

export async function removeHRIntegration(hrType: THRType) {
	const { data } = await apiReq("DELETE", `/v1/company/hrIntegrations/${hrType}`);

	return CompanyModel.fromServerData(data);
}

export async function addHRIntegration(hrType: THRType, publicToken: string, uuid: string) {
	const { data } = await apiReq("POST", `/v1/company/hrIntegrations/${hrType}`, { publicToken, configurationId: uuid });

	return CompanyModel.fromServerData(data);
}

export async function testHRIntegration(hrType: THRType) {
	const { data } = await apiReq("GET", `/v1/company/hrIntegrations/${hrType}/test`);

	return data as { valid: true } | { valid: false; errorId: string };
}

export async function removeTicketingIntegration(ticketingType: TTicketingIntegrationType) {
	const { data } = await apiReq("DELETE", `/v1/company/ticketingIntegrations/${ticketingType}`);

	return CompanyModel.fromServerData(data);
}

export async function addTicketingIntegration(
	ticketingType: TTicketingIntegrationType,
	publicToken: string,
	uuid: string
) {
	const { data } = await apiReq("POST", `/v1/company/ticketingIntegrations/${ticketingType}`, {
		publicToken,
		configurationId: uuid
	});

	return CompanyModel.fromServerData(data);
}

export async function removeOnCallIntegration(onCallType: TOnCallType) {
	const { data } = await apiReq("DELETE", `/v1/company/onCallIntegrations/${onCallType}`);

	return CompanyModel.fromServerData(data);
}

export async function addOnCallIntegration(onCallType: TOnCallType, apiKey: string) {
	const { data } = await apiReq("POST", `/v1/company/onCallIntegrations/${onCallType}`, { apiKey });

	return CompanyModel.fromServerData(data);
}
