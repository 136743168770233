import React from "react";
import { TagChip } from "components/ui/chips/TagChip";
import { HiddenChip } from "components/ui/chips/HiddenChip";
import { Tooltip } from "components/ui/Tooltip";
import { useStyles } from "./styles";

type TProps = {
	tags: string[];
};

export const RoleBarTag: FC<TProps> = ({ className, tags, innerRef }) => {
	const classes = useStyles();

	if (tags.length === 0) {
		return null;
	}

	if (tags.length === 1) {
		return (
			<TagChip variant="regular" className={className}>
				{tags.at(0)}
			</TagChip>
		);
	}

	return (
		<Tooltip
			trigger="hover"
			placement="bottom"
			content={
				<div className={classes.container}>
					{tags.map(tag => (
						<TagChip key={tag} variant="regular" className={className} innerRef={innerRef}>
							{tag}
						</TagChip>
					))}
				</div>
			}>
			<HiddenChip variant="regular" type="tag" className={className}>
				{`${tags.length} +`}
			</HiddenChip>
		</Tooltip>
	);
};
