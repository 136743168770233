import classNames from "classnames";
import { Typography, TTypographyVariant } from "components/ui/Typography";
import React from "react";
import { useStyles } from "./styles";

interface IProps {
	textVariant?: TTypographyVariant;
	contentClassName?: string;
	content: string;
	isDeleted?: boolean;
	prefixIcon?: JSX.Element;
}

export const Entity: FC<IProps> = ({
	textVariant,
	className,
	content,
	contentClassName,
	id,
	innerRef,
	isDeleted = false,
	prefixIcon = null
}) => {
	const classes = useStyles();

	return (
		<div id={id} ref={innerRef} className={classNames(classes.entity, className)}>
			{prefixIcon}
			<Typography
				relative
				variant={textVariant}
				className={classNames(classes.content, contentClassName, { deleted: isDeleted })}>
				{content}
			</Typography>
		</div>
	);
};
