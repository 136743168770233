import React, { useMemo } from "react";
import { Map } from "immutable";
import classNames from "classnames";
import { useWorkflowEditorContext } from "context/workflowEditorContext";
import { Block } from "components/ui/Block";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { ApprovalFlowModel } from "models/ApprovalFlowModel";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { IsNullError } from "utils/errors/isNullError";
import { useStyles } from "./styles";
import { AlgorithmRule } from "./components/AlgorithmRule";
import { ApprovalFlow } from "./components/ApprovalFlow";
import { AlgorithmTitle } from "./components/AlgorithmTitle";
import { AlgorithmRuleEditable } from "./components/Editable/AlgorithmRule";
import { ApprovalFlowEditable } from "./components/Editable/ApprovalFlow";

interface IReadonlyProps {
	algorithm: ApprovalAlgorithmModel;
	approvalFlows: Map<string, ApprovalFlowModel>;
	onEdit?: (algorithmId: string) => void;
	withDelete?: boolean;
	useContext?: never;
}

interface IEditProps {
	useContext: true;
	algorithm?: never;
	approvalFlows?: never;
	onEdit?: never;
	withDelete?: never;
}

type TProps = { contentOnly?: boolean } & (IReadonlyProps | IEditProps);

export const ApprovalAlgorithm: FC<TProps> = props => {
	const { algorithm, approvalFlows, useContext, className, contentOnly } = props;

	const classes = useStyles();

	const content = (
		<div className={classes.container}>
			{useContext ? (
				<ApprovalAlgorithmWithContext />
			) : algorithm && approvalFlows ? (
				<ApprovalAlgorithmWithProps algorithm={algorithm} approvalFlows={approvalFlows} />
			) : null}
		</div>
	);

	if (contentOnly) {
		return content;
	}

	return (
		<Block className={classNames(classes.approvalAlgorithm, className)}>
			<AlgorithmTitle {...props} />
			{content}
		</Block>
	);
};

const ApprovalAlgorithmWithContext: FC = () => {
	const { state } = useWorkflowEditorContext();
	const classes = useStyles();
	return (
		<div className={classNames(classes.rulesContainer, classes.editable)}>
			{state.rulesAndFlows?.map((_, index) => (
				<div key={index} className={classes.ruleAndFlowContainer}>
					<AlgorithmRuleEditable index={index} />
					<ApprovalFlowEditable index={index} />
				</div>
			))}
		</div>
	);
};

const ApprovalAlgorithmWithProps: FC<{
	algorithm: ApprovalAlgorithmModel;
	approvalFlows: Map<string, ApprovalFlowModel>;
}> = ({ algorithm, approvalFlows }) => {
	const classes = useStyles();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const { rules } = algorithm;
	const sortedRules = useMemo(() => {
		if (algorithm) {
			if (!rules) {
				openGlobalErrorModal(
					IsNullError.from({
						location: "ApprovalAlgorithmWithProps",
						parentObject: {
							name: "algorithm",
							value: algorithm.toJS()
						},
						requestedProperty: "rules"
					})
				);
			} else {
				return rules.sortBy(rule => rule.sortOrder);
			}
		}
		return [];
	}, [algorithm, openGlobalErrorModal, rules]);
	return (
		<div className={classes.rulesContainer}>
			{sortedRules.map(rule => {
				const approvalFlow = approvalFlows.get(rule.approvalFlowId);
				if (!approvalFlow) return null;
				return (
					<div key={rule.id} className={classes.ruleAndFlowContainer}>
						<AlgorithmRule algorithmRule={rule} />
						<ApprovalFlow approvalFlow={approvalFlow} />
					</div>
				);
			})}
		</div>
	);
};
