import constate from "constate";
import { Map, Record } from "immutable";
import { useCallback, useMemo, useRef } from "react";
import { getUser, multiGetUsers } from "api/users";
import { UserModel } from "models/UserModel";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useThrottledBulkFetch } from "hooks/useThrottledBulkFetch";
import { notEmpty } from "utils/comparison";

type TUserState = { loading: boolean; data: UserModel | null; hadError: boolean };
class UserLoadingState extends Record<TUserState>({ loading: false, data: null, hadError: false }) {}

const useUsers = () => {
	const {
		itemsById: users,
		loadIds: loadUsers,
		setItemsById: setUsers
	} = useThrottledBulkFetch(multiGetUsers, {
		throttleTime: 50,
		includeDeleted: true
	});
	const fullUsersStateRef = useRef(Map<string, UserLoadingState>());
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const setUser = useCallback(
		(user: UserModel) => {
			fullUsersStateRef.current = fullUsersStateRef.current.set(
				user.id,
				(fullUsersStateRef.current.get(user.id) || new UserLoadingState()).set("loading", false).set("data", user)
			);
			setUsers(current => current?.set(user.id, user) || current);
		},
		[setUsers]
	);

	const loadFullUser: (id: string) => Promise<UserModel | null> = useCallback(
		async (id: string) => {
			try {
				const state = fullUsersStateRef.current.get(id) || new UserLoadingState();
				if (!state.loading) {
					fullUsersStateRef.current = fullUsersStateRef.current.set(id, state.set("loading", true));
					const fullUser = await getUser(id);
					setUser(fullUser);
					return fullUser;
				}
				return state.data;
			} catch (err) {
				openGlobalErrorModal(err as Error);
				fullUsersStateRef.current = fullUsersStateRef.current.set(
					id,
					fullUsersStateRef.current.get(id)!.set("loading", false).set("hadError", true)
				);
				return null;
			}
		},
		[openGlobalErrorModal, setUser]
	);

	const loadUser = useCallback((id: string) => loadUsers([id]), [loadUsers]);

	const fullUsers: Map<string, UserModel> = useMemo(
		() => fullUsersStateRef.current.map(value => value.data).filter(notEmpty),
		[]
	);

	const sortedUsers = useMemo(
		() =>
			users
				.filter(notEmpty)
				?.toList()
				?.sortBy(user => user.fullName),
		[users]
	);

	const addUsersToContext = useCallback(
		(newUsers: UserModel[]) => {
			const missingUsers = newUsers.filter(user => !users.has(user.id));
			if (!missingUsers.length) return;
			setUsers(curr => {
				missingUsers.forEach(user => {
					curr = curr.set(user.id, user);
				});
				return curr;
			});
		},
		[setUsers, users]
	);

	return {
		state: {
			users,
			sortedUsers,
			fullUsers,
			fullUsersState: fullUsersStateRef.current
		},
		actions: { loadUsers, loadUser, loadFullUser, setUser, addUsers: addUsersToContext }
	};
};

export const [UsersProvider, useUsersContext] = constate(useUsers);
