import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useApprovalFlowsWebhooksMap } from "hooks/useApprovalFlowsWebhooksMap";
import { WebhookIcon } from "components/ui/Icons/WebhookIcon";
import { Typography } from "components/ui/legacy/Typography";
import { directoryIconRegex } from "utils/ui/directoryGroups";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { useMultiUsers } from "hooks/useMultiUsers";
import { EmailTooltip } from "../EmailTooltip";
import { TextWithDirectoryIcon } from "../TextWithDirectoryIcon";
import { Entity } from "../Entity";
import { Avatar } from "../Avatar";
import { useStyles } from "./styles";
import type { Map } from "immutable";
import type { TFunction } from "i18next";
import type { TicketApprovalEntityModel } from "models/TicketApprovalEntityModel";
import type { UserModel } from "models/UserModel";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";
import type { IApprovalStatusChipProps, TApprovalStatus } from "../ApprovalStatusChip";
import type { TRespondersInGroup } from "./TicketListComponents";

type TMultipleApproverEntity = TicketApprovalEntityModel & {
	type: "IntegrationMaintainer" | "IntegrationOwner" | "ResourceMaintainer" | "ResourceOwner";
};

interface IMapGroupApproverOptions {
	directoryGroup: DirectoryGroupModel | null;
	entity: TicketApprovalEntityModel;
	getRespondersInGroup: (userIds: string[]) => TRespondersInGroup;
	className: string;
}

const TRANSLATION_PREFIX = "common.ticketList.";

const isMultipleApproverEntity = (entity: TicketApprovalEntityModel): entity is TMultipleApproverEntity =>
	entity.approvers.size > 1;

const getAvatar = (user: UserModel | undefined) => (user ? <Avatar user={user} /> : undefined);

function mapSingleApprover(
	entity: TicketApprovalEntityModel,
	getApprovalStatus: (entity: TicketApprovalEntityModel) => TApprovalStatus,
	t: TFunction<"translation", undefined>,
	usersMap: Map<string, UserModel> | null
): TProps<IApprovalStatusChipProps> {
	const { approverIds, type, displayName } = entity;
	const user = usersMap?.get(approverIds.first());
	const approvalStatus = getApprovalStatus(entity);
	const typeTranslation = `${TRANSLATION_PREFIX}${type}` as const;

	const matches = directoryIconRegex.exec(displayName);
	const cleanDisplayName = matches?.[2] || undefined;

	const approverDisplayType =
		type === "User"
			? ""
			: ` (${t(typeTranslation, { count: 1, groupName: type === "DirectoryGroup" ? cleanDisplayName : undefined })})`;

	return {
		approvalStatus,
		approverType: user ? "user" : "system",
		approverName: user ? user.fullName + approverDisplayType : displayName,
		entityIcon: getAvatar(user),
		isDeleted: user?.isDeleted,
		entityId: user?.id,
		tooltip: user?.email
	};
}

function mapGroupApprovers(
	options: IMapGroupApproverOptions,
	usersMap: Map<string, UserModel> | null
): TProps<IApprovalStatusChipProps> {
	const { directoryGroup, entity, getRespondersInGroup, className } = options;
	const { approvers, displayName, approverIds } = entity;
	const groupEmail = directoryGroup?.email;
	const groupIsDeleted = directoryGroup?.isDeleted;
	const [respondersInGroup, approvalStatus] = getRespondersInGroup(approverIds.toArray());
	if (respondersInGroup.length) {
		const responderInGroup = respondersInGroup[0];
		const responder = usersMap?.get(responderInGroup);
		if (responder && approverIds.includes(responderInGroup)) {
			return {
				approvalStatus,
				approverType: "group",
				approverName: displayName,
				className,
				children: (
					<>
						<EmailTooltip email={groupEmail || undefined}>
							<TextWithDirectoryIcon value={displayName} isDeleted={groupIsDeleted} />
						</EmailTooltip>
						<span> </span> {/* for extra space */}
						<EmailTooltip email={responder?.email} isDeleted={responder?.isDeleted}>
							<Typography relative>
								<Entity content={`(${responder?.fullName})`} isDeleted={responder.isDeleted} />
							</Typography>
						</EmailTooltip>
					</>
				)
			};
		}
	}

	return {
		approverIds: approvers.map(approver => approver.userIds.toArray()).toArray(),
		approvalStatus,
		approverType: "group",
		approverName: displayName,
		tooltip: groupEmail || undefined,
		isDeleted: directoryGroup?.isDeleted
	};
}

export const useApproverChip = (
	entity: TicketApprovalEntityModel,
	getApprovalStatus: (entity: TicketApprovalEntityModel) => TApprovalStatus,
	getRespondersInGroup: (userIds: string[]) => TRespondersInGroup
) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const directoryGroup = useDirectoryGroup((entity.type === "DirectoryGroup" && entity.identifier) || "");
	const approverUsers = useMultiUsers(entity.approverIds.toArray());
	const webhooks = useApprovalFlowsWebhooksMap();

	const chipProps: TProps<IApprovalStatusChipProps> = useMemo(() => {
		const { approvers, type, identifier } = entity;
		if (type === "Automatic") {
			const approverName = t("common.approvalFlow.entityTypes.Automatic");
			return { approvalStatus: "approved", approverType: "auto", approverName };
		}
		if (type === "Webhook") {
			const webhook = webhooks && identifier ? webhooks.get(identifier) : null;
			const defaultTypeName = t("common.ticketList.Webhook");
			let webhookTypeName = defaultTypeName;
			let approverName = webhook ? `${webhook.name} (${webhookTypeName})` : webhookTypeName;
			if (entity.approvers.size) {
				webhookTypeName = t("common.ticketList.Webhook", {
					context: "redirect",
					webhookName: webhook?.name || defaultTypeName
				});
				approverName = `${entity.displayName} (${webhookTypeName})`;
			}
			const status = getApprovalStatus(entity);

			return { approverName, approvalStatus: status, approverType: "webhook", entityIcon: <WebhookIcon /> };
		}
		const flatApproverIds = entity.approverIds.toArray();

		if (isMultipleApproverEntity(entity)) {
			// Only system objects have multiple approvers, like integration maintainers and owners
			const status = getApprovalStatus(entity);
			const approverName = t(`${TRANSLATION_PREFIX}${entity.type}`, { count: flatApproverIds.length });
			const approverIds = approvers.map(approver => approver.userIds.toArray()).toArray();

			return { approvalStatus: status, approverName, approverType: "system", approverIds };
		}

		return flatApproverIds.length === 1
			? mapSingleApprover(entity, getApprovalStatus, t, approverUsers)
			: mapGroupApprovers(
					{ entity, directoryGroup, getRespondersInGroup, className: classes.approverChip },
					approverUsers
				);
	}, [
		entity,
		getApprovalStatus,
		t,
		approverUsers,
		directoryGroup,
		getRespondersInGroup,
		classes.approverChip,
		webhooks
	]);

	return chipProps;
};
