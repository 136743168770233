import classNames from "classnames";
import { Unknown } from "components/ui/Unknown";
import { Typography } from "components/ui/legacy/Typography";
import { UserModel } from "models/UserModel";
import React from "react";
import { Avatar } from "../Avatar";
import { useStyles } from "./styles";

export const UserOption: FC<{ option: UserModel; noEmail?: boolean }> = ({ option, className, noEmail = false }) => {
	const classes = useStyles();

	return (
		<Typography variant="tiny" component="div" className={classNames(classes.container, className)}>
			<Unknown unknown={!option}>
				<>
					<Typography variant="standard" className={classes.userTitle}>
						<Avatar user={option} />
						{option.fullName}
					</Typography>
					{!noEmail && option.email && (
						<Typography noWrap variant="tiny">
							{option.email}
						</Typography>
					)}
				</>
			</Unknown>
		</Typography>
	);
};
