import { Record } from "immutable";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { TDiff } from "utils/auditLogs/auditLogUtils";

export interface IIntegrationResourceAuditLogDiffData {
	approvalAlgorithmId?: TDiff<string>;
	name?: TDiff<string>;
	owner?: TDiff<string>;
	allowsRequests?: TDiff<boolean>;
	userDefinedDescription: TDiff<string>;
	userDefinedTags: TDiff<string[]>;
	allowedDurations: TDiff<number[]>;
}

type TIntegrationResourceAuditLogAction =
	| "ResourceCreatedManually"
	| "ResourceDeletedManually"
	| "ResourceUpdatedOverrideAllowedDurations"
	| "ResourceUpdatedAllowRequests"
	| "ResourceUpdatedWorkflow"
	| "ResourceUpdatedName"
	| "ResourceUpdatedMaintainers"
	| "ResourceUpdatedOwner"
	| "ResourceUpdatedDescription"
	| "ResourceUpdatedTags";

const defaults = {
	action: "ResourceCreatedManually" as TIntegrationResourceAuditLogAction,
	integrationResource: null as IntegrationResourceModel | null,
	integrationResourceId: "",
	id: "",
	integrationId: "",
	createdAt: new Date(0),
	data: null as IIntegrationResourceAuditLogDiffData | null,
	userId: ""
};

export class IntegrationResourceAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "integrationResource";

	static fromServerData(data: unknown) {
		const {
			id,
			createdAt,
			data: _data,
			action,
			integrationResource,
			integrationId,
			integrationResourceId,
			userId
		} = data as typeof defaults;

		return new IntegrationResourceAuditLogModel({
			id,
			createdAt: new Date(createdAt),
			data: _data ? (_data as IIntegrationResourceAuditLogDiffData) : null,
			action,
			integrationId,
			integrationResource: integrationResource ? IntegrationResourceModel.fromServerData(integrationResource) : null,
			integrationResourceId,
			userId
		});
	}
}
