import { useCallback, useEffect, useRef, useState } from "react";
import { devLog, isDevelopment } from "utils/devtools/devLogging";

type TControlledValue<T> = T | undefined | null;

export function useControlled<T>({
	controlled,
	default: defaultProp
}: {
	controlled: TControlledValue<T>;
	default: TControlledValue<T>;
}): [TControlledValue<T>, (value: T | undefined) => void] {
	const { current: isControlled } = useRef(controlled !== undefined);
	const [valueState, setValue] = useState<TControlledValue<T>>(defaultProp);
	const value = isControlled ? controlled : valueState;

	const setValueIfUncontrolled = useCallback(
		(value: T | undefined) => {
			if (!isControlled) {
				setValue(value);
			}
		},
		[isControlled]
	);

	// Log possible errors in development mode
	const { current: defaultValue } = useRef(defaultProp);
	const checkControlledStateChange = useCallback(() => {
		if (isControlled !== (controlled !== undefined)) {
			devLog({
				message: [
					`A component is changing it's state from uncontrolled to controlled (or vice verse).`,
					"Decide between using a controlled or uncontrolled element for the lifetime of the component.",
					"The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`."
				].join("\n"),
				level: "error"
			});
		}
		// deliberate disable to avoid linting errors, as this is a development only check
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [controlled]);

	const checkDefaultValueChange = useCallback(() => {
		if (!isControlled && defaultValue !== defaultProp) {
			devLog({
				message: [
					"A component is changing the default state of an uncontrolled value after being initialized.",
					"This is usually not what you want to do. Consider switching to a controlled element."
				].join("\n"),
				level: "error"
			});
		}
		// deliberate disable to avoid linting errors, as this is a development only check
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tryStringify(defaultProp)]);

	useEffect(() => {
		if (isDevelopment) {
			checkControlledStateChange();
			checkDefaultValueChange();
		}
	}, [checkControlledStateChange, checkDefaultValueChange]);

	return [value, setValueIfUncontrolled];
}

const tryStringify = (value: unknown) => {
	try {
		return JSON.stringify(value);
	} catch {
		return value;
	}
};
