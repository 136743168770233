import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	userImage: {
		overflow: "hidden",
		marginRight: "0.6rem"
	},
	table: {
		backgroundColor: "white",
		borderRadius: "var(--border-radius-semi-rounded)",
		marginBottom: "1rem",
		"&:last-child": {
			marginBottom: "unset"
		}
	},
	cell: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		height: "100%",
		width: "100%",
		padding: ".5rem 0"
	},
	usersTableContainer: {
		height: "1px",
		flex: "1",
		display: "flex"
	},
	container: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		margin: 0,
		padding: "2rem",
		gap: "2rem"
	},
	shrink: {
		flexShrink: "0"
	},
	input: {
		width: "33%",
		flexGrow: 0
	}
});
