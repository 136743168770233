import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderCellContent, IconCellContent, TextCellContent } from "components/ui/VirtualTable/components";
import { IntegrationImage } from "components/common/IntegrationImage";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { useTranslation } from "react-i18next";
import { useIntegrations } from "hooks/useIntegrations";
import { TColumn, PaginatedVirtualTable } from "components/ui/VirtualTable";
import { CalendarIcon } from "components/ui/Icons/CalendarIcon";
import { DurationIcon } from "components/ui/Icons/DurationIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { PermissionsIcon } from "components/ui/Icons/PermissionsIcon";
import { EmailIcon } from "components/ui/Icons/EmailIcon";
import { EntitleLogo } from "components/ui/systemLogos/EntitleLogo";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMultiUsers } from "hooks/useMultiUsers";
import { IntegrationLogDetails } from "../IntegrationLogDetails";
import { PermissionsCell } from "../utils/PermissionsCell";
import { UsersCell } from "../utils/UsersCell";
import { formatDateToText, formatTimeToText } from "../utils/utils";
import { useStyles } from "./styles";
import type { TTimezone } from "utils/auditLogs/systemAuditLogTimezone";
import type { SessionAuditLogModel } from "models/auditLogs/SessionAuditLogModel";

type TIntegrationLogsTableProps = {
	sessionAuditLogs: (SessionAuditLogModel | undefined)[];
	fetchPage: (page: number) => void;
	isLoading: boolean;
	totalResults: number;
	timezone: TTimezone;
	closeDrawer?: (isOpen: boolean) => void;
};
const ENTITLE_SOURCE = "entitle";
const INTEGRATION_LOGS_TAB = "integrationLogs";
const PER_PAGE = 50;
const INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX = "pages.auditLog.integrationLogs";

const COLUMNS_WIDTHS = {
	dateColumnSize: "124px",
	timeColumnSize: "116px",
	actionColumnSize: "minmax(400px, 1fr)",
	subjectColumnSize: "minmax(164px, 220px)",
	targetColumnSize: "minmax(300px, 1fr)",
	statusColumnSize: "minmax(100px, 220px)",
	integrationColumnSize: "minmax(168px, 200px)",
	sourceColumnSize: "60px",
	userColumnSize: "minmax(164px, 220px)",
	emailColumnSize: "minmax(120px, 280px)",
	accountColumnSize: "minmax(164px, 180px)",
	permissionsColumnSize: "168px"
};
export const IntegrationLogsTable: FC<TIntegrationLogsTableProps> = ({
	className,
	innerRef,
	sessionAuditLogs,
	totalResults,
	isLoading,
	fetchPage,
	timezone,
	closeDrawer
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const integrations = useIntegrations();
	const sessionAuditLogUserIds = useMemo(() => sessionAuditLogs.flatMap(log => log?.users) || [], [sessionAuditLogs]);
	const users = useMultiUsers(sessionAuditLogUserIds);
	const navigate = useNavigate();
	const [urlSearchParams] = useSearchParams();

	const [selectedRow, setSelectedRow] = useState<SessionAuditLogModel>();

	const renderEmailTextCell = useCallback(
		(row: SessionAuditLogModel) => {
			return users?.get(row.users[0])?.email;
		},
		[users]
	);

	const isRowSelectable = useCallback((row: SessionAuditLogModel) => {
		return row.source !== ENTITLE_SOURCE;
	}, []);

	const changeSelectedRow = useCallback(
		(row: SessionAuditLogModel) => {
			if (isRowSelectable(row)) {
				setSelectedRow(row);
			}
		},
		[isRowSelectable]
	);

	const toggleRowSelection = useCallback(
		(row: SessionAuditLogModel) => {
			return urlSearchParams.get("integrationLogId") === row.id;
		},
		[urlSearchParams]
	);

	const moveToNextLog = useCallback(() => {
		let passedCurrent = false;
		const nextLog = sessionAuditLogs.find(log => {
			if (passedCurrent && log && isRowSelectable(log)) return true;
			passedCurrent = passedCurrent || log!.id === selectedRow!.id;
			return false;
		});
		if (nextLog) changeSelectedRow(nextLog);
	}, [changeSelectedRow, isRowSelectable, selectedRow, sessionAuditLogs]);

	const moveToPrevLog = useCallback(() => {
		const currentIndex = sessionAuditLogs.findIndex(row => selectedRow?.id === row?.id);
		if (currentIndex > 0) {
			const prevRow = sessionAuditLogs
				.slice(0, currentIndex)
				.reverse()
				.find(row => row && isRowSelectable(row));
			if (prevRow) changeSelectedRow(prevRow);
		}
	}, [changeSelectedRow, isRowSelectable, selectedRow?.id, sessionAuditLogs]);

	useEffect(() => {
		if (selectedRow) {
			navigate(`?tab=${INTEGRATION_LOGS_TAB}&integrationLogId=${selectedRow?.id}`);
			closeDrawer?.(false);
		}
	}, [closeDrawer, navigate, selectedRow]);

	useEffect(() => {
		navigate(`?tab=${INTEGRATION_LOGS_TAB}`);
	}, [navigate]);

	const columns = useMemo(
		() =>
			[
				{
					renderCell: TextCellContent,
					getProps: (row: SessionAuditLogModel) => ({ text: formatDateToText(new Date(row.date), timezone) }),
					header: (
						<HeaderCellContent
							text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.date`)}
							icon={<CalendarIcon />}
						/>
					),
					key: "date",
					width: COLUMNS_WIDTHS.dateColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: (row: SessionAuditLogModel) => ({ text: formatTimeToText(row.date, timezone) }),
					header: (
						<HeaderCellContent
							text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.time`)}
							icon={<DurationIcon />}
						/>
					),
					key: "time",
					width: COLUMNS_WIDTHS.timeColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: (row: SessionAuditLogModel) => ({ text: row.action }),
					header: <HeaderCellContent text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.action`)} />,
					key: "action",
					width: COLUMNS_WIDTHS.actionColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: (row: SessionAuditLogModel) => ({ text: row.subject }),
					header: <HeaderCellContent text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.subject`)} />,
					key: "subject",
					width: COLUMNS_WIDTHS.subjectColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: (row: SessionAuditLogModel) => ({ text: row.target }),
					header: <HeaderCellContent text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.target`)} />,
					key: "target",
					width: COLUMNS_WIDTHS.targetColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: (row: SessionAuditLogModel) => ({ text: row.status }),
					header: <HeaderCellContent text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.status`)} />,
					key: "status",
					width: COLUMNS_WIDTHS.statusColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: row => {
						const integration = integrations?.get(row.integrationId!);
						return {
							icon: integration ? <IntegrationImage noBackground noWrap size="xs" integration={integration} /> : null,
							text: integration?.name ?? ""
						};
					},
					header: (
						<HeaderCellContent
							text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.integration`)}
							icon={<IntegrationIcon />}
						/>
					),
					key: "integration",
					width: COLUMNS_WIDTHS.integrationColumnSize
				},
				{
					renderCell: IconCellContent,
					headerContainerClassName: classes.logoTableHeader,
					getProps: row => {
						return {
							icon: row.source === ENTITLE_SOURCE ? <EntitleLogo /> : null
						};
					},
					key: "source",
					width: COLUMNS_WIDTHS.sourceColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: row => ({
						text: <UsersCell rowUsers={row.users} isCell />
					}),
					header: (
						<HeaderCellContent
							text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.user`)}
							icon={<UserCircleIcon />}
						/>
					),
					key: "user",
					width: COLUMNS_WIDTHS.userColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: row => ({
						text: renderEmailTextCell(row)
					}),
					header: (
						<HeaderCellContent
							text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.email`)}
							icon={<EmailIcon />}
						/>
					),
					key: "email",
					width: COLUMNS_WIDTHS.emailColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: (row: SessionAuditLogModel) => ({ text: row.account }),
					header: (
						<HeaderCellContent
							text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.account`)}
							icon={<AccountIcon />}
						/>
					),
					key: "account",
					width: COLUMNS_WIDTHS.accountColumnSize
				},
				{
					renderCell: (row: SessionAuditLogModel) => <PermissionsCell permissions={row.permissions} />,
					header: (
						<HeaderCellContent
							text={t(`${INTEGRATION_LOG_PAGE_TRANSLATION_PREFIX}.columns.permissions`)}
							icon={<PermissionsIcon />}
						/>
					),
					key: "permissions",
					width: COLUMNS_WIDTHS.permissionsColumnSize
				}
			] as TColumn<SessionAuditLogModel>[],
		[t, classes.logoTableHeader, timezone, integrations, renderEmailTextCell]
	);

	return (
		<>
			<IntegrationLogDetails
				integrationAuditLog={selectedRow}
				timezone={timezone}
				moveToNextLog={moveToNextLog}
				moveToPrevLog={moveToPrevLog}
				setSelectedRow={setSelectedRow}
			/>
			<PaginatedVirtualTable
				rows={sessionAuditLogs}
				totalRows={totalResults}
				selectable
				columns={columns}
				toggleRowSelection={toggleRowSelection}
				compact
				selectableTrigger="regular"
				className={className}
				onRowClicked={changeSelectedRow}
				innerRef={innerRef}
				isRowHoverable={isRowSelectable}
				isLoading={isLoading}
				perPage={PER_PAGE}
				selectedRow={selectedRow}
				fetchPage={fetchPage}
			/>
		</>
	);
};
