import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes/routes";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { Button } from "components/ui/Button";
import { useIsOpenState } from "hooks/useIsOpenState";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { SubmitModal } from "components/common/Stepper/components/SubmitModal";
import { RequestReasonInputs } from "../RequestReasonInputs";
import { useNewRequestFormContext } from "../../newRequestFormContext";
import { OnBehalfSection } from "./components/OnBehalfSection";
import { RequestTargetsSection } from "./components/RequestTargetsSection";
import { AccessDurationSection } from "./components/AccessDurationSection";
import {
	useHandleNewRequestIntegrationActors,
	useNewRequestSubmit,
	useSwitchablePrePopulatedData
} from "./summaryStep.hooks";
import { ChooseActorModal } from "./components/ChooseActorModal";
import { RemoveAllTargetsModal } from "./components/RemoveAllTargetsModal";
import { ReceiverMissingActorModal } from "./components/ReceiverMissingActorModal";
import { CopyTemplate } from "./components/CopyTemplate";
import { NotAllowedRequestModal } from "./components/UnauthorizedDataModal";

export const SummaryStep: FC<{ onExit: () => void }> = ({ className, innerRef, onExit }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep" });

	const {
		actions: { setFooterAction }
	} = useStepperContext();

	const {
		actorModalsShown,
		chooseActorModal,
		isIntegrationActorsValid,
		multipleAndMissingActorsIntegrations,
		multipleAndMissingActorsTargets,
		showMissingAndChoiceModals,
		receiverMissingActorsModal
	} = useHandleNewRequestIntegrationActors();

	const {
		state: { isFormValid, fullReceiverUser }
	} = useNewRequestFormContext();

	const { isLoading: isLoadingUrlData, hasPrePopulatedData, isNotAllowed } = useSwitchablePrePopulatedData();
	const createRequestModal = useIsOpenState();
	const removeAllTargetsModal = useIsOpenState();
	const { onSubmit: apiNewRequestSubmit, error, success } = useNewRequestSubmit();

	const integrationActorsAreValid = useMemo(() => isIntegrationActorsValid(), [isIntegrationActorsValid]);

	const missingActorIntegrations = useMemo(
		() =>
			multipleAndMissingActorsIntegrations?.filter(integration => {
				if (!fullReceiverUser || !fullReceiverUser.integrationActors) return false;
				const currentIntegrationUserActors = fullReceiverUser.integrationActors.filter(
					actor => actor.integrationId === integration.id
				);
				return !currentIntegrationUserActors.size;
			}) || null,
		[fullReceiverUser, multipleAndMissingActorsIntegrations]
	);

	const actorIntegration = useMemo(
		() =>
			multipleAndMissingActorsIntegrations?.find(integration => chooseActorModal.modalToShow === integration.id) ||
			null,
		[chooseActorModal.modalToShow, multipleAndMissingActorsIntegrations]
	);

	const onSubmit = useCallback(() => {
		if (!integrationActorsAreValid && !actorModalsShown) {
			showMissingAndChoiceModals();
			return;
		}
		if (!isFormValid || !integrationActorsAreValid) return;
		createRequestModal.open();
		apiNewRequestSubmit();
	}, [
		actorModalsShown,
		apiNewRequestSubmit,
		createRequestModal,
		isFormValid,
		integrationActorsAreValid,
		showMissingAndChoiceModals
	]);

	const submitButton = useMemo(() => {
		let disabled = !isFormValid;
		let tooltip = undefined;
		if (actorModalsShown) {
			disabled = !(isFormValid && integrationActorsAreValid);
			tooltip = integrationActorsAreValid ? undefined : t("invalidSubmitTooltip");
		}
		return (
			<Button
				variant="primary"
				size="large"
				suffix={<GrantedIcon size={24} />}
				onClick={onSubmit}
				disabled={disabled}
				tooltip={tooltip}>
				{t("submitButtonLabel")}
			</Button>
		);
	}, [actorModalsShown, integrationActorsAreValid, isFormValid, onSubmit, t]);

	useEffect(() => {
		setFooterAction("complete", submitButton);
		return () => setFooterAction("complete", undefined);
	}, [setFooterAction, submitButton]);

	const isDataValid = useMemo(() => {
		const isUrlFormDataValid = !isLoadingUrlData && !isNotAllowed;
		return !hasPrePopulatedData || isUrlFormDataValid;
	}, [isLoadingUrlData, isNotAllowed, hasPrePopulatedData]);

	return (
		<PageTitleContent innerRef={innerRef} className={className} noTransition>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top>
					<PageTitleContent.Header.Actions>
						<IconButton size="large" onClick={onExit}>
							<CloseIcon />
						</IconButton>
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Top>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("title")} />
					<PageTitleContent.Header.Actions>{isDataValid ? <CopyTemplate /> : null}</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<NotAllowedRequestModal isOpen={!!isNotAllowed} />
				{isDataValid ? (
					<>
						<ReceiverMissingActorModal
							missingActorIntegrations={missingActorIntegrations}
							isOpen={receiverMissingActorsModal.isOpen}
							close={receiverMissingActorsModal.close}
						/>
						<ChooseActorModal
							actorIntegration={actorIntegration}
							isOpen={chooseActorModal.isOpen}
							close={chooseActorModal.onClose}
						/>
						<SubmitModal
							close={createRequestModal.close}
							isOpen={createRequestModal.isOpen}
							retry={onSubmit}
							error={!!error}
							success={success}
							navigateTo={routes.requests.main}
							successMessage={t("createRequestModal.success")}
							errorTitle={t("createRequestModal.error.title")}
							errorContent={t("createRequestModal.error.content")}
							loadingMessage={t("createRequestModal.loading")}
							goBackMessage={t("createRequestModal.error.goBack")}
						/>
						<RemoveAllTargetsModal isOpen={removeAllTargetsModal.isOpen} close={removeAllTargetsModal.close} />
						<OnBehalfSection />
						<RequestTargetsSection
							onExit={removeAllTargetsModal.open}
							chooseActor={actorModalsShown ? chooseActorModal.open : undefined}
							multipleAndMissingActorsTargets={multipleAndMissingActorsTargets}
						/>
						<AccessDurationSection />
						<RequestReasonInputs hasPrePopulatedData={hasPrePopulatedData} />
					</>
				) : null}
				{isLoadingUrlData ? <LoadingSpinner /> : null}
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
