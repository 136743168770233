import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { useTranslation } from "react-i18next";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Button } from "components/ui/Button";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "components/ui/Icons/ArrowLeftIcon";
import { IconButton } from "components/ui/IconButton";
import { RulesSection } from "components/common/Rules/RulesSection";
import { useGoBack } from "hooks/useGoBack";
import { RulesProvider, useRulesContext } from "./RulesContext";

const PageContent: FC = () => {
	const {
		actions: { setRulesReadonly }
	} = useRulesContext();

	useEffect(() => {
		setRulesReadonly(false);
	}, [setRulesReadonly]);
	return <RulesSection shouldImmediatelyChangePriorityInServer />;
};

export const RulesPage: FC = React.memo(function RulesPage({ className, innerRef }) {
	const { t } = useTranslation("translation", { keyPrefix: "pages.rules" });
	const navigate = useNavigate();

	const onGoBack = useGoBack("/integrations");
	const onCreateRule = useCallback(() => navigate("create"), [navigate]);
	return (
		<RulesProvider>
			<PageTitleContent innerRef={innerRef} className={classNames(className)}>
				<PageTitleContent.Header>
					<PageTitleContent.Header.Top
						breadcrumbs={
							<IconButton size="large" onClick={onGoBack}>
								<ArrowLeftIcon />
							</IconButton>
						}
					/>
					<PageTitleContent.Header.Bottom>
						<PageTitleContent.Header.Title title={t("title")} subtitle={t("subtitle")} />
						<PageTitleContent.Header.Actions>
							<Button size="large" prefix={<AddIcon />} onClick={onCreateRule}>
								{t("buttons.addRule")}
							</Button>
						</PageTitleContent.Header.Actions>
					</PageTitleContent.Header.Bottom>
				</PageTitleContent.Header>
				<PageTitleContent.Body>
					<PageContent />
				</PageTitleContent.Body>
			</PageTitleContent>
		</RulesProvider>
	);
});
