import constate from "constate";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import set from "lodash/set";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { Map, is } from "immutable";
import { useNavigate } from "react-router-dom";
import { fromFilters } from "utils/api/urlSearchParams";
import { ALL_FILTERS_BY_NAME, type TFilterName } from "filters";
import { requirePropertyOf } from "utils/security";
import { bulkEditIntegrations, bulkEditResources, bulkEditRoles } from "api/bulkEdit";
import { IntegrationIdFilter } from "filters/integration";
import { IntegrationResourceIdFilter } from "filters/integrationResource";
import { IntegrationResourceRoleIdFilter } from "filters/integrationResourceRole";
import { useBulkActionsUpdatesContext } from "context/bulkActionsUpdatesContext";
import { useSubscriber } from "hooks/useSubscriber";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useBulkActionSort, useSelection } from "./bulkActions.hooks";
import {
	INTEGRATION,
	RESOURCE,
	ROLE,
	getIntegrationChangeKey,
	getResourceChangeKey,
	getRoleChangeKey,
	getStateFromSearchParams,
	type TMaintainersAction,
	type TChange,
	type TMaintainerOption,
	REQUESTABLE,
	MAINTAINER,
	WORKFLOW,
	OWNER
} from "./utils";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TBulkActionModels, TBulkActionTabOption, TFormFilters } from "./types";
import type { IFilter } from "types/filters";
import type { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import type { UserModel } from "models/UserModel";
import type { Constructor } from "types/utilTypes";

const generateEmptyFilters = () => {
	return Map<TBulkActionTabOption, TFormFilters>([
		[INTEGRATION, Map()],
		[RESOURCE, Map()],
		[ROLE, Map()]
	]);
};

const getValidFilters = (filters: TFormFilters) =>
	filters
		.toList()
		.filter(({ isValid }) => isValid)
		.map(({ filter }) => filter)
		.toArray();

const getValidFilterRecord = (filters: TFormFilters) =>
	filters
		.filter(({ isValid }) => isValid)
		.reduce(
			(acc, { filter }, filterName) => set(acc, filterName, [filter]),
			{} as Partial<Record<TFilterName, IFilter[]>>
		);

// The following function check if the two arrays have equal values regardless of order
const isDeepEqual = (filtersA: IFilter[], filtersB: IFilter[]) => {
	if (filtersA.length !== filtersB.length) return false;
	return filtersA.every(filterA => filtersB.some(filterB => is(filterA, filterB)));
};
const isUser = (value: TMaintainerOption): value is UserModel => "role" in value;

type TChangeValue = ApprovalAlgorithmModel | boolean | TMaintainerOption[] | UserModel;

const mapValue = (change: TChange, value: TChangeValue) => {
	switch (change) {
		case REQUESTABLE:
			return value as boolean;
		case MAINTAINER:
			return (value as TMaintainerOption[]).map(option => ({
				id: option.id,
				type: isUser(option) ? "user" : "directoryGroup"
			}));
		case WORKFLOW:
			return (value as ApprovalAlgorithmModel).id;
		case OWNER:
			return (value as UserModel).id;
		default:
			return null;
	}
};

const BULK_ACTION_DONE_RESET_TIMEOUT_MS = 3000;

const useBulkActions = () => {
	const [initializing, setInitializing] = useState(true);
	const [selectedTab, setSelectedTab] = useState<TBulkActionTabOption>(INTEGRATION);
	const [filters, setFilters] = useState(generateEmptyFilters());
	const integrationsBulkActionSelection = useSelection<IntegrationModel>();
	const resourcesBulkActionSelection = useSelection<IntegrationResourceModel>();
	const rolesBulkActionSelection = useSelection<IntegrationResourceRoleModel>();
	const selectAllIntegrations = integrationsBulkActionSelection.selectAll;
	const selectAllResources = resourcesBulkActionSelection.selectAll;
	const selectAllRoles = rolesBulkActionSelection.selectAll;
	const [totalAmount, setTotalAmount] = useState(0);
	const [isBulkActionSelected, setIsBulkActionSelected] = useState(false);
	const [isBulkActionDone, setIsBulkActionDone] = useState(false);
	const [validIntegrationFilters, setValidIntegrationFilters] = useState<IFilter[]>();
	const [validResourceFilters, setValidResourceFilters] = useState<IFilter[]>();
	const [validRoleFilters, setValidRoleFilters] = useState<IFilter[]>();
	const isFirstLoadRef = useRef(false);
	const lastSearch = useRef("");
	const navigate = useNavigate();
	const bulkActionsSort = useBulkActionSort();
	const {
		subscribeOnDone,
		unsubscribeOnDone,
		isBulkActionInProgress,
		setIsBulkActionInProgress,
		isBulkActionFailed,
		setIsBulkActionFailed
	} = useBulkActionsUpdatesContext();
	const {
		addSubscription: addResetSubscription,
		notify: notifyReset,
		removeSubscription: removeResetSubscription
	} = useSubscriber();
	const openErrorModal = useOpenGlobalErrorModal();

	const addOnResetCallback = useCallback(
		(key: string, callback: () => void) => {
			addResetSubscription(key, callback);
		},
		[addResetSubscription]
	);

	const {
		resetSelection: resetIntegrationSelection,
		excludedItems: excludedIntegrations,
		selectedItems: selectedIntegrations
	} = integrationsBulkActionSelection;
	const {
		resetSelection: resetResourceSelection,
		excludedItems: excludedResources,
		selectedItems: selectedResources
	} = resourcesBulkActionSelection;
	const {
		resetSelection: resetRoleSelection,
		excludedItems: excludedRoles,
		selectedItems: selectedRoles
	} = rolesBulkActionSelection;

	const resetAll = useCallback(() => {
		resetIntegrationSelection();
		resetResourceSelection();
		resetRoleSelection();
		setFilters(generateEmptyFilters());
		lastSearch.current = "";
		setTotalAmount(0);
		navigate({ search: "" }, { replace: true });
		notifyReset();
	}, [navigate, notifyReset, resetIntegrationSelection, resetResourceSelection, resetRoleSelection]);

	useEffect(() => {
		let timeout: number;
		subscribeOnDone("bulkActionsContext", (data: Record<string, unknown>) => {
			const isError = data.type === "error";
			setIsBulkActionDone(!isError);
			setIsBulkActionFailed(isError);
			timeout = window.setTimeout(() => {
				setIsBulkActionDone(false);
				setIsBulkActionFailed(false);
				resetAll();
			}, BULK_ACTION_DONE_RESET_TIMEOUT_MS);
		});

		return () => {
			unsubscribeOnDone("bulkActionsContext");
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [unsubscribeOnDone, resetAll, subscribeOnDone, setIsBulkActionFailed]);

	useEffect(() => {
		if (window.location.search === "" && lastSearch.current === "") {
			// no filters from url
			setInitializing(false);
		}

		if (lastSearch.current === window.location.search) return;
		isFirstLoadRef.current = true;
		setInitializing(true);
		const { tab, filters } = getStateFromSearchParams();
		setSelectedTab(tab);
		const asSelectedFilters: TFormFilters = Map(
			filters.map(filter => [filter.name as TFilterName, { filter, isValid: true }])
		);
		setFilters(current => current.set(tab, asSelectedFilters));
		setInitializing(false);
	}, [setSelectedTab]);

	const currentSelection = useMemo(() => {
		switch (selectedTab) {
			case INTEGRATION:
				return integrationsBulkActionSelection;
			case RESOURCE:
				return resourcesBulkActionSelection;
			case ROLE:
			default:
				return rolesBulkActionSelection;
		}
	}, [integrationsBulkActionSelection, resourcesBulkActionSelection, rolesBulkActionSelection, selectedTab]);

	useEffect(() => {
		if (initializing) return;
		const defaultMap: TFormFilters = Map();
		const newValidIntegrationFilters = getValidFilters(filters.get(INTEGRATION, defaultMap));
		const newValidResourceFilters = getValidFilters(filters.get(RESOURCE, defaultMap));
		const newValidRoleFilters = getValidFilters(filters.get(ROLE, defaultMap));
		const shouldSelectAll = !isFirstLoadRef.current;

		setValidIntegrationFilters(current => {
			if (isDeepEqual(newValidIntegrationFilters, current ?? [])) return current ?? [];
			if (shouldSelectAll) {
				selectAllIntegrations(newValidIntegrationFilters.length > 0);
			}
			return newValidIntegrationFilters;
		});

		setValidResourceFilters(current => {
			if (isDeepEqual(newValidResourceFilters, current ?? [])) return current ?? [];
			if (shouldSelectAll) {
				selectAllResources(newValidResourceFilters.length > 0);
			}
			return newValidResourceFilters;
		});

		setValidRoleFilters(current => {
			if (isDeepEqual(newValidRoleFilters, current ?? [])) return current ?? [];
			if (shouldSelectAll) {
				selectAllRoles(newValidRoleFilters.length > 0);
			}
			return newValidRoleFilters;
		});

		isFirstLoadRef.current = false;
	}, [initializing, filters, selectAllIntegrations, selectAllResources, selectAllRoles]);

	const initialized = useMemo(
		() => !isNil(validIntegrationFilters) && !isNil(validResourceFilters) && !isNil(validRoleFilters),
		[validIntegrationFilters, validResourceFilters, validRoleFilters]
	);

	const setSearch = useCallback(
		(filters: TFormFilters, tab: TBulkActionTabOption) => {
			const mergedSearchParams = fromFilters(getValidFilters(filters));
			mergedSearchParams.set("tab", tab);
			const newSearch = `?${mergedSearchParams.toString()}`;
			lastSearch.current = newSearch;
			navigate({ search: newSearch }, { replace: true });
		},
		[navigate]
	);

	const onChangeTab = useCallback(
		(tab: TBulkActionTabOption) => {
			setSelectedTab(tab);
			setSearch(filters.get(tab, Map()), tab);
		},
		[filters, setSearch, setSelectedTab]
	);

	const onChangeFilter = useCallback(
		(filter: IFilter, isValid = false) => {
			setFilters(current => {
				let newTabFilters: TFormFilters = Map();
				newTabFilters = current.get(selectedTab, newTabFilters);
				newTabFilters = newTabFilters.set(filter.name as TFilterName, { filter, isValid });
				setSearch(newTabFilters, selectedTab);

				return current.set(selectedTab, newTabFilters);
			});
		},
		[selectedTab, setSearch]
	);

	const onToggleFilter = useCallback(
		(filterName: TFilterName) => {
			// The casting here is because the produced type causes the following error:
			// "Expression produces a union type that is too complex to represent. ts(2590)"
			const FilterModel = requirePropertyOf(ALL_FILTERS_BY_NAME, filterName) as new () => IFilter;
			setFilters(current => {
				let newTabFilters: TFormFilters = Map();
				let isRemoved = false;

				newTabFilters = current.get(selectedTab, newTabFilters);
				isRemoved = newTabFilters.has(filterName);
				newTabFilters = isRemoved
					? newTabFilters.remove(filterName)
					: newTabFilters.set(filterName, { filter: new FilterModel(), isValid: false });

				setSearch(newTabFilters, selectedTab);

				return current.set(selectedTab, newTabFilters);
			});
		},
		[selectedTab, setSearch]
	);

	const onClearFilters = useCallback(
		(tab: TBulkActionTabOption) => {
			setFilters(current => current.set(tab, Map()));
			setSearch(Map(), selectedTab);
		},
		[setSearch, selectedTab]
	);

	const getFiltersForBulkAction = useCallback(
		(options: {
			FilterClass: Constructor<IFilter>;
			excludedItems: TBulkActionModels[];
			filterName: TFilterName;
			selectedItems: TBulkActionModels[];
			type: TBulkActionTabOption;
		}) => {
			const { excludedItems, FilterClass: filterClass, type, selectedItems, filterName } = options;
			let validFilters: Partial<Record<TFilterName, IFilter[]>> = {};
			if (selectedItems.length > 0) {
				const filter = new filterClass({
					value: selectedItems.map(({ id }) => id),
					operator: "is"
				});

				validFilters = { [filterName]: [filter] };
			} else {
				validFilters = getValidFilterRecord(filters.get(type, Map()));
				if (excludedItems.length > 0) {
					const currentIdFilters = get(validFilters, filterName) || [];
					const filter = new filterClass({
						value: excludedItems.map(({ id }) => id),
						operator: "isNot"
					});
					currentIdFilters.push(filter);
					validFilters = set(validFilters, filterName, currentIdFilters);
				}
			}
			return validFilters;
		},
		[filters]
	);

	const onBulkAction = useCallback(
		async (updates: { action?: TMaintainersAction; change: TChange; value: TChangeValue }) => {
			const { action, change, value } = updates;
			if (change === "maintainer" && !action) return;
			const mappedValue = mapValue(change, value);
			if (mappedValue === null) return;
			setIsBulkActionInProgress(true);
			try {
				if (selectedTab === INTEGRATION) {
					const validFilters = getFiltersForBulkAction({
						FilterClass: IntegrationIdFilter,
						excludedItems: excludedIntegrations,
						filterName: IntegrationIdFilter.filterName,
						selectedItems: selectedIntegrations,
						type: INTEGRATION
					});
					await bulkEditIntegrations(validFilters, {
						[getIntegrationChangeKey(change)]: change === "maintainer" ? { [action!]: mappedValue } : mappedValue
					});
				} else if (selectedTab === RESOURCE) {
					const validFilters = getFiltersForBulkAction({
						FilterClass: IntegrationResourceIdFilter,
						excludedItems: excludedResources,
						filterName: IntegrationResourceIdFilter.filterName,
						selectedItems: selectedResources,
						type: RESOURCE
					});
					await bulkEditResources(validFilters, {
						[getResourceChangeKey(change)]: change === "maintainer" ? { [action!]: mappedValue } : mappedValue
					});
				} else {
					const validFilters = getFiltersForBulkAction({
						FilterClass: IntegrationResourceRoleIdFilter,
						excludedItems: excludedRoles,
						filterName: IntegrationResourceRoleIdFilter.filterName,
						selectedItems: selectedRoles,
						type: ROLE
					});
					await bulkEditRoles(validFilters, {
						[getRoleChangeKey(change)]: mappedValue
					});
				}
			} catch (error) {
				setIsBulkActionInProgress(false);
				if (error instanceof Error) {
					openErrorModal(error);
				}
			}
		},
		[
			excludedIntegrations,
			excludedResources,
			excludedRoles,
			getFiltersForBulkAction,
			openErrorModal,
			selectedIntegrations,
			selectedResources,
			selectedRoles,
			selectedTab,
			setIsBulkActionInProgress
		]
	);

	const state = useMemo(
		() => ({
			isBulkActionFailed,
			bulkActionsSort,
			currentSelection,
			filters,
			integrationsBulkActionSelection,
			initialized,
			isBulkActionDone,
			isBulkActionInProgress,
			isBulkActionSelected,
			resourcesBulkActionSelection,
			rolesBulkActionSelection,
			selectedTab,
			totalAmount,
			validIntegrationFilters: validIntegrationFilters ?? [],
			validResourceFilters: validResourceFilters ?? [],
			validRoleFilters: validRoleFilters ?? []
		}),
		[
			isBulkActionFailed,
			bulkActionsSort,
			currentSelection,
			filters,
			integrationsBulkActionSelection,
			initialized,
			isBulkActionDone,
			isBulkActionInProgress,
			isBulkActionSelected,
			resourcesBulkActionSelection,
			rolesBulkActionSelection,
			selectedTab,
			totalAmount,
			validIntegrationFilters,
			validResourceFilters,
			validRoleFilters
		]
	);

	const actions = useMemo(
		() => ({
			addOnDoneCallback: addOnResetCallback,
			onBulkAction,
			onChangeFilter,
			onChangeTab,
			onClearFilters,
			onToggleFilter,
			removeOnDoneCallback: removeResetSubscription,
			setIsBulkActionDone,
			setIsBulkActionSelected,
			setTotalAmount
		}),
		[
			addOnResetCallback,
			onBulkAction,
			onChangeFilter,
			onChangeTab,
			onClearFilters,
			onToggleFilter,
			removeResetSubscription
		]
	);

	return useMemo(
		() => ({
			state,
			actions
		}),
		[state, actions]
	);
};

export const [BulkActionsProvider, useBulkActionsContext] = constate(useBulkActions);
