import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import classNames from "classnames";
import { Typography } from "components/ui/Typography";
import { Tooltip } from "components/ui/Tooltip";
import { IconButton } from "components/ui/IconButton";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { BasicCard } from "components/ui/BasicCard";
import { useRuleStepperContext } from "components/pages/CreateRulePage/ruleStepperContext";
import { TResourceUpdates, TRoleUpdates, TRuleType } from "models/RuleModel";
import { List } from "immutable";
import { IconPrefix } from "components/ui/IconPrefix";
import { FilterSection } from "components/ui/filters/FilterSection";
import { getIconByAttribute } from "components/common/Rules/RulesList/rules.utils";
import { useStyles } from "./styles";
import { AttributeExpression } from "./AttributeExpression";

const RULE_TYPES = ["resources", "roles"] as const;
const RESOURCE_UPDATES = ["approvalAlgorithmId", "ownerUserId", "allowsRequests", "maintainers"] as const;
const ROLE_UPDATES = ["approvalAlgorithmId", "allowsRequests"] as const;
const RULE_UPDATES = new Map<TRuleType, typeof RESOURCE_UPDATES | typeof ROLE_UPDATES>([
	["resources", RESOURCE_UPDATES],
	["roles", ROLE_UPDATES]
]);

type TUpdateAttribute = keyof TRoleUpdates | keyof TResourceUpdates;
export const UpdatesSection: FC = ({ className }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.createRule" });
	const { t: updatesTranslation } = useTranslation("translation", { keyPrefix: "pages.rules.updates" });
	const classes = useStyles();

	const {
		state: { updates, ruleType },
		actions: { setUpdatesAttribute, setRuleTypeByUpdates, removeAttribute }
	} = useRuleStepperContext();
	const [selectedAttributes, setSelectedAttributes] = useState<List<TUpdateAttribute>>(
		List(Object.keys(updates) as TUpdateAttribute[])
	);

	const removeSelectedAttribute = useCallback(
		(attribute: TUpdateAttribute) => {
			const newState = selectedAttributes.filter(selectedAttribute => selectedAttribute !== attribute);
			if (newState.size === 0) {
				setRuleTypeByUpdates(() => undefined); // we must provide function because undefined is treated as function instead of value
			}
			setSelectedAttributes(newState);
			removeAttribute(attribute);
		},
		[selectedAttributes, setRuleTypeByUpdates, removeAttribute]
	);

	const toggleAttributeSelection = useCallback(
		(attribute: TUpdateAttribute, ruleType: TRuleType) => {
			if (selectedAttributes.includes(attribute)) {
				if (get(updates, attribute) !== undefined) {
					return;
				}

				removeSelectedAttribute(attribute);
				return;
			}
			setUpdatesAttribute(attribute, undefined);
			setRuleTypeByUpdates(ruleType);
			setSelectedAttributes(selectedAttributes.push(attribute));
		},
		[selectedAttributes, updates, setRuleTypeByUpdates, removeSelectedAttribute, setUpdatesAttribute]
	);

	const enableSetTo = selectedAttributes.size > 0;

	const attributeExpressions = useMemo(
		() =>
			selectedAttributes
				.map(selectedAttribute => (
					<AttributeExpression
						selectedAttribute={selectedAttribute}
						ruleType={ruleType!}
						value={get(updates, selectedAttribute)!}
						setUpdatesAttribute={setUpdatesAttribute}
						removeSelectedAttribute={removeSelectedAttribute}
						key={selectedAttribute}
					/>
				))
				.toArray(),
		[ruleType, updates, selectedAttributes, setUpdatesAttribute, removeSelectedAttribute]
	);

	const isFilterOptionSelected = useCallback(
		(option: { value: TUpdateAttribute; label: string }) => selectedAttributes.includes(option.value),
		[selectedAttributes]
	);

	return (
		<div className={classNames(classes.updatesSection, className)}>
			<div className={classes.updatesContainer}>
				<div className={classes.titleContainer}>
					<div className={classes.titleText}>
						<Typography variant="body_sb">{t("ruleDefinition.updates.title")}</Typography>
						<Typography variant="body_reg">{t("ruleDefinition.multipleSelectSubtitle")}</Typography>
					</div>
					<Tooltip content={t("ruleDefinition.updates.explanation")}>
						<IconButton size="medium">
							<InfoIcon />
						</IconButton>
					</Tooltip>
				</div>
				{RULE_TYPES.map(currentRuleType => (
					<div key={currentRuleType} className={classes.ruleTypeAttributes}>
						<Typography variant="text_reg">{t(`buildRuleStep.ruleType.${currentRuleType}`)}</Typography>
						<div className={classes.cardSelection}>
							{RULE_UPDATES.get(currentRuleType)!.map(attribute => (
								<BasicCard
									key={attribute}
									onClick={() => toggleAttributeSelection(attribute, currentRuleType)}
									disabled={ruleType && ruleType !== currentRuleType}
									selected={selectedAttributes.includes(attribute) && ruleType === currentRuleType}
									size="medium"
									tooltip={attribute === "maintainers" ? updatesTranslation("maintainers.explanation") : undefined}>
									<IconPrefix
										Icon={getIconByAttribute(attribute)}
										semibold
										size="medium"
										content={
											currentRuleType === "resources"
												? updatesTranslation(`${currentRuleType}.${attribute as keyof TResourceUpdates}`)
												: updatesTranslation(`${currentRuleType}.${attribute as keyof TRoleUpdates}`)
										}
									/>
								</BasicCard>
							))}
						</div>
					</div>
				))}
			</div>
			<div className={classes.setToContainer}>
				<Typography className={classNames({ [classes.disabled]: !enableSetTo })} variant="body_sb">
					{t("ruleDefinition.updates.setToTitle")}
				</Typography>
				{enableSetTo && ruleType ? (
					<FilterSection
						relation="and"
						filterOptions={RULE_UPDATES.get(ruleType)!.map(attribute => ({
							value: attribute,
							label: updatesTranslation(
								ruleType === "resources"
									? `${ruleType}.${attribute as keyof TResourceUpdates}`
									: `${ruleType}.${attribute as keyof TRoleUpdates}`
							)
						}))}
						filters={attributeExpressions}
						isFilterOptionSelected={isFilterOptionSelected}
						onFilterOptionSelected={option => toggleAttributeSelection(option.value, ruleType)}
					/>
				) : null}
			</div>
		</div>
	);
};
