import React, { useCallback, useMemo, useState } from "react";
import { addOnCallIntegration } from "api/companyExternalIntegrations";
import { useTranslation } from "react-i18next";
import { List } from "immutable";
import { useWithGlobalErrorHandler } from "hooks/useWithGlobalErrorHandler";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { ON_CALL_INTEGRATIONS_LOGOS } from "utils/ui/onCall";
import { Button } from "components/ui/Button";
import { IntegrationRow } from "../../../IntegrationRow";
import { useStyles } from "./styles";
import { OnCallIntegrationModal } from "./OnCallIntegrationModal";
import type { TOnCallType } from "models/OnCallIntegrationScheduleModel";

type TOnCallIntegrationProps = {
	afterIntegrate: (integration: TOnCallType) => void;
	disconnect: (integration: TOnCallType) => Promise<void>;
	integration: TOnCallType;
	integratedToOnCalls: List<TOnCallType>;
};

export const OnCallIntegration: FC<TOnCallIntegrationProps> = ({
	afterIntegrate,
	disconnect,
	integration,
	integratedToOnCalls
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const withGlobalErrorHandler = useWithGlobalErrorHandler();
	const isIntegrated = useMemo(() => integratedToOnCalls.includes(integration), [integratedToOnCalls, integration]);
	const [isModalOpen, setModalOpen] = useState(false);

	const openModal = useCallback(() => setModalOpen(true), []);
	const closeModal = useCallback(() => setModalOpen(false), []);

	const Logo = ON_CALL_INTEGRATIONS_LOGOS.get(integration);

	const integrate = useCallback(
		async (apiKey: string) => {
			const integrateHandler = async (apiKey: string) => {
				await addOnCallIntegration(integration, apiKey);
				afterIntegrate(integration);
				closeModal();
			};

			await withGlobalErrorHandler(integrateHandler(apiKey));
		},
		[afterIntegrate, closeModal, integration, withGlobalErrorHandler]
	);

	const disconnectHandler = useCallback(async () => {
		await withGlobalErrorHandler(disconnect(integration));
		closeModal();
	}, [closeModal, disconnect, integration, withGlobalErrorHandler]);

	return (
		<>
			<AreYouSureModal
				isOpen={isModalOpen && isIntegrated}
				onClose={closeModal}
				onAction={disconnectHandler}
				actionLabel={t("pages.settings.integrations.disconnect")}
				content={t("pages.settings.integrations.disconnectModal", { integration })}
			/>
			<OnCallIntegrationModal
				integration={integration}
				logo={Logo && <Logo className={classes.logo} />}
				open={isModalOpen && !isIntegrated}
				integrate={integrate}
				closeModal={closeModal}
			/>
			<IntegrationRow
				isIntegrated={isIntegrated}
				SystemLogo={Logo}
				integration={integration}
				actions={
					<Button variant="text" size="small" onClick={openModal}>
						{t(`pages.settings.integrations.${isIntegrated ? "disconnect" : "connect"}`)}
					</Button>
				}
			/>
		</>
	);
};
