import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Typography } from "components/ui/Typography";
import { Select } from "components/ui/Select";
import { IconPrefix } from "components/ui/IconPrefix";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { RemoveIcon } from "components/ui/Icons/RemoveIcon";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import { type TUserSelectOption, UserSelect } from "components/common/UserSelect";
import { ApprovalAlgorithmSelect } from "components/common/ApprovalAlgorithmSelect";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { ArrowRightIcon } from "components/ui/Icons/ArrowRightIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useBulkActionsContext } from "../../bulkActions.context";
import {
	type TChange,
	type TMaintainersAction,
	MAINTAINER,
	OWNER,
	REQUESTABLE,
	WORKFLOW,
	ADD,
	REMOVE,
	REPLACE,
	AVAILABLE_ROLE_CHANGES,
	AVAILABLE_CHANGES,
	AVAILABLE_ACTIONS,
	type TMaintainerOption
} from "../../utils";
import { useStyles } from "./styles";
import { RequestableSelect } from "./components/RequestableSelect";
import { MaintainersSelect } from "./components/MaintainersSelect";
import type { UserModel } from "models/UserModel";
import type { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import type { TIconProps } from "components/ui/Icon";

const BulkActionSubmitted: FC<{
	action?: TMaintainersAction | null;
	changeValue: string | boolean | null;
	getIcon: (change: TChange | null) => FC<TIconProps> | undefined;
	isBulkActionDone: boolean;
	isBulkActionFailed: boolean;
	selectedAmount: number;
	selectedChange: TChange | null;
}> = React.memo(function BulkActionSubmitted({
	action,
	changeValue,
	isBulkActionFailed,
	className,
	getIcon,
	innerRef,
	isBulkActionDone,
	selectedAmount,
	selectedChange
}) {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.bulkActions" });
	const classes = useStyles();

	const getChangeValueIcon = useCallback(
		(change: TChange | null) => {
			switch (change) {
				case MAINTAINER:
					return undefined;
				case OWNER:
					return UserCircleIcon;
				case REQUESTABLE:
					return changeValue ? GrantedIcon : CloseIcon;
				case WORKFLOW:
					return WorkflowsIcon;
				default:
					return undefined;
			}
		},
		[changeValue]
	);

	const changeValueText = useMemo(() => {
		if (changeValue === null) return "";
		if (typeof changeValue === "boolean") return changeValue ? t("inProgress.canRequest") : t("inProgress.cantRequest");
		return changeValue;
	}, [changeValue, t]);

	const changingText = useMemo(() => {
		if (selectedChange === MAINTAINER && action) {
			return t(`inProgress.${action}`, { count: selectedAmount });
		}
		return t(`inProgress.changing`, { count: selectedAmount });
	}, [action, selectedAmount, selectedChange, t]);

	const content = useMemo(() => {
		if (isBulkActionDone) {
			return (
				<div className={classes.bulkActionLoader}>
					<Typography variant="title_sb">{t("inProgress.isDone")}</Typography>
					<GrantedIcon size={44} className={classes.greenIcon} />
				</div>
			);
		}

		if (isBulkActionFailed) {
			return (
				<div className={classes.bulkActionLoader}>
					<Typography variant="title_sb">{t("inProgress.isFailed")}</Typography>
					<CloseIcon size={44} className={classes.redIcon} />
				</div>
			);
		}

		return null;
	}, [classes, isBulkActionDone, isBulkActionFailed, t]);

	return (
		<div className={classNames(classes.wrapper, className)} ref={innerRef}>
			<div
				className={classNames(classes.bulkActionInProgress, {
					[classes.center]: isBulkActionDone || !selectedChange || isBulkActionFailed
				})}>
				{content ? (
					content
				) : (
					<>
						<div className={classes.bulkActionLoader}>
							<LoadingSpinner size={44} />
							<Typography variant="title_sb">{t("inProgress.inProgress")}</Typography>
						</div>
						{selectedChange ? (
							<div className={classes.bulkActionChange}>
								<Typography variant="text_reg">{changingText}</Typography>
								<IconPrefix
									Icon={getIcon(selectedChange)}
									content={selectedChange ? t(`inProgress.${selectedChange}` as const) : ""}
								/>
							</div>
						) : null}
						{changeValue !== null ? (
							<>
								<ArrowRightIcon size={24} />
								<div className={classes.bulkActionChange}>
									<Typography variant="text_reg">{t("inProgress.to")}</Typography>
									<IconPrefix Icon={getChangeValueIcon(selectedChange)} content={changeValueText} />
								</div>
							</>
						) : null}
					</>
				)}
			</div>
		</div>
	);
});

export const BulkActions: FC = ({ className, innerRef }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.bulkActions" });
	const { t: entitiesTranslation } = useTranslation("translation", { keyPrefix: "entities.plural" });
	const classes = useStyles();
	const [selectedChange, setSelectedChange] = useState<TChange | null>(null);
	const [selectedAction, setSelectedAction] = useState<TMaintainersAction | null>(null);
	const [selectedWorkflowValue, setSelectedWorkflowValue] = useState<ApprovalAlgorithmModel | null>(null);
	const [selectedRequestableValue, setSelectedRequestableValue] = useState<boolean | null>(null);
	const [selectedMaintainerValue, setSelectedMaintainerValue] = useState<TMaintainerOption[] | null>(null);
	const [selectedOwnerValue, setSelectedOwnerValue] = useState<UserModel | null>(null);

	const {
		state: { currentSelection, totalAmount, selectedTab, isBulkActionDone, isBulkActionInProgress, isBulkActionFailed },
		actions: { setIsBulkActionSelected, onBulkAction, addOnDoneCallback }
	} = useBulkActionsContext();

	const reset = useCallback(() => {
		setSelectedChange(null);
		setSelectedAction(null);
		setSelectedWorkflowValue(null);
		setSelectedRequestableValue(null);
		setSelectedMaintainerValue(null);
		setSelectedOwnerValue(null);
		setIsBulkActionSelected(false);
	}, [setIsBulkActionSelected]);

	useEffect(() => {
		addOnDoneCallback("bulkActionSectionReset", reset);
	}, [addOnDoneCallback, reset]);

	const selectedAmount = useMemo(() => {
		return currentSelection.getSelectedAmount(totalAmount);
	}, [currentSelection, totalAmount]);

	const isValid = useMemo(() => {
		if (!selectedChange || !currentSelection.anySelected) return false;
		switch (selectedChange) {
			case MAINTAINER:
				return !!selectedMaintainerValue?.length;
			case OWNER:
				return !!selectedOwnerValue;
			case REQUESTABLE:
				return selectedRequestableValue !== null;
			case WORKFLOW:
				return !!selectedWorkflowValue;
			default:
				return false;
		}
	}, [
		currentSelection.anySelected,
		selectedChange,
		selectedMaintainerValue?.length,
		selectedOwnerValue,
		selectedRequestableValue,
		selectedWorkflowValue
	]);

	const modelTranslation = useMemo(() => {
		if (selectedTab === "integration") return entitiesTranslation("Integration");
		if (selectedTab === "resource") return entitiesTranslation("IntegrationResource");
		if (selectedTab === "role") return entitiesTranslation("IntegrationResourceRole");
		return "";
	}, [selectedTab, entitiesTranslation]);

	const getChangeIcon = useCallback((change: TChange | null) => {
		switch (change) {
			case MAINTAINER:
				return MaintainersIcon;
			case OWNER:
				return OwnerIcon;
			case REQUESTABLE:
				return RequestsIcon;
			case WORKFLOW:
				return WorkflowsIcon;
			default:
				return undefined;
		}
	}, []);

	const onUserChange = useCallback((user: TUserSelectOption) => {
		if (typeof user === "string") return;
		setSelectedOwnerValue(user);
	}, []);

	const onChangeSelectedChange = useCallback(
		(value: TChange | null) => {
			setIsBulkActionSelected(!!value);
			let didChange = false;
			setSelectedChange(current => {
				if (current !== value) {
					didChange = true;
				}
				return value;
			});
			if (didChange) {
				setSelectedAction(value === MAINTAINER ? ADD : null);
				setSelectedWorkflowValue(null);
				setSelectedRequestableValue(null);
				setSelectedMaintainerValue([]);
				setSelectedOwnerValue(null);
			}
		},
		[setIsBulkActionSelected]
	);

	const onSubmit = useCallback(async () => {
		if (!selectedChange || !isValid) return;
		let bulkActionOptions;
		switch (selectedChange) {
			case MAINTAINER:
				bulkActionOptions =
					selectedMaintainerValue?.length && selectedAction
						? {
								change: selectedChange,
								action: selectedAction,
								value: selectedMaintainerValue
							}
						: undefined;
				break;
			case OWNER:
				bulkActionOptions = selectedOwnerValue
					? {
							change: selectedChange,
							value: selectedOwnerValue
						}
					: undefined;
				break;
			case REQUESTABLE:
				bulkActionOptions =
					typeof selectedRequestableValue === "boolean"
						? {
								change: selectedChange,
								value: selectedRequestableValue
							}
						: undefined;
				break;
			case WORKFLOW:
				bulkActionOptions = selectedWorkflowValue
					? {
							change: selectedChange,
							value: selectedWorkflowValue
						}
					: undefined;
				break;
			default:
				break;
		}
		if (!bulkActionOptions) return;
		await onBulkAction(bulkActionOptions);
	}, [
		isValid,
		onBulkAction,
		selectedAction,
		selectedChange,
		selectedMaintainerValue,
		selectedOwnerValue,
		selectedRequestableValue,
		selectedWorkflowValue
	]);

	useEffect(() => {
		onChangeSelectedChange(null);
	}, [onChangeSelectedChange, selectedTab]);

	const getChangeOptionLabel = useCallback((option: TChange) => t(`inputs.changeSelected.${option}` as const), [t]);

	const renderChangeOption = useCallback(
		(option: TChange) => {
			return <IconPrefix size="small" Icon={getChangeIcon(option)} content={getChangeOptionLabel(option)} />;
		},
		[getChangeOptionLabel, getChangeIcon]
	);

	const getActionOptionLabel = useCallback((option: TMaintainersAction) => t(`inputs.withThisAction.${option}`), [t]);

	const renderActionOption = useCallback(
		(option: TMaintainersAction) => {
			let Icon = undefined;
			switch (option) {
				case ADD:
					Icon = AddIcon;
					break;
				case REMOVE:
					Icon = RemoveIcon;
					break;
				case REPLACE:
					Icon = RefreshIcon;
					break;
				default:
					break;
			}
			return <IconPrefix size="small" Icon={Icon} content={getActionOptionLabel(option)} />;
		},
		[getActionOptionLabel]
	);

	const changeValueText = useMemo(() => {
		switch (selectedChange) {
			case MAINTAINER:
				return null;
			case OWNER:
				return selectedOwnerValue?.fullName || null;
			case REQUESTABLE:
				if (selectedRequestableValue === null) return null;
				return selectedRequestableValue;
			case WORKFLOW:
				return selectedWorkflowValue?.name || null;
			default:
				return null;
		}
	}, [selectedChange, selectedWorkflowValue, selectedRequestableValue, selectedOwnerValue]);

	const toValueSelect = useMemo(() => {
		const label = t("inputs.toThis.value");
		const placeholder = t("inputs.toThis.placeholder", { context: selectedChange || "null" });
		switch (selectedChange) {
			case MAINTAINER:
				return (
					<MaintainersSelect
						value={selectedMaintainerValue}
						onChange={setSelectedMaintainerValue}
						withDeleted={selectedAction === "remove"}
					/>
				);
			case OWNER:
				return (
					<UserSelect value={selectedOwnerValue} onChange={onUserChange} label={label} placeholder={placeholder} />
				);
			case REQUESTABLE:
				return <RequestableSelect value={selectedRequestableValue} onChange={setSelectedRequestableValue} />;
			case WORKFLOW:
				return (
					<ApprovalAlgorithmSelect
						value={selectedWorkflowValue}
						onChange={setSelectedWorkflowValue}
						label={label}
						placeholder={placeholder}
					/>
				);
			default:
				return <Select disabled options={[]} label={label} placeholder={placeholder} />;
		}
	}, [
		onUserChange,
		selectedAction,
		selectedChange,
		selectedMaintainerValue,
		selectedOwnerValue,
		selectedRequestableValue,
		selectedWorkflowValue,
		t
	]);

	useEffect(() => {
		if (!selectedAmount) {
			reset();
		}
	}, [reset, selectedAmount]);

	if (isBulkActionInProgress || isBulkActionDone || isBulkActionFailed) {
		return (
			<BulkActionSubmitted
				action={selectedAction}
				changeValue={changeValueText}
				isBulkActionFailed={isBulkActionFailed}
				className={className}
				getIcon={getChangeIcon}
				innerRef={innerRef}
				isBulkActionDone={isBulkActionDone}
				selectedAmount={selectedAmount}
				selectedChange={selectedChange}
			/>
		);
	}

	return (
		<div className={classNames(classes.wrapper, className)} ref={innerRef}>
			<div className={classes.container}>
				<div className={classes.inputs}>
					<div className={classes.inputPart}>
						<Typography variant="body_sb">{t("inputs.changeSelected.label")}</Typography>
						<Select
							disabled={selectedAmount === 0}
							getOptionLabel={getChangeOptionLabel}
							label={t("inputs.changeSelected.attribute")}
							onChange={onChangeSelectedChange}
							options={selectedTab === "role" ? AVAILABLE_ROLE_CHANGES : AVAILABLE_CHANGES}
							placeholder={t("inputs.changeSelected.placeholder")}
							renderLabel={renderChangeOption}
							renderOption={renderChangeOption}
							value={selectedChange}
						/>
					</div>
					{selectedChange === "maintainer" ? (
						<div className={classes.inputPart}>
							<Typography variant="body_sb">{t("inputs.withThisAction.label")}</Typography>
							<Select
								getOptionLabel={getActionOptionLabel}
								hideClear
								label={t("inputs.withThisAction.action")}
								onChange={setSelectedAction}
								options={AVAILABLE_ACTIONS}
								placeholder={t("inputs.withThisAction.placeholder")}
								renderLabel={renderActionOption}
								renderOption={renderActionOption}
								value={selectedAction}
							/>
						</div>
					) : null}
					<div className={classes.inputPart}>
						<Typography variant="body_sb">{t("inputs.toThis.label")}</Typography>
						{toValueSelect}
					</div>
				</div>
				<div className={classes.actions}>
					<Button size="medium" disabled={!isValid} onClick={onSubmit}>
						{t("applyChanges", { count: selectedAmount, model: modelTranslation })}
					</Button>
				</div>
			</div>
		</div>
	);
};
