import { apiReq } from "utils/api/apiReq";
import { List } from "immutable";
import { AccessReviewTemplateModel, TFullTAccessReviewTemplateModel } from "models/AccessReviewTemplateModel";

export const getAccessReviewTemplates = async (): Promise<List<AccessReviewTemplateModel>> => {
	const { data } = await apiReq("GET", "/v1/accessReviewTemplates");

	return List<AccessReviewTemplateModel>(data.map(AccessReviewTemplateModel.fromServerData));
};

export const createAccessReviewTemplate = async (
	template: AccessReviewTemplateModel
): Promise<TFullTAccessReviewTemplateModel> => {
	const { data: accessReviewTemplate } = await apiReq("POST", "/v1/accessReviewTemplates", template);

	return AccessReviewTemplateModel.fromServerData(accessReviewTemplate) as TFullTAccessReviewTemplateModel;
};

export const editAccessReviewTemplate = async (
	id: string,
	template: Partial<AccessReviewTemplateModel>
): Promise<TFullTAccessReviewTemplateModel> => {
	const { data: accessReviewTemplate } = await apiReq("PUT", `/v1/accessReviewTemplates/${id}`, template);

	return AccessReviewTemplateModel.fromServerData(accessReviewTemplate) as TFullTAccessReviewTemplateModel;
};

export const getAccessReviewTemplate = async (id: string): Promise<TFullTAccessReviewTemplateModel> => {
	const { data: accessReviewTemplate } = await apiReq("GET", `/v1/accessReviewTemplates/${id}`);

	return AccessReviewTemplateModel.fromServerData(accessReviewTemplate) as TFullTAccessReviewTemplateModel;
};

export const deleteAccessReviewTemplate = async (id: string) => {
	return apiReq("DELETE", `/v1/accessReviewTemplates/${id}`);
};
