import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PageTemplate } from "components/templates/PageTemplate";
import { PolicyModel } from "models/PolicyModel";
import { Button } from "components/ui/Button";
import { Table } from "components/ui/Table";
import { useTranslation } from "react-i18next";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { usePoliciesContext } from "context/policiesContext";
import { LoadingDots } from "components/ui/LoadingDots";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { PolicyConflictsModel } from "models/PolicyConflictsModel";
import { useIsOpenState } from "hooks/useIsOpenState";
import useErrorModalState from "hooks/useErrorModalState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { ErrorModal } from "components/ui/ErrorModal";
import { EditPolicyModal } from "./components/EditPolicyModal";
import { PolicyRow } from "./components/PolicyRow";
import { useStyles } from "./styles";
import { PolicyConflictsModal } from "./components/PolicyConflictsModal";
import type { ICreatePolicyParams } from "api/policies";

export const PoliciesPage: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [policyToUpdate, setPolicyToUpdate] = useState<PolicyModel | null>(null);
	const [policyConflicts, setPolicyConflicts] = useState<PolicyConflictsModel | null>(null);
	const {
		state: { policies, lastSortOrder },
		actions: { loadPolicies, movePolicy, deletePolicy, createPolicy, updatePolicy }
	} = usePoliciesContext();
	const openErrorModal = useOpenGlobalErrorModal();
	const { isOpen: areYouSureModalIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();
	const {
		errorModalSetError,
		errorModalIsOpen,
		errorModalError,
		errorModalClose: closeErrorModal
	} = useErrorModalState();

	const policiesList = useMemo(
		() => Array.from(policies?.values() || []).sort((a, b) => a.sortOrder - b.sortOrder),
		[policies]
	);
	const [idToRemove, setIdToRemove] = useState<string | null>(null);

	const onDeletePolicyClick = useCallback(
		(policyId: string) => {
			setIdToRemove(policyId);
			openAreYouSureModal();
		},
		[openAreYouSureModal]
	);

	const removePolicy = useCallback(async () => {
		if (!idToRemove) return;
		try {
			await deletePolicy(idToRemove);
		} catch (err) {
			errorModalSetError(err as Error);
		} finally {
			closeAreYouSureModal();
		}
	}, [idToRemove, deletePolicy, errorModalSetError, closeAreYouSureModal]);

	const openNewPolicyModal = useCallback(() => {
		setPolicyToUpdate(new PolicyModel({ sortOrder: lastSortOrder + 1 }));
	}, [lastSortOrder]);

	const openEditPolicyModal = useCallback((policy: PolicyModel) => {
		setPolicyToUpdate(policy);
	}, []);

	const closeEditPolicyModal = useCallback(() => {
		setPolicyToUpdate(null);
	}, []);
	const closePolicyConflictsModal = useCallback(() => {
		setPolicyConflicts(null);
	}, []);

	const movePolicyUp = useCallback(
		async (policy: PolicyModel) => (policy.sortOrder > 1 ? await movePolicy(policy.id, -1) : undefined),
		[movePolicy]
	);

	const movePolicyDown = useCallback(
		async (policy: PolicyModel) => (policy.sortOrder < lastSortOrder ? await movePolicy(policy.id, 1) : undefined),
		[lastSortOrder, movePolicy]
	);

	useEffect(() => {
		loadPolicies();
	}, [loadPolicies]);

	const savePolicy = useCallback(
		async (body: ICreatePolicyParams) => {
			if (!policyToUpdate) return;
			if (!body.directoryGroupIds.length && !body.onCallIntegrationScheduleIds.length) {
				openErrorModal(new Error(t("pages.policies.form.errors.noDirectoryGroups")));
				return;
			}
			if (body.integrationResourceRoleIds.length + body.bundleIds.length === 0) {
				openErrorModal(new Error(t("pages.policies.form.errors.noPermissions")));
				return;
			}
			const policy = await (policyToUpdate.id ? updatePolicy(policyToUpdate.id, body) : createPolicy(body));
			if (policy?.conflicts) {
				closeEditPolicyModal();
				setPolicyConflicts(policy.conflicts);
			}
		},
		[policyToUpdate, updatePolicy, createPolicy, openErrorModal, t, closeEditPolicyModal]
	);

	return (
		<PageTemplate>
			<PageTemplate.Title className={classes.title}>
				{t("pages.policies.title")}
				<Button size="medium" onClick={openNewPolicyModal} prefix={<AddIcon />}>
					{t("pages.policies.addPolicy")}
				</Button>
			</PageTemplate.Title>
			<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />
			<EditPolicyModal
				isOpen={!!policyToUpdate}
				policy={policyToUpdate}
				closeModal={closeEditPolicyModal}
				savePolicy={savePolicy}
			/>
			<PolicyConflictsModal
				isOpen={!!policyConflicts}
				conflicts={policyConflicts}
				closeModal={closePolicyConflictsModal}
			/>
			<AreYouSureModal
				onClose={closeAreYouSureModal}
				isOpen={areYouSureModalIsOpen}
				onAction={removePolicy}
				actionLabel={t("pages.policies.deletePolicy.deletePolicy")}
				title={t("pages.policies.deletePolicy.title")}
				content={t("pages.policies.deletePolicy.content")}
			/>
			<PageTemplate.Content>
				{policies ? (
					<Table gridColumns="4rem 4.5rem 4fr 5fr 5fr 10rem" className={classes.table}>
						<Table.Row>
							<Table.Header />
							<Table.Header>{t("pages.policies.table.headers.number")}</Table.Header>
							<Table.Header>{t("pages.policies.table.headers.group")}</Table.Header>
							<Table.Header>{t("pages.policies.table.headers.bundles")}</Table.Header>
							<Table.Header>{t("pages.policies.table.headers.integrationResourceRoles")}</Table.Header>
							<Table.Header />
						</Table.Row>
						{policiesList.map(policy => (
							<PolicyRow
								key={policy.id}
								policy={policy}
								deletePolicy={onDeletePolicyClick}
								movePolicyUp={policy.sortOrder > 1 ? movePolicyUp : undefined}
								movePolicyDown={policy.sortOrder < lastSortOrder ? movePolicyDown : undefined}
								openEditPolicyModal={openEditPolicyModal}
							/>
						))}
					</Table>
				) : (
					<LoadingDots center />
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
