import React from "react";
import { BundleModel } from "models/BundleModel";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { Tooltip } from "components/ui/Tooltip";
import { TitleBody } from "components/ui/TitleBody";
import { useTranslation } from "react-i18next";
import { Chip, TChipVariant } from "../Chip";

interface IBundleChipProps {
	bundle: BundleModel;
	readonly?: boolean;
	variant?: TChipVariant;
	selected?: boolean;
	onDelete?: () => void;
}

export const BundleChip: FC<IBundleChipProps> = ({
	innerRef,
	className,
	variant = "regular",
	readonly = false,
	bundle,
	selected = false,
	onDelete
}) => {
	const { t } = useTranslation();

	return (
		<Tooltip
			content={
				<div>
					<TitleBody size="small" title={t("common.roleBar.roleBarBundle.bundleName")} body={bundle.name} />
					{bundle.description ? (
						<TitleBody
							size="small"
							title={t("common.roleBar.roleBarBundle.bundleDescription")}
							body={bundle.description}
						/>
					) : null}
				</div>
			}>
			<Chip
				noTooltip
				readonly={readonly}
				variant={variant}
				size="medium"
				PrefixIcon={BundleIcon}
				onDelete={onDelete}
				selected={selected}
				className={className}
				innerRef={innerRef}>
				{bundle.name}
			</Chip>
		</Tooltip>
	);
};
