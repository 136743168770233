import React, { useCallback, useMemo, useState } from "react";
import { PageTemplate } from "components/templates/PageTemplate";
import { useTranslation } from "react-i18next";
import { Block } from "components/ui/Block";
import { Typography } from "components/ui/legacy/Typography";
import { UserModel } from "models/UserModel";
import { UserWithEmail } from "components/common/UserWithEmail";
import { Input } from "components/ui/Input";
import { useNavigate } from "react-router-dom";
import { useUsersSelect } from "hooks/useUsersSelect";
import { PaginatedVirtualTable } from "components/ui/VirtualTable";
import { HeaderCellContent } from "components/ui/VirtualTable/components";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { useStyles } from "./styles";
import type { TVirtualTableWidth } from "components/ui/VirtualTable/types";

const PER_PAGE = 50;

export const UsersPage: FC = ({ className }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [query, setQuery] = useState("");
	const {
		itemsForVirtualTable: users,
		isLoading,
		totalResults,
		getPage
	} = useUsersSelect(query, { perPage: PER_PAGE });
	const userList = useMemo(() => users || [], [users]);

	const columns = useMemo(
		() => [
			{
				header: <HeaderCellContent text={t("pages.users.columns.name")} icon={<UserCircleIcon />} />,
				key: "email",
				renderCell: (row: UserModel) => (
					<div className={classes.cell}>
						<UserWithEmail user={row} />
					</div>
				),
				width: "1fr" as TVirtualTableWidth
			},
			{
				header: <HeaderCellContent text={t("pages.users.columns.role")} icon={<RoleIcon />} />,
				key: "role",
				renderCell: (row: UserModel) => (
					<div className={classes.cell}>
						<Typography variant="small">{t(`shared.roles.${row.role}`)}</Typography>
					</div>
				),
				width: "1fr" as TVirtualTableWidth
			}
		],
		[classes.cell, t]
	);

	const fetchNextPage = useCallback(
		(page: number) => {
			getPage(page, true);
		},
		[getPage]
	);

	const onClick = useCallback(
		(data: UserModel) => {
			navigate(`/users/${data.id}`);
		},
		[navigate]
	);

	return (
		<PageTemplate className={className}>
			<PageTemplate.Title>{t("pages.users.title")}</PageTemplate.Title>
			<PageTemplate.Content>
				<Block className={classes.container}>
					<Input
						className={classes.input}
						placeholder={t("pages.users.search")}
						onValueChange={setQuery}
						value={query}
						variant="search"
					/>
					<div className={classes.usersTableContainer}>
						<PaginatedVirtualTable
							isLoading={isLoading}
							className={classes.table}
							rows={userList}
							totalRows={totalResults}
							columns={columns}
							perPage={PER_PAGE}
							fetchPage={fetchNextPage}
							onRowClicked={onClick}
							selectableTrigger="regular"
							selectable
						/>
					</div>
				</Block>
			</PageTemplate.Content>
		</PageTemplate>
	);
};
