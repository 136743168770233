import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { UserCard } from "components/common/UserCard";
import { useStyles } from "./styles";
import type { TAdminResponse } from "components/common/RequestDetails/types";

const THEME_COLOR = "var(--color-black)";

interface IProps {
	adminResponse: TAdminResponse;
}

export const AdminStep: FC<IProps> = ({ adminResponse }) => {
	const classes = useStyles({ color: THEME_COLOR });
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails.approvalProcess.step" });

	return (
		<div className={classes.stepContainer}>
			<div className={classes.stepTitleContainer}>
				<div className={classes.stepTitle}>
					<Typography variant="body_sb" className={classes.stepTitleText}>
						{t(adminResponse.type)}
					</Typography>
				</div>
			</div>
			<div className={classes.stepEntitiesGroupsContainer}>
				<UserCard
					user={adminResponse.userId!}
					color={adminResponse.type === "adminDecline" ? "red" : "green"}
					selected
				/>
			</div>
		</div>
	);
};
