import React, { useMemo } from "react";
import classNames from "classnames";
import { sourceIcons, TSources } from "utils/ui/sourceIcons";
import { Entity } from "components/common/Entity";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "./styles";

const sourceRegex = new RegExp(/\[([A-z]+)\] (.*)/);

interface IProps {
	icon?: JSX.Element;
	iconClassName?: string;
	value: string;
	isDeleted?: boolean;
}

export const DirectoryGroup: FC<IProps> = ({ icon = null, iconClassName, isDeleted = false, value }) => {
	const classes = useStyles();

	const { name, Icon } = useMemo(() => {
		const match = sourceRegex.exec(value);
		if (!match) {
			return {
				name: value,
				icon: null
			};
		}

		const iconString = match[1] as TSources;
		const name = match[2];
		const Icon = requirePropertyOf(sourceIcons, iconString);

		return {
			name,
			Icon
		};
	}, [value]);

	return (
		<div className={classes.sourceIconContainer}>
			<Entity
				content={name}
				isDeleted={isDeleted}
				prefixIcon={icon || (Icon ? <Icon className={classNames(classes.sourceIcon, iconClassName)} /> : undefined)}
			/>
		</div>
	);
};
