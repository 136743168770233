import { Map, Set, List } from "immutable";
import { useCallback, useRef, useState } from "react";
import { useThrottleFn } from "hooks/useThrottle";
import { useOpenGlobalErrorModal } from "./useGlobalError";

type TBulkFetchOptions = { throttleTime?: number; includeDeleted?: boolean };
type TFetchingState = "Initial" | "Loading" | "Loaded" | "Error";

export const useThrottledBulkFetch = <T extends { id: string }>(
	fetchFn: (ids: string[], options?: TBulkFetchOptions) => Promise<List<T>>,
	options: TBulkFetchOptions = {}
) => {
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const { throttleTime = 50 } = options;
	const [itemsById, setItemsById] = useState(Map<string, T | null>());
	const itemsState = useRef(Map<string, TFetchingState>());

	const fetchBulkData = useCallback(async () => {
		const ids = Set(itemsState.current.filter(state => state === "Initial").keySeq());
		try {
			itemsState.current = itemsState.current.merge(ids.map(id => [id, "Loading"]));
			const newItems = await fetchFn(ids.toArray(), options);
			const newItemsIds = Set(newItems.map(item => item.id).valueSeq());
			const missingIds = ids.filter(id => !newItemsIds.has(id));
			itemsState.current = itemsState.current.merge(ids.map(id => [id, "Loaded"]));
			setItemsById(curr =>
				curr.merge(
					newItems.map(item => [item.id, item]).concat(missingIds.map(id => [id, null])) as List<[string, T | null]>
				)
			);
		} catch (e) {
			itemsState.current = itemsState.current.merge(ids.map(id => [id, "Error"]));
			openGlobalErrorModal(new Error("Failed to fetch bulk data: " + (e as Error).message));
		}
	}, [fetchFn, openGlobalErrorModal, options]);

	const [throttledFetchBulkData] = useThrottleFn(fetchBulkData, throttleTime, { leading: false });

	const loadIds = useCallback(
		(ids: string[]) => {
			const missingIds = Set(ids.filter(id => !id || !itemsState.current.has(id)));
			if (!missingIds.size) return;
			itemsState.current = itemsState.current.merge(missingIds.map(id => [id, "Initial"]));
			throttledFetchBulkData();
		},
		[throttledFetchBulkData]
	);

	return { itemsById, setItemsById, loadIds };
};
