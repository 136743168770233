import React, { memo, useMemo } from "react";
import classNames from "classnames";
import { List } from "immutable";
import { Modal } from "components/ui/Modal";
import { Typography } from "components/ui/Typography";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { notEmpty } from "utils/comparison";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

const ConnectedWorkflowsModalComponent: FC<{ workflowIds?: List<string>; closeModal: () => void }> = ({
	workflowIds,
	closeModal
}) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "pages.workflowsWebhooks.connectedWorkflowsModal" });
	const approvalAlgorithms = useApprovalAlgorithms();
	const open = Boolean(workflowIds?.size);

	const algorithmNames = useMemo(() => {
		if (!workflowIds?.size || !approvalAlgorithms) return null;
		return workflowIds.map(workflowId => approvalAlgorithms.get(workflowId)?.name).filter(notEmpty);
	}, [approvalAlgorithms, workflowIds]);

	const content = useMemo(() => {
		return (
			<div className={classes.contentContainer}>
				<Typography className={classes.title} variant="title_sb">
					{t("title")}
				</Typography>
				<Typography className={classes.subTitle} variant="text_sm_reg">
					{t("subTitle")}
				</Typography>
				{approvalAlgorithms ? (
					<div className={classes.workflowList}>
						{algorithmNames?.map((name, index) => (
							<div key={index} className={classes.workflowItem}>
								<WorkflowsIcon size={24} />
								<Typography variant="body_reg" className={classes.workflowItemText}>
									{name}
								</Typography>
							</div>
						))}
					</div>
				) : (
					<LoadingSpinner />
				)}
			</div>
		);
	}, [classes, t, approvalAlgorithms, algorithmNames]);

	return (
		<Modal
			className={classNames(classes.modal, { [classes.loading]: open && !approvalAlgorithms })}
			isOpen={open}
			onClose={closeModal}
			content={content}
		/>
	);
};

export const ConnectedWorkflowsModal = memo(ConnectedWorkflowsModalComponent);
