import React from "react";
import { useTranslation } from "react-i18next";
import { DoneAllIcon } from "components/ui/Icons/DoneAllIcon";
import { Typography } from "components/ui/legacy/Typography";
import { NotificationIcon } from "components/ui/Icons/NotificationIcon";
import { AnyOrAllToggle } from "../AnyOrAllToggle/AnyOrAllToggle";
import { ApprovalAlgorithmEntity } from "../ApprovalAlgorithmEntity";
import { FlowBlock } from "../FlowBlock";
import { useStyles } from "./styles";
import type { IEntity } from "types/entity";

interface IProps {
	approverEntities: IEntity[];
	notifiedEntities?: IEntity[];
	operator: "and" | "or";
}

export const ShouldBeApproved: FC<IProps> = ({ approverEntities, notifiedEntities, className, operator }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const label = (
		<div className={classes.title}>
			<span>{t("flow.shouldBeApproved")}</span>
			<AnyOrAllToggle operator={operator} disabled />
		</div>
	);
	return (
		<FlowBlock label={label} className={className}>
			<div className={classes.entitiesContainer}>
				<DoneAllIcon />
				<Typography className={classes.labelContainer}>{t("flow.by")}</Typography>
				{approverEntities && <ApprovalAlgorithmEntity value={approverEntities} />}
			</div>
			{notifiedEntities && notifiedEntities.length ? (
				<div className={classes.entitiesContainer}>
					<NotificationIcon />
					<Typography className={classes.labelContainer}>{t("flow.notifyOnApprove")}</Typography>
					<ApprovalAlgorithmEntity value={notifiedEntities} />
				</div>
			) : null}
		</FlowBlock>
	);
};
