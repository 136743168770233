import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter, TFilterOperator } from "types/filters";

export const OPERATORS = ["is", "isNot"] as const satisfies TFilterOperator[];
const filterName = "integrationId" as const;

const defaults = {
	name: filterName,
	value: [] as string[],
	operator: "is" as (typeof OPERATORS)[number]
};

type TDefaults = typeof defaults;

export class IntegrationIdFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationIdFilter | null {
		const integrationId = uniq(urlSearchParams.getAll("integrationId"));
		const integrationIdOperator = urlSearchParams.get("integrationIdOperator") || undefined;
		if (integrationId.length === 0 || !validateFilterEqualityOperator(integrationIdOperator)) {
			return null;
		}
		return new IntegrationIdFilter({
			value: integrationId,
			operator: integrationIdOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationId", value);
		}
		params.append("integrationIdOperator", this.operator);
		return params;
	}
}

export type TIntegrationIdFilter = Omit<TDefaults, "name">;
