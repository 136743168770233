import constate from "constate";
import { List, Map } from "immutable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAccessReviewPermissions } from "hooks/useBulkUpdateAccessReviewsPermissions";
import { TAccessReviewPermissionModel, TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";
import { AccessReviewReportModel } from "models/AccessReviewReportModel";
import { STATUS_DICT, TPermissionStatusOptions } from "utils/accessReview";
import { useAccessReviewResource } from "hooks/useAccessReviewResource";
import { TAccessReviewPermissionType } from "api/accessReviewPermissions";
import { bulkComment } from "api/accessReviewPermissionComments";
import { AccessReviewPermissionCommentModel } from "models/AccessReviewPermissionCommentModel";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";

type TCheckboxStateMap = Map<string, boolean>;

export type TResourcePermission = TAccessReviewPermissionModel & {
	status: TAccessReviewPermissionStatus;
	resourcePermissionId: string;
};

const useResourcePermissionReview = ({
	resourceId,
	report,
	immediateRevoke
}: {
	resourceId: string;
	report: AccessReviewReportModel;
	immediateRevoke: boolean;
}) => {
	const [checkboxesState, setCheckboxesState] = useState<TCheckboxStateMap>(Map());
	const {
		bulkApproveAccessReviewPermissions,
		bulkDenyAccessReviewPermissions,
		bulkFlagAccessReviewPermissions,
		isLoading
	} = useAccessReviewPermissions();

	const { accessReviewResource, updateResource } = useAccessReviewResource(resourceId);

	const { user } = useAuthenticatedUser();

	const resourcePermissions: Map<string, TResourcePermission> | undefined = useMemo(() => {
		if (!accessReviewResource || !accessReviewResource.accessReviewResourcePermissions) return undefined;

		return accessReviewResource.accessReviewResourcePermissions.reduce(
			(acc, permission) =>
				acc.set(permission.id, {
					status: permission.status,
					resourcePermissionId: permission.id,
					...permission.accessReviewPermission!.toObject(),
					roleName: permission.roleName,
					resourceName: permission.resourceName
				}),
			Map<string, TResourcePermission>()
		);
	}, [accessReviewResource]);

	const selectedPermissions = useMemo(() => checkboxesState.filter(Boolean).keySeq().toArray(), [checkboxesState]);

	const resetCheckboxesState = useCallback(() => {
		if (resourcePermissions) {
			const newCheckboxState = resourcePermissions.reduce((acc, permission) => {
				{
					if (!(permission.status === "denied" && immediateRevoke))
						return acc.set(permission.resourcePermissionId, false);
					return acc;
				}
			}, Map<string, boolean>());
			setCheckboxesState(newCheckboxState);
		} else {
			setCheckboxesState(Map());
		}
	}, [immediateRevoke, resourcePermissions]);

	const handlePermissionCheckboxChange = useCallback((permissionId: string, checked: boolean) => {
		setCheckboxesState(current => current.set(permissionId, checked));
	}, []);

	const handleSelectAllCheckboxChange = useCallback((checked: boolean) => {
		setCheckboxesState(currentState => currentState.map(() => checked));
	}, []);

	const removePermissionCheckbox = useCallback((permissionId: string) => {
		setCheckboxesState(current => current.delete(permissionId));
	}, []);

	const getStatusAction = useCallback(
		(
			status: Exclude<TPermissionStatusOptions, "pending">
		): ((permissionIds: string[], type: TAccessReviewPermissionType) => Promise<unknown>) => {
			if (status === "approve") {
				return bulkApproveAccessReviewPermissions;
			}
			if (status === "deny") {
				return bulkDenyAccessReviewPermissions;
			}
			if (status === "flag") {
				return bulkFlagAccessReviewPermissions;
			}
			throw new Error("Invalid status: " + status);
		},
		[bulkApproveAccessReviewPermissions, bulkDenyAccessReviewPermissions, bulkFlagAccessReviewPermissions]
	);

	const updatePermissions = useCallback(
		(status: TPermissionStatusOptions, permissionIdsToChange: string[]): void => {
			const newPermissions = accessReviewResource?.accessReviewResourcePermissions?.map(permission => {
				if (permissionIdsToChange.includes(permission.id) && STATUS_DICT.has(status)) {
					return permission.set("status", STATUS_DICT.get(status)!);
				}
				return permission;
			});
			updateResource({
				accessReviewResourcePermissions: newPermissions
			});
		},
		[accessReviewResource, updateResource]
	);

	const setSelected = useCallback(
		async (status: Exclude<TPermissionStatusOptions, "pending">): Promise<void> => {
			if (selectedPermissions.length > 0) {
				await getStatusAction(status)(selectedPermissions, "resource");
				updatePermissions(status, selectedPermissions);
			}
		},
		[selectedPermissions, getStatusAction, updatePermissions]
	);

	const setSinglePermission = useCallback(
		async (permissionId: string, status: Exclude<TPermissionStatusOptions, "pending">): Promise<void> => {
			if (permissionId) {
				await getStatusAction(status)([permissionId], "resource");
				updatePermissions(status, [permissionId]);
			}
		},
		[getStatusAction, updatePermissions]
	);

	const getIsSelected = useCallback(
		(permissionId: string): boolean => {
			return checkboxesState.get(permissionId) || false;
		},
		[checkboxesState]
	);

	const getPermissionStatus = useCallback(
		(permissionId: string): TAccessReviewPermissionStatus => {
			return resourcePermissions?.get(permissionId)?.status || "pending";
		},
		[resourcePermissions]
	);

	const setComment = useCallback(
		async (permissionIds: string[], content: string) => {
			if (!permissionIds || permissionIds.length === 0) return;
			const now = new Date();
			await bulkComment(permissionIds, content);
			const newPermissions = accessReviewResource?.accessReviewResourcePermissions?.map(permission => {
				if (permissionIds.includes(permission.accessReviewPermissionId)) {
					const accessReviewPermission = permission.accessReviewPermission!;
					let comments = accessReviewPermission.comments;
					if (comments && comments.size > 0) {
						const userCommentIndex = comments.findIndex(comment => comment.creatorId === user?.id);
						let userComment = userCommentIndex > -1 ? comments.get(userCommentIndex) : null;
						if (userComment) {
							userComment = userComment.merge({ content, updatedAt: now });
							comments = comments.set(userCommentIndex, userComment);
						} else {
							comments = comments.push(
								new AccessReviewPermissionCommentModel({ content, createdAt: now, updatedAt: now, creatorId: user?.id })
							);
						}
					} else {
						comments = List([
							new AccessReviewPermissionCommentModel({ content, createdAt: now, updatedAt: now, creatorId: user?.id })
						]);
					}

					return permission.set("accessReviewPermission", accessReviewPermission.set("comments", comments));
				}
				return permission;
			});
			updateResource({
				accessReviewResourcePermissions: newPermissions
			});
		},
		[accessReviewResource?.accessReviewResourcePermissions, updateResource, user?.id]
	);

	const setSelectedComment = useCallback(
		async (content: string) => {
			if (!resourcePermissions) return;
			const flatPermissions = resourcePermissions.valueSeq();
			const accessReviewPermissionIds = flatPermissions.reduce((acc, permission) => {
				if (selectedPermissions.includes(permission.resourcePermissionId)) {
					acc.push(permission.id);
				}
				return acc;
			}, [] as string[]);
			await setComment(accessReviewPermissionIds, content);
		},
		[resourcePermissions, selectedPermissions, setComment]
	);

	useEffect(() => {
		resetCheckboxesState();
	}, [resetCheckboxesState]);

	return {
		state: {
			checkboxesState,
			isLoading,
			report,
			resource: accessReviewResource,
			resourcePermissions,
			selectedAmount: selectedPermissions.length,
			totalAmount: checkboxesState.size
		},
		actions: {
			getIsSelected,
			getPermissionStatus,
			handlePermissionCheckboxChange,
			handleSelectAllCheckboxChange,
			resetCheckboxesState,
			removePermissionCheckbox,
			setComment,
			setSelected,
			setSelectedComment,
			setSinglePermission
		}
	};
};

export const [ResourcesPermissionsReviewContextProvider, useResourcePermissionsReviewContext] =
	constate(useResourcePermissionReview);
