import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterInclusionOperator, validateFilterRelation } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceRoleIntegrationName" as const;

const defaults = {
	name: filterName,
	value: [] as string[],
	operator: "contains" as "contains" | "notContains",
	relation: "or" as "and" | "or"
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleIntegrationNameFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceRoleIntegrationNameFilter | null {
		const integrationResourceRoleIntegrationName = uniq(
			urlSearchParams.getAll("integrationResourceRoleIntegrationName")
		);
		const integrationResourceRoleIntegrationNameOperator =
			urlSearchParams.get("integrationResourceRoleIntegrationNameOperator") || undefined;
		const integrationResourceRoleIntegrationNameRelation =
			urlSearchParams.get("integrationResourceRoleIntegrationNameRelation") || undefined;
		if (
			integrationResourceRoleIntegrationName.length === 0 ||
			!validateFilterInclusionOperator(integrationResourceRoleIntegrationNameOperator) ||
			!validateFilterRelation(integrationResourceRoleIntegrationNameRelation)
		) {
			return null;
		}
		return new IntegrationResourceRoleIntegrationNameFilter({
			value: integrationResourceRoleIntegrationName,
			operator: integrationResourceRoleIntegrationNameOperator,
			relation: integrationResourceRoleIntegrationNameRelation
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceRoleIntegrationName", value);
		}
		params.append("integrationResourceRoleIntegrationNameOperator", this.operator);
		params.append("integrationResourceRoleIntegrationNameRelation", this.relation);
		return params;
	}
}
