import { Record, List } from "immutable";
import { IsNullError } from "utils/errors/isNullError";
import { removeRedundantSpaces } from "utils/strings";
import { TTicketDuration } from "utils/durationsOptions";
import { ApprovalAlgorithmModel } from "./ApprovalAlgorithmModel";
import { BundleItemModel, IBundleItemSchema } from "./BundleItemModel";

const defaults = {
	id: "",
	allowedDurations: null as List<TTicketDuration> | null,
	approvalAlgorithm: null as ApprovalAlgorithmModel | null,
	bundleItems: null as List<BundleItemModel> | null,
	name: "",
	category: null as string | null,
	isDeleted: false,
	description: null as string | null,
	tags: null as string[] | null
};

interface IBundleSchema {
	id?: string | null;
	approvalAlgorithmId: string;
	allowedDurations: List<TTicketDuration> | null;
	bundleItems?: List<IBundleItemSchema> | null;
	name: string;
	category: string | null;
	description: string | null;
	tags: string[] | null;
}

export class BundleModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { id, approvalAlgorithm, bundleItems, name, category, isDeleted, description, tags, allowedDurations } =
			data as typeof defaults;
		return new BundleModel({
			id,
			allowedDurations: allowedDurations ? List(allowedDurations) : null,
			approvalAlgorithm: approvalAlgorithm ? ApprovalAlgorithmModel.fromServerData(approvalAlgorithm) : null,
			bundleItems: bundleItems ? List(bundleItems.map(bundleItem => BundleItemModel.fromServerData(bundleItem))) : null,
			name,
			category,
			isDeleted,
			description,
			tags
		});
	}

	toServerData(): IBundleSchema {
		const { id, approvalAlgorithm, bundleItems, name, category, description, tags, allowedDurations } = this;
		if (!approvalAlgorithm) {
			throw IsNullError.from({
				location: "BundleModel.toServerData",
				parentObject: {
					name: "this (BundleModel)",
					value: this
				},
				requestedProperty: "approvalAlgorithm"
			});
		}
		return {
			id,
			allowedDurations,
			bundleItems: bundleItems ? bundleItems.map(bundleItem => bundleItem.toServerData()) : null,
			name: removeRedundantSpaces(name),
			approvalAlgorithmId: approvalAlgorithm && approvalAlgorithm.id,
			category,
			description,
			tags
		};
	}

	toDropDownOption() {
		return {
			value: this.id,
			label: this.name
		};
	}
}
