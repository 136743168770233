import { AccessReviewPermissionCommentHeader } from "components/common/AccessReviewPermissionCommentHeader";
import { Button } from "components/ui/Button";
import { Checkbox } from "components/ui/Checkbox";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { FlagIcon } from "components/ui/Icons/FlagIcon";
import { Typography } from "components/ui/legacy/Typography";
import { IntegrationModel } from "models/IntegrationModel";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ImmediateRevokeModal } from "components/common/ImmediateRevokeModal";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useStyles } from "./styles";

interface IHeaderProps {
	selectedAmount: number;
	setApproved: () => Promise<void>;
	setComment: (comment: string) => Promise<void>;
	setDenied: () => Promise<void>;
	setFlagged: () => Promise<void>;
	totalAmount: number;
	immediateRevoke: boolean;
}

export const PermissionsListHeader: FC<IHeaderProps> = ({
	selectedAmount,
	setApproved,
	setComment,
	setDenied,
	setFlagged,
	totalAmount,
	immediateRevoke
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { isOpen: areYouSureModalIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();
	const [activeLoadingAction, setActiveAction] = useState<"approved" | "denied" | "flagged" | undefined>(undefined);

	const onSetApproved = useCallback(async () => {
		setActiveAction("approved");
		await setApproved();
		setActiveAction(undefined);
	}, [setApproved]);

	const onSetDenied = useCallback(async () => {
		setActiveAction("denied");
		await setDenied();
		setActiveAction(undefined);
		areYouSureModalIsOpen && closeAreYouSureModal();
	}, [areYouSureModalIsOpen, closeAreYouSureModal, setDenied]);

	const onSetFlagged = useCallback(async () => {
		setActiveAction("flagged");
		await setFlagged();
		setActiveAction(undefined);
	}, [setFlagged]);

	const disableActions = selectedAmount === 0 || !!activeLoadingAction;

	return (
		<>
			{immediateRevoke && (
				<ImmediateRevokeModal onAction={onSetDenied} onClose={closeAreYouSureModal} isOpen={areYouSureModalIsOpen} />
			)}
			<div className={classes.header}>
				<div className={classes.subHeaderDataContainer}>
					<Typography variant="h3" className={classes.amountContainer}>
						{t(`common.subordinatePermissionsReviewTable.${totalAmount ? "selected" : "nothingToReview"}`, {
							amount: selectedAmount,
							total: totalAmount
						})}
					</Typography>
					<AccessReviewPermissionCommentHeader onSubmit={setComment} />
				</div>
				<div className={classes.subHeaderDataContainer}>
					<Button
						variant="secondary"
						size="medium"
						className={classNames(classes.buttonApprove, { [classes.disabled]: disableActions })}
						prefix={<GrantedIcon />}
						loading={activeLoadingAction === "approved"}
						onClick={onSetApproved}
						disabled={disableActions}>
						{t("common.subordinatePermissionsReviewTable.approve")}
					</Button>
					<Button
						variant="secondary"
						size="medium"
						className={classNames(classes.buttonDeny, { [classes.disabled]: disableActions })}
						prefix={<CloseIcon />}
						loading={activeLoadingAction === "denied"}
						onClick={immediateRevoke ? openAreYouSureModal : onSetDenied}
						disabled={disableActions}>
						{t("common.subordinatePermissionsReviewTable.deny")}
					</Button>
					<Button
						variant="secondary"
						size="medium"
						className={classNames(classes.buttonFlag, { [classes.disabled]: disableActions })}
						prefix={<FlagIcon />}
						loading={activeLoadingAction === "flagged"}
						onClick={onSetFlagged}
						disabled={disableActions}>
						{t("common.subordinatePermissionsReviewTable.flag")}
					</Button>
				</div>
			</div>
		</>
	);
};

interface IIntegrationTitleProps {
	checkboxOnChange?: (checked: boolean) => void;
	checkboxState?: "unchecked" | "checked" | "indeterminate";
	integration: IntegrationModel;
	readonly?: boolean;
}

export const IntegrationTitle: FC<IIntegrationTitleProps> = ({
	checkboxOnChange,
	checkboxState,
	integration,
	readonly
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const onClick = useCallback(
		(event: React.MouseEvent) => {
			checkboxOnChange && checkboxOnChange(checkboxState === "unchecked");
			event.stopPropagation();
		},
		[checkboxState, checkboxOnChange]
	);

	if (!integration) return null;

	const IntegrationName = (
		<div className={classes.integrationTitle}>
			{integration?.imageUrl && (
				<img className={classes.integrationImage} src={integration.imageUrl} alt={integration.name} />
			)}
			<Typography>{integration.name}</Typography>
		</div>
	);

	if (readonly) {
		return IntegrationName;
	}
	return (
		<Checkbox
			className={classes.checkbox}
			onClick={onClick}
			partialSelected={checkboxState === "indeterminate"}
			selected={checkboxState !== "unchecked"}
			label={IntegrationName}
			tooltipText={checkboxState === "unchecked" ? t("checkbox.selectAll") : t("checkbox.deselectAll")}
		/>
	);
};
