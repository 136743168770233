import React, { useMemo } from "react";
import classNames from "classnames";
import { UserModel } from "models/UserModel";
import { UserFilledDeletedIcon } from "components/ui/Icons/UserFilledDeletedIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { useUser } from "hooks/useUser";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { useVirtualTableContext } from "../../virtualTableContext";
import { useStyles } from "./styles";
import type { IRowOptions } from "../../types";

interface IProps {
	user: string | UserModel | null;
	isDeleted?: boolean;
	emptyState?: React.ReactNode;
}

const UserCellContent: FC<IProps & IRowOptions> = ({ isDeleted, user, emptyState = null }) => {
	const classes = useStyles();
	const {
		state: { compact }
	} = useVirtualTableContext();

	const fetchedUser = useUser(typeof user === "string" ? user : "");
	const userModel = useMemo(() => (user instanceof UserModel ? user : fetchedUser), [user, fetchedUser]);
	const userImage = useMemo(() => {
		const deleted = isDeleted || userModel?.isDeleted;
		const className = classNames(classes.userImage, { [classes.compact]: compact });
		if (!userModel) return null;
		if (deleted) return <UserFilledDeletedIcon className={className} />;
		if (!userModel?.imageUrl) return <UserCircleIcon className={className} />;

		return <img src={userModel.imageUrl} className={className} />;
	}, [classes.compact, classes.userImage, isDeleted, userModel, compact]);

	const content = useMemo(() => {
		const getContent = (emailVariant: "body_reg" | "text_tny_reg") => (
			<div className={classes.userNameWithEmail}>
				<Typography className={classes.userText} relative noWrap variant="body_sb">
					{userModel?.fullName ?? emptyState}
				</Typography>
				{!compact && userModel ? (
					<Typography className={classes.userText} relative noWrap variant={emailVariant}>
						{userModel.email}
					</Typography>
				) : null}
			</div>
		);

		return emptyState ? (
			getContent("text_tny_reg")
		) : (
			<Tooltip content={getContent("body_reg")}>{getContent("text_tny_reg")}</Tooltip>
		);
	}, [userModel, classes, compact, emptyState]);

	return (
		<div className={classes.userNodeContainer}>
			{userImage}
			{content}
		</div>
	);
};

const UserCellContentMemo = React.memo(UserCellContent) as typeof UserCellContent;

export { UserCellContentMemo as UserCellContent };
