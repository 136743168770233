import React, { MouseEventHandler, useMemo } from "react";
import classNames from "classnames";
import { LoadingSpinner } from "../LoadingSpinner";
import { Tooltip } from "../Tooltip";
import { useStyles } from "./styles";

export type TIconButtonVariant = "primary" | "secondary" | "reverse";
export type TIconButtonSize = "tiny" | "xs" | "small" | "medium" | "large" | "huge";

export type TIconButtonProps = {
	disabled?: boolean;
	loading?: boolean;
	onClick?: MouseEventHandler;
	size?: TIconButtonSize;
	tooltip?: React.ReactNode;
	variant?: TIconButtonVariant;
	tooltipClassName?: string;
};

export const IconButton: FC<TIconButtonProps> = ({
	className,
	children,
	innerRef,
	disabled = false,
	loading = false,
	onClick,
	size = "medium",
	tooltip,
	tooltipClassName,
	variant = "primary"
}) => {
	const classes = useStyles();

	const sizeClassName = useMemo(() => {
		switch (size) {
			case "tiny":
				return classes.tiny;
			case "xs":
				return classes.xs;
			case "small":
				return classes.small;
			case "medium":
				return classes.medium;
			case "large":
				return classes.large;
			case "huge":
				return classes.huge;
		}
	}, [classes, size]);

	const variantClassName = useMemo(() => {
		switch (variant) {
			case "primary":
				return classes.primary;
			case "secondary":
				return classes.secondary;
			case "reverse":
				return classes.reverse;
		}
	}, [classes, variant]);

	return (
		<Tooltip className={tooltipClassName} content={tooltip}>
			<button
				className={classNames(
					classes.iconButton,
					sizeClassName,
					variantClassName,
					{ [classes.disabled]: disabled },
					className
				)}
				ref={innerRef}
				onClick={!disabled && !loading ? onClick : undefined}>
				{loading ? <LoadingSpinner /> : children}
			</button>
		</Tooltip>
	);
};
