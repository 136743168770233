import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import sortBy from "lodash/sortBy";
import { Button } from "components/ui/Button";
import { useIntegrations } from "hooks/useIntegrations";
import { LoadingDots } from "components/ui/LoadingDots";
import { InProgressIcon } from "components/ui/Icons/InProgressIcon";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { useTasksContext } from "context/tasksContext";
import { toGroupMapBy } from "utils/toMapBy";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { TasksTodoIcon } from "components/ui/Icons/TasksTodoIcon";
import { TasksStatusColumn } from "./TasksStatusColumn";
import { useStyles } from "./styles";
import type { TTaskStatus } from "models/TaskModel";

const TRANSLATION_PREFIX = "pages.tasks";

interface IColumn {
	status: TTaskStatus;
	header: string | JSX.Element;
	buttonText?: string;
	onClick?: (id: string) => Promise<void>;
	order: number;
	icon: JSX.Element;
}

export const TasksStatusColumns: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations(true);
	const { user } = useAuthenticatedUser();

	const {
		state: { tasks, archiveAllDoneTasksIsLoading },
		actions: { setTaskStatus, archiveTask, archiveAllDoneTasks }
	} = useTasksContext();

	const groupedTasks = useMemo(() => {
		if (!tasks) return null;
		const grouped = toGroupMapBy(tasks.toList(), ({ status }) => status);
		return grouped.map(group => sortBy(group, g => g.updatedAt));
	}, [tasks]);

	const startWorking = useCallback(
		async (id: string) => {
			await setTaskStatus(id, "inProgress");
		},
		[setTaskStatus]
	);

	const finishWorking = useCallback(
		async (id: string) => {
			await setTaskStatus(id, "done");
		},
		[setTaskStatus]
	);

	const columns = useMemo<IColumn[]>(() => {
		const isAdmin = user?.isAdmin;
		return [
			{
				status: "todo",
				header: t(`${TRANSLATION_PREFIX}.columns.todo`),
				icon: <TasksTodoIcon />,
				onClick: startWorking,
				buttonText: t(`${TRANSLATION_PREFIX}.taskCard.actions.start`),
				order: 1
			},
			{
				status: "inProgress",
				header: t(`${TRANSLATION_PREFIX}.columns.inProgress`),
				icon: <InProgressIcon />,
				onClick: finishWorking,
				buttonText: t(`${TRANSLATION_PREFIX}.taskCard.actions.resolve`),
				order: 2
			},
			{
				status: "done",
				header: (
					<div className={classes.doneTitle}>
						{t(`${TRANSLATION_PREFIX}.columns.done`)}
						{isAdmin && (
							<Button
								variant="secondary"
								size="small"
								className={classes.archiveAllButton}
								onClick={archiveAllDoneTasks}
								loading={archiveAllDoneTasksIsLoading}>
								{t(`${TRANSLATION_PREFIX}.taskCard.actions.archiveAll`)}
							</Button>
						)}
					</div>
				),
				icon: <DoneCircleIcon />,
				buttonText: isAdmin ? t(`${TRANSLATION_PREFIX}.taskCard.actions.archive`) : undefined,
				onClick: isAdmin ? archiveTask : undefined,
				order: 3
			}
		];
	}, [
		user?.isAdmin,
		t,
		startWorking,
		finishWorking,
		classes.doneTitle,
		classes.archiveAllButton,
		archiveAllDoneTasks,
		archiveAllDoneTasksIsLoading,
		archiveTask
	]);

	if (!groupedTasks) return <LoadingDots center />;

	return (
		<div className={classes.taskStatusColumnsContainer}>
			{columns && integrations ? (
				columns.map(({ status, header, icon, buttonText, onClick, order }) => (
					<TasksStatusColumn
						buttonText={buttonText}
						header={header}
						integrations={integrations}
						key={status}
						onButtonClick={onClick}
						order={order}
						prefixIcon={icon}
						tasks={groupedTasks.get(status) || []}
					/>
				))
			) : (
				<LoadingDots center />
			)}
		</div>
	);
};
