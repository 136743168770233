import React, { useCallback } from "react";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { type IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { getIntegrationResourcesRoles } from "api/accessRequestForm";
import { useTranslation } from "react-i18next";
import { SearchOption } from "../../../NewTicketBySearchForm/components/SearchOption";
import { SearchChip } from "../../../NewTicketBySearchForm/components/SearchChip";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TFullRole } from "../../../../types";
import type { Require } from "types/utilTypes";

const LIMIT = 100;

interface IProps {
	isRoleConflicted: (role: TFullRole) => boolean;
	selectedResourceIds: string[];
	setSelectedRoles: (value: Require<IntegrationResourceRoleModel, "integrationResource">[] | null) => void;
	selectedRoles: Require<IntegrationResourceRoleModel, "integrationResource">[] | null;
	userId: string;
}

const getOptionLabel = (option: Require<IntegrationResourceRoleModel, "integrationResource">) =>
	`${option.integrationResource.name} - ${option?.name}`;

const equality = (option: IntegrationResourceRoleModel, value: IntegrationResourceRoleModel) => option.id === value.id;

export const RolesInput: FC<IProps> = ({
	selectedResourceIds,
	setSelectedRoles,
	selectedRoles,
	userId,
	isRoleConflicted
}) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.rolesInput" });

	const fetchRoles = useCallback(
		async (query: string) => {
			if (selectedResourceIds.length) {
				const response = await getIntegrationResourcesRoles({
					integrationResourceIds: selectedResourceIds,
					userId,
					search: query,
					perPage: LIMIT
				});
				return response.result.toArray();
			}
			return [];
		},
		[selectedResourceIds, userId]
	);

	const { selectProps, query } = useSelectSearchProps(fetchRoles, selectedRoles, true);

	const renderChip = useCallback((params: IRenderChipParams<TFullRole>) => {
		const { option, ...rest } = params;
		return <SearchChip {...rest} option={{ type: "role", value: option }} />;
	}, []);

	const renderOption = useCallback(
		(option: TFullRole) => <SearchOption type="role" value={option} key={option.id} query={query} />,
		[query]
	);

	return (
		<MultipleSelect
			{...selectProps}
			disabled={selectedResourceIds.length === 0}
			filter={null}
			getOptionLabel={getOptionLabel}
			isOptionDisabled={isRoleConflicted}
			isOptionEqualToValue={equality}
			label={t("label")}
			limit={LIMIT}
			limitChipType="role"
			onChange={setSelectedRoles}
			placeholder={t("placeholder")}
			renderChip={renderChip}
			renderOption={renderOption}
			required
			sort={null}
			value={selectedRoles}
		/>
	);
};
