import classNames from "classnames";
import { UserWithEmail } from "components/common/UserWithEmail";
import { Table } from "components/ui/Table";
import { TooltipOnOverflow } from "components/ui/legacy/TooltipOnOverflow";
import { Typography } from "components/ui/legacy/Typography";
import { useIntegrations } from "hooks/useIntegrations";
import { formatDate } from "i18n";
import React from "react";
import { IsNullError } from "utils/errors/isNullError";
import { useTranslation } from "react-i18next";
import { Link } from "components/common/Link";
import { usePermission } from "hooks/usePermission";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";
import type { IEntitlement } from "../../utils";

export const EntitlementRow: FC<{ entitlement: IEntitlement; index: number; user?: UserModel | null }> = ({
	entitlement,
	index,
	user
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const { expiresAt, integration } = usePermission(
		entitlement.source?.integrationResource ?? null,
		entitlement.ticketPermissions
	);

	if (!integration && entitlement?.source && integrations) {
		throw IsNullError.from({
			location: "EntitlementsTable",
			parentObject: {
				name: "integrations",
				value: integrations
			},
			requestedProperty: entitlement.source.integrationResource!.integrationId
		});
	}
	return (
		<Table.Row key={user?.id + entitlement.actor.id + entitlement.role.id + entitlement.source?.id}>
			<Table.Cell className={classNames({ [classes.subRowCell]: index > 0 })}>
				{index === 0 ? (
					user ? (
						<UserWithEmail user={user} />
					) : (
						<Typography className={classes.noUser}>{t("pages.integrationResource.userlessEntitlement")}</Typography>
					)
				) : null}
			</Table.Cell>
			<Table.Cell>
				<TooltipOnOverflow content={<Typography>{entitlement.actor.displayName}</Typography>} />
			</Table.Cell>
			<Table.Cell>
				<TooltipOnOverflow className={classes.entitlements} content={entitlement.role.name} />
			</Table.Cell>
			<Table.Cell className={classes.sourceCell}>
				{entitlement.source ? (
					integration &&
					entitlement.source && (
						<>
							<Link
								className={classes.link}
								noDecoration
								to={`/integrations/${entitlement.source.integrationResource!.integrationId}`}>
								{integration.name}
							</Link>
							/
							<Link
								className={classes.link}
								noDecoration
								to={`/integrations/${entitlement.source.integrationResource!.integrationId}/resources/${
									entitlement.source.integrationResource!.id
								}`}>
								{`${entitlement.source.integrationResource!.name}`}
							</Link>
							/
							<Link
								className={classes.link}
								noDecoration
								to={`/integrations/${entitlement.source.integrationResource!.integrationId}/resources/${
									entitlement.source.integrationResource!.id
								}/roles/${entitlement.source.id}`}>
								{`${entitlement.source.name}`}
							</Link>
						</>
					)
				) : (
					<Typography> {t("shared.direct")} </Typography>
				)}
			</Table.Cell>
			<Table.Cell className={classes.ticketsCell}>
				{entitlement.ticketPermissions.map(
					({ ticket }) =>
						ticket && (
							<Link noDecoration key={ticket.id} to={`?ticketId=${ticket.id}`}>
								#{ticket.number}
							</Link>
						)
				)}
			</Table.Cell>
			<Table.Cell>
				<Typography>
					{expiresAt ? t("dateTime.date", { date: expiresAt }) : t("shared.ticketExpiration.never")}
				</Typography>
			</Table.Cell>
			<Table.Cell>
				<Typography>
					{entitlement.actor.lastUsed
						? formatDate(
								entitlement.actor.lastUsed instanceof Date
									? entitlement.actor.lastUsed
									: new Date(entitlement.actor.lastUsed)
							)
						: t("pages.integrationResource.unknown")}
				</Typography>
			</Table.Cell>
		</Table.Row>
	);
};
