import { Map } from "immutable";
import { TaskModel, TTaskStatus } from "models/TaskModel";
import { apiReq } from "../utils/api/apiReq";

export async function getTasks(): Promise<Map<string, TaskModel>> {
	const { data } = await apiReq("GET", "/v1/tasks");
	return Map(
		data.map((task: unknown) => {
			const taskModel = TaskModel.fromServerData(task);
			return [taskModel.id, taskModel];
		})
	);
}

export async function setTaskStatus(id: string, status: TTaskStatus): Promise<TaskModel> {
	const { data } = await apiReq("PUT", `/v1/tasks/${id}`, { status });
	return TaskModel.fromServerData(data);
}

export async function archiveTask(id: string): Promise<TaskModel> {
	const { data } = await apiReq("DELETE", `/v1/tasks/${id}`);
	return TaskModel.fromServerData(data);
}

export async function archiveDone(): Promise<Map<string, TaskModel>> {
	const { data } = await apiReq("DELETE", "/v1/tasks/archiveDone");
	return Map(
		data.map((task: unknown) => {
			const taskModel = TaskModel.fromServerData(task);
			return [taskModel.id, taskModel];
		})
	);
}
