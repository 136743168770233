import React from "react";
import { useIntegrations } from "hooks/useIntegrations";
import { Chip } from "components/ui/chips/Chip";
import { BundleChip } from "components/ui/chips/BundleChip";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import type { IRenderChipParams } from "components/ui/MultipleSelect";
import type { TNewTicketOption } from "components/pages/NewTicketPage/components/NewTicketForm/types";

export const SearchChip: FC<IRenderChipParams<TNewTicketOption>> = ({ option, componentKey, ...rest }) => {
	const integrations = useIntegrations();
	const integration = option.type === "role" ? integrations?.get(option.value.integrationResource.integrationId) : null;
	const name =
		option.type === "role" ? `${option.value.integrationResource.name} - ${option.value.name}` : option.value.name;

	const imageUrl = integration?.imageUrl;
	const image = imageUrl ? getDynamicSizeIcon(<img src={imageUrl} />) : undefined;

	return option.type === "bundle" ? (
		<BundleChip onDelete={rest.onRemove} variant="regular" selected bundle={option.value}></BundleChip>
	) : (
		<Chip onDelete={rest.onRemove} variant="regular" selected PrefixIcon={image} size="medium">
			{name}
		</Chip>
	);
};
