import { BetaContext } from "context/betaContext";
import { useCompany } from "hooks/useCompany";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const BetaTriggerPage: React.FC = () => {
	const navigate = useNavigate();
	const { pathname, search, hash } = useLocation();
	const company = useCompany();
	const {
		actions: { setBeta }
	} = BetaContext();

	useEffect(() => {
		if (company?.forceBeta) {
			navigate(
				{
					pathname: pathname.replace("/beta", ""),
					search,
					hash
				},
				{ replace: true }
			);
		} else {
			setBeta(true);
		}
	}, [company?.forceBeta, pathname, search, hash, navigate, setBeta]);

	return null;
};
