import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { Select } from "components/ui/Select";
import { Button } from "components/ui/Button";
import { useCompanyContext } from "context/companyContext";
import { getTimeZones } from "@vvo/tzdb";
import { Title } from "components/ui/Title";
import { useStyles } from "./styles";

interface ITimeZoneOption {
	value: string;
	label: string;
	group: string;
}

const timeZones = getTimeZones();
const timeZoneOptions: ITimeZoneOption[] = timeZones.map(({ name, continentName, currentTimeFormat }) => ({
	value: name,
	label: `${name.replace("_", " ").replace(/\//g, " - ")} (GMT${currentTimeFormat.split(" ")[0]})`,
	group: continentName
}));

const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const browserTimeZoneOption = timeZoneOptions.find(({ value }) => value === browserTimeZone) || null;

export const Timezone: FC = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		state: { company },
		actions: { saveTimezone }
	} = useCompanyContext();

	const [timeZone, setTimeZone] = useState<ITimeZoneOption | null>(browserTimeZoneOption);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (company?.timeZone) {
			setTimeZone(timeZoneOptions.find(({ value }) => value === company.timeZone) || null);
		}
	}, [company]);

	const submit = useCallback(async () => {
		setIsLoading(true);
		try {
			await saveTimezone(timeZone?.value || null);
		} finally {
			setIsLoading(false);
		}
	}, [saveTimezone, timeZone?.value]);

	const hasChanged = timeZone?.value !== company?.timeZone;

	return (
		<>
			<Title variant="h3" noBorder>
				{t(`pages.settings.timezone.title`)}
			</Title>
			<div className={classes.container}>
				<Select
					className={classes.timeZoneSelection}
					groupBy={option => option.group}
					limit={timeZoneOptions.length}
					onChange={setTimeZone}
					options={timeZoneOptions}
					placeholder={t(`pages.settings.timezone.placeholder`)}
					value={timeZone}
				/>
				<Button
					size="medium"
					disabled={!timeZone || !hasChanged}
					onClick={submit}
					loading={isLoading}
					className={classes.save}>
					{t("buttons.save")}
				</Button>
			</div>
		</>
	);
};
