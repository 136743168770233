import React from "react";
import classNames from "classnames";
import { useStyles } from "./styles";

type TBaseProps = {
	color?: string;
	width?: number;
	rounded?: boolean;
};

type TProps = TBaseProps & ({ vertical: true } | { horizontal: true });

export const Divider: FC<TProps> = props => {
	const { className, innerRef, color = "var(--color-grey-500)", width = 1, rounded = false } = props;
	const isHorizontal = "horizontal" in props;
	const classes = useStyles({ width });

	const viewBox = isHorizontal ? "0 0 100 1" : "0 0 1 100";
	const pathD = isHorizontal ? "M0 0.5 L100 0.5" : "M0.5 0 L0.5 100";

	return (
		<div className={classNames(classes.divider, { [classes.horizontal]: isHorizontal }, className)} ref={innerRef}>
			<svg
				viewBox={viewBox}
				preserveAspectRatio="none"
				className={classNames(classes.svg, { [classes.horizontal]: isHorizontal, [classes.rounded]: rounded })}>
				<path d={pathD} stroke={color} fill="none" />
			</svg>
		</div>
	);
};
