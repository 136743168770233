import React, { useCallback, useMemo } from "react";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { Select } from "components/ui/Select";
import { useTranslation } from "react-i18next";
import { sortByDisplayName } from "utils/sortUtils";
import { useIntegrations } from "hooks/useIntegrations";

interface IProps {
	onChange: (integrationId: string, value: IntegrationActorModel) => void;
	inputLabel?: string;
	inputClassName?: string;
	limit?: number;
	integrationId: string;
	optionClassName?: string;
	options: IntegrationActorModel[];
	selected: string | null;
	multiple?: boolean;
}

const getOptionLabel = (option: IntegrationActorModel) => option?.displayName || "";
const equality = (option: IntegrationActorModel, selected: IntegrationActorModel) => option.id === selected.id;

export const IntegrationActorSelectInput: FC<IProps> = ({
	onChange,
	inputLabel,
	integrationId,
	limit,
	options,
	selected,
	className,
	multiple = false
}) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.actorInput" });
	const integrations = useIntegrations();
	const integration = useMemo(() => integrations?.get(integrationId), [integrationId, integrations]);
	const handleChange = useCallback(
		(value: IntegrationActorModel | null) => {
			if (value) {
				onChange(integrationId, value);
			}
		},
		[integrationId, onChange]
	);

	const selectedOption = useMemo(
		() => (selected ? options?.find(option => option.id === selected) : null),
		[options, selected]
	);
	const selectOptions = useMemo(() => options ?? [], [options]);

	const label = useMemo(
		() =>
			inputLabel
				? inputLabel
				: t(`label.${integration && multiple ? "selectIntegrationActor" : "userIntegrationActors"}`, {
						integrationName: integration?.name
					}),
		[inputLabel, integration, multiple, t]
	);

	return (
		<Select
			className={className}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={equality}
			label={label}
			limit={limit}
			onChange={handleChange}
			options={selectOptions}
			placeholder={t("placeholder")}
			required
			sort={sortByDisplayName}
			value={selectedOption ?? null}
		/>
	);
};
