import React, { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/ui/Modal";
import { Button } from "components/ui/Button";
import { useLoadingState } from "hooks/useLoadingState";
import { Input } from "components/ui/Input";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";
import type { TOnCallType } from "models/OnCallIntegrationScheduleModel";

type TOnCallIntegrationProps = {
	integration: TOnCallType;
	logo?: ReactNode;
	open: boolean;
	integrate: (apiKey: string) => Promise<void>;
	closeModal: () => void;
};

export const OnCallIntegrationModal: FC<TOnCallIntegrationProps> = ({
	integration,
	open,
	logo,
	integrate,
	closeModal
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { withLoader, isLoading } = useLoadingState();
	const [apiKey, setApiKey] = useState("");
	const [errors, setErrors] = useState<string[] | undefined>(undefined);

	const onErrors = useCallback((newErrors: string[] | null) => {
		setErrors(newErrors ? newErrors : undefined);
	}, []);

	const submit = useCallback(async () => {
		if (apiKey && integrate) {
			try {
				await withLoader(integrate(apiKey));
				setApiKey("");
			} catch (e) {
				setErrors([t("pages.settings.integrations.onCall.failedToIntegrate")]);
			}
		}
	}, [apiKey, integrate, t, withLoader]);

	const close = useCallback(() => {
		setApiKey("");
		closeModal();
	}, [closeModal]);

	return (
		<Modal
			isOpen={open}
			onClose={close}
			actions={
				<Button size="medium" onClick={submit} loading={isLoading} disabled={isLoading}>
					{t("buttons.integrate")}
				</Button>
			}
			content={
				<div className={classes.modalContent}>
					<TitleBody
						size="large"
						title={
							<div className={classes.modalTitle}>
								{t("pages.settings.integrations.integrateWith", { integration })}
								{logo}
							</div>
						}
					/>
					<Input
						autoFocus
						label={t("pages.settings.integrations.onCall.ApiKeyLabel")}
						errors={errors}
						onError={onErrors}
						value={apiKey}
						onValueChange={setApiKey}
					/>
				</div>
			}
		/>
	);
};
