import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	textArea: {
		fontSize: "var(--typography-standard-font-size)",
		backgroundColor: "var(--color-almost-white)",
		fontFamily: `"Lucida Console", "Courier New", monospace`, // cspell:disable-line
		outline: "none",
		resize: "none",
		lineHeight: "1.1em"
	},
	container: {
		boxSizing: "border-box"
	}
});
