import React from "react";
import { TextUser } from "components/common/TextUser";
import { UserModel } from "models/UserModel";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/useUser";

export const AuditLogUser: FC<{ user?: UserModel | string }> = ({ user, className }) => {
	const fetchedUser = useUser(typeof user === "string" ? user : "");
	const userModel = typeof user === "string" ? fetchedUser : user;
	const { t } = useTranslation();
	// if there is no user we assume its API user
	if (!user) return <b>{t("pages.auditLog.apiUser")}</b>;

	return <TextUser user={userModel} className={className} />;
};
