import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { getNameAndIcon } from "utils/ui/directoryGroups";
import { Entity } from "../Entity";
import { useStyles } from "./styles";

interface IProps {
	icon?: JSX.Element;
	iconClassName?: string;
	isDeleted?: boolean;
	onClick?: () => void;
	value: string;
	separator?: string;
	noWrap?: boolean;
}

export const TextWithDirectoryIcon: FC<IProps> = ({
	className,
	icon,
	iconClassName,
	innerRef,
	noWrap,
	isDeleted,
	onClick: propsOnClick,
	value,
	separator
}) => {
	const classes = useStyles();

	const nameAndIcon = useMemo(() => getNameAndIcon(value), [value]);

	const onClick = useCallback(
		(event: React.MouseEvent) => {
			if (propsOnClick) {
				event.stopPropagation();
				propsOnClick();
			}
		},
		[propsOnClick]
	);

	return (
		<span className={classNames(classes.sourceIconContainer, className)} ref={innerRef} onClick={onClick}>
			<Entity
				className={classes.entity}
				content={nameAndIcon.name}
				isDeleted={isDeleted}
				noWrap={noWrap}
				prefixIcon={
					icon ||
					(nameAndIcon.Icon ? (
						<nameAndIcon.Icon className={classNames(classes.sourceIcon, iconClassName)} />
					) : undefined)
				}
			/>
			{separator}
		</span>
	);
};
