import { useEffect, useMemo } from "react";
import { List } from "immutable";
import { useNewRequestAllowedDurations } from "components/pages/NewRequestPage/newRequestDataContext";
import type { UserModel } from "models/UserModel";
import type { TRequestTarget } from "components/pages/NewRequestPage/types";

export const useAvailableDurations = (
	requestTargets: List<TRequestTarget>,
	receiverUser: UserModel | null | undefined
) => {
	const { data: durations, fetch: fetchDurations, loadingState } = useNewRequestAllowedDurations();

	useEffect(() => {
		if (!receiverUser || requestTargets.size === 0) return;
		fetchDurations({
			userId: receiverUser.id,
			targets: requestTargets.toArray().map(target => ({
				id: target.id,
				type: target.type
			}))
		});
	}, [requestTargets, receiverUser, fetchDurations]);

	return useMemo(() => ({ durations, loadingState }), [durations, loadingState]);
};
