import classNames from "classnames";
import React, { useCallback, useRef, memo, useMemo, useEffect } from "react";
import isEqual from "lodash/isEqual";
import { FixedSizeList, type ListOnItemsRenderedProps, type ListChildComponentProps } from "react-window";
import { useDebounceFn } from "hooks/useDebounce";
import { useResizeDetector } from "react-resize-detector";
import { useIsOverflowed } from "hooks/useIsOverflowed";
import { SCROLLBAR_SIZE_PX } from "globalStylesVariables";
import { LoadingDots } from "../LoadingDots";
import { useStyles } from "./styles";

type TItemRenderer<T extends object> = (row?: T) => React.ReactNode;

interface IListProps<T extends object> {
	fetchItem: (itemIndex: number) => Promise<void> | void;
	gapSize?: number;
	getKey: (item?: T) => string;
	height?: number;
	itemHeight: number;
	itemRenderer: TItemRenderer<T>;
	items: (T | undefined)[];
	loadingElement?: React.ReactNode;
	overscan?: number;
	totalItems: number;
}

type TItemProps<T extends object> = {
	itemRenderer: TItemRenderer<T>;
	item?: T;
	style: React.CSSProperties;
	itemKey: string;
	gapSize?: number;
	loadingElement?: React.ReactNode;
};

const ItemWrapper = <T extends object>({
	itemRenderer,
	item,
	style,
	itemKey: key,
	gapSize = 0,
	loadingElement
}: TItemProps<T>) => {
	const element = useMemo(
		() => (item ? itemRenderer(item) : (loadingElement ?? <LoadingDots center />)),
		[item, itemRenderer, loadingElement]
	);

	return (
		<div style={{ ...style, padding: `${gapSize / 2}px 0px` }} key={key}>
			{element}
		</div>
	);
};

const ItemWrapperMemo = memo(ItemWrapper, (prev, next) => isEqual(prev, next)) as typeof ItemWrapper;

export const VirtualList = <T extends object>({
	className,
	fetchItem,
	gapSize = 0,
	getKey,
	height,
	itemHeight,
	itemRenderer,
	items,
	loadingElement,
	overscan = 5,
	totalItems
}: TProps<IListProps<T>>) => {
	const classes = useStyles({});

	const requestedItemsRef = useRef(new Set<number>());
	const handleFetchItem = useCallback(
		(itemIndex: number) => {
			if (items.at(itemIndex)) return;
			if (requestedItemsRef.current?.has(itemIndex)) return;
			requestedItemsRef.current?.add(itemIndex);
			fetchItem(itemIndex);
		},
		[fetchItem, items]
	);

	const { ref: bodyRef, width: bodyWidth, height: bodyHeight } = useResizeDetector();
	const outerListRef = useRef<HTMLElement | null>(null);
	const { overflowedX } = useIsOverflowed(outerListRef);

	const handleItemsRendered: (params: ListOnItemsRenderedProps) => void = useCallback(
		({ overscanStopIndex, overscanStartIndex }) => {
			for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
				handleFetchItem(i);
			}
		},
		[handleFetchItem]
	);
	const [debouncedHandleItemsRendered] = useDebounceFn(handleItemsRendered, 50);

	const renderItem = useCallback(
		({ index, style }: ListChildComponentProps<T>) => {
			const item = items.at(index);
			const key = item ? getKey(item) : `loading-${index}`;
			return (
				<ItemWrapperMemo
					itemRenderer={itemRenderer}
					item={item}
					style={style}
					itemKey={key}
					loadingElement={loadingElement}
				/>
			);
		},
		[getKey, itemRenderer, items, loadingElement]
	);

	const listHeight = useMemo(() => {
		if (height) {
			return height + (overflowedX ? SCROLLBAR_SIZE_PX : 0);
		}
		return bodyHeight;
	}, [bodyHeight, height, overflowedX]);

	return (
		<div className={classNames(classes.body, className)} ref={bodyRef}>
			{listHeight && bodyWidth ? (
				<FixedSizeList
					height={listHeight}
					width={bodyWidth}
					itemCount={totalItems}
					itemSize={itemHeight + gapSize}
					overscanCount={overscan}
					onItemsRendered={debouncedHandleItemsRendered}
					outerRef={outerListRef}>
					{renderItem}
				</FixedSizeList>
			) : null}
		</div>
	);
};

export const PaginatedVirtualList = <T extends { id: string }>({
	perPage,
	fetchPage,
	...virtualTableProps
}: Omit<TProps<IListProps<T>>, "fetchItem" | "overscan"> & {
	perPage: number;
	fetchPage: (page: number) => Promise<unknown> | void;
}) => {
	const requestedPagesRef = useRef(new Set<number>());

	useEffect(() => {
		requestedPagesRef.current.clear();
	}, [fetchPage, perPage]);

	const fetchItem = useCallback(
		(itemIndex: number) => {
			const page = Math.ceil((itemIndex + 1) / perPage);
			if (requestedPagesRef.current.has(page)) return;
			requestedPagesRef.current.add(page);
			fetchPage(page);
		},
		[fetchPage, perPage]
	);

	return <VirtualList overscan={perPage / 2} fetchItem={fetchItem} {...virtualTableProps} />;
};
