import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Title } from "components/ui/Title";
import { Button } from "components/ui/Button";
import { Typography } from "components/ui/legacy/Typography";
import { useTranslation } from "react-i18next";
import { sortByCreatedAt } from "utils/tickets/ticketActivity";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { usePersonalAccessTokensContext } from "context/personalAccessTokensContext";
import { useIsOpenState } from "hooks/useIsOpenState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { TokenWithExpirationTable } from "components/common/TokenWithExpirationTable";
import { useStyles } from "./styles";

const PREFIX = "pages.profile.changeProfileForm.personalAccessTokens";

export const PersonalAccessTokens: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { open: openDeleteModal, close: closeDeleteModal, isOpen: isDeleteModalOpen } = useIsOpenState();

	const {
		state: { personalAccessTokens, isLoading },
		actions: { loadTokens, createToken, editToken, deleteToken }
	} = usePersonalAccessTokensContext();

	const [isNew, setIsNew] = useState(false);
	const [tokenIdToDelete, setTokenIdToDelete] = useState<string | null>(null);

	const resetInputs = useCallback(() => {
		setIsNew(false);
		setTokenIdToDelete(null);
	}, []);

	const openCreateInput = useCallback(() => {
		setIsNew(true);
	}, []);

	const setTokenToDelete = useCallback(
		(id: string) => {
			setTokenIdToDelete(id);
			openDeleteModal();
		},
		[openDeleteModal]
	);

	const handleDelete = useCallback(async () => {
		if (tokenIdToDelete) {
			await deleteToken(tokenIdToDelete);
			resetInputs();
			closeDeleteModal();
		}
	}, [closeDeleteModal, deleteToken, resetInputs, tokenIdToDelete]);

	const sortedTokens = useMemo(
		() => personalAccessTokens?.sort((a, b) => sortByCreatedAt(a, b, "desc")).toList(),
		[personalAccessTokens]
	);

	useEffect(() => {
		if (!personalAccessTokens) {
			loadTokens();
		}
	}, [personalAccessTokens, loadTokens]);

	return (
		<>
			<AreYouSureModal
				onClose={closeDeleteModal}
				isOpen={isDeleteModalOpen}
				onAction={handleDelete}
				actionLabel={t("buttons.revoke")}
				title={t(`${PREFIX}.areYouSure.title`)}
				content={t(`${PREFIX}.areYouSure.content`)}
			/>
			<Title variant="h3" noBorder className={classes.title} extraMargin>
				<span>{t(`${PREFIX}.title`)}</span>
				<Button onClick={openCreateInput} variant="secondary" size="medium" prefix={<AddIcon />}>
					{t("buttons.add")}
				</Button>
			</Title>
			<div>
				{isLoading && <LoadingSpinner />}
				{personalAccessTokens?.isEmpty() && !isNew ? (
					<Typography className={classes.noForwards} variant="h2">
						{t("pages.settings.tokens.noTokens")}
					</Typography>
				) : (
					<TokenWithExpirationTable
						tokens={sortedTokens}
						onReset={resetInputs}
						onCreate={createToken}
						onEdit={editToken}
						onDelete={setTokenToDelete}
						newRow={isNew}
					/>
				)}
			</div>
		</>
	);
};
