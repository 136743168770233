import React, { RefObject, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useRuleStepperContext } from "components/pages/CreateRulePage/ruleStepperContext";
import { BasicCard } from "components/ui/BasicCard";
import { TConditionName } from "filters/condition.interface";
import { IconPrefix } from "components/ui/IconPrefix";
import { FilterSection } from "components/ui/filters/FilterSection";
import { OPERATORS as resourceNameOperators } from "filters/integrationResource/integrationResourceNameFilter";
import { OPERATORS as resourceTagsOperators } from "filters/integrationResource/integrationResourceTagsFilter";
import { OPERATORS as resourceTypeOperators } from "filters/integrationResource/integrationResourceTypeFilter";
import { OPERATORS as roleNameOperators } from "filters/integrationResourceRole/integrationResourceRoleNameFilter";
import { Conditions, TRuleType } from "models/RuleModel";
import { Typography } from "components/ui/Typography";
import { Tooltip } from "components/ui/Tooltip";
import { IconButton } from "components/ui/IconButton";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { useStyles } from "./styles";
import { ConditionExpression } from "./ConditionExpression";

const FILTERS: Exclude<TConditionName, "integration">[] = ["resourceType", "resourceName", "resourceTags", "roleName"];
const CONDITIONS_OPERATORS = new Map([
	["resourceType", resourceTypeOperators as NonNullable<Conditions["resourceType"]>["operator"][]],
	["resourceName", resourceNameOperators as NonNullable<Conditions["resourceName"]>["operator"][]],
	["resourceTags", resourceTagsOperators as NonNullable<Conditions["resourceTags"]>["operator"][]],
	["roleName", roleNameOperators as NonNullable<Conditions["roleName"]>["operator"][]]
]);

const conditionsByRuleType = new Map<TRuleType, TConditionName[]>([
	["resources", ["resourceType", "resourceName", "resourceTags"]],
	["roles", ["resourceType", "resourceName", "resourceTags", "roleName"]]
]);

export const SetConditionsSection: FC = () => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.createRule.ruleDefinition" });
	const { t: conditionsTranslation } = useTranslation("translation", { keyPrefix: "filters" });
	const classes = useStyles();
	const {
		state: { conditions, ruleType, filtersOrdered },
		actions: { upsertCondition: updateCondition, removeCondition }
	} = useRuleStepperContext();

	const filterExpressionsRefs = useRef<Map<TConditionName, RefObject<HTMLDivElement | null>>>(
		new Map<TConditionName, RefObject<HTMLDivElement | null>>([
			["resourceName", { current: null }],
			["resourceType", { current: null }],
			["resourceTags", { current: null }],
			["roleName", { current: null }]
		])
	);

	const onRemoveFilter = useCallback(
		(conditionName: TConditionName) => {
			removeCondition(conditionName);
		},
		[removeCondition]
	);

	const toggleFilterSelection = useCallback(
		(conditionName: TConditionName) => {
			const condition = conditions.get(conditionName);
			if (condition !== undefined) {
				if (!isEmpty(condition.value)) {
					return;
				}
				onRemoveFilter(conditionName);
				return;
			}
			updateCondition(conditionName, {
				operator: CONDITIONS_OPERATORS.get(conditionName)![0],
				value: []
			});
		},
		[conditions, onRemoveFilter, updateCondition]
	);

	const enableSetConditions = conditions.toSeq().some(condition => condition !== undefined);

	const filterExpressions = useMemo(
		() =>
			filtersOrdered
				.filter(filterName => conditions.get(filterName))
				.map(filterName => (
					<ConditionExpression
						key={filterName}
						conditionName={filterName as Exclude<TConditionName, "integration">}
						conditions={conditions}
						removeCondition={onRemoveFilter}
						updateCondition={updateCondition}
					/>
				))
				.toArray(),
		[filtersOrdered, conditions, onRemoveFilter, updateCondition]
	);

	const isFilterOptionSelected = useCallback(
		(option: { value: TConditionName; label: string }) => conditions.get(option.value) !== undefined,
		[conditions]
	);

	const isFilterOptionDisabled = useCallback(
		(option: { value: TConditionName; label: string }) =>
			!!ruleType && !conditionsByRuleType.get(ruleType)!.includes(option.value),
		[ruleType]
	);

	const filterOptions = useMemo(
		() =>
			FILTERS.map(conditionName => ({
				value: conditionName,
				label: conditionsTranslation(`filterNames.${conditionName}`)
			})),
		[conditionsTranslation]
	);

	return (
		<div className={classes.setConditionsSection}>
			<div className={classes.conditionContainer}>
				<div className={classes.titleContainer}>
					<div className={classes.titleText}>
						<Typography variant="body_sb">{t("conditions.title")}</Typography>
						<Typography variant="body_reg">{t("multipleSelectSubtitle")}</Typography>
					</div>
					<Tooltip content={t("conditions.explanation")} className={classes.titleExplanationTooltip}>
						<IconButton size="medium">
							<InfoIcon />
						</IconButton>
					</Tooltip>
				</div>
				<div className={classes.conditionsList}>
					{FILTERS.map(filterName => (
						<Tooltip
							key={filterName}
							content={t(`conditions.explanations.${filterName}`)}
							className={classes.conditionCardTooltip}>
							<BasicCard
								innerRef={filterExpressionsRefs.current.get(filterName)}
								onClick={() => toggleFilterSelection(filterName)}
								disabled={ruleType && !conditionsByRuleType.get(ruleType)!.includes(filterName)}
								selected={conditions.get(filterName) !== undefined}
								size="medium">
								<IconPrefix semibold size="medium" content={conditionsTranslation(`filterNames.${filterName}`)} />
							</BasicCard>
						</Tooltip>
					))}
				</div>
			</div>
			<div className={classes.setConditions}>
				<Typography className={classNames({ [classes.disabled]: !enableSetConditions })} variant="body_sb">
					{t("conditions.setConditionsTitle")}
				</Typography>
				{enableSetConditions ? (
					<FilterSection
						relation="and"
						filterOptions={filterOptions}
						filters={filterExpressions}
						isFilterOptionSelected={isFilterOptionSelected}
						isFilterOptionDisabled={isFilterOptionDisabled}
						onFilterOptionSelected={option => toggleFilterSelection(option.value)}
					/>
				) : null}
			</div>
		</div>
	);
};
