import constate from "constate";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { List, Map } from "immutable";
import { ApprovalAlgorithmModel, IApprovalAlgorithmSchema } from "models/ApprovalAlgorithmModel";
import { ApprovalAlgorithmRuleModel } from "models/ApprovalAlgorithmRuleModel";
import { ApprovalFlowEntityModel } from "models/ApprovalFlowEntityModel";
import { ApprovalFlowModel } from "models/ApprovalFlowModel";
import { ApprovalFlowRequestModel } from "models/ApprovalFlowRequestModel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IsNullError } from "utils/errors/isNullError";
import { removeRedundantSpaces } from "utils/strings";
import { useApprovalFlows } from "hooks/useApprovalFlows";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useApprovalAlgorithmsContext } from "./approvalAlgorithmsContext";
import { useApprovalFlowsContext } from "./approvalFlowContext";

type TPartialRule = Partial<ApprovalAlgorithmRuleModel>;
type TPartialFlow = Partial<ApprovalFlowModel>;
type TPartialFlowRequest = Partial<ApprovalFlowRequestModel>;
type TRulesAndFlows = List<[ApprovalAlgorithmRuleModel, ApprovalFlowModel]>;

const initialState = {
	name: "",
	rulesAndFlows: List() as TRulesAndFlows,
	id: null as string | null
};

const IntegrationOwnerApprovalFlowEntity = new ApprovalFlowEntityModel({
	identifier: "IntegrationOwner",
	type: "IntegrationOwner"
});

const fromAlgorithmAndFlows = (
	algorithm: ApprovalAlgorithmModel,
	flows: Map<string, ApprovalFlowModel>
): typeof initialState => {
	const getOrError = (id: string) => {
		const flow = flows.get(id);
		if (!flow) {
			throw IsNullError.from({
				location: "workflowEditorContext.fromAlgorithmAndFlows",
				parentObject: {
					name: "flows",
					value: flows.toJS()
				},
				requestedProperty: id
			});
		}
		return flow;
	};

	if (!algorithm.rules) {
		throw IsNullError.from({
			location: "workflowEditorContext.fromAlgorithmAndFlows",
			parentObject: {
				name: "algorithm",
				value: algorithm.toJS()
			},
			requestedProperty: "rules"
		});
	}

	return {
		name: algorithm.name,
		rulesAndFlows: List(
			(algorithm.rules || List<ApprovalAlgorithmRuleModel>())
				.sortBy(rule => rule.sortOrder)
				.map(rule => {
					return [rule, getOrError(rule.approvalFlowId)];
				})
		),
		id: algorithm.id
	};
};

const createNewEmptyFlow = (withDefault = false) => {
	return new ApprovalFlowModel({
		requests: List<ApprovalFlowRequestModel>([
			new ApprovalFlowRequestModel({
				operator: "or",
				sortOrder: 1,
				approvers: withDefault ? List([IntegrationOwnerApprovalFlowEntity]) : null
			})
		])
	});
};

const isFormNameValid = (name: string) => {
	return !(removeRedundantSpaces(name || "").length < 2 || removeRedundantSpaces(name || "").length > 50);
};

const isFormRulesValid = (rulesAndFlows: TRulesAndFlows) => {
	if (!rulesAndFlows) return false;

	if (rulesAndFlows.size === 0) return false;

	const validFlow = rulesAndFlows.every(([_rule, flow]) => {
		if (!flow?.requests) {
			return false;
		}
		return (
			flow.requests.size > 0 &&
			flow.requests.every(request => {
				if (request.approvers) return request.approvers.size > 0;
				return false;
			})
		);
	});
	if (!validFlow) return false;

	return true;
};

const useWorkflowEditor = ({
	workflowId,
	mode
}: { workflowId?: string; mode: "edit" } | { workflowId?: never; mode: "create" }) => {
	const [name, setName] = useState<string | null>(null);
	const [id, setId] = useState<string | null>(null);
	const [rulesAndFlows, setRulesAndFlows] = useState<TRulesAndFlows>(
		List([[new ApprovalAlgorithmRuleModel(), createNewEmptyFlow(true)]])
	);
	const initialStateRef = useRef<{
		name: string | null;
		rulesAndFlows: TRulesAndFlows;
		workflowId?: string;
		mode: "create" | "edit";
	}>({ name, rulesAndFlows, mode });

	const { actions: approvalFlowActions } = useApprovalFlowsContext();
	const { actions: approvalAlgorithmActions } = useApprovalAlgorithmsContext();

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const isNameValid = useMemo(() => (name ? isFormNameValid(name) : false), [name]);
	const isValid = useMemo(() => isFormRulesValid(rulesAndFlows) && isNameValid, [isNameValid, rulesAndFlows]);

	const getRuleFlowOrShowError = useCallback(
		(index: number, throwError?: boolean) => {
			const ruleFlow = rulesAndFlows.get(index);
			if (!ruleFlow) {
				const error = IsNullError.from({
					location: "workflowEditorContext.getRuleFlowOrError",
					parentObject: {
						name: "rulesAndFlows",
						value: rulesAndFlows.toJS()
					},
					requestedProperty: index
				});
				if (throwError) {
					throw error;
				}
				openGlobalErrorModal(error);
			}
			return ruleFlow;
		},
		[rulesAndFlows, openGlobalErrorModal]
	);

	const approvalAlgorithms = useApprovalAlgorithms();
	const approvalFlows = useApprovalFlows();

	const setInitial = useCallback(
		(algorithm: ApprovalAlgorithmModel, flows: Map<string, ApprovalFlowModel>) => {
			try {
				const newState = fromAlgorithmAndFlows(algorithm, flows);
				setName(newState.name);
				setId(newState.id);
				setRulesAndFlows(newState.rulesAndFlows);
				initialStateRef.current = { name: newState.name, rulesAndFlows: newState.rulesAndFlows, mode, workflowId };
			} catch (err) {
				openGlobalErrorModal(err as Error);
				return;
			}
		},
		[mode, openGlobalErrorModal, workflowId]
	);

	useEffect(() => {
		if (mode === "edit" && workflowId && workflowId !== initialStateRef.current.workflowId) {
			const algorithm = approvalAlgorithms?.get(workflowId);
			if (algorithm && approvalFlows) {
				setInitial(algorithm, approvalFlows);
			}
		}
	}, [approvalAlgorithms, approvalFlows, mode, setInitial, workflowId]);

	const reset = useCallback(() => {
		setName(null);
		setId(null);
		setRulesAndFlows(List([[new ApprovalAlgorithmRuleModel(), createNewEmptyFlow(true)]]));
	}, []);

	const setNameAction = useCallback((newName: string) => {
		setName(newName);
	}, []);

	const addNewRuleFlow = useCallback((rule = new ApprovalAlgorithmRuleModel(), flow = createNewEmptyFlow()) => {
		setRulesAndFlows(current => current.unshift([rule, flow]));
	}, []);

	const removeRuleFlow = useCallback((index: number) => {
		setRulesAndFlows(current => (!current.has(index) ? current : current.remove(index)));
	}, []);

	const updateRule = useCallback(
		(index: number, rule: TPartialRule) => {
			const ruleFlow = getRuleFlowOrShowError(index);
			if (!ruleFlow) return;
			const [previousRule, flow] = ruleFlow;
			const currentRule = previousRule.merge(rule);
			setRulesAndFlows(current => current.set(index, [currentRule, flow]));
		},
		[getRuleFlowOrShowError]
	);

	const updateFlow = useCallback(
		(index: number, flow: TPartialFlow) => {
			const ruleFlow = getRuleFlowOrShowError(index);
			if (!ruleFlow) return;
			const [rule, previousFlow] = ruleFlow;
			const currentFlow = previousFlow.merge(flow);
			setRulesAndFlows(current => current.set(index, [rule, currentFlow]));
		},
		[getRuleFlowOrShowError]
	);

	const updateFlowRequest = useCallback(
		(index: number, request: TPartialFlowRequest & { sortOrder: number }) => {
			const ruleFlow = getRuleFlowOrShowError(index);
			if (!ruleFlow) return;
			const [rule, previousFlow] = ruleFlow;
			const currentFlow = previousFlow.set(
				"requests",
				previousFlow.requests?.map(r => {
					if (r.sortOrder === request.sortOrder) {
						return r.merge(request);
					}
					return r;
				}) || List<ApprovalFlowRequestModel>([new ApprovalFlowRequestModel(request)])
			);
			setRulesAndFlows(current => current.set(index, [rule, currentFlow]));
		},
		[getRuleFlowOrShowError]
	);

	const saveWorkflow = useCallback(async () => {
		if (!isValid || !rulesAndFlows || rulesAndFlows.size === 0 || !name) return null;

		const responseFlows = await approvalFlowActions.handleApprovalFlows(rulesAndFlows.map(([_, flow]) => flow));

		const rulesWithUpdatedFlows = rulesAndFlows.map(([rule, _], index) => {
			const newFlow = responseFlows.at(index);
			if (!newFlow) {
				// returned flows amount is not equal to the one sent
				throw IsNullError.from({
					location: "workflowEditorContext.saveWorkflow",
					parentObject: {
						name: "responseFlows",
						value: responseFlows.map(flow => flow.toJS())
					},
					requestedProperty: index
				});
			}
			return rule.merge({
				directoryGroupIds: rule.directoryGroupIds?.length > 0 ? rule.directoryGroupIds : undefined,
				sortOrder: index + 1,
				approvalFlowId: newFlow.id
			});
		});

		const flowsMap = Map<string, ApprovalFlowModel>(
			responseFlows.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {})
		);

		const algorithm: IApprovalAlgorithmSchema = { id: id || "", name, rules: rulesWithUpdatedFlows.toArray() };

		const responseAlgorithm = id
			? await approvalAlgorithmActions.updateApprovalAlgorithm(algorithm)
			: await approvalAlgorithmActions.saveApprovalAlgorithm(algorithm);

		if (!responseAlgorithm) return; // errors are handled in the action

		const newState = fromAlgorithmAndFlows(responseAlgorithm, flowsMap);
		setName(newState.name);
		setId(newState.id);
		setRulesAndFlows(newState.rulesAndFlows);
		return [responseAlgorithm, flowsMap];
	}, [approvalAlgorithmActions, approvalFlowActions, id, isValid, name, rulesAndFlows]);

	const changeRuleOrder = useCallback(
		(index: number, vector: 1 | -1) => {
			const newIndex = index + vector;
			if (newIndex >= 0 && newIndex < rulesAndFlows.size) {
				const currentRule = getRuleFlowOrShowError(index);
				if (!currentRule) return;
				const replaceRule = getRuleFlowOrShowError(newIndex);
				if (!replaceRule) return;
				const newRulesAndFlows = rulesAndFlows.set(index, replaceRule).set(newIndex, currentRule);
				setRulesAndFlows(newRulesAndFlows);
			}
		},
		[getRuleFlowOrShowError, rulesAndFlows]
	);

	const actions = useMemo(
		() => ({
			addNewRuleFlow,
			changeRuleOrder,
			getRuleFlowOrShowError,
			removeRuleFlow,
			reset,
			saveWorkflow,
			setName: setNameAction,
			updateFlow,
			updateFlowRequest,
			updateRule
		}),
		[
			addNewRuleFlow,
			changeRuleOrder,
			getRuleFlowOrShowError,
			removeRuleFlow,
			reset,
			saveWorkflow,
			setNameAction,
			updateFlow,
			updateFlowRequest,
			updateRule
		]
	);

	return {
		state: {
			name,
			id,
			rulesAndFlows,
			isValid,
			isNameValid,
			mode,
			isModified: rulesAndFlows !== initialStateRef.current.rulesAndFlows || name !== initialStateRef.current.name
		},
		actions
	};
};

export const [WorkflowEditorProvider, useWorkflowEditorContext] = constate(useWorkflowEditor);
