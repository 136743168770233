import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Title } from "components/ui/Title";
import { Button } from "components/ui/Button";
import { Typography } from "components/ui/legacy/Typography";
import { useTranslation } from "react-i18next";
import { Table } from "components/ui/Table";
import { ForwardModel } from "models/ForwardModel";
import { getForwards, TForwardType } from "api/forwards";
import { Forward } from "./components/Forward";
import { useStyles } from "./styles";
import type { Map } from "immutable";

export const Forwards: FC<{ type: TForwardType }> = ({ type }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [newForward, setNewForward] = useState<ForwardModel | null>(null);
	const [editForwardId, setEditForwardId] = useState<string>("");
	const [forwards, setForwards] = useState<Map<string, ForwardModel>>();

	const forwardsList = useMemo(() => forwards?.toList(), [forwards]);

	useEffect(() => {
		(async () => setForwards(await getForwards(type)))();
	}, [type]);

	const handleCancel = useCallback(() => {
		setNewForward(null);
		setEditForwardId("");
	}, []);

	const createForward = useCallback(() => {
		handleCancel();
		setNewForward(new ForwardModel({}));
	}, [handleCancel]);

	const editForward = useCallback(
		(forwardId: string) => {
			handleCancel();
			setEditForwardId(forwardId);
		},
		[handleCancel]
	);

	const existingForwardersIds = useMemo(
		() => forwardsList?.toArray().map(({ forwarderUserId }) => forwarderUserId) || [],
		[forwardsList]
	);

	const onForwardDeleted = useCallback((forwardId: string) => setForwards(current => current?.delete(forwardId)), []);
	const onForwardSaved = useCallback(
		(forward: ForwardModel) => setForwards(current => current?.set(forward.id, forward)),
		[]
	);

	useEffect(() => handleCancel(), [handleCancel, forwards]);

	const isLoading = useMemo(() => !forwardsList, [forwardsList]);
	const isEmpty = useMemo(() => forwardsList && forwardsList.isEmpty() && !newForward, [forwardsList, newForward]);

	return (
		<>
			<Title variant="h3" noBorder className={classes.title} extraMargin>
				<span>{t(`pages.settings.forwards.${type}.title`)}</span>
				<Button onClick={createForward} variant="secondary" size="medium">
					{t("buttons.add")}
				</Button>
			</Title>
			<div>
				{isLoading && <LoadingSpinner />}
				{!isLoading && isEmpty && (
					<Typography className={classes.noForwards} variant="h2">
						{t("pages.settings.forwards.noForwards")}
					</Typography>
				)}
				{!isLoading && !isEmpty && (
					<Table gridColumns="1fr 1fr 15rem">
						<Table.Row>
							<Table.Header>
								<Typography variant="small">{t(`pages.settings.forwards.${type}.forwarderHeader`)}</Typography>
							</Table.Header>
							<Table.Header>
								<Typography variant="small">{t("pages.settings.forwards.targetHeader")}</Typography>
							</Table.Header>
						</Table.Row>
						{newForward && (
							<Forward
								forward={newForward}
								create
								onCancel={handleCancel}
								existingForwardersIds={existingForwardersIds}
								onSave={onForwardSaved}
								type={type}
							/>
						)}
						{forwardsList?.map(forward => (
							<Forward
								key={forward.id}
								forward={forward}
								onCancel={handleCancel}
								edit={editForwardId === forward.id}
								enterEdit={editForward}
								onDelete={onForwardDeleted}
								onSave={onForwardSaved}
								type={type}
							/>
						))}
					</Table>
				)}
			</div>
		</>
	);
};
