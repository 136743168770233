import React from "react";
import { ExportIcon } from "components/ui/Icons/ExportIcon";
import { Chip, TChipVariant, TChipSize } from "../Chip";
import type { TIconProps } from "components/ui/Icon";

interface IExternalLinkChipProps {
	PrefixIcon?: FC<TIconProps>;
	readonly?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
	onChipClick?: (e: React.MouseEvent) => void;
	onSuffixIconClick?: (e: React.MouseEvent) => void;
}

export const ExternalLinkChip: FC<IExternalLinkChipProps> = ({
	innerRef,
	className,
	PrefixIcon,
	children,
	size = "medium",
	readonly = false,
	variant = "regular",
	onSuffixIconClick,
	onChipClick
}) => {
	return (
		<Chip
			readonly={readonly}
			variant={variant}
			size={size}
			PrefixIcon={PrefixIcon}
			SuffixIcon={ExportIcon}
			onClick={onChipClick}
			className={className}
			innerRef={innerRef}
			onSuffixIconClick={onSuffixIconClick}>
			{children}
		</Chip>
	);
};
