import { useEffect, useMemo } from "react";
import { Set } from "immutable";
import { useUsersContext } from "context/usersContext";
import { notEmpty } from "utils/comparison";
import { devLog } from "utils/devtools/devLogging";

export const useMultiUsers = (
	ids: (string | undefined | null)[],
	includeDeleted = true,
	allowPartialResults = false
) => {
	const {
		state: { users },
		actions: { loadUsers }
	} = useUsersContext();

	const validIds = useMemo(() => {
		const invalidIds = ids.filter(id => id && typeof id !== "string");
		if (invalidIds.length) {
			devLog({ level: "warn", message: "Invalid multi get ids", extra: invalidIds });
		}
		return Set(ids.filter(id => id && typeof id === "string").filter(notEmpty));
	}, [ids]);

	useEffect(() => {
		const missingIds = validIds.filter(id => !users.has(id));
		if (missingIds.size) {
			loadUsers(missingIds.toArray());
		}
	}, [ids, loadUsers, users, validIds]);

	const filteredUsers = useMemo(() => {
		const existingUsers = users.filter((_, id) => validIds.has(id));
		if (existingUsers.size !== validIds.size && !allowPartialResults) return null;
		if (!includeDeleted) return existingUsers.filter(notEmpty).filter(({ isDeleted }) => !isDeleted);
		return existingUsers.filter(notEmpty);
	}, [includeDeleted, allowPartialResults, users, validIds]);

	// if null, still loading
	return filteredUsers;
};
