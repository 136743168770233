import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceRoleId" as const;

const defaults = {
	name: filterName,
	value: [] as string[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleIdFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceRoleIdFilter | null {
		const integrationResourceRoleId = uniq(urlSearchParams.getAll("integrationResourceRoleId"));
		const integrationResourceRoleIdOperator = urlSearchParams.get("integrationResourceRoleIdOperator") || undefined;
		if (integrationResourceRoleId.length === 0 || !validateFilterEqualityOperator(integrationResourceRoleIdOperator)) {
			return null;
		}
		return new IntegrationResourceRoleIdFilter({
			value: integrationResourceRoleId,
			operator: integrationResourceRoleIdOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceRoleId", value);
		}
		params.append("integrationResourceRoleIdOperator", this.operator);
		return params;
	}
}
