import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { DeniedIcon } from "components/ui/Icons/DeniedIcon";
import { List, Map } from "immutable";
import { ApprovalStepEntity } from "../ApprovalEntityCard";
import { useStyles } from "./styles";
import type { TFullApprovalRequest } from "components/common/RequestDetails";
import type { TTicketApprovalResponseType } from "models/TicketApprovalResponseModel";

const THEME_COLORS = {
	APPROVED: "var(--color-green-400)",
	PENDING: "var(--color-purple-500)",
	DECLINED: "var(--color-red-700)"
};

interface IProps {
	approvalRequests: List<TFullApprovalRequest>;
	responsesByUserId: Map<string, TTicketApprovalResponseType>;
	active: boolean;
	sortOrder: number;
}

const StepIcon: FC<{ status: TFullApprovalRequest["status"] }> = ({ status, className }) => {
	if (status === "pending") return null;
	const Icon = status === "approved" ? DoneCircleIcon : DeniedIcon;

	return <Icon size="large" className={className} />;
};

export const ApprovalProcessStep = ({ approvalRequests, responsesByUserId, sortOrder, active }: IProps) => {
	const disabled = !(active || approvalRequests.some(request => request.active));
	const { status, color } = useMemo(() => {
		if (approvalRequests.some(request => request.status === "declined"))
			return { status: "declined" as TFullApprovalRequest["status"], color: THEME_COLORS.DECLINED };
		if (approvalRequests.every(request => request.status === "approved"))
			return { status: "approved" as TFullApprovalRequest["status"], color: THEME_COLORS.APPROVED };

		return { status: "pending" as TFullApprovalRequest["status"], color: THEME_COLORS.PENDING };
	}, [approvalRequests]);

	const classes = useStyles({ color });
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails.approvalProcess.step" });

	const isAutomatic = useMemo(
		() => approvalRequests.every(request => request.approvalEntities.every(entity => entity.type === "Automatic")),
		[approvalRequests]
	);

	const requestsClean = useMemo(
		() =>
			isAutomatic
				? approvalRequests
				: // If not everything is automatic, we don't want to show the automatic entities
					approvalRequests.filter(request => request.approvalEntities.get(0)?.type !== "Automatic"),
		[approvalRequests, isAutomatic]
	);

	return (
		<div className={classNames(classes.stepContainer, { [classes.disabled]: disabled })}>
			{isAutomatic ? (
				<ApprovalStepEntity
					entity={approvalRequests.get(0)!.approvalEntities.get(0)!}
					responsesByUserId={responsesByUserId}
				/>
			) : (
				<>
					<div className={classes.stepTitleContainer}>
						<div className={classes.stepTitle}>
							<Typography variant="body_sb" className={classes.stepTitleText}>
								{t("title", { number: sortOrder })}
							</Typography>
							<StepIcon status={status} className={classes.stepTitleIcon} />
						</div>
						<div className={classes.divider} />
					</div>
					<div className={classes.stepEntitiesGroupsContainer}>
						{requestsClean.map((request, index) => (
							<React.Fragment key={request.id}>
								<div
									className={classNames({ [classes.stepEntitiesGroupContainer]: request.approvalEntities.size > 1 })}>
									{request.approvalEntities.size === 1 ? (
										<ApprovalStepEntity
											entity={request.approvalEntities.get(0)!}
											responsesByUserId={responsesByUserId}
										/>
									) : (
										<>
											<Typography variant="text_sm_sb">{t(`entitiesGroupTitle.${request.operator}`)}</Typography>
											<div className={classes.stepEntitiesGroup}>
												{request.approvalEntities.map(entity => (
													<ApprovalStepEntity
														request={request}
														key={entity.id}
														entity={entity}
														responsesByUserId={responsesByUserId}
													/>
												))}
											</div>
										</>
									)}
								</div>
								{index !== requestsClean.size - 1 && (
									<Typography variant="text_sm_sb" className={classes.groupsSeparator}>
										{t("separator")}
									</Typography>
								)}
							</React.Fragment>
						))}
					</div>
				</>
			)}
		</div>
	);
};
