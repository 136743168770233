import React, { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { Typography } from "components/ui/legacy/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { useAccessReviewSubordinate } from "hooks/useAccessReviewSubordinate";
import { useAccessReviewReport } from "hooks/useAccessReviewReport";
import { SubordinatePermissionsReviewTable } from "components/common/SubordinatePermissionsReviewTable";
import { Loading } from "components/ui/Loading";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { RequestDetails } from "components/common/RequestDetails";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";

export const AdminSubordinateAccessReviewPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { subordinateId } = useParams<{ subordinateId: string }>();
	const navigate = useNavigate();
	const { accessReviewSubordinate, isGetLoading: subordinateLoading } = useAccessReviewSubordinate(subordinateId);
	const { accessReviewReport, getActionLoading: reportLoading } = useAccessReviewReport(
		accessReviewSubordinate?.accessReviewReportId || ""
	);
	const {
		state: { accessReviews },
		actions: { loadAccessReviews }
	} = useAccessReviewsContext();
	const subordinateUser = useUser(accessReviewSubordinate?.userId);

	useEffect(() => {
		if (!accessReviews) {
			loadAccessReviews();
		}
	}, [accessReviews, loadAccessReviews]);

	const sortedSubordinates = useMemo(() => accessReviewReport?.sortedSubordinates || [], [accessReviewReport]);

	const onChangeSubordinate = useCallback(
		(id: string) => {
			if (id !== subordinateId) {
				navigate(`/accessReview/subordinates/${id}`);
			}
		},
		[navigate, subordinateId]
	);

	const currentAccessReview = accessReviews?.get(accessReviewReport?.accessReviewId || "");

	const getContent = useCallback(() => {
		if (currentAccessReview && accessReviewSubordinate && accessReviewReport) {
			return (
				<div className={classes.permissionList}>
					<SubordinatePermissionsReviewTable
						subordinate={accessReviewSubordinate}
						report={accessReviewReport}
						onChangeSubordinate={onChangeSubordinate}
						sortedSubordinates={sortedSubordinates}
						isAdmin
						immediateRevoke={currentAccessReview.immediateRevoke}
					/>
				</div>
			);
		}
		return (
			<Typography className={classes.center} variant="h2">
				{t("pages.adminSubordinateReview.subordinateNotFound")}
			</Typography>
		);
	}, [
		accessReviewReport,
		accessReviewSubordinate,
		classes,
		currentAccessReview,
		onChangeSubordinate,
		sortedSubordinates,
		t
	]);

	const breadcrumbs = useMemo(() => {
		if (!accessReviewReport || !subordinateUser) return [];

		return [
			{
				title: t("navigation.accessReview"),
				url: "/accessReview"
			},
			{
				title: t("common.accessReview.reportFrom", { date: accessReviewReport.createdAt }),
				url: `/accessReview/reports/${accessReviewReport.id}`
			},
			{
				title: subordinateUser.fullName
			}
		];
	}, [accessReviewReport, subordinateUser, t]);

	return (
		<PageTemplate className={classNames(className)} subPage>
			<RequestDetails />
			<PageTemplate.Title className={classes.header}>
				<Breadcrumbs parts={breadcrumbs} />
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.content}>
				<Loading loading={subordinateLoading || reportLoading} render={getContent} />
			</PageTemplate.Content>
		</PageTemplate>
	);
};
