import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { Typography } from "components/ui/Typography";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { IconPrefix } from "components/ui/IconPrefix";
import { useStyles } from "./styles";
import type { TCardProps } from "../Card";

export type TResourceAndRoleCountCardBodyProps = {
	resourcesCount?: number | string;
	resourcesLabel?: string;
	rolesCount?: number | string;
	rolesLabel?: string;
} & Pick<TCardProps, "size">;

const ResourceAndRoleCountCardBody: FC<TResourceAndRoleCountCardBodyProps> = props => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { resourcesCount, resourcesLabel, rolesCount, rolesLabel, size } = props;

	const { resourcesText, rolesText } = useMemo(() => {
		const resourcesText = resourcesLabel || t("common.bundleCard.resources");
		const rolesText = rolesLabel || t("common.bundleCard.roles");
		return { resourcesText, rolesText };
	}, [resourcesLabel, rolesLabel, t]);

	return (
		<>
			{typeof resourcesCount !== "undefined" ? (
				<div className={classes.contentRow}>
					<IconPrefix
						className={classes.resourcesText}
						Icon={ResourcesIcon}
						content={resourcesText}
						size={size === "small" ? "tiny" : "small"}
					/>
					<Typography variant="text_title_sb">{resourcesCount}</Typography>
				</div>
			) : null}
			{typeof rolesCount !== "undefined" ? (
				<div className={classes.contentRow}>
					<IconPrefix
						className={classes.resourcesText}
						Icon={RoleIcon}
						content={rolesText}
						size={size === "small" ? "tiny" : "small"}
					/>
					<Typography variant="text_title_sb">{rolesCount}</Typography>
				</div>
			) : null}
		</>
	);
};

const memoizedResourceAndRoleCountCardBody = memo(ResourceAndRoleCountCardBody);
export { memoizedResourceAndRoleCountCardBody as ResourceAndRoleCountCardBody };
