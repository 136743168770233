import get from "lodash/get";
import set from "lodash/set";
import { isImmutable } from "immutable";

type TKey = string | number | symbol;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasPropertyOf = <T, K extends TKey>(obj: T, prop: K): obj is T & Record<K, any> => {
	if ("hasOwn" in Object) {
		return (Object.hasOwn as (obj: T, prop: TKey) => boolean)(obj, prop);
	}
	return Object.prototype.hasOwnProperty.call(obj, prop);
};

export const requirePropertyOf = <T, K extends TKey>(obj: T, prop: K): T extends Record<K, infer R> ? R : unknown => {
	if (
		hasPropertyOf(obj, prop) ||
		hasPropertyOf(Object.getPrototypeOf(obj), prop) ||
		(isImmutable(obj) && obj.has(prop))
	) {
		return isImmutable(obj) ? obj.get(prop) || get(obj, prop) : get(obj, prop);
	}
	throw new Error(`Object does not have property ${String(prop)}`);
};

type UnionToIntersection<U> = (U extends unknown ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

const isValueObject = (obj: unknown): obj is Record<string, unknown> =>
	typeof obj === "object" && obj !== null && !Array.isArray(obj);

export const safeMerge = <T extends Record<string, unknown>[]>(...sources: [...T]): UnionToIntersection<T[number]> => {
	const newObj = {};
	const addAttributesToNewObj = (obj: object) => {
		for (const key of Object.keys(obj)) {
			if (hasPropertyOf(obj, key)) {
				const currentValue = get(newObj, key);
				const nextValue = get(obj, key);
				const newValue =
					isValueObject(currentValue) && isValueObject(nextValue) ? safeMerge(currentValue, nextValue) : nextValue;
				set(newObj, key, newValue);
			}
		}
	};
	for (const source of sources) {
		addAttributesToNewObj(source);
	}
	return newObj as UnionToIntersection<T[number]>;
};
