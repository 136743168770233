import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BambooHRLogo } from "components/ui/systemLogos/BambooHRLogo";
import { HibobLogo } from "components/ui/systemLogos/HibobLogo";
import { FreshteamLogo } from "components/ui/systemLogos/FreshteamLogo";
import { addHRIntegration, getMergeLinkToken, testHRIntegration, THRType } from "api/companyExternalIntegrations";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useTranslation } from "react-i18next";
import { List } from "immutable";
import { useCompanyContext } from "context/companyContext";
import { useLoadingState } from "hooks/useLoadingState";
import { useWithGlobalErrorHandler } from "hooks/useWithGlobalErrorHandler";
import { Chip } from "components/ui/chips/Chip";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { WorkdayLogo } from "components/ui/systemLogos/WorkdayLogo";
import classNames from "classnames";
import { InProgressIcon } from "components/ui/Icons/InProgressIcon";
import { Button } from "components/ui/Button";
import { IntegrationRow } from "../../../IntegrationRow";
import { useStyles } from "./styles";

const SYNCING_ERROR_ID = "hrIntegration.syncing";

const INTEGRATIONS_ICONS = new Map<THRType, FC>([
	["BambooHR", BambooHRLogo],
	["Hibob", HibobLogo],
	["Freshteam", FreshteamLogo],
	["Workday", WorkdayLogo]
]);

type THRIntegrationProps = {
	integration: THRType;
	integratedToHRs: List<THRType>;
	afterIntegrate: (integration: THRType) => void;
};

type TTestStatus = "syncing" | "working" | "notWorking" | "error" | "testing" | null;

const TestStatus: FC<{ testStatus: TTestStatus }> = ({ testStatus }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	if (testStatus === null) {
		return null;
	}

	const [Icon, statusClass] =
		testStatus === "working"
			? [DoneCircleIcon.Green, classes.positive]
			: testStatus === "notWorking" || testStatus === "error"
				? [CloseCircleIcon.Red, classes.negative]
				: [InProgressIcon, undefined];

	const text = t(`pages.settings.integrations.hrStatuses.${testStatus}`);

	return (
		<div className={classes.statusChipContainer}>
			<Chip size="small" variant="regular" PrefixIcon={Icon} className={classNames(classes.statusChip, statusClass)}>
				{text}
			</Chip>
		</div>
	);
};

export const HRIntegration: FC<THRIntegrationProps> = ({ integration, integratedToHRs, afterIntegrate }) => {
	const { t } = useTranslation();

	const isIntegrated = useMemo(() => integratedToHRs.includes(integration), [integratedToHRs, integration]);
	const [uuid, setUuid] = useState<string | null>(null);
	const [linkToken, setLinkToken] = useState<string>("");
	const [hasOpenedSinceLastClick, setHasOpenedSinceLastClick] = useState(false);

	const [testStatus, setTestStatus] = useState<TTestStatus>(null);

	const { withLoader, isLoading } = useLoadingState();

	const withGlobalErrorHandler = useWithGlobalErrorHandler();

	const {
		actions: { removeHrIntegration }
	} = useCompanyContext();

	const onMergeSuccess = useCallback(
		async (publicToken: string) => {
			if (!uuid) {
				return;
			}
			await withGlobalErrorHandler(withLoader(addHRIntegration(integration, publicToken, uuid)));
			afterIntegrate(integration);
		},
		[afterIntegrate, integration, uuid, withGlobalErrorHandler, withLoader]
	);

	const { open, isReady } = useMergeLink({
		linkToken,
		onSuccess: onMergeSuccess
	});

	useEffect(() => {
		if (isReady && linkToken && !isIntegrated && !hasOpenedSinceLastClick) {
			open();
			setHasOpenedSinceLastClick(true);
		}
	}, [open, isReady, linkToken, isIntegrated, hasOpenedSinceLastClick]);

	const connect = useCallback(async () => {
		if (!isIntegrated) {
			const mergeToken = await getMergeLinkToken(integration);
			setUuid(mergeToken.uuid);
			setLinkToken(mergeToken.linkToken);
			setHasOpenedSinceLastClick(false);
		}
	}, [integration, isIntegrated]);

	const disconnect = useCallback(async () => {
		if (isIntegrated) {
			setTestStatus(null);
			await withGlobalErrorHandler(withLoader(removeHrIntegration(integration)));
		}
	}, [integration, isIntegrated, removeHrIntegration, withGlobalErrorHandler, withLoader]);

	const test = useCallback(async () => {
		try {
			setTestStatus("testing");
			const result = await withLoader(testHRIntegration(integration));
			if (result.valid === true) {
				setTestStatus("working");
			} else if (result.errorId === SYNCING_ERROR_ID) {
				setTestStatus("syncing");
			} else {
				setTestStatus("notWorking");
			}
		} catch {
			setTestStatus("error");
		}
	}, [integration, withLoader]);

	return (
		<IntegrationRow
			isIntegrated={isIntegrated}
			SystemLogo={INTEGRATIONS_ICONS.get(integration)}
			integration={integration}
			additionalStatus={<TestStatus testStatus={testStatus} />}
			actions={
				isIntegrated ? (
					<>
						<Button variant="text" onClick={disconnect} size="small" loading={isLoading}>
							{t("pages.settings.integrations.disconnect")}
						</Button>
						<Button variant="text" onClick={test} size="small" loading={isLoading}>
							{t("pages.settings.integrations.test")}
						</Button>
					</>
				) : (
					<Button variant="text" onClick={connect} size="small" loading={isLoading}>
						{t("pages.settings.integrations.connect")}
					</Button>
				)
			}
		/>
	);
};
