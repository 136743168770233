import React, { useCallback } from "react";
import { Select } from "components/ui/Select";
import { FilterHeader } from "../FilterHeader";
import { useGetOperatorLabel } from "../../useRenderOperatorLabel";
import { SelectRowEmptyState } from "../SelectRowEmptyState";
import { useStyles } from "./styles";
import type { TSingleSelectFilterProps } from "../../types";
import type { TFilterOperator } from "types/filters";

export function SingleSelectFilter<T>({
	className,
	emptyState,
	filter,
	getMoreOptions,
	getOperatorLabel: propGetOperatorLabel,
	groupBy,
	innerRef,
	inputPlaceholder,
	isLoading,
	onOperatorSelect,
	onOptionSelect,
	onRemoveFilter,
	onReset,
	operators,
	options,
	renderOption,
	renderSelected,
	selected,
	selectedOperator,
	title
}: TProps<TSingleSelectFilterProps<T>>) {
	const classes = useStyles();
	const getOperatorLabel = useGetOperatorLabel();

	const handleOperatorChange = useCallback(
		(operator: TFilterOperator | null) => {
			if (operator) {
				onOperatorSelect(operator);
			}
		},
		[onOperatorSelect]
	);

	const handleValueChange = useCallback(
		(option: T | null) => {
			if (option) {
				onOptionSelect(option);
			}
		},
		[onOptionSelect]
	);

	const onInputChange = useCallback(
		(event: { target: { value: string } }) => {
			getMoreOptions?.(event.target.value);
		},
		[getMoreOptions]
	);

	const isOptionEqualToValue = useCallback((option: T) => selected === option, [selected]);

	return (
		<>
			<FilterHeader
				className={className}
				title={title}
				onRemoveFilter={onRemoveFilter}
				onReset={onReset}
				innerRef={innerRef}
				hasSelection={!!selected}
				inputs={
					<>
						<Select
							className={classes.operatorSelect}
							getOptionLabel={propGetOperatorLabel ?? getOperatorLabel}
							hideClear
							onChange={handleOperatorChange}
							options={operators}
							value={selectedOperator}
						/>
						<Select
							filter={filter}
							getOptionLabel={null}
							groupBy={groupBy}
							hideClear
							isOptionEqualToValue={isOptionEqualToValue}
							loading={isLoading}
							onChange={handleValueChange}
							onInputChange={onInputChange}
							options={options}
							placeholder={inputPlaceholder}
							renderOption={renderOption}
							resetValueOnSelect
							sort={null}
							value={null}
						/>
					</>
				}
			/>
			<div className={classes.content}>
				{selected ? renderSelected(selected) : <SelectRowEmptyState content={emptyState} />}
			</div>
		</>
	);
}
