import { apiReq } from "utils/api/apiReq";
import { NotificationIntegrationModel } from "models/NotificationIntegrationModel";

export const getNotificationIntegrations = async (): Promise<NotificationIntegrationModel[]> => {
	const { data } = await apiReq("GET", "/v1/notificationIntegrations");

	return data.map((item: unknown) => NotificationIntegrationModel.fromServerData(item));
};

export const deleteNotificationIntegration = async (notificationIntegrationId: string) => {
	const { data } = await apiReq("DELETE", `/v1/notificationIntegrations/${notificationIntegrationId}`);

	return NotificationIntegrationModel.fromServerData(data);
};
