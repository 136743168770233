import { Select } from "components/ui/Select";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DURATION_OPTIONS, TTicketDuration } from "utils/durationsOptions";
import { useStyles } from "./styles";
import type { IFilterProps } from "../../";

const TRANSLATION_PREFIX = "common.ticketFilters";

export const DurationFilter: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const minDuration = useMemo(
		() =>
			ticketFilters.accessDuration?.min
				? {
						value: ticketFilters.accessDuration.min,
						label: t(`common.durations.${ticketFilters.accessDuration.min}`)
					}
				: null,
		[t, ticketFilters.accessDuration?.min]
	);
	const maxDuration = useMemo(
		() =>
			ticketFilters.accessDuration?.max
				? {
						value: ticketFilters.accessDuration.max,
						label: t(`common.durations.${ticketFilters.accessDuration.max}`)
					}
				: null,
		[t, ticketFilters.accessDuration?.max]
	);

	const minDurationsOptions = useMemo(
		() =>
			DURATION_OPTIONS.filter(duration => duration !== -1)
				.sort((a, b) => a - b)
				.map(duration => ({ value: duration, label: t(`common.durations.${duration}`) })),
		[t]
	);
	const maxDurationsOptions = useMemo(
		() =>
			DURATION_OPTIONS.filter(duration => duration !== -1 && (minDuration?.value || 0) <= duration)
				.sort((a, b) => a - b)
				.map(duration => ({
					value: duration,
					label: t(`common.durations.${duration}`)
				})),
		[minDuration?.value, t]
	);

	const changeMinDuration = useCallback(
		(duration: { value: TTicketDuration; label: string } | null) => {
			onFiltersChange(current => {
				if (duration && current.accessDuration)
					return {
						...current,
						accessDuration: {
							min: duration.value,
							max:
								current.accessDuration.max && current.accessDuration.max < duration.value
									? undefined
									: current.accessDuration.max
						}
					};
				return { ...current, accessDuration: { min: duration?.value, max: current.accessDuration?.max } };
			});
		},
		[onFiltersChange]
	);
	const changeMaxDuration = useCallback(
		(duration: { value: TTicketDuration; label: string } | null) => {
			onFiltersChange(current => ({
				...current,
				accessDuration: { min: current.accessDuration?.min, max: duration?.value }
			}));
		},
		[onFiltersChange]
	);

	return (
		<div className={classes.range}>
			<Select
				options={minDurationsOptions}
				label={t(`${TRANSLATION_PREFIX}.minDuration`)}
				value={minDuration}
				onChange={changeMinDuration}
				sort={null}
			/>
			<Select
				options={maxDurationsOptions}
				label={t(`${TRANSLATION_PREFIX}.maxDuration`)}
				value={maxDuration}
				onChange={changeMaxDuration}
				sort={null}
			/>
		</div>
	);
};
