import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { getUniqueKey } from "utils/ui/getUniqueKey";
import { Chip } from "components/ui/chips/Chip";
import { DropdownIconButton } from "components/ui/DropdownIconButton";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useIsOpenState } from "hooks/useIsOpenState";
import { IconButton } from "components/ui/IconButton";
import { FilterExpression } from "../FilterExpression";
import { useStyles } from "./styles";
import type { TFilterExpressionProps } from "../FilterExpression/types";
import type { TFilterRelation } from "types/filters";

export type TFilterOption<TValue extends string = string> = { value: TValue; label: string };

type TAddButtonProps<TOptionValue extends string> = {
	filterOptions: TFilterOption<TOptionValue>[];
	isFilterOptionSelected: (filterOption: TFilterOption<TOptionValue>) => boolean;
	isFilterOptionDisabled?: (filterOption: TFilterOption<TOptionValue>) => boolean;
	onFilterOptionSelected: (filterOption: TFilterOption<TOptionValue>) => void;
};

type TFilterSectionProps<T = unknown, TOptionValue extends string = string> = TAddButtonProps<TOptionValue> & {
	filters: React.ReactNode[] | TFilterExpressionProps<T>[];
	relation?: TFilterRelation;
	showRelation?: boolean;
};

const FILTER_TYPES = [
	"datetime",
	"multiChoice",
	"singleChoice",
	"multiSelect",
	"singleSelect",
	"multiText",
	"multiSelectAndText"
];

function isFilterExpressions<T = unknown>(
	filters: React.ReactNode[] | TFilterExpressionProps<T>[]
): filters is TFilterExpressionProps<T>[] {
	return filters.every(
		filter => filter && typeof filter === "object" && "type" in filter && FILTER_TYPES.includes(filter.type.toString())
	);
}

function AddButton<TOptionValue extends string = string>({
	filterOptions,
	isFilterOptionSelected,
	isFilterOptionDisabled,
	onFilterOptionSelected
}: TProps<TAddButtonProps<TOptionValue>>) {
	const classes = useStyles();
	const { close, open, isOpen } = useIsOpenState();

	const renderedOptions = useMemo(() => {
		return filterOptions.map(option => {
			const isSelected = isFilterOptionSelected(option);
			const isDisabled = isFilterOptionDisabled && isFilterOptionDisabled(option);
			const onClick = () => {
				if (!isDisabled) {
					onFilterOptionSelected(option);
					close();
				}
			};

			return (
				<div
					key={option.value}
					className={classNames(classes.option, { [classes.selected]: isSelected, [classes.disabled]: isDisabled })}
					onClick={onClick}>
					<Typography variant={isSelected ? "text_title_sb" : "text_reg"}>{option.label}</Typography>
				</div>
			);
		});
	}, [filterOptions, isFilterOptionSelected, isFilterOptionDisabled, classes, onFilterOptionSelected, close]);

	const selectedOptions = useMemo(
		() => new Set(filterOptions.filter(isFilterOptionSelected)),
		[filterOptions, isFilterOptionSelected]
	);

	const nonDisabledOptions = useMemo(
		() => filterOptions.filter(option => !isFilterOptionDisabled?.(option)),
		[filterOptions, isFilterOptionDisabled]
	);

	const lastOptionAddButton = useMemo(() => {
		const lastSelectedOption = nonDisabledOptions.find(option => !selectedOptions.has(option))!;
		const onClick = () => onFilterOptionSelected(lastSelectedOption);

		return (
			<IconButton className={classes.addButton} onClick={onClick} variant="primary">
				<AddIcon size="medium" />
			</IconButton>
		);
	}, [selectedOptions, nonDisabledOptions, onFilterOptionSelected, classes.addButton]);

	if (selectedOptions.size === nonDisabledOptions.length) {
		return null;
	}

	if (selectedOptions.size === nonDisabledOptions.length - 1) {
		return lastOptionAddButton;
	}

	return (
		<DropdownIconButton
			Icon={AddIcon}
			className={classes.addButton}
			dropdown={<div className={classes.dropdown}>{renderedOptions}</div>}
			onClick={open}
			onClose={close}
			position="bottom"
			open={isOpen}
		/>
	);
}

export function FilterSection<TOptionValue extends string = string>({
	className,
	filterOptions,
	filters,
	id,
	innerRef,
	isFilterOptionSelected,
	isFilterOptionDisabled,
	onFilterOptionSelected,
	relation = "and",
	showRelation = false
}: TProps<TFilterSectionProps<unknown, TOptionValue>>) {
	const { t } = useTranslation();
	const classes = useStyles();
	const filterElements = useMemo(() => {
		const filtersAsElements = isFilterExpressions(filters)
			? filters.map(filterProps => <FilterExpression key={getUniqueKey(filterProps)} {...filterProps} />)
			: filters;
		return showRelation
			? filtersAsElements.map((filter, index) => {
					const relationText = t(`filters.filterRelations.${relation}`, { context: "capital" });
					return (
						<React.Fragment key={`${relation}-${getUniqueKey(filter, index.toString())}`}>
							{index > 0 ? (
								<Chip size="medium" variant="regular">
									{relationText}
								</Chip>
							) : null}
							{filter}
						</React.Fragment>
					);
				})
			: filtersAsElements;
	}, [filters, relation, showRelation, t]);

	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			{filterElements}
			{filterElements.length > 0 ? (
				<AddButton
					filterOptions={filterOptions}
					isFilterOptionSelected={isFilterOptionSelected}
					onFilterOptionSelected={onFilterOptionSelected}
					isFilterOptionDisabled={isFilterOptionDisabled}
				/>
			) : null}
		</div>
	);
}
