import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { AccessReviewPermissionCommentModel } from "models/AccessReviewPermissionCommentModel";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { Typography } from "components/ui/legacy/Typography";
import { Tooltip } from "components/ui/Tooltip";
import { Input } from "components/ui/Input";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { getCommentValidator } from "utils/validation/fieldValidation";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";
import type { List } from "immutable";

interface IProps {
	comments: List<AccessReviewPermissionCommentModel> | null;
	readonly?: boolean;
	onSubmit?: (comment: string) => Promise<void>;
}

const COMMENT_VALIDATORS = getCommentValidator(1, 1024, "entities.Comment");

export const AccessReviewPermissionCommentCell: FC<IProps> = ({
	className,
	comments,
	id,
	innerRef,
	onSubmit,
	readonly = false
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { user } = useAuthenticatedUser();
	const [showInput, setShowInput] = useState(false);
	const [loading, setLoading] = useState(false);
	const [content, setContent] = useState<string>();
	const inputRef = useRef<HTMLInputElement | null>(null);

	const userComment = useMemo(
		() => comments?.find(comment => comment.creatorId === user?.id) || null,
		[comments, user]
	);

	const shownComment = useMemo(() => {
		if (userComment) return userComment;
		if (comments && comments.size) return comments.sortBy(comment => comment.createdAt.getTime()).get(0)!;
		return null;
	}, [comments, userComment]);

	const commentUser = useUser(shownComment?.creatorId);

	const tooltipText = useMemo(
		() =>
			shownComment
				? t(`common.accessReviewPermissionComment.tooltip`, {
						creatorName: commentUser?.fullName || t("shared.unknown"),
						createdAt: shownComment.updatedAt
					})
				: undefined,
		[shownComment, t, commentUser]
	);

	const onBlur = useCallback(async () => {
		if (!onSubmit) return;
		if (inputRef.current && inputRef.current === document.activeElement) return;
		if (!content || content === userComment?.content) {
			setShowInput(false);
			return;
		}
		setLoading(true);
		await onSubmit(content);
		setLoading(false);
		setShowInput(false);
	}, [content, onSubmit, userComment?.content]);

	const onEditClick = useCallback(() => {
		setShowInput(true);
	}, []);

	useEffect(() => {
		if (userComment) {
			setContent(userComment.content);
		}
	}, [userComment]);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "Enter") {
				onBlur();
			}
		},
		[onBlur]
	);

	if (readonly) {
		return (
			<Tooltip content={tooltipText}>
				<Typography className={classNames(className)} id={id}>
					{shownComment?.content}
				</Typography>
			</Tooltip>
		);
	}

	return (
		<div className={classNames(className, classes.fullCell)} id={id} ref={innerRef}>
			<Tooltip content={tooltipText}>
				{showInput ? (
					<Input
						autoFocus
						inputRef={inputRef}
						className={classes.fullHeight}
						inputContainerClassName={classes.fullHeight}
						placeholder={t("common.addComment.placeholder")}
						onBlur={onBlur}
						onKeyDown={onKeyDown}
						onValueChange={setContent}
						suffix={loading ? <LoadingSpinner inline /> : null}
						validators={[COMMENT_VALIDATORS]}
						variant="table"
						value={content}
					/>
				) : (
					<Typography className={classNames(classes.comment, classes.fullCell)} onClick={onEditClick}>
						{shownComment ? shownComment.content : t("common.addComment.placeholder")}
					</Typography>
				)}
			</Tooltip>
		</div>
	);
};
