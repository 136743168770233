import React, { useMemo } from "react";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Typography } from "components/ui/Typography";
import { useTranslation } from "react-i18next";
import { PermissionsIcon } from "components/ui/Icons/PermissionsIcon";
import { Chip } from "components/ui/chips/Chip";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { IconPrefix } from "components/ui/IconPrefix";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { RoleBar } from "components/common/RoleBar";
import { useStyles } from "./styles";
import type { TPermissions } from "models/auditLogs/SessionAuditLogModel";

type TProps = {
	permissions: TPermissions[];
};
const TOOLTIP_OFFSET = [-45, 6] as [number, number];
export const PermissionsCell: FC<TProps> = ({ permissions }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations();

	const permissionsTooltipHeader = useMemo(() => {
		return (
			<div className={classes.tooltipTitle}>
				<Typography variant="body_med"> {t("pages.auditLog.integrationLogs.columns.permissions")}</Typography>
				<StaticChip size="small" variant="regular">
					{permissions.length}
				</StaticChip>
			</div>
		);
	}, [classes.tooltipTitle, permissions.length, t]);

	const permissionsTooltipBody = useMemo(() => {
		return permissions.map((permission, index) => {
			const permissionIntegration = integrations?.find(
				integration => integration.name === permission.integrationResource.integration.name
			);
			return (
				<div className={classes.permissionsContainer} key={index}>
					<RoleBar
						noInteraction
						columns={[
							{
								content: (
									<IconPrefix
										Icon={
											permissionIntegration?.imageUrl
												? getDynamicSizeIcon(<img src={permissionIntegration.imageUrl} />)
												: IntegrationIcon
										}
										content={permission.integrationResource.integration.name}
									/>
								)
							},
							{
								content: <IconPrefix Icon={ResourcesIcon} content={permission.integrationResource.integration.name} />
							},
							{
								content: <IconPrefix Icon={RoleIcon} content={permission.name} />
							}
						]}
					/>
				</div>
			);
		});
	}, [classes.permissionsContainer, integrations, permissions]);

	return permissions.length > 0 ? (
		<TitleTooltip
			className={classes.tooltipBodyContainer}
			header={permissionsTooltipHeader}
			body={permissionsTooltipBody}
			placement="top-end"
			offset={TOOLTIP_OFFSET}>
			<div className={classes.permissionChip}>
				<Chip PrefixIcon={PermissionsIcon} size="small">
					{permissions.length}
				</Chip>
			</div>
		</TitleTooltip>
	) : null;
};
