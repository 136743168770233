import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { List } from "immutable";
import isEqual from "lodash/isEqual";
import { Typography } from "components/ui/legacy/Typography";
import { Checkbox } from "components/ui/Checkbox";
import { Select } from "components/ui/Select";
import { Button } from "components/ui/Button";
import { LoadingDots } from "components/ui/LoadingDots";
import { Form } from "components/ui/Form";
import { Title } from "components/ui/Title";
import { TDayOfTheWeek, TRoutineReminderSchedule } from "models/CompanyModel";
import { useCompanyContext } from "context/companyContext";
import classNames from "classnames";
import { NotificationIcon } from "components/ui/Icons/NotificationIcon";
import { useStyles } from "./styles";

const MINUTE_INTERVAL = 5;

interface IProps {
	reminderSchedule: TRoutineReminderSchedule | null;
	onSave: (schedule: TRoutineReminderSchedule) => void;
	type: "accessReports" | "pendingRequests";
}

const hours = new Array(24).fill(0).map((_, i) => ({ value: i, label: `${i < 10 ? 0 : ""}${i}` }));
const minutes = new Array(60 / MINUTE_INTERVAL).fill(0).map((_, i) => {
	const minuteValue = i * MINUTE_INTERVAL;
	return { value: minuteValue, label: `${minuteValue < 10 ? 0 : ""}${minuteValue}` };
});

const defaultHour = hours[10];
const defaultMinute = minutes[6];

const convertDaysOfTheWeekToSelectedDays = (daysOfTheWeek: List<boolean>) =>
	daysOfTheWeek
		.map((isSelected, i) => (isSelected ? i : null))
		.filter(dayOfTheWeek => dayOfTheWeek !== null)
		.toArray() as TDayOfTheWeek[];

export const Reminder: FC<IProps> = ({ type, reminderSchedule, onSave }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		state: { company }
	} = useCompanyContext();

	const [daysOfTheWeek, setDaysOfTheWeek] = useState(
		List<boolean>(
			Array(7)
				.fill(false)
				.map((_, i) => i < 5)
		)
	);
	interface ITimeOption {
		value: number;
		label: string;
	}
	const [hour, setHour] = useState<ITimeOption>(defaultHour);
	const [minute, setMinute] = useState<ITimeOption>(defaultMinute);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (reminderSchedule) {
			setDaysOfTheWeek(
				List<boolean>(
					Array(7)
						.fill(false)
						.map((_, i) => reminderSchedule!.daysOfTheWeek.includes(i as TDayOfTheWeek))
				)
			);
			setHour(hours[reminderSchedule.hour]);
			setMinute(minutes[reminderSchedule.minute / MINUTE_INTERVAL]);
		}
	}, [company, reminderSchedule]);

	const submit = useCallback(async () => {
		const selectedDays = convertDaysOfTheWeekToSelectedDays(daysOfTheWeek);

		setIsLoading(true);
		try {
			await onSave({ daysOfTheWeek: selectedDays, hour: hour?.value || 0, minute: minute?.value || 0 });
		} finally {
			setIsLoading(false);
		}
	}, [daysOfTheWeek, hour?.value, minute?.value, onSave]);

	const isValid = hour && minute && daysOfTheWeek.some(Boolean);
	const hasChanged =
		hour?.value !== reminderSchedule?.hour ||
		minute?.value !== reminderSchedule?.minute ||
		!isEqual(convertDaysOfTheWeekToSelectedDays(daysOfTheWeek), reminderSchedule?.daysOfTheWeek);

	const onChangeHour = useCallback((value: ITimeOption | null) => {
		if (value) {
			setHour(value);
		} else {
			setHour(defaultHour);
		}
	}, []);

	const onChangeMinute = useCallback((value: ITimeOption | null) => {
		if (value) {
			setMinute(value);
		} else {
			setMinute(defaultMinute);
		}
	}, []);

	return (
		<>
			<Title variant="h3" noBorder>
				{t(`pages.settings.reminders.${type}.title`)}
			</Title>
			<div className={classes.container}>
				{company ? (
					<Form className={classes.reminderForm}>
						<Form.Field>
							<Typography variant="small" className={classNames(classes.label, classes.weekdayLabel)}>
								<NotificationIcon />
								{t(`pages.settings.reminders.remindersFrequency`)}
							</Typography>
							<div className={classes.weekdayContainer}>
								{daysOfTheWeek.map((selected, dayOfTheWeek) => (
									<Checkbox
										key={dayOfTheWeek}
										selected={selected}
										onClick={() => setDaysOfTheWeek(current => current.set(dayOfTheWeek, !selected))}
										label={t(`common.daysOfTheWeek.${dayOfTheWeek as TDayOfTheWeek}`)}
									/>
								))}
							</div>
						</Form.Field>
						<Form.Field className={classes.timeField}>
							<Typography variant="small" className={classes.label}>
								{t(`pages.settings.reminders.at`)}
							</Typography>
							<div className={classes.timeContainer}>
								<Select
									options={hours}
									value={hour}
									onChange={onChangeHour}
									className={classes.timeInput}
									filter={null}
									required
								/>
								<Typography variant="h2">:</Typography>
								<Select
									options={minutes}
									value={minute}
									onChange={onChangeMinute}
									className={classes.timeInput}
									filter={null}
									required
								/>
							</div>
						</Form.Field>
						<Form.Actions className={classes.formActions}>
							<Button size="medium" onClick={submit} loading={isLoading} disabled={!isValid || !hasChanged}>
								{reminderSchedule ? t("buttons.save") : t("buttons.create")}
							</Button>
						</Form.Actions>
						<Typography variant="small">{t(`pages.settings.reminders.${type}.notice`)}</Typography>
					</Form>
				) : (
					<LoadingDots center />
				)}
			</div>
		</>
	);
};
