import React from "react";
import classNames from "classnames";
import { IconPrefix } from "components/ui/IconPrefix";
import { useStyles } from "./styles";
import type { TIconProps } from "components/ui/Icon";

type TProps = { text: string; Icon?: FC<TIconProps> };

export const FilterExpressionEmptyState: FC<TProps> = ({ className, text, Icon, innerRef }) => {
	const classes = useStyles();

	return (
		<IconPrefix className={classNames(classes.emptyState, className)} content={text} Icon={Icon} innerRef={innerRef} />
	);
};
