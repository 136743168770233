import React from "react";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useIntegrations } from "hooks/useIntegrations";
import { TitleBody } from "components/ui/TitleBody";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { useTranslation } from "react-i18next";
import { Chip, TChipSize, TChipVariant } from "../Chip";
import { SkeletonChip } from "../SkeletonChip";
import type { IntegrationModel } from "models/IntegrationModel";

interface IIntegrationChipProps {
	integration: IntegrationModel | string;
	onDelete?: () => void;
	readonly?: boolean;
	selected?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
}

export const IntegrationChip: FC<IIntegrationChipProps> = ({
	className,
	innerRef,
	integration,
	onDelete,
	readonly = false,
	selected = false,
	size = "medium",
	variant = "regular"
}) => {
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const currentIntegration = typeof integration === "string" ? integrations?.get(integration) : integration;

	return currentIntegration ? (
		<Tooltip
			content={<TitleBody size="small" title={t("common.tooltips.integrationName")} body={currentIntegration?.name} />}>
			<Chip
				noTooltip
				readonly={readonly}
				variant={variant}
				size={size}
				PrefixIcon={
					currentIntegration?.imageUrl
						? getDynamicSizeIcon(<img src={currentIntegration?.imageUrl} />)
						: IntegrationIcon
				}
				onDelete={onDelete}
				selected={selected}
				className={className}
				innerRef={innerRef}>
				{`${currentIntegration.name}`}
			</Chip>
		</Tooltip>
	) : (
		<SkeletonChip size={size} />
	);
};
