import classNames from "classnames";
import { Typography } from "components/ui/legacy/Typography";
import React, { useCallback, useEffect, useRef } from "react";
import { TRenderOption } from "utils/ui/selectUtils";
import { useStyles } from "./styles";

interface ISelectItemProps<T> {
	disabled?: boolean;
	highlighted?: boolean;
	index: number;
	isWithinGroup?: boolean;
	onHover?: (index: number) => void;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	renderOption: TRenderOption<T>;
	value: T;
}

export const SelectItem = <T,>({
	className,
	disabled = false,
	index,
	onSelect,
	renderOption,
	value,
	highlighted,
	onHover,
	isWithinGroup = false
}: TProps<ISelectItemProps<T>>) => {
	const onClick = useCallback(
		(event: React.MouseEvent) => {
			!disabled && onSelect(event, value);
		},
		[disabled, onSelect, value]
	);
	const classes = useStyles();
	const onMouseEnter = useCallback(() => onHover && onHover(index), [index, onHover]);
	const ref = useRef<HTMLElement>();

	useEffect(() => {
		if (highlighted) {
			ref.current?.scrollIntoView({ block: "nearest", inline: "nearest" });
		}
	}, [highlighted]);

	return (
		<Typography
			innerRef={ref}
			onMouseEnter={onMouseEnter}
			onClick={onClick}
			className={classNames(classes.selectItem, className, {
				disabled,
				[classes.highlighted]: highlighted,
				[classes.isWithinGroup]: isWithinGroup
			})}>
			{renderOption(value, index, disabled)}
		</Typography>
	);
};
