import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { deleteTicketComment, updateTicketComment } from "api/ticketComments";
import { Avatar } from "components/common/Avatar";
import { Button } from "components/ui/Button";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { Typography } from "components/ui/Typography";
import { useIntegrations } from "hooks/useIntegrations";
import { useLoadingState } from "hooks/useLoadingState";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { TicketCommentModel } from "models/TicketCommentModel";
import { getApprovalIcon } from "utils/auditLogs/auditLogType";
import { Separator } from "components/ui/Separator";
import { EntitleLogo } from "components/ui/systemLogos/EntitleLogo";
import { getCommentValidators } from "utils/validation/validationUtils";
import { getTicketingIntegrationLogoByType } from "utils/tickets/ticketingIntegrationLogos";
import { useUser } from "hooks/useUser";
import {
	AuditLogContent,
	IAuditLogActivity,
	IAuditLogAndCommentActivity,
	ICommentActivity,
	RECEIVER_ACTIONS,
	TActivity,
	TextUser
} from "../../utils";
import { useStyles } from "./styles";
import type { TFullTicket } from "components/common/RequestDetails";

interface IProps {
	activity: TActivity;
	ticket: TFullTicket;
	updateComment: (comment: TicketCommentModel) => void;
	showLine?: boolean;
}

const USER_ACTION_TYPES = ["userApproved", "userDeclined", "redirect.toUser"];

const TRANSLATION_PREFIX = "common.ticketActivity.ticketActivityItem.";

export const TicketActivityItem: FC<IProps> = ({ activity, ...compProps }) => {
	switch (activity.type) {
		case "auditLog":
			return <TicketAuditLogActivityItem activity={activity} {...compProps} />;
		case "comment":
		case "auditLogAndComment":
			return <TicketCommentActivityItem activity={activity} {...compProps} />;
		default:
			return null;
	}
};

const TicketAuditLogActivityItem: FC<{ activity: IAuditLogActivity; ticket: TFullTicket; showLine?: boolean }> = ({
	activity,
	className,
	id,
	innerRef,
	ticket,
	showLine
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations();

	const isManual = activity.data.data?.get("isManual");
	const manualAction =
		(activity.data.action === "AccessRequestPermissionGrantedSuccess" ||
			activity.data.action === "AccessRequestPermissionExpiredRevokedSuccess") &&
		isManual;

	let activityUserId =
		activity.data.action && RECEIVER_ACTIONS.includes(activity.data.action) ? ticket.receiverId : activity.data.userId;

	if (manualAction) {
		activityUserId = (activity.data.data?.get("userManualActionId") as string | undefined) || ticket.receiverId;
	}

	const activityUser = useUser(activityUserId);

	const ApprovalIcon = useMemo(() => getApprovalIcon(activity.data.action), [activity]);

	const userAction = (activity.data.action && USER_ACTION_TYPES.includes(activity.data.action)) || manualAction;

	const integrationId = activity.data.data?.get("integrationId") as string | undefined;
	const integration = integrationId && integrations?.get(integrationId);

	const prefixIcon = useMemo(() => {
		if (userAction) return <Avatar size="large" user={activityUser} />;
		if (integration && integration.imageUrl)
			return <img src={integration.imageUrl} className={classNames(classes.integrationLogo, classes.entitleLogo)} />;
		if (ticket.ticketingIntegrationTicket && activity.data.action === "AccessRequest3rdPartyTicketClosed") {
			const Logo = getTicketingIntegrationLogoByType(ticket.ticketingIntegrationTicket.ticketingIntegration) ?? null;
			return Logo && <Logo />;
		}
		return <EntitleLogo className={classes.entitleLogo} />;
	}, [
		activity.data.action,
		activityUser,
		classes.entitleLogo,
		classes.integrationLogo,
		integration,
		ticket.ticketingIntegrationTicket,
		userAction
	]);

	return (
		<div className={classNames(classes.itemContainer, className)} id={id} ref={innerRef}>
			{(activityUser || ticket.ticketingIntegrationTicket) && (
				<div className={classes.itemPrefix}>
					<div className={classes.itemUserAvatar}>{prefixIcon}</div>
					{showLine && (
						<div className={classes.separator}>
							<Separator className={classes.line} />
						</div>
					)}
				</div>
			)}
			<div className={classes.itemBody}>
				<div className={classes.itemTitle}>
					<div className={classes.title}>
						{ApprovalIcon && (
							<div className={classes.approvalIcon}>
								<ApprovalIcon />
							</div>
						)}
						<Typography variant="text_reg">
							<AuditLogContent log={activity.data} ticket={ticket} />
						</Typography>
					</div>
				</div>
				<Typography variant="text_tny_reg" className={classes.itemMetadata}>
					{t("dateTime.dateWithTime", { date: activity.data.createdAt })}
				</Typography>
			</div>
		</div>
	);
};

const TicketCommentActivityItem: FC<{
	activity: ICommentActivity | IAuditLogAndCommentActivity;
	ticket: TFullTicket;
	updateComment: (comment: TicketCommentModel) => void;
	showLine?: boolean;
}> = ({ activity, className, id, innerRef, ticket, updateComment, showLine }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { user } = useAuthenticatedUser();

	// Show
	const isWithLog = activity.type === "auditLogAndComment";
	const comment = useMemo(() => (isWithLog ? activity.data.comment : activity.data), [activity.data, isWithLog]);
	const isUpdated = useMemo(() => new Date(comment.updatedAt) > new Date(comment.createdAt), [comment]);
	const isDeleted = comment.isDeleted;

	// Edit
	const { isLoading, withLoader } = useLoadingState();
	const [inHover, setInHover] = useState(false);
	const [inEdit, setInEdit] = useState(false);
	const commentCreator = useUser(comment?.creatorId);
	const isUsersComment = useMemo(() => user?.id === comment?.creatorId, [comment?.creatorId, user?.id]);
	const isEditing = useMemo(() => inEdit && isUsersComment, [inEdit, isUsersComment]);
	const [content, setContent] = useState(comment.content);

	const onMouseEnter = useCallback(() => setInHover(true), []);
	const onMouseLeave = useCallback(() => setInHover(false), []);
	const onSetEdit = useCallback(() => setInEdit(true), []);
	const onSetEditCancel = useCallback(() => {
		setInEdit(false);
		setContent(comment.content);
	}, [comment.content]);

	const commentValidators = useMemo(() => getCommentValidators(t("common.addComment.comment")), [t]);

	const isValid = useMemo(() => {
		return !commentValidators.some(validate => validate(content)) && content !== comment.content && content.length;
	}, [commentValidators, content, comment]);

	const update = useCallback(async () => {
		if (!isValid) return;
		const updatedComment = await withLoader(updateTicketComment(comment.id, content));
		updateComment(updatedComment);
		setContent(updatedComment.content);
		setInEdit(false);
	}, [comment.id, content, isValid, updateComment, withLoader]);

	const deleteComment = useCallback(async () => {
		if (!user || !comment || !updateComment || activity.cantDelete) return;
		if (!isUsersComment) return;
		const updatedComment = await withLoader(deleteTicketComment(comment.id));
		updateComment(updatedComment);
	}, [user, comment, updateComment, activity.cantDelete, withLoader, isUsersComment]);

	if (isDeleted) {
		return (
			commentCreator && (
				<div className={classNames(classes.itemContainer, className)} id={id} ref={innerRef}>
					<div className={classes.itemPrefix}>
						<div className={classes.itemUserAvatar}>{<Avatar size="large" user={commentCreator} />}</div>
						{showLine && (
							<div className={classes.separator}>
								<Separator className={classes.line} />
							</div>
						)}
					</div>
					<div className={classes.itemBody}>
						<div className={classes.itemTitle}>
							<Typography variant="text_reg">{t("common.comment.deleted")}</Typography>
						</div>
					</div>
				</div>
			)
		);
	}

	return (
		commentCreator && (
			<div
				className={classNames(classes.itemContainer, classes.comment, { [classes.editing]: isEditing }, className)}
				id={id}
				ref={innerRef}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}>
				<div className={classes.itemPrefix}>
					<div className={classes.itemUserAvatar}>
						<Avatar size="medium" user={commentCreator} />
					</div>
					{showLine && (
						<div className={classes.separator}>
							<Separator className={classes.line} />
						</div>
					)}
				</div>
				<div className={classes.itemBody}>
					<div className={classes.itemTitle}>
						<Typography variant="text_reg">
							{isWithLog ? (
								<AuditLogContent log={activity.data.auditLog} ticket={ticket} />
							) : (
								<Trans
									t={t}
									i18nKey={`${TRANSLATION_PREFIX}commented`}
									components={{ user: <TextUser user={commentCreator} /> }}
								/>
							)}
						</Typography>
						{inHover && isUsersComment && (
							<div className={classes.itemCommentActions}>
								{!inEdit && (
									<Button size="small" variant="text" onClick={onSetEdit} loading={isLoading}>
										{t("buttons.edit")}
									</Button>
								)}
								{!activity.cantDelete && (
									<Button size="small" variant="text" onClick={deleteComment} loading={isLoading}>
										{t("buttons.delete")}
									</Button>
								)}
							</div>
						)}
					</div>
					<div className={classes.itemCommentContainer}>
						{isEditing ? (
							<>
								<TextAreaInput
									autoResize
									textAreaClassName={classes.itemCommentInput}
									value={content}
									onValueChange={setContent}
									validators={commentValidators}
								/>
								<div className={classes.itemCommentActions}>
									<Button variant="secondary" size="small" onClick={onSetEditCancel}>
										{t("buttons.cancel")}
									</Button>
									<Button
										size="small"
										onClick={update}
										loading={isLoading}
										className={classes.itemCommentPostButton}
										disabled={!isValid}>
										{t("buttons.post")}
									</Button>
								</div>
							</>
						) : (
							<Typography className={classes.itemComment} variant="text_reg">
								{`"${comment.content}"`}
							</Typography>
						)}
						<Typography variant="text_tny_reg" className={classes.itemMetadata}>
							{t("dateTime.dateWithTime", { date: new Date(comment.createdAt) })}
							{isUpdated && t("common.comment.edited")}
						</Typography>
					</div>
				</div>
			</div>
		)
	);
};
