import React, { useCallback, useState, type ChangeEvent } from "react";
import classNames from "classnames";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";

type TTimePickerProps = {
	dateTime: Date;
	onTimeChange?: (dateTime: Date) => void;
};

const TIME_AM = "AM";
const TIME_PM = "PM";
const SEPERATOR = ":";
const HOUR_DIFFERENTIAL = 12;
const MAX_MINUTES = 59;
const MIN_HOURS = 1;
const MIN_MINUTES = 0;
const MAX_HOURS = 23;

type TTimeType = "AM" | "PM";
const minutesPattern = /^([0-9]|[1-5][0-9])$/;
const hoursPattern = /^(?:2[0-3]|1\d|0?[1-9])$/;

export const TimePicker: FC<TTimePickerProps> = ({ dateTime, onTimeChange }) => {
	const [hours, setHours] = useState(dateTime.getHours() % HOUR_DIFFERENTIAL || HOUR_DIFFERENTIAL);
	const [lastHoursValidInput, setLastHoursValidInput] = useState(0);
	const [minutes, setMinutes] = useState(dateTime.getMinutes() || 0);
	const [lastMinutesValidInput, setLastMinutesValidInput] = useState(0);
	const [timeType, setTimeType] = useState<TTimeType>(dateTime.getHours() >= HOUR_DIFFERENTIAL ? TIME_PM : TIME_AM);
	const classes = useStyles();

	const handleTimeChange = useCallback(
		(newHours?: number, newMinutes?: number, newTimeType?: TTimeType) => {
			const currentTimeType = newTimeType ?? timeType;
			onTimeChange?.(
				new Date(
					0,
					0,
					0,
					(newHours || hours) + (currentTimeType === TIME_PM ? HOUR_DIFFERENTIAL : 0),
					newMinutes || minutes
				)
			);
		},
		[hours, minutes, onTimeChange, timeType]
	);

	const addTime = useCallback((time: number, maxTime: number, minTime: number) => {
		return time === maxTime ? minTime : time < maxTime ? time + 1 : time;
	}, []);

	const removeTime = useCallback((time: number, maxTime: number, minTime: number) => {
		return time === minTime ? maxTime : time > minTime ? time - 1 : time;
	}, []);
	const addFirstTimerHours = useCallback(() => {
		setHours(prev => {
			const newHours = addTime(prev, HOUR_DIFFERENTIAL, MIN_HOURS);
			handleTimeChange(newHours);
			return newHours;
		});
	}, [addTime, handleTimeChange]);

	const addFirstTimerMinutes = useCallback(() => {
		setMinutes(prev => {
			const newHours = addTime(prev, MAX_MINUTES, MIN_MINUTES);
			handleTimeChange(newHours);
			return newHours;
		});
	}, [addTime, handleTimeChange]);

	const removeFirstTimerHours = useCallback(() => {
		setHours(prev => {
			const newHours = removeTime(prev, HOUR_DIFFERENTIAL, MIN_HOURS);
			handleTimeChange(newHours);
			return newHours;
		});
	}, [handleTimeChange, removeTime]);

	const removeFirstTimerMinutes = useCallback(() => {
		setMinutes(prev => {
			const newHours = removeTime(prev, MAX_MINUTES, MIN_MINUTES);
			handleTimeChange(newHours);
			return newHours;
		});
	}, [handleTimeChange, removeTime]);

	const handleTimePmClick = useCallback(() => {
		handleTimeChange(undefined, undefined, TIME_PM);
		setTimeType(TIME_PM);
	}, [handleTimeChange]);

	const handleTimeAmClick = useCallback(() => {
		handleTimeChange(undefined, undefined, TIME_AM);
		setTimeType(TIME_AM);
	}, [handleTimeChange]);

	const parseTime = useCallback((time: number) => {
		if (time < 10 || time === 0) {
			return `0${time}`;
		}
		return time.toString();
	}, []);

	const handleHoursInputChange = useCallback(
		(event: React.FocusEvent<HTMLInputElement, Element>) => {
			if (!hoursPattern.test(event.currentTarget.value)) {
				setHours(lastHoursValidInput);
			} else if (
				Number(event.currentTarget.value) > HOUR_DIFFERENTIAL &&
				Number(event.currentTarget.value) <= MAX_HOURS
			) {
				setHours(Number(event.currentTarget.value) % HOUR_DIFFERENTIAL);
				setLastHoursValidInput(Number(event.currentTarget.value) % HOUR_DIFFERENTIAL);
				setTimeType(TIME_PM);
			} else {
				setLastHoursValidInput(Number(event.currentTarget.value));
			}
			handleTimeChange();
		},
		[handleTimeChange, lastHoursValidInput]
	);

	const handleMinutesInputChange = useCallback(
		(event: React.FocusEvent<HTMLInputElement, Element>) => {
			if (!minutesPattern.test(event.currentTarget.value)) {
				setMinutes(lastMinutesValidInput);
			} else {
				setLastMinutesValidInput(Number(event.currentTarget.value));
			}
			handleTimeChange();
		},
		[handleTimeChange, lastMinutesValidInput]
	);

	const handleHoursValueChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setHours(Number(event.target.value));
			handleTimeChange();
		},
		[handleTimeChange]
	);

	const handleMinutesValueChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setMinutes(Number(event.target.value));
			handleTimeChange();
		},
		[handleTimeChange]
	);

	return (
		<div className={classes.timer}>
			<div className={classes.timeInput}>
				<div onClick={addFirstTimerHours} className={classNames(classes.iconButton, classes.chevronUp)}>
					<ChevronUpIcon />
				</div>
				<input
					autoFocus
					className={classes.timeInputElement}
					onBlur={handleHoursInputChange}
					onChange={handleHoursValueChange}
					value={parseTime(hours)}
				/>
				<div onClick={removeFirstTimerHours} className={classNames(classes.iconButton, classes.chevronDown)}>
					<ChevronDownIcon />
				</div>
			</div>
			<Typography className={classes.seperatorInput} variant="header_sb">
				{SEPERATOR}
			</Typography>
			<div className={classes.timeInput}>
				<div onClick={addFirstTimerMinutes} className={classNames(classes.iconButton, classes.chevronUp)}>
					<ChevronUpIcon />
				</div>
				<input
					autoFocus
					className={classes.timeInputElement}
					onBlur={handleMinutesInputChange}
					onChange={handleMinutesValueChange}
					value={parseTime(minutes)}
				/>
				<div onClick={removeFirstTimerMinutes} className={classNames(classes.iconButton, classes.chevronDown)}>
					<ChevronDownIcon />
				</div>
			</div>
			<div className={classes.inputs}>
				<div
					onClick={handleTimeAmClick}
					onDoubleClick={handleTimeAmClick}
					className={classNames(classes.timeButtonInput, {
						[classes.selectedTimeType]: timeType === TIME_AM
					})}>
					<Typography variant="body_sb">{TIME_AM}</Typography>
				</div>
				<div
					onClick={handleTimePmClick}
					className={classNames(classes.timeButtonInput, {
						[classes.selectedTimeType]: timeType === TIME_PM
					})}>
					<Typography variant="body_sb">{TIME_PM}</Typography>
				</div>
			</div>
		</div>
	);
};
