import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes/routes";
import { useNavigate } from "react-router-dom";
import { Modal } from "components/ui/Modal";
import { Button } from "components/ui/Button";
import { TitleBody } from "components/ui/TitleBody";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { useStyles } from "./styles";

type TProps = {
	isOpen: boolean;
};

export const NotAllowedRequestModal: FC<TProps> = ({ className, innerRef, isOpen }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep.unauthorizedDataModal" });
	const classes = useStyles();
	const navigate = useNavigate();

	const {
		actions: { goToStep }
	} = useStepperContext();

	const {
		actions: { resetState }
	} = useNewRequestFormContext();

	const goBack = useCallback(() => {
		goToStep(0);
		navigate({ pathname: routes.newRequest.main });
		resetState();
	}, [goToStep, navigate, resetState]);

	return (
		<Modal
			actions={
				<Button size="medium" onClick={goBack}>
					{t("createNewRequest")}
				</Button>
			}
			content={<TitleBody size="large" title={t("title")} body={t("content")} />}
			className={classNames(classes.modal, className)}
			innerRef={innerRef}
			isOpen={isOpen}
			noTopCloseButton
		/>
	);
};
