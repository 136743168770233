import { List } from "immutable";
import { UserModel } from "models/UserModel";
import { useCallback, useEffect, useState } from "react";
import type { TTicketDuration } from "utils/durationsOptions";

export type TFormData = {
	owner: UserModel | null | undefined;
	resourceType: string | undefined;
	allowsRequests: boolean;
	allowedDurations: List<TTicketDuration> | undefined;
	tags: string[] | undefined | null;
	description: string | undefined | null;
	name: string;
	overrideAllowedDurations?: boolean;
};

type TStateArgs = {
	propOwner: UserModel | null | undefined;
	propAllowsRequests: boolean;
	propAllowedDurations: List<TTicketDuration> | undefined;
	propOnSave: (formData: TFormData) => Promise<void>;
	propResourceType?: string;
	propDescription?: string | null;
	propTags?: string[] | null;
	propName: string;
	propOverrideAllowedDurations?: boolean;
};

export const useSettingsPopupState = ({
	propAllowedDurations,
	propAllowsRequests,
	propOnSave,
	propOwner,
	propDescription,
	propResourceType,
	propTags,
	propName,
	propOverrideAllowedDurations = false
}: TStateArgs) => {
	const [owner, setOwner] = useState(propOwner);
	const [allowsRequests, setAllowsRequests] = useState(propAllowsRequests);
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(propOverrideAllowedDurations);
	const [allowedDurations, setAllowedDurations] = useState(propAllowedDurations || List<TTicketDuration>());
	const [resourceType, setResourceType] = useState(propResourceType);
	const [name, setName] = useState(propName);
	const [description, setDescription] = useState(propDescription);
	const [tags, setTags] = useState(propTags);

	useEffect(() => {
		propAllowedDurations && setAllowedDurations(propAllowedDurations);
	}, [propAllowedDurations]);

	const toggleAllowsRequests = useCallback(() => setAllowsRequests(curr => !curr), []);

	const onClose = useCallback(async () => {
		setName(propName);
		setResourceType(propResourceType);
		setDescription(propDescription);
		setTags(propTags);
		setAllowedDurations(propAllowedDurations || List<TTicketDuration>());
		setOverrideAllowedDurations(propOverrideAllowedDurations);
		setAllowsRequests(propAllowsRequests);
		setOwner(propOwner);
	}, [
		propAllowedDurations,
		propAllowsRequests,
		propDescription,
		propName,
		propOverrideAllowedDurations,
		propOwner,
		propResourceType,
		propTags
	]);

	const onSave = useCallback(async () => {
		await propOnSave({
			owner,
			allowedDurations: overrideAllowedDurations ? allowedDurations : undefined,
			resourceType,
			description,
			allowsRequests,
			tags,
			name
		});
	}, [
		propOnSave,
		owner,
		overrideAllowedDurations,
		allowedDurations,
		resourceType,
		description,
		allowsRequests,
		tags,
		name
	]);

	return {
		state: {
			owner,
			allowsRequests,
			overrideAllowedDurations,
			allowedDurations,
			resourceType,
			description,
			tags,
			name,
			setOwner,
			toggleAllowsRequests,
			setOverrideAllowedDurations,
			setAllowedDurations,
			onSave,
			onClose,
			setResourceType,
			setDescription,
			setTags,
			setName
		}
	};
};
