import React, { useCallback, useMemo, useState } from "react";
import isNil from "lodash/isNil";
import { UserModel } from "models/UserModel";
import { UserCard } from "components/common/UserCard";
import { useTranslation } from "react-i18next";
import { Entity } from "components/ui/Entity";
import { Chip } from "components/ui/chips/Chip";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { useUsersSelect } from "hooks/useUsersSelect";
import { TUpdateExpressionProps } from "../../updates.types";
import { SingleSelectAttributeExpression } from "../SingleSelectAttributeExpression";
import { useStyles } from "./styles";
import type { TTargetValue } from "components/ui/Select";

const INHERIT_USER = "inherit" as const;
const ATTRIBUTE = "ownerUserId" as const;
type TOption = UserModel | typeof INHERIT_USER;
export const ResourceOwnerAttributeExpression: FC<Omit<TUpdateExpressionProps<"ownerUserId">, "ruleType">> = ({
	innerRef,
	selected,
	onRemoveAttribute,
	setUpdatesAttribute
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.rules.updates" });
	const { t: sharedTranslation } = useTranslation("translation", { keyPrefix: "shared" });
	const classes = useStyles();
	const [query, setQuery] = useState("");
	const {
		items: users,
		isLoading,
		selectedItems
	} = useUsersSelect(query, { includeDeleted: false, selectedIds: selected ? [selected] : undefined });
	const onReset = useCallback(() => {
		setUpdatesAttribute(ATTRIBUTE, undefined);
	}, [setUpdatesAttribute]);

	const onOptionSelect = useCallback(
		(value: TOption) => setUpdatesAttribute(ATTRIBUTE, value === INHERIT_USER ? null : value.id),
		[setUpdatesAttribute]
	);

	const renderSelected = useCallback(
		(value: TOption) =>
			value === INHERIT_USER ? (
				<div className={classes.userNodeHeight}>
					<Chip PrefixIcon={OwnerIcon} size="large" variant="regular" selected onDelete={onReset}>
						{sharedTranslation("integrationDefault")}
					</Chip>
				</div>
			) : (
				<UserCard user={value} onDelete={onReset} selected />
			),
		[onReset, sharedTranslation, classes.userNodeHeight]
	);

	const renderOption = useCallback(
		(option: TOption) =>
			option === INHERIT_USER ? (
				<Entity content={sharedTranslation("integrationDefault")} />
			) : (
				<UserCard user={option} />
			),
		[sharedTranslation]
	);

	const options = useMemo(() => [INHERIT_USER as TOption].concat(users.toArray()), [users]);

	const filterOptions = useCallback(
		(options: TOption[], inputValue: string) =>
			inputValue
				? options.filter(
						option => option !== INHERIT_USER && option.fullName.toLowerCase().includes(inputValue.toLowerCase())
					)
				: options,
		[]
	);

	const onInputChange = useCallback((event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	}, []);

	const selectedValue = useMemo(
		() => (isNil(selected) ? (selected === null ? INHERIT_USER : undefined) : (selectedItems.first() ?? undefined)),
		[selected, selectedItems]
	);

	return (
		<SingleSelectAttributeExpression
			innerRef={innerRef}
			onRemoveAttribute={onRemoveAttribute}
			title={t(`resources.${ATTRIBUTE}`)}
			inputPlaceholder={t(`placeholders.${ATTRIBUTE}`)}
			filter={filterOptions}
			options={options}
			renderSelected={renderSelected}
			onReset={onReset}
			renderOption={renderOption}
			onOptionSelect={onOptionSelect}
			sort={null}
			selected={selectedValue}
			isLoading={isLoading}
			onInputChange={onInputChange}
			emptyState={<div className={classes.userNodeHeight} />}
		/>
	);
};
