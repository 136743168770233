import React, { useMemo } from "react";
import { Map } from "immutable";
import classNames from "classnames";
import { TVariant, Typography } from "components/ui/legacy/Typography";
import { Unknown } from "components/ui/Unknown";
import { useStyles } from "./styles";

type TSize = "small" | "medium" | "large";
interface IProps {
	size?: TSize;
	imageUrl?: string | null;
	integrationName?: string;
	name?: string;
	deleted?: boolean;
}

const SIZE_VARIANT = Map<TSize, TVariant>([
	["small", "standard"],
	["medium", "h3"],
	["large", "h2"]
]);

const SIZE_INTEGRATION_VARIANT = Map<TSize, TVariant>([
	["small", "small"],
	["medium", "standard"],
	["large", "h3"]
]);

export const ResourceName: FC<IProps> = ({
	className,
	id,
	size = "small",
	imageUrl,
	innerRef,
	integrationName,
	name,
	deleted = false
}) => {
	const classes = useStyles();
	const imageSizeClass = useMemo(() => {
		if (size === "large") return classes.imageLarge;
		if (size === "medium") return classes.imageMedium;
		return classes.imageSmall;
	}, [classes, size]);
	const nameTextVariant = SIZE_VARIANT.get(size);

	return (
		<Typography
			innerRef={innerRef}
			id={id}
			className={classNames(classes.resourceContainer, className)}
			component="div"
			variant={nameTextVariant}>
			<Unknown unknown={!name}>
				<>
					{imageUrl && (
						<img
							className={classNames(classes.integrationImage, imageSizeClass)}
							src={imageUrl}
							alt={integrationName || name}
						/>
					)}
					<div className={classes.resourceName}>
						<Typography
							component="div"
							variant={nameTextVariant}
							className={classNames(classes.resourceNameText, deleted ? "deleted" : "")}>
							{name}
						</Typography>
						{integrationName && (
							<Typography component="div" variant={SIZE_INTEGRATION_VARIANT.get(size)}>
								{integrationName}
							</Typography>
						)}
					</div>
				</>
			</Unknown>
		</Typography>
	);
};
