import React from "react";
import classNames from "classnames";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useIsBeta } from "context/betaContext";
import { ErrorBoundary } from "react-error-boundary";
import { DefaultErrorBoundary } from "../ErrorBoundary";
import { PageRouter } from "./components/PageRouter";
import { TopNav } from "./components/TopNav";
import { SideNav } from "./components/SideNav";
import { SystemOverlays } from "./components/SystemOverlays";
import { useStyles } from "./styles";

export const Layout: FC = ({ className }) => {
	const classes = useStyles();
	const { user } = useAuthenticatedUser();
	const isBeta = useIsBeta();

	return (
		<div className={classNames(classes.columnLayout, className)}>
			<TopNav />
			<SystemOverlays />
			<div className={classNames(classes.rowLayout, className)}>
				{(!isBeta || user?.isAdmin) && <SideNav className={classes.sideNav} />}
				<ErrorBoundary FallbackComponent={DefaultErrorBoundary}>
					<div className={classes.pages}>{user && <PageRouter user={user} />}</div>
				</ErrorBoundary>
			</div>
		</div>
	);
};
