import { apiReq } from "utils/api/apiReq";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { IPaginationResponse, withPagination } from "utils/pagination";
import { List } from "immutable";
import { fromPaginationSortFilterOptions } from "utils/api/urlSearchParams";
import type { TTicketDuration } from "utils/durationsOptions";
import type { TPaginationSortFilterOptions } from "types/pagination";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { Require } from "types/utilTypes";

export type TIntegrationResourceRole = {
	allowedDurations?: List<TTicketDuration> | null;
	allowsRequests?: boolean;
	approvalAlgorithmId?: string | null;
	id?: string;
	integrationResourceId: string;
	name?: string;
	virtualizedRoleId?: string;
};

export type TFullIntegrationResourceRole = Omit<IntegrationResourceRoleModel, "integrationResource"> & {
	// The query returns those fields
	integrationResource: Require<IntegrationResourceModel, "id" | "integrationId" | "name" | "type" | "ownerId">;
};

export async function getIntegrationResourceRoles(
	options: TPaginationSortFilterOptions
): Promise<IPaginationResponse<TFullIntegrationResourceRole>> {
	const searchParams = fromPaginationSortFilterOptions(options);
	const querystring = searchParams?.size ? `?${searchParams.toString()}` : "";
	return withPagination(
		(role: Record<string, unknown>) =>
			IntegrationResourceRoleModel.fromServerData(role) as TFullIntegrationResourceRole,
		() => apiReq("GET", `/v1/integrationResourceRoles${querystring}`)
	);
}

export async function getIntegrationResourceRole(
	integrationResourceRoleId: string,
	withDeleted = false
): Promise<IntegrationResourceRoleModel> {
	const { data } = await apiReq(
		"GET",
		`/v1/integrationResourceRoles/${integrationResourceRoleId}?withDeleted=${withDeleted}`
	);
	return IntegrationResourceRoleModel.fromServerData(data);
}

export async function multiGetIntegrationResourceRoles(ids: string[], withDeleted = false) {
	const idsQuery = ids.map(id => `ids=${id}`).join("&");
	const { data } = await apiReq("GET", `/v1/integrationResourceRoles/multiGet?withDeleted=${withDeleted}&${idsQuery}`);
	const parsedData = data as Record<string, unknown>[];
	return parsedData.map(IntegrationResourceRoleModel.fromServerData);
}

export async function createIntegrationResourceRole(
	integrationResourceRole: TIntegrationResourceRole
): Promise<IntegrationResourceRoleModel> {
	const { data } = await apiReq("POST", `/v1/integrationResourceRoles`, integrationResourceRole);
	return IntegrationResourceRoleModel.fromServerData(data);
}

export async function editIntegrationResourceRole(
	integrationResourceRole: Partial<TIntegrationResourceRole>
): Promise<IntegrationResourceRoleModel> {
	const { data } = await apiReq(
		"PUT",
		`/v1/integrationResourceRoles/${integrationResourceRole.id}`,
		integrationResourceRole
	);
	return IntegrationResourceRoleModel.fromServerData(data);
}

export async function deleteIntegrationResourceRole(
	integrationResourceRoleId: string
): Promise<IntegrationResourceRoleModel> {
	const { data } = await apiReq("DELETE", `/v1/integrationResourceRoles/${integrationResourceRoleId}`);
	return IntegrationResourceRoleModel.fromServerData(data);
}
