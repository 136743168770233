import React, { useCallback, useMemo } from "react";
import { IntegrationModel } from "models/IntegrationModel";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { DropdownCellContent } from "components/ui/VirtualTable/components";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Typography } from "components/ui/Typography";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { IconPrefix } from "components/ui/IconPrefix";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { useStyles } from "./styles";
import type { TInherit } from "../IntegrationResourcesTable";

type TWorkflowProps = {
	defaultValue: string;
	integration: IntegrationModel | null;
	options: (ApprovalAlgorithmModel | "inherit")[];
	value?: "inherit" | ApprovalAlgorithmModel | null;
	disabled?: boolean;
	onChange: (value: ApprovalAlgorithmModel | "inherit" | null) => void;
};

const INHERIT = "inherit" as const;

export const WorkflowCell: FC<TWorkflowProps> = ({ defaultValue, integration, options, value, disabled, onChange }) => {
	const classes = useStyles();

	const getApprovalAlgorithmOptionLabel = useCallback(
		(option: ApprovalAlgorithmModel | TInherit) => (option === INHERIT ? defaultValue : option.name),
		[defaultValue]
	);

	const tooltipHeader = useMemo(
		() => (
			<Typography variant="body_sb" className={classes.tooltipHeader}>
				{defaultValue}
			</Typography>
		),
		[classes.tooltipHeader, defaultValue]
	);

	const tooltipBody = useMemo(() => {
		return integration && integration.defaultApprovalAlgorithm ? (
			<IconPrefix
				Icon={WorkflowsIcon}
				content={integration.defaultApprovalAlgorithm.name ?? ""}
				className={classes.tooltipBody}
			/>
		) : null;
	}, [classes.tooltipBody, integration]);

	const renderApprovalAlgorithmOptionLabel = useCallback(
		(option: ApprovalAlgorithmModel | TInherit) => {
			if (option === INHERIT) {
				return (
					<TitleTooltip header={tooltipHeader} body={tooltipBody}>
						<Typography variant="body_reg"> {defaultValue}</Typography>
					</TitleTooltip>
				);
			}

			return <TooltipOnOverflow textVariant={"body_sb"} content={option.name} />;
		},
		[defaultValue, tooltipBody, tooltipHeader]
	);

	return (
		<DropdownCellContent
			options={options}
			value={value}
			disabled={disabled}
			onChange={onChange}
			renderLabel={renderApprovalAlgorithmOptionLabel}
			getOptionLabel={getApprovalAlgorithmOptionLabel}
			hideClear
			sort={null}
		/>
	);
};
