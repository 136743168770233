import { useState } from "react";
import constate from "constate";

export type TSwitchAnimation =
	| "move-from-side"
	| "fade-move-from-bottom"
	| "fade-move-from-end"
	| "fade-move-from-center"
	| "fade"
	| "none";

export type TTransitionAnimationOptions = {
	animation: TSwitchAnimation;
	animate: boolean;
	reverse: boolean;
	movement: "in" | "out" | "standstill";
};

const DEFAULT_OPTIONS: TTransitionAnimationOptions = {
	animation: "fade-move-from-center",
	animate: false,
	reverse: false,
	movement: "standstill"
};

const useTransitionAnimation = ({ options = DEFAULT_OPTIONS }: { options?: TTransitionAnimationOptions }) => {
	const [animationIsRunning, setAnimationIsRunning] = useState(false);

	return { transitionOptions: options, animationIsRunning, setAnimationIsRunning };
};

export const [TransitionAnimationProvider, useTransitionAnimationContext] = constate(useTransitionAnimation);
