import React from "react";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { UserTooltip } from "components/common/UserTooltip";
import { UserModel } from "models/UserModel";
import { useUser } from "hooks/useUser";
import { Chip, TChipSize, TChipVariant } from "../Chip";
import { SkeletonChip } from "../SkeletonChip";
import type { TIconProps } from "components/ui/Icon";

interface IUserChipProps {
	SuffixIcon?: FC<TIconProps>;
	onDelete?: () => void;
	readonly?: boolean;
	selected?: boolean;
	size?: TChipSize;
	value: UserModel | string;
	variant?: TChipVariant;
}

export const UserChip: FC<IUserChipProps> = ({
	SuffixIcon,
	className,
	innerRef,
	onDelete,
	readonly = false,
	selected = false,
	size = "medium",
	value,
	variant = "regular"
}) => {
	const fetchedUser = useUser(typeof value === "string" ? value : "");
	const currentUser = typeof value === "string" ? fetchedUser : value;

	if (currentUser) {
		return (
			<UserTooltip user={currentUser}>
				<Chip
					noTooltip
					readonly={readonly}
					variant={variant}
					size={size}
					PrefixIcon={UserCircleIcon}
					SuffixIcon={SuffixIcon}
					onDelete={onDelete}
					selected={selected}
					className={className}
					innerRef={innerRef}>
					{currentUser?.fullName || null}
				</Chip>
			</UserTooltip>
		);
	}
	return <SkeletonChip size={size} />;
};
