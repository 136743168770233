import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	placeholder: {
		color: "var(--color-grey-600)",
		fontSize: "var(--typography-font-size-text)",
		fontStyle: "normal",
		fontWeight: "var(--typography-font-weight-regular)"
	},
	disabledPlaceholder: {
		extend: "placeholder",
		color: "var(--color-grey-400)"
	},
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		gap: "var(--spacing-x1, 4px)",
		justifyContent: "flex-start",
		minWidth: "140px",
		width: "100%"
	},
	top: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		gap: "var(--spacing-x1, 4px)",
		justifyContent: "center",
		"&$disabled": {
			color: "var(--color-grey-400)"
		}
	},
	textAreaContainer: {
		backgroundColor: "var(--color-white)",
		borderColor: "var(--color-grey-500)",
		borderRadius: "6px",
		borderStyle: "solid",
		borderWidth: "1px",
		display: "flex",
		minHeight: "44px",
		overflow: "auto",
		padding: "var(--spacing-x2, 8px)",
		position: "relative",
		width: "100%",
		"&$autoResize": {
			maxHeight: "50vh",
			"& > $textAreaHTMLComponent": {
				height: "auto"
			}
		},
		"&:hover:not($disabled), &$focused:not($disabled)": {
			backgroundColor: "var(--color-purple-50)"
		},
		"&$disabled": {
			borderColor: "var(--color-grey-400)",
			color: "var(--color-grey-400)"
		},
		"&$error": {
			borderColor: "var(--color-red-700)",
			borderWidth: "2px"
		},
		transitionDuration: "0.2s",
		transitionProperty: "background-color, border-color, border-width",
		transitionTimingFunction: "ease-in-out",
		willChange: "background-color, border-color, border-width"
	},
	textAreaHTMLComponent: {
		backgroundColor: "transparent",
		border: "none",
		fontFamily: "inherit",
		letterSpacing: "inherit",
		minHeight: "inherit",
		height: "100%",
		outline: "none",
		resize: "none",
		width: "100%",
		"&$disabled": {
			"&:placeholder": {
				extend: "disabledPlaceholder"
			},
			"&:-ms-input-placeholder": {
				extend: "disabledPlaceholder"
			},
			"&::-webkit-input-placeholder": {
				extend: "disabledPlaceholder"
			},
			"&::-moz-placeholder": {
				extend: "disabledPlaceholder"
			}
		},
		"&:placeholder": {
			extend: "placeholder"
		},
		"&:-ms-input-placeholder": {
			extend: "placeholder"
		},
		"&::-webkit-input-placeholder": {
			extend: "placeholder"
		},
		"&::-moz-placeholder": {
			extend: "placeholder"
		}
	},
	checkButton: {
		fontFamily: "Poppins",
		width: "fit-content",
		alignSelf: "end",
		position: "absolute",
		right: "var(--spacing-x05, 2px)",
		paddingBottom: 0,
		marginBottom: 0,
		bottom: "var(--spacing-x05, 2px)"
	},
	autoResize: {},
	focused: {},
	error: {},
	disabled: {}
});
