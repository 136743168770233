import { useEffect, useMemo } from "react";
import { useDirectoryGroupsContext } from "context/directoryGroupsContext";
import { List, Set } from "immutable";
import { notEmpty } from "utils/comparison";
import { devLog } from "utils/devtools/devLogging";

export const useMultiDirectoryGroups = (ids: (string | undefined | null)[], allowPartialResults = false) => {
	const {
		state: { directoryGroupsById },
		actions: { loadGroups }
	} = useDirectoryGroupsContext();

	const validIds = useMemo(() => {
		const invalidIds = ids.filter(id => id && typeof id !== "string");
		if (invalidIds.length) {
			devLog({ level: "warn", message: "Invalid multi get ids", extra: invalidIds });
		}
		return Set(ids.filter(id => id && typeof id === "string").filter(notEmpty));
	}, [ids]);

	useEffect(() => {
		const missingIds = validIds.filter(id => !directoryGroupsById.has(id));
		if (missingIds.size) {
			loadGroups(missingIds.toArray());
		}
	}, [directoryGroupsById, loadGroups, validIds]);

	const directoryGroups = useMemo(
		() =>
			List(validIds)
				.map(id => directoryGroupsById.get(id))
				.filter(notEmpty),
		[directoryGroupsById, validIds]
	);

	// if null, still loading
	return allowPartialResults || directoryGroups.size === validIds.size ? directoryGroups : null;
};
