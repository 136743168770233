import classNames from "classnames";
import React from "react";
import { useStyles } from "./styles";

type TImageUrlProps = {
	imageUrl?: string | null;
	imageClassName?: string;
};

type TIconProps = {
	icon: React.ReactNode;
};

export const OptionWithIcon: FC<TImageUrlProps | TIconProps> = props => {
	const classes = useStyles();
	const { className, children, innerRef, id } = props;
	if ("icon" in props) {
		return (
			<div className={classNames(classes.container, className)} ref={innerRef} id={id}>
				{props.icon}
				<div className={classes.text}>{children}</div>
			</div>
		);
	}
	if (!props.imageUrl)
		return (
			<div className={classes.text} ref={innerRef} id={id}>
				{children}
			</div>
		);
	return (
		<div className={classNames(classes.container, className)} ref={innerRef} id={id}>
			<img src={props.imageUrl} className={classNames(classes.image, props.imageClassName)} />
			<div className={classes.text}>{children}</div>
		</div>
	);
};
