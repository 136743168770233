import { apiReq, type TBody, type TMethod, type TOptions, type TResult } from "./apiReq";

const requests = new Map<string, AbortController[]>();

export async function apiReqAbortOld({
	key,
	method,
	path,
	body = null,
	options = {}
}: {
	key: string;
	method: TMethod;
	path: string;
	body?: TBody;
	options?: TOptions;
}): Promise<TResult> {
	if (!requests.has(key)) requests.set(key, []);
	requests.get(key)?.forEach(controller => controller.abort());

	const controller = new AbortController();
	requests.get(key)?.push(controller);
	try {
		return apiReq(method, path, body, { ...options, abortSignal: controller.signal });
	} finally {
		requests.get(key)?.splice(requests.get(key)!.indexOf(controller), 1);
	}
}
