import partition from "lodash/partition";
import { apiReq } from "utils/api/apiReq";
import { TicketModel, TTicketStatus } from "models/TicketModel";
import { exportToCSV } from "utils/api/downloadFile";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";
import { IPaginationResponse, withPagination } from "utils/pagination";
import { TTicketDuration } from "utils/durationsOptions";

export interface ITargetData {
	type: "role" | "bundle";
	id: string;
}

interface ITicketCreationData {
	comment: string;
	duration: number;
	receiverIntegrationActorIds?: Record<string, string | null>;
	ticketingIntegrationTicketId?: string;
	targets: ITargetData[];
	receiverId?: string;
	dry?: boolean;
}

export type TSearchTicketsParams = Partial<{
	creator: string | string[];
	accessDuration: Partial<{ min: TTicketDuration; max: TTicketDuration }>;
	requestedFor: string | string[];
	status: TTicketStatus | TTicketStatus[];
	createdAt: Partial<{ from: Date; to: Date }>;
	target: { bundle?: string | string[]; integration?: string; resource?: string; role?: string | string[] };
}>;

export async function getTickets(
	paginationOptions?: IPaginatedSearchOptions,
	searchParams?: TSearchTicketsParams
): Promise<IPaginationResponse<TicketModel>> {
	return withPagination(
		(ticket: Record<string, unknown>) => TicketModel.fromServerData(ticket),
		() => {
			let url = "/v1/tickets/search?";
			const paginationQs = getPaginatedSearchQueryString(paginationOptions);
			url += paginationQs ? "&" + paginationQs : "";
			return apiReq("POST", url, searchParams || {});
		}
	);
}

export async function exportTicketsToCSV(searchParams?: TSearchTicketsParams): Promise<void> {
	await exportToCSV("/v1/tickets/downloadCsv", "tickets.csv", searchParams || {});
}

export async function createNewTicket(creationData: ITicketCreationData) {
	const { data } = await apiReq("POST", "/v1/tickets", creationData);
	return TicketModel.fromServerData(data);
}

// Unused
export async function canRequestSameTicket(ticketId: string): Promise<boolean> {
	const { data } = await apiReq("GET", `/v1/tickets/${ticketId}/canRequestSameTicket`);
	return data?.canRequest || false;
}

export async function getTicket(ticketId: string) {
	const { data } = await apiReq("GET", `/v1/tickets/${ticketId}`);
	return TicketModel.fromServerData(data);
}
// Todo: rename on server to 'redirectFrom'
// cspell:ignore redirector
export async function redirectTicket(ticketId: string, redirector: string, redirectTo: string) {
	const { data } = await apiReq("PUT", `/v1/tickets/${ticketId}/redirect`, { redirector, redirectTo });
	return TicketModel.fromServerData(data);
}

export async function respondToTicket(ticketId: string, approved: boolean): Promise<TicketModel> {
	const { data } = await apiReq("PUT", `/v1/tickets/${ticketId}/${approved ? "approve" : "decline"}`);

	return TicketModel.fromServerData(data);
}

export async function adminRespondToTicket(ticketId: string, approved: boolean): Promise<TicketModel> {
	const { data } = await apiReq("PUT", `/v1/tickets/${ticketId}/${approved ? "adminApprove" : "adminDecline"}`);

	return TicketModel.fromServerData(data);
}

export async function retryTicket(ticketId: string): Promise<TicketModel> {
	const { data } = await apiReq("GET", `/v1/tickets/${ticketId}/retry`);
	return TicketModel.fromServerData(data);
}

export async function cancelTicket(ticketId: string): Promise<TicketModel> {
	const { data } = await apiReq("PUT", `/v1/tickets/${ticketId}/cancel`);

	return TicketModel.fromServerData(data);
}

export async function renewTicket(ticketId: string): Promise<TicketModel> {
	const { data } = await apiReq("POST", `/v1/tickets/${ticketId}/renew`);

	return TicketModel.fromServerData(data);
}

export const getMissingActorIntegrationIds = async (targets: ITargetData[], userId: string) => {
	const [bundleTargets, roleTargets] = partition(targets, target => target.type === "bundle");
	const bundlesQS = bundleTargets.map(({ id }) => `bundleIds=${id}`).join("&");
	const rolesQS = roleTargets.map(({ id }) => `roleIds=${id}`).join("&");
	const { data } = await apiReq(
		"GET",
		`/v1/tickets/integrationsWithMissingActors?receiverId=${userId}${rolesQS.length ? `&${rolesQS}` : ""}${
			bundlesQS.length ? `&${bundlesQS}` : ""
		}`
	);

	return (data as { id: string }[]).map(({ id }) => id);
};
