import React from "react";
import { SessionAuditLogModel } from "models/auditLogs/SessionAuditLogModel";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { ActionField } from "./components";

const INTEGRATION_INFO_PREFIX = "common.integrationLogDetails.generalInfo";

type TIntegrationLogActionFields = {
	integrationAuditLog?: SessionAuditLogModel;
};

export const IntegrationLogActionFields: FC<TIntegrationLogActionFields> = ({ integrationAuditLog }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.fieldsContainer}>
			{integrationAuditLog?.action ? (
				<ActionField
					monospace
					title={t(`${INTEGRATION_INFO_PREFIX}.fields.action`)}
					content={integrationAuditLog.action}
				/>
			) : null}
			{integrationAuditLog?.subject ? (
				<ActionField
					monospace
					title={t(`${INTEGRATION_INFO_PREFIX}.fields.subject`)}
					content={integrationAuditLog.subject}
				/>
			) : null}

			{integrationAuditLog?.target ? (
				<ActionField title={t(`${INTEGRATION_INFO_PREFIX}.fields.target`)} content={integrationAuditLog.target} />
			) : null}

			{integrationAuditLog?.status ? (
				<ActionField title={t(`${INTEGRATION_INFO_PREFIX}.fields.status`)} content={integrationAuditLog.status} />
			) : null}
		</div>
	);
};
