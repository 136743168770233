import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Map, List } from "immutable";
import { IntegrationModel } from "models/IntegrationModel";
import { BundleModel } from "models/BundleModel";
import { TRequestTarget } from "components/pages/NewRequestPage/types";
import { useStyles } from "./styles";
import { RequestCartHeader } from "./components/RequestCartHeader";
import { RequestCartItems } from "./components/RequestCartItems";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { TResourceCardCartOnlyProps } from "../ResourceCard/types";

export type TCartItem = {
	resource: IntegrationResourceModel;
	role: IntegrationResourceRoleModel;
};

export type TCartCardProps = Omit<TResourceCardCartOnlyProps, "selectedRoles" | "onRemoveResource">;

export type TResourceCartProps = {
	cart: Map<string, IntegrationModel | BundleModel>;
	// integrationId -> List<(resource, role)>
	cartItems: Map<string, List<TCartItem>>;
	loadingCartItems: Map<string, TRequestTarget>;
	// integrationId or bundleId
	removeAppFromCart: (id: string) => void;
	onRemoveResource: (resourceId: string) => void;
} & TCartCardProps; // cart resource cards props;

export const RequestCart: FC<TResourceCartProps> = ({
	className,
	innerRef,
	cart,
	cartItems,
	loadingCartItems,
	removeAppFromCart,
	...resourceCartCardProps
}) => {
	const classes = useStyles();
	const [selectedApp, setSelectedApp] = useState<string>();

	const setSelected = useCallback((key: string) => {
		setSelectedApp(current => (current !== key ? key : undefined));
	}, []);

	const cartApps = useMemo(() => cart.valueSeq().toList(), [cart]);

	const getCartItems = useCallback(
		(id: string) => {
			return cartItems.get(id) || List<TCartItem>();
		},
		[cartItems]
	);

	useEffect(() => {
		if (selectedApp && !cart.has(selectedApp)) {
			setSelectedApp(undefined);
		}
	}, [cart, selectedApp]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<RequestCartHeader
				cartApps={cartApps}
				loadingCartItems={loadingCartItems}
				getCartItems={getCartItems}
				removeAppFromCart={removeAppFromCart}
				selectedApp={selectedApp}
				setSelectedApp={setSelected}
			/>
			<RequestCartItems
				cart={cart}
				cartItems={cartItems}
				loadingCartItems={loadingCartItems}
				selectedApp={selectedApp}
				{...resourceCartCardProps}
			/>
		</div>
	);
};
