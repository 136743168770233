import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { useRulesContext } from "components/pages/RulesPage/RulesContext";
import { RuleModel } from "models/RuleModel";
import { useStyles } from "./styles";
import { RuleTypeSection } from "./RuleTypeSection";

type TRulesSectionProps = {
	shouldImmediatelyChangePriorityInServer?: boolean;
	showTypelessRules?: boolean;
};

export const RulesSection: FC<TRulesSectionProps> = ({
	shouldImmediatelyChangePriorityInServer = false,
	showTypelessRules = false
}) => {
	const {
		state: { roleRules, resourceRules, typelessRules },
		actions: { changePriority }
	} = useRulesContext();
	const classes = useStyles();

	const changeRulePriority = useCallback(
		(rule: RuleModel, newPriority: number) => {
			if (rule) {
				changePriority(rule, newPriority, shouldImmediatelyChangePriorityInServer);
			}
		},
		[changePriority, shouldImmediatelyChangePriorityInServer]
	);

	const { t } = useTranslation("translation", { keyPrefix: "pages.rules" });

	if (roleRules.size === 0 && resourceRules.size === 0 && (typelessRules.size === 0 || !showTypelessRules)) {
		return <div className={classes.emptyRulesContainer}>{t("emptyRules")}</div>;
	}
	return (
		<div className={classes.rulesSectionContainer}>
			{roleRules.size > 0 && (
				<RuleTypeSection
					changeRulePriority={changeRulePriority}
					title={t("roleRulesTitle")}
					infoExplanation={t("roleRulesExplanation")}
					Icon={RoleIcon}
					rules={roleRules}
				/>
			)}
			{resourceRules.size > 0 && (
				<RuleTypeSection
					changeRulePriority={changeRulePriority}
					title={t("resourceRulesTitle")}
					infoExplanation={t("resourceRulesExplanation")}
					Icon={ResourcesIcon}
					rules={resourceRules}
				/>
			)}
			{typelessRules.size > 0 && showTypelessRules && (
				<RuleTypeSection
					changeRulePriority={changeRulePriority}
					title={t("typelessRulesTitle")}
					rules={typelessRules}
				/>
			)}
		</div>
	);
};
