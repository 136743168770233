import React, { SVGProps, useMemo } from "react";
import classNames from "classnames";
import { requirePropertyOf } from "utils/security";
import { Tooltip } from "../Tooltip";
import { useStyles } from "./styles";

type TCssColor =
	| `#${string | number}`
	| `rgb(${number}, ${number}, ${number})`
	| `rgba(${number}, ${number}, ${number}, ${number})`
	| string;
type TColor = { color?: TCssColor };
type TSize = { size?: "small" | "medium" | "large" | "xl" | number };
type TBorder = { border?: boolean };
type TDisabled = { disabled?: boolean };
type TToolTip = { tooltip?: React.ReactNode };
type TClickable = { clickable?: boolean };

export type TIconProps = SVGProps<SVGElement> & TSize & TColor & TBorder & TDisabled & TToolTip & TClickable;

type TBaseIconComponent = FC<TIconProps & { svg: JSX.Element }>;

export const isIcon = (icon: unknown): icon is IconComponent => {
	return !!icon && !!(icon as IconComponent).isIconComponent;
};

const Icon: TBaseIconComponent = ({
	className,
	onClick,
	svg,
	color = "currentColor",
	size,
	disabled = false,
	border = false,
	innerRef,
	tooltip,
	clickable = !!onClick,
	...svgProps
}) => {
	const SVG = useMemo(() => {
		return React.Children.map(svg, child => React.cloneElement(child, svgProps));
	}, [svg, svgProps]);
	const classes = useStyles({ color, size: typeof size === "number" ? size : undefined });

	const sizeClassName = useMemo(() => {
		if (typeof size === "number") {
			return classes.customSize;
		}
		return size ? requirePropertyOf(classes, size) : undefined;
	}, [size, classes]);

	const component = (
		<div
			className={classNames(
				classes.icon,
				className,
				sizeClassName,
				{ [classes.border]: border, [classes.clickable]: clickable },
				disabled ? classes.disabled : ""
			)}
			onClick={onClick as React.MouseEventHandler}
			ref={innerRef as React.Ref<HTMLDivElement>}>
			{SVG}
		</div>
	);

	if (tooltip) {
		return (
			<Tooltip content={tooltip} delayShow={700}>
				{component}
			</Tooltip>
		);
	}

	return component;
};

export const makeIcon = (svg: JSX.Element): IconComponent => {
	const SVGIcon: IconComponent = props => {
		return <Icon {...props} svg={svg} />;
	};
	SVGIcon.isIconComponent = true;
	return SVGIcon;
};
