import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Set } from "immutable";
import { PaginatedVirtualTable, type TTableSelectionProps, type TColumn } from "components/ui/VirtualTable";
import { IntegrationImage } from "components/common/IntegrationImage";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import {
	HeaderCellContent,
	LinkCellContent,
	TextCellContent,
	ToggleSwitchCellContent,
	UserCellContent
} from "components/ui/VirtualTable/components";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { useApprovalAlgorithms, useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { useIntegrations } from "hooks/useIntegrations";
import { editIntegrationResourceRole, type TIntegrationResourceRole } from "api/integrationResourceRoles";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { useMultiUsers } from "hooks/useMultiUsers";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { Typography } from "components/ui/Typography";
import { UserCard } from "components/common/UserCard";
import { WorkflowCell } from "../WorkflowCell";
import type { Require } from "types/utilTypes";
import type { TGetProps } from "components/ui/VirtualTable/types";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

type TRoleWithResource = Require<IntegrationResourceRoleModel, "integrationResource">;

type TProps = {
	disabled?: boolean;
	fetchIntegrationResourceRoles: (page: number) => void;
	integrationResourceRoles: TRoleWithResource[];
	perPage?: number;
	onRoleUpdate: (role: Partial<TRoleWithResource> & { id: string }) => void;
	totalIntegrationResourceRoles: number;
} & TTableSelectionProps<TRoleWithResource>;

const COLUMNS_WIDTHS = [
	"minmax(196px, 340px)",
	"minmax(182px, 280px)",
	"minmax(176px, 1fr)",
	"minmax(176px, 1fr)",
	"minmax(168px, 280px)",
	"minmax(200px, 340px)",
	"180px"
] as const;

const INHERIT = "inherit" as const;
type TInherit = typeof INHERIT;

const DEFAULT_PER_PAGE = 10;

const useIntegrationResourceRolesTable = ({ disabled, onRoleUpdate }: Pick<TProps, "disabled" | "onRoleUpdate">) => {
	const integrations = useIntegrations();
	const approvalAlgorithms = useApprovalAlgorithms();
	const approvalAlgorithmList = useApprovalAlgorithmsList();

	const approvalAlgorithmOptions = useMemo<(ApprovalAlgorithmModel | TInherit)[]>(
		() => [INHERIT, ...(approvalAlgorithmList?.toArray() || [])],
		[approvalAlgorithmList]
	);

	const [loadingIntegrationResourceRoles, setLoadingIntegrationResourceRoles] = useState(Set<string>());

	const onUpdateIntegrationResourceRole = useCallback(
		async (
			role: Pick<Partial<TIntegrationResourceRole>, "allowsRequests" | "approvalAlgorithmId"> & {
				id: string;
			}
		) => {
			setLoadingIntegrationResourceRoles(current => current.add(role.id));
			await editIntegrationResourceRole(role);
			setLoadingIntegrationResourceRoles(current => current.remove(role.id));
			onRoleUpdate(role);
		},
		[onRoleUpdate]
	);

	const onUpdateAllowsRequests = useCallback(
		async (integrationResourceRoleId: string, allowsRequests: boolean) => {
			await onUpdateIntegrationResourceRole({ id: integrationResourceRoleId, allowsRequests });
		},
		[onUpdateIntegrationResourceRole]
	);

	const onUpdateApprovalAlgorithm = useCallback(
		async (integrationResourceRoleId: string, newApprovalAlgorithm: ApprovalAlgorithmModel | TInherit) => {
			await onUpdateIntegrationResourceRole({
				id: integrationResourceRoleId,
				approvalAlgorithmId: newApprovalAlgorithm === INHERIT ? null : newApprovalAlgorithm.id
			});
		},
		[onUpdateIntegrationResourceRole]
	);

	const getIsDisabled = useCallback(
		(entity: string | TRoleWithResource) =>
			disabled || loadingIntegrationResourceRoles.has(typeof entity === "string" ? entity : entity.id),
		[disabled, loadingIntegrationResourceRoles]
	);

	const getIntegration = useCallback(
		(integrationResourceRole: TRoleWithResource) => {
			return integrations?.get(integrationResourceRole.integrationResource?.integrationId || "") || null;
		},
		[integrations]
	);

	return {
		approvalAlgorithmOptions,
		approvalAlgorithms,
		getIntegration,
		getIsDisabled,
		onUpdateAllowsRequests,
		onUpdateApprovalAlgorithm
	};
};

export const IntegrationResourceRolesTable: FC<TProps> = ({
	className,
	disabled = false,
	fetchIntegrationResourceRoles,
	innerRef,
	isLoading,
	integrationResourceRoles,
	perPage = DEFAULT_PER_PAGE,
	onRoleUpdate: onRoleUpdate,
	totalIntegrationResourceRoles,
	...selectionProps
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "common.tables.integrationResourceRoles" });
	const {
		approvalAlgorithmOptions,
		approvalAlgorithms,
		getIntegration,
		getIsDisabled,
		onUpdateAllowsRequests,
		onUpdateApprovalAlgorithm
	} = useIntegrationResourceRolesTable({ disabled, onRoleUpdate: onRoleUpdate });

	const resourceOwnerIds = useMemo(
		() => integrationResourceRoles.map(role => role.integrationResource.ownerId),
		[integrationResourceRoles]
	);

	const resourcesOwners = useMultiUsers(resourceOwnerIds);

	const getOwnerLabel = useCallback(
		(integrationResourceRole: TRoleWithResource) => {
			const integration = getIntegration(integrationResourceRole);
			if (!integration) return null;

			return (
				<TitleTooltip
					header={<Typography variant="body_sb">{t("selectNullValue")}</Typography>}
					body={<UserCard user={integration.ownerId} />}>
					<Typography variant="body_reg"> {t("selectNullValue")}</Typography>
				</TitleTooltip>
			);
		},
		[getIntegration, t]
	);

	const columns = useMemo<TColumn<TRoleWithResource>[]>(() => {
		const columns: TColumn<TRoleWithResource>[] = [
			{
				renderCell: TextCellContent,
				getProps: integrationResourceRole => {
					const integration = getIntegration(integrationResourceRole);
					return {
						icon: integration ? (
							<IntegrationImage noWrap size="xs" integration={integration} />
						) : (
							<IntegrationIcon size={20} />
						),
						text: integration?.name ?? "",
						disabled: getIsDisabled(integrationResourceRole.id)
					};
				},
				header: <HeaderCellContent text={t("headers.integration")} icon={<IntegrationIcon />} />,
				key: "integration",
				width: COLUMNS_WIDTHS[0]
			},
			{
				renderCell: TextCellContent,
				getProps: integrationResourceRole => {
					return {
						text: integrationResourceRole.integrationResource?.type ?? "",
						disabled: getIsDisabled(integrationResourceRole.id)
					};
				},
				header: <HeaderCellContent text={t("headers.resourceType")} />,
				key: "resourceType",
				width: COLUMNS_WIDTHS[1]
			},
			{
				renderCell: LinkCellContent,
				getProps: integrationResourceRole => {
					return {
						url: `/integrations/${integrationResourceRole.integrationResource.integrationId}/resources/${integrationResourceRole.integrationResourceId}`,
						text: integrationResourceRole.integrationResource.name,
						disabled: getIsDisabled(integrationResourceRole.id)
					};
				},
				header: <HeaderCellContent text={t("headers.integrationResource")} icon={<ResourcesIcon />} />,
				key: "resource",
				width: COLUMNS_WIDTHS[2]
			},
			{
				renderCell: LinkCellContent,
				getProps: integrationResourceRole => {
					return {
						url: `/integrations/${integrationResourceRole.integrationResource.integrationId}/resources/${integrationResourceRole.integrationResource.id}/roles/${integrationResourceRole.id}`,
						text: integrationResourceRole.name,
						disabled: getIsDisabled(integrationResourceRole.id)
					};
				},
				header: <HeaderCellContent text={t("headers.integrationResourceRole")} icon={<ResourcesIcon />} />,
				key: "role",
				width: COLUMNS_WIDTHS[3]
			},
			{
				renderCell: (integrationResourceRole, options) => (
					<WorkflowCell
						integration={getIntegration(integrationResourceRole)}
						defaultValue={t("selectNullValue")}
						options={approvalAlgorithmOptions}
						value={
							integrationResourceRole.approvalAlgorithmId
								? approvalAlgorithms?.get(integrationResourceRole.approvalAlgorithmId)
								: INHERIT
						}
						disabled={options.disabled || getIsDisabled(integrationResourceRole.id)}
						onChange={newApprovalAlgorithm => {
							newApprovalAlgorithm && onUpdateApprovalAlgorithm(integrationResourceRole.id, newApprovalAlgorithm);
						}}
					/>
				),
				header: <HeaderCellContent text={t("headers.approvalAlgorithm")} icon={<WorkflowsIcon />} />,
				key: "workflow",
				width: COLUMNS_WIDTHS[4]
			},
			{
				renderCell: UserCellContent,
				getProps: integrationResourceRole => ({
					user: resourcesOwners?.get(integrationResourceRole.integrationResource.ownerId || ""),
					emptyState: getOwnerLabel(integrationResourceRole),
					disabled: getIsDisabled(integrationResourceRole.id)
				}),
				header: <HeaderCellContent text={t("headers.owner")} icon={<OwnerIcon />} />,
				key: "owner",
				width: COLUMNS_WIDTHS[5]
			} as TColumn<TRoleWithResource>,
			{
				renderCell: ToggleSwitchCellContent,
				getProps: (integrationResourceRole => ({
					disabled: getIsDisabled(integrationResourceRole.id),
					checked: integrationResourceRole.allowsRequests,
					onChange: checked => {
						onUpdateAllowsRequests(integrationResourceRole.id, checked);
					}
				})) as TGetProps<IntegrationResourceRoleModel, typeof ToggleSwitchCellContent>,
				header: <HeaderCellContent text={t("headers.requestable")} icon={<RequestsIcon />} />,
				key: "requestable",
				width: COLUMNS_WIDTHS[6]
			}
		];
		return columns;
	}, [
		t,
		getIntegration,
		getIsDisabled,
		approvalAlgorithmOptions,
		approvalAlgorithms,
		onUpdateApprovalAlgorithm,
		resourcesOwners,
		getOwnerLabel,
		onUpdateAllowsRequests
	]);

	return (
		<PaginatedVirtualTable
			isLoading={isLoading}
			className={className}
			innerRef={innerRef}
			columns={columns}
			rows={integrationResourceRoles}
			totalRows={totalIntegrationResourceRoles || integrationResourceRoles.length}
			perPage={perPage}
			fetchPage={fetchIntegrationResourceRoles}
			shouldDisableRow={getIsDisabled}
			{...selectionProps}
		/>
	);
};
