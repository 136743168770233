import classNames from "classnames";
import { TicketApprovalEntityModel } from "models/TicketApprovalEntityModel";
import { UserModel } from "models/UserModel";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { TC } from "utils/tickets/ticketActivity";
import { EmailTooltip } from "../EmailTooltip";
import { Entity } from "../Entity";
import { TextWithDirectoryIcon } from "../TextWithDirectoryIcon";
import { useStyles } from "./styles";

interface IProps {
	bold?: boolean;
	content: string;
	ticketApprovalEntity?: TicketApprovalEntityModel;
	noWrap?: boolean;
	user: UserModel;
}

export const ApproverTypeUser: FC<IProps> = ({ bold, className, content, ticketApprovalEntity, user, noWrap }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const entityRole = ticketApprovalEntity
		? t(`common.ticketList.${ticketApprovalEntity.type}`, { groupName: ticketApprovalEntity.displayName, count: 1 })
		: null;

	const isDirectoryGroup = ticketApprovalEntity?.type === "DirectoryGroup";

	const approverTypeComponent = isDirectoryGroup ? (
		<TextWithDirectoryIcon noWrap={noWrap} value={entityRole || ""} />
	) : (
		<TC content={entityRole} />
	);

	return (
		<EmailTooltip user={user}>
			<div className={classNames(classes.entity, className)}>
				{entityRole ? (
					<Trans
						t={t}
						i18nKey={"common.approverWithType"}
						components={{
							user: <Entity bold={bold} content={content} isDeleted={user.isDeleted} noWrap={noWrap} />,
							approverType: approverTypeComponent
						}}
						values={approverTypeComponent}
					/>
				) : (
					<Entity bold content={content} isDeleted={user.isDeleted} className={className} noWrap={noWrap} />
				)}
			</div>
		</EmailTooltip>
	);
};
