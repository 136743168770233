import React from "react";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { ResourceDetailsTooltip } from "components/common/ResourceDetailsTooltip";
import { useIntegrations } from "hooks/useIntegrations";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { Chip, TChipVariant, type TChipSize } from "../Chip";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

interface IResourceChipProps {
	integrationResource: IntegrationResourceModel;
	onDelete?: () => void;
	readonly?: boolean;
	selected?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
}

export const ResourceChip: FC<IResourceChipProps> = ({
	className,
	innerRef,
	integrationResource,
	onDelete,
	readonly = false,
	selected = false,
	size = "medium",
	variant = "regular"
}) => {
	const integrations = useIntegrations();
	const resourceIntegration = integrations?.get(integrationResource.integrationId);
	const resourceImage = resourceIntegration?.imageUrl;

	return (
		<ResourceDetailsTooltip
			name={integrationResource.name}
			type={integrationResource.type}
			id={integrationResource.id}
			euid={integrationResource.euid}
			description={integrationResource.description}>
			<Chip
				noTooltip
				readonly={readonly}
				variant={variant}
				size={size}
				PrefixIcon={resourceImage ? getDynamicSizeIcon(<img src={resourceImage}></img>) : ResourcesIcon}
				onDelete={onDelete}
				selected={selected}
				className={className}
				innerRef={innerRef}>
				{`${integrationResource.name}`}
			</Chip>
		</ResourceDetailsTooltip>
	);
};
