import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LoadingDots } from "components/ui/LoadingDots";
import { Typography } from "components/ui/legacy/Typography";
import { useAccessReviewReport } from "hooks/useAccessReviewReport";
import { AccessReviewSubordinateModel } from "models/AccessReviewSubordinateModel";
import { UserWithEmail } from "components/common/UserWithEmail";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { Table } from "components/ui/Table";
import { Unknown } from "components/ui/Unknown";
import { PageSelect } from "components/ui/PageSelect";
import { ASC, usePagination } from "hooks/usePagination";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { getSubordinates } from "api/accessReviewsReport";
import { useMultiUsers } from "hooks/useMultiUsers";
import { useStyles } from "./styles";

interface IProps {
	reportId: string;
	onViewClick: (subordinate: AccessReviewSubordinateModel | null) => void;
	showTitle?: boolean;
}
const PAGE_SIZE = 9;

export const SubordinatesReviewTable: FC<IProps> = ({ className, id, innerRef, reportId, onViewClick, showTitle }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { accessReviewReport, getActionLoading } = useAccessReviewReport(reportId);

	const fetchSubordinates = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => {
			return getSubordinates(reportId, paginationOptions);
		},
		[reportId]
	);

	const { totalPages, currentPageNumber, page, changePage } = usePagination({
		fetchItems: fetchSubordinates,
		perPage: PAGE_SIZE,
		sortOrder: ASC,
		initialFilter: null
	});

	const sortedSubordinates = useMemo(() => page?.sortBy(s => s.userId).toArray() || [], [page]);
	const users = useMultiUsers(sortedSubordinates.map(sub => sub.userId));

	const onViewClicked = useCallback(
		(subordinate: AccessReviewSubordinateModel | null) => {
			onViewClick(subordinate);
		},
		[onViewClick]
	);

	const onPageChange = useCallback(
		(num: number) => {
			changePage(num - 1);
		},
		[changePage]
	);

	const headers: string[] = [
		t("common.subordinatesReviewTable.columns.subordinate"),
		t("common.subordinatesReviewTable.columns.numberOfEntitlements"),
		t("common.subordinatesReviewTable.columns.numberOfApproved"),
		t("common.subordinatesReviewTable.columns.numberOfDenied"),
		t("common.subordinatesReviewTable.columns.status")
	];

	if (!accessReviewReport) {
		return getActionLoading ? <LoadingDots center /> : null;
	}
	if (!sortedSubordinates || sortedSubordinates.length === 0) {
		return null;
	}
	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			{showTitle && (
				<Typography variant="h3">
					{t("common.subordinatesReviewTable.title", { count: sortedSubordinates.length })}
				</Typography>
			)}
			<>
				<Table outline gridColumns="3fr repeat(4, 2fr)" overflow>
					<Table.Row>
						{headers.map(title => (
							<Table.Header key={title}>
								<Typography>{title}</Typography>
							</Table.Header>
						))}
					</Table.Row>
					{sortedSubordinates.map(subordinate => {
						const user = users?.get(subordinate.userId);
						const amountApproved = subordinate.statistics?.permissionsStatus?.approved;
						const amountDenied = subordinate.statistics?.permissionsStatus?.denied;
						const onClick = () => onViewClicked(subordinate);
						return (
							<Table.Row key={subordinate.id} onClick={onClick}>
								<Table.Cell>
									<Unknown unknown={!user}>{user && <UserWithEmail user={user} />}</Unknown>
								</Table.Cell>
								<Table.Cell>
									<Typography>{subordinate.permissionsSize}</Typography>
								</Table.Cell>
								<Table.Cell>
									<Typography className={classes.statistics}>
										{amountApproved ? (
											<>
												<DoneCircleIcon />
												{amountApproved}
											</>
										) : (
											"-"
										)}
									</Typography>
								</Table.Cell>
								<Table.Cell>
									<Typography className={classes.statistics}>
										{amountDenied ? (
											<>
												<CloseCircleIcon />
												{amountDenied}
											</>
										) : (
											"-"
										)}
									</Typography>
								</Table.Cell>
								<Table.Cell>
									<Typography>
										{t(`common.subordinatesReviewTable.subordinateStatuses.${subordinate.status || "pending"}`)}
									</Typography>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table>
				{totalPages > 1 && (
					<PageSelect
						pagesCount={totalPages}
						pagesShownAmount={3}
						currentPageNumber={currentPageNumber}
						changePage={onPageChange}
					/>
				)}
			</>
		</div>
	);
};
