import React, { ReactNode } from "react";
import classNames from "classnames";
import { useStyles } from "./styles";
import type { IRowOptions } from "../../types";

const IconCellContent: FC<{ icon: ReactNode } & IRowOptions> = ({ icon, className }) => {
	const classes = useStyles();
	return (
		<div className={classNames(className, classes.centeredCellContent)}>
			<div className={classes.icon}>{icon}</div>
		</div>
	);
};

const IconCellContentMemo = React.memo(IconCellContent) as typeof IconCellContent;

export { IconCellContentMemo as IconCellContent };
