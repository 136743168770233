import { TicketApprovalEntityModel } from "models/TicketApprovalEntityModel";
import { TicketModel } from "models/TicketModel";
import { Typography } from "components/ui/legacy/Typography";
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import uniq from "lodash/uniq";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import { DURATION_OPTIONS } from "utils/durationsOptions";
import { useIntegrations } from "hooks/useIntegrations";
import classNames from "classnames";
import { TooltipOnOverflow } from "components/ui/legacy/TooltipOnOverflow";
import { getApprovalIconAndText } from "utils/tickets/ticketStatus";
import { ApprovalStatusChip, TApprovalStatus } from "components/common/ApprovalStatusChip";
import { ImageGrid } from "components/common/ImageGrid";
import { Entity } from "components/common/Entity";
import { LoadingDots } from "components/ui/LoadingDots";
import { TicketApprovalResponseModel } from "models/TicketApprovalResponseModel";
import { TicketApprovalRequestModel } from "models/TicketApprovalRequestModel";
import { IsNullError } from "utils/errors/isNullError";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { Tooltip } from "components/ui/Tooltip";
import { useTicketApprovers } from "hooks/useTicketApprovers";
import { useBundles } from "hooks/useBundles";
import { useMultiUsers } from "hooks/useMultiUsers";
import { useUser } from "hooks/useUser";
import { UserWithEmail } from "../UserWithEmail";
import { ApprovalFlowSimulation } from "../ApprovalFlowSimulation";
import { UserTooltip } from "../UserTooltip";
import { TicketingIntegrationTicketChip } from "../TicketingIntegrationTicketChip";
import { TC } from "../../../utils/tickets/ticketActivity";
import { useStyles } from "./styles";
import { useApproverChip } from "./useApproverChip";
import type { TChipSize } from "components/ui/chips/Chip";

export type TRespondersInGroup = [string[], TApprovalStatus];

const TRANSLATION_PREFIX = "common.ticketList.";

const Cell: FC = ({ className, children, innerRef }) => {
	const classes = useStyles();
	return (
		<div className={classNames(classes.cell, className)} ref={innerRef}>
			{children}
		</div>
	);
};

export const ResourceCell: FC<{ data: TicketModel }> = ({ data: ticket }) => {
	const { targets } = ticket;
	const resourceRole = useMemo(() => {
		const firstTicketPermission = ticket.ticketPermissions?.first();
		return firstTicketPermission?.role;
	}, [ticket]);
	const integrations = useIntegrations(true);
	const bundles = useBundles(true);
	const { t } = useTranslation();
	const [singleRow, setSingleRow] = useState(false);

	const classes = useStyles();
	const isMultiple = useMemo(() => targets && targets.size > 1, [targets]);

	const images = useMemo(() => {
		if (isMultiple) {
			return null;
		}
		if (!ticket?.ticketPermissions) {
			return null;
		} else {
			return uniq(
				ticket.ticketPermissions
					.map(ticketPermission => {
						const integrationId = ticketPermission.role?.integrationResource?.integrationId;

						return integrationId && integrations?.has(integrationId) ? integrations.get(integrationId)!.imageUrl : null;
					})
					.filter((imageUrl): imageUrl is string => typeof imageUrl === "string")
					.toArray()
			);
		}
	}, [isMultiple, ticket.ticketPermissions, integrations]);

	const Name = useMemo(() => {
		if (!ticket || !targets) return null;
		if (isMultiple) {
			setSingleRow(true);
			return (
				<Typography variant="h3">
					{t(`${TRANSLATION_PREFIX}multipleTargetsName`, {
						amount: targets.size
					})}
				</Typography>
			);
		}
		const isBundle = targets?.size === 1 && targets?.first()?.type === "bundle";
		if (isBundle) {
			setSingleRow(true);
			const target = targets.first();
			const bundleName = target?.bundle?.name || (target?.targetId && bundles?.get(target?.targetId)?.name) || "";
			return (
				<Typography variant={isBundle ? "h3" : "standard"} className={classNames({ [classes.subText]: !isBundle })}>
					{bundleName}
				</Typography>
			);
		}
		const integration = integrations?.get(resourceRole?.integrationResource?.integrationId || "");
		if (!resourceRole || !integration) {
			setSingleRow(true);
			return <LoadingDots center />;
		}
		setSingleRow(false);
		return (
			<>
				<Typography variant="h3">{integration.name}</Typography>
				<Typography className={classes.subText}>{resourceRole.integrationResource?.name}</Typography>
				<Typography className={classes.subText}>{resourceRole.name}</Typography>
			</>
		);
	}, [bundles, classes.subText, integrations, isMultiple, resourceRole, t, targets, ticket]);

	return (
		<Cell>
			<div className={classNames(classes.resource, { [classes.singleRow]: singleRow })}>
				<div className={classes.resourceLogoContainer}>
					<ImageGrid images={images || []} imageGridClass={classes.resourceLogo} />
				</div>
				<div className={classes.targetContainer}>{Name}</div>
			</div>
		</Cell>
	);
};

export const CreationTimeCell: FC<{ data: TicketModel }> = ({ data }) => {
	const { createdAt } = data;
	const { t } = useTranslation();
	const classes = useStyles();

	const date = t(`${TRANSLATION_PREFIX}creationDate`, { date: createdAt });
	const time = t(`${TRANSLATION_PREFIX}creationTimeValue`, { date: createdAt });

	return (
		<Cell className={classes.creationDate}>
			<TooltipOnOverflow content={date} />
			<TooltipOnOverflow content={time} />
		</Cell>
	);
};

export const RequestedForCell: FC<{ data: TicketModel }> = ({ className, data }) => {
	const { creatorId, receiverId, justification } = data;
	const classes = useStyles();
	const { t } = useTranslation();
	const users = useMultiUsers([receiverId, creatorId]);
	const sameUser = creatorId === receiverId;
	const receiver = users?.get(receiverId);
	const creator = sameUser ? receiver : users?.get(creatorId);
	if (!users) {
		return <LoadingDots />;
	}
	return (
		<div className={classNames(classes.column, classes.requestedFor)}>
			{receiver && <UserWithEmail user={receiver} nameSize="standard" avatarSize="medium" wrap />}
			{!sameUser && (
				<div>
					<UserTooltip user={creator}>
						<Typography className={classNames(classes.requestedBy, className)} variant="tiny">
							<Trans
								t={t}
								i18nKey="common.ticketList.by"
								components={{
									user: creator ? <Entity content={creator.fullName} /> : <TC />
								}}
							/>
						</Typography>
					</UserTooltip>
				</div>
			)}
			{justification && (
				<Typography className={classes.justificationCell}>
					<Trans
						t={t}
						i18nKey="common.ticketList.justificationComment"
						components={{ justification: <TC content={justification} /> }}
					/>
				</Typography>
			)}
		</div>
	);
};

const getCurrentRequests = (data: TicketModel): TicketApprovalRequestModel[] => {
	if (!data.approvalRequests) {
		throw IsNullError.from({
			location: "getCurrentRequest",
			parentObject: {
				name: "data",
				value: data.toJS()
			},
			requestedProperty: "approvalRequests"
		});
	}
	const sortedRequests = data.approvalRequests.sortBy(request => request.sortOrder);

	let currentRequests = sortedRequests.filter(request => request.active || request.status === "declined").toArray();
	if (currentRequests.length === 0) {
		const lastSortOrder = sortedRequests.last()?.sortOrder;
		if (lastSortOrder) {
			currentRequests = sortedRequests.filter(request => request.sortOrder === lastSortOrder).toArray();
		} else {
			currentRequests = [new TicketApprovalRequestModel()];
		}
	}
	return currentRequests;
};

type TApprovalRequestProps = {
	approvalRequest: TicketApprovalRequestModel;
	data: TicketModel;
};

const ApprovalRequest: FC<TApprovalRequestProps> = ({ approvalRequest, data }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { getRespondersInGroup, getApprovalStatus } = useTicketApprovers(data);

	const { operator, approvalEntities } = approvalRequest;
	const operatorKey = `flow.${operator || "and"}` as const;

	const uniqueApprovers = useMemo(() => {
		return uniqWith(approvalEntities?.toArray(), (first, second) => isEqual(first.approverIds, second.approverIds));
	}, [approvalEntities]);

	return (
		<div className={classes.approvalRequest}>
			{uniqueApprovers.map((entity: TicketApprovalEntityModel, index: number) => (
				<React.Fragment key={entity.id}>
					<div className={classes.approversList}>
						<Approver
							entity={entity}
							getApprovalStatus={getApprovalStatus}
							getRespondersInGroup={getRespondersInGroup}
						/>
					</div>
					{index === uniqueApprovers.length - 1 ? null : <Typography>{t(operatorKey)}</Typography>}
				</React.Fragment>
			))}
		</div>
	);
};

export const ApproversCell: FC<{ data: TicketModel }> = ({ data }) => {
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const adminResponse = useMemo(() => {
		if (data.status !== "waitingForApproval") {
			return (
				data?.ticketApprovalResponses?.find(
					response => response.type === "adminApprove" || response.type === "adminDecline"
				) || null
			);
		}
		return null;
	}, [data.status, data.ticketApprovalResponses]);

	const currentRequests = useMemo(() => {
		try {
			return getCurrentRequests(data);
		} catch (error) {
			openGlobalErrorModal(error as Error);
			return [new TicketApprovalRequestModel()];
		}
	}, [data, openGlobalErrorModal]);

	const classes = useStyles();

	return (
		<Tooltip
			className={classes.workflowTooltip}
			content={
				<ApprovalFlowSimulation
					ticket={data}
					withStatus
					direction="horizontal"
					noBorder
					noBackground
					noModal
					adminAction={!!adminResponse?.userId}
				/>
			}
			trigger="hover"
			placement="bottom-start">
			<div className={classNames(classes.column, classes.approvalRequests)}>
				{adminResponse ? (
					<AdminApprover adminResponse={adminResponse} />
				) : (
					currentRequests.flatMap(request => <ApprovalRequest approvalRequest={request} data={data} key={request.id} />)
				)}
			</div>
		</Tooltip>
	);
};

const AdminApprover: FC<{ adminResponse: TicketApprovalResponseModel }> = ({ adminResponse }) => {
	const admin = useUser(adminResponse.userId!);

	const status = adminResponse.type === "adminApprove" ? "approved" : "rejected";
	return admin ? (
		<ApprovalStatusChip
			approvalStatus={status}
			approverName={admin?.fullName || ""}
			approverType="user"
			isDeleted={admin?.isDeleted}
			tooltip={admin?.email}
		/>
	) : (
		<LoadingDots />
	);
};

interface IApproverProps {
	entity: TicketApprovalEntityModel;
	getApprovalStatus: (entity: TicketApprovalEntityModel) => TApprovalStatus;
	getRespondersInGroup: (userIds: string[]) => TRespondersInGroup;
	noBorder?: boolean;
	noModal?: boolean;
	showStatus?: boolean;
	size?: TChipSize;
	withIcon?: boolean;
}

export const Approver: FC<IApproverProps> = ({
	className,
	entity,
	getApprovalStatus,
	getRespondersInGroup,
	noBorder = true,
	noModal,
	showStatus = true,
	size = "medium",
	withIcon = true
}) => {
	const statusChipProps = useApproverChip(entity, getApprovalStatus, getRespondersInGroup);

	return (
		<ApprovalStatusChip
			className={className}
			{...statusChipProps}
			noModal={noModal}
			showStatus={showStatus}
			withIcon={withIcon}
			noBorder={noBorder}
			size={size}
		/>
	);
};

export const DurationCell: FC<{ data: TicketModel }> = ({ data: ticket }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { ticketingIntegrationTicket } = ticket;

	const content = useMemo(() => {
		const durationInSec = ticket?.duration;
		if (!durationInSec) {
			return <TooltipOnOverflow content={t(`common.durations.unmanaged`)} />;
		}
		if (DURATION_OPTIONS.includes(durationInSec)) {
			const value = t(`common.durations.${durationInSec}`);
			return <TooltipOnOverflow content={value || ""} />;
		} else return <TooltipOnOverflow content={t(`common.durations.unknown`)} />;
	}, [ticket?.duration, t]);

	return (
		<Cell
			className={
				ticket.ticketingIntegrationTicket ? classes.thirdPartyTicketDurationCell : classes.regularDurationCell
			}>
			{content}
			{ticketingIntegrationTicket && (
				<TicketingIntegrationTicketChip
					className={classes.ticketingIntegrationTicketChip}
					label={<Typography variant="tiny">{t("common.ticketList.linkedTo")}</Typography>}
					ticketingIntegrationTicket={ticketingIntegrationTicket}
				/>
			)}
		</Cell>
	);
};

export const TicketNumberCell: FC<{ data: TicketModel }> = ({ data }) => {
	const classes = useStyles();
	return (
		<Cell className={classes.basicCell}>
			<TooltipOnOverflow content={data.number} />
		</Cell>
	);
};

export const StatusCell: FC<{ data: TicketModel }> = ({ data }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { status } = data;
	const [Icon, text] = getApprovalIconAndText(status, t);

	return (
		<Cell className={classes.statusContainer}>
			<Tooltip content={text}>
				<Icon className={classes.statusIcon} />
			</Tooltip>
		</Cell>
	);
};
