import { CompanyModel } from "models/CompanyModel";
import { getPageLocation } from "routes/utils";
import { devLog } from "utils/devtools/devLogging";
import type { UserModel } from "../models/UserModel";
import type { TSwitchAnimation } from "context/transitionAnimationContext";

type TAnimationObject = {
	name: TSwitchAnimation;
	reverse?: boolean;
	targets?: string[];
};

interface IRoute {
	matches: string[];
	animationOrder?: number;
	inAnimation?: TAnimationObject;
	outAnimation?: TAnimationObject;
	Page: FC;
	pageId: TPageId;
	subRoutes?: Record<string, Route>;
	shouldRender?: (options: TShouldRenderOptions) => boolean;
	hideSideNav?: boolean;
}

type TShouldRenderOptions = {
	user: UserModel;
	company?: CompanyModel;
	isBeta?: boolean;
};

export type TPageId =
	| "AccessReport"
	| "AccessReview"
	| "AccessReviewReport"
	| "AccessReviewTemplatePage"
	| "AccessReviewTemplatesPage"
	| "AdminResourceAccessReview"
	| "AdminSubordinateAccessReview"
	| "AuditLogs"
	| "BetaTrigger"
	| "BulkActions"
	| "Bundles"
	| "CreateIntegration"
	| "CreateWorkflow"
	| "EditWorkflow"
	| "Home"
	| "IdentityGraph"
	| "Integration"
	| "IntegrationResource"
	| "IntegrationResourceRole"
	| "Integrations"
	| "MyPermissions"
	| "MyRequests"
	| "NewRequest"
	| "NewTicket"
	| "PastRequests"
	| "PendingRequests"
	| "Policies"
	| "Profile"
	| "Requests"
	| "RequestsLog"
	| "ResourceAccessReport"
	| "Settings"
	| "SubordinateAccessReport"
	| "Tasks"
	| "User"
	| "Users"
	| "Rules"
	| "RulesCreate"
	| "RulesEdit"
	| "Workflows"
	| "WorkflowsCreate"
	| "WorkflowsEdit"
	| "WorkflowsWebhooks";

export type TShouldRenderFunction = (options: TShouldRenderOptions) => boolean;

export class Route {
	public matches: string[];

	public animationOrder?: number;
	public inAnimation?: TAnimationObject;
	public outAnimation?: TAnimationObject;

	public Page: FC;
	public pageId: TPageId;
	public subRoutes?: Record<string, Route>;

	public shouldRender: (options: TShouldRenderOptions) => boolean;

	public hideSideNav: boolean;

	constructor({
		matches,
		animationOrder,
		inAnimation,
		outAnimation,
		Page,
		pageId,
		subRoutes,
		shouldRender = () => true,
		hideSideNav = false
	}: IRoute) {
		this.matches = matches;
		this.subRoutes = subRoutes;
		this.inAnimation = inAnimation;
		this.outAnimation = outAnimation;
		this.Page = Page;
		this.pageId = pageId;
		this.animationOrder = animationOrder;
		this.shouldRender = shouldRender;
		this.hideSideNav = hideSideNav;
	}

	public get main() {
		return this.matches[0];
	}

	public get location() {
		const location = getPageLocation(this.pageId);
		if (!location) {
			devLog({ message: "No location found for pageId", extra: { pageId: this.pageId } });
			return this.main;
		}

		return location;
	}

	public get matchesWithChildren() {
		const matches = [...this.matches];

		if (this.subRoutes) {
			Object.values(this.subRoutes).forEach(route => {
				const subMatches = route.matchesWithChildren;

				this.matches.forEach(match => {
					subMatches.forEach(subMatch => {
						matches.push(match + subMatch);
					});
				});
			});
		}

		return matches;
	}
}
