import React from "react";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useTranslation } from "react-i18next";
import { StaticChip } from "../StaticChip";
import type { TChipSize, TChipVariant } from "../Chip";

type TAllowRequestsChipProps = {
	size?: TChipSize;
	variant: TChipVariant;
	selected?: boolean;
	value: boolean;
};
export const AllowRequestsChip: FC<TAllowRequestsChipProps> = ({ variant, size, value, className }) => {
	const { t } = useTranslation();
	return (
		<StaticChip
			className={className}
			key={value.toString()}
			variant={variant}
			size={size ?? "medium"}
			PrefixIcon={value ? GrantedIcon : CloseIcon}>
			{value ? t("shared.boolean.true") : t("shared.boolean.false")}
		</StaticChip>
	);
};
