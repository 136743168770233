import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	cardsContainer: {
		display: "flex",
		flexWrap: "wrap",
		minWidth: "600px",
		gap: "var(--spacing-x3, 12px)",
		width: "100%"
	},
	drawerContent: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		alignItems: "flex-start",
		flexWrap: "wrap",
		width: "100%"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		width: "100%"
	},
	filterTitle: {
		alignItems: "center",
		display: "flex",
		flexGrow: 1,
		gap: "var(--spacing-x3, 12px)"
	},
	filterTitleContainer: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x3, 12px)",
		width: "100%"
	},
	filterExpressionsContainer: {
		width: "100%",
		minWidth: "400px"
	}
});
