import classNames from "classnames";
import { Typography } from "components/ui/legacy/Typography";
import { UserModel } from "models/UserModel";
import React from "react";
import { Avatar } from "../Avatar";
import { useStyles } from "./styles";

interface IProps {
	user: UserModel;
	wrap?: boolean;
	emailClassName?: string;
	nameSize?: "small" | "standard";
	avatarSize?: "small" | "medium";
}

export const UserWithEmail: FC<IProps> = ({
	user,
	className,
	nameSize = "small",
	avatarSize = "small",
	innerRef,
	emailClassName,
	wrap = false
}) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			{user ? <Avatar user={user} size={avatarSize} /> : null}
			<div className={classes.title}>
				<Typography noWrap={!wrap} variant={nameSize}>
					{user?.fullName}
				</Typography>
				<Typography className={emailClassName} noWrap={!wrap} variant="tiny">
					{user?.email}
				</Typography>
			</div>
			{user.isDeleted && <span className={classes.deleted}>*</span>}
		</div>
	);
};
