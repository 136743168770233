import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceOwner" as const;

const defaults = {
	name: filterName,
	value: [] as (string | null)[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationResourceOwnerFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceOwnerFilter | null {
		const integrationResourceOwner = uniq(
			urlSearchParams.getAll("integrationResourceOwner").map(value => (value === "null" ? null : value))
		);
		const integrationResourceOwnerOperator = urlSearchParams.get("integrationResourceOwnerOperator") || undefined;
		if (integrationResourceOwner.length === 0 || !validateFilterEqualityOperator(integrationResourceOwnerOperator)) {
			return null;
		}
		return new IntegrationResourceOwnerFilter({
			value: integrationResourceOwner,
			operator: integrationResourceOwnerOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceOwner", value || "null");
		}
		params.append("integrationResourceOwnerOperator", this.operator);
		return params;
	}
}
