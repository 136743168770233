import { useMemo } from "react";
import { requirePropertyOf } from "utils/security";
import { sourceIcons, TSources } from "utils/ui/sourceIcons";

const sourceRegex = new RegExp(/\[([A-z]+)\] (.*)/);

export const useDirectoryGroupIcon = (directoryGroupName: string) => {
	const { name, Icon } = useMemo(() => {
		const match = sourceRegex.exec(directoryGroupName);
		if (!match) {
			return { name: directoryGroupName, Icon: null };
		}

		const iconString = match[1] as TSources;
		const name = match[2];

		const Icon = requirePropertyOf(sourceIcons, iconString);

		return { name, Icon };
	}, [directoryGroupName]);

	return { name, Icon };
};
