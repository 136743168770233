import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Checkbox } from "components/ui/Checkbox";
import { Input } from "components/ui/Input";
import { RadioInput } from "components/ui/RadioInput";
import { useDebounceFn } from "hooks/useDebounce";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useTranslation } from "react-i18next";
import { sortByName } from "utils/sortUtils";
import { Typography } from "components/ui/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useLoadingState } from "hooks/useLoadingState";
import { RolesTitle } from "../helperComponents";
import { MAX_VISIBLE_ROLES, useStyles } from "./styles";
import type { TExpandedResourceCardProps } from "../../types";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { IInputState } from "components/ui/fieldHelpers/types";

const RoleRow: FC<{
	role: IntegrationResourceRoleModel;
	selected: boolean;
	onSelect: (roleId: string) => void;
	multiRole?: boolean;
}> = ({ role, selected, onSelect, multiRole = false }) => {
	const classes = useStyles();
	const selectionComponent: React.ReactNode = useMemo(() => {
		if (!multiRole) {
			return <RadioInput className={classes.radioInput} value={role.id} selected={selected} onClick={onSelect} />;
		}
		const onSelection = (_event: React.MouseEvent, value: string) => onSelect(value);
		return <Checkbox value={role.id} selected={selected} onClick={onSelection} noMargin />;
	}, [classes.radioInput, multiRole, onSelect, role.id, selected]);

	const rowOnClick = useCallback(() => onSelect(role.id), [onSelect, role.id]);
	return (
		<div
			className={classNames(classes.roleRow, classes.clickable, { [classes.selectedRow]: selected })}
			onClick={rowOnClick}>
			{selectionComponent}
			<TooltipOnOverflow textVariant="text_reg" content={role.name} />
		</div>
	);
};

const DEBOUNCE_WAIT_MS = 100;

export const ExpandedResourceCardContent: FC<Omit<TExpandedResourceCardProps, "onClick" | "resource">> = ({
	className,
	innerRef,
	onSearch,
	onSelect,
	roleOptions,
	selectedRoleIds,
	totalRoles,
	multiRole
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [isDirty, setIsDirty] = useState(!!selectedRoleIds.size);
	const [debouncedSearch] = useDebounceFn(onSearch, DEBOUNCE_WAIT_MS);
	const { withLoader, isLoading } = useLoadingState();
	const onSearchRole = useCallback(
		(search: string) => withLoader(debouncedSearch(search)),
		[debouncedSearch, withLoader]
	);
	const rows = useMemo(() => {
		return sortByName(roleOptions).map(role => (
			<RoleRow
				key={role.id}
				role={role}
				selected={selectedRoleIds.includes(role.id)}
				onSelect={onSelect}
				multiRole={multiRole}
			/>
		));
	}, [multiRole, onSelect, roleOptions, selectedRoleIds]);

	const rolesChipContent = useMemo(() => {
		if (!selectedRoleIds || !selectedRoleIds.size) return totalRoles || 0;
		return `${selectedRoleIds.size}/${totalRoles}`;
	}, [selectedRoleIds, totalRoles]);

	const onInputStateChange = useCallback((state: IInputState) => {
		setIsDirty(state.dirty);
	}, []);

	useEffect(() => {
		if (isDirty || roleOptions.length !== 1 || selectedRoleIds.size) return;
		const role = roleOptions[0];
		setIsDirty(true);
		onSelect(role.id);
	}, [isDirty, onSelect, roleOptions, selectedRoleIds.size]);

	const noOptions = rows.length === 0;

	const rolesListContent = useMemo(() => {
		if (isLoading) {
			return <LoadingSpinner />;
		}
		if (noOptions) {
			return (
				<div className={classes.noResults}>
					<Typography variant="body_reg">{t("common.resourceCard.expanded.noResults")}</Typography>
				</div>
			);
		}
		return rows;
	}, [classes.noResults, isLoading, noOptions, rows, t]);
	return (
		<>
			{totalRoles > MAX_VISIBLE_ROLES ? (
				<div className={classes.searchContainer}>
					<Input
						onStateChange={onInputStateChange}
						onValueChange={onSearchRole}
						placeholder={t("common.resourceCard.expanded.inputPlaceholder")}
						size="medium"
						variant="search"
					/>
				</div>
			) : null}
			<div className={classNames(classes.container, className)} ref={innerRef}>
				<RolesTitle chipContent={rolesChipContent} />
				<div className={classNames(classes.rolesList, { [classes.loading]: isLoading })}>{rolesListContent}</div>
			</div>
		</>
	);
};
