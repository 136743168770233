import React, { useMemo } from "react";
import { checkEquality as defaultCheckEquality } from "utils/ui/selectUtils";
import { getUniqueKey } from "utils/ui/getUniqueKey";
import { BasicCard } from "components/ui/BasicCard";
import { FilterHeader } from "../FilterHeader";
import { useStyles } from "./styles";
import type { TMultiChoiceFilterProps } from "../../types";

export function MultiChoiceFilter<T>({
	className,
	innerRef,
	title,
	onReset,
	onRemoveFilter,
	options,
	renderOption,
	selected,
	onOptionSelect,
	checkEquality = defaultCheckEquality
}: TProps<TMultiChoiceFilterProps<T>>) {
	const classes = useStyles();

	const renderedOptions = useMemo(() => {
		return options.map((option, index) => {
			const isSelected = selected.some(selectedItem => checkEquality(option, selectedItem));
			return {
				isSelected,
				key: getUniqueKey(option, index.toString()),
				onClick: () => onOptionSelect(option),
				optionElement: renderOption(option, isSelected)
			};
		});
	}, [options, selected, renderOption, checkEquality, onOptionSelect]);

	return (
		<>
			<FilterHeader
				className={className}
				title={title}
				onRemoveFilter={onRemoveFilter}
				onReset={onReset}
				innerRef={innerRef}
				hasSelection={!!selected}
			/>
			<div className={classes.content}>
				{renderedOptions.map(option => (
					<BasicCard size="medium" key={option.key} selected={option.isSelected} onClick={option.onClick}>
						{option.optionElement}
					</BasicCard>
				))}
			</div>
		</>
	);
}
