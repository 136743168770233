import React from "react";
import { EntityNode, TEntityNodeColor } from "../EntityNode";

type TProps = {
	actions?: React.ReactNode;
	color?: TEntityNodeColor;
	content: React.ReactNode;
	fixedWidth?: boolean;
	icon?: React.ReactNode;
	isDeleted?: boolean;
	onDelete?: () => void;
	selected?: boolean;
};

const DEFAULT_COLOR = "magenta" as const;
const BORDER_RADIUS_PX = 0;

export const GroupNode: FC<TProps> = React.memo(function GroupNode(props) {
	return <EntityNode {...props} borderRadius={BORDER_RADIUS_PX} color={props.color || DEFAULT_COLOR} />;
});
