import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	keyContainer: {
		width: "100%",
		wordBreak: "break-all"
	},
	token: {
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column"
	},
	expiryContainer: {
		width: "100%"
	},
	expired: {
		color: "var(--color-red)"
	},
	name: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%"
	},
	inputContainer: {
		width: "calc(100% + 2rem)"
	},
	expiration: {
		padding: "1rem"
	},
	error: {
		color: "var(--color-red)",
		padding: "0 1rem",
		fontSize: "12px"
	},
	actionsContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-start",
		width: "calc(100% + 1rem)",
		margin: "-0.5rem"
	},
	edit: {
		opacity: 0,
		transition: "opacity 0.2s ease-in-out"
	},
	container: {
		"&:hover $edit": {
			opacity: 1
		}
	}
});
