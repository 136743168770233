import { getImageGridLocations } from "utils/ui/imageGrid";
import React from "react";
import classNames from "classnames";
import { useStyles } from "./styles";

export const ImageGrid: FC<{
	images: string[];
	imageGridClass?: string;
	imageRowClass?: string;
	emptyCellClass?: string;
}> = ({ images, imageGridClass, imageRowClass, emptyCellClass }) => {
	const classes = useStyles();

	const imagesNum = images.length;
	const imageGrid = getImageGridLocations(imagesNum);
	const rowElements = [];
	let imageCounter = 0;

	for (let i = 0; i < imageGrid.length; i++) {
		const row = imageGrid.at(i)!;
		const rowImages = [];
		const columnNum = row.length;

		for (let j = 0; j < columnNum; j++) {
			const shouldShow = !!row.at(j);
			rowImages.push(
				shouldShow ? (
					<img key={j} src={images[imageCounter++]} className={classes.image} />
				) : (
					<div className={classNames(classes.emptyCell, emptyCellClass)} key={j}></div>
				)
			);
		}

		rowElements.push(
			<div key={i} className={classNames(classes.imageRow, imageRowClass)}>
				{rowImages}
			</div>
		);
	}

	return <div className={classNames(classes.imageGrid, imageGridClass)}>{rowElements}</div>;
};
