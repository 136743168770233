import { useMemo } from "react";
import { notEmpty } from "utils/comparison";
import { Map, List, Seq } from "immutable";
import { useCustomerEntitiesSelect } from "./useCustomerEntitiesSelect";
import type { TCustomerEntityType } from "api/customerEntities";
import type { ApprovalFlowEntityModel, TApprovalFlowEntityType } from "models/ApprovalFlowEntityModel";
import type { IEntityOption } from "utils/entityOptionType";

const convertToEntitiesArray = <K extends TApprovalFlowEntityType, T extends IEntityOption<K>>(
	entities?: List<T> | Seq.Indexed<T>
) => entities?.map(entity => entity.toEntityOption()).toArray();

const ENTITY_TO_ENTITY_TYPE = Map<string, TCustomerEntityType>([
	["DirectoryGroup", "directoryGroup"],
	["User", "user"],
	["Webhook", "approvalFlowWebhook"],
	["OnCallIntegrationSchedule", "onCallIntegrationSchedule"]
]);

export const useEntities = (query: string, values: ApprovalFlowEntityModel[]) => {
	const selectedIdsByType = useMemo(
		() =>
			List(values)
				.groupBy(entity => entity.type)
				.mapKeys(k => ENTITY_TO_ENTITY_TYPE.get(k)!)
				.map(entities =>
					entities
						.map(({ identifier }) => identifier)
						.toArray()
						.filter(notEmpty)
				)
				.toObject(),
		[values]
	);

	const {
		items,
		selectedItems: selectedAllEntities,
		isLoading: isLoadingEntities
	} = useCustomerEntitiesSelect(query, { selectedIdsByType });

	const entities = useMemo(() => convertToEntitiesArray(items), [items]);
	const selectedItems = useMemo(() => convertToEntitiesArray(selectedAllEntities), [selectedAllEntities]);

	return {
		entities,
		loading: !items || isLoadingEntities,
		selectedItems
	};
};
