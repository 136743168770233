import { createUseStyles } from "react-jss";

export const DEFAULT_ROW_HEIGHT_PX = 60;
export const COMPACT_ROW_HEIGHT_PX = 32;

export const useStyles = createUseStyles({
	row: {
		height: `${DEFAULT_ROW_HEIGHT_PX}px`,
		display: "grid",
		"&$selected": {
			backgroundColor: "var(--table-selected-row-background-color)"
		}
	},
	header: {
		flexShrink: 0,
		flexBasis: "fit-content",
		textAlign: "initial",
		overflow: "hidden",
		whiteSpace: "nowrap",
		wordBreak: "keep-all",
		width: "100%",
		overflowY: "hidden",
		overflowX: "hidden",
		scrollbarColor: "transparent transparent",
		"&::-webkit-scrollbar-track": {
			backgroundColor: "transparent"
		},
		"&::-webkit-scrollbar-thumb": {
			background: "transparent"
		},
		"&::-webkit-scrollbar-thumb:hover": {
			background: "transparent"
		}
	},
	cell: {
		flexShrink: 0,
		margin: 0,
		display: "flex",
		gap: "var(--spacing-x2)",
		justifyContent: "flex-start",
		alignItems: "center",
		padding: "var(--spacing-x1) var(--spacing-x5)",
		width: "100%",
		overflow: "hidden",
		borderBottom: "2px solid var(--table-cell-border-color)",
		"&:not(:first-child)": {
			borderLeft: "2px solid var(--table-cell-border-color)"
		},
		"&$scrollPlaceholder": {
			borderLeft: "none"
		}
	},
	selectable: {
		"& > $cell:first-child": {
			padding: "var(--spacing-x1) var(--spacing-x3)"
		}
	},
	selected: {},
	scrollPlaceholder: {}
});
