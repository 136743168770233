import React from "react";
import { LoadingDots } from "components/ui/LoadingDots";
import { PartialOrNull, RequiredNotNull } from "types/utilTypes";
import { notEmpty } from "utils/comparison";

type Nullable<T> = T extends object ? T | PartialOrNull<T> | null : T | null;

type TRequiredRender<T> = (data: T extends object ? RequiredNotNull<T> : NonNullable<T>) => React.ReactNode;

type TNotRequiredRender<T> = (data?: Nullable<T> | T) => React.ReactNode;

interface IProps<T, Require extends boolean> {
	center?: boolean;
	data?: Nullable<T>;
	inline?: boolean;
	loading?: boolean;
	require?: Require;
	// eslint-disable-next-line @typescript-eslint/ban-types
	render: Require extends true ? TRequiredRender<T> : Require extends false ? Function : never;
	size?: "small" | "medium" | "big";
}

const isRecord = (value: unknown): value is object => {
	return !!value && typeof value === "object" && !Array.isArray(value);
};

const exists = <T,>(value?: Nullable<T>): T | null =>
	typeof value === "undefined" || value === null ? null : (value as T);

export function Loading<T, Require extends boolean = false>({
	center = true,
	className,
	data,
	inline,
	loading = false,
	render,
	require,
	size
}: TProps<IProps<T, Require>>) {
	if (loading) {
		return <LoadingDots center={center} className={className} inline={inline} size={size} />;
	}
	if (require) {
		const propsData = exists<T>(data);
		if (propsData) {
			if (isRecord(propsData)) {
				const keysSize = Object.keys(propsData).filter(notEmpty).length;
				const valuesSize = Object.values(propsData).filter(notEmpty).length;
				if (!keysSize || valuesSize !== keysSize) {
					return null;
				}
			}
			return <>{render(propsData as T extends object ? RequiredNotNull<T> : NonNullable<T>)}</>;
		}
		return null;
	}
	return <>{(render as TNotRequiredRender<T>)(data)}</>;
}
