import classNames from "classnames";
import {
	ApproversCell,
	ResourceCell,
	RequestedForCell,
	CreationTimeCell,
	DurationCell,
	TicketNumberCell
} from "components/common/TicketsListComponents";
import { Chip } from "components/ui/chips/Chip";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { TicketExtraOptionsButton } from "components/common/TicketExtraOptionsButton";
import { Tooltip } from "components/ui/Tooltip";
import { type TColumn, PaginatedVirtualTable } from "components/ui/legacy/VirtualTable";
import { TicketModel } from "models/TicketModel";
import { useLoadingState } from "hooks/useLoadingState";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { useNavigate } from "react-router-dom";
import { SortableVirtualTableHeader } from "components/common/SortableTableHeader";
import { IconButton } from "components/ui/IconButton";
import { LoadingDots } from "components/ui/LoadingDots";
import { useStyles } from "./styles";

const TRANSLATION_PREFIX = "pages.pendingRequests.pendingRequestsList";

interface IProps {
	tickets: (TicketModel | undefined)[] | null;
	totalTickets: number;
	getPage: (page: number) => Promise<void>;
	perPage: number;
	respondToTicket: (id: string, response: boolean) => Promise<TicketModel>;
	errorModalSetError: (error: Error) => void;
}

export const PendingRequestsList: FC<IProps> = ({
	tickets,
	getPage,
	perPage,
	totalTickets,
	respondToTicket,
	errorModalSetError
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();

	const columns: TColumn<TicketModel>[] = useMemo(
		() =>
			[
				{
					renderCell: row => <TicketNumberCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.ticketNumber")} field="ticketNumber" />,
					key: "number",
					width: 80
				},
				{
					renderCell: row => <ResourceCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.resourceLabel")} />,
					key: "resource",
					width: "minmax(320px, 20fr)"
				},
				{
					renderCell: row => <DurationCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.duration")} field="duration" />,
					key: "duration",
					width: 220
				},
				{
					renderCell: row => <RequestedForCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.requestedFor")} field="receiver" />,
					key: "requestedFor",
					width: "minmax(192px, 12fr)"
				},
				{
					renderCell: row => <CreationTimeCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.creationTime")} field="creationTime" />,
					key: "creationTime",
					width: 160
				},
				{
					renderCell: row => <ApproversCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.approversLabel")} />,
					key: "approvers",
					width: "minmax(192px, 12fr)"
				},
				{
					renderCell: row => (
						<ActionsCell data={row} respondToTicket={respondToTicket} errorModalSetError={errorModalSetError} />
					),
					key: "actions",
					width: 140
				}
			] as TColumn<TicketModel>[],
		[errorModalSetError, respondToTicket, t]
	);

	const onClick = useCallback(
		(row: TicketModel) => {
			navigate(`?ticketId=${row.id}`);
		},
		[navigate]
	);

	if (!tickets) {
		return <LoadingDots center />;
	}

	return (
		<PaginatedVirtualTable
			fetchPage={getPage}
			perPage={perPage}
			columns={columns}
			className={classes.requestsContainer}
			totalRows={totalTickets}
			rows={tickets}
			onRowClicked={onClick}
		/>
	);
};

const ActionsCell: FC<{
	data: TicketModel;
	respondToTicket: (id: string, response: boolean) => Promise<TicketModel>;
	errorModalSetError: (error: Error) => void;
}> = ({ data, respondToTicket, errorModalSetError }) => {
	const [approvalState, setApprovalState] = useState<"approved" | "declined">();
	const { t } = useTranslation();
	const { notifyTicketUpdate } = useTicketUpdatesContext();

	const { isLoading: respondIsLoading, withLoader: withRespondLoader } = useLoadingState();
	const approved = `${TRANSLATION_PREFIX}.approved` as const;
	const declined = `${TRANSLATION_PREFIX}.declined` as const;

	const classes = useStyles();

	const setApproved = useCallback(
		async (event: React.MouseEvent) => {
			try {
				event.stopPropagation();
				const updatedTicket = await withRespondLoader(respondToTicket(data.id, true));
				notifyTicketUpdate(updatedTicket);
				setApprovalState("approved");
			} catch (e) {
				errorModalSetError(e as Error);
			}
		},
		[data.id, errorModalSetError, notifyTicketUpdate, respondToTicket, withRespondLoader]
	);

	const setDeclined = useCallback(
		async (event: React.MouseEvent) => {
			try {
				event.stopPropagation();
				const updatedTicket = await withRespondLoader(respondToTicket(data.id, false));
				notifyTicketUpdate(updatedTicket);
				setApprovalState("declined");
			} catch (e) {
				errorModalSetError(e as Error);
			}
		},
		[data.id, errorModalSetError, notifyTicketUpdate, respondToTicket, withRespondLoader]
	);

	useEffect(() => {
		if (data.status === "rejected") {
			setApprovalState("declined");
		} else if (data.status !== "waitingForApproval") {
			setApprovalState("approved");
		}
	}, [data.status]);

	if (approvalState) {
		return (
			<div className={classes.approvalStatusContainer}>
				<Chip size="medium" variant="regular" className={classNames(classes.approvalStatus)}>
					{approvalState === "approved" ? (
						<>
							<DoneCircleIcon.Green /> {t(approved)}
						</>
					) : (
						<>
							<CloseCircleIcon.Red /> {t(declined)}
						</>
					)}
				</Chip>
			</div>
		);
	}

	return (
		<div className={classes.actionButtons}>
			<IconButton
				variant="secondary"
				disabled={respondIsLoading}
				className={classNames(classes.approveButton, classes.actionButton)}
				onClick={setApproved}
				size="small"
				tooltip={t(`${TRANSLATION_PREFIX}.approveTooltip`)}>
				<GrantedIcon />
			</IconButton>
			<IconButton
				variant="secondary"
				disabled={respondIsLoading}
				className={classNames(classes.declineButton, classes.actionButton)}
				onClick={setDeclined}
				size="small"
				tooltip={t(`${TRANSLATION_PREFIX}.declineTooltip`)}>
				<CloseIcon />
			</IconButton>
			<Tooltip content={t(`${TRANSLATION_PREFIX}.moreActionsTooltip`)}>
				<TicketExtraOptionsButton
					ticket={data}
					disabled={respondIsLoading}
					size="medium"
					className={classNames(classes.actionButton, classes.viewTicketButton)}
				/>
			</Tooltip>
		</div>
	);
};
