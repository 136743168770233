import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IToggleOption, TimezoneButtonDropdown } from "components/ui/DropdownTimezoneButton";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Typography } from "components/ui/Typography";
import { Button } from "components/ui/Button";
import { FilterIcon } from "components/ui/Icons/FilterIcon";
import { Section } from "components/ui/Section";
import { useCompany } from "hooks/useCompany";
import { useSessionAuditLogsContext } from "../../sessionAuditLogContext";
import { IntegrationLogsTable } from "./components/IntegrationLogsTable";
import { useStyles } from "./styles";
import { ExternalLogsNotConnected } from "./components/ExternalLogsNotConnected";

const TRANSLATION_PREFIX = "pages.auditLog.";

type TIntegrationLogsSectionProps = {
	toggleDrawer?: (isOpen: boolean) => void;
	drawerIsOpen?: boolean;
};

const PLUS = "+";
const MINUTES = 60;
const TOTAL_RESULTS_DIFFERENTIAL = 1;

export const IntegrationLogsSection: FC<TIntegrationLogsSectionProps> = ({ className, toggleDrawer, drawerIsOpen }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const company = useCompany();

	const {
		state: { timezone, totalResults, sessionAuditLogs, filters, totalLogs, isLoading, totalFilters },
		actions: { setTimezone, fetchPage }
	} = useSessionAuditLogsContext();

	const currentTimezoneToggleOptions: [IToggleOption, IToggleOption] = useMemo(
		() => [
			{
				value: "local",
				label: t(`${TRANSLATION_PREFIX}timezoneToggle.local`),
				dropdownLabel: t(`${TRANSLATION_PREFIX}timezoneToggle.localGmtTime`, {
					timezone: new Date().getTimezoneOffset() / MINUTES
				})
			},
			{
				value: "global",
				label: t(`${TRANSLATION_PREFIX}timezoneToggle.globalUtcTime`),
				dropdownLabel: t(`${TRANSLATION_PREFIX}timezoneToggle.globalUtcTime`)
			}
		],
		[t]
	);

	const filterNumber = useMemo(() => {
		if (isLoading && totalResults === 0) return "-";
		else if (totalResults === 0) return "0";
		else if (totalResults !== totalLogs) return totalResults.toString();
		return `${totalResults - TOTAL_RESULTS_DIFFERENTIAL}${PLUS}`;
	}, [isLoading, totalLogs, totalResults]);

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_med"> {t("pages.auditLog.total")}</Typography>
				<StaticChip size="small" variant="regular">
					{filterNumber}
				</StaticChip>
			</>
		);
	}, [filterNumber, t]);

	const handleFiltersClick = useCallback(() => {
		toggleDrawer && toggleDrawer(!drawerIsOpen);
	}, [drawerIsOpen, toggleDrawer]);

	const IntegrationlogsActions = useMemo(() => {
		return (
			<>
				<Button
					variant={filters.size > 0 || drawerIsOpen ? "primary" : "secondary"}
					size="medium"
					onClick={handleFiltersClick}
					prefix={<FilterIcon />}>
					{`${t("buttons.filter")} ${totalFilters.length > 0 ? `(${totalFilters.length})` : ""}`}
				</Button>
				<TimezoneButtonDropdown
					options={currentTimezoneToggleOptions}
					selectedOption={timezone}
					onSelect={setTimezone}
				/>
			</>
		);
	}, [
		currentTimezoneToggleOptions,
		drawerIsOpen,
		filters.size,
		handleFiltersClick,
		setTimezone,
		t,
		timezone,
		totalFilters.length
	]);

	return (
		<div className={classes.integrationLogsSectionWrapper}>
			{!company?.enableExternalAuditLogs ? <ExternalLogsNotConnected /> : null}
			<Section
				titleActions={IntegrationlogsActions}
				fullHeight
				title={title}
				className={classNames(classes.integrationLogsSection, className)}>
				<IntegrationLogsTable
					sessionAuditLogs={sessionAuditLogs}
					fetchPage={fetchPage}
					totalResults={totalResults}
					isLoading={isLoading}
					closeDrawer={toggleDrawer}
					timezone={timezone}
				/>
			</Section>
		</div>
	);
};
