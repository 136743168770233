import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Title } from "components/ui/Title";
import { Button } from "components/ui/Button";
import { Typography } from "components/ui/legacy/Typography";
import { useTranslation } from "react-i18next";
import { useApiTokens } from "hooks/useApiTokens";
import { sortByCreatedAt } from "utils/tickets/ticketActivity";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useApiTokensContext } from "context/apiTokensContext";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { TokenWithExpirationTable } from "components/common/TokenWithExpirationTable";
import { useStyles } from "./styles";
import type { IAreYouSureModalProps } from "../..";

interface IProps {
	openAreYouSureModal: (props: IAreYouSureModalProps) => void;
}

export const ApiTokens: FC<IProps> = ({ openAreYouSureModal }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		actions: { deleteApiToken, updateApiToken, createApiToken }
	} = useApiTokensContext();

	const [isNew, setIsNew] = useState(false);

	const apiTokens = useApiTokens();
	const handleError = useOpenGlobalErrorModal();

	const handleCancel = useCallback(() => {
		setIsNew(false);
	}, []);

	const createToken = useCallback(() => {
		setIsNew(true);
	}, []);

	const tokens = useMemo(() => apiTokens?.sort((a, b) => sortByCreatedAt(a, b, "desc")).toList(), [apiTokens]);

	const deleteHandler = useCallback(
		(tokenId: string) => {
			const onAction = async () => {
				try {
					await deleteApiToken(tokenId);
				} catch (error) {
					handleError(error as Error);
				}
			};

			openAreYouSureModal({
				onAction,
				title: t("pages.settings.apiTokens.areYouSure.title"),
				content: t("pages.settings.apiTokens.areYouSure.content")
			});
		},
		[deleteApiToken, handleError, openAreYouSureModal, t]
	);

	useEffect(() => {
		handleCancel();
	}, [handleCancel, apiTokens]);

	return (
		<>
			<Title variant="h3" noBorder className={classes.title} extraMargin>
				<span>{t("pages.settings.apiTokens.title")}</span>
				<Button onClick={createToken} variant="secondary" size="medium" prefix={<AddIcon />}>
					{t("buttons.add")}
				</Button>
			</Title>
			<div>
				{!!apiTokens || <LoadingSpinner />}
				{apiTokens?.isEmpty() && !isNew ? (
					<Typography className={classes.noForwards} variant="h2">
						{t("pages.settings.tokens.noTokens")}
					</Typography>
				) : (
					<TokenWithExpirationTable
						tokens={tokens}
						onReset={handleCancel}
						onCreate={createApiToken}
						onEdit={updateApiToken}
						onDelete={deleteHandler}
						newRow={isNew}
					/>
				)}
			</div>
		</>
	);
};
