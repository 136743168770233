import React from "react";
import { Typography } from "components/ui/legacy/Typography";
import { useSortableHeader } from "hooks/useSortableHeader";
import { Table } from "components/ui/Table";
import { SortButton } from "components/common/SortButton";
import { useStyles } from "./styles";
import type { TSortOrder } from "types/pagination";

export const SortableVirtualTableHeader: FC<{
	title: string;
	field?: string;
}> = ({ field, title }) => {
	const { onHover, onLeave, setSortState, sortOrder, sortVisible } = useSortableHeader(title, field);

	return (
		<SortableVirtualTableHeaderContent
			sortVisible={sortVisible}
			onHover={onHover}
			onLeave={onLeave}
			setSortState={setSortState}
			sortOrder={sortOrder}
			title={title}
			field={field}
		/>
	);
};

export const SortableVirtualTableHeaderContent: FC<{
	title: string;
	sortOrder: TSortOrder;
	setSortState: (field: string, order: TSortOrder) => void;
	onHover: () => void;
	onLeave: () => void;
	sortVisible: boolean;
	field?: string;
}> = ({ field, title, onHover, onLeave, setSortState, sortOrder, sortVisible }) => {
	const classes = useStyles();

	return (
		<div className={classes.virtualTableHeaderContent} onMouseOver={onHover} onMouseLeave={onLeave}>
			<Typography noWrap>{title}</Typography>
			{field && (
				<SortButton
					field={field}
					order={sortOrder}
					setOrder={setSortState}
					className={sortVisible ? undefined : classes.sortButtonInvisible}
				/>
			)}
		</div>
	);
};

export const SortableTableHeader: FC<{
	title: string;
	field?: string;
}> = ({ field, title }) => {
	const classes = useStyles();
	const { onHover, onLeave, setSortState, sortOrder, sortVisible } = useSortableHeader(title, field);

	return (
		<Table.Header className={classes.tableHeader} key={title} onMouseOver={onHover} onMouseLeave={onLeave}>
			<div className={classes.tableHeaderContent}>
				<Typography>{title}</Typography>
				{field && (
					<SortButton
						field={field}
						order={sortOrder}
						setOrder={setSortState}
						className={sortVisible ? undefined : classes.sortButtonInvisible}
					/>
				)}
			</div>
		</Table.Header>
	);
};
