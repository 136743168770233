import classNames from "classnames";
import {
	ApproversCell,
	ResourceCell,
	RequestedForCell,
	CreationTimeCell,
	DurationCell,
	TicketNumberCell
} from "components/common/TicketsListComponents";
import { Chip } from "components/ui/chips/Chip";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { type TColumn, PaginatedVirtualTable } from "components/ui/legacy/VirtualTable";
import { TicketModel } from "models/TicketModel";
import { LoadingDots } from "components/ui/LoadingDots";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { SortableVirtualTableHeaderContent } from "components/common/SortableTableHeader";
import { useRespondedTicketsTableContext } from "context/sortingTableContext";
import { useStyles } from "./styles";

const TRANSLATION_PREFIX = "pages.pendingRequests.recentlyRespondedList";

interface IProps {
	tickets: (TicketModel | undefined)[] | null;
	totalTickets: number;
	getPage: (page: number) => Promise<unknown>;
	perPage: number;
}

const SortableVirtualTableHeader: FC<{
	title: string;
	field?: string;
}> = ({ field, title }) => {
	const {
		actions: { setHoveredField, setSortState },
		state: { hoveredField, sortFields, sortOrder }
	} = useRespondedTicketsTableContext();
	const sortVisible = hoveredField === title || sortFields[0] === field;
	const onHover = useCallback(() => setHoveredField(title), [setHoveredField, title]);
	const onLeave = useCallback(() => setHoveredField(undefined), [setHoveredField]);

	return (
		<SortableVirtualTableHeaderContent
			sortVisible={sortVisible}
			onHover={onHover}
			onLeave={onLeave}
			setSortState={setSortState}
			sortOrder={sortOrder}
			title={title}
			field={field}
		/>
	);
};

export const RecentlyRespondedList: FC<IProps> = ({ tickets, getPage, perPage, totalTickets }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();

	const columns: TColumn<TicketModel>[] = useMemo(
		() =>
			[
				{
					renderCell: row => <TicketNumberCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.ticketNumber")} field="ticketNumber" />,
					key: "number",
					width: 80
				},
				{
					renderCell: row => <ResourceCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.resourceLabel")} />,
					key: "resource",
					width: "minmax(320px, 20fr)"
				},
				{
					renderCell: row => <DurationCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.duration")} field="duration" />,
					key: "duration",
					width: 220
				},
				{
					renderCell: row => <RequestedForCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.requestedFor")} field="receiver" />,
					key: "requestedFor",
					width: "minmax(192px, 12fr)"
				},
				{
					renderCell: row => <CreationTimeCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.creationTime")} field="creationTime" />,
					key: "creationTime",
					width: 160
				},
				{
					renderCell: row => <ApproversCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.approversLabel")} />,
					key: "approvers",
					width: "minmax(192px, 12fr)"
				},
				{
					renderCell: row => <ActionsCell data={row} />,
					header: <SortableVirtualTableHeader title={t("common.ticketList.yourResponse")} />,
					key: "actions",
					width: 192
				}
			] as TColumn<TicketModel>[],
		[t]
	);

	const onClick = useCallback(
		(data: TicketModel) => {
			navigate(`?ticketId=${data.id}`);
		},
		[navigate]
	);

	if (!tickets) {
		return <LoadingDots center />;
	}

	return (
		<PaginatedVirtualTable
			className={classes.requestsContainer}
			columns={columns}
			rows={tickets}
			fetchPage={getPage}
			perPage={perPage}
			totalRows={totalTickets}
			onRowClicked={onClick}
		/>
	);
};

const approversTypes = ["adminApprove", "approve"];
const rejectedTypes = ["decline", "adminDecline"];

const ActionsCell: FC<{
	data: TicketModel;
}> = ({ data }) => {
	const { t } = useTranslation();
	const { user } = useAuthenticatedUser();

	const classes = useStyles();

	const approvalState = useMemo(() => {
		return data.ticketApprovalResponses
			?.toArray()
			?.find(ticketApprovalResponse => ticketApprovalResponse.userId === user?.id)?.type;
	}, [data.ticketApprovalResponses, user?.id]);

	const prefixIcon = useMemo(() => {
		if (approvalState) {
			if (approversTypes.includes(approvalState)) return DoneCircleIcon.Green;
			if (rejectedTypes.includes(approvalState)) return CloseCircleIcon.Red;
		}
		return;
	}, [approvalState]);

	const approvalStatusContent = approvalState ? t(`${TRANSLATION_PREFIX}.${approvalState}` as const) : undefined;

	return (
		<div className={classes.actionButtons}>
			{approvalState && (
				<div className={classes.approvalStatusContainer}>
					<Chip variant="regular" size="small" className={classNames(classes.approvalStatus)} PrefixIcon={prefixIcon}>
						{approvalStatusContent}
					</Chip>
				</div>
			)}
		</div>
	);
};
