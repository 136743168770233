import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator, validateFilterInclusionOperator } from "utils/validation/filterValidation";
import type { IFilter, TFilterOperator } from "types/filters";

export const OPERATORS = ["is", "isNot", "contains", "notContains"] as const satisfies TFilterOperator[];

const filterName = "integrationResourceType" as const;

const defaults = {
	name: filterName,
	value: [] as string[],
	operator: "is" as (typeof OPERATORS)[number]
};

type TDefaults = typeof defaults;

export class IntegrationResourceTypeFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceTypeFilter | null {
		const integrationResourceType = uniq(urlSearchParams.getAll("integrationResourceType"));
		const integrationResourceTypeOperator = urlSearchParams.get("integrationResourceTypeOperator") || undefined;
		if (
			integrationResourceType.length === 0 ||
			!(
				validateFilterEqualityOperator(integrationResourceTypeOperator) ||
				validateFilterInclusionOperator(integrationResourceTypeOperator)
			)
		) {
			return null;
		}
		return new IntegrationResourceTypeFilter({
			value: integrationResourceType,
			operator: integrationResourceTypeOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceType", value);
		}
		params.append("integrationResourceTypeOperator", this.operator);
		return params;
	}
}

export type TIntegrationResourceTypeFilter = Omit<TDefaults, "name">;
