import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { ErrorModal } from "components/ui/ErrorModal";
import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import useErrorModalState from "hooks/useErrorModalState";
import { getUrlParams } from "components/pages/NewTicketPage/utils/urlFormDataUtils";
import copy from "copy-to-clipboard";
import { Form } from "components/ui/Form";
import { getMissingActorIntegrationIds } from "api/tickets";
import { useStyles } from "./styles";

const ActionButtons: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		state: { receiverUser, formState },
		actions: { onMissingActors, onSuccess }
	} = useNewTicketFormContext();

	const isFormDataValid = formState.isValid;

	const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

	const { errorModalSetError, errorModalIsOpen, errorModalError, errorModalRetry, errorModalClose } =
		useErrorModalState();

	const onSubmit = useCallback(async () => {
		setIsLoadingSubmission(true);
		try {
			if (receiverUser) {
				const missingActorIntegrationIds = await getMissingActorIntegrationIds(
					formState.selectedItems.map(({ value: { id }, type }) => ({ id, type })).toArray(),
					receiverUser.id
				);
				if (missingActorIntegrationIds.length) {
					onMissingActors(missingActorIntegrationIds, receiverUser);
					return;
				}
				await onSuccess();
			}
		} catch (error) {
			errorModalSetError(error as Error);
			return;
		} finally {
			setIsLoadingSubmission(false);
		}
	}, [receiverUser, formState.selectedItems, onSuccess, onMissingActors, errorModalSetError]);

	const copyLink = useCallback(async () => {
		if (formState.isValid) {
			const params = getUrlParams({
				targets: formState.selectedItems.toArray(),
				duration: formState.duration!,
				justification: formState.justification,
				ticketingIntegrationTicketId: formState.ticketingIntegrationTicketId ?? undefined
			});
			const link = `${window.location.origin}/request?${params}`;
			copy(link);
		}
	}, [
		formState.duration,
		formState.isValid,
		formState.justification,
		formState.selectedItems,
		formState.ticketingIntegrationTicketId
	]);

	return (
		<>
			<ErrorModal
				isOpen={errorModalIsOpen}
				error={errorModalError}
				onRetry={errorModalRetry}
				onClose={errorModalClose}
			/>
			<Form.Actions className={classes.formLinkActions}>
				<Button
					size="medium"
					variant="primary"
					loading={isLoadingSubmission}
					disabled={isLoadingSubmission || !isFormDataValid}
					onClick={onSubmit}>
					{t("buttons.sendRequest")}
				</Button>
				{!isLoadingSubmission && isFormDataValid && (
					<Button variant="text" size="medium" onClick={copyLink}>
						{t(`pages.newTicket.newTicketForm.copyLink`)}
					</Button>
				)}
			</Form.Actions>
		</>
	);
};

export const NewTicketFormActions = React.memo(ActionButtons);
