import { TOP_NAV_HEIGHT_PX } from "components/App/components/Layout/components/TopNav/styles";
import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

const TYPES_SELECT_SIZE = 260;
const INPUT_MAX_WIDTH = 400;

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		backgroundColor: "var(--color-blue-100)",
		border: "3px solid var(--color-grey-400)",
		borderRadius: "12px 12px 40px 40px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		maxHeight: `calc(90vh - ${TOP_NAV_HEIGHT_PX}px)`,
		overflow: "hidden",
		width: "100%"
	},
	containerHeader: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		width: "100%"
	},
	headerTop: {
		alignItems: "center",
		alignSelf: "stretch",
		borderBottom: "3px solid var(--color-grey-400)",
		display: "flex",
		gap: "var(--spacing-x3, 12px)",
		height: "100px",
		padding: "var(--spacing-x3, 12px) var(--spacing-x3, 12px) var(--spacing-x3, 12px) var(--spacing-x7, 28px)"
	},
	headerTopTitleDescription: {
		alignItems: "center",
		alignSelf: "stretch",
		display: "flex",
		flex: "1 0 0",
		gap: "var(--spacing-x8, 32px)"
	},
	headerTopActions: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		justifyContent: "flex-end"
	},
	headerBottom: {
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)",
		flexWrap: "wrap",
		alignSelf: "stretch",
		display: "flex",
		justifyContent: "space-between",
		padding: "var(--spacing-x3, 12px) var(--spacing-x4, 16px) var(--spacing-x3, 12px) var(--spacing-x7, 28px)"
	},
	headerBottomInfo: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x8, 32px)"
	},
	headerBottomActions: {
		alignItems: "flex-end",
		display: "flex",
		flexWrap: "wrap",
		gap: "var(--spacing-x2, 8px)",
		maxWidth: "calc(320px + var(--spacing-x2, 8px) + 260px)",
		width: "100%",
		[BP_MEDIA_QUERIES.TWENTY]: {
			flexWrap: "nowrap"
		}
	},
	headerBottomInfoCountLine: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2, 8px)"
	},
	containerBody: {
		display: "grid",
		gap: "var(--spacing-x4)",
		gridAutoRows: "max-content",
		gridTemplateColumns: "repeat(1, 1fr)",
		minHeight: "192px",
		overflowY: "auto",
		padding: "var(--spacing-x4, 16px)",
		width: "100%",
		[BP_MEDIA_QUERIES.TWENTY]: {
			gridTemplateColumns: "repeat(2, 1fr)"
		},
		[BP_MEDIA_QUERIES.FORTY]: {
			gridTemplateColumns: "repeat(3, 1fr)"
		},
		[BP_MEDIA_QUERIES.FIFTY]: {
			gridTemplateColumns: "repeat(4, 1fr)"
		},
		[BP_MEDIA_QUERIES.SEVENTY]: {
			gridTemplateColumns: "repeat(5, 1fr)"
		},
		[BP_MEDIA_QUERIES.EIGHTY]: {
			gridTemplateColumns: "repeat(6, 1fr)"
		},
		"&$isLoading": {
			gridTemplateColumns: "auto"
		}
	},
	isLoading: {},
	searchInput: {
		background: "var(--color-white)",
		maxWidth: `${INPUT_MAX_WIDTH}px`,
		width: "100%",
		[BP_MEDIA_QUERIES.TWENTY]: {
			maxWidth: "320px",
			minWidth: "252px"
		}
	},
	searchInputInnerContainer: {
		height: "40px"
	},
	resourceTypesSelect: {
		maxWidth: `${INPUT_MAX_WIDTH}px`,
		width: "100%",
		[BP_MEDIA_QUERIES.TWENTY]: {
			width: `${TYPES_SELECT_SIZE}px`
		}
	},
	resourceTypesInput: {
		background: "var(--color-white)",
		maxWidth: `${INPUT_MAX_WIDTH}px`,
		width: "100%",
		[BP_MEDIA_QUERIES.TWENTY]: {
			width: `${TYPES_SELECT_SIZE}px`
		}
	},
	clickable: {
		cursor: "pointer"
	}
});
