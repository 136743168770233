import { useRef, useCallback } from "react";
import { Map } from "immutable";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSubscriber = <T extends (...args: any[]) => void = (...args: any[]) => void>() => {
	const callbackMapRef = useRef(Map<string, T>());

	const addSubscription = useCallback((key: string, fn: T) => {
		callbackMapRef.current = callbackMapRef.current.set(key, fn);
	}, []);

	const removeSubscription = useCallback((key: string) => {
		callbackMapRef.current = callbackMapRef.current.delete(key);
	}, []);

	const notify = useCallback((...args: Parameters<T>) => {
		callbackMapRef.current.forEach(fn => fn(...args));
	}, []);

	return { addSubscription, removeSubscription, notify };
};
