import { useCallback, useState } from "react";
import { getAccessReviewPermissions } from "api/accessReviews";
import { usePagination } from "./usePagination";
import type { TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

const PER_PAGE = 5;
const ALL_OPTION = "all";

export const useAccessReviewSubjectPermissions = (accessReviewId: string) => {
	const [statusFilter, setStatusFilter] = useState<TAccessReviewPermissionStatus | "all">(ALL_OPTION);

	const fetchSubjectPermissions = useCallback(
		async (paginationOptions: IPaginatedSearchOptions) => {
			return getAccessReviewPermissions(
				accessReviewId,
				paginationOptions,
				statusFilter === ALL_OPTION ? undefined : statusFilter
			);
		},
		[accessReviewId, statusFilter]
	);

	const { page, lastPage, setCurrentPageNumber, currentPageNumber, totalPages, isLoading, totalResults } =
		usePagination({ fetchItems: fetchSubjectPermissions, perPage: PER_PAGE });

	return {
		currentPageNumber,
		isLoading,
		lastPage,
		page,
		setCurrentPageNumber,
		setStatusFilter,
		statusFilter,
		totalPages,
		totalResults
	};
};
