import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import uniq from "lodash/uniq";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { TFilterOperator } from "types/filters";
import { useSessionAuditLogsContext } from "components/pages/AuditLogsPage/sessionAuditLogContext";
import { UserModel } from "models/UserModel";
import { IntegrationModel } from "models/IntegrationModel";
import { AccountChip } from "components/ui/chips/AccountChip";
import { IntegrationChip } from "components/ui/chips/IntegrationChip";
import { UserCard } from "components/common/UserCard";
import { Chip } from "components/ui/chips/Chip";
import Immutable from "immutable";
import { useOnMount } from "hooks/useOnMount";
import type { TFilterExpressionProps } from "components/ui/filters/FilterExpression/types";

type TRenderType = "AccountChip" | "IntegrationChip" | "UserCard";

type TMultiSelect<T> = Partial<TFilterExpressionProps<T>> & {
	filterField?: string;
	filterName: string;
	inputPlaceholder?: string;
	fetchValues?: Immutable.List<T>;
	renderType?: TRenderType;
	fetchOptions?: (field: string, search: string) => Promise<string[]>;
	filterEmptyState?: ReactNode;
	onFilterRemove: () => void;
	onInputChange?: (query: string) => void;
	getOptionKey?: (value: T) => string;
	getOptionLabel?: (value: T) => string;
	renderOption?: (value: T) => JSX.Element;
	onOptionSelect?: (value: T) => void;
};

const OPERATORS = ["is", "isNot"] as TFilterOperator[];
const OPERATOR_SUFFIX = "Operator";

export const IntegrationLogsMultiSelectFilterExpression = <T,>(props: TMultiSelect<T>) => {
	const {
		title,
		fetchValues,
		inputPlaceholder,
		renderType,
		filterName,
		filterField,
		onOptionSelect: propsOnOptionSelect,
		renderOption: propsRenderOption,
		onReset: propsOnReset,
		getOptionKey: propsGetOptionKey,
		getOptionLabel: propsGetOptionLabel,
		fetchOptions,
		onFilterRemove,
		filterEmptyState,
		onInputChange
	} = props;
	const [selectedValues, setSelectedValues] = useState<T[]>([]);
	const [options, setOptions] = useState<T[]>([]);
	const [selectedOperator, setSelectedOperator] = useState<TFilterOperator>("is");
	const ref = useRef<HTMLDivElement>(null);
	const {
		state: { filters },
		actions: { setSearchFilters, setTotalFilters }
	} = useSessionAuditLogsContext();

	const toURLSearchParams = useCallback(
		(values: T[], operator?: TFilterOperator) => {
			const prevParams = filters;
			prevParams.delete(filterName);
			prevParams.delete(`${filterName}${OPERATOR_SUFFIX}`);
			setTotalFilters(curr => curr.filter(filter => filter.field !== filterName));
			const params = new URLSearchParams(prevParams);
			if (!values) {
				setSearchFilters(params);
				return;
			}
			for (const value of uniq(values)) {
				if (value instanceof UserModel || value instanceof IntegrationModel) {
					params.append(filterName, value.id);
					setTotalFilters(curr => [
						...curr,
						{ field: filterName, operator: operator ?? selectedOperator, value: value.id }
					]);
				} else {
					params.append(filterName, value as string);
					setTotalFilters(curr => [
						...curr,
						{ field: filterName, operator: operator ?? selectedOperator, value: value as string }
					]);
				}
			}

			if (params.get(filterName)) {
				params.append(`${filterName}${OPERATOR_SUFFIX}`, operator ?? selectedOperator);
				setSearchFilters(params);
			}
		},
		[filterName, filters, selectedOperator, setSearchFilters, setTotalFilters]
	);

	const handleOpersatorSelection = useCallback(
		(operator: TFilterOperator) => {
			setSelectedOperator(operator);
			toURLSearchParams(selectedValues, operator);
		},
		[selectedValues, toURLSearchParams]
	);

	const removeFromURLSearchParams = useCallback(
		(value?: T) => {
			const wantedValue = (
				value instanceof UserModel || value instanceof IntegrationModel ? value.id : value
			) as string;
			const params = new URLSearchParams(filters);
			if (wantedValue) {
				params.delete(filterName, wantedValue);
				if (!params.get(filterName)) {
					params.delete(filterName);
					params.delete(`${filterName}${OPERATOR_SUFFIX}`);
				}
			} else {
				params.delete(filterName);
				params.delete(`${filterName}${OPERATOR_SUFFIX}`);
			}
			setTotalFilters(curr => {
				return curr.filter(filter => filter.value !== wantedValue);
			});
			setSearchFilters(params);
		},
		[filterName, filters, setSearchFilters, setTotalFilters]
	);

	const handleOptionRemove = useCallback(
		(valueToRemove?: T) => {
			if (fetchValues && (valueToRemove instanceof UserModel || valueToRemove instanceof IntegrationModel)) {
				setSelectedValues(current =>
					current.filter(value => {
						if (value instanceof UserModel || value instanceof IntegrationModel) return value.id !== valueToRemove.id;
						return undefined;
					})
				);
			} else {
				setSelectedValues(current => current.filter(value => value !== valueToRemove));
			}
			removeFromURLSearchParams(valueToRemove);
		},
		[fetchValues, removeFromURLSearchParams]
	);

	const handleOptionSelection = useCallback(
		async (selectedValue: T) => {
			if (selectedValue instanceof UserModel || selectedValue instanceof IntegrationModel) {
				if (
					selectedValues.some(value => {
						if (value instanceof UserModel || value instanceof IntegrationModel) return value.id === selectedValue.id;
						return false;
					})
				) {
					handleOptionRemove(selectedValue);
				}
			}

			setSelectedValues(current => [...current, selectedValue]);
			toURLSearchParams([...selectedValues, selectedValue]);
		},
		[handleOptionRemove, selectedValues, toURLSearchParams]
	);

	const renderOption = useCallback((value: T) => {
		return <>{value}</>;
	}, []);

	const renderSelected = useCallback(
		(value: T) => {
			const renderValue = (value && typeof value === "object" && "id" in value ? value.id : value) as string;

			const onDelete = () => handleOptionRemove(value);

			switch (renderType) {
				case "AccountChip":
					return (
						<AccountChip size="large" onDelete={onDelete} selected>
							{renderValue}
						</AccountChip>
					);
				case "IntegrationChip":
					return <IntegrationChip size="large" selected integration={renderValue ?? ""} onDelete={onDelete} />;
				case "UserCard":
					return <UserCard user={renderValue ?? ""} onDelete={onDelete} selected />;
				default:
					return (
						<Chip selected size="large" onDelete={onDelete}>
							{renderValue}
						</Chip>
					);
			}
		},
		[handleOptionRemove, renderType]
	);

	const handleFilterReset = useCallback(() => {
		setSelectedValues([]);
		removeFromURLSearchParams();
		setTotalFilters(curr => curr.filter(filter => filter.field !== filterName));
	}, [filterName, removeFromURLSearchParams, setTotalFilters]);

	const handleFilterRemove = useCallback(() => {
		onFilterRemove();
		if (filters.size !== 0) {
			removeFromURLSearchParams();
		}

		setTotalFilters(curr => curr.filter(filter => filter.field !== filterName));
	}, [filterName, filters.size, onFilterRemove, removeFromURLSearchParams, setTotalFilters]);

	const handleSearch = useCallback(
		async (search?: string) => {
			if (filterField) {
				const selectedOptions = fetchOptions ? await fetchOptions(filterField, search || "") : [];
				setOptions(selectedOptions as T[]);
			} else if (fetchValues?.toArray()[0] instanceof IntegrationModel) {
				setOptions(
					fetchValues
						.toArray()
						.filter(option =>
							(option as IntegrationModel).name.toLocaleLowerCase().startsWith(search?.toLocaleLowerCase() || "")
						) as T[]
				);
			} else {
				onInputChange?.(search || "");
			}
		},
		[fetchOptions, fetchValues, filterField, onInputChange]
	);

	useEffect(() => {
		async function fetchData() {
			const initialOptions = fetchOptions ? await fetchOptions(filterField!, "") : [];
			setOptions(initialOptions as T[]);
		}
		if (filterField) fetchData();
		else if (fetchValues) {
			setOptions(fetchValues.toArray());
		}
	}, [fetchOptions, filterField, fetchValues]);

	const currentOptions = useMemo(
		() => (fetchValues?.first() instanceof UserModel ? fetchValues.toArray() : options),
		[fetchValues, options]
	);

	useOnMount(() => {
		ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	});

	return (
		<FilterExpression
			onOperatorSelect={handleOpersatorSelection}
			onOptionSelect={propsOnOptionSelect || handleOptionSelection}
			operators={OPERATORS}
			options={currentOptions}
			getOptionKey={propsGetOptionKey}
			getOptionLabel={propsGetOptionLabel}
			renderOption={propsRenderOption || renderOption}
			emptyState={filterEmptyState}
			renderSelected={renderSelected}
			selected={selectedValues}
			innerRef={ref}
			inputPlaceholder={inputPlaceholder}
			onReset={propsOnReset || handleFilterReset}
			onRemoveFilter={handleFilterRemove}
			getMoreOptions={handleSearch}
			selectedOperator={selectedOperator}
			title={title}
			type="multiSelect"
		/>
	);
};
