import React, { useCallback, useMemo } from "react";
import { List } from "immutable";
import { RuleModel } from "models/RuleModel";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { Divider } from "components/ui/Divider";
import classNames from "classnames";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useRulesContext } from "components/pages/RulesPage/RulesContext";
import { useStrictDroppable } from "hooks/useStrictDroppable";
import { useStyles } from "./styles";
import { RuleBar } from "./RuleBar";

type TRulesListProps = {
	rules: List<RuleModel>;
	changeRulePriority?: (rule: RuleModel, newPriority: number) => void;
};

const DraggableRuleBar: FC<{ rule: RuleModel }> = ({ rule }) => {
	const classes = useStyles();
	const ruleId = rule.id || "new";
	const draggableId = useStrictDroppable(ruleId);
	const {
		state: { selectedRule }
	} = useRulesContext();
	const isDragDisabled = !!selectedRule && selectedRule.id !== rule.id;
	return (
		<Draggable isDragDisabled={isDragDisabled} key={draggableId} draggableId={draggableId} index={rule.priority - 1}>
			{provided => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className={classNames(classes.fullWidth, { [classes.draggable]: !isDragDisabled })}>
					<RuleBar key={ruleId} rule={rule} />
				</div>
			)}
		</Draggable>
	);
};

const RuleListMemo = React.memo(function RuleList({
	rules,
	disableDrag
}: {
	rules: List<RuleModel>;
	disableDrag?: boolean;
}) {
	if (disableDrag) {
		return rules.map(rule => <RuleBar key={rule.id || "new"} rule={rule} />);
	}
	return rules.map(rule => <DraggableRuleBar key={rule.id || "new"} rule={rule} />);
});

export const RulesList: FC<TRulesListProps> = ({ rules, changeRulePriority }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "pages.rules.ruleBar" });
	const headers = useMemo(
		() => [t("headers.priority"), t("headers.applyTo"), t("headers.conditions"), t("headers.setTo"), ""],
		[t]
	);
	const onDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination || result.destination.index === result.source.index) {
				return;
			}

			changeRulePriority &&
				changeRulePriority(
					rules.find(rule => (rule.id || "new") === result.draggableId)!,
					result.destination.index + 1
				);
		},
		[rules, changeRulePriority]
	);
	const droppableId = useStrictDroppable(`rule-list-${rules.first()!.type ?? "typeless"}`);

	return (
		<div className={classes.overflowContainer}>
			<div className={classes.listContainer}>
				<div className={classNames(classes.barContainer, classes.headersContainer)}>
					{headers.map((header, index) => (
						<React.Fragment key={header}>
							<Typography variant="text_reg" noWrap>
								{header}
							</Typography>
							{index < headers.length - 2 && <Divider vertical />}
						</React.Fragment>
					))}
				</div>
				{changeRulePriority && rules.size > 1 ? (
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable key={droppableId} type="group" droppableId={droppableId}>
							{provided => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
									className={classNames(classes.listContainer, classes.fullWidth)}>
									<RuleListMemo rules={rules} />
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				) : (
					<RuleListMemo rules={rules} disableDrag />
				)}
			</div>
		</div>
	);
};
