import { useCallback } from "react";

export const useStopPropagation = (callback?: () => void) => {
	return useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.stopPropagation();
			callback?.();
		},
		[callback]
	);
};
