import React from "react";
import classNames from "classnames";
import { useStyles } from "./styles";

type TCell = FC<{
	center?: boolean;
	noPadding?: boolean;
	onClick?: () => void;
	onMouseOver?: () => void;
	onMouseLeave?: () => void;
}>;
type TRow = FC<{ onClick?: () => void; onMouseOver?: () => void; onMouseLeave?: () => void }>;
type THeader = FC<{ onMouseOver?: () => void; onMouseLeave?: () => void }>;
type TTable = FC<{ gridColumns?: string; outline?: boolean; loading?: boolean; overflow?: boolean }> & {
	Cell: TCell;
	Row: TRow;
	Header: THeader;
};

export const Table: TTable = ({
	children,
	className,
	gridColumns,
	id,
	innerRef,
	loading,
	outline,
	overflow = false
}) => {
	const classes = useStyles();

	return (
		<div
			className={classNames(
				classes.table,
				{ [classes.outline]: outline, [classes.loading]: loading, [classes.overflow]: overflow },
				className
			)}
			style={gridColumns ? { gridTemplateColumns: gridColumns } : undefined}
			id={id}
			ref={innerRef}>
			{children}
		</div>
	);
};

const Cell: TCell = ({
	center = false,
	children,
	className,
	noPadding = false,
	onClick,
	onMouseLeave,
	onMouseOver
}) => {
	const classes = useStyles();

	return (
		<div
			onClick={onClick}
			className={classNames(
				classes.cell,
				{ [classes.noPadding]: noPadding, [classes.clickable]: Boolean(onClick), [classes.center]: center },
				className
			)}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}>
			{children}
		</div>
	);
};

const Row: TRow = ({ children, className, onClick, onMouseOver, onMouseLeave }) => {
	const classes = useStyles();

	return (
		<div
			className={classNames(classes.row, className, { [classes.clickable]: !!onClick })}
			onClick={onClick}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}>
			{children}
		</div>
	);
};

const Header: THeader = ({ children, className, onMouseOver, onMouseLeave }) => {
	const classes = useStyles();

	return (
		<Cell className={classNames(classes.header, className)} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
			{children}
		</Cell>
	);
};

Table.Cell = Cell;
Table.Row = Row;
Table.Header = Header;
