import { DateInput } from "components/ui/DateInput";
import { dayjs } from "utils/dates/dayjs";
import { useCompany } from "hooks/useCompany";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import type { IFilterProps } from "../../";

const TRANSLATION_PREFIX = "common.ticketFilters";

export const CreatedAtFilter: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const company = useCompany();

	const [now] = useState(new Date());
	const [fromInput, setFromInput] = useState<Date | null>(null);
	const [toInput, setToInput] = useState<Date | null>(null);
	const [toErrors, setToErrors] = useState<string[]>();

	useEffect(() => {
		if (company) {
			onFiltersChange(current =>
				current.createdAt ? current : { ...current, createdAt: { from: company.createdAt, to: now } }
			);
			setFromInput(company.createdAt);
			setToInput(now);
		}
	}, [company, now, onFiltersChange]);

	const changeFrom = useCallback(
		(value: Date | null) => {
			setFromInput(value);
			if (!value) {
				onFiltersChange(current => ({
					...current,
					createdAt: { from: undefined, to: current.createdAt?.to }
				}));
				setToErrors(undefined);
				return;
			}

			onFiltersChange(current => {
				if (current.createdAt?.to && dayjs(current.createdAt.to).isBefore(value)) {
					setToErrors([t("validationErrors.global.date.toBeforeFrom")]);
					return current;
				}
				setToErrors(undefined);
				return { ...current, createdAt: { from: value, to: current.createdAt?.to } };
			});
		},
		[onFiltersChange, t]
	);
	const changeTo = useCallback(
		(value: Date | null) => {
			setToInput(value);
			onFiltersChange(current => ({
				...current,
				createdAt: { to: value || undefined, from: fromInput || current.createdAt?.from }
			}));
			setToErrors(undefined);
		},
		[fromInput, onFiltersChange]
	);

	return (
		<div className={classes.range}>
			<DateInput
				hint={
					ticketFilters.createdAt?.from
						? t("dateTime.dateWithTime", { date: ticketFilters.createdAt.from })
						: t(`${TRANSLATION_PREFIX}.anyDate`)
				}
				label={t(`${TRANSLATION_PREFIX}.creationTimeFrom`)}
				onChange={changeFrom}
				value={fromInput}
				minValue={company?.createdAt || now}
				maxValue={now}
				disabled={!company}
			/>
			<DateInput
				errors={toErrors}
				hint={
					ticketFilters.createdAt?.to
						? t("dateTime.dateWithTime", { date: ticketFilters.createdAt.to })
						: t(`${TRANSLATION_PREFIX}.anyDate`)
				}
				label={t(`${TRANSLATION_PREFIX}.creationTimeTo`)}
				onChange={changeTo}
				value={toInput}
				minValue={fromInput || company?.createdAt || now}
				maxValue={now}
				disabled={!company}
				defaultMax
			/>
		</div>
	);
};
