import { Map } from "immutable";
import { AccessReviewModel } from "models/AccessReviewModel";
import constate from "constate";
import { useCallback, useMemo, useState } from "react";
import {
	activateAccessReview as apiActivateAccessReview,
	createAccessReview as apiCreateAccessReview,
	deleteAccessReview as apiDeleteAccessReview,
	editAccessReview as apiEditAccessReview,
	getAccessReview as apiGetAccessReview,
	getAccessReviews as apiGetAccessReviews,
	finishAccessReview as apiFinishAccessReview
} from "api/accessReviews";
import { useLoadingState } from "hooks/useLoadingState";
import { useAuthenticatedUserContext } from "./AuthenticatedUserContext";

const useAccessReviews = () => {
	const [accessReviews, setAccessReviews] = useState<Map<string, AccessReviewModel> | null>(null);
	const [fullAccessReviews, setFullAccessReviews] = useState<Map<string, AccessReviewModel>>(Map());
	const { withLoader: withCreateLoader, isLoading: isCreateLoading } = useLoadingState();
	const { withLoader: withDeleteLoader, isLoading: isDeleteLoading } = useLoadingState();
	const { withLoader: withFinishLoader, isLoading: isFinishLoading } = useLoadingState();
	const { withLoader: withActivateLoader, isLoading: isActivateLoading } = useLoadingState();
	const { withLoader: withGetLoader, isLoading: isGetLoading } = useLoadingState();
	const { withLoader: withLoadLoader, isLoading: isLoadLoading } = useLoadingState();
	const {
		actions: { loadUser }
	} = useAuthenticatedUserContext();

	const loadAccessReviews = useCallback(async () => {
		const loadedAccessReviews = await withLoadLoader(apiGetAccessReviews());
		setAccessReviews(
			Map<string, AccessReviewModel>(loadedAccessReviews.map(accessReview => [accessReview.id, accessReview]))
		);
		return loadedAccessReviews;
	}, [withLoadLoader]);

	const createAccessReview = useCallback(
		async (name: string, description: string, immediateRevoke: boolean, templateId?: string) => {
			const accessReview = await withCreateLoader(
				apiCreateAccessReview(name, description, immediateRevoke, templateId)
			);
			await loadAccessReviews();
			await loadUser();
			return accessReview;
		},
		[loadAccessReviews, loadUser, withCreateLoader]
	);

	const editAccessReview = useCallback(async (id: string, accessReviewChanges: Partial<AccessReviewModel>) => {
		const accessReview = await apiEditAccessReview(id, accessReviewChanges);
		setAccessReviews(current => {
			return current?.set(id, accessReview) ?? null;
		});
		setFullAccessReviews(current => current?.set(id, accessReview) ?? null);
		return accessReview;
	}, []);

	const loadAccessReview = useCallback(
		async (id: string, force = false) => {
			if (!fullAccessReviews.get(id) || force) {
				const accessReview = await withGetLoader(apiGetAccessReview(id));
				setFullAccessReviews(current => current.set(id, accessReview));
			}
		},
		[fullAccessReviews, withGetLoader]
	);

	const deleteAccessReview = useCallback(
		async (id: string) => {
			await withDeleteLoader(apiDeleteAccessReview(id));
			await loadAccessReviews();
			setFullAccessReviews(current => current.delete(id));
		},
		[loadAccessReviews, withDeleteLoader]
	);

	const finishAccessReview = useCallback(
		async (id: string) => {
			await withFinishLoader(apiFinishAccessReview(id));
			await loadAccessReviews();
			setFullAccessReviews(current => (current.has(id) ? current.setIn([id, "status"], "done") : current));
		},
		[loadAccessReviews, withFinishLoader]
	);

	const activateAccessReview = useCallback(
		async (id: string) => {
			await withActivateLoader(apiActivateAccessReview(id));
			await loadAccessReviews();
			await loadUser();
			setFullAccessReviews(current => (current.has(id) ? current.setIn([id, "status"], "active") : current));
		},
		[loadAccessReviews, loadUser, withActivateLoader]
	);

	const accessReviewsOrder = useMemo(
		() =>
			accessReviews
				?.valueSeq()
				.sortBy(
					accessReview => accessReview.createdAt,
					(date1, date2) => date2.valueOf() - date1.valueOf()
				)
				.map(({ id }) => id)
				.toArray(),
		[accessReviews]
	);

	const latestAccessReviewId = useMemo(() => accessReviewsOrder?.at(0) || undefined, [accessReviewsOrder]);

	return {
		state: {
			accessReviews,
			accessReviewsOrder,
			fullAccessReviews,
			isActivateLoading,
			isCreateLoading,
			isDeleteLoading,
			isFinishLoading,
			isGetLoading,
			isLoadLoading,
			latestAccessReviewId
		},
		actions: {
			activateAccessReview,
			createAccessReview,
			deleteAccessReview,
			editAccessReview,
			finishAccessReview,
			loadAccessReview,
			loadAccessReviews
		}
	};
};

export const [AccessReviewsProvider, useAccessReviewsContext] = constate(useAccessReviews);
