import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { useLoadingState } from "hooks/useLoadingState";
import { BundleModel } from "models/BundleModel";
import { useBundlesContext } from "context/bundlesContext";
import { Input } from "components/ui/Input";
import { useIntegrations } from "hooks/useIntegrations";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { DurationsOverrideSelect } from "components/common/DurationsOverrideSelect";
import { useCompany } from "hooks/useCompany";
import { getDescriptionValidators, getNameValidators, getTagValidators } from "utils/validation/validationUtils";
import { TagsInput } from "components/common/TagsInput";
import { IconPrefix } from "components/ui/IconPrefix";
import { Form } from "components/ui/Form";
import classnames from "classnames";
import { ApprovalAlgorithmsSelectInput } from "./components/ApprovalAlgorithmsSelectInput";
import { useStyles } from "./styles";
import { BundleItemForm } from "./components/BundleItemForm";
import { CategorySelectInput, ICategoryOption } from "./components/CategorySelectInput";
import { useBundleFormState } from "./useBundleFormState";
import { bundleModelToBundleData, bundleDataToBundleModel } from "./utils";

interface IBaseProps {
	isOpen: boolean;
	onClose: () => void;
	onError: (error: Error) => void;
	onSubmit: () => void;
	categories: string[];
}

interface IPropsForEdit extends IBaseProps {
	bundle?: BundleModel;
}

interface IPropsForCreate extends IBaseProps {
	bundle?: never;
}

type TProps = IPropsForEdit | IPropsForCreate;

const TRANSLATION_PREFIX = "pages.bundles.bundleEditor";

type TModalContentProps = Omit<TProps, "isOpen" | "onClose"> & { setActions: (actions: JSX.Element) => void };

const ModalContent: FC<TModalContentProps> = ({ onError, onSubmit, categories, bundle, setActions }) => {
	const integrations = useIntegrations();
	const initialBundleData = useMemo(() => bundleModelToBundleData(bundle, integrations), [bundle, integrations]);
	const { isLoading, withLoader } = useLoadingState();
	const { t } = useTranslation();
	const company = useCompany();

	const {
		actions: { saveBundle, updateBundle }
	} = useBundlesContext();
	const {
		state,
		actions: {
			addNewBundleItem,
			setAllowedDurations,
			setOverrideAllowedDurations,
			setApprovalAlgorithm,
			setCategory,
			setName,
			setBundleItems,
			removeBundleItem,
			setTags,
			setDescription
		}
	} = useBundleFormState(initialBundleData);
	const {
		approvalAlgorithm,
		category,
		bundleItems,
		name,
		id,
		tags,
		description,
		allowedDurations,
		overrideAllowedDurations
	} = state;
	const existingBundle = id !== undefined;
	const classes = useStyles({ hasItems: !!bundleItems.count() });

	const updateOrCreate = useCallback(async () => {
		const bundle = bundleDataToBundleModel(state, overrideAllowedDurations);
		return existingBundle ? updateBundle(bundle) : saveBundle(bundle);
	}, [state, overrideAllowedDurations, existingBundle, updateBundle, saveBundle]);

	const nameValidators = useMemo(() => getNameValidators(t("shared.name")), [t]);
	const descriptionValidators = useMemo(() => getDescriptionValidators(t("shared.description")), [t]);
	const tagValidators = useMemo(() => getTagValidators(t("shared.tag")), [t]);

	const isValid = useMemo(() => {
		if (!state || !state.name?.trim() || !state.approvalAlgorithm || !state.bundleItems?.size) return false;

		if (nameValidators.some(validate => validate(state.name!))) return false;

		if (state.description && descriptionValidators.some(validate => validate(state.description!))) return false;

		if (state.tags.length) {
			const invalidTags = state.tags.some(tag => tagValidators.some(validate => validate(tag)));
			if (invalidTags) return false;
		}

		return !state.bundleItems?.some(
			item => !item || !item.get("role") || !item.get("role")?.integrationResourceId || !item.get("role")?.id
		);
	}, [descriptionValidators, nameValidators, state, tagValidators]);

	const setBundleCategory = useCallback(
		(option: ICategoryOption | null) => setCategory(option?.value || null),
		[setCategory]
	);

	const handleSubmit = useCallback(async () => {
		if (!isValid) return;
		try {
			await withLoader(updateOrCreate());
			onSubmit();
		} catch (error) {
			onError(error as Error);
		}
	}, [isValid, withLoader, updateOrCreate, onSubmit, onError]);

	useEffect(() => {
		if (bundle?.allowedDurations) {
			setAllowedDurations(bundle.allowedDurations);
		} else if (company?.allowedDurations) {
			setAllowedDurations(company.allowedDurations);
		}
	}, [company, bundle, setAllowedDurations]);

	useEffect(() => {
		setActions(
			<Button size="medium" disabled={isLoading || !isValid} loading={isLoading} onClick={handleSubmit}>
				{t(`${TRANSLATION_PREFIX}.saveBundle`)}
			</Button>
		);
	}, [handleSubmit, isLoading, isValid, setActions, t]);

	return (
		<div className={classes.modalContent}>
			<IconPrefix
				size="large"
				semibold
				Icon={BundleIcon}
				content={t(`${TRANSLATION_PREFIX}.${existingBundle ? "editTitle" : "createTitle"}`)}
			/>

			<Form>
				<Form.Field>
					<Input
						label={t(`${TRANSLATION_PREFIX}.name`)}
						onValueChange={setName}
						placeholder={t(`${TRANSLATION_PREFIX}.placeholders.name`)}
						validators={nameValidators}
						value={name || ""}
						isRequired
					/>
				</Form.Field>
				<Form.Field>
					<Input
						label={t(`${TRANSLATION_PREFIX}.description`)}
						onValueChange={setDescription}
						placeholder={t(`${TRANSLATION_PREFIX}.placeholders.description`)}
						value={description || ""}
						validators={descriptionValidators}
					/>
				</Form.Field>
				<Form.Field>
					<ApprovalAlgorithmsSelectInput selected={approvalAlgorithm} onChange={setApprovalAlgorithm} />
				</Form.Field>
				<Form.Field>
					<TagsInput label={t(`${TRANSLATION_PREFIX}.tags`)} values={tags} onChange={setTags} />
				</Form.Field>
				<Form.Field>
					<CategorySelectInput categories={categories} selected={category} setSelected={setBundleCategory} />
				</Form.Field>
				<Form.Field>
					<div className={classes.resourcesAndRoles}>
						{bundleItems?.map((bundleItem, index) => (
							<BundleItemForm
								key={index}
								{...{ index, setBundleItems, removeBundleItem, bundleItem: bundleItem.toObject() }}
							/>
						))}
						<div className={classes.addResourceButtonContainer}>
							<div className={classes.addResourceButton}>
								<Button variant="secondary" size="small" onClick={addNewBundleItem}>
									{t(`${TRANSLATION_PREFIX}.addResource`)}
								</Button>
							</div>
						</div>
					</div>
				</Form.Field>
				<Form.Field>
					<DurationsOverrideSelect
						overrideAllowedDurations={overrideAllowedDurations}
						setOverrideAllowedDurations={setOverrideAllowedDurations}
						allowedDurations={allowedDurations}
						setAllowedDurations={setAllowedDurations}
						disabled={!company}
						type="bundle"
					/>
				</Form.Field>
			</Form>
		</div>
	);
};

export const EditBundle: FC<TProps> = ({ isOpen, onClose, className, ...restProps }) => {
	const classes = useStyles();
	const [actions, setActions] = useState<JSX.Element | null>(null);
	return (
		<Modal
			isOpen={isOpen}
			className={classnames(classes.modal, className)}
			onClose={onClose}
			content={<ModalContent {...restProps} setActions={setActions} />}
			actions={actions}
		/>
	);
};
