import React, { MutableRefObject, useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { ArrowLeftIcon } from "components/ui/Icons/ArrowLeftIcon";
import { ArrowRightIcon } from "components/ui/Icons/ArrowRightIcon";
import { Typography } from "components/ui/Typography";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Bp } from "utils/ui/breakpoints";
import { useStyles } from "./styles";
import { StepperProvider, TStepperContextProps, useStepperContext } from "./stepperContext";
import type { Require } from "types/utilTypes";
import type EventEmitter from "events";

type TProps = {
	resetEventEmitter?: EventEmitter;
};

const Stepper: FC<TProps> = ({ className, resetEventEmitter, innerRef }) => {
	const { t } = useTranslation();
	const {
		state: { steps, canContinue, activeStepIndex, footerActions, stepperContainerRef, footerActionsLabels },
		actions: { prevStep, nextStep, goToStep, onComplete }
	} = useStepperContext();
	const { isBiggerThan: hideStepperActiveStep } = useBreakpoint(Bp.TWENTY);
	const classes = useStyles();
	const { back, complete, next } = footerActionsLabels || {};
	const resetStepper = useCallback(() => goToStep(0), [goToStep]);

	useEffect(() => {
		resetEventEmitter?.on("resetStepper", resetStepper);

		return () => {
			resetEventEmitter?.off("resetStepper", resetStepper);
		};
	}, [resetEventEmitter, resetStepper]);

	const activeStep = useMemo(() => {
		return steps.find(step => step.index === activeStepIndex)?.step || null;
	}, [activeStepIndex, steps]);

	const backAction = useMemo(() => {
		if (activeStepIndex === 0) return null;
		if (typeof footerActions?.back === "undefined")
			return (
				<Button variant="secondary" size="large" prefix={<ArrowLeftIcon />} onClick={prevStep}>
					{back || t("buttons.back")}
				</Button>
			);
		return footerActions.back;
	}, [activeStepIndex, footerActions.back, back, prevStep, t]);

	const completeAction = useMemo(() => {
		if (typeof footerActions?.complete === "undefined")
			return (
				<Button variant="primary" size="large" suffix={<GrantedIcon />} onClick={onComplete} disabled={!canContinue}>
					{complete || t("buttons.done")}
				</Button>
			);
		return footerActions.complete;
	}, [canContinue, footerActions.complete, complete, onComplete, t]);

	const nextAction = useMemo(() => {
		if (typeof footerActions?.next === "undefined")
			return (
				<Button variant="primary" size="large" suffix={<ArrowRightIcon />} onClick={nextStep} disabled={!canContinue}>
					{next || t("buttons.next")}
				</Button>
			);
		return footerActions.next;
	}, [canContinue, footerActions.next, next, nextStep, t]);

	const isFinalStep = activeStepIndex === steps.length - 1;

	useEffect(() => {
		if (stepperContainerRef.current) {
			stepperContainerRef.current.scrollTo({ top: 0, behavior: "instant" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- we want to scroll every time activeStepIndex changes
	}, [activeStepIndex]);

	if (steps.length === 0) return null;
	return (
		<>
			<div
				className={classNames(classes.container, className)}
				ref={element => {
					if (innerRef) {
						if (typeof innerRef === "function") {
							innerRef(element);
						} else {
							(innerRef as MutableRefObject<HTMLDivElement | null>).current = element;
						}
					}
					(stepperContainerRef as MutableRefObject<HTMLDivElement | null>).current = element;
				}}>
				<div className={classes.steps}>{activeStep}</div>
			</div>
			<div className={classes.footer}>
				<div className={classes.footerLeft} />
				<div className={classes.footerCenter}>
					{hideStepperActiveStep ? (
						<Typography variant="title_med">
							{t("common.stepper.activeStep", { active: activeStepIndex + 1, total: steps.length })}
						</Typography>
					) : null}
				</div>
				<div className={classes.footerRight}>
					{backAction}
					{isFinalStep ? completeAction : nextAction}
				</div>
			</div>
		</>
	);
};

const StepperWithContext: FC<TProps & Require<TStepperContextProps, "steps">> = props => {
	const { steps, activeStepIndex, canContinue, ...stepperProps } = props;
	return (
		<StepperProvider steps={steps} key={steps.length} activeStepIndex={activeStepIndex} canContinue={canContinue}>
			<Stepper {...stepperProps} />
		</StepperProvider>
	);
};

export { StepperWithContext as Stepper };
