import { useEffect, useMemo } from "react";
import { List } from "immutable";
import { getPaginatedDirectoryGroups } from "api/directoryGroups";
import { useDirectoryGroupsContext } from "context/directoryGroupsContext";
import { notEmpty } from "utils/comparison";
import { useMultiDirectoryGroups } from "./useMultiDirectoryGroups";
import { useDebouncedPaginatedSearch } from "./useDebouncedPaginationSearch";
import type { TUsePaginationOptions } from "./usePagination";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";

type TDirectoryGroupsDebouncedPaginationOptions = Omit<
	TUsePaginationOptions<List<DirectoryGroupModel>>,
	"fetchItems"
> & {
	wait?: number;
	selectedIds?: string[];
};

/*
 * This hook performs three main tasks:
 * 1. Fetches the selected IDs and adds them to the directory groups context.
 * 2. Fetches a paginated list of DirectoryGroups and adds them to the directory groups context.
 * 3. Manages the debounced pagination of the DirectoryGroups.
 */
export const useDirectoryGroupsSelect = (query: string, options: TDirectoryGroupsDebouncedPaginationOptions = {}) => {
	const selectedIds = useMemo(() => options.selectedIds || [], [options.selectedIds]);
	let selectedDirectoryGroups = useMultiDirectoryGroups(selectedIds);
	selectedDirectoryGroups = selectedDirectoryGroups || List<DirectoryGroupModel>();
	const {
		actions: { addGroups },
		state: { directoryGroupsById }
	} = useDirectoryGroupsContext();

	const pagination = useDebouncedPaginatedSearch(query, {
		fetchItems: getPaginatedDirectoryGroups,
		...options
	});
	const items = pagination.items || List<DirectoryGroupModel>();
	const { getPageDebounced } = pagination;

	const allItems = useMemo(() => directoryGroupsById.toList().filter(notEmpty), [directoryGroupsById]);

	useEffect(() => addGroups(items.toArray()), [addGroups, items]);

	useEffect(() => getPageDebounced(), [getPageDebounced, query]);

	return {
		...pagination,
		items: query ? items : allItems,
		selectedItems: selectedDirectoryGroups
	};
};
