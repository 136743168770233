import { TaskModel } from "models/TaskModel";
import React, { useCallback } from "react";
import { Typography } from "components/ui/legacy/Typography";
import { VirtualScroll } from "components/ui/VirtualScroll";
import { TaskCard } from "../TaskCard";
import { useStyles } from "./styles";
import type { IntegrationModel } from "models/IntegrationModel";
import type { Map } from "immutable";

interface IProps {
	buttonText?: string;
	header: string | JSX.Element;
	integrations: Map<string, IntegrationModel>;
	onButtonClick?: (id: string) => Promise<void>;
	order: number;
	tasks: TaskModel[];
	prefixIcon?: JSX.Element;
}

const TASK_CARD_ESTIMATED_HEIGHT = 340;
export const TasksStatusColumn: FC<IProps> = ({
	tasks,
	header,
	integrations,
	buttonText,
	onButtonClick,
	order,
	prefixIcon
}) => {
	const classes = useStyles();
	const renderTaskCard = useCallback(
		(task: TaskModel, index: number) =>
			task ? (
				<TaskCard
					buttonText={buttonText}
					index={index}
					integrations={integrations}
					key={task.id}
					onButtonClick={onButtonClick}
					order={order}
					task={task}
				/>
			) : null,
		[buttonText, integrations, onButtonClick, order]
	);

	return (
		<div className={classes.taskStatusColumn}>
			<Typography variant="h3" className={classes.columnTitle}>
				{prefixIcon}
				{header}
			</Typography>
			<div className={classes.taskStatusColumnContentContainer}>
				<div className={classes.taskStatusColumnContent}>
					<VirtualScroll
						estimateSize={TASK_CARD_ESTIMATED_HEIGHT}
						renderRow={renderTaskCard}
						rowContainerClassName={classes.taskContainer}
						rows={tasks}
					/>
				</div>
			</div>
		</div>
	);
};
