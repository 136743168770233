import { List, Map } from "immutable";
import omitBy from "lodash/omitBy";
import { IntegrationModel } from "models/IntegrationModel";
import { apiReq } from "utils/api/apiReq";
import { fromPaginationSortFilterOptions } from "utils/api/urlSearchParams";
import { withPagination, type IPaginationResponse } from "utils/pagination";
import type { TTicketDuration } from "utils/durationsOptions";
import type { TPaginationSortFilterOptions } from "types/pagination";
import type { Require } from "types/utilTypes";

export async function getIntegrations(
	options?: Omit<TPaginationSortFilterOptions, "perPage" | "page">
): Promise<Map<string, IntegrationModel>>;
export async function getIntegrations(): Promise<Map<string, IntegrationModel>>;
export async function getIntegrations(
	options: Require<TPaginationSortFilterOptions, "perPage"> | Require<TPaginationSortFilterOptions, "page">
): Promise<IPaginationResponse<IntegrationModel>>;
export async function getIntegrations(
	options?: TPaginationSortFilterOptions
): Promise<Map<string, IntegrationModel> | IPaginationResponse<IntegrationModel>> {
	const searchParams = fromPaginationSortFilterOptions(options);
	const querystring = searchParams.size ? `?${searchParams.toString()}` : "";

	if (options?.page !== undefined || options?.perPage !== undefined) {
		return withPagination(
			(integration: Record<string, unknown>) => IntegrationModel.fromServerData(integration),
			() => apiReq("GET", `/v1/integrations${querystring}`)
		);
	}

	const { data } = await apiReq("GET", `/v1/integrations${querystring}`);

	return Map<string, IntegrationModel>(
		data
			.map((integration: unknown) => IntegrationModel.fromServerData(integration))
			.map((integration: IntegrationModel) => [integration.id, integration])
	);
}

export async function getIntegration(integrationId: string): Promise<IntegrationModel> {
	const { data } = await apiReq("GET", `/v1/integrations/${integrationId}`);

	return IntegrationModel.fromServerData(data);
}

export const deleteIntegration = async (integrationId: string): Promise<IntegrationModel> => {
	const { data } = await apiReq("DELETE", `/v1/integrations/${integrationId}`);

	return IntegrationModel.fromServerData(data);
};

export const updateIntegrationImage = async (integrationId: string, image: File): Promise<void> => {
	const formData = new FormData();
	formData.append("file", image);
	await apiReq("PUT", `/v1/integrations/${integrationId}/image`, formData, { multipart: true });
};

interface IIntegrationMaintainer {
	id: string;
	type: "user" | "directoryGroup";
}
export interface IIntegrationSharedData {
	agentTokenId: string | null;
	allowedDurations: List<TTicketDuration> | null;
	allowsRequests: boolean;
	applicationId: string;
	canCreateActors: boolean;
	canEditPermissions: boolean;
	configuration: Record<string, unknown> | null;
	defaultApprovalAlgorithmId: string;
	name: string;
	notifyAboutExternalPermissions: boolean;
	oauthConfiguration: boolean;
	ownerUserId: string;
	readonly: boolean;
	autoAssignRecommendedResourceMaintainers: boolean;
	autoAssignRecommendedResourceOwner: boolean;
}
export interface IIntegrationCreationData extends IIntegrationSharedData {
	defaultAllowsRequests: boolean;
}

export interface ICheckIntegration {
	isValidConfig: boolean;
	message: string;
}

interface ICheckIntegrationData {
	agentTokenId?: string;
	integrationId?: string;
	applicationId: string;
	configuration: Record<string, unknown> | null;
}
export type TIntegrationUpdateData = Partial<IIntegrationSharedData> & {
	id: string;
	maintainers?: IIntegrationMaintainer[] | null;
	defaultAllowsRequests?: {
		value: boolean;
		updateAllResources: boolean;
	};
};

export async function createIntegration(integration: IIntegrationCreationData): Promise<IntegrationModel> {
	const { data } = await apiReq("POST", "/v1/integrations", integration);

	return IntegrationModel.fromServerData(data);
}

export async function checkIntegration(checkConfigData: ICheckIntegrationData) {
	const { data } = await apiReq("POST", "/v1/integrations/checkConfiguration", checkConfigData);
	return data as ICheckIntegration;
}

export async function syncIntegration(integrationId: string) {
	const { data } = await apiReq("POST", `/v1/integrations/${integrationId}/sync`);
	return IntegrationModel.fromServerData(data);
}

export async function editIntegration(integration: TIntegrationUpdateData): Promise<IntegrationModel> {
	const body = omitBy(integration, value => value === undefined);
	const { data } = await apiReq("PUT", `/v1/integrations/${integration.id}`, body);

	return IntegrationModel.fromServerData(data);
}
