import { TicketModel } from "models/TicketModel";
import constate from "constate";
import { useState } from "react";
import { useIsOpenState } from "hooks/useIsOpenState";

const useRedirectModal = () => {
	const [ticket, setTicket] = useState<TicketModel | null>(null);
	const { isOpen, close, open, setIsOpen } = useIsOpenState();

	return { state: { ticket, isOpen }, actions: { setTicket, open, close, setIsOpen } };
};

export const [RedirectModalProvider, useRedirectModalContext] = constate(useRedirectModal);
