import React from "react";
import { LoadingDots } from "components/ui/LoadingDots";
import { useIntegrations } from "hooks/useIntegrations";
import { Chip } from "components/ui/chips/Chip";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { useStyles } from "./styles";
import type { IntegrationModel } from "models/IntegrationModel";

const RoleItem: FC<{ role: IntegrationResourceRoleModel; integration?: IntegrationModel }> = ({
	role,
	integration
}) => {
	const classes = useStyles();

	return (
		<Chip
			size="medium"
			variant="regular"
			PrefixIcon={
				integration?.imageUrl
					? getDynamicSizeIcon(<img className={classes.image} src={integration.imageUrl} />)
					: undefined
			}>
			{`${role.integrationResource!.name} - ${role.name}`}
		</Chip>
	);
};

export const RolesList: FC<{ roles: IntegrationResourceRoleModel[] }> = ({ roles }) => {
	const integrations = useIntegrations(true);

	const getRoleIntegration = (role: IntegrationResourceRoleModel) =>
		integrations && role?.integrationResource ? integrations?.get(role.integrationResource.integrationId) : undefined;
	return integrations ? (
		<>
			{roles.map(role => (
				<RoleItem key={role.id} role={role} integration={getRoleIntegration(role)} />
			))}
		</>
	) : (
		<LoadingDots center />
	);
};
