import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useControlled } from "hooks/useControlled";
import { CollapseIcon } from "components/ui/Icons/CollapseIcon";
import { ExpandIcon } from "components/ui/Icons/ExpandIcon";
import { IconButton } from "components/ui/IconButton";
import { Typography } from "components/ui/Typography";
import { Divider } from "../Divider";
import { useStyles } from "./styles";

type TProps = {
	collapsible?: boolean;
	defaultExpanded?: boolean;
	expanded?: boolean;
	onToggle?: (expanded: boolean) => void;
	subtitle?: React.ReactNode;
	title: React.ReactNode;
	titleActions?: React.ReactNode;
	noDivider?: boolean;
	fullHeight?: boolean;
	contentClassName?: string;
};

export const Section: FC<TProps> = ({
	children,
	className,
	collapsible = false,
	defaultExpanded,
	expanded: propExpanded,
	fullHeight = false,
	innerRef,
	noDivider = false,
	onToggle,
	subtitle,
	title,
	titleActions,
	contentClassName
}) => {
	const [expanded, setExpanded] = useControlled<boolean>({
		controlled: propExpanded,
		default: defaultExpanded
	});
	const classes = useStyles();

	const toggle = useCallback(() => {
		setExpanded(!expanded);

		if (onToggle) {
			onToggle(!expanded);
		}
	}, [expanded, onToggle, setExpanded]);

	const titleComponent = useMemo(() => {
		if (typeof title === "string") {
			return <Typography variant="body_sb">{title}</Typography>;
		}
		return title;
	}, [title]);

	const subtitleComponent = useMemo(() => {
		if (!subtitle) return null;
		if (typeof subtitle === "string") {
			return <Typography variant="text_reg">{subtitle}</Typography>;
		}
		return subtitle;
	}, [subtitle]);

	const expandIcon = useMemo(() => {
		if (!collapsible) return null;
		return <IconButton onClick={toggle}> {expanded ? <CollapseIcon /> : <ExpandIcon />}</IconButton>;
	}, [collapsible, expanded, toggle]);

	return (
		<div className={classNames(classes.container, { [classes.full]: fullHeight }, className)} ref={innerRef}>
			<div className={classes.header}>
				<div className={classes.title}>
					<div className={classes.titleLeft}>
						{titleComponent}
						{expandIcon}
					</div>
					<div className={classes.titleRight}>{titleActions || null}</div>
				</div>
				{subtitleComponent}
			</div>
			<div
				className={classNames(
					classes.content,
					{
						[classes.expanded]: !collapsible || expanded,
						[classes.full]: fullHeight
					},
					contentClassName
				)}>
				{children}
			</div>
			{noDivider ? null : (
				<div className={classes.divider}>
					<Divider horizontal />
				</div>
			)}
		</div>
	);
};
