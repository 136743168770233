import React from "react";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { useIntegrations } from "hooks/useIntegrations";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { useTranslation } from "react-i18next";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { Chip, TChipVariant, type TChipSize } from "../Chip";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

interface IRoleChipProps {
	integrationResourceRole: IntegrationResourceRoleModel;
	onDelete?: () => void;
	readonly?: boolean;
	selected?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
}

export const RoleChip: FC<IRoleChipProps> = ({
	className,
	innerRef,
	integrationResourceRole,
	onDelete,
	readonly = false,
	selected = false,
	size = "medium",
	variant = "regular"
}) => {
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const integration = integrations?.get(integrationResourceRole.integrationResource?.integrationId || "");
	const image = integration?.imageUrl;

	return (
		<Tooltip
			content={<TitleBody size="small" title={t("common.tooltips.roleName")} body={integrationResourceRole.name} />}>
			<Chip
				noTooltip
				readonly={readonly}
				variant={variant}
				size={size}
				PrefixIcon={image ? getDynamicSizeIcon(<img src={image}></img>) : RoleIcon}
				onDelete={onDelete}
				selected={selected}
				className={className}
				innerRef={innerRef}>
				{`${integrationResourceRole.name}`}
			</Chip>
		</Tooltip>
	);
};
