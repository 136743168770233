import { useCallback } from "react";
import get from "lodash/get";
import { useNavigate } from "react-router-dom";

export const useGoBack = (defaultRoute = "/requests") => {
	const navigate = useNavigate();

	const onGoBack = useCallback(() => {
		const canGoBack = get(window, "history.state.idx", 0) !== 0;
		if (canGoBack) navigate(-1);
		else navigate(defaultRoute);
	}, [navigate, defaultRoute]);

	return onGoBack;
};
