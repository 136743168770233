import { useAuthenticatedUserContext } from "context/AuthenticatedUserContext";
import { useUsersContext } from "context/usersContext";
import { UserModel } from "models/UserModel";
import { useCallback } from "react";

export const useUpdateUser = () => {
	const {
		actions: { updateUser }
	} = useAuthenticatedUserContext();

	const {
		actions: { setUser }
	} = useUsersContext();

	const updateUserData = useCallback(
		(newUser: UserModel) => {
			updateUser(newUser);
			setUser(newUser);
		},
		[updateUser, setUser]
	);

	return updateUserData;
};
