import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	header: {
		alignItems: "center",
		display: "flex",
		flexWrap: "wrap",
		gap: "var(--spacing-x3)",
		justifyContent: "flex-start",
		width: "100%"
	},
	headerLeft: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2)",
		justifyContent: "flex-start",
		maxWidth: "296px",
		minWidth: "160px"
	},
	headerRight: {
		alignItems: "center",
		display: "flex",
		flexGrow: 1,
		gap: "var(--spacing-x2)",
		justifyContent: "flex-end"
	},
	inputs: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2)",
		justifyContent: "flex-start",
		width: "100%"
	},
	headerActions: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2)",
		justifyContent: "flex-start"
	}
});
