import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { Typography } from "components/ui/legacy/Typography";
import { useStyles } from "./styles";

const IntegrationCard: FC = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<div className={classes.emptyStateCard}>
			<IntegrationIcon />
			<Typography relative>{t("pages.identityGraph.emptyState.integration")}</Typography>
		</div>
	);
};

const UserCard: FC = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<div className={classes.emptyStateCard}>
			<UserCircleIcon />
			<Typography relative>{t("pages.identityGraph.emptyState.user")}</Typography>
		</div>
	);
};

export const EmptyGraphState: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const transComponents = useMemo(() => {
		return { user: <UserCard />, integration: <IntegrationCard /> };
	}, []);

	return (
		<Typography className={classNames(className, classes.emptyStateText)} variant="h3" innerRef={innerRef}>
			<Trans components={transComponents} t={t} i18nKey={"pages.identityGraph.emptyState.container"} />
		</Typography>
	);
};
