import React, { useCallback, useMemo } from "react";
import { MultipleSelect, TTargetValue } from "components/ui/MultipleSelect";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { useSearchOptions } from "hooks/useSearchOptions";
import { getIntegrationActors } from "api/integrationActors";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Typography } from "components/ui/legacy/Typography";
import { Select } from "components/ui/Select";

interface IBaseSelectProps<TValue> {
	integrationId: string;
	optionClassName?: string;
	value: TValue | null;
	sortBy?: (a: IntegrationActorModel, b: IntegrationActorModel) => number;
}

interface IMultipleProps extends IBaseSelectProps<IntegrationActorModel[]> {
	label?: string;
	onChange: (value: IntegrationActorModel[], integrationId: string) => Promise<void> | void;
	multiLine?: boolean;
}

interface ISingleProps extends IBaseSelectProps<IntegrationActorModel> {
	label?: string;
	onChange: (value: IntegrationActorModel, integrationId: string) => Promise<void> | void;
}

const getLabel = (integrationActor: IntegrationActorModel) => integrationActor?.displayName;
const equality = (option: IntegrationActorModel, value: IntegrationActorModel) => option?.id === value?.id;

const fetchActors = (integrationId: string) => async (query: string) => {
	if (integrationId) {
		const resources = await getIntegrationActors(integrationId, query);
		const options = resources.toArray();
		return options;
	}
	return [];
};

function useIntegrationActorSelect<TValue extends IntegrationActorModel | IntegrationActorModel[]>({
	integrationId,
	optionClassName,
	value,
	sortBy
}: IBaseSelectProps<TValue>) {
	const fetchIntegrationActorsHandler = useMemo(() => fetchActors(integrationId), [integrationId]);
	const [actors, searchActors, loading] = useSearchOptions<IntegrationActorModel>(
		fetchIntegrationActorsHandler,
		value,
		true
	);
	const { t } = useTranslation();

	const renderActorOption = useCallback(
		(option: IntegrationActorModel) => (
			<Typography component="div" className={classNames(optionClassName, { disabled: option.isUsed })}>
				<span>{option.displayName}</span>
				{option.isUsed ? (
					<Typography variant="small">{t("pages.profile.changeProfileForm.integrationActors.used")}</Typography>
				) : null}
			</Typography>
		),
		[optionClassName, t]
	);

	const onInputChange = useCallback(
		({ target: { value } }: TTargetValue) => {
			searchActors(value);
		},
		[searchActors]
	);

	const sortedActors = useMemo(() => (sortBy && actors ? actors.sort(sortBy) : actors || []), [actors, sortBy]);

	return { renderActorOption, actors, searchActors, loading, onInputChange, sortedActors };
}

export const IntegrationActorsSelectInput: FC<IMultipleProps> = ({
	className,
	multiLine,
	value,
	label,
	integrationId,
	onChange,
	sortBy,
	optionClassName
}) => {
	const { sortedActors, renderActorOption, onInputChange, loading } = useIntegrationActorSelect({
		optionClassName,
		value,
		integrationId,
		sortBy
	});
	const onValueChanged = useCallback(
		(newValue: IntegrationActorModel[] | null) => {
			if (newValue) {
				onChange(newValue, integrationId);
			}
		},
		[integrationId, onChange]
	);

	return (
		<MultipleSelect
			className={className}
			getOptionLabel={getLabel}
			isOptionEqualToValue={equality}
			label={label}
			loading={loading}
			onChange={onValueChanged}
			onInputChange={onInputChange}
			options={sortedActors}
			renderOption={renderActorOption}
			filter={null}
			value={value}
			type="account"
			limitChipType="account"
			multiLine={multiLine}
		/>
	);
};

export const IntegrationActorSelectInput: FC<ISingleProps> = ({
	className,
	value,
	label,
	integrationId,
	onChange,
	sortBy,
	optionClassName
}) => {
	const { sortedActors, renderActorOption, onInputChange, loading } = useIntegrationActorSelect({
		optionClassName,
		value,
		integrationId,
		sortBy
	});
	const onValueChanged = useCallback(
		(newValue: IntegrationActorModel | null) => {
			if (newValue) {
				onChange(newValue, integrationId);
			}
		},
		[integrationId, onChange]
	);

	return (
		<Select
			className={className}
			getOptionLabel={getLabel}
			isOptionEqualToValue={equality}
			label={label}
			loading={loading}
			onChange={onValueChanged}
			onInputChange={onInputChange}
			options={sortedActors}
			renderOption={renderActorOption}
			filter={null}
			value={value}
		/>
	);
};
