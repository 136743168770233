import { EventEmitter } from "events";
import { useRef, useCallback } from "react";
import constate from "constate";
import { useLiveUpdate } from "hooks/useLiveUpdate";
import { BULK_ACTIONS_EVENT_NAME, UPDATES_ROOM_NAME } from "utils/websockets/constants";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";

export type TBulkActionProgressData = { model: string } & ({ progressPercentage: number } | { error: true });

const useAdminLiveUpdates = () => {
	const { user } = useAuthenticatedUser();
	const eventEmitterRef = useRef(new EventEmitter());

	const onBulkActionsUpdate = useCallback((data: TBulkActionProgressData) => {
		eventEmitterRef.current.emit(BULK_ACTIONS_EVENT_NAME, data);
	}, []);

	const subscribeToBulkActionsUpdate = useCallback(
		(callback: (data: { model: string; progressPercentage: number }) => void) => {
			eventEmitterRef.current.on(BULK_ACTIONS_EVENT_NAME, callback);
		},
		[]
	);

	const unsubscribeFromBulkActionsUpdate = useCallback(
		(callback: (data: { model: string; progressPercentage: number }) => void) => {
			eventEmitterRef.current.off(BULK_ACTIONS_EVENT_NAME, callback);
		},
		[]
	);

	useLiveUpdate({
		roomName: UPDATES_ROOM_NAME,
		eventName: BULK_ACTIONS_EVENT_NAME,
		handleEvent: onBulkActionsUpdate,
		shouldJoinRoom: user?.role === "admin"
	});

	return { subscribeToBulkActionsUpdate, unsubscribeFromBulkActionsUpdate };
};

export const [AdminLiveUpdatesProvider, useAdminLiveUpdatesContext] = constate(useAdminLiveUpdates);
