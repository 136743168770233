import type { TFunction } from "i18next";
import type { ErrorObject } from "ajv";

export const HEADERS_SCHEMA = {
	type: "object",
	additionalProperties: {
		type: "string"
	}
};

export const getErrorMessages = (errors: Partial<ErrorObject>[] | string[], t: TFunction) =>
	errors.map(error => {
		if (typeof error === "string") return error;

		const { message, keyword, instancePath } = error;

		const noPathMessage = t("pages.workflowsWebhooks.table.errorMessage", {
			message,
			keyword,
			context: message ? "WithMessage" : undefined
		});

		if (instancePath) {
			let path: string | undefined;
			try {
				const pathArray = JSON.parse(instancePath.replace(/'/g, '"')) as string[];
				path = pathArray.join("/");
				return t("pages.workflowsWebhooks.table.errorMessage", {
					message,
					keyword,
					path,
					context: message ? "WithPathAndMessage" : "WithPath"
				});
			} catch (_) {
				return noPathMessage;
			}
		}
		return noPathMessage;
	});
