import React, { ReactNode, useMemo } from "react";
import classNames from "classnames";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "./styles";
import type { TTypographyVariant } from "components/ui/Typography";
import type { IRowOptions } from "../../types";

const TextCellContent: FC<
	{
		text: ReactNode;
		icon?: ReactNode;
		textVariant?: TTypographyVariant;
		chip?: ReactNode;
	} & IRowOptions
> = ({ text, icon, textVariant, chip, className }) => {
	const classes = useStyles();

	const iconComponent = useMemo(() => (icon ? <div className={classes.icon}>{icon}</div> : null), [classes.icon, icon]);

	return (
		<div className={classNames(classes.textCell, className)}>
			{iconComponent}
			<TooltipOnOverflow content={text} textVariant={textVariant} />
			{chip ?? null}
		</div>
	);
};

const TextCellContentMemo = React.memo(TextCellContent) as typeof TextCellContent;

export { TextCellContentMemo as TextCellContent };
