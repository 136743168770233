import copy from "copy-to-clipboard";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "components/ui/Icons/CopyIcon";
import { Button } from "components/ui/Button";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { getUrlParams } from "components/pages/NewRequestPage/components/SummaryStep/components/CopyTemplate/copyTemplate.utils";

export const CopyTemplate: FC = () => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep" });
	const {
		state: { requestTargets, duration, ticketingIntegrationTicketId }
	} = useNewRequestFormContext();
	const [isCopied, setIsCopied] = useState(false);

	const copyLink = useCallback(async () => {
		const params = getUrlParams({
			targets: requestTargets.toArray(),
			duration: duration!,
			ticketingIntegrationTicketId: ticketingIntegrationTicketId ?? undefined
		});
		const link = `${window.location.origin}/beta/newRequest?${params}`;
		if (params) {
			copy(link);
			setIsCopied(true);
			setTimeout(() => setIsCopied(false), 3000);
		}
	}, [duration, requestTargets, ticketingIntegrationTicketId]);

	return (
		<Button
			prefix={<CopyIcon size={20} />}
			suffix={isCopied ? <GrantedIcon size={20} /> : null}
			onClick={copyLink}
			variant={isCopied ? "primary" : "secondary"}
			size="medium">
			{isCopied ? t("copied") : t("copyLink")}
		</Button>
	);
};
