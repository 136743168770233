import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { List } from "immutable";
import uniq from "lodash/uniq";
import { useTranslation } from "react-i18next";
import { TitleBody } from "components/ui/TitleBody";
import { Button } from "components/ui/Button";
import { changeProfile } from "api/user";
import { IconPrefix } from "components/ui/IconPrefix";
import { getIntegrationIcon } from "components/common/IntegrationImage";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { Modal } from "components/ui/Modal";
import { Input } from "components/ui/Input";
import { AccountNode } from "components/common/Nodes/AccountNode";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { getIntegrationActors } from "api/integrationActors";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useUpdateUser } from "hooks/useUpdateUser";
import { useLoadingState } from "hooks/useLoadingState";
import { useStyles } from "./styles";
import type { IntegrationActorModel } from "models/IntegrationActorModel";
import type { IntegrationModel } from "models/IntegrationModel";
type TProps = {
	close: () => void;
	isOpen: boolean;
	actorIntegration: IntegrationModel | null;
};

const Integration: FC<{ integration?: IntegrationModel }> = ({ integration }) => {
	if (!integration) return null;
	return <IconPrefix size="huge" semibold Icon={getIntegrationIcon(integration)} content={integration.name} />;
};

const TRANSLATION_PREFIX = "pages.newRequest.summaryStep.chooseActorModal";

const useIntegrationActors = (integrationId: string | undefined) => {
	const [actors, setActors] = useState<List<IntegrationActorModel>>(List());
	useEffect(() => {
		if (!integrationId) return;
		getIntegrationActors(integrationId).then(actorRes => setActors(actorRes));
	}, [integrationId]);

	return { actors };
};

export const ChooseActorModal: FC<TProps> = ({ className, innerRef, actorIntegration, isOpen, close }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { user } = useAuthenticatedUser();
	const [selectedIntegrationActor, setSelectedIntegrationActor] = useState<IntegrationActorModel | null>(null);
	const [query, setQuery] = useState("");
	const updateUser = useUpdateUser();
	const { isLoading, withLoader } = useLoadingState();
	const { actors: integrationActors } = useIntegrationActors(actorIntegration?.id);
	const {
		state: { fullReceiverUser },
		actions: { addReceiverIntegrationActor }
	} = useNewRequestFormContext();

	const currentIntegrationUserActors = useMemo(() => {
		if (!fullReceiverUser || !fullReceiverUser.integrationActors || !actorIntegration) return null;
		return fullReceiverUser.integrationActors.filter(actor => actor.integrationId === actorIntegration.id);
	}, [fullReceiverUser, actorIntegration]);

	const isMultipleActors = useMemo(
		() => currentIntegrationUserActors?.size && currentIntegrationUserActors.size > 1,
		[currentIntegrationUserActors?.size]
	);

	const fillMissingActors = useCallback(async () => {
		const newIntegrationActors = uniq([
			selectedIntegrationActor?.id || "",
			...(user?.integrationActors?.map(actor => actor.id) || [])
		]);
		const newUser = await withLoader(
			changeProfile({
				integrationActors: newIntegrationActors
			})
		);
		updateUser(newUser);
	}, [selectedIntegrationActor?.id, user?.integrationActors, withLoader, updateUser]);

	const setIntegrationActor = useCallback(async () => {
		if (!selectedIntegrationActor || !actorIntegration) return;
		if (isMultipleActors) {
			addReceiverIntegrationActor(actorIntegration.id, selectedIntegrationActor.id);
		} else {
			await fillMissingActors();
		}
		close();
	}, [
		selectedIntegrationActor,
		actorIntegration,
		isMultipleActors,
		close,
		addReceiverIntegrationActor,
		fillMissingActors
	]);

	const actors = isMultipleActors ? currentIntegrationUserActors : integrationActors;
	const modalContent = useMemo(() => {
		if (!actors || !actorIntegration) return null;
		const filteredActors = actors.filter(actor => actor.displayName.toLowerCase().includes(query.toLowerCase()));
		const title = t(`${TRANSLATION_PREFIX}.${isMultipleActors ? "assignAccountTitle" : "missingAccountTitle"}`);
		const content = t(`${TRANSLATION_PREFIX}.${isMultipleActors ? "assignAccountContent" : "missingAccountContent"}`);
		return (
			<div className={classes.content}>
				<TitleBody size="large" title={title} body={content} />
				<Integration integration={actorIntegration} />
				<div className={classes.inputAndList}>
					{actors.size > 5 ? (
						<Input value={query} placeholder={t(`${TRANSLATION_PREFIX}.inputPlaceholder`)} onValueChange={setQuery} />
					) : null}
					<div className={classes.actorList}>
						{filteredActors.map(actor => {
							const selectActor = () => setSelectedIntegrationActor(actor);
							return (
								<AccountNode
									key={actor.id}
									content={<TooltipOnOverflow textVariant="text_sm_sb" content={actor.displayName} />}
									selected={selectedIntegrationActor?.id === actor.id}
									onClick={selectActor}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}, [
		actors,
		actorIntegration,
		t,
		isMultipleActors,
		classes.content,
		classes.inputAndList,
		classes.actorList,
		query,
		selectedIntegrationActor?.id
	]);

	const modalActions = useMemo(() => {
		return (
			<>
				<Button variant="secondary" size="medium" onClick={close}>
					{t("buttons.cancel")}
				</Button>
				<Button size="medium" onClick={setIntegrationActor} disabled={!selectedIntegrationActor} loading={isLoading}>
					{t(`${TRANSLATION_PREFIX}.assignAccount`)}
				</Button>
			</>
		);
	}, [close, isLoading, selectedIntegrationActor, setIntegrationActor, t]);

	if (!modalContent) return null;
	return (
		<Modal
			actions={modalActions}
			className={classNames(classes.modal, className)}
			content={modalContent}
			innerRef={innerRef}
			isOpen={isOpen}
			onClose={close}
		/>
	);
};
