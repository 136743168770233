import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	integrationLogsSectionWrapper: {
		display: "flex",
		flexDirection: "column"
	},
	integrationLogsSection: {
		paddingTop: "var(--spacing-x7, 28px)"
	}
});
