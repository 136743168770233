import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { List, Set } from "immutable";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { StaticChip } from "components/ui/chips/StaticChip";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { getPrerequisitePermissions } from "api/accessRequestForm";
import { Button } from "components/ui/Button";
import { PrerequisiteIcon } from "components/ui/Icons/PrerequisiteIcon";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useIsOpenState } from "hooks/useIsOpenState";
import { EditButton } from "../EditButton";
import { PrerequisitePermissionsModal } from "./components/PrerequisitePermissionsModal";
import { useStyles } from "./styles";
import { RequestTarget } from "./components/RequestTarget";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TRequestTarget } from "components/pages/NewRequestPage/types";

const usePrerequisitePermissions = (targets: List<TRequestTarget>, receiverUserId?: string) => {
	const [prerequisitePermissions, setPrerequisitePermissions] = useState(List<IntegrationResourceRoleModel>());

	useEffect(() => {
		const fetchPrerequisitePermissions = async () => {
			if (!receiverUserId) return;
			const roles = await getPrerequisitePermissions(
				receiverUserId,
				targets
					.map(target => ({
						type: target.type,
						id: target.id
					}))
					.toArray()
			);
			setPrerequisitePermissions(roles);
		};
		fetchPrerequisitePermissions();
	}, [targets, receiverUserId]);

	return prerequisitePermissions;
};

type TRequestTargetSectionProps = {
	multipleAndMissingActorsTargets: Set<string>;
	chooseActor?: (id: string) => void;
	onExit: () => void;
};

export const RequestTargetsSection: FC<TRequestTargetSectionProps> = ({
	className,
	innerRef,
	chooseActor,
	onExit,
	multipleAndMissingActorsTargets
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep.requestTargetsSection" });
	const classes = useStyles();

	const [targetToRemove, setTargetToRemove] = useState<string>();

	const { isOpen: prerequisiteIsOpen, open: prerequisiteOpen, close: prerequisiteClose } = useIsOpenState();
	const { isOpen: areYouSureIsOpen, open: areYouSureOpen, close: areYouSureClose } = useIsOpenState();

	const {
		state: { requestTargets, receiverUser },
		actions: { removeTarget }
	} = useNewRequestFormContext();
	const prerequisitePermissions = usePrerequisitePermissions(requestTargets, receiverUser?.id);

	const title = useMemo(() => {
		return (
			<div className={classes.titleContainer}>
				<div className={classes.titleChipContainer}>
					<Typography variant="body_sb">{t("title")}</Typography>
					<StaticChip size="small" variant="regular">
						{requestTargets.size}
					</StaticChip>
				</div>
				<EditButton stepIndex={0} />
			</div>
		);
	}, [classes.titleChipContainer, classes.titleContainer, requestTargets.size, t]);

	const titleActions = useMemo(() => {
		if (prerequisitePermissions.size === 0) return null;
		return (
			<Button variant="text" size="small" onClick={prerequisiteOpen} prefix={<PrerequisiteIcon size={12} />}>
				{t("prerequisites")}
			</Button>
		);
	}, [prerequisitePermissions.size, prerequisiteOpen, t]);

	const hasBundles = useMemo(() => {
		return requestTargets.some(target => target.type === "bundle");
	}, [requestTargets]);

	const onRemoveTargetClick = useCallback(
		(targetId: string) => {
			if (requestTargets.size === 1) {
				onExit();
			} else {
				setTargetToRemove(targetId);
				areYouSureOpen();
			}
		},
		[areYouSureOpen, onExit, requestTargets.size]
	);

	const onRemoveTarget = useCallback(async () => {
		if (targetToRemove) {
			areYouSureClose();
			return removeTarget(targetToRemove);
		}
	}, [areYouSureClose, removeTarget, targetToRemove]);

	return (
		<>
			<PrerequisitePermissionsModal
				isOpen={prerequisiteIsOpen}
				onClose={prerequisiteClose}
				prerequisitePermissions={prerequisitePermissions}
			/>
			<AreYouSureModal
				onAction={onRemoveTarget}
				isOpen={areYouSureIsOpen}
				onClose={areYouSureClose}
				onCancel={areYouSureClose}
				content={t("areYouSureModal.content")}
				actionLabel={t("areYouSureModal.actionLabel")}
				closeLabel={t("areYouSureModal.cancelLabel")}
			/>
			<Section className={classNames(className)} title={title} innerRef={innerRef} titleActions={titleActions}>
				<div className={classes.targets}>
					{requestTargets.toArray().map(target => (
						<RequestTarget
							hasBundles={hasBundles}
							key={target.id}
							chooseActor={multipleAndMissingActorsTargets.has(target.id) ? chooseActor : undefined}
							removeTarget={onRemoveTargetClick}
							target={target}
						/>
					))}
				</div>
			</Section>
		</>
	);
};
