import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AccessReviewReportsProvider } from "context/accessReviewReportsContext";
import { AccessReviewResourcesProvider } from "context/accessReviewResourcesContext";
import { AccessReviewSubordinatesProvider } from "context/accessReviewSubordinatesContext";
import { AccessReviewTemplatesProvider } from "context/accessReviewTemplatesContext";
import { AccessReviewsProvider } from "context/accessReviewsContext";
import { AdminsProvider } from "context/adminsContext";
import { ApiTokensProvider } from "context/apiTokensContext";
import { ApprovalAlgorithmsProvider } from "context/approvalAlgorithmsContext";
import { ApprovalFlowsProvider } from "context/approvalFlowContext";
import { BundlesProvider } from "context/bundlesContext";
import { CompanyProvider } from "context/companyContext";
import { GlobalErrorProvider } from "context/globalErrorContext";
import { IntegrationsProvider } from "context/integrationsContext";
import { OnCallIntegrationSchedulesProvider } from "context/onCallIntegrationSchedulesContext";
import { Provider } from "context/Provider";
import { RedirectModalProvider } from "context/redirectModalContext";
import { TasksProvider } from "context/tasksContext";
import { TicketUpdatesProvider } from "context/ticketUpdatesContext";
import { AuthenticatedUserProvider } from "context/AuthenticatedUserContext";
import { UsersProvider } from "context/usersContext";
import { AgentTokensProvider } from "context/agentTokensContext";
import { ApplicationsProvider } from "context/applicationsContext";
import { AccessReportResourcesProvider } from "context/accessReportResourcesContext";
import { DirectoryConfigurationsProvider } from "context/directoryConfigurationsContext";
import { OverlaysProvider } from "context/overlaysContext";
import { ObserverProvider } from "context/observerContext";
import { PoliciesProvider } from "context/policiesContext";
import { AuthProvider } from "context/authContext";
import { AuditLogsWebhooksProvider } from "context/auditLogsWebhooksContext";
import { PersonalAccessTokensProvider } from "context/personalAccessTokensContext";
import { IdentityProvidersProvider } from "context/identityProvidersContext";
import { BetaProvider } from "context/betaContext";
import { BreakpointsProvider } from "context/breakpointsContext";
import { ApprovalFlowsWebhookProvider } from "context/approvalFlowsWebhooksContext";
import { SideNavProvider } from "context/sideNavContext";
import { LiveUpdatesProvider } from "context/liveUpdatesContext";
import { AdminLiveUpdatesProvider } from "context/adminLiveUpdatesContext";
import { BulkActionsUpdatesProvider } from "context/bulkActionsUpdatesContext";
import { DirectoryGroupsProvider } from "context/directoryGroupsContext";
import { DefaultErrorBoundary } from "./components/ErrorBoundary";

export const Providers: FC = ({ children }) => {
	return (
		<BreakpointsProvider>
			<ErrorBoundary FallbackComponent={DefaultErrorBoundary}>
				<GlobalErrorProvider>
					<AuthProvider>
						<LiveUpdatesProvider>
							<AuthenticatedUserProvider>
								<Provider
									// The order does matter! do not change
									// There are multiple levels of providers, because some contexts depend on others
									providers={[
										IntegrationsProvider,
										AdminLiveUpdatesProvider,
										AccessReviewResourcesProvider,
										AccessReviewSubordinatesProvider,
										ApprovalAlgorithmsProvider,
										ApprovalFlowsProvider
									]}>
									<Provider
										providers={[
											AccessReportResourcesProvider,
											AccessReviewReportsProvider,
											AccessReviewTemplatesProvider,
											AccessReviewsProvider,
											AdminsProvider,
											AgentTokensProvider,
											ApiTokensProvider,
											ApplicationsProvider,
											ApprovalFlowsWebhookProvider,
											AuditLogsWebhooksProvider,
											BetaProvider,
											BulkActionsUpdatesProvider,
											BundlesProvider,
											CompanyProvider,
											DirectoryConfigurationsProvider,
											DirectoryGroupsProvider,
											IdentityProvidersProvider,
											OnCallIntegrationSchedulesProvider,
											OverlaysProvider,
											PersonalAccessTokensProvider,
											PoliciesProvider,
											RedirectModalProvider,
											SideNavProvider,
											TasksProvider,
											TicketUpdatesProvider,
											UsersProvider,
											ObserverProvider
										]}>
										<>{children}</>
									</Provider>
								</Provider>
							</AuthenticatedUserProvider>
						</LiveUpdatesProvider>
					</AuthProvider>
				</GlobalErrorProvider>
			</ErrorBoundary>
		</BreakpointsProvider>
	);
};
