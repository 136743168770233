import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		justifyContent: "flex-end",
		gap: "var(--spacing-x2, 8px)"
	}
});
