import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator, validateFilterRelation } from "utils/validation/filterValidation";
import type { IFilter, TFilterOperator } from "types/filters";

export const OPERATORS = ["is", "isNot"] as const satisfies TFilterOperator[];

const filterName = "integrationResourceTags" as const;

const defaults = {
	name: filterName,
	value: [] as string[],
	operator: "is" as (typeof OPERATORS)[number],
	relation: "or" as "and" | "or"
};

type TDefaults = typeof defaults;

export class IntegrationResourceTagsFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceTagsFilter | null {
		const integrationResourceTags = uniq(urlSearchParams.getAll("integrationResourceTags"));
		const integrationResourceTagsOperator = urlSearchParams.get("integrationResourceTagsOperator") || undefined;
		const integrationResourceTagsRelation = urlSearchParams.get("integrationResourceTagsRelation") || undefined;
		if (
			integrationResourceTags.length === 0 ||
			!validateFilterEqualityOperator(integrationResourceTagsOperator) ||
			!validateFilterRelation(integrationResourceTagsRelation)
		) {
			return null;
		}
		return new IntegrationResourceTagsFilter({
			value: integrationResourceTags,
			operator: integrationResourceTagsOperator,
			relation: integrationResourceTagsRelation
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceTags", value);
		}
		params.append("integrationResourceTagsOperator", this.operator);
		params.append("integrationResourceTagsRelation", this.relation);
		return params;
	}
}

export type TIntegrationResourceTagsFilter = Omit<TDefaults, "name">;
