import { Title } from "components/ui/Title";
import { Input } from "components/ui/Input";
import { Button } from "components/ui/Button";
import React, { useCallback, useMemo, useState } from "react";
import { removeRedundantSpaces } from "utils/strings";
import { changeProfile } from "api/user";
import { IsNullError } from "utils/errors/isNullError";
import { UserModel } from "models/UserModel";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useTranslation } from "react-i18next";
import { useLoadingState } from "hooks/useLoadingState";
import { getNameValidators } from "utils/validation/validationUtils";
import { useStyles } from "./styles";

interface IProps {
	user: UserModel;
	onSuccess: () => void;
	onUpdate: (user: UserModel) => void;
	setError: (error: Error | null) => void;
}

const PREFIX = "pages.profile.changeProfileForm.name";

export const UserNameEdit: FC<IProps> = ({ user, onUpdate, onSuccess, setError }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { withLoader, isLoading } = useLoadingState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const [givenName, setGivenName] = useState<string>(user.givenName || "");
	const [familyName, setFamilyName] = useState<string>(user.familyName || "");

	const isValid = useMemo(() => {
		if (!givenName || !familyName) {
			return false;
		}
		const clearGivenName = removeRedundantSpaces(givenName);
		const clearFamilyName = removeRedundantSpaces(familyName);
		return clearGivenName.length <= 50 && clearFamilyName.length <= 50;
	}, [givenName, familyName]);

	const onSaveName = useCallback(async () => {
		if (!isValid) {
			return;
		}

		const clearGivenName = removeRedundantSpaces(givenName);
		const clearFamilyName = removeRedundantSpaces(familyName);

		try {
			const newUser = await withLoader(
				changeProfile({
					givenName: clearGivenName,
					familyName: clearFamilyName
				})
			);
			onUpdate(newUser);
			onSuccess();
		} catch (error) {
			if (!user) {
				openGlobalErrorModal(
					IsNullError.from({
						location: "onSendRequest",
						requestedProperty: "user"
					})
				);
			}
			setGivenName(user.givenName || "");
			setFamilyName(user.familyName || "");
			setError(error as Error);
		}
	}, [isValid, givenName, familyName, withLoader, onUpdate, onSuccess, user, setError, openGlobalErrorModal]);

	const nameValidator = useMemo(() => getNameValidators(t("shared.name")), [t]);

	return (
		<>
			<Title noBorder variant="h3">
				{t(`${PREFIX}.title`)}
			</Title>
			<div className={classes.userName}>
				<Input
					label={t(`${PREFIX}.givenName`)}
					onValueChange={setGivenName}
					value={givenName}
					validators={nameValidator}
				/>
				<Input
					label={t(`${PREFIX}.familyName`)}
					onValueChange={setFamilyName}
					value={familyName}
					validators={nameValidator}
				/>
			</div>
			<Button
				size="medium"
				disabled={isLoading || !isValid}
				loading={isLoading}
				onClick={onSaveName}
				className={classes.saveButton}>
				{t("buttons.save")}
			</Button>
		</>
	);
};
