import { useMemo, useEffect, useCallback } from "react";
import { Map, List } from "immutable";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import {
	useNewRequestBundles,
	useNewRequestIntegrationResourceRoles,
	useNewRequestIntegrationResources,
	useNewRequestIntegrations
} from "components/pages/NewRequestPage/newRequestDataContext";
import type { IntegrationModel } from "models/IntegrationModel";
import type { BundleModel } from "models/BundleModel";
import type { TCartItem } from "components/common/RequestCart";
import type { TRequestTarget } from "components/pages/NewRequestPage/types";

const useFetchMissingTargets = () => {
	const {
		state: { requestTargets, receiverUser }
	} = useNewRequestFormContext();
	const { allData: bundlesData, fetchBundle } = useNewRequestBundles();
	const { allData: integrationsData, fetchIntegration } = useNewRequestIntegrations();
	const { allData: resources, fetchResource } = useNewRequestIntegrationResources();
	const { allData: roles, fetchRole } = useNewRequestIntegrationResourceRoles();

	// Fetch missing data
	useEffect(() => {
		if (!receiverUser) return;
		requestTargets.forEach(target => {
			if (target.type === "bundle") {
				fetchBundle(target.id, receiverUser.id);
			} else if (target.type === "role") {
				if (!integrationsData.integrations.has(target.integrationId)) {
					fetchIntegration(target.integrationId, receiverUser.id);
				}
				if (!resources.has(target.integrationId)) {
					fetchResource(target.resourceId, receiverUser.id);
				}
				if (!roles.has(target.resourceId)) {
					fetchRole(target.id, receiverUser.id);
				}
			}
		});
	}, [
		bundlesData,
		fetchBundle,
		fetchIntegration,
		fetchResource,
		fetchRole,
		integrationsData,
		receiverUser,
		requestTargets,
		resources,
		roles
	]);

	return useMemo(
		() => ({ bundlesData, integrationsData, resources, roles }),
		[bundlesData, integrationsData, resources, roles]
	);
};

export const useRequestCartData = () => {
	const {
		state: { requestTargets },
		actions: { removeTarget }
	} = useNewRequestFormContext();
	const { bundlesData, integrationsData, resources, roles } = useFetchMissingTargets();

	const cart = useMemo(() => {
		return requestTargets.reduce((acc, target) => {
			if (target.type === "bundle") {
				const bundle = bundlesData.bundles.get(target.id);
				if (!bundle) return acc;

				return acc.set(target.id, bundle);
			}
			const integration = integrationsData.integrations.get(target.integrationId);
			if (!integration) return acc;
			return acc.set(target.integrationId, integration);
		}, Map<string, IntegrationModel | BundleModel>());
	}, [requestTargets, bundlesData, integrationsData]);

	const loadingCartItems = useMemo(() => {
		return requestTargets.reduce((acc, target) => {
			if (target.type === "bundle") {
				const bundle = bundlesData.bundles.get(target.id);
				if (bundle) return acc;

				return acc.set(target.id, target);
			}
			const integration = integrationsData.integrations.get(target.integrationId);
			if (integration) return acc;
			return acc.set(target.integrationId, target);
		}, Map<string, TRequestTarget>());
	}, [requestTargets, bundlesData, integrationsData]);

	const cartItems: Map<string, List<TCartItem>> = useMemo(() => {
		return requestTargets.reduce((acc, target) => {
			if (target.type === "bundle") {
				const bundle = bundlesData.bundles.get(target.id);
				if (!bundle) return acc;
				const cartItems = bundle?.bundleItems.reduce((acc, item) => {
					const role = item.integrationResourceRole;
					const resource = item.integrationResourceRole.integrationResource;
					if (!role || !resource) return acc;
					return acc.push({ resource, role });
				}, List<TCartItem>());
				return acc.set(target.id, cartItems);
			}
			const integration = integrationsData.integrations.get(target.integrationId);
			const resource = resources.get(target.integrationId)?.integrationResources.get(target.resourceId);
			const role = roles.get(target.resourceId)?.integrationResourceRoles.get(target.id);
			if (!integration || !resource || !role) return acc;

			const cartItem: TCartItem = { resource, role };
			const currentIntegrationList = acc.get(target.integrationId) || List();
			return acc.set(target.integrationId, currentIntegrationList.push(cartItem));
		}, Map<string, List<TCartItem>>());
	}, [requestTargets, integrationsData.integrations, resources, roles, bundlesData.bundles]);

	const removeAppFromCart = useCallback(
		(appId: string) => {
			requestTargets.forEach(target => {
				if (
					(target.type === "bundle" && target.id === appId) ||
					(target.type === "role" && target.integrationId === appId)
				) {
					removeTarget(target.id);
				}
			});
		},
		[removeTarget, requestTargets]
	);

	const removeResource = useCallback(
		(resourceId: string) => {
			requestTargets.forEach(target => {
				if (target.type === "role" && target.resourceId === resourceId) {
					removeTarget(target.id);
				}
			});
		},
		[removeTarget, requestTargets]
	);

	const removeRole = useCallback(
		(roleId: string) => {
			removeTarget(roleId);
		},
		[removeTarget]
	);

	return useMemo(
		() => ({
			cart,
			cartItems,
			loadingCartItems,
			removeAppFromCart,
			removeResource,
			removeRole
		}),
		[cart, cartItems, loadingCartItems, removeAppFromCart, removeResource, removeRole]
	);
};
