import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Select, TInputChangeEvent } from "components/ui/Select";
import {
	useNewRequestIntegrationResourceRoles,
	useNewRequestSearchResults
} from "components/pages/NewRequestPage/newRequestDataContext";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { useDebounceFn } from "hooks/useDebounce";
import { useIntegrations } from "hooks/useIntegrations";
import {
	ROLE_BAR_INTEGRATION_WIDTH,
	RoleBarIntegration
} from "components/common/RoleBar/components/RoleBarIntegration";
import { IsNullError } from "utils/errors/isNullError";
import { ROLE_BAR_RESOURCE_WIDTH, RoleBarResource } from "components/common/RoleBar/components/RoleBarResource";
import { ROLE_BAR_ROLE_WIDTH, RoleBarRole } from "components/common/RoleBar/components/RoleBarRole";
import { RoleBar, TRoleBarColumn } from "components/common/RoleBar";
import { RoleBarBundle } from "components/common/RoleBar/components/RoleBarBundle";
import { notEmpty } from "utils/comparison";
import { ROLE_BAR_HEIGHT_PX } from "components/common/RoleBar/styles";
import { SearchIcon } from "components/ui/Icons/SearchIcon";
import { useStyles } from "./styles";
import type { TRequestTarget } from "components/pages/NewRequestPage/types";
import type { TNewTicketOption } from "components/pages/NewTicketPage/components/NewTicketForm/types";

const DEBOUNCE_TIME = 50;
const SHOWN_OPTIONS = 6;

const equality = (option: TNewTicketOption, value: TNewTicketOption) => option.value.id === value.value.id;
const getOptionLabel = () => "";
const getOptionKey = (option: TNewTicketOption) => option.value.id;

const EMPTY_BUNDLE_COLUMN: TRoleBarColumn = {
	content: <RoleBarBundle />
};

const SearchBarOption: FC<{ option: TNewTicketOption; hasBundles: boolean }> = ({ option, hasBundles }) => {
	const integrations = useIntegrations();
	const columns: TRoleBarColumn[] = useMemo(() => {
		if (!integrations) return [];
		if (option.type === "role") {
			const role = option.value;
			const resource = role.integrationResource;
			const integration = integrations.get(resource.integrationId);
			if (!integration) {
				throw IsNullError.from({
					location: "SearchBarOption",
					parentObject: {
						name: "integrations",
						value: integrations.toJS()
					},
					requestedProperty: `get(${resource.integrationId})`
				});
			}
			const roleColumns: TRoleBarColumn[] = [
				{
					content: <RoleBarIntegration name={integration.name} imageUrl={integration.imageUrl} />,
					width: ROLE_BAR_INTEGRATION_WIDTH
				},
				{
					content: (
						<RoleBarResource
							name={resource.name}
							euid={resource.euid}
							description={resource.description || undefined}
							tags={resource.calculatedTags}
							type={resource.type}
						/>
					),
					width: ROLE_BAR_RESOURCE_WIDTH
				},
				{
					content: <RoleBarRole name={role.name} />,
					width: ROLE_BAR_ROLE_WIDTH
				}
			];
			return hasBundles ? [EMPTY_BUNDLE_COLUMN, ...roleColumns] : roleColumns;
		} else {
			const bundle = option.value;
			const roles = bundle.bundleItems.map(bundleItem => bundleItem.integrationResourceRole);
			const resources = roles.map(role => role.integrationResource);
			const uniqueResourcesIds = resources
				.map(resource => resource?.id)
				.filter(notEmpty)
				.toSet();
			const integrationIds = resources
				.map(resource => resource?.integrationId)
				.filter(notEmpty)
				.toSet();
			return [
				{
					content: <RoleBarBundle name={bundle.name} description={bundle.description} tags={bundle.tags} />
				},
				{
					content: <RoleBarIntegration amount={integrationIds.size} />,
					width: ROLE_BAR_INTEGRATION_WIDTH
				},
				{
					content: <RoleBarResource amount={uniqueResourcesIds.size} />,
					width: ROLE_BAR_RESOURCE_WIDTH
				},
				{
					content: <RoleBarRole amount={roles.size} />,
					width: ROLE_BAR_ROLE_WIDTH
				}
			];
		}
	}, [hasBundles, integrations, option]);

	return <RoleBar noInteraction columns={columns} />;
};

export const RoleSearchBar: FC<TProps> = ({ className, innerRef }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.selectRolesStep.searchBar" });
	const classes = useStyles();
	const { data, fetch, loadingState } = useNewRequestSearchResults();
	const {
		state: { requestTargets, receiverUser },
		actions: { toggleTarget }
	} = useNewRequestFormContext();
	const { fetch: fetchRoles } = useNewRequestIntegrationResourceRoles();
	const receiverUserId = receiverUser?.id;

	const options = useMemo(() => {
		if (!data) return [];
		return data.filter(result => !requestTargets.find(target => target.id === result.value.id)).toArray();
	}, [data, requestTargets]);

	const hasBundles = useMemo(() => {
		return options.some(option => option.type === "bundle");
	}, [options]);

	const [debouncedFetch] = useDebounceFn(fetch, DEBOUNCE_TIME);

	const onInputChange = useCallback(
		(event: TInputChangeEvent) => {
			if (!receiverUser?.id) return;
			debouncedFetch({ search: event.target.value, userId: receiverUser.id });
		},
		[debouncedFetch, receiverUser?.id]
	);

	const renderOption = useCallback(
		(option: TNewTicketOption) => {
			return <SearchBarOption option={option} hasBundles={hasBundles} />;
		},
		[hasBundles]
	);

	const onSelect = useCallback(
		(newValue: TNewTicketOption | null) => {
			if (!newValue) return;
			const isBundle = newValue.type === "bundle";
			const resource = !isBundle ? newValue.value.integrationResource : null;
			const newTarget: TRequestTarget = isBundle
				? {
						type: "bundle",
						id: newValue.value.id,
						fullTarget: newValue.value
					}
				: {
						type: "role",
						id: newValue.value.id,
						integrationId: resource!.integrationId,
						resourceId: resource!.id,
						fullTarget: newValue.value
					};

			toggleTarget(newTarget);

			if (!receiverUserId || isBundle || !resource) return;
			fetchRoles({
				userId: receiverUserId,
				integrationResourceId: resource.id
			});
		},
		[fetchRoles, receiverUserId, toggleTarget]
	);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Select
				filter={null}
				getOptionKey={getOptionKey}
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={equality}
				loading={loadingState === "Loading"}
				maxDropdownHeight={ROLE_BAR_HEIGHT_PX * SHOWN_OPTIONS}
				noOptionsText={t("noResults")}
				onChange={onSelect}
				onInputChange={onInputChange}
				optionSelectItemClassName={classes.searchOption}
				options={options}
				placeholder={t("placeholder")}
				prefix={<SearchIcon />}
				renderOption={renderOption}
				sort={null}
				suffix={null}
				variant="search"
			/>
		</div>
	);
};
