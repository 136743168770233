import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { SubordinatesReviewTable } from "components/common/SubordinatesReviewTable";
import { Typography } from "components/ui/legacy/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { useAccessReviewReportState } from "hooks/useAccessReviewReportState";
import { EmailTooltip } from "components/common/EmailTooltip";
import { Entity } from "components/common/Entity";
import { AccessReviewSubordinateModel } from "models/AccessReviewSubordinateModel";
import { ResourcesReviewTable } from "components/common/ResourcesReviewTable";
import { AccessReviewResourceModel } from "models/AccessReviewResourceModel";
import { LoadingDots } from "components/ui/LoadingDots";
import { Tabs } from "components/ui/legacy/Tabs";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";

export const AdminAccessReviewPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { reportId } = useParams<{ reportId: string }>();
	const { accessReviewReport, loading, getAccessReviewReport } = useAccessReviewReportState(reportId || "");
	const reporter = useUser(accessReviewReport?.userId);

	useEffect(() => {
		getAccessReviewReport();
	}, [getAccessReviewReport]);

	const navigate = useNavigate();
	const onViewSubordinate = useCallback(
		(subordinate: AccessReviewSubordinateModel | null) => {
			if (subordinate && subordinate.id) {
				navigate(`/accessReview/subordinates/${subordinate.id}`);
			}
		},
		[navigate]
	);

	const onViewResource = useCallback(
		(resource: AccessReviewResourceModel | null) => {
			if (resource && resource.id) {
				navigate(`/accessReview/resources/${resource.id}`);
			}
		},
		[navigate]
	);

	const defaultTab = accessReviewReport?.sortedSubordinates?.length ? "subordinates" : "resources";

	const breadcrumbs = useMemo(() => {
		return [
			{
				title: t("navigation.accessReview"),
				url: "/accessReview"
			},
			{
				title: t("common.accessReview.reportFrom", { date: accessReviewReport?.createdAt })
			}
		];
	}, [accessReviewReport?.createdAt, t]);

	return (
		<PageTemplate className={className} subPage>
			<PageTemplate.Title className={classes.navigation}>
				<Breadcrumbs parts={breadcrumbs} />
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.content}>
				{loading || !reportId || !accessReviewReport ? (
					<LoadingDots />
				) : (
					<div className={classes.container}>
						<div className={classes.header}>
							{reporter && (
								<Typography variant="h2">
									<EmailTooltip user={reporter}>
										<Entity content={reporter.fullName} />
									</EmailTooltip>
								</Typography>
							)}
							<Typography>
								({t("common.accessReview.entitlements", { count: accessReviewReport.permissionsSize })})
							</Typography>
							<div className={classes.headerSpacer} />

							<Typography>
								{t(`common.accessReview.statuses.subordinates.${accessReviewReport.calculatedStatus}`)}
							</Typography>
						</div>
						<Tabs defaultActiveKey={defaultTab}>
							<Tabs.Tab
								disabled={!accessReviewReport.sortedSubordinates?.length}
								key={"subordinates"}
								tab={t("common.subordinatesReviewTable.title", {
									count: accessReviewReport.sortedSubordinates?.length
								})}>
								<SubordinatesReviewTable reportId={reportId!} onViewClick={onViewSubordinate} />
							</Tabs.Tab>
							<Tabs.Tab
								disabled={!accessReviewReport?.sortedResources?.length}
								key={`resources`}
								tab={t("common.resourcesReviewTable.title", { count: accessReviewReport.sortedResources?.length })}>
								<ResourcesReviewTable reportId={reportId!} onViewClick={onViewResource} />
							</Tabs.Tab>
						</Tabs>
					</div>
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
