import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Map } from "immutable";
import { Checkbox } from "components/ui/Checkbox";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { FlagIcon } from "components/ui/Icons/FlagIcon";
import { Table } from "components/ui/Table";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/legacy/Typography";
import {
	TSubordinatePermission,
	useSubordinatePermissionsReviewContext
} from "context/subordinatePermissionReviewContext";
import { Unknown } from "components/ui/Unknown";
import { AccessReviewPermissionCommentCell } from "components/common/AccessReviewPermissionCommentCell";
import { notEmpty } from "utils/comparison";
import { STATUS_DICT, TPermissionStatusOptions } from "utils/accessReview";
import { TicketNumberWithLink } from "components/common/TicketNumberWithLink";
import { ImmediateRevokeModal } from "components/common/ImmediateRevokeModal";
import { getTicketExpirationDate } from "utils/tickets/ticketExpirationDate";
import { useIsOpenState } from "hooks/useIsOpenState";
import orderBy from "lodash/orderBy";
import { SortTableProvider, useSortTableContext } from "context/sortingTableContext";
import { SortableTableHeader } from "components/common/SortableTableHeader";
import { ASC } from "hooks/usePagination";
import { useStyles } from "./styles";
import type { TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";
import type { ListIteratee } from "lodash";

const TRANSLATION_PREFIX = "common.subordinatePermissionsReviewTable";

interface IProps {
	changeCheckbox?: (permissionId: string, checked: boolean) => void;
	getIsSelected?: (permissionId: string) => boolean;
	permissions: TSubordinatePermission[] | undefined;
	integrationId: string;
	readonly?: boolean;
	immediateRevoke: boolean;
	removeCheckbox: (integrationId: string, permissionId: string) => void;
}

const SubordinatePermissionsTableContent: FC<IProps> = ({
	changeCheckbox,
	className,
	getIsSelected,
	id,
	innerRef,
	permissions,
	readonly,
	immediateRevoke,
	removeCheckbox,
	integrationId
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		state: { sortOrder, sortFields }
	} = useSortTableContext();

	const {
		actions: { setSinglePermission, getPermissionStatus, setComment }
	} = useSubordinatePermissionsReviewContext();
	const [rowsDisabled, setRowsDisabled] = useState(Map<string, boolean>);

	const headers = useMemo(
		() =>
			[
				[t(`${TRANSLATION_PREFIX}.table.resource`), "resource"],
				[t(`${TRANSLATION_PREFIX}.table.role`), "role"],
				[t(`${TRANSLATION_PREFIX}.table.account`), "account"],
				[t(`${TRANSLATION_PREFIX}.table.requests`)],
				[t(`${TRANSLATION_PREFIX}.table.expires`), "expires"],
				[t(`${TRANSLATION_PREFIX}.table.lastUse`), "lastUse"],
				[t(`${TRANSLATION_PREFIX}.table.comments`), "comments"]
			].concat(readonly ? [[t(`${TRANSLATION_PREFIX}.table.status`)]] : []),
		[readonly, t]
	);

	const permissionExpirations = useMemo(
		() =>
			(permissions || []).reduce((acc, permission) => {
				const ticketPermissions = permission.integrationActorPermission?.ticketPermissions;
				const expiresAt = getTicketExpirationDate(ticketPermissions || null);

				return acc.set(
					permission.subordinatePermissionId,
					expiresAt ? t("dateTime.date", { date: expiresAt }) : t("shared.ticketExpiration.never")
				);
			}, Map<string, string>()),
		[permissions, t]
	);

	const onCommentChange = useCallback(
		async (permissionId: string, comment: string) => {
			if (readonly) return;
			await setComment([permissionId], comment);
		},
		[readonly, setComment]
	);

	const onImmediatelyRevoke = useCallback(
		(permissionId: string) => {
			removeCheckbox(integrationId, permissionId);
			setRowsDisabled(current => current.set(permissionId, true));
		},
		[removeCheckbox, integrationId]
	);

	useEffect(() => {
		permissions?.forEach(permission => {
			const disabled = permission.status === "denied" && immediateRevoke;
			setRowsDisabled(current => current.set(permission.subordinatePermissionId, disabled));
		});
	}, [immediateRevoke, permissions, rowsDisabled]);

	const sortedPermissions = useMemo(() => {
		if (!sortOrder || !sortFields) {
			return permissions;
		}
		const sortingFuncs = Map<string, ListIteratee<TSubordinatePermission>>({
			resource: "resourceName",
			role: "roleName",
			account: "actorName",
			expires: permission => permissionExpirations.get(permission.subordinatePermissionId),
			lastUse: "integrationActorPermission.integrationActor.lastUsed",
			comments: "comments"
		});
		return (
			permissions &&
			(orderBy(
				permissions,
				[sortingFuncs.get(sortFields[0])],
				[sortOrder === ASC ? "asc" : "desc"]
			) as TSubordinatePermission[])
		);
	}, [sortFields, permissions, sortOrder, permissionExpirations]);

	return (
		<Table
			outline
			id={id}
			innerRef={innerRef}
			className={classNames(classes.table, className)}
			gridColumns="2fr 1fr 1.8fr 1fr 1fr 1fr 1.8fr 10rem">
			<Table.Row>
				{headers.map(([title, field]) => (
					<SortableTableHeader title={title} key={title} field={field} />
				))}
			</Table.Row>
			{sortedPermissions &&
				sortedPermissions.map(permission => {
					return (
						<Table.Row key={permission.subordinatePermissionId}>
							<Table.Cell>
								<NameCell
									permission={permission}
									changeCheckbox={changeCheckbox}
									getIsSelected={getIsSelected}
									readonly={readonly || rowsDisabled.get(permission.subordinatePermissionId) === true}
								/>
							</Table.Cell>
							<Table.Cell>
								<Typography>{permission.roleName}</Typography>
							</Table.Cell>
							<Table.Cell>
								<Typography>{permission.actorName}</Typography>
							</Table.Cell>
							<Table.Cell className={classes.ticketsCell}>
								{permission.integrationActorPermission?.ticketPermissions
									?.map(({ ticket }) => ticket)
									.filter(notEmpty)
									.map(({ id: ticketId, number }) => (
										<TicketNumberWithLink key={ticketId} ticketId={ticketId} ticketNumber={number} />
									))}
							</Table.Cell>
							<Table.Cell>
								<Typography>{permissionExpirations.get(permission.subordinatePermissionId)}</Typography>
							</Table.Cell>
							<Table.Cell>
								<Typography>
									<Unknown unknown={!permission?.integrationActorPermission?.integrationActor?.lastUsed}>
										{t("dateTime.date", { date: permission.integrationActorPermission!.integrationActor!.lastUsed })}
									</Unknown>
								</Typography>
							</Table.Cell>
							<Table.Cell>
								<AccessReviewPermissionCommentCell
									readonly={readonly}
									comments={permission.comments}
									onSubmit={comment => onCommentChange(permission.id, comment)}
								/>
							</Table.Cell>
							<Table.Cell center>
								{readonly ? (
									<Typography>
										{t(
											`common.accessReview.statuses.permissions.${getPermissionStatus(
												permission.subordinatePermissionId
											)}`
										)}
									</Typography>
								) : (
									<ActionCell
										permission={permission}
										changeStatus={setSinglePermission}
										getPermissionStatus={getPermissionStatus}
										immediateRevoke={immediateRevoke}
										removeCheckbox={onImmediatelyRevoke}
									/>
								)}
							</Table.Cell>
						</Table.Row>
					);
				})}
		</Table>
	);
};

export const SubordinatePermissionsTable: FC<IProps> = props => (
	<SortTableProvider defaultSortField="resource" defaultSortOrder={ASC}>
		<SubordinatePermissionsTableContent {...props} />
	</SortTableProvider>
);

type TUpdatableStatusOptions = Exclude<TPermissionStatusOptions, "pending">;
interface IActionsCellProps {
	changeStatus?: (permissionId: string, status: TUpdatableStatusOptions) => Promise<void>;
	getPermissionStatus?: (permissionId: string) => TAccessReviewPermissionStatus;
	permission: TSubordinatePermission;
	immediateRevoke: boolean;
	removeCheckbox: (permissionId: string) => void;
}

const ActionCell: FC<IActionsCellProps> = ({
	permission,
	getPermissionStatus,
	changeStatus,
	immediateRevoke,
	removeCheckbox
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { isOpen: areYouSureModalIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();

	const [activeLoadingAction, setActiveAction] = useState<TUpdatableStatusOptions | undefined>(undefined);

	const status = getPermissionStatus ? getPermissionStatus(permission.subordinatePermissionId) : "pending";
	const disableActions = immediateRevoke && status === "denied";

	const setNewStatus = useCallback(
		async (newStatus: TUpdatableStatusOptions) => {
			if (STATUS_DICT.get(newStatus) !== status) {
				setActiveAction(newStatus);
				try {
					changeStatus && (await changeStatus(permission.subordinatePermissionId, newStatus));
				} finally {
					setActiveAction(undefined);
				}
			}
		},
		[changeStatus, permission.subordinatePermissionId, status]
	);

	const onApprove = useCallback(() => !disableActions && setNewStatus("approve"), [setNewStatus, disableActions]);
	const onDeny = useCallback(() => !disableActions && setNewStatus("deny"), [setNewStatus, disableActions]);
	const onFlag = useCallback(() => !disableActions && setNewStatus("flag"), [setNewStatus, disableActions]);

	const revokePermission = useCallback(async () => {
		if (immediateRevoke) {
			await onDeny();
			removeCheckbox(permission.subordinatePermissionId);
		}
		closeAreYouSureModal();
	}, [closeAreYouSureModal, immediateRevoke, onDeny, permission, removeCheckbox]);

	return (
		<>
			{immediateRevoke && (
				<ImmediateRevokeModal
					onAction={revokePermission}
					onClose={closeAreYouSureModal}
					isOpen={areYouSureModalIsOpen}
				/>
			)}
			<div className={classNames(classes.tableCell, classes.actionsContainer)}>
				<Tooltip content={t(`${TRANSLATION_PREFIX}.approve`)}>
					<div
						className={classNames(classes.button, classes.approveButton, {
							[classes.selected]: status === "approved",
							[classes.disabled]: disableActions || !!activeLoadingAction
						})}
						onClick={!activeLoadingAction ? onApprove : undefined}>
						<GrantedIcon />
					</div>
				</Tooltip>
				<Tooltip content={t(`${TRANSLATION_PREFIX}.deny`)}>
					<div
						className={classNames(classes.button, classes.denyButton, {
							[classes.selected]: status === "denied",
							[classes.disabled]: !!activeLoadingAction,
							[classes.immediateRevokeDisabled]: disableActions
						})}
						onClick={!activeLoadingAction ? (immediateRevoke ? openAreYouSureModal : onDeny) : undefined}>
						<CloseIcon />
					</div>
				</Tooltip>
				<Tooltip content={t(`${TRANSLATION_PREFIX}.flag`)}>
					<div
						className={classNames(classes.button, classes.flagButton, {
							[classes.selected]: status === "flagged",
							[classes.disabled]: disableActions || !!activeLoadingAction
						})}
						onClick={!activeLoadingAction ? onFlag : undefined}>
						<FlagIcon />
					</div>
				</Tooltip>
			</div>
		</>
	);
};

interface INameCellProps {
	changeCheckbox?: (id: string, isSelected: boolean) => void;
	getIsSelected?: (id: string) => boolean;
	permission: TSubordinatePermission;
	readonly?: boolean;
}

const NameCell: FC<INameCellProps> = ({ permission, getIsSelected, changeCheckbox, readonly }) => {
	const classes = useStyles();
	const isSelected = useMemo(
		() => (getIsSelected ? getIsSelected(permission.subordinatePermissionId) : false),
		[getIsSelected, permission.subordinatePermissionId]
	);

	const onClick = useCallback(() => {
		changeCheckbox && changeCheckbox(permission.subordinatePermissionId, !isSelected);
	}, [changeCheckbox, isSelected, permission.subordinatePermissionId]);

	if (!permission) return null;
	const content = (
		<div className={classes.resourceName}>
			<Typography>{permission.resourceName}</Typography>
		</div>
	);

	return (
		<div className={classes.tableCell}>
			{readonly ? content : <Checkbox label={content} selected={isSelected} onClick={onClick} />}
		</div>
	);
};
