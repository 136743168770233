import { useLoadingState } from "hooks/useLoadingState";
import { useOnMount } from "hooks/useOnMount";
import { usePagination } from "hooks/usePagination";
import { Map } from "immutable";
import { useCallback, useState } from "react";
import type { IFilter } from "types/filters";
import type { TPaginationSortFilterOptions } from "types/pagination";
import type { Constructor } from "types/utilTypes";
import type { IPaginationResponse } from "utils/pagination";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

export const useFilterFormExpression = <TFilter extends IFilter = IFilter>({
	filterName,
	onChange,
	getFilter
}: {
	filterName: TFilter["name"];
	onChange: (filter: TFilter | undefined, isValid: boolean) => void;
	getFilter: (name: TFilter["name"]) => Constructor<TFilter>;
}) => {
	const clearFilter = useCallback(
		(sideEffect?: () => void) => {
			if (typeof sideEffect === "function") {
				sideEffect();
			}
			const FilterConstructor = getFilter(filterName);
			onChange(new FilterConstructor(), false);
		},
		[filterName, getFilter, onChange]
	);

	const removeFilter = useCallback(
		(sideEffect?: () => void) => {
			if (typeof sideEffect === "function") {
				sideEffect();
			}
			onChange(undefined, false);
		},
		[onChange]
	);

	return { clearFilter, removeFilter };
};

export const useInitialLoading = <T extends { id: string }>({
	canFetch,
	fetch,
	fetchOptions
}: {
	fetch: (options: TPaginationSortFilterOptions) => Promise<IPaginationResponse<T>>;
	fetchOptions: TPaginationSortFilterOptions;
	canFetch: boolean;
}) => {
	const [selectedModels, setSelectedModels] = useState(Map<string, T>());

	const { isLoading: isInitialLoading, withLoader: withInitialLoader } = useLoadingState();

	useOnMount(() => {
		if (canFetch) {
			withInitialLoader(fetch(fetchOptions)).then(response => {
				setSelectedModels(prev => prev.merge(response.result.map(model => [model.id, model])));
			});
		}
	});

	return { selectedModels, isInitialLoading, setSelectedModels };
};

const PER_PAGE = 50;

export const useFetchModels = <T extends { id: string }>({
	fetch,
	getFilters
}: {
	fetch: (options: TPaginationSortFilterOptions) => Promise<IPaginationResponse<T>>;
	getFilters: (options: { name?: string[] }) => IFilter[];
}) => {
	const [searchQuery, setSearchQuery] = useState<string>();

	const fetchItems = useCallback(
		async (paginationOptions: IPaginatedSearchOptions) => {
			return fetch({
				page: paginationOptions.pagination?.page,
				perPage: paginationOptions.pagination?.perPage,
				order: paginationOptions.sort?.order,
				sortFields: paginationOptions.sort?.sortFields,
				filters: getFilters({ name: searchQuery ? [searchQuery] : undefined })
			});
		},
		[fetch, getFilters, searchQuery]
	);

	const { items, isLoading } = usePagination({
		fetchItems: fetchItems,
		perPage: PER_PAGE,
		sortFields: "name",
		sortOrder: "ASC"
	});

	return { items, isLoading, setSearchQuery };
};
