import { createUseStyles } from "react-jss";

const LARGE_CARD_FIXED_WIDTH = 260;
export const SMALL_CARD_FIXED_WIDTH = 240;

export const useStyles = createUseStyles({
	container: {
		background: "var(--color-white)",
		border: "3px solid var(--color-grey-400)",
		borderRadius: "8px 8px 20px 20px",
		display: "flex",
		flexDirection: "column",
		minWidth: "200px",
		"&$disabled": {
			color: "var(--color-grey-500)"
		},
		"&:hover": {
			background: "var(--color-blue-100)",
			"&$clickable": {
				cursor: "pointer"
			}
		},
		"&$selected": {
			borderColor: "var(--color-blue-500)",
			borderWidth: "4px",
			"& > $header": {
				borderColor: "var(--color-blue-500)",
				borderWidth: "4px"
			}
		},
		"& > $header": {
			borderBottom: "3px solid var(--color-grey-400)"
		},
		"&$large": {
			"&$fixedWidth": {
				width: `${LARGE_CARD_FIXED_WIDTH}px`
			},
			"& > $header": {
				minHeight: "146px"
			},
			"& > $content": {
				gap: "var(--spacing-x2)",
				minHeight: "72px"
			}
		},
		"&$small": {
			"&$fixedWidth": {
				width: `${SMALL_CARD_FIXED_WIDTH}px`
			},
			"& > $header": {
				minHeight: "72px"
			},
			"& > $content": {
				gap: "var(--spacing-x05)",
				minHeight: "56px"
			}
		},
		"&$expanded": {
			background: "var(--color-blue-200)",
			border: "none",
			borderRadius: "8px 8px 0px 0px",
			"&:hover": {
				background: "var(--color-blue-300)",
				"&$clickable": {
					cursor: "pointer"
				}
			},
			"& > $header": {
				borderBottom: "4px solid var(--color-white)"
			}
		}
	},
	header: {
		display: "flex",
		flexDirection: "column",
		padding: "12px"
	},
	topActions: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-end"
	},
	headerContent: {
		alignItems: "center",
		display: "flex",
		flexGrow: 1,
		gap: "12px",
		justifyContent: "center"
	},
	content: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		justifyContent: "center",
		padding: "12px 28px 16px 28px"
	},
	clickable: {},
	disabled: {},
	expanded: {},
	fixedWidth: {},
	large: {},
	selected: {},
	small: {}
});
