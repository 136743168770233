import { useEffect, useMemo } from "react";
import { List } from "immutable";
import { notEmpty } from "utils/comparison";
import { getPaginatedUsers } from "api/users";
import { useUsersContext } from "context/usersContext";
import { useDebouncedPaginatedSearch } from "./useDebouncedPaginationSearch";
import { useMultiUsers } from "./useMultiUsers";
import type { TUsePaginationOptions } from "./usePagination";
import type { UserModel } from "models/UserModel";

type TUsersDebouncedPaginationOptions = Omit<TUsePaginationOptions<List<UserModel>>, "fetchItems"> & {
	wait?: number;
	includeDeleted?: boolean;
	initialFetch?: boolean;
	selectedIds?: string[];
};

/*
 * This hook performs three main tasks:
 * 1. Fetches the selected IDs and adds them to the users context.
 * 2. Fetches a paginated list of Users and adds them to the users context.
 * 3. Manages the debounced pagination of the Users.
 */
export const useUsersSelect = (query: string, options: TUsersDebouncedPaginationOptions = {}) => {
	const selectedIds = useMemo(() => options.selectedIds || [], [options.selectedIds]);
	const selectedUsersMap = useMultiUsers(selectedIds);
	const selectedUsers = useMemo(() => selectedUsersMap?.toList() || List<UserModel>(), [selectedUsersMap]);
	const includeDeleted = options.includeDeleted ?? true;
	const initialFetch = options.initialFetch ?? true;
	const {
		actions: { addUsers },
		state: { users }
	} = useUsersContext();

	const pagination = useDebouncedPaginatedSearch(query, {
		fetchItems: getPaginatedUsers,
		payload: { includeDeleted },
		...options
	});

	const { getPageDebounced } = pagination;
	const items = pagination.items || List<UserModel>();

	const allItems = useMemo(() => {
		const allUsers = users.toList().filter(notEmpty);
		if (!includeDeleted) return allUsers.filter(user => !user.isDeleted);
		return allUsers;
	}, [includeDeleted, users]);

	useEffect(() => {
		addUsers(items.toArray());
	}, [addUsers, items]);

	useEffect(() => {
		if (!initialFetch) return;
		getPageDebounced();
	}, [getPageDebounced, initialFetch, query]);

	return {
		...pagination,
		items: query ? items : allItems,
		selectedItems: selectedUsers
	};
};
