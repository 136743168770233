import { createUseStyles, type Styles } from "react-jss";

export const iconButtonVariantStyles: Styles<
	"iconButton" | "primary" | "secondary" | "reverse" | "disabled" | "loading"
> = {
	iconButton: {
		alignItems: "center",
		border: "none",
		display: "flex",
		flexShrink: 0,
		justifyContent: "center",
		padding: "var(--spacing-x1, 4px)",
		transitionDuration: "0.3s",
		transitionProperty: "background-color, border-color, color",
		transitionTimingFunction: "ease-out",
		"&$disabled": {
			transition: "none",
			pointerEvents: "none"
		},
		"&$loading": {
			pointerEvents: "none"
		},
		"&:hover": {
			cursor: "pointer"
		}
	},
	primary: {
		backgroundColor: "var(--icon-button-primary-background-color-regular)",
		color: "var(--icon-button-primary-color)",
		"&:hover": {
			backgroundColor: "var(--icon-button-primary-background-color-hover)"
		},
		"&:active": {
			backgroundColor: "var(--icon-button-primary-background-color-pressed)"
		},
		"&$disabled": {
			color: "var(--icon-button-primary-color-disabled)"
		}
	},
	secondary: {
		backgroundColor: "var(--icon-button-secondary-background-color-regular)",
		border: "1px solid var(--icon-button-secondary-border-color)",
		color: "var(--icon-button-secondary-color)",
		"&:hover": {
			backgroundColor: "var(--icon-button-secondary-background-color-hover)"
		},
		"&:active": {
			backgroundColor: "var(--icon-button-secondary-background-color-pressed)"
		},
		"&$disabled": {
			color: "var(--icon-button-secondary-color-disabled)"
		}
	},
	reverse: {
		backgroundColor: "var(--icon-button-reverse-background-color-regular)",
		color: "var(--icon-button-reverse-color)",
		"&:hover": {
			backgroundColor: "var(--icon-button-reverse-background-color-hover)"
		},
		"&:active": {
			backgroundColor: "var(--icon-button-reverse-background-color-pressed)"
		},
		"&$disabled": {
			color: "var(--icon-button-reverse-color-disabled)"
		}
	},
	disabled: {},
	loading: {}
};

export const useStyles = createUseStyles({
	...iconButtonVariantStyles,
	huge: {
		borderRadius: "6px",
		fontSize: "32px"
	},
	large: {
		borderRadius: "4px",
		fontSize: "24px",
		padding: "var(--spacing-x05, 2px)"
	},
	medium: {
		borderRadius: "4px",
		fontSize: "20px",
		padding: "var(--spacing-x05, 2px)"
	},
	small: {
		borderRadius: "4px",
		fontSize: "16px",
		padding: "var(--spacing-x05, 2px)"
	},
	xs: {
		borderRadius: "3px",
		fontSize: "12px",
		padding: "var(--spacing-x05, 2px)"
	},
	tiny: {
		borderRadius: "2px",
		fontSize: "12px",
		padding: "0"
	}
});
