import React, { useState, useCallback, useEffect, RefObject, useRef, useMemo } from "react";
import classNames from "classnames";
import { useControlled } from "hooks/useControlled";
import { isEmptyReactNode } from "utils/ui/components";
import { Description, Errors, FieldTitle } from "../fieldHelpers/fieldHelpers";
import { SearchIcon } from "../Icons/SearchIcon";
import { useStyles } from "./styles";
import type { IInputState, TInputSize } from "../fieldHelpers/types";

export interface IBaseInputProps {
	autoFocus?: boolean;
	defaultValue?: string;
	dirty?: boolean;
	disabled?: boolean;
	error?: boolean;
	errors?: string[];
	focused?: boolean;
	description?: string;
	inputContainerClassName?: string;
	inputRef?: React.Ref<HTMLInputElement>;
	isRequired?: boolean;
	label?: React.ReactNode;
	labelIcon?: React.ReactNode | IconComponent;
	labelInfo?: React.ReactNode;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onError?: (errors: string[] | null) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onMouseDown?: (event: React.MouseEvent<HTMLInputElement>) => void;
	onStateChange?: (state: IInputState) => void;
	onValueChange?: (value: string) => void;
	placeholder?: string;
	prefix?: React.ReactNode;
	readonly?: boolean;
	renderInput?: (
		props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
	) => React.ReactNode;
	showInput?: boolean;
	size?: TInputSize;
	suffix?: React.ReactNode;
	touched?: boolean;
	type?: "text" | "password" | "email" | "number" | "tel" | "url";
	validators?: ((value: string) => string | null)[];
	value?: string | null;
	variant?: "search" | "table" | "text";
}

export const Input: FC<IBaseInputProps> = ({
	autoFocus = false,
	className,
	defaultValue = "",
	dirty = false,
	disabled,
	errors: userErrors = null,
	focused = false,
	description,
	id,
	innerRef,
	inputContainerClassName,
	inputRef: propInputRef,
	isRequired,
	label,
	labelIcon,
	labelInfo,
	onBlur: userOnBlur,
	onChange: userOnChange,
	onError: userOnError,
	onFocus: userOnFocus,
	onKeyDown: userOnKeyDown,
	onKeyUp: userOnKeyUp,
	onMouseDown: userOnMouseDown,
	onStateChange: userOnStateChange,
	onValueChange: userOnValueChange,
	placeholder,
	prefix: propPrefix = null,
	readonly = false,
	renderInput = null,
	showInput = true,
	size = "large",
	suffix: propSuffix = null,
	touched = false,
	type = "text",
	validators = null,
	value: userValue,
	variant = "text"
}) => {
	const classes = useStyles();
	const [inputValue, setInputValue] = useControlled<string>({
		controlled: userValue,
		default: defaultValue
	});
	const [isDirty, setIsDirty] = useState(dirty);
	const [isTouched, setIsTouched] = useState(touched);
	const [isFocused, setIsFocused] = useState(focused);
	const [isError, setIsError] = useState(userErrors ? userErrors.length > 0 && userErrors.every(Boolean) : undefined);
	const [errorMessages, setErrorMessages] = useState(userErrors);
	const fallbackRef = useRef(null);
	const inputRef = useMemo(() => propInputRef || fallbackRef, [propInputRef]);

	useEffect(() => {
		setIsError(userErrors ? userErrors.length > 0 && userErrors.every(Boolean) : undefined);
		setErrorMessages(userErrors);
	}, [userErrors]);

	const handleStateChange = useCallback(() => {
		if (userOnStateChange) {
			userOnStateChange({
				dirty: isDirty,
				focused: isFocused,
				touched: isTouched
			});
		}
	}, [isDirty, isTouched, isFocused, userOnStateChange]);

	useEffect(() => handleStateChange, [handleStateChange]);

	const validate = useCallback(
		(value: string) => {
			const errors =
				validators?.map(validator => validator(value)).filter((err): err is string => typeof err === "string") || [];
			return errors?.length ? errors : undefined;
		},
		[validators]
	);

	const onFocus = useCallback(
		(event?: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(true);
			setIsTouched(true);
			if (userOnFocus && event) userOnFocus(event);
		},
		[userOnFocus]
	);

	const onInputContainerFocus = useCallback(() => {
		const element = (inputRef as RefObject<HTMLInputElement>)?.current;
		if (element) {
			element.focus();
		}
	}, [inputRef]);

	const onBlur = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(false);
			if (userOnBlur) userOnBlur(event);
		},
		[userOnBlur]
	);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newInputValue = event.target.value;
			const errors = validate(newInputValue);
			setIsDirty(true);
			if (errors) {
				setIsError(true);
				setErrorMessages(errors);
				userOnError && userOnError(errors);
			} else {
				setIsError(false);
				setErrorMessages(null);
				userOnError && userOnError(null);
			}

			setInputValue(newInputValue);
			if (userOnValueChange) {
				userOnValueChange(newInputValue);
			}
			if (userOnChange) {
				userOnChange(event);
			}
		},
		[validate, setInputValue, userOnValueChange, userOnChange, userOnError]
	);

	useEffect(() => {
		setIsFocused(focused);
		setIsTouched(touched);
		setIsDirty(dirty);
	}, [focused, dirty, touched]);

	const title = useMemo(() => {
		if (!label) return null;
		if (typeof label === "string") {
			return <FieldTitle title={label} icon={labelIcon} info={labelInfo} required={isRequired} size={size} />;
		}
		return label;
	}, [label, labelIcon, labelInfo, isRequired, size]);

	const prefix = useMemo(
		() =>
			!isEmptyReactNode(propPrefix) || variant === "search" ? (
				<div className={classes.prefix}>{variant === "search" ? <SearchIcon size={16} /> : propPrefix}</div>
			) : null,
		[classes.prefix, propPrefix, variant]
	);
	const suffix = useMemo(
		() => (!isEmptyReactNode(propSuffix) ? <div className={classes.suffix}>{propSuffix}</div> : null),
		[classes.suffix, propSuffix]
	);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef as React.Ref<HTMLDivElement>}>
			{title || description ? (
				<div className={classNames(classes.top, { [classes.disabled]: disabled })}>
					{title}
					<Description description={description} size={size} />
				</div>
			) : null}

			<div
				className={classNames(classes.inputContainer, inputContainerClassName, {
					[classes.disabled]: disabled,
					[classes.error]: isError,
					[classes.focused]: isFocused && !disabled,
					[classes.large]: size === "large",
					[classes.medium]: size === "medium",
					[classes.text]: variant === "text",
					[classes.search]: variant === "search",
					[classes.table]: variant === "table",
					[classes.noPrefix]: !prefix,
					[classes.noSuffix]: !suffix
				})}
				onFocus={onInputContainerFocus}
				onClick={onInputContainerFocus}>
				{prefix}
				{renderInput ? (
					renderInput({
						id,
						autoFocus,
						className: classes.inputHTMLComponent,
						disabled,
						onFocus,
						onBlur,
						onChange,
						onKeyDown: userOnKeyDown,
						onKeyUp: userOnKeyUp,
						onMouseDown: userOnMouseDown,
						ref: inputRef,
						required: isRequired,
						spellCheck: false,
						type: type === "password" ? "password" : "text",
						value: inputValue || ""
					})
				) : (
					<input
						id={id}
						autoFocus={autoFocus}
						className={classNames(classes.inputHTMLComponent, {
							[classes.hideInput]: !showInput,
							[classes.disabled]: disabled
						})}
						disabled={disabled}
						onBlur={onBlur}
						onChange={onChange}
						onFocus={onFocus}
						onKeyDown={userOnKeyDown}
						onKeyUp={userOnKeyUp}
						onMouseDown={userOnMouseDown}
						placeholder={placeholder}
						readOnly={readonly}
						ref={inputRef}
						required={isRequired}
						spellCheck={false}
						type={type === "password" ? "password" : "text"}
						value={inputValue || ""}
					/>
				)}
				{suffix}
			</div>
			<Errors errorMessages={errorMessages} />
		</div>
	);
};
