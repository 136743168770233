import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import uniqBy from "lodash/uniqBy";
import { List, Map } from "immutable";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TicketPermissionModel } from "models/TicketPermissionModel";

export interface IEntitlement {
	actor: IntegrationActorModel;
	role: IntegrationResourceRoleModel;
	source: IntegrationResourceRoleModel | null;
	ticketPermissions: List<TicketPermissionModel>;
}

export interface IEntitlementsByUser {
	userId: string | null;
	entitlements: List<IEntitlement>;
}

type TEntitlementWithUser = IEntitlement & { userId: string | null };

const getEntitlementsWithUsers = (integrationResource: IntegrationResourceModel) => {
	const integrationActorPermissions = integrationResource.entitlements;
	if (!integrationActorPermissions) return undefined;

	const rolesMap = integrationResource.integrationResourceRoles?.reduce(
		(acc, role) => acc.set(role.id, role),
		Map<string, IntegrationResourceRoleModel>()
	);
	const resourceRoleIds = rolesMap?.keySeq().toList() ?? List<string>();

	const directActors: List<TEntitlementWithUser> = integrationActorPermissions
		.filter(({ integrationResourceRole }) => Boolean(integrationResourceRole))
		.flatMap(({ integrationActor, ticketPermissions, integrationResourceRole, givenRoleId }) =>
			extractEntitlementsWithUsers({
				isDirectSource: resourceRoleIds.includes(integrationResourceRole!.id) ?? false,
				role: integrationResourceRole!,
				integrationActor,
				ticketPermissions,
				givenRole: rolesMap?.get(givenRoleId || "") || null
			})
		);
	return uniqByIds(directActors);
};

const extractEntitlementsWithUsers = ({
	isDirectSource,
	role,
	integrationActor,
	ticketPermissions,
	givenRole
}: {
	isDirectSource: boolean;
	role: IntegrationResourceRoleModel;
	integrationActor: IntegrationActorModel | null;
	ticketPermissions: List<TicketPermissionModel> | null;
	givenRole: IntegrationResourceRoleModel | null;
}) => {
	if (!integrationActor) {
		return [];
	}
	if (integrationActor.userIds?.size === 0) {
		const userlessEntitlement: TEntitlementWithUser = {
			userId: null,
			actor: integrationActor,
			role: givenRole ?? role,
			source: isDirectSource ? null : role,
			ticketPermissions: ticketPermissions || List<TicketPermissionModel>()
		};
		return [userlessEntitlement];
	}
	return (
		integrationActor.userIds?.flatMap(userId => [
			{
				userId: userId,
				actor: integrationActor,
				role: role,
				source: isDirectSource ? null : role,
				ticketPermissions: ticketPermissions || List<TicketPermissionModel>()
			}
		]) || []
	);
};

const uniqByIds = (entities: List<TEntitlementWithUser>) =>
	List(uniqBy(entities.toArray(), e => (e.userId ?? e.actor.id) + e.role.id));

const entitlementsByUser = (entitlements: List<TEntitlementWithUser>): List<IEntitlementsByUser> => {
	const byUser = entitlements.groupBy(entitlement => entitlement.userId);
	return byUser.toList().map(groupedEntitlement => ({
		userId: groupedEntitlement.first()!.userId,
		entitlements: groupedEntitlement.toList().map(entitlement => ({
			role: entitlement.role,
			actor: entitlement.actor,
			source: entitlement.source,
			ticketPermissions: entitlement.ticketPermissions
		}))
	}));
};

export const getEntitlementsByUsers = (
	integrationResource: IntegrationResourceModel
): List<IEntitlementsByUser> | undefined => {
	const entitlements = getEntitlementsWithUsers(integrationResource);
	if (!entitlements) return undefined;
	return entitlementsByUser(entitlements);
};
