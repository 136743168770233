import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import sortBy from "lodash/sortBy";
import { Select, type ISelectProps } from "components/ui/Select";
import { useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { IconPrefix } from "components/ui/IconPrefix";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { useStyles } from "./styles";
import type { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";

const getLabel = (option: ApprovalAlgorithmModel | null) => option?.name || "";
const equality = (option?: ApprovalAlgorithmModel | null, value?: ApprovalAlgorithmModel | null) =>
	option?.id === value?.id;
const renderOption = (option: ApprovalAlgorithmModel | null) =>
	option ? <IconPrefix size="tiny" Icon={WorkflowsIcon} content={option.name} /> : "";

const sortOptions = (options: (ApprovalAlgorithmModel | null)[]) => sortBy(options, getLabel);

const TRANSLATION_PREFIX = "common.approvalAlgorithmSelect" as const;

type TApprovalAlgorithmSelectProps = Omit<ISelectProps<ApprovalAlgorithmModel | null>, "options"> & {
	options?: (ApprovalAlgorithmModel | null)[];
};
export const ApprovalAlgorithmSelect: FC<TApprovalAlgorithmSelectProps> = ({
	className,
	innerRef,
	onChange,
	options: propOptions,
	placeholder: propsPlaceholder,
	renderLabel: propRenderLabel,
	...selectProps
}) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: TRANSLATION_PREFIX });
	const approvalAlgorithms = useApprovalAlgorithmsList(false);

	const options = useMemo(() => {
		if (propOptions) return propOptions;
		return approvalAlgorithms ? approvalAlgorithms.toArray() : [];
	}, [propOptions, approvalAlgorithms]);

	const handleValueChange = useCallback(
		(approvalAlgorithm: ApprovalAlgorithmModel | null) => {
			onChange?.(approvalAlgorithm);
		},
		[onChange]
	);

	const placeholder = propsPlaceholder ?? t("placeholder");

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Select
				getOptionLabel={getLabel}
				isOptionEqualToValue={equality}
				options={options}
				placeholder={placeholder}
				renderLabel={propRenderLabel ?? renderOption}
				renderOption={renderOption}
				sort={sortOptions}
				onChange={handleValueChange}
				{...selectProps}
			/>
		</div>
	);
};
