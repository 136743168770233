import { Route, TShouldRenderFunction } from "routes/route";
import { AccessReportPage } from "components/pages/AccessReportPage";
import { AccessReviewDashboardPage } from "components/pages/AccessReviewDashboardPage";
import { AccessReviewTemplatePage } from "components/pages/AccessReviewTemplatePage";
import { AccessReviewTemplatesPage } from "components/pages/AccessReviewTemplatesPage";
import { AdminAccessReviewPage } from "components/pages/AdminAccessReviewPage";
import { AdminResourceAccessReviewPage } from "components/pages/AdminResourceAccessReview";
import { AdminSubordinateAccessReviewPage } from "components/pages/AdminSubordinateAccessReview";
import { AuditLogsPage } from "components/pages/AuditLogsPage";
import { BundlesPage } from "components/pages/BundlesPage";
import { CreateIntegrationPage } from "components/pages/CreateIntegrationPage";
import { IntegrationPage } from "components/pages/IntegrationPage";
import { IntegrationResourcePage } from "components/pages/IntegrationResourcePage";
import { IntegrationsPage } from "components/pages/IntegrationsPage";
import { MyRequestsPage } from "components/pages/MyRequestsPage";
import { NewTicketPage } from "components/pages/NewTicketPage";
import { PendingRequestsPage } from "components/pages/PendingRequestsPage";
import { PoliciesPage } from "components/pages/PoliciesPage";
import { ProfilePage } from "components/pages/ProfilePage";
import { ResourceAccessReportPage } from "components/pages/ResourceAccessReportPage";
import { SettingsPage } from "components/pages/SettingsPage";
import { SubordinateAccessReportPage } from "components/pages/SubordinateAccessReportPage";
import { TasksPage } from "components/pages/TasksPage";
import { WorkflowsPage } from "components/pages/WorkflowsPage";
import { UserPage } from "components/pages/UserPage";
import { UsersPage } from "components/pages/UsersPage";
import { IntegrationResourceRolePage } from "components/pages/IntegrationResourceRolePage";
import { IdentityGraphPage } from "components/pages/IdentityGraphPage";
import { Homepage } from "components/pages/Homepage";
import { RequestsPage } from "components/pages/RequestsPage";
import { PastRequestsPage } from "components/pages/PastRequestsPage";
import { MyPermissionsPage } from "components/pages/MyPermissionsPage";
import { NewRequestPage } from "components/pages/NewRequestPage";
import { WorkflowsWebhooksPage } from "components/pages/WorkflowsWebhooksPage";
import { CreateWorkflowPage, EditWorkflowPage } from "components/pages/WorkflowsPage/components/EditWorkflow";
import { BetaTriggerPage } from "components/pages/BetaTriggerPage";
import { CreateRulePage } from "components/pages/CreateRulePage";
import { RulesPage } from "components/pages/RulesPage";
import { EditRulePage } from "components/pages/CreateRulePage/EditRulePage";
import { BulkActionsPage } from "components/pages/BulkActionsPage";
import { RequestsLogPage } from "components/pages/RequestsLogPage";

export type TRoutes = { [key: string]: Route };

const renderIfAdmin: TShouldRenderFunction = ({ user: { isAdmin } }) => isAdmin;

const shouldRenderPolicies: TShouldRenderFunction = ({ user }) => Boolean(renderIfAdmin({ user }));

const shouldRenderAccessReview: TShouldRenderFunction = ({ user, company }) =>
	Boolean(renderIfAdmin({ user }) && company?.enableAccessReviews);

const renderIfHasAccessReports: TShouldRenderFunction = ({ user: { hasAccessReviewReports }, company }) =>
	Boolean(hasAccessReviewReports && company?.enableAccessReviews);

const shouldRenderIdentityGraph: TShouldRenderFunction = ({ user }) => Boolean(renderIfAdmin({ user }));

const shouldRenderIfBeta: TShouldRenderFunction = ({ isBeta }) => !!isBeta;
const shouldRenderIfNotBeta: TShouldRenderFunction = ({ isBeta, company }) => !isBeta || (company?.forceBeta ?? false);

const shouldRenderBulkActions: TShouldRenderFunction = ({ user, company }) =>
	Boolean(renderIfAdmin({ user }) && company?.enableBulkActions);

export const routes = {
	home: new Route({
		matches: ["/"],
		inAnimation: {
			name: "fade-move-from-center"
		},
		outAnimation: {
			name: "fade-move-from-center",
			reverse: true
		},
		Page: Homepage,
		pageId: "Home"
	}),
	requests: new Route({
		matches: ["/requests"],
		Page: RequestsPage,
		shouldRender: shouldRenderIfBeta,
		pageId: "Requests",
		subRoutes: {
			myPermissions: new Route({
				matches: ["/myPermissions"],
				Page: MyPermissionsPage,
				shouldRender: shouldRenderIfBeta,
				pageId: "MyPermissions"
			}),
			past: new Route({
				matches: ["/past"],
				Page: PastRequestsPage,
				shouldRender: shouldRenderIfBeta,
				pageId: "PastRequests"
			})
		}
	}),
	newRequest: new Route({
		matches: ["/newRequest"],
		Page: NewRequestPage,
		shouldRender: shouldRenderIfBeta,
		pageId: "NewRequest",
		inAnimation: {
			name: "fade-move-from-center"
		},
		outAnimation: {
			name: "fade-move-from-center",
			reverse: true
		},
		hideSideNav: true
	}),
	newTicket: new Route({
		matches: ["/request"],
		inAnimation: {
			name: "fade-move-from-center"
		},
		outAnimation: {
			name: "fade-move-from-center",
			reverse: true
		},
		Page: NewTicketPage,
		pageId: "NewTicket"
	}),
	pendingRequests: new Route({
		matches: ["/pendingRequests"],
		animationOrder: 1,
		Page: PendingRequestsPage,
		pageId: "PendingRequests",
		shouldRender: shouldRenderIfNotBeta
	}),
	myRequests: new Route({
		matches: ["/myRequests"],
		animationOrder: 2,
		Page: MyRequestsPage,
		pageId: "MyRequests",
		shouldRender: shouldRenderIfNotBeta
	}),
	identityGraph: new Route({
		matches: ["/graph"],
		animationOrder: 3,
		Page: IdentityGraphPage,
		pageId: "IdentityGraph",
		shouldRender: shouldRenderIdentityGraph
	}),
	workflows: new Route({
		matches: ["/workflows"],
		animationOrder: 4,
		Page: WorkflowsPage,
		pageId: "Workflows",
		shouldRender: renderIfAdmin,
		subRoutes: {
			webhooks: new Route({
				matches: ["/webhooks"],
				Page: WorkflowsWebhooksPage,
				pageId: "WorkflowsWebhooks",
				shouldRender: renderIfAdmin
			}),
			editWorkflow: new Route({
				matches: ["/edit/:workflowId"],
				Page: EditWorkflowPage,
				pageId: "EditWorkflow",
				shouldRender: renderIfAdmin
			}),
			createWorkflow: new Route({
				matches: ["/create"],
				Page: CreateWorkflowPage,
				pageId: "CreateWorkflow",
				shouldRender: renderIfAdmin
			})
		}
	}),
	rules: new Route({
		matches: ["/rules"],
		animationOrder: 50,
		Page: RulesPage,
		pageId: "Rules",
		shouldRender: shouldRenderBulkActions,
		subRoutes: {
			createRule: new Route({
				hideSideNav: true,
				matches: ["/create"],
				animationOrder: 1,
				Page: CreateRulePage,
				pageId: "RulesCreate",
				shouldRender: shouldRenderBulkActions
			}),
			editRule: new Route({
				hideSideNav: true,
				matches: ["/edit/:ruleId"],
				animationOrder: 1,
				Page: EditRulePage,
				pageId: "RulesEdit",
				shouldRender: shouldRenderBulkActions
			})
		}
	}),
	integrations: new Route({
		matches: ["/integrations"],
		animationOrder: 5,
		Page: IntegrationsPage,
		pageId: "Integrations",
		shouldRender: renderIfAdmin,
		subRoutes: {
			createIntegration: new Route({
				animationOrder: 1,
				matches: ["/create"],
				Page: CreateIntegrationPage,
				pageId: "CreateIntegration",
				shouldRender: renderIfAdmin
			}),
			integration: new Route({
				animationOrder: 3,
				matches: ["/:integrationId"],
				Page: IntegrationPage,
				pageId: "Integration",
				shouldRender: renderIfAdmin,
				subRoutes: {
					integrationResource: new Route({
						matches: ["/resources/:integrationResourceId"],
						animationOrder: 2,
						Page: IntegrationResourcePage,
						pageId: "IntegrationResource",
						shouldRender: renderIfAdmin,
						subRoutes: {
							integrationResourceRole: new Route({
								matches: ["/roles/:integrationResourceRoleId"],
								animationOrder: 1,
								Page: IntegrationResourceRolePage,
								pageId: "IntegrationResourceRole",
								shouldRender: renderIfAdmin
							})
						}
					})
				}
			})
		}
	}),
	bulkActions: new Route({
		matches: ["/bulkActions"],
		Page: BulkActionsPage,
		shouldRender: shouldRenderBulkActions,
		pageId: "BulkActions"
	}),
	bundles: new Route({
		matches: ["/bundles"],
		animationOrder: 6,
		Page: BundlesPage,
		pageId: "Bundles",
		shouldRender: renderIfAdmin
	}),
	policies: new Route({
		matches: ["/policies"],
		animationOrder: 7,
		Page: PoliciesPage,
		pageId: "Policies",
		shouldRender: shouldRenderPolicies
	}),
	tasks: new Route({
		matches: ["/tasks"],
		animationOrder: 8,
		Page: TasksPage,
		pageId: "Tasks"
	}),
	accessReview: new Route({
		matches: ["/accessReview"],
		animationOrder: 9,
		Page: AccessReviewDashboardPage,
		pageId: "AccessReview",
		shouldRender: shouldRenderAccessReview,
		subRoutes: {
			report: new Route({
				matches: ["/reports/:reportId"],
				animationOrder: 1,
				Page: AdminAccessReviewPage,
				pageId: "AccessReviewReport",
				shouldRender: shouldRenderAccessReview
			}),
			adminResourceReview: new Route({
				matches: ["/resources/:resourceId"],
				animationOrder: 2,
				Page: AdminResourceAccessReviewPage,
				pageId: "AdminResourceAccessReview",
				shouldRender: shouldRenderAccessReview
			}),
			adminSubordinateReview: new Route({
				matches: ["/subordinates/:subordinateId"],
				animationOrder: 3,
				Page: AdminSubordinateAccessReviewPage,
				pageId: "AdminSubordinateAccessReview",
				shouldRender: shouldRenderAccessReview
			}),
			template: new Route({
				matches: ["/templates"],
				animationOrder: 4,
				Page: AccessReviewTemplatesPage,
				pageId: "AccessReviewTemplatesPage",
				shouldRender: shouldRenderAccessReview,
				subRoutes: {
					create: new Route({
						matches: ["/create"],
						animationOrder: 1,
						Page: AccessReviewTemplatePage,
						pageId: "AccessReviewTemplatePage",
						shouldRender: shouldRenderAccessReview
					}),
					edit: new Route({
						matches: ["/edit/:templateId"],
						animationOrder: 2,
						Page: AccessReviewTemplatePage,
						pageId: "AccessReviewTemplatePage",
						shouldRender: shouldRenderAccessReview
					})
				}
			})
		}
	}),
	accessReport: new Route({
		matches: ["/accessReport", "/accessReport/:accessReviewId"],
		animationOrder: 10,
		Page: AccessReportPage,
		pageId: "AccessReport",
		shouldRender: renderIfHasAccessReports,
		subRoutes: {
			subordinateReview: new Route({
				matches: ["/subordinates/:subordinateId"],
				animationOrder: 1,
				Page: SubordinateAccessReportPage,
				pageId: "SubordinateAccessReport",
				shouldRender: renderIfHasAccessReports
			}),
			resourceReview: new Route({
				matches: ["/resources/:resourceId"],
				animationOrder: 2,
				Page: ResourceAccessReportPage,
				pageId: "ResourceAccessReport",
				shouldRender: renderIfHasAccessReports
			})
		}
	}),
	requestsLog: new Route({
		matches: ["/requestHistory"],
		animationOrder: 11,
		Page: RequestsLogPage,
		pageId: "RequestsLog",
		shouldRender: renderIfAdmin
	}),
	auditLogs: new Route({
		matches: ["/auditLogs"],
		animationOrder: 12,
		Page: AuditLogsPage,
		pageId: "AuditLogs",
		shouldRender: renderIfAdmin
	}),
	settings: new Route({
		matches: ["/organizationSettings"],
		animationOrder: 13,
		shouldRender: renderIfAdmin,
		Page: SettingsPage,
		pageId: "Settings"
	}),
	users: new Route({
		matches: ["/users"],
		animationOrder: 14,
		Page: UsersPage,
		subRoutes: {
			user: new Route({
				matches: ["/:userId"],
				Page: UserPage,
				pageId: "User",
				shouldRender: renderIfAdmin
			})
		},
		shouldRender: renderIfAdmin,
		pageId: "Users"
	}),
	profile: new Route({
		matches: ["/profile"],
		animationOrder: 15,
		Page: ProfilePage,
		pageId: "Profile",
		inAnimation: {
			name: "fade-move-from-center"
		},
		outAnimation: {
			name: "fade-move-from-center",
			reverse: true
		}
	}),
	betaTrigger: new Route({
		matches: ["/beta", "/beta/*"],
		Page: BetaTriggerPage,
		pageId: "BetaTrigger"
	})
};
