import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { SettingsIcon } from "components/ui/Icons/SettingsIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { MyRequestsIcon } from "components/ui/Icons/MyRequestsIcon";
import { InProgressIcon } from "components/ui/Icons/InProgressIcon";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { TaskIcon } from "components/ui/Icons/TaskIcon";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useCompany } from "hooks/useCompany";
import { ReportIcon } from "components/ui/Icons/ReportIcon";
import { OverviewIcon } from "components/ui/Icons/OverviewIcon";
import { AuditLogsIcon } from "components/ui/Icons/AuditLogsIcon";
import { UsersIcon } from "components/ui/Icons/UsersIcon";
import { PoliciesIcon } from "components/ui/Icons/PoliciesIcon";
import { PermissionsIcon } from "components/ui/Icons/PermissionsIcon";
import { useIsBeta } from "context/betaContext";
import { useSideNavContext } from "context/sideNavContext";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { useBreakpoints } from "hooks/useBreakpoints";
import { Bp } from "utils/ui/breakpoints";
import { useStyles } from "./styles";
import { ToggleSection } from "./components/ToggleSection";
import { Section } from "./components/Section";
import { TabOption } from "./components/TabOption";
import { Spacer } from "./components/Spacer";
import { NewRequestSection } from "./components/NewRequestSection";

export const SideNav: FC = ({ className }) => {
	const { t } = useTranslation();
	const { user } = useAuthenticatedUser();
	const classes = useStyles();
	const company = useCompany();
	const { isBiggerThan } = useBreakpoints(Bp.FORTY);
	const [minimized, setMinimized] = useState(false);

	useEffect(() => setMinimized(!isBiggerThan), [isBiggerThan]);

	const toggle = useCallback(() => setMinimized(curr => !curr), []);

	const isBeta = useIsBeta();

	const {
		state: { hideSideNav }
	} = useSideNavContext();

	if (hideSideNav) return null;

	return (
		<div className={classNames(classes.navigationBar, { [classes.minimized]: minimized }, className)}>
			{isBiggerThan ? <ToggleSection toggle={toggle} /> : null}

			{!isBeta && <NewRequestSection />}

			<div className={classes.scrollContainer}>
				<Section minimized={minimized}>
					{!isBeta && (
						<TabOption
							routeKey="pendingRequests"
							Icon={PendingIcon}
							text={t("navigation.pendingRequests")}
							minimized={minimized}
						/>
					)}

					{!isBeta && (
						<TabOption
							routeKey="myRequests"
							Icon={MyRequestsIcon}
							text={t("navigation.myRequests")}
							minimized={minimized}
						/>
					)}

					{user?.isAdmin && (
						<>
							{isBeta && (
								<TabOption
									routeKey="requests"
									Icon={RequestsIcon}
									text={t("navigation.requests")}
									minimized={minimized}
								/>
							)}

							<TabOption
								routeKey="identityGraph"
								Icon={PermissionsIcon}
								text={t("navigation.identityGraph")}
								minimized={minimized}
							/>

							<TabOption
								routeKey="workflows"
								Icon={WorkflowsIcon}
								text={t("navigation.workflows")}
								minimized={minimized}
							/>

							<TabOption
								routeKey="integrations"
								Icon={IntegrationIcon}
								text={t("navigation.integrations")}
								minimized={minimized}
							/>

							<TabOption routeKey="bundles" Icon={BundleIcon} text={t("navigation.bundles")} minimized={minimized} />

							<TabOption
								routeKey="policies"
								Icon={PoliciesIcon}
								text={t("navigation.policies")}
								minimized={minimized}
							/>
						</>
					)}

					<TabOption routeKey="tasks" Icon={TaskIcon} text={t("navigation.tasks")} minimized={minimized} />
				</Section>

				{(user?.isAdmin || user?.hasAccessReviewReports) && company?.enableAccessReviews && (
					<>
						<Spacer />
						<Section header={t("navigation.accessReviewHeader")} minimized={minimized}>
							{user?.isAdmin && company?.enableAccessReviews && (
								<TabOption
									routeKey="accessReview"
									Icon={OverviewIcon}
									text={t("navigation.accessReview")}
									minimized={minimized}
								/>
							)}
							{user?.hasAccessReviewReports && company?.enableAccessReviews && (
								<TabOption
									routeKey="accessReport"
									Icon={ReportIcon}
									text={t("navigation.accessReport")}
									minimized={minimized}
								/>
							)}
						</Section>
					</>
				)}

				{user?.isAdmin && (
					<>
						<Spacer />
						<Section header={t("navigation.administrationHeader")} minimized={minimized}>
							<TabOption
								routeKey="requestsLog"
								Icon={InProgressIcon}
								text={t("navigation.requestsLog")}
								minimized={minimized}
							/>

							<TabOption
								routeKey="auditLogs"
								Icon={AuditLogsIcon}
								text={t("navigation.auditLogs")}
								minimized={minimized}
							/>

							<TabOption
								routeKey="settings"
								Icon={SettingsIcon}
								text={t("navigation.settings")}
								minimized={minimized}
							/>

							<TabOption routeKey="users" Icon={UsersIcon} text={t("navigation.users")} minimized={minimized} />
						</Section>
					</>
				)}
			</div>
		</div>
	);
};
