import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceRoleIntegrationResourceId" as const;

const defaults = {
	name: filterName,
	value: [] as string[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleIntegrationResourceIdFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName = filterName;

	static fromURLSearchParams(
		urlSearchParams: URLSearchParams
	): IntegrationResourceRoleIntegrationResourceIdFilter | null {
		const integrationResourceRoleIntegrationResourceId = uniq(
			urlSearchParams.getAll("integrationResourceRoleIntegrationResourceId")
		);
		const integrationResourceRoleIntegrationResourceIdOperator =
			urlSearchParams.get("integrationResourceRoleIntegrationResourceIdOperator") || undefined;
		if (
			integrationResourceRoleIntegrationResourceId.length === 0 ||
			!validateFilterEqualityOperator(integrationResourceRoleIntegrationResourceIdOperator)
		) {
			return null;
		}
		return new IntegrationResourceRoleIntegrationResourceIdFilter({
			value: integrationResourceRoleIntegrationResourceId,
			operator: integrationResourceRoleIntegrationResourceIdOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceRoleIntegrationResourceId", value);
		}
		params.append("integrationResourceRoleIntegrationResourceIdOperator", this.operator);
		return params;
	}
}
