import React from "react";
import { useParams } from "react-router-dom";
import { PageTemplate } from "components/templates/PageTemplate";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { RequestDetails } from "components/common/RequestDetails";
import { useUser } from "hooks/useUser";
import { UserEntitlements } from "./components/UserEntitlements";
import { useStyles } from "./styles";
import { UserPageHeader } from "./UserPageHeader";

export const UserPage: FC = ({ className }) => {
	const params = useParams<{ userId: string }>();
	const userId = params.userId;
	const user = useUser(userId, true, true);
	const classes = useStyles();

	return user ? (
		<PageTemplate className={className} subPage>
			<RequestDetails />
			<PageTemplate.Title>
				<UserPageHeader user={user} />
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.pageContent} noBorder noBackground>
				<div className={classes.entitlementsContent}>
					<UserEntitlements user={user} />
				</div>
			</PageTemplate.Content>
		</PageTemplate>
	) : (
		<LoadingSpinner />
	);
};
