import React, { useCallback } from "react";
import { useIntegrations } from "hooks/useIntegrations";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { IntegrationModel } from "models/IntegrationModel";
import { OptionWithIcon } from "components/common/OptionWithIcon";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TIntegrationFilterExpressionProps = {
	onRemove: () => void;
};

export const IntegrationFilterExpression: FC<TIntegrationFilterExpressionProps> = ({ onRemove }) => {
	const integrations = useIntegrations();
	const { t } = useTranslation();

	const renderOption = useCallback((value: IntegrationModel) => {
		return <OptionWithIcon imageUrl={value.imageUrl}>{value.name}</OptionWithIcon>;
	}, []);

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchValues={integrations!.toList()}
			title={t("pages.auditLog.integrationLogs.filter.integration")}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.integrationPlaceholder")}
			filterEmptyState={
				<FilterExpressionEmptyState
					text={t("pages.auditLog.integrationLogs.filter.integrationEmptyState")}
					Icon={IntegrationIcon}
				/>
			}
			renderOption={renderOption}
			onFilterRemove={onRemove}
			renderType="IntegrationChip"
			filterName="sessionAuditLogIntegrationId"
		/>
	);
};
