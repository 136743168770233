import { Map, List, Set } from "immutable";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TGrouping } from "./types";
import type { TicketModel } from "models/TicketModel";
import type { TPermissionsGrouping } from "./components/RequestPermissions/types";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

interface IGetGroupParams {
	groups: Map<string, TGrouping>;
	id: string;
	name?: string;
	type: "integration" | "bundle";
	role: IntegrationResourceRoleModel;
	resource: IntegrationResourceModel;
}

const getGroup = ({ groups, id, type, role, resource, name }: IGetGroupParams): TGrouping => {
	const group = groups.get(id) || {
		id,
		type,
		resource,
		name,
		resourcesIds: Set<string>(),
		rolesIds: Set<string>()
	};
	group.resourcesIds = group.resourcesIds.add(role.integrationResourceId);
	group.rolesIds = group.rolesIds.add(role.id);
	if (group.resourcesIds.size > 1) {
		group.resource = undefined;
	}
	if (type === "integration") {
		group.role = group.rolesIds.size <= 1 ? role : undefined;
	}
	return group;
};

// Grouping roles by bundle and integration
// Rules: Grouping based on targets array
// Bundles: grouping of all roles in ticketsPermissions that have bundle in targets array
// Integrations: grouping of all roles in targets array by integration
// If integration has only one role, it will be displayed as a single role card
export const getPermissionsGrouping = (request: TicketModel): TPermissionsGrouping => {
	const { ticketPermissions, targets } = request;
	if (!ticketPermissions || !targets) {
		return List();
	}
	const filteredPermissions = ticketPermissions.filter(permission => permission.type === "regular");
	const targetsWithPermissions = targets.map(target => {
		let rolesIds = Set<string>();
		let resourcesIds = Set<string>();
		let resource: IntegrationResourceModel | undefined | null;
		if (target.type === "role") {
			const permission = filteredPermissions.find(permission => permission.ticketTargetIds.includes(target.id));
			const role = permission?.integrationResourceRole;
			return {
				target,
				role: role || target.integrationResourceRole
			};
		}
		filteredPermissions
			.filter(permission => permission.ticketTargetIds.includes(target.id) && permission.integrationResourceRole)
			.forEach(permission => {
				const role = permission.integrationResourceRole;
				resource = role?.integrationResource;
				if (!role || !resource) return;
				rolesIds = rolesIds.add(role.id);
				resourcesIds = resourcesIds.add(resource.id);
				if (resourcesIds.size > 1) {
					resource = undefined;
				}
			});
		return {
			id: target.targetId,
			target,
			type: target.type,
			name: target.bundle?.name,
			rolesIds,
			resource,
			resourcesIds
		};
	});
	const [roleTargets, bundlesWithPermissions] = targetsWithPermissions.partition(({ type }) => type === "bundle");
	// Group role targets by integration id
	let integrations = Map<string, TGrouping>();
	roleTargets.forEach(({ role }) => {
		const integrationId = role?.integrationResource?.integrationId;
		if (integrationId) {
			const resource = role?.integrationResource;
			const group = getGroup({ groups: integrations, id: integrationId, type: "integration", role, resource });
			integrations = integrations.set(integrationId, group);
		}
	});
	return List(bundlesWithPermissions.filter(bundle => bundle?.resourcesIds?.size)).concat(
		integrations.valueSeq().toList()
	) as TPermissionsGrouping;
};
