import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import random from "lodash/random";
import { useTranslation } from "react-i18next";
import { Set } from "immutable";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { StaticChip } from "components/ui/chips/StaticChip";
import { getUserPendingTickets } from "api/user";
import { LoadingDots } from "components/ui/LoadingDots";
import { VirtualRequestList } from "components/common/RequestList";
import { RequestBar } from "components/common/RequestBar";
import { TicketModel } from "models/TicketModel";
import { DESC, usePagination } from "hooks/usePagination";
import { Skeleton } from "components/ui/Skeleton";
import { REQUEST_BAR_HEIGHT } from "components/common/RequestBar/styles";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useStyles } from "./styles";
import type { TRequestBarType } from "components/common/RequestBar/types";

const LIST_SIZE = 10;
const SHOWN_SIZE = 3;

const SORT_FIELDS = ["ticketNumber"];
const UPDATE_TICKET_STATUS_SUBSCRIPTION_ID = "updateTicketStatus";

export const AnimatedRequestBar: FC<{
	request: TicketModel;
	onAnimationEnd: (requestId: string) => void;
	onCancel?: (requestId: string) => void;
	type?: TRequestBarType;
}> = ({ request, onAnimationEnd, onCancel, type }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const containerRef = useRef<HTMLDivElement | null>(null);
	const { status } = request;
	const hasResponded = status === "approved" || status === "rejected";
	const overlayContent = useMemo(() => {
		if (!hasResponded) return null;
		return (
			<>
				<Typography variant="title_sb">{t(`common.requestBar.overlay.${status}`)}</Typography>
				{status === "approved" ? <GrantedIcon size={52} /> : <CloseIcon size={52} />}
			</>
		);
	}, [hasResponded, status, t]);

	const animationClass = useMemo(() => {
		switch (status) {
			case "approved":
				return classes.approvedAnimation;
			case "rejected":
				return classes.deniedAnimation;
			default:
				return undefined;
		}
	}, [classes.approvedAnimation, classes.deniedAnimation, status]);

	useEffect(() => {
		const currentRefDiv = containerRef.current;
		if (!currentRefDiv) return;
		const animationEndHandler = () => onAnimationEnd(request.id);
		currentRefDiv.addEventListener("animationend", animationEndHandler);
		return () => currentRefDiv.removeEventListener("animationend", animationEndHandler);
	}, [onAnimationEnd, request.id]);

	return (
		<div ref={containerRef} className={animationClass}>
			<RequestBar key={request.id} openSidebarOnClick request={request} type={type} onCancel={onCancel} />
			<div className={classes.animationOverlay}>
				<div className={classes.overlayText}>{overlayContent}</div>
			</div>
		</div>
	);
};

export const AwaitingApprovalSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const { itemsForVirtualTable, isLoading, totalResults, getPage, setItem } = usePagination({
		fetchItems: getUserPendingTickets,
		perPage: LIST_SIZE,
		sortOrder: DESC,
		sortFields: SORT_FIELDS
	});
	const { subscribeTicketUpdates, unsubscribeTicketUpdates } = useTicketUpdatesContext();
	const [respondedIds, setRespondedIds] = useState(Set<string>());

	const requests = useMemo(() => {
		if (!itemsForVirtualTable) return [];
		return itemsForVirtualTable.filter(request => (request ? !respondedIds.has(request.id) : true));
	}, [itemsForVirtualTable, respondedIds]);

	const totalAmount = useMemo(
		() => totalResults - ((itemsForVirtualTable?.length || 0) - requests.length),
		[totalResults, itemsForVirtualTable, requests]
	);

	const onRequestRemoved = useCallback((requestId: string) => {
		setRespondedIds(prev => prev.add(requestId));
	}, []);

	const title = useMemo(() => {
		const awaitingApprovalTextTitle = t("pages.requests.awaitingApprovalSection.title");
		return (
			<>
				<Typography variant="body_sb">{awaitingApprovalTextTitle}</Typography>
				<StaticChip size="small" variant="regular">
					{totalAmount}
				</StaticChip>
			</>
		);
	}, [t, totalAmount]);

	const renderRequest = useCallback(
		(request?: TicketModel) => {
			if (!request) return <Skeleton key={`loading-${random(true)}`} height={REQUEST_BAR_HEIGHT} />;
			return (
				<AnimatedRequestBar
					key={request.id}
					type="awaitingResponse"
					onAnimationEnd={onRequestRemoved}
					request={request}
				/>
			);
		},
		[onRequestRemoved]
	);

	useEffect(() => {
		subscribeTicketUpdates(UPDATE_TICKET_STATUS_SUBSCRIPTION_ID, setItem);
		return () => {
			unsubscribeTicketUpdates(UPDATE_TICKET_STATUS_SUBSCRIPTION_ID);
		};
	}, [setItem, subscribeTicketUpdates, unsubscribeTicketUpdates]);

	if (totalAmount === 0) {
		return isLoading ? <LoadingDots center /> : null;
	}
	return (
		<Section className={className} innerRef={innerRef} title={title}>
			<VirtualRequestList
				fetchPage={getPage}
				perPage={LIST_SIZE}
				requestRenderer={renderRequest}
				requests={requests}
				totalRequests={totalAmount}
				shownSize={SHOWN_SIZE}
			/>
		</Section>
	);
};
