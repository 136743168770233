import constate from "constate";
import { useMemo, useRef } from "react";
import { COMPACT_ROW_HEIGHT_PX, DEFAULT_ROW_HEIGHT_PX } from "./components";

export interface IVirtualTableContextProps {
	compact: boolean;
	rowHeight: typeof COMPACT_ROW_HEIGHT_PX | typeof DEFAULT_ROW_HEIGHT_PX;
	gridTemplateColumns: string;
	selectable: boolean;
}

const useVirtualTable = ({ compact, rowHeight, gridTemplateColumns, selectable }: IVirtualTableContextProps) => {
	const tableContainerRef = useRef<HTMLDivElement>(null);

	const store = useMemo(
		() => ({ state: { compact, tableContainerRef, rowHeight, gridTemplateColumns, selectable } }),
		[compact, rowHeight, gridTemplateColumns, selectable]
	);

	return store;
};

export const [VirtualTableProvider, useVirtualTableContext] = constate(useVirtualTable);
