import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import partition from "lodash/partition";
import { PolicyModel } from "models/PolicyModel";
import { Modal } from "components/ui/Modal";
import { Button } from "components/ui/Button";
import { useTranslation } from "react-i18next";
import { useLoadingState } from "hooks/useLoadingState";
import { LoadingDots } from "components/ui/LoadingDots";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useIntegrationResourceRoles } from "hooks/useIntegrationResourceRoles";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Section } from "components/ui/Section";
import { GroupsAndSchedulesSelectInput } from "./components/GroupsAndSchedulesSelectInput";
import { useStyles } from "./styles";
import { RolesList } from "./components/RolesList";
import { BundlesList } from "./components/BundlesList";
import type { ICreatePolicyParams } from "api/policies";

interface IProps {
	policy: PolicyModel | null;
	closeModal: () => void;
	isOpen: boolean;
	savePolicy: (data: ICreatePolicyParams) => Promise<void>;
}

const TRANSLATION_PREFIX = "pages.policies.form";

export const EditPolicyModal: FC<IProps> = ({ policy, closeModal, savePolicy, isOpen }) => {
	const { withLoader, isLoading } = useLoadingState();
	const [policyRoleIds, setPolicyRoleIds] = useState<string[]>([]);
	const roles = useIntegrationResourceRoles(policyRoleIds, true);

	const [policyBundles, setPolicyBundles] = useState<string[]>([]);
	const [addNewRole, setAddNewRole] = useState(false);
	const [addNewBundle, setAddNewBundle] = useState(false);
	const [directoryGroupIds, setDirectoryGroupIds] = useState<string[]>([]);
	const [onCallIntegrationScheduleIds, setOnCallIntegrationScheduleIds] = useState<string[]>([]);
	const [policyRoles, setPolicyRoles] = useState<IntegrationResourceRoleModel[]>([]);

	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		setDirectoryGroupIds(policy?.directoryGroupIds ?? []);
		setPolicyBundles(policy?.bundleIds ?? []);
		setPolicyRoleIds(policy?.integrationResourceRoleIds ?? []);
		setOnCallIntegrationScheduleIds(policy?.onCallIntegrationScheduleIds ?? []);
	}, [policy]);

	useEffect(() => {
		setPolicyRoles(roles ?? []);
	}, [roles]);

	const setPolicyGroupsAndSchedules = useCallback((ids: { type: "group" | "schedule"; id: string }[] | null) => {
		const [groups, schedules] = ids ? partition(ids, ({ type }) => type === "group") : [[], []];

		setDirectoryGroupIds(groups.map(({ id }) => id));
		setOnCallIntegrationScheduleIds(schedules.map(({ id }) => id));
	}, []);

	const editRole = useCallback((role: IntegrationResourceRoleModel, index: number) => {
		setPolicyRoles(policyRoles => {
			if (policyRoles.length <= index) {
				setAddNewRole(false);
				return [...policyRoles, role];
			}
			return policyRoles.map((policyRole, roleIndex) => (index !== roleIndex ? policyRole : role));
		});
	}, []);

	const removeRole = useCallback((role: IntegrationResourceRoleModel) => {
		setPolicyRoles(policyRoles => policyRoles.filter(policyRole => policyRole.id !== role.id));
	}, []);

	const editBundle = useCallback((bundleId: string, index: number) => {
		setPolicyBundles(policyBundleIds => {
			if (policyBundleIds.length <= index) {
				setAddNewBundle(false);
				return [...policyBundleIds, bundleId];
			}
			return policyBundleIds.map((policyBundleId, bundleIndex) => (bundleIndex !== index ? policyBundleId : bundleId));
		});
	}, []);
	const removeBundle = useCallback((bundleId: string) => {
		setPolicyBundles(policyBundles => policyBundles.filter(policyBundleId => policyBundleId !== bundleId));
	}, []);

	const onClose = useCallback(() => {
		if (!isLoading) {
			closeModal();
			setAddNewBundle(false);
			setAddNewRole(false);
		}
	}, [closeModal, isLoading]);

	const save = useCallback(async () => {
		if (!policy || isLoading) return;

		const policyBody = {
			bundleIds: policyBundles,
			directoryGroupIds,
			integrationResourceRoleIds: policyRoles.map(({ id }) => id),
			onCallIntegrationScheduleIds,
			sortOrder: policy.sortOrder
		};

		await withLoader(savePolicy(policyBody));
	}, [
		policy,
		isLoading,
		policyBundles,
		directoryGroupIds,
		policyRoles,
		onCallIntegrationScheduleIds,
		withLoader,
		savePolicy
	]);

	const openNewBundleInput = useCallback(() => {
		setAddNewBundle(true);
	}, []);
	const openNewRoleInput = useCallback(() => {
		setAddNewRole(true);
	}, []);
	const closeNewBundleInput = useCallback(() => {
		setAddNewBundle(false);
	}, []);
	const closeNewRoleInput = useCallback(() => {
		setAddNewRole(false);
	}, []);

	const isDisabled = useMemo(
		() =>
			addNewBundle ||
			addNewRole ||
			(!directoryGroupIds.length && !onCallIntegrationScheduleIds.length) ||
			(!policyBundles.length && !policyRoles.length),
		[
			addNewBundle,
			addNewRole,
			directoryGroupIds.length,
			onCallIntegrationScheduleIds.length,
			policyBundles.length,
			policyRoles.length
		]
	);

	const selectedIdsByType = {
		directoryGroup: directoryGroupIds,
		onCallIntegrationSchedule: onCallIntegrationScheduleIds
	};

	return (
		<Modal
			actions={
				<>
					<Button variant="secondary" size="medium" onClick={onClose}>
						{t("buttons.cancel")}
					</Button>
					<Button size="medium" onClick={save} disabled={isDisabled}>
						{t(`${TRANSLATION_PREFIX}.save`)}
					</Button>
				</>
			}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={t(`${TRANSLATION_PREFIX}.${policy?.id ? "editTitle" : "createTitle"}`)} />
					{isLoading ? (
						<LoadingDots center />
					) : (
						<>
							<GroupsAndSchedulesSelectInput
								selectedIdsByType={selectedIdsByType}
								onChange={setPolicyGroupsAndSchedules}
								label={t(`${TRANSLATION_PREFIX}.selectGroups`)}
								placeholder={t(`${TRANSLATION_PREFIX}.selectGroupsPlaceholder`)}
							/>
							<Section
								title={t(`${TRANSLATION_PREFIX}.givesAccessTo.roles`)}
								titleActions={
									<Button
										size="small"
										variant="secondary"
										onClick={openNewRoleInput}
										disabled={addNewRole}
										prefix={<AddIcon />}>
										{t(`${TRANSLATION_PREFIX}.addRole`)}
									</Button>
								}
								noDivider>
								<RolesList
									closeNewInput={closeNewRoleInput}
									editRole={editRole}
									removeRole={removeRole}
									roles={policyRoles}
									newInputOpen={addNewRole}
								/>
							</Section>
							<Section
								title={t(`${TRANSLATION_PREFIX}.givesAccessTo.bundles`)}
								titleActions={
									<Button
										size="small"
										variant="secondary"
										onClick={openNewBundleInput}
										disabled={addNewBundle}
										prefix={<AddIcon />}>
										{t(`${TRANSLATION_PREFIX}.addBundle`)}
									</Button>
								}
								noDivider>
								<BundlesList
									bundles={policyBundles}
									closeNewInput={closeNewBundleInput}
									editBundle={editBundle}
									removeBundle={removeBundle}
									newInputOpen={addNewBundle}
								/>
							</Section>
						</>
					)}
				</div>
			}
			isOpen={isOpen}
			onClose={onClose}
			className={classNames({ [classes.disabled]: isLoading }, classes.modal)}
		/>
	);
};
