import { Record } from "immutable";
import type { TJsonSchema } from "types/utilTypes";

type TApplicationFlagRestriction = "required" | "optional" | "disabled";

const defaults = {
	adapterName: "",
	canCreateActors: "optional" as TApplicationFlagRestriction,
	canEditPermissions: "optional" as TApplicationFlagRestriction,
	configurationSchema: null as TJsonSchema[] | null,
	id: "",
	imageUrl: "",
	adapterless: false,
	name: "",
	oauthConfigurationSchema: null as TJsonSchema[] | null,
	oauth: "optional" as TApplicationFlagRestriction,
	virtual: false,
	manual: false
};

export class ApplicationModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		return new ApplicationModel(data as typeof defaults);
	}
}
