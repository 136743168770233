import { Button } from "components/ui/Button";
import { ChevronLeftIcon } from "components/ui/Icons/ChevronLeftIcon";
import { ChevronRightIcon } from "components/ui/Icons/ChevronRightIcon";
import { Typography } from "components/ui/legacy/Typography";
import { AccessReviewModel } from "models/AccessReviewModel";
import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIsOpenState } from "hooks/useIsOpenState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { IconButton } from "components/ui/IconButton";
import { useStyles } from "./styles";

const TRANSLATION_PREFIX = "pages.accessReview.dashboard";

interface IProps {
	back: () => void;
	change: (accessReviewId: string) => void;
	createdAt: Date;
	currentAccessReviewIndex: number;
	sortedAccessReviews: AccessReviewModel[];
}

export const NavigationHeader: FC<IProps> = ({
	back,
	change,
	createdAt,
	currentAccessReviewIndex,
	sortedAccessReviews
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const openErrorModal = useOpenGlobalErrorModal();
	const areYouSureModal = useIsOpenState();

	const {
		actions: { finishAccessReview }
	} = useAccessReviewsContext();

	const hasPrevious = currentAccessReviewIndex >= 1 && sortedAccessReviews?.length > 1;
	const hasNext = currentAccessReviewIndex >= 0 && currentAccessReviewIndex < sortedAccessReviews?.length - 1;

	const changeToPrevious = useCallback(() => {
		if (!hasPrevious || !change) return;
		const previousAccessReview = sortedAccessReviews?.at(currentAccessReviewIndex - 1);
		if (!previousAccessReview) return;
		change(previousAccessReview.id);
	}, [change, currentAccessReviewIndex, hasPrevious, sortedAccessReviews]);

	const changeToNext = useCallback(() => {
		if (!hasNext || !change) return;
		const nextAccessReview = sortedAccessReviews?.at(currentAccessReviewIndex + 1);
		if (!nextAccessReview) return;
		change(nextAccessReview.id);
	}, [change, currentAccessReviewIndex, hasNext, sortedAccessReviews]);

	const accessReview = sortedAccessReviews.at(currentAccessReviewIndex);

	const [isFinishing, setIsFinishing] = useState(false);
	const onFinish = useCallback(async () => {
		if (accessReview) {
			setIsFinishing(true);
			try {
				await finishAccessReview(accessReview.id);
				areYouSureModal.close();
			} catch (error) {
				openErrorModal(error as Error);
			} finally {
				setIsFinishing(false);
			}
		}
	}, [accessReview, areYouSureModal, finishAccessReview, openErrorModal]);

	return (
		<div className={classes.navigationHeader}>
			<AreYouSureModal
				title={t("pages.accessReview.complete.title")}
				content={
					<Trans
						i18nKey={"pages.accessReview.complete.description"}
						components={{ bold: <b /> }}
						context={accessReview?.immediateRevoke ? "revoke" : undefined}
					/>
				}
				isOpen={areYouSureModal.isOpen}
				onClose={areYouSureModal.close}
				onAction={onFinish}
				actionLabel={t("buttons.done")}
			/>

			<Button
				variant="text"
				size="medium"
				className={classes.navigationBackButton}
				onClick={back}
				prefix={<ChevronLeftIcon />}>
				{t(`${TRANSLATION_PREFIX}.back`)}
			</Button>
			<div className={classes.navigationContainer}>
				<IconButton
					size="medium"
					disabled={!hasPrevious}
					tooltip={
						hasPrevious
							? t(`${TRANSLATION_PREFIX}.previous`, {
									date: sortedAccessReviews?.at(currentAccessReviewIndex - 1)?.createdAt
								})
							: undefined
					}
					onClick={changeToPrevious}>
					<ChevronLeftIcon />
				</IconButton>

				<Typography variant="h3">
					{t("pages.accessReview.name", {
						date: createdAt,
						name: accessReview?.name
					})}
				</Typography>

				<IconButton
					size="medium"
					disabled={!hasNext}
					tooltip={
						hasNext
							? t(`${TRANSLATION_PREFIX}.next`, {
									date: sortedAccessReviews?.at(currentAccessReviewIndex + 1)?.createdAt
								})
							: undefined
					}
					onClick={changeToNext}>
					<ChevronRightIcon />
				</IconButton>
			</div>
			{accessReview && accessReview.permissionCount && accessReview.permissionCount > 0 && (
				<Button
					variant={accessReview.status === "done" ? "text" : "secondary"}
					size="medium"
					className={classes.doneButton}
					prefix={<GrantedIcon />}
					loading={isFinishing}
					disabled={accessReview.status === "done"}
					onClick={areYouSureModal.open}>
					{t("buttons.done")}
				</Button>
			)}
		</div>
	);
};
