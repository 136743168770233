import React, { ReactNode, useMemo } from "react";
import classNames from "classnames";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "./styles";

const HeaderCellContent: FC<{ text: ReactNode; icon?: ReactNode }> = ({ text, icon, className }) => {
	const classes = useStyles();

	const iconComponent = useMemo(() => (icon ? <div className={classes.icon}>{icon}</div> : null), [classes.icon, icon]);

	return (
		<div className={classNames(classes.textCell, className)}>
			{iconComponent}
			<TooltipOnOverflow content={text} textVariant="body_sb" />
		</div>
	);
};

const HeaderCellContentMemo = React.memo(HeaderCellContent) as typeof HeaderCellContent;

export { HeaderCellContentMemo as HeaderCellContent };
