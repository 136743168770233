import React, { memo, useMemo } from "react";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import { SCROLLBAR_SIZE_PX } from "globalStylesVariables";
import { useVirtualTableContext } from "../../virtualTableContext";
import { useStyles } from "./styles";
import type { TColumn } from "../../types";

const TableHeader = <T extends object>({
	columns,
	hasVerticalScroll,
	gridTemplateColumns,
	headerRef
}: {
	columns: TColumn<T>[];
	hasVerticalScroll: boolean;
	gridTemplateColumns: string;
	headerRef: React.RefObject<HTMLDivElement>;
}) => {
	const classes = useStyles();

	const {
		state: { selectable }
	} = useVirtualTableContext();

	const gridStyle = useMemo(
		() => ({
			gridTemplateColumns: hasVerticalScroll ? `${gridTemplateColumns} ${SCROLLBAR_SIZE_PX}px` : gridTemplateColumns
		}),
		[hasVerticalScroll, gridTemplateColumns]
	);

	return (
		<div className={classes.header} ref={headerRef}>
			<div className={classNames(classes.row, { [classes.selectable]: selectable })} style={gridStyle}>
				{columns.map(column => (
					<div key={column.key} className={classNames(classes.cell, column.headerContainerClassName)}>
						{column.header}
					</div>
				))}
				{hasVerticalScroll && <div className={classNames(classes.cell, classes.scrollPlaceholder)} />}
			</div>
		</div>
	);
};

const HeadersMemo = memo(TableHeader, isEqual) as typeof TableHeader;

export { HeadersMemo as TableHeader };
