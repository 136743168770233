import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useIntegrations } from "hooks/useIntegrations";
import { Button } from "components/ui/Button";
import { useLoadingState } from "hooks/useLoadingState";
import { Modal } from "components/ui/Modal";
import { IntegrationSelectInput } from "components/common/IntegrationSelectInput";
import { ResourceSelectInput } from "components/common/ResourceSelectInput";
import { RoleSelectInput } from "components/common/RoleSelectInput";
import { PrerequisiteIcon } from "components/ui/Icons/PrerequisiteIcon";
import { IconPrefix } from "components/ui/IconPrefix";
import { useStyles } from "./styles";
import type { TClauseCreation } from "types/prerequisitePermissions";

interface IBaseProps {
	onClose: () => void;
	isOpen?: boolean;
	prohibitedIds?: {
		integrationResourceIds?: string[] | null;
		integrationResourceRoleIds?: string[] | null;
	};
}

interface IPermissionProps extends IBaseProps {
	onSavePermission: (data: TClauseCreation) => Promise<void>;
	type: "clause";
	onSaveRole?: never;
	existingClauseRoles?: never;
}

interface IRoleProps extends IBaseProps {
	onSaveRole: (integrationResourceRoleId: string) => Promise<void>;
	type: "role";
	onSavePermission?: never;
	existingClauseRoles: IntegrationResourceRoleModel[];
}

type TProps = IRoleProps | IPermissionProps;

export const AddRoleFormModal: FC<TProps> = ({
	onSaveRole,
	onSavePermission,
	onClose,
	isOpen = false,
	type,
	existingClauseRoles = [],
	prohibitedIds = {}
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [integration, setIntegration] = useState<IntegrationModel | null>(null);
	const [integrationResource, setIntegrationResource] = useState<IntegrationResourceModel | null>(null);
	const [integrationResourceRole, setIntegrationResourceRole] = useState<IntegrationResourceRoleModel | null>(null);
	const integrations = useIntegrations();
	const { isLoading, withLoader } = useLoadingState();

	const onChangeIntegration = useCallback((selectedIntegration: IntegrationModel | null) => {
		setIntegration(selectedIntegration);
		setIntegrationResource(null);
		setIntegrationResourceRole(null);
	}, []);

	const onChangeResource = useCallback((selectedResource: IntegrationResourceModel | null) => {
		setIntegrationResource(selectedResource);
		setIntegrationResourceRole(null);
	}, []);

	const handleClose = useCallback(() => {
		onClose();
		setIntegration(null);
		setIntegrationResource(null);
		setIntegrationResourceRole(null);
	}, [onClose]);

	const handleOnSubmit = useCallback(async () => {
		if (integrationResourceRole?.id) {
			if (type === "clause") {
				const data = {
					roles: [{ integrationResourceRoleId: integrationResourceRole.id, defaultOption: true }]
				};
				await withLoader(onSavePermission(data));
			} else {
				await withLoader(onSaveRole(integrationResourceRole.id));
			}
		}
		handleClose();
	}, [handleClose, integrationResourceRole, onSavePermission, onSaveRole, type, withLoader]);

	const shouldFilterIntegration = useCallback(
		(integration: IntegrationModel) => !integration.actorless && !integration.virtual,
		[]
	);

	const shouldFilterResource = useCallback(
		(integrationResource: IntegrationResourceModel) =>
			!prohibitedIds.integrationResourceIds?.includes(integrationResource.id) || false,
		[prohibitedIds]
	);

	const shouldFilterRole = useCallback(
		(integrationResourceRole: IntegrationResourceRoleModel) =>
			!prohibitedIds.integrationResourceRoleIds?.includes(integrationResourceRole.id) || false,
		[prohibitedIds]
	);

	const shouldDisableRole = useCallback(
		(integrationResourceRole: IntegrationResourceRoleModel) =>
			existingClauseRoles.some(existingRole => existingRole.id === integrationResourceRole.id) || false,
		[existingClauseRoles]
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			className={classes.modal}
			actions={
				<div className={classes.actions}>
					<Button
						size="medium"
						loading={isLoading}
						onClick={handleOnSubmit}
						disabled={!integrationResourceRole?.id || isLoading}>
						{t("prerequisitePermissions.clauseForm.savePermission")}
					</Button>
				</div>
			}
			content={
				<div className={classes.content}>
					<IconPrefix
						Icon={PrerequisiteIcon}
						size="large"
						semibold
						content={t(`prerequisitePermissions.${type}Form.title`)}
					/>
					<div className={classes.roleForm}>
						<IntegrationSelectInput
							className={classes.input}
							filter={shouldFilterIntegration}
							label={t("common.integrationSelectInput.label")}
							onChange={onChangeIntegration}
							options={integrations}
							placeholder={t("common.integrationSelectInput.placeholder")}
							required
							value={integration}
						/>
						<ResourceSelectInput
							className={classes.input}
							inputLabel={t("common.resourceSelectInput.label")}
							onChange={onChangeResource}
							placeholder={t("common.resourceSelectInput.placeholder")}
							required
							selected={integrationResource}
							selectedIntegrationId={integration?.id || ""}
							shouldFilter={shouldFilterResource}
						/>
						<RoleSelectInput
							className={classes.input}
							disabled={!integration || !integrationResource}
							inputLabel={t("common.roleSelectInput.label")}
							integrationResourceId={integrationResource?.id}
							onChange={setIntegrationResourceRole}
							placeholder={t("common.roleSelectInput.placeholder")}
							required
							selectedRole={integrationResourceRole}
							shouldDisable={shouldDisableRole}
							shouldFilter={shouldFilterRole}
						/>
					</div>
				</div>
			}
		/>
	);
};
