import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IntegrationSelectInput } from "components/common/IntegrationSelectInput";
import { ResourceSelectInput } from "components/common/ResourceSelectInput";
import { RoleSelectInput } from "components/common/RoleSelectInput";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { IconButton } from "components/ui/IconButton";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { useStyles } from "./styles";

export const RolesList: FC<{
	roles: IntegrationResourceRoleModel[];
	newInputOpen: boolean;
	closeNewInput: () => void;
	editRole: (role: IntegrationResourceRoleModel, index: number) => void;
	removeRole: (role: IntegrationResourceRoleModel) => void;
}> = ({ roles, className, removeRole, editRole, newInputOpen, closeNewInput }) => {
	const classes = useStyles();

	const removeRoleHandler = useCallback(
		(role?: IntegrationResourceRoleModel) => {
			if (role) {
				removeRole(role);
			}
		},
		[removeRole]
	);

	const addNewRoleHandler = useCallback(
		(role: IntegrationResourceRoleModel, roleIndex: number) => {
			editRole(role, roleIndex);
		},
		[editRole]
	);

	const shouldDisableRole = useCallback(
		(integrationResourceRole: IntegrationResourceRoleModel) =>
			roles?.some(existingRole => existingRole.id === integrationResourceRole.id) || false,
		[roles]
	);

	return (
		<div className={classNames(className, classes.rolesListContainer)}>
			{roles?.map((role, index) => (
				<RoleSelect
					key={`${role.id}-${index}`}
					roleIndex={index}
					removeRole={removeRoleHandler}
					editRole={editRole}
					role={role}
					shouldDisableRole={shouldDisableRole}
				/>
			))}
			{newInputOpen && (
				<RoleSelect
					roleIndex={roles.length}
					removeRole={closeNewInput}
					editRole={addNewRoleHandler}
					shouldDisableRole={shouldDisableRole}
				/>
			)}
		</div>
	);
};

const RoleSelect: FC<{
	roleIndex: number;
	editRole: (role: IntegrationResourceRoleModel, index: number) => void;
	removeRole: (role?: IntegrationResourceRoleModel) => void;
	role?: IntegrationResourceRoleModel;
	shouldDisableRole?: (integrationResourceRole: IntegrationResourceRoleModel) => boolean;
}> = ({ editRole, removeRole, roleIndex, role, shouldDisableRole }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const integrations = useIntegrations(true);
	const integrationOptions = useMemo(
		() =>
			integrations?.filter(
				integration => !(integration.canCreateActors && !integration.canEditPermissions) && !integration.isDeleted
			) ?? null,
		[integrations]
	);

	const [selectedIntegration, setSelectedIntegration] = useState<IntegrationModel | null>(null);
	const [selectedResource, setSelectedResource] = useState<IntegrationResourceModel | null>(null);

	const handleRoleChange = useCallback(
		(newRole: IntegrationResourceRoleModel | null) => {
			if (newRole) {
				const roleWithResource = newRole.set("integrationResource", selectedResource);
				editRole(roleWithResource, roleIndex);
			}
		},
		[editRole, roleIndex, selectedResource]
	);

	const handleIntegrationChange = useCallback(async (integration: IntegrationModel | null) => {
		setSelectedResource(null);
		setSelectedIntegration(integration);
	}, []);

	const removeRoleHandler = useCallback(() => {
		removeRole(role);
	}, [removeRole, role]);

	const shouldFilterRoleOption = useCallback(
		(integrationResourceRole: IntegrationResourceRoleModel) => {
			if (!integrationResourceRole.virtualizedRole) return true;

			const virtualizedRole = integrationResourceRole.virtualizedRole;
			const virtualizedRoleIntegrationId = virtualizedRole.integrationResource!.integrationId;
			return integrationOptions?.has(virtualizedRoleIntegrationId) ?? false;
		},
		[integrationOptions]
	);

	const shouldDisableOption = useCallback(
		(integrationResourceRole: IntegrationResourceRoleModel) => {
			if (integrationResourceRole.id === role?.id) return false;
			if (!shouldFilterRoleOption(integrationResourceRole)) return false;
			return shouldDisableRole ? shouldDisableRole(integrationResourceRole) : false;
		},
		[shouldDisableRole, shouldFilterRoleOption, role]
	);

	useEffect(() => {
		if (role?.integrationResource && !selectedResource?.id && !selectedIntegration?.id) {
			setSelectedResource(role.integrationResource);
			if (role.integrationResource.integrationId !== selectedIntegration?.id) {
				const integration = integrations?.find(({ id }) => id === role.integrationResource?.integrationId);
				if (integration) {
					setSelectedIntegration(integration);
				}
			}
		}
	}, [integrations, role, selectedIntegration?.id, selectedResource?.id]);

	return (
		<div className={classes.roleSelectInput}>
			<IntegrationSelectInput
				className={classes.input}
				disabled={role?.isDeleted}
				label={t("common.integrationSelectInput.label")}
				onChange={handleIntegrationChange}
				options={integrationOptions}
				placeholder={t("common.integrationSelectInput.placeholder")}
				required
				value={selectedIntegration}
			/>
			<ResourceSelectInput
				className={classes.input}
				disabled={role?.isDeleted || !selectedIntegration}
				inputLabel={t("common.resourceSelectInput.label")}
				onChange={setSelectedResource}
				placeholder={t("common.resourceSelectInput.placeholder")}
				required
				selected={selectedResource}
				selectedIntegrationId={selectedIntegration?.id || ""}
			/>
			<RoleSelectInput
				className={classes.input}
				disabled={role?.isDeleted || !selectedIntegration || !selectedResource}
				inputLabel={t("common.roleSelectInput.label")}
				integrationResourceId={selectedResource?.id}
				noOptionsText={t("pages.policies.form.selectRoles.noOptionsText")}
				onChange={handleRoleChange}
				placeholder={t("common.roleSelectInput.placeholder")}
				required
				selectedRole={role && role?.integrationResourceId === selectedResource?.id ? role : null}
				shouldDisable={shouldDisableOption}
				shouldFilter={shouldFilterRoleOption}
			/>
			<IconButton className={classes.icon} size="large" onClick={removeRoleHandler}>
				<DeleteIcon />
			</IconButton>
		</div>
	);
};
