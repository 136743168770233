import { RuleModel } from "models/RuleModel";
import React, { useCallback } from "react";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Tooltip } from "components/ui/Tooltip";
import { RuleIcon } from "components/ui/Icons/RuleIcon";
import { IconButton } from "components/ui/IconButton";
import { Typography } from "components/ui/Typography";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { useTranslation } from "react-i18next";
import { useIsOpenState } from "hooks/useIsOpenState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useNavigate } from "react-router-dom";
import { useRulesContext } from "components/pages/RulesPage/RulesContext";
import { useStyles } from "./styles";

type TRuleCellProps = {
	rule: RuleModel;
};

const RuleInfo: FC<{ rule: RuleModel }> = React.memo(function RuleInfo({ rule }) {
	const classes = useStyles();

	const { t } = useTranslation("translation", { keyPrefix: "pages.rules.ruleInfo" });
	return (
		<div className={classes.ruleInfoContainer}>
			<Typography variant="text_sm_sb">{t("ruleId")}</Typography>
			<Typography variant="text_sm_reg">{rule.number}</Typography>
		</div>
	);
});

const RuleNumberContent: FC<{ rule: RuleModel }> = React.memo(function RuleNumberContent({ rule, children }) {
	const classes = useStyles();

	return (
		<div className={classes.ruleActionsCellContainer}>
			{rule.id ? (
				<Tooltip content={<RuleInfo rule={rule} />}>
					<StaticChip size="small" variant="regular" PrefixIcon={RuleIcon}>
						{rule.number}
					</StaticChip>
				</Tooltip>
			) : undefined}
			{children}
		</div>
	);
});

export const RuleActionsCell: FC<TRuleCellProps> = ({ rule }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const {
		state: { rulesReadonly },
		actions: { deleteRule }
	} = useRulesContext();

	const editRule = useCallback(() => {
		!rulesReadonly && navigate(`/rules/edit/${rule.id}`);
	}, [navigate, rule.id, rulesReadonly]);

	const { isOpen: areYouSureIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();

	const { t } = useTranslation("translation", { keyPrefix: "pages.rules.ruleBar.actions" });

	const onDelete = useCallback(() => {
		deleteRule(rule);
		closeAreYouSureModal();
	}, [deleteRule, rule, closeAreYouSureModal]);

	return (
		<>
			{areYouSureIsOpen ? (
				<AreYouSureModal
					isOpen
					closeLabel={t("delete.modalCancel")}
					actionLabel={t("delete.modalConfirm")}
					title={t("delete.modalTitle")}
					onCancel={onDelete}
					onClose={closeAreYouSureModal}
					content={t("delete.modalContent")}
					onAction={closeAreYouSureModal}
				/>
			) : undefined}
			<RuleNumberContent rule={rule}>
				{rulesReadonly ? null : (
					<div className={classes.ruleActionsContainer}>
						<IconButton size="medium" tooltip={t("edit")} onClick={editRule}>
							<EditIcon />
						</IconButton>
						<IconButton size="medium" tooltip={t("delete.tooltipText")} onClick={openAreYouSureModal}>
							<DeleteIcon />
						</IconButton>
					</div>
				)}
			</RuleNumberContent>
		</>
	);
};
