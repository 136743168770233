import partition from "lodash/partition";
import pickBy from "lodash/pickBy";
import type { PartialOrNullWithOneRequired } from "types/utilTypes";
import type { TRequestTarget } from "components/pages/NewRequestPage/types";

type TFormData = {
	targets: TRequestTarget[];
	duration: number;
	justification?: string;
	ticketingIntegrationTicketId?: string;
};

const urlFieldsCreator = (formData: PartialOrNullWithOneRequired<TFormData, "targets">) => {
	const { targets } = formData;
	if (!targets) return null;

	const [roleTargets, bundleTargets] = partition(targets, target => target.type === "role");
	return {
		ticketingIntegrationTicketId: formData.ticketingIntegrationTicketId,
		duration: formData.duration?.toString(),
		roles: roleTargets.length ? roleTargets.map(target => ({ type: "role", id: target.id })) : undefined,
		bundleIds: bundleTargets.length ? bundleTargets.map(target => target.id) : undefined
	};
};

export const getUrlParams = (
	formData: Pick<TFormData, "duration" | "justification" | "targets" | "ticketingIntegrationTicketId">
) => {
	const fields = urlFieldsCreator(formData);
	if (!fields) return;

	const dataStr = JSON.stringify(pickBy(fields, Boolean));
	return new URLSearchParams({ data: Buffer.from(dataStr).toString("base64") }).toString();
};
