import {
	ApproversCell,
	ResourceCell,
	RequestedForCell,
	CreationTimeCell,
	DurationCell,
	TicketNumberCell,
	StatusCell
} from "components/common/TicketsListComponents";
import { TicketModel } from "models/TicketModel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import classNames from "classnames";
import { Tooltip } from "components/ui/Tooltip";
import { PaginatedVirtualTable, TColumn } from "components/ui/legacy/VirtualTable";
import { LoadingDots } from "components/ui/LoadingDots";
import { DESC, usePagination } from "hooks/usePagination";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { IPaginationResponse } from "utils/pagination";
import { useNavigate } from "react-router-dom";
import { cancelTicket, getTicket, renewTicket } from "api/tickets";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { SyncIcon } from "components/ui/Icons/SyncIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { SortableVirtualTableHeader } from "components/common/SortableTableHeader";
import { SortTableProvider, useSortTableContext } from "context/sortingTableContext";
import { CopyIcon } from "components/ui/Icons/CopyIcon";
import { IconButton } from "components/ui/IconButton";
import { useStopPropagation } from "hooks/useStopPropagation";
import { useStyles } from "./styles";
import type ApiError from "utils/errors/apiError";

interface IProps {
	searchUserTickets: (paginationOptions: IPaginatedSearchOptions) => Promise<IPaginationResponse<TicketModel>>;
}

const PER_PAGE = 30;
const MY_REQUESTS_LIST_SUBSCRIPTION_ID = "myRequestsList";

const MyRequestsListContent: FC<IProps> = ({ searchUserTickets }) => {
	const {
		state: { sortFields, sortOrder }
	} = useSortTableContext();
	const {
		getPage,
		lastPageNumber,
		totalResults,
		itemsForVirtualTable: tickets,
		setItem
	} = usePagination({
		fetchItems: searchUserTickets,
		perPage: PER_PAGE,
		sortOrder,
		sortFields
	});
	const { t } = useTranslation();
	const classes = useStyles();
	const { subscribeTicketUpdates, unsubscribeTicketUpdates } = useTicketUpdatesContext();
	const navigate = useNavigate();
	const areYouSureModal = useIsOpenState();
	const openErrorModal = useOpenGlobalErrorModal();
	const [idToCancel, setIdToCancel] = useState<string>();

	const onTicketUpdated = useCallback(
		(ticket: TicketModel) => {
			setItem(ticket);
		},
		[setItem]
	);

	useEffect(() => {
		subscribeTicketUpdates && subscribeTicketUpdates(MY_REQUESTS_LIST_SUBSCRIPTION_ID, onTicketUpdated);
		return () => {
			unsubscribeTicketUpdates(MY_REQUESTS_LIST_SUBSCRIPTION_ID);
		};
	}, [onTicketUpdated, subscribeTicketUpdates, unsubscribeTicketUpdates]);

	const getNextPage = useCallback(async () => {
		await getPage(lastPageNumber + 1);
	}, [getPage, lastPageNumber]);

	const onCancelClicked = useCallback(
		(id: string) => {
			areYouSureModal.open();
			setIdToCancel(id);
		},
		[areYouSureModal]
	);

	const onDismiss = useCallback(() => {
		areYouSureModal.close();
		setIdToCancel(undefined);
	}, [areYouSureModal]);

	const handleCancel = useCallback(async () => {
		if (idToCancel) {
			try {
				await cancelTicket(idToCancel);
				const ticket = await getTicket(idToCancel);
				onTicketUpdated(ticket);
				setIdToCancel(undefined);
				areYouSureModal.close();
			} catch (e) {
				const error = e as ApiError;
				if (error.errorId === "ticket.cantCancelNonPending") {
					const ticket = await getTicket(idToCancel);
					setItem(ticket);
					setIdToCancel(undefined);
					areYouSureModal.close();
				}

				openErrorModal(error);
			}
		}
	}, [areYouSureModal, idToCancel, openErrorModal, setItem, onTicketUpdated]);

	const handleTicketRenewal = useCallback(
		async (id: string) => {
			try {
				await renewTicket(id);
				await getPage(1, false, true);
			} catch (e) {
				const error = e as ApiError;
				openErrorModal(error);
			}
		},
		[getPage, openErrorModal]
	);

	useEffect(() => {
		if (!tickets) {
			getNextPage();
		}
	}, [tickets, getNextPage]);

	const columns: TColumn<TicketModel>[] = useMemo(() => {
		return [
			{
				renderCell: row => <TicketNumberCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.ticketNumber")} field="ticketNumber" />,
				key: "number",
				width: 80
			},
			{
				renderCell: row => <ResourceCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.resourceLabel")} />,
				key: "resource",
				width: "minmax(320px, 20fr)"
			},
			{
				renderCell: row => <DurationCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.duration")} field="duration" />,
				key: "duration",
				width: 220
			},
			{
				renderCell: row => <RequestedForCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.requestedFor")} field="receiver" />,
				key: "requestedFor",
				width: "minmax(192px, 12fr)"
			},
			{
				renderCell: row => <CreationTimeCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.creationTime")} field="creationTime" />,
				key: "creationTime",
				width: 160
			},
			{
				renderCell: row => <ApproversCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.approversLabel")} />,
				key: "approvers",
				width: "minmax(192px, 12fr)"
			},
			{
				renderCell: row => <StatusCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.statusLabel")} field="status" />,
				key: "status",
				width: 96
			},
			{
				renderCell: row => <ActionsCell data={row} onCancel={onCancelClicked} onTicketRenewal={handleTicketRenewal} />,
				key: "actions",
				width: 80
			}
		] as TColumn<TicketModel>[];
	}, [t, onCancelClicked, handleTicketRenewal]);

	const onClick = useCallback(
		(data: TicketModel) => {
			navigate(`?ticketId=${data.id}`);
		},
		[navigate]
	);

	if (!tickets) {
		return <LoadingDots center />;
	}

	return (
		<>
			<AreYouSureModal
				isOpen={areYouSureModal.isOpen}
				onClose={onDismiss}
				onAction={handleCancel}
				content={t("pages.myRequests.cancelContent")}
				actionLabel={t("pages.myRequests.cancelRequest")}
			/>

			<PaginatedVirtualTable
				// this is to reset table state when rows change
				key={sortOrder + "_" + sortFields.join(",")}
				perPage={PER_PAGE}
				fetchPage={getPage}
				className={classes.requestsContainer}
				columns={columns}
				totalRows={totalResults}
				rows={tickets}
				onRowClicked={onClick}
			/>
		</>
	);
};

export const MyRequestsList: FC<IProps> = props => (
	<SortTableProvider defaultSortField="ticketNumber" defaultSortOrder={DESC} secondaryField="ticketNumber">
		<MyRequestsListContent {...props} />
	</SortTableProvider>
);

const ActionsCell: FC<{
	data: TicketModel;
	onCancel: (id: string) => void;
	onTicketRenewal: (id: string) => Promise<void>;
}> = ({ data, onCancel, onTicketRenewal }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const sharableLink = `${window.location.origin}/pendingRequests?ticketId=${data.id}`;
	const onShareClick = useCopyToClipboard(sharableLink);

	const onShare = useStopPropagation(onShareClick);

	const onCancelClicked = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			onCancel(data.id);
		},
		[data, onCancel]
	);

	const handleRenewClick = useCallback(
		async (event: React.MouseEvent) => {
			event.stopPropagation();
			await onTicketRenewal(data.id);
		},
		[data, onTicketRenewal]
	);

	return (
		<div className={classes.actionButtons}>
			{data.status === "waitingForApproval" ? (
				<>
					<Tooltip content={t("pages.myRequests.shareTooltip")}>
						<IconButton variant="secondary" className={classNames(classes.actionButton)} size="small" onClick={onShare}>
							<CopyIcon />
						</IconButton>
					</Tooltip>
					<Tooltip content={t("pages.myRequests.cancelTooltip")}>
						<IconButton
							variant="secondary"
							className={classNames(classes.actionButton)}
							size="small"
							onClick={onCancelClicked}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</>
			) : null}
			{(data.status === "granted" || data.status === "revoked") && (
				<Tooltip content={t("pages.myRequests.renewTicket")}>
					<IconButton
						variant="secondary"
						className={classNames(classes.actionButton)}
						size="small"
						onClick={handleRenewClick}>
						<SyncIcon />
					</IconButton>
				</Tooltip>
			)}
		</div>
	);
};
